export const ENV = 'dev';
export const API_BASE_URL = 'https://api.dev.greatchildcare.org';

export const AMPLIFY_CONFIG = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:acbf8ef8-d992-4455-9e91-5ef9cad34288',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_BzN5uXDFM',
  aws_user_pools_web_client_id: '3kjalnr6jpqe9ol0vcub7ekro2',
  oauth: {},
  federationTarget: 'COGNITO_IDENTITY_POOLS',
  aws_user_files_s3_bucket: 'dev-gcc-uploads-bucket',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_content_delivery_bucket: 'dev-gcc-hosting-bucket',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d2t1qcgkiw77qg.cloudfront.net',
};
