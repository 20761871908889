import React, { useCallback, useMemo } from 'react';
import useChangeRequestClassName from 'hooks/useChangeRequestClassName';
import { getValue } from '@testing-library/user-event/dist/utils';
import { Alert, Button, Col, Divider, Form, Input, InputNumber, Radio, Row } from 'antd';
import FormItem from 'components/FormItem';
import { AsyncSelect } from 'components/Select';
import useEntityGroup, { GetEntityListData } from 'hooks/useEntityGroup';
import providerService from 'services/providers.service';
import Card from 'components/Card/Card';

export default function FeeRatesContainer(props) {
  const getData = useCallback(async () => {
    if (props.center?.id) {
      return providerService.getFeeRates(props.center?.id);
    }
  }, [props.center?.id]);

  return (
    <GetEntityListData name="feeRates" getData={getData} className="mb-0">
      <FeeRates {...props} />
    </GetEntityListData>
  );
}

export function FeeRates({ value: items = [], onChange, center, readOnly, className }) {
  const onAddItem = useCallback(
    async (data) => {
      return await providerService.addUpdateFeeRate(center?.id, data);
    },
    [center?.id],
  );
  const onDeleteItem = useCallback(async (item) => providerService.deleteFeeRate(center?.id, item.id), [center?.id]);
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">Type</span>,
        headerText: 'Type',
        dataIndex: ['type', 'title'],
        key: 'type',
      },
      {
        title: <span className="action-header">Charged Per</span>,
        headerText: 'Charged Per',
        dataIndex: ['chargedBy', 'title'],
        key: 'chargedBy',
      },
      {
        title: <span className="action-header">Frequency</span>,
        headerText: 'Frequency',
        dataIndex: ['frequency', 'title'],
        key: 'frequency',
      },
      {
        title: <span className="action-header">Amount</span>,
        headerText: 'amount',
        dataIndex: 'amount',
        key: 'amount',
        ellipsis: true,
        render: (value) => `$${value}`,
      },
      {
        title: <span className="action-header">Additional Comments</span>,
        headerText: 'description',
        dataIndex: 'description',
        key: 'description',
        ellipsis: true,
      },
    ];
    return allColumns;
  }, []);
  const keys = useMemo(
    () => ['id', ['type', 'id'], 'description', ['chargedBy', 'id'], ['frequency', 'id'], 'amount'],
    [],
  );
  const rowClassName = useChangeRequestClassName({ keys, snapshotKey: ['feeRates'] });
  const getFormFields = useCallback(({ values, setVisible, items, form, item, index, addUpdateLoading }) => {
    const getSnapshotValue = (snapshot, name) => {
      return getValue(
        snapshot.feeRates.find((v) => v.id === item?.id),
        name,
      );
    };

    return (
      <>
        <Row gutter={[20, 0]} className="form-row">
          <Col xs={24} md={12} xl={8}>
            <FormItem
              name="type"
              label="Fee Type"
              rules={[{ required: true, message: 'Fee Type is required.' }]}
              getSnapshotValue={getSnapshotValue}
              isSame={(prev, curr) => prev?.id === curr?.id}
            >
              <AsyncSelect
                optionsApiUrl="/options/34"
                placeholder="Fee Type"
                ariaLabel="Fee Type"
                name="type"
                sorter={(opt1, opt2) => {
                  if (['Meal/Snack Fee', 'Registration Fee'].includes(opt1?.title)) {
                    return -1;
                  }

                  return opt1?.sequenceNumber - opt2?.sequenceNumber;
                }}
                getOptionLabel={(opt) => {
                  if ('Registration Fee' === opt?.title) {
                    return (
                      <div className="border-b border-solid border-gray-300 my-[-8px] mx-[-12px] py-2 px-3">
                        {opt.title}
                      </div>
                    );
                  }

                  return opt?.title;
                }}
              />
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={16}>
            <FormItem
              name="description"
              label="Additional Comments"
              rules={[{ max: 225, message: 'Description is too long.' }]}
              getSnapshotValue={getSnapshotValue}
            >
              <Input />
            </FormItem>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <FormItem
              name="chargedBy"
              label="Charged Per"
              rules={[{ required: true, message: 'Charged Per is required.' }]}
              getSnapshotValue={getSnapshotValue}
              isSame={(prev, curr) => prev?.id === curr?.id}
            >
              <AsyncSelect
                optionsApiUrl="/options/36"
                placeholder="Charged Per"
                ariaLabel="Charged Per"
                name="chargedBy"
              />
            </FormItem>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <FormItem
              name="frequency"
              label="Frequency"
              rules={[{ required: true, message: 'Frequency is required.' }]}
              getSnapshotValue={getSnapshotValue}
              isSame={(prev, curr) => prev?.id === curr?.id}
            >
              <AsyncSelect optionsApiUrl="/options/35" placeholder="Frequency" ariaLabel="Frequency" name="frequency" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem
              label="Amount"
              name={'amount'}
              rules={[{ required: true, message: 'Amount is required.' }]}
              getSnapshotValue={getSnapshotValue}
            >
              <InputNumber
                placeholder="Amount"
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </FormItem>
          </Col>
        </Row>
        <Divider className="mt-0" />
        <div className="actions flex mt-5">
          <Button onClick={() => setVisible(false)} data-testid="cancel-feeRate-btn">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            data-testid="save-feeRate-btn"
            disabled={addUpdateLoading}
            loading={addUpdateLoading}
          >
            Save
          </Button>
        </div>
      </>
    );
  }, []);

  const { section } = useEntityGroup({
    items,
    onChange,
    onAddItem,
    onDeleteItem,
    entity: center,
    getAllColumns,
    getFormFields,
    readOnly,
    entityTitle: 'fee',
    tableProps: {
      rowClassName,
    },
    entityName: 'feeRate',
  });

  const form = Form.useFormInstance();
  const doYouChargeFees = Form.useWatch('doYouChargeFees', form);

  return (
    <Card collapsible title="Fees">
      {center?.id && (!items || items.length === 0) && (
        <Form.Item
          name="doYouChargeFees"
          label="Do you charge any fees that do NOT INCLUDE THE COST OF DEPOSITS,
            SUPPLIES, ACTIVITIES, TRANSPORTATION, ETC.?"
          rules={[
            {
              required: true,
              message: 'This is required',
            },
          ]}
          initialValue={readOnly ? false : undefined}
        >
          <Radio.Group disabled={readOnly}>
            <Radio value={false}>No</Radio>
            <Radio value={true}>Yes</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {((items && items.length !== 0) || doYouChargeFees) && (
        <>
          {!readOnly && (
            <Alert
              message="Please add all fees that you regularly charge families, but note that only Registration Fees may qualify for
        reimbursement."
              className="mb-4"
            />
          )}

          {section}
        </>
      )}
    </Card>
  );
}
