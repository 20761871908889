import React, { useEffect, useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { useHistory } from 'react-router-dom';

import Dashboard from './Dashboard';
import Layout from 'components/Layout';
import LandingPage from './LandingPage';
import HelpTree from 'components/HelpTree';
import FeeCalculator from './FeeCalculator';
import AddApplication from './AddApplication';
import Profile from 'containers/Family/Profile';
import LoadingPage from 'components/LoadingPage';
import FamilyApplications from './FamilyApplications';
import FamilyProgramsApplications from './FamilyApplications/FamilyProgramsApplications';
import ProtectedRoute from 'components/ProtectedRoute';
import ExternalAllEmailsPage from 'components/ExternalAllEmailsPage';
import { SnapshotContext } from 'utils';
import styles from './Family.modules.less';
import { familyService } from 'services';
import { actions, selectors } from 'features/family';
import { Email, Home, Identification, ListChecked } from '@carbon/icons-react';

export default function Family(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: householdId } = useSelector(selectors.selectHousehold);

  const { result: houseHold } = useAsync(async () => {
    const houseHold = await familyService.getHouseHold();
    const children = await familyService.getChildren(houseHold.id);
    const adults = await familyService.getAdults(houseHold.id);
    dispatch(actions.setHousehold());
    return { ...houseHold, children, adults };
  }, []);

  useEffect(() => {
    if (houseHold?.id) dispatch(actions.setHousehold(houseHold));
  }, [dispatch, houseHold, houseHold?.id]);

  const menuOptions = useMemo(
    () => [
      {
        label: 'Home',
        icon: Home,
        to: '/family',
        match: ['/family'],
      },
      {
        label: 'Applications',
        icon: () => <ListChecked />,
        to: '/family/applications',
        onClick: () => {
          history.push('/family/applications');
        },
        match: ['/family/applications', '/family/application', /^\/family\/application\/\d+(.*)/],
      },
      {
        label: 'Messages',
        icon: Email,
        to: '/family/messages',
        match: ['/family/messages'],
      },
      {
        label: 'Profile',
        icon: Identification,
        to: '/family/profile',
        match: ['/family/profile'],
      },
    ],
    [history],
  );

  return (
    <Layout {...props} menuOptions={menuOptions} className={classNames('h-full', styles.family)}>
      <SnapshotContext.Provider value={{ snapshotSelector: () => null }}>
        <React.Suspense fallback={<LoadingPage />}>
          <Switch>
            <ProtectedRoute exact path="/family" message="Navigated to Family Landing Page">
              <LandingPage />
            </ProtectedRoute>
            <ProtectedRoute exact path="/family/help" message="Navigated to Help Page">
              <HelpTree />
            </ProtectedRoute>
            <ProtectedRoute exact path="/family/messages" message="Navigated to Messages Page">
              <ExternalAllEmailsPage className="p-6" entityLabel="App ID" />
            </ProtectedRoute>
            <ProtectedRoute exact path="/family/profile" message="Navigated to Profile Page">
              <Profile className="p-6" />
            </ProtectedRoute>
            <ProtectedRoute exact path="/family/applications" message="Navigated to Family Applications List Page">
              <FamilyApplications householdId={householdId} />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/family/programs/applications/"
              message="Navigated to Family Applications List Page"
            >
              <FamilyProgramsApplications householdId={householdId} />
            </ProtectedRoute>
            <ProtectedRoute exact path="/fee-calculator" message="Navigated to Family Applications List Page">
              <FeeCalculator />
            </ProtectedRoute>
            <ProtectedRoute exact path="/family/application" message="Navigated to Family Application Page">
              <AddApplication />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/family/application/:id/dashboard"
              message="Navigated to Family Application Dashboard"
            >
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute exact path="/family/application/:id" message="Navigated to Family Application Page">
              <AddApplication />
            </ProtectedRoute>

            <ProtectedRoute exact path="/family/application/:id/:step" message="Navigated to Family Application Page">
              <AddApplication />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/family/application/:id/:step/:tab"
              message="Navigated to Family Application Page"
            >
              <AddApplication />
            </ProtectedRoute>
            <ProtectedRoute
              exact
              path="/family/application/:id/:step/:tab/:nestedTab"
              message="Navigated to Family Application Page"
            >
              <AddApplication />
            </ProtectedRoute>
            <Redirect to="/family" />
          </Switch>
        </React.Suspense>
      </SnapshotContext.Provider>
    </Layout>
  );
}
