import { useAsync } from 'react-async-hook';
import { commonService } from 'services';

export default function useComponent({ id, programSponsorId, fallback = {} }) {
  const { result: component, loading } = useAsync(async () => {
    if (!programSponsorId || !id) return fallback;
    const data = await commonService.get(`/common/program-types/family/components?branchId=${programSponsorId}`, {
      cache: { interpretHeader: false },
    });
    return data.find((item) => item.id === id) || fallback;
  }, [id, programSponsorId]);

  return { component, loading };
}
