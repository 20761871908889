export const CenterIcon = ({ className }) => (
  <svg className={className} version="1.1" x="0px" y="0px" viewBox="0 0 469.333 469.333" width="1em" height="1em">
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M234.646,63.979c-23.542,0-42.688,19.146-42.688,42.688s19.146,42.687,42.688,42.687
c23.542,0,42.687-19.146,42.687-42.687S258.187,63.979,234.646,63.979z M234.646,128.021c-11.771,0-21.354-9.583-21.354-21.354
s9.583-21.354,21.354-21.354c11.771,0,21.354,9.583,21.354,21.354S246.417,128.021,234.646,128.021z"
          />
          <path
            fill="currentColor"
            d="M458.667,192c5.896,0,10.667-4.742,10.667-10.637v-42.662c0-3.76-1.979-7.249-5.219-9.166L255.458,5.621
c-0.094-0.063-0.188-0.115-0.281-0.167c-12.969-7.187-27.594-7.405-41.313,0.146L5.219,129.535
C1.979,131.451,0,134.941,0,138.701v42.662C0,187.258,4.771,192,10.667,192h32v192.009C19.135,384.009,0,403.142,0,426.671
v31.997c0,5.895,4.771,10.666,10.667,10.666h448c5.896,0,10.667-4.77,10.667-10.666v-31.997
c0-23.529-19.135-42.662-42.667-42.662V192H458.667z M448,426.667V448H21.333v-21.333c0-11.76,9.573-21.333,21.333-21.333h10.667
H416h10.667C438.427,405.333,448,414.906,448,426.667z M64,384V192h42.667v192H64z M128,384V192h64v192H128z M213.333,384V192
H256v192H213.333z M277.333,384V192h64v192H277.333z M362.667,384V192h42.667v192H362.667z M21.333,170.667V144.74
L224.448,24.073c6.646-3.656,13.708-3.646,20.271-0.073L448,144.74v25.927H21.333z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const CrossIcon = ({ className }) => (
  <svg
    fill="currentColor"
    strokeWidth="0"
    version="1.2"
    baseProfile="tiny"
    viewBox="0 0 24 24"
    className={className}
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.414 6.586c-.78-.781-2.048-.781-2.828 0l-2.586 2.586-2.586-2.586c-.78-.781-2.048-.781-2.828 0-.781.781-.781 2.047 0 2.828l2.585 2.586-2.585 2.586c-.781.781-.781 2.047 0 2.828.39.391.902.586 1.414.586s1.024-.195 1.414-.586l2.586-2.586 2.586 2.586c.39.391.902.586 1.414.586s1.024-.195 1.414-.586c.781-.781.781-2.047 0-2.828l-2.585-2.586 2.585-2.586c.781-.781.781-2.047 0-2.828z"></path>
  </svg>
);

export const Home = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    className={className}
    fill="currentColor"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Facility located in a commercial building</title>
    <g id="Icon/Icon/Building/Icon-Center" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 3.000000)" stroke="currentColor">
        <line x1="30.483871" y1="17.9032258" x2="30.483871" y2="21.7741935" id="Line-2" strokeLinecap="square"></line>
        <line
          x1="30.483871"
          y1="1.4516129"
          x2="30.483871"
          y2="10.1612903"
          id="Line-2-Copy"
          strokeLinecap="square"
        ></line>
        <path
          d="M36.7858158,0.5 C37.3346931,0.5 37.8150717,0.617620451 38.2236852,0.863773987 C38.6042161,1.0930102 38.9198233,1.43168175 39.1774194,1.87288462 L39.1774194,1.87288462 L39.1774194,6.91095299 C38.5547247,6.62168734 37.7648807,6.46774194 36.7985487,6.46774194 C35.7154062,6.46774194 34.9045123,6.93174133 34.100741,7.41494986 C33.4563795,7.80232494 32.81859,8.20967742 31.955304,8.20967742 C31.5610925,8.20967742 31.234348,8.18431494 30.9776657,8.12423003 L30.9776657,8.12423003 L30.5673669,1.87982594 C31.033793,2.1241736 31.5037723,2.24193548 31.9744369,2.24193548 C32.4481007,2.24193548 33.1018458,1.92971385 33.8587647,1.52529303 C34.7135392,1.06858803 35.7334241,0.5 36.7858158,0.5 Z"
          id="Rectangle"
        ></path>
        <line x1="33.3870968" y1="21.7741935" x2="30.483871" y2="21.7741935" id="Line-3" strokeLinecap="square"></line>
        <rect id="Rectangle" x="4.37096774" y="11.1451613" width="51.2580645" height="45.4516129"></rect>
        <rect id="Rectangle-Copy" x="15.016129" y="28.5645161" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-7" x="47.9193548" y="28.5645161" width="3.83870968" height="6.74193548"></rect>
        <circle id="Oval" cx="30.483871" cy="20.8064516" r="5.79032258"></circle>
        <rect id="Rectangle-Copy-3" x="15.016129" y="18.8870968" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-8" x="47.9193548" y="18.8870968" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-5" x="15.016129" y="38.2419355" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-9" x="47.9193548" y="38.2419355" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-2" x="8.24193548" y="28.5645161" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-10" x="41.1451613" y="28.5645161" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-4" x="8.24193548" y="18.8870968" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-11" x="41.1451613" y="18.8870968" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-6" x="8.24193548" y="38.2419355" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-12" x="41.1451613" y="38.2419355" width="3.83870968" height="6.74193548"></rect>
        <rect id="Rectangle-Copy-14" x="29.5322581" y="45.983871" width="7.70967742" height="10.6129032"></rect>
        <path
          d="M30.4677419,45.983871 L30.4677419,56.5967742 L22.7580645,56.5967742 L22.7580645,45.983871 L30.4677419,45.983871 Z"
          id="Rectangle-Copy-13"
        ></path>
        <line x1="-1.61161407e-16" y1="56.6129032" x2="60" y2="56.6129032" id="Line" strokeLinecap="square"></line>
      </g>
    </g>
  </svg>
);

export const InHome = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon/Building/Building/EFM Icon Fill Outline</title>
    <g id="Icon/Building/Building/EFM-Icon-Fill-Outline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Group"
        transform="translate(4.688886, 30.229838)"
        fill="none"
        fillOpacity="0.4"
        fillRule="nonzero"
        stroke="#000000"
      >
        <path
          d="M54.401978,7.41026713 C53.295749,6.40721776 51.5707164,6.47276936 50.4082741,7.41026713 L41.7457582,14.3384122 C40.686404,15.1914986 39.3645688,15.6509091 37.9957671,15.6509091 L26.9051869,15.6509091 C26.0802072,15.6509091 25.6218912,15.4016889 25.6218912,14.5767091 C25.6218912,13.7517294 26.3390262,13.6168421 27.1640059,13.6168421 L34.5045951,13.6168421 C35.9952532,13.6168421 37.3827316,12.5949329 37.6264444,11.1231346 C37.9358919,9.24813912 36.4921087,7.61685638 34.663988,7.61685638 L19.6640238,7.61685638 C17.1327798,7.61685638 14.6859473,8.48871099 12.717202,10.0824572 L8.09901833,12.6509163 L2.90524767,12.6509163 C2.08026437,12.6509163 1.40524767,13.325933 1.40524767,14.1509127 L1.40524767,23.1508912 C1.40524767,23.9758709 2.08026437,24.6508876 2.90524767,24.6508876 L36.355146,24.6508876 C37.7145178,24.6508876 39.0364446,24.1914771 40.1051371,23.3383908 L54.2801216,11.9946678 C55.7051365,10.8603321 55.8176546,8.68531902 54.401978,7.41026713 Z"
          id="Path"
          transform="translate(28.406684, 15.666621) rotate(15.000000) translate(-28.406684, -15.666621) "
        ></path>
        <path
          d="M49.0011368,10.2282276 C50.3666121,9.62353652 52.0498316,10.0066898 52.8587585,11.2618742 C53.2377259,11.8456177 53.388638,12.492581 53.3461341,13.1141126 C52.8251566,13.077777 52.2895263,13.1672455 51.7954898,13.3860261 L41.635006,17.8360763 C40.3909534,18.3859134 38.9952545,18.4875538 37.6730936,18.1332818 L26.9604157,15.2628284 C26.1635465,15.049308 25.7853502,14.6899588 25.9988707,13.8930896 L26.0009886,13.8802955 C26.1864366,13.7678811 26.3756025,13.6535103 26.5684862,13.5371832 C27.0880009,13.6763867 28.9019132,13.9672639 30.988273,14.2357729 L34.8273357,15.2649422 C35.7635745,15.5158066 36.7695007,15.3380445 37.5280912,14.8095125 C38.1596199,14.8118399 38.6260446,14.7731288 38.840653,14.6782778 Z"
          id="Combined-Shape"
        ></path>
        <path
          d="M47.6149158,9.51828927 C47.8467294,9.87536389 47.9932105,10.2560937 48.0622875,10.6401881 L38.840653,14.6782778 C38.6260446,14.7731288 38.1596199,14.8118399 37.5280912,14.8095125 C37.5164106,14.8176507 37.5046714,14.8257057 37.4928747,14.8336773 C37.9169707,14.5470155 38.2665365,14.1528693 38.4882294,13.6641998 C38.9250015,12.7000694 38.8168514,11.6681992 38.3301909,10.8613867 L43.7572941,8.48464265 C45.1227694,7.87995155 46.8059889,8.26310483 47.6149158,9.51828927 Z M27.736871,13.3650579 L30.9852573,14.2353847 C29.8483913,14.0890429 28.7925873,13.9360828 27.9832521,13.8046737 L27.59957,13.740841 C27.1174751,13.6584781 26.7574007,13.5878026 26.5684862,13.5371832 C26.3756025,13.6535103 26.1864366,13.7678811 26.0009886,13.8802955 L26.0039886,13.8776164 L26.0391413,13.7680497 C26.2934506,13.102855 26.9842723,13.1633997 27.736871,13.3650579 Z"
          id="Combined-Shape"
        ></path>
        <path
          d="M38.3743655,14.7769883 L38.2163422,14.8452026 C38.0449344,14.9209601 37.7128873,14.9609047 37.264263,14.9725404 C37.3539627,14.9241408 37.4415671,14.8696936 37.5261066,14.810894 L37.4928747,14.8336773 L37.5280912,14.8095125 C37.8599757,14.8107356 38.1462622,14.8006248 38.3743655,14.7769883 Z M26.5684862,13.5371832 C26.7574007,13.5878026 27.1174751,13.6584781 27.59957,13.740841 L27.9832521,13.8046737 C28.7925873,13.9360828 29.8483913,14.0890429 30.9852573,14.2353847 L29.1389686,13.7402169 L32.5998347,14.6677532 C29.9637236,14.3838097 27.2023757,13.975368 26.1937587,13.762253 L26.0029686,13.8790953 C26.1877831,13.767067 26.3762889,13.6530963 26.5684862,13.5371832 Z M41.9272683,8.88504255 C41.9954798,8.99011237 42.0573337,9.0969446 42.1129795,9.20515648 L38.3299686,10.8612169 L38.2374842,10.7170715 C37.8467872,10.1452807 37.2569589,9.70431547 36.534208,9.51065495 L34.5110254,8.96821685 L37.4441959,7.68380695 C39.0310609,6.98107502 40.987187,7.42635038 41.9272683,8.88504255 Z"
          id="Combined-Shape"
        ></path>
      </g>
      <path
        d="M32.4393248,11.4394034 C33.0250778,10.8536047 33.9748436,10.8536047 34.5605966,11.4394034 C35.1463953,12.0252022 35.1463953,12.9748765 34.5605966,13.5606752 L30.1249687,17.9963031 L30.1249687,30.4999964 C30.1249687,31.3284094 29.4533852,31.9999928 28.6249723,31.9999928 L27.8749741,31.9999928 C27.0465611,31.9999928 26.3749776,31.3284094 26.3749776,30.4999964 L26.3749776,25.2500089 L25.6249794,25.2500089 L25.6249794,30.4999964 C25.6249794,31.3284094 24.953396,31.9999928 24.124983,31.9999928 L23.3749848,31.9999928 C22.5465719,31.9999928 21.8749884,31.3284094 21.8749884,30.4999964 L21.8749884,17.9963031 L17.4393605,13.560721 C16.8535618,12.9749222 16.8535618,12.0252022 17.4393605,11.4394034 C18.0251135,10.8536047 18.9748793,10.8536047 19.5606323,11.4394034 L23.6212611,15.5000322 L28.3786502,15.5000322 Z M25.9999785,8.00005007 C27.863942,8.00005007 29.3749705,9.51107857 29.3749705,11.375042 C29.3749705,13.2389597 27.863942,14.750034 25.9999785,14.750034 C24.1360151,14.750034 22.6249866,13.2389597 22.6249866,11.375042 C22.6249866,9.51107857 24.1360151,8.00005007 25.9999785,8.00005007 Z"
        id="Combined-Shape"
        stroke="#000000"
        fill="none"
        fillRule="nonzero"
      ></path>
      <path
        d="M43.9999928,8.00005007 C42.3431598,8.00005007 40.9999928,9.34321703 40.9999928,11.0000501 C40.9999928,12.6568688 42.3431598,14.0000358 43.9999928,14.0000358 C45.6568116,14.0000358 46.9999785,12.6568688 46.9999785,11.0000501 C46.9999785,9.34321703 45.6568116,8.00005007 43.9999928,8.00005007 Z M42.2825063,14.750034 C43.3929467,15.2606681 44.6528925,15.2395653 45.7174651,14.750034 L46.2499803,14.750034 C46.5190094,14.750034 46.7660917,14.8448098 46.95974,15.0027217 L51.6912955,18.803966 C52.1469185,19.3063485 52.1469185,20.1208307 51.6912955,20.6232132 C51.2357082,21.1255956 50.4969988,21.1255956 50.0414114,20.6232132 L48.2165181,19.1070501 L49.5913725,24.6021376 C49.7590738,25.2728564 49.2883779,25.923527 48.6199043,25.9937537 L48.499975,26.0000072 L45.8749812,26.0000072 L45.8749812,30.8749955 C45.8749812,31.4963167 45.3713051,31.9999928 44.7499839,31.9999928 L43.2499875,31.9999928 C42.6286663,31.9999928 42.1249902,31.4963167 42.1249902,30.8749955 L42.1249902,26.0000072 L39.4999964,26.0000072 C38.7679425,26.0000072 38.2313074,25.311166 38.4085989,24.6021376 L39.7585181,19.2030501 L38.1131194,20.6232132 C37.6879045,21.0921035 37.0160589,21.1233628 36.5579599,20.7169912 L36.4632353,20.6232132 C36.0076123,20.1208307 36.0076123,19.3063877 36.4632353,18.8040053 L40.9124247,15.1247076 C41.1184349,14.8948441 41.4174466,14.750034 41.7499911,14.750034 Z"
        id="Combined-Shape"
        stroke="#000000"
        fill="none"
        fillRule="nonzero"
      ></path>
    </g>
  </svg>
);

export const FamilyGroup = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    className={className}
    fill="currentColor"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Group family home located at an individual's private residence</title>
    <g id="Icon/Icon/Building/Home" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 3.000000)" stroke="currentColor">
        <path
          d="M30.0055469,1.63364082 L59.5532782,23.0741044 L55.6657272,29.0100627 L30.0001646,10.7932892 L4.41976654,28.9702759 L-0.745242544,23.1094791 L30.0055469,1.63364082 Z"
          id="Path-13"
        ></path>
        <rect id="Rectangle" x="12.9528302" y="28.8018868" width="15.9811321" height="28.4339623"></rect>
        <rect id="Rectangle-Copy-2" x="33.3301887" y="28.8018868" width="13.7169811" height="14.8490566"></rect>
        <rect id="Rectangle" x="32.1981132" y="43.5188679" width="15.9811321" height="2.39622642"></rect>
        <line x1="40.1886792" y1="30" x2="40.1886792" y2="43.5849057" id="Line" strokeLinecap="square"></line>
        <line
          x1="40.1886792"
          y1="31.1320755"
          x2="40.1886792"
          y2="44.7169811"
          id="Line-Copy"
          strokeLinecap="square"
          transform="translate(40.188679, 37.924528) rotate(-90.000000) translate(-40.188679, -37.924528) "
        ></line>
        <path
          d="M17.6132075,5.02830189 L17.6132075,10.2817215 L9.55660377,15.9020937 L9.55660377,5.02830189 L17.6132075,5.02830189 Z"
          id="Rectangle-Copy"
        ></path>
        <rect id="Rectangle" x="8.4245283" y="0.5" width="10.3207547" height="4.66037736"></rect>
        <circle id="Oval" cx="16.9811321" cy="43.0188679" r="1.76415094"></circle>
        <path
          d="M30,10.7934171 L51.5754717,26.1253905 L51.5754717,57.2358491 L8.4245283,57.2358491 L8.4245283,26.1253905 L30,10.7934171 Z"
          id="Path-14"
        ></path>
        <rect id="Rectangle" x="0.5" y="57.1037736" width="59" height="1"></rect>
      </g>
    </g>
  </svg>
);

export const ReligiousCareFacility = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    className={className}
    fill="currentColor"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icon/Icon/Building/Religious</title>
    <g id="Icon/Icon/Building/Religious" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 2.000000)" stroke="currentColor">
        <path
          d="M19.8225807,27.847096 L19.8225807,59.4926061 L8.37096774,59.4926061 L8.37096774,35.204609 L19.8225807,27.847096 Z"
          id="Rectangle"
        ></path>
        <path
          d="M40.1774194,27.847096 L51.6290324,35.204609 L51.6290324,59.4926061 L40.1774194,59.4926061 L40.1774194,27.847096 Z"
          id="Rectangle-Copy"
        ></path>
        <path
          d="M29.8387093,36.4926061 C31.3574923,36.4926061 32.7324923,37.1082145 33.7277966,38.1035188 C34.7231008,39.098823 35.3387093,40.473823 35.3387093,41.9926061 L35.3387093,41.9926061 L35.3387093,59.4926061 L24.3387093,59.4926061 L24.3387093,41.9926061 C24.3387093,40.473823 24.9543177,39.098823 25.949622,38.1035188 C26.9449262,37.1082145 28.3199262,36.4926061 29.8387093,36.4926061 Z"
          id="Rectangle-Copy-13"
        ></path>
        <line x1="-1.61161407e-16" y1="59.5087947" x2="60" y2="59.5087947" id="Line" strokeLinecap="square"></line>
        <line
          x1="19"
          y1="47.5087947"
          x2="41"
          y2="47.5087947"
          id="Line-Copy"
          strokeLinecap="square"
          transform="translate(30.000000, 47.508795) rotate(90.000000) translate(-30.000000, -47.508795) "
        ></line>
        <g id="Group-2" transform="translate(24.193548, 18.481963)">
          <line
            x1="5.32258065"
            y1="2.4190567"
            x2="5.32258065"
            y2="6.28954741"
            id="Line-2"
            strokeLinecap="square"
          ></line>
          <line
            x1="8.22580645"
            y1="6.28954741"
            x2="5.32258065"
            y2="6.28954741"
            id="Line-3"
            strokeLinecap="square"
          ></line>
          <ellipse id="Oval" cx="5.80645161" cy="5.80573607" rx="5.30645161" ry="5.30573607"></ellipse>
        </g>
        <line
          x1="29.516129"
          y1="1.45143402"
          x2="29.516129"
          y2="10.1600381"
          id="Line-2-Copy"
          strokeLinecap="square"
        ></line>
        <path
          d="M35.8180739,0.5 C36.3669602,0.5 36.8473465,0.617607179 37.2559666,0.863734375 C37.6364862,1.09293553 37.9520867,1.43155284 38.2096774,1.87268491 L38.2096774,1.87268491 L38.2096774,6.91004641 C37.5869842,6.62081258 36.79714,6.46688329 35.8308068,6.46688329 C34.7476722,6.46688329 33.9367868,6.93082446 33.1330224,7.41396927 C32.4886539,7.80130083 31.8508559,8.20860411 30.9875621,8.20860411 C30.5933499,8.20860411 30.2666045,8.18324463 30.00992,8.12316794 L30.00992,8.12316794 L29.5996289,1.87965118 C30.0660532,2.12397114 30.5360308,2.24172082 31.006695,2.24172082 C31.4803543,2.24172082 32.1340904,1.92953762 32.8910002,1.52517147 C33.7457813,1.06851923 34.7656753,0.5 35.8180739,0.5 Z"
          id="Rectangle"
        ></path>
        <rect id="Rectangle-Copy-35" x="43.0806452" y="37.690567" width="3.806452" height="3.805736" rx="1"></rect>
        <rect id="Rectangle-Copy-37" x="43.0806452" y="44.690567" width="3.806452" height="3.805736" rx="1"></rect>
        <rect id="Rectangle-Copy-39" x="43.0806452" y="51.690567" width="3.806452" height="3.805736" rx="1"></rect>
        <rect id="Rectangle-Copy-36" x="12.1129032" y="37.690567" width="3.806452" height="3.805736" rx="1"></rect>
        <rect id="Rectangle-Copy-38" x="12.1129032" y="44.690567" width="3.806452" height="3.805736" rx="1"></rect>
        <rect id="Rectangle-Copy-40" x="12.1129032" y="51.690567" width="3.806452" height="3.805736" rx="1"></rect>
        <path
          d="M30,10.4402373 L40.1451613,22.161515 L40.1451613,59.5 L19.8548387,59.5 L19.8548387,22.161515 L30,10.4402373 Z"
          id="Path-14"
        ></path>
      </g>
    </g>
  </svg>
);

export const SchoolChildCare = ({ className }) => (
  <svg
    width="1em"
    height="1em"
    className={className}
    fill="currentColor"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Public/private school or secondary educational institution as recognized by the local school board</title>
    <g id="Icon/Icon/Building/School" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(2.000000, 2.000000)" stroke="currentColor">
        <rect id="Rectangle" x="4.37096774" y="32.4315484" width="15.4516129" height="27.0610577"></rect>
        <rect id="Rectangle-Copy-2" x="8.24193548" y="21.7876989" width="11.5806452" height="10.6114721"></rect>
        <rect id="Rectangle-Copy" x="40.1774194" y="32.4315484" width="15.4516129" height="27.0610577"></rect>
        <rect id="Rectangle-Copy-37" x="40.1774194" y="21.7876989" width="11.5806452" height="10.6114721"></rect>
        <path
          d="M34.3387097,48.8811339 L34.3387097,59.4926061 L26.6290323,59.4926061 L26.6290323,48.8811339 L34.3387097,48.8811339 Z"
          id="Rectangle-Copy-13"
        ></path>
        <line x1="-1.61161407e-16" y1="59.5087947" x2="60" y2="59.5087947" id="Line" strokeLinecap="square"></line>
        <line x1="29.516129" y1="17.9010196" x2="29.516129" y2="21.7715103" id="Line-2" strokeLinecap="square"></line>
        <line
          x1="29.516129"
          y1="1.45143402"
          x2="29.516129"
          y2="10.1600381"
          id="Line-2-Copy"
          strokeLinecap="square"
        ></line>
        <path
          d="M35.8180739,0.5 C36.3669602,0.5 36.8473465,0.617607179 37.2559666,0.863734375 C37.6364862,1.09293553 37.9520867,1.43155284 38.2096774,1.87268491 L38.2096774,1.87268491 L38.2096774,6.91004641 C37.5869842,6.62081258 36.79714,6.46688329 35.8308068,6.46688329 C34.7476722,6.46688329 33.9367868,6.93082446 33.1330224,7.41396927 C32.4886539,7.80130083 31.8508559,8.20860411 30.9875621,8.20860411 C30.5933499,8.20860411 30.2666045,8.18324463 30.00992,8.12316794 L30.00992,8.12316794 L29.5996289,1.87965118 C30.0660532,2.12397114 30.5360308,2.24172082 31.006695,2.24172082 C31.4803543,2.24172082 32.1340904,1.92953762 32.8910002,1.52517147 C33.7457813,1.06851923 34.7656753,0.5 35.8180739,0.5 Z"
          id="Rectangle"
        ></path>
        <line x1="32.4193548" y1="21.7715103" x2="29.516129" y2="21.7715103" id="Line-3" strokeLinecap="square"></line>
        <ellipse id="Oval" cx="30" cy="21.2876989" rx="5.30645161" ry="5.30573607"></ellipse>
        <rect id="Rectangle-Copy-3" x="10.5" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-19" x="45.3387097" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-35" x="43.0806452" y="24.690567" width="4.80645161" height="4.80573607"></rect>
        <rect id="Rectangle-Copy-7" x="10.5" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-20" x="45.3387097" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-11" x="10.5" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-21" x="45.3387097" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-15" x="10.5" y="53.7192473" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-22" x="45.3387097" y="53.7192473" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-5" x="16.3064516" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-23" x="51.1451613" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-8" x="16.3064516" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-24" x="51.1451613" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-12" x="16.3064516" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-25" x="51.1451613" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-16" x="16.3064516" y="53.7192473" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-26" x="51.1451613" y="53.7192473" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-4" x="8.24193548" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-27" x="43.0806452" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-9" x="8.24193548" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-28" x="43.0806452" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-13" x="8.24193548" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-29" x="43.0806452" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-17" x="8.24193548" y="53.7192473" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-30" x="43.0806452" y="53.7192473" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-6" x="14.0483871" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-36" x="12.1129032" y="24.690567" width="4.80645161" height="4.80573607"></rect>
        <rect id="Rectangle-Copy-34" x="48.8870968" y="36.3020391" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-10" x="14.0483871" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-31" x="48.8870968" y="42.1077752" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-14" x="14.0483871" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-32" x="48.8870968" y="47.9135113" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-18" x="14.0483871" y="53.7192473" width="1" height="2.87049071"></rect>
        <rect id="Rectangle-Copy-33" x="48.8870968" y="53.7192473" width="1" height="2.87049071"></rect>
        <path
          d="M30,10.310217 L40.1451613,18.2193735 L40.1451613,59.5 L19.8548387,59.5 L19.8548387,18.2193735 L30,10.310217 Z"
          id="Path-14"
        ></path>
      </g>
    </g>
  </svg>
);

export const ClearIcon = ({
  className = 'text-gray-400 w-5 h-5 absolute top-2.5 right-2 cursor-pointer',
  onClick,
  ...rest
}) => {
  return (
    <svg
      height="1em"
      width="1em"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className={className}
      stroke="currentColor"
      fill="currentColor"
      onClick={onClick}
      strokeWidth="0"
      title="Clear"
      aria-label="Clear"
      {...rest}
    >
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};
