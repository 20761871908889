import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Steps, Spin } from 'antd';
import { BiCheck } from 'react-icons/bi';
import { useAsync } from 'react-async-hook';

import styles from './ApplicationStatusCard.module.less';
import { formatDateTimeToLocal } from 'utils';

export default function ApplicationStatusCard({ className, status, loading, getPublicStatusList }) {
  const { loading: statusLoading, result = [] } = useAsync(async () => await getPublicStatusList(), []);
  const statuses = useMemo(() => result.sort((a, b) => a.order - b.order), [result]);
  const currentStep = useMemo(
    () => statuses.findIndex((item) => item.title === status?.status?.publicStatus?.title),
    [status, statuses],
  );
  const publicStatus = useMemo(() => status?.status?.publicStatus?.title, [status]);
  // 'QUALITY CHECK', 'ACTION REQUIRED', 'INACTIVE' added to inEligibleStatuses temporarily to hide the steps until we need them later.
  const inEligibleStatuses = useMemo(
    () => ['DEACTIVATED', 'DISQUALIFIED', 'QUALITY CHECK', 'ACTION REQUIRED', 'INACTIVE'],
    [],
  );
  const disabled = inEligibleStatuses.includes(publicStatus);
  const items = useMemo(() => {
    return statuses
      .filter(({ title }) => !inEligibleStatuses.includes(title))
      .map(({ id: key, description: label, title: currentTitle }, index) => {
        return {
          key,
          title: (
            <div className="flex space-x-5 lg:space-x-10">
              <span>
                Step {index + 1}: {label}
              </span>
              {currentTitle === publicStatus && (
                <span data-testid="status-date" className="font-normal">
                  {formatDateTimeToLocal(status?.lastModifiedDate)}
                </span>
              )}
            </div>
          ),
          icon: currentStep > index ? <BiCheck className="check" /> : <span>{index + 1}</span>,
          className: classNames({
            // active: index + 1 < currentStep,
            done: currentStep > index,
          }),
        };
        // return (
        //   <Steps.Step
        //     key={key}
        //     title={
        //       <div className="flex space-x-5 lg:space-x-10">
        //         <span>
        //           Step {index + 1}: {label}
        //         </span>
        //         {currentTitle === publicStatus && (
        //           <span data-testid="status-date" className="font-normal">
        //             {formatDateTimeToLocal(status?.lastModifiedDate)}
        //           </span>
        //         )}
        //       </div>
        //     }
        //     icon={currentStep > index ? <BiCheck className="check" /> : <span>{index + 1}</span>}
        //     className={classNames({
        //       // active: index + 1 < currentStep,
        //       done: currentStep > index,
        //     })}
        //   />
        // );
      });
  }, [currentStep, inEligibleStatuses, publicStatus, status?.lastModifiedDate, statuses]);
  return (
    <div className={classNames(styles.statusCard, className)}>
      <Spin spinning={loading || statusLoading} className="status-loader">
        {disabled ? (
          <div className="-mt-4">
            <h3 data-testid="deactivated-status" className="text-base text-red-500 font-medium">
              {status?.status?.publicStatus?.description || publicStatus}
            </h3>
            <p className="text-15 mt-3 mb-10">
              There is an issue with the information submitted in your application. Please contact us at{' '}
              <b>(800) 424-246</b>.
            </p>
          </div>
        ) : (
          <Steps direction="vertical" current={currentStep} items={items} />
        )}
      </Spin>
    </div>
  );
}
