import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import styles from './Loading.module.less';

export default function Loading({ theme, fixed, className, style, layoutId }) {
  return (
    <motion.div
      layoutId={layoutId}
      className={classNames(styles.loading, { fixed }, className)}
      data-testid="loading"
      aria-live="polite"
      style={{
        bottom: fixed ? 'none' : 0,
        height: fixed ? '100vh' : 'auto',
        position: fixed ? 'sticky' : 'absolute',
        style,
      }}
    >
      <div className={classNames('lds-ripple', theme)}>
        <div></div>
        <div></div>
      </div>
    </motion.div>
  );
}

Loading.propTypes = {
  theme: PropTypes.oneOf(['primary', 'secondary']),
  fixed: PropTypes.bool,
  className: PropTypes.string,
  layoutId: PropTypes.string,
  style: PropTypes.object,
};

Loading.defaultProps = {
  theme: 'secondary',
  className: '',
  fixed: false,
  style: {},
};
