import React from 'react';
import { Divider, Input } from 'antd';
import { MdCheck } from 'react-icons/md';

import { passwordValidator } from 'utils';
import FormField from 'components/Authentication/FormField';

export default function ResetPasswordFields({ password, confirmPassword, form, actions, label = 'New Password' }) {
  const passwordErrors = form.getFieldError('password');
  const confirmPasswordErrors = form.getFieldError('confirmPassword');

  return (
    <>
      <FormField
        label={label}
        name="password"
        rules={[{ required: true, message: `${label} is required` }, passwordValidator]}
      >
        <Input type="password" placeholder="New Password" />
      </FormField>
      <span
        data-testid="length-12-required"
        className={`text-primary block text-xs relative ${passwordErrors.length > 0 ? '' : '-mt-4'}`}
      >
        Must be at least 8 characters long{' '}
        {password && password?.length >= 8 && (
          <MdCheck data-testid="length-12-required-svg" className="text-green-600 text-md absolute ml-2 top-0.5" />
        )}
      </span>
      <span data-testid="number-required" className="text-primary block text-xs relative">
        Must have at least 1 number{' '}
        {password && /\d+/.test(password) && (
          <MdCheck data-testid="number-required-svg" className="text-green-600 text-md absolute ml-2 top-0.5" />
        )}
      </span>
      <span data-testid="upper-case-required" className="text-primary block text-xs relative">
        Must have at least 1 upper case letter{' '}
        {password && /[A-Z]+/.test(password) && (
          <MdCheck data-testid="upper-case-required-svg" className="text-green-600 text-md absolute ml-2 top-0.5" />
        )}
      </span>
      <span data-testid="lower-case-required" className="text-primary block text-xs relative">
        Must have at least 1 lower case letter{' '}
        {password && /[a-z]+/.test(password) && (
          <MdCheck data-testid="lower-case-required-svg" className="text-green-600 text-md absolute ml-2 top-0.5" />
        )}
      </span>
      <span data-testid="special-required" className="text-primary block text-xs mb-5 relative">
        Must have at least 1 special character{' '}
        {password && /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) && (
          <MdCheck data-testid="special-required-svg" className="text-green-600 text-md absolute ml-2 top-0.5" />
        )}
      </span>

      <FormField
        label={`Confirm ${label}`}
        name="confirmPassword"
        rules={[
          { required: true, message: `${label} is required` },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Password does not match.');
            },
          }),
        ]}
        dependencies={['password']}
      >
        <Input type="password" placeholder="Repeat New Password" />
      </FormField>

      <span className={`text-primary block text-xs mb-5 relative  ${confirmPasswordErrors.length > 0 ? '' : '-mt-4'}`}>
        Both passwords must match{' '}
        {password && confirmPassword && password === confirmPassword && (
          <MdCheck className="text-green-600 text-md absolute ml-2 top-0.5" />
        )}
      </span>

      <Divider />

      {actions}
    </>
  );
}
