import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RESET_ALL } from 'constants/index';
import { all } from 'redux-saga/effects';

const initialState = {
  allDocuments: [],
  documents: {},
  loading: false,
  error: '',
  documentTypes: [],
};

export const selectDocumentsState = createSelector(
  (state) => {
    return state.documents;
  },
  (state) => state,
);
export const selectDocumentTypes = createSelector(
  (state) => {
    return state.documents;
  },
  (documentState) => documentState?.documentTypes || [],
);

export const documentStateSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocuments: (state, { payload }) => {
      state.documents = payload;
    },
    setAllDocuments: (state, { payload }) => {
      state.allDocuments = payload;
    },
  },
  extraReducers: {
    [RESET_ALL]: () => {
      return initialState;
    },
  },
});

const reducer = documentStateSlice.reducer;
const actions = {
  ...documentStateSlice.actions,
};

const selectors = {
  selectDocumentTypes,
  selectDocumentsState,
};

function* sagas() {
  yield all([]);
}

export { reducer, actions, selectors, initialState, sagas };
export default documentStateSlice;
