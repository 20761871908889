import React, { useCallback, useEffect } from 'react';
import { Checkbox, Button, Radio } from 'antd';
import { MdClose } from 'react-icons/md';
import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';

import Form from 'components/Form';
import RangeFilter from './RangeFilter/RangeFilter';
import FilterContainer from './FilterContainer';
import { actions } from 'features/search';
import SearchBar from './SearchBar';
import useBreakpoints from 'hooks/useBreakpoints';
import useSearchParams from './useSearchParams';
import { IoFilter } from 'react-icons/io5';
import { FaArrowsAlt } from 'react-icons/fa';

export default function Filters({ isPublicSearch, className }) {
  const { params } = useSearchParams({ isPublicSearch });
  const breakpoints = useBreakpoints();

  return (
    <div className={classNames('inline-flex flex-row [&>*]:m-0.5 flex-wrap w-full', className)}>
      <FacilityFilter params={params} isPublicSearch={isPublicSearch} />

      <DistanceFilter params={params} isPublicSearch={isPublicSearch} />
      {!breakpoints.lg ? <SearchBar className="min-w-max" popover /> : null}
    </div>
  );
}

const FacilityFilter = ({ params, isPublicSearch }) => {
  const { facilityTypes, preferredProvider } = params || {};
  let label = (facilityTypes || []).reduce((acc, curr) => `${acc}${acc ? ',' : ''} ${capitalize(curr)}`, '');
  label = `${label}${label ? ' - ' : ''}${preferredProvider === 'true' ? 'Preferred Provider' : 'All Providers'}`;

  return (
    <Filter
      params={params}
      btnLabel={
        <span className="font-medium text-clip flex items-center gap-1">
          <IoFilter className="mt-[-2px]" /> Facility Type: {label}
        </span>
      }
      // className="z-10"
      panelClassName="min-w-full sm:min-w-[400px] md:min-w-[600px]"
      isPublicSearch={isPublicSearch}
    >
      <FilterContainer title="Type of Facility" id="facilityTypes" titleClassName="mb-4 font-medium text-gray-600">
        <Form.Item name="facilityTypes" className="flex justify-between w-full">
          <Checkbox.Group>
            <div className={classNames('inline-flex flex-row', { isPublicSearch: '[&>*]:mx-4 flex-wrap' })}>
              <Checkbox value="HOME">Family Child Care Home</Checkbox>
              <Checkbox value="CENTER">Child Care Center</Checkbox>
              <Checkbox value="SCHOOL">School</Checkbox>
              {!isPublicSearch && <Checkbox value="IN_HOME">In Home</Checkbox>}
            </div>
          </Checkbox.Group>
        </Form.Item>
      </FilterContainer>
      <FilterContainer title="Type of Providers" id="preferredProvider" titleClassName="pb-4 font-medium text-gray-600">
        <Form.Item name="preferredProvider">
          <Radio.Group className="flex justify-between w-4/5">
            <Radio value={false}>All Providers</Radio>
            <Radio value={true}>Preferred Provider Network</Radio>
          </Radio.Group>
        </Form.Item>
      </FilterContainer>
    </Filter>
  );
};

const DistanceFilter = ({ params, isPublicSearch }) => {
  const { distanceInMiles } = params || {};
  return (
    <Filter
      params={params}
      btnLabel={
        <span className="font-medium text-clip flex items-center gap-1">
          <FaArrowsAlt className="w-3 h-3 mt-[-1px]" /> Distance{distanceInMiles ? `: ${distanceInMiles} mi` : ''}
        </span>
      }
      panelClassName="w-full"
      isPublicSearch={isPublicSearch}
    >
      <Form.Item name="distanceInMiles">
        <RangeFilter
          titleClassName="font-medium text-gray-600"
          id="distanceInMiles"
          title="Distance from Address"
          tipFormatter={(v) => `${v}mi`}
          min={0}
          max={50}
          step={10}
          visible={true}
          // range
        />
      </Form.Item>
    </Filter>
  );
};

export const Filter = ({
  params,
  btnLabel,
  children,
  className,
  panelClassName = 'min-w-full sm:min-w-[400px]',
  isPublicSearch,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const panel = React.useRef(null);
  const arrow = React.useRef(null);
  useEffect(() => {
    form.setFieldsValue({ ...params, preferredProvider: params?.preferredProvider === 'true' });
  }, [params, form]);

  const adjustPanel = useCallback(() => {
    if (panel.current) {
      const bounds = panel.current.getBoundingClientRect();
      const bodyBounds = document.body.getBoundingClientRect();
      const right = bodyBounds.width - bounds.x - bounds.width - /* count modal padding */ (isPublicSearch ? 0 : 20);
      if (right < 0) {
        panel.current.style.left = `${right - 10}px`;
        arrow.current.style.setProperty('--gcc-arrow-offset', `${-right + 30}px`);
      }
      return bounds;
    }
  }, [isPublicSearch]);
  useEffect(() => {
    window.addEventListener('resize', adjustPanel);
    return () => {
      window.addEventListener('resize', adjustPanel);
    };
  }, [adjustPanel]);

  return (
    <Popover className={classNames(className, 'relative')}>
      {({ close, open }) => {
        adjustPanel();
        return (
          <>
            <Popover.Overlay className={classNames('fixed inset-0 bg-black opacity-30', { 'z-10': open })} />
            <Popover.Button
              data-testid="trigger-facility-filter"
              className={classNames(
                'text-black w-auto flex justify-center items-center h-9 px-6 rounded-3xl hover:border-primary bg-white border truncate focus:ring-0',
                { 'z-10': open },
              )}
            >
              {btnLabel}
            </Popover.Button>
            {/* {open && <div style={{ height: bounds?.height }} />} */}
            <Popover.Panel
              unmount={false}
              ref={panel}
              tabIndex="0"
              className={classNames('absolute top-9 left-0', { 'z-10': open })}
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={(values) => {
                  dispatch(actions.setParams({ params: { ...params, ...values }, isPublicSearch }));
                  close();
                }}
                style={{ maxWidth: 'calc(100vw - 10px)' }}
              >
                <div
                  ref={arrow}
                  className={classNames(
                    'arrow-primary-top border-primary border border-solid p-6 bg-white h-auto rounded-xl absolute mt-4',
                    panelClassName,
                  )}
                >
                  <div
                    tabIndex="0"
                    role="button"
                    onClick={close}
                    className="flex-end absolute right-5 top-5 bg-[#ac2e2e33] rounded-full text-center h-8 w-8 justify-center items-center flex z-[1] "
                  >
                    <MdClose size={20} className="text-red-700" />
                  </div>
                  {children}
                  <div className={classNames('flex justify-end space-x-2')}>
                    <Button onClick={() => form.resetFields()}>Reset</Button>
                    <Button
                      onClick={() => {
                        close();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button data-testid="apply-facility-filters" type="primary" htmlType="submit">
                      Apply
                    </Button>
                  </div>
                </div>
              </Form>
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
};
