import React from 'react';
import ProviderHelpButtons from 'components/ProviderHelpButtons';
import ExternalAllEmailsPage from 'components/ExternalAllEmailsPage';

export default function Messages() {
  return (
    <div className="p-6 relative">
      <div className="absolute top-0 right-0">
        <ProviderHelpButtons />
      </div>
      <ExternalAllEmailsPage entityLabel="Location ID" />
    </div>
  );
}
