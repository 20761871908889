import { useMemo } from 'react';
import { Descriptions, Skeleton } from 'antd';

import { formatDate, getFullName } from 'utils';
import useProgram from 'hooks/useProgram';
import { familyService } from 'services';
import { useAsync } from 'react-async-hook';

export default function EligibilityHeader({ id, application, loading }) {
  const { program, loading: programsLoading } = useProgram({ id: application?.program?.id });

  const { result: agreementType } = useAsync(async () => {
    if (!id) return 'N/A';
    const agreements = await familyService.getApplicationAgreements(id);
    const type = agreements.map((item) => item.ula.name).join(',');
    return (
      <span title={type} className="block truncate text-ellipsis max-w-[250px] ">
        {type}
      </span>
    );
  }, [id]);

  const items = useMemo(
    () => [
      {
        id: 'program_selected',
        title: 'Program Selected',
        value: program?.type?.title || 'N/A',
      },
      {
        id: 'submission_date',
        title: 'Submission Date',
        className: 'max-w-[250px] w-[250px]',
        value: formatDate(application?.submittedDate) || 'N/A',
      },
      {
        id: 'program_eligibility_acknowledged',
        title: 'Program Eligibility Acknowledged',
        value: application?.eligibilityAcknowledgmentStatus ? 'Yes' : 'No' || 'N/A',
      },

      {
        id: 'application_dates',
        title: 'Application Dates',
        value: `${formatDate(application?.startDate)}-${formatDate(application?.endDate)}` || 'N/A',
      },

      {
        id: 'parent_user_agreement_type',
        title: 'Parent User Agreement Type',
        value: agreementType || 'N/A',
      },

      {
        id: 'ula_accepted_date',
        title: 'ULA Accepted Date',
        value: formatDate(application?.agreementAcceptedDate) || 'N/A',
      },
    ],
    [
      program?.type?.title,
      application?.submittedDate,
      application?.eligibilityAcknowledgmentStatus,
      application?.startDate,
      application?.endDate,
      application?.agreementAcceptedDate,
      agreementType,
    ],
  );

  const descItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        key: item.id,
        label: item.title,
        children: item.value,
      })),
    [items],
  );
  return (
    <div className="px-6 pb-6">
      <Skeleton avatar loading={loading || programsLoading} paragraph={{ rows: 4 }}>
        <div className="mt-4 mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Sponsor:</span>{' '}
                <span className=" text-primary font-medium">{getFullName(application?.applicant) || 'N/A'}</span>
              </p>
            }
            size="small"
            items={descItems}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {descItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}
