import React, { useEffect, useMemo } from 'react';
import { Button, Input, message, Spin, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { useAsyncCallback } from 'react-async-hook';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Section from 'components/Section';
import { AsyncSelect } from 'components/Select';
import useStartEndDates from 'hooks/useStartEndDates';
import { OperationHours } from 'containers/Provider/AddCenter/Tabs/Schedule';
import familyService from 'services/family.service';
import { TIME_FORMAT, DAYS_OF_WEEK } from 'constants/index';
import { formatDates, formatTo24HourTime } from 'utils';

export default function AddScheduleModal({
  visible,
  setVisible,
  applicationId,
  childId,
  schedule,
  reload,
  setVisibleProviderSelectionModal,
  programId,
}) {
  const [scheduleForm] = Form.useForm();
  const [careStartDate, careEndDate] = useStartEndDates({
    form: scheduleForm,
    startDateFieldProps: {
      name: 'startDate',
      label: 'Care Start Date',
      rules: [{ required: true, message: 'Please select care start date' }],
    },
    endDateFieldProps: {
      name: 'endDate',
      label: 'Care End Date',
      rules: [{ required: false }],
    },
  });

  const timeFormat = (day) => {
    const startTime = day.startTime
      ? dayjs(day.startTime, TIME_FORMAT.HOURS_MINUTES).format(TIME_FORMAT.HOURS_MINUTES_AM_PM)
      : '';
    const endTime = day.endTime
      ? dayjs(day.endTime, TIME_FORMAT.HOURS_MINUTES).format(TIME_FORMAT.HOURS_MINUTES_AM_PM)
      : '';
    return { startTime, endTime };
  };

  const formattedScheduleTime = useMemo(
    () =>
      schedule?.days?.map((day) => {
        const { startTime, endTime } = timeFormat(day);
        return {
          ...day,
          startTime,
          endTime,
        };
      }),
    [schedule],
  );
  useEffect(() => {
    const formatDays = SCHEDULE.map((day, index) => {
      return {
        ...formattedScheduleTime?.[index],
        ...day,
      };
    });
    const updateSchedule = { ...schedule };
    updateSchedule.days = formatDays;
    scheduleForm.setFieldsValue({ ...updateSchedule });
  }, [formattedScheduleTime, schedule, scheduleForm]);

  const { loading: scheduling, execute: submitScheduleRequest } = useAsyncCallback(
    async ({ nextStep }) => {
      try {
        await scheduleForm.validateFields();
        let values = await scheduleForm.getFieldsValue();
        values.days = values?.days?.map((day) => ({
          ...day,
          startTime: day?.startTime ? formatTo24HourTime(day?.startTime) : day?.startTime,
          endTime: day?.endTime ? formatTo24HourTime(day?.endTime) : day?.endTime,
        }));
        values = formatDates(values, [['startDate'], ['endDate']]);
        const schedule = await familyService.saveSchedule(applicationId, childId, values);
        setVisible(false);
        reload(!nextStep, schedule);
        if (nextStep) {
          setVisibleProviderSelectionModal(true);
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to add schedule.', 5);
      }
    },
    [reload],
  );
  return (
    <Modal visible={visible} setVisible={setVisible} width={900} destroyOnClose>
      <Spin spinning={scheduling}>
        <Form layout="vertical" form={scheduleForm}>
          {(formValues) => {
            return (
              <>
                <Section
                  heading="Add Schedule of care"
                  collapsible={false}
                  contentClassName=""
                  className="border-b-2"
                  headerContainerClassName="!px-2 !py-2"
                >
                  <Form.Item name={['id']} hidden>
                    <InputNumber />
                  </Form.Item>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-x-4">
                    <Form.Item
                      name="careDurationType"
                      label="Type of care Needed"
                      rules={[{ required: true, message: 'Type of care is required' }]}
                    >
                      <AsyncSelect
                        placeholder="Type of care"
                        ariaLabel="Type of care"
                        optionsApiUrl={`/care-duration-types`}
                        apiPrefix="families"
                      />
                    </Form.Item>
                    <div>{careStartDate}</div>
                    <div>{careEndDate}</div>
                  </div>
                </Section>

                <Section
                  heading="Schedule of care"
                  contentClassName=""
                  headerContainerClassName="!px-2"
                  triggerClassName="!right-0"
                >
                  <OperationHours
                    enabledSchoolCare={false}
                    // enabledWeekendCare
                    data={formValues?.days || DAYS_OF_WEEK.map((day) => ({ dayOfWeek: day.fullName }))}
                    storedHoursOfOperation={[]}
                    formValues={formValues}
                    name="days"
                    type="Before School Care"
                    openingTimeKey="startTime"
                    closingTimeKey="endTime"
                    // weekendSwitchLabel="Child attends weekend care"
                    hideTwentyFourHourCare
                    format={TIME_FORMAT.HOURS_MINUTES_SECONDS}
                    form={scheduleForm}
                  />

                  <Form.Item
                    name="scheduleNotes"
                    label="Please list down ant special considerations for this schedule."
                  >
                    <Input.TextArea placeholder="List times here." autoSize={{ minRows: 5 }} />
                  </Form.Item>
                </Section>
                <div className="flex actions mt-4">
                  <Button onClick={() => setVisible(false)}>Cancel</Button>

                  <Button type="primary" onClick={() => submitScheduleRequest({ nextStep: true })}>
                    Save
                  </Button>
                </div>
              </>
            );
          }}
        </Form>
      </Spin>
    </Modal>
  );
}
const SCHEDULE = DAYS_OF_WEEK.map((day) => ({ dayOfWeek: day.fullName }));
