import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, memo, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useComponentSize } from 'react-use-size';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { LayoutContext } from 'utils';

import styles from './ContentLayout.module.less';
import useBreakpoints from 'hooks/useBreakpoints';

export default function ContentLayout({
  contentClassName,
  sidebarClassName,
  draggerClassName,
  layoutClassName,
  style = {},
  children,
  sidebarContent,
  breakpoint,
  onCollapseChange,
}) {
  const breakpoints = useBreakpoints();
  const { ref, width } = useComponentSize();
  return (
    <div className={classNames(styles.contentLayout, 'w-full', layoutClassName)} style={{ style }} ref={ref}>
      <SplitPanel
        width={width}
        breakpoints={breakpoints}
        breakpoint={breakpoint}
        content={children}
        sidebar={sidebarContent}
        contentClassName={contentClassName}
        sidebarClassName={sidebarClassName}
        draggerClassName={draggerClassName}
        onCollapseChange={onCollapseChange}
      />
    </div>
  );
}

ContentLayout.propTypes = {
  children: PropTypes.any,
  contentClassName: PropTypes.string,
  layoutClassName: PropTypes.string,
  sidebarClassName: PropTypes.string,
  sidebarContent: PropTypes.any,
  style: PropTypes.object,
  breakpoint: PropTypes.string,
  onCollapseChange: PropTypes.func,
};
ContentLayout.defaultProps = {
  breakpoint: 'xl',
  onCollapseChange: () => {},
};

const DRAG_HANDLE_WIDTH = 8;
const SIDEBAR_DEFAULT_WIDTH = 360;

const SplitPanel = memo(
  ({
    width,
    breakpoints,
    breakpoint,
    content,
    sidebar,
    className,
    contentClassName = 'pl-5',
    sidebarClassName,
    draggerClassName,
    onCollapseChange,
  }) => {
    const matchesBreakpoint = useMemo(() => breakpoints[breakpoint], [breakpoint, breakpoints]);
    const [collapsed, setCollapsed] = useState(false);
    const x = useMotionValue(width - (matchesBreakpoint ? SIDEBAR_DEFAULT_WIDTH + DRAG_HANDLE_WIDTH : 0));
    const contentWidth = useTransform(x, (x) => `calc(${x}px + ${DRAG_HANDLE_WIDTH / 2}px)`);
    const isCollapsed = useTransform(x, (value) => width - SIDEBAR_DEFAULT_WIDTH / 2 < value);

    useEffect(
      () =>
        isCollapsed.onChange((latest) => {
          setCollapsed(latest);
          onCollapseChange(latest);
        }),
      [isCollapsed, onCollapseChange],
    );
    useEffect(() => {
      x.set(width - (matchesBreakpoint ? SIDEBAR_DEFAULT_WIDTH + DRAG_HANDLE_WIDTH : 0));
    }, [matchesBreakpoint, width, x]);

    const collapse = useCallback(
      (collapsed) => {
        if (collapsed) {
          x.set(width - DRAG_HANDLE_WIDTH);
        } else {
          x.set(width - (matchesBreakpoint ? SIDEBAR_DEFAULT_WIDTH + DRAG_HANDLE_WIDTH : 0));
        }
        setCollapsed(collapsed);
      },
      [matchesBreakpoint, width, x],
    );
    return (
      <LayoutContext.Provider value={{ collapsed, setCollapsed, contentWidth, collapse }}>
        <div className={classNames('flex flex-row relative items-stretch', className)}>
          <motion.div className="h-full min-w-0 overflow-hidden" style={{ width: contentWidth }}>
            {/* <div className={classNames('content-area', contentClassName)}>{content}</div> */}
            <Scrollbars style={{ height: 'calc(100vh - 98px)' }}>
              <div className={classNames('content-area', contentClassName)}>{content}</div>
            </Scrollbars>
          </motion.div>
          <motion.div
            title="drag to resize"
            className={classNames(
              'absolute h-full bg-transparent hover:bg-gray-300 active:bg-gray-400',
              draggerClassName,
            )}
            dragMomentum={false}
            drag="x"
            dragConstraints={{
              left: breakpoints.xs ? 0 : width - 500,
              right: width - DRAG_HANDLE_WIDTH,
            }}
            style={{
              x,
              width: DRAG_HANDLE_WIDTH,
              cursor: 'col-resize',
            }}
          ></motion.div>
          <motion.div className="h-full min-w-0 flex-1 sticky top-0">
            <Button
              className="!w-10 h-10 !bg-white hover:bg-white rounded-full border-gray-300 absolute -left-4 top-12 flex justify-center items-center z-10"
              type="link"
              shape="circle"
              onClick={() => {
                if (breakpoints.xs) {
                  x.set(width / 2 < x.get() ? 0 : width);
                } else {
                  x.set(collapsed ? width - SIDEBAR_DEFAULT_WIDTH : width - DRAG_HANDLE_WIDTH);
                }
              }}
              icon={collapsed ? <FiChevronLeft size={24} /> : <FiChevronRight size={24} />}
              data-testid="sidebar-trigger"
              aria-label={collapsed ? 'Open Sidebar' : 'Close Sidebar'}
              title={collapsed ? 'Open Sidebar' : 'Close Sidebar'}
            />

            <Scrollbars style={{ height: 'calc(100vh - 98px)' }}>
              <div style={{ minWidth: SIDEBAR_DEFAULT_WIDTH }} className={classNames('sidebar-area', sidebarClassName)}>
                {sidebar}
              </div>
            </Scrollbars>
          </motion.div>
        </div>
      </LayoutContext.Provider>
    );
  },
);

SplitPanel.propTypes = {
  breakpoint: PropTypes.string,
  breakpoints: PropTypes.shape({
    xs: PropTypes.bool,
    sm: PropTypes.bool,
    md: PropTypes.bool,
    lg: PropTypes.bool,
    xl: PropTypes.bool,
    xxl: PropTypes.bool,
  }),
  content: PropTypes.any,
  contentClassName: PropTypes.string,
  sidebar: PropTypes.any,
  sidebarClassName: PropTypes.string,
  width: PropTypes.number,
};

SplitPanel.defaultProps = {
  breakpoint: 'xl',
};
