import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { AUTH_STATES, ROLES } from 'constants/index';
import { selectActiveRole, selectRoles } from 'features/auth';

const redirectionPaths = {
  [ROLES.SYSTEM_ADMIN]: '/admin',
  [ROLES.PROVIDER]: '/provider',
  [ROLES.PROVIDER_MANAGER]: '/dashboard',
  [ROLES.PROVIDER_COORDINATOR]: '/dashboard',
  [ROLES.PROVIDER_QC_MANAGER]: '/dashboard',
  [ROLES.PROVIDER_QC_COORDINATOR]: '/dashboard',
  [ROLES.PROVIDER_REVIEWER_MANAGER]: '/dashboard',
  [ROLES.PROVIDER_REVIEWER]: '/dashboard',
  [ROLES.PROVIDER_BGC_MANAGER]: '/dashboard',
  [ROLES.PROVIDER_BGC_COORDINATOR]: '/dashboard',
  [ROLES.PROVIDER_SUPPORT_SPECIALIST]: '/dashboard',

  [ROLES.FAMILY]: '/family',
  [ROLES.FAMILY_MANAGER]: '/dashboard',
  [ROLES.FAMILY_COORDINATOR]: '/dashboard',
  [ROLES.FAMILY_QC_MANAGER]: '/dashboard',
  [ROLES.FAMILY_QC_COORDINATOR]: '/dashboard',
  [ROLES.ER_MANAGER]: '/dashboard',
  [ROLES.ER_COORDINATOR]: '/dashboard',
  [ROLES.FAMILY_SUPPORT_MANAGER]: '/dashboard',
  [ROLES.FAMILY_SUPPORT_SPECIALIST]: '/dashboard',
  [ROLES.PARENT_SERVICES_MANAGER]: '/dashboard',
  [ROLES.PARENT_SERVICES_SPECIALIST]: '/dashboard',
  [ROLES.PAYMENT_MANAGER]: '/dashboard',
  [ROLES.PAYMENT_COORDINATOR]: '/dashboard',
  [ROLES.REPORTING_MANAGER]: '/dashboard',
  [ROLES.REPORTING_ANALYST]: '/dashboard',
  [ROLES.FINANCE_MANAGER]: '/dashboard',
};

const ProtectedRoute = ({ authRequired, roleRequired, hideFor, children, message, ...rest }) => {
  const authState = useSelector((state) => state.auth.authState);
  const roles = useSelector(selectRoles);
  const activeRole = useSelector(selectActiveRole);

  let { path } = rest;
  const { location } = rest;
  const { search, pathname } = location;
  if (
    authState === AUTH_STATES.SIGN_IN &&
    authRequired &&
    path !== '/signin' &&
    path !== '/change-password' &&
    path !== '/'
  ) {
    return (
      <Redirect to={pathname.startsWith('/family') ? `/family/signin?next=${pathname}` : `/signin?next=${pathname}`} />
    );
  }
  if (authState === AUTH_STATES.LOGGED_IN && /signin|signup|password/.test(path)) {
    const searchParams = new URLSearchParams(search);
    let nextPath = searchParams.get('next') || redirectionPaths[activeRole] || '/';

    // If user logged out from the 'add new location' page,
    // when logged back in, show the list of locations instead
    if (nextPath === '/provider/facility') {
      nextPath = '/provider';
    }

    return <Redirect to={nextPath} />;
  }
  if (roleRequired) {
    let redirect = false;
    if (Array.isArray(roleRequired)) {
      redirect = !roleRequired.some((_role) => roles.includes(_role));
    } else {
      redirect = !roles.includes(roleRequired);
    }

    if (!activeRole) {
      redirect = true;
    }
    if (redirect) {
      return <Redirect to={redirectionPaths[activeRole] || '/dashboard'} />;
    }
  }
  if (hideFor) {
    let redirect = false;
    if (Array.isArray(hideFor)) {
      redirect = hideFor.includes(activeRole);
    } else {
      redirect = activeRole === roleRequired;
    }
    if (!activeRole) {
      redirect = true;
    }
    if (redirect) {
      return <Redirect to={redirectionPaths[activeRole] || '/dashboard'} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {message && (
            <div className="sr-only" role="status" aria-live="assertive" aria-atomic="true">
              <span>{message}</span>
            </div>
          )}

          {React.cloneElement(children, props)}
        </>
      )}
    />
  );
};

ProtectedRoute.propTypes = {
  authRequired: PropTypes.bool,
  roleRequired: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  message: PropTypes.string,
  children: PropTypes.node,
};

ProtectedRoute.defaultProps = {
  authRequired: false,
};

export default ProtectedRoute;
