import React, { useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Button, Spin, Skeleton } from 'antd';
import { IoWarning } from 'react-icons/io5';
import { FaArrowAltCircleDown, FaEnvelope, FaRegEnvelopeOpen, FaDownload } from 'react-icons/fa';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';
import ReplyEmailModal from 'components/Modals/ReplyEmailModal';

import { downloadFile, formatDate, formatDateTimeToLocal } from 'utils';
import { EMAIL_STATUS, EMAIL_TYPES, ENTITY_TYPES } from 'constants/index';
import styles from './EmailPreview.module.less';
import { selectors as centerSelectors } from 'features/add-center';
import config from 'config';
import { useAsync } from 'react-async-hook';
import { filesService } from 'services';
import { Maximize, Reply } from '@carbon/icons-react';

export default function EmailPreview({ className, loading, data, type, showExpand }) {
  const [showAttachments, setShowAttachments] = useState(false);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const center = useSelector(centerSelectors.selectCenterState);
  const { result: attachments = [] } = useAsync(async () => {
    if (!data?.id) return [];
    return filesService.getDocuments({ entityId: data.id, entityType: ENTITY_TYPES.ATTACHMENT });
  }, [data?.id]);

  if (loading) {
    return <Skeleton className="w-full h-screen p-5" avatar paragraph={{ rows: 8 }}></Skeleton>;
  }

  let icon;
  if (data?.status === EMAIL_STATUS.QUEUED || data?.status === EMAIL_STATUS.DELIVERED) {
    icon = <FaRegEnvelopeOpen className="text-primary" />;
  } else if (data?.status === EMAIL_STATUS.ERROR) {
    icon = <FaEnvelope className="text-red-700" />;
  } else {
    icon = <FaEnvelope className="text-primary" />;
  }
  const deliveryDateTime = type === EMAIL_TYPES.INCOMING ? data.deliveryDateTime : data.createdAt;

  return (
    <div className={classNames(styles.emailPreview, className)} id="email-preview">
      <ReplyEmailModal
        setVisible={() => setShowReplyModal(false)}
        visible={showReplyModal}
        getContainer={'#sent-emails'}
        title={'Editing Automatic Email Response'}
        center={center}
      />

      <Spin spinning={loading}>
        <div className="px-4 py-4 w-full -ml-2 md:ml-0">
          <div className="flex flex-col mb-2 md:flex-row space-y-3 md:space-y-0 md:space-x-3 md:justify-between md:items-center">
            {/* <h4 className="text-primary text-md font-medium uppercase"></h4> */}
            <div className="w-full">
              {data?.status === EMAIL_STATUS.PENDING && (
                <div className="flex items-center">
                  {icon}
                  <span className="font-medium text-lg text-primary uppercase px-2">Email Scheduled</span>
                </div>
              )}
              {(data?.status === EMAIL_STATUS.DELIVERED || data?.status === EMAIL_STATUS.QUEUED) && (
                <div className="flex items-center">
                  {icon}
                  <span className="font-medium text-lg text-primary uppercase px-2">Email Sent</span>
                </div>
              )}
              {data?.status === EMAIL_STATUS.ERROR && (
                <>
                  <div className="flex items-center">
                    <IoWarning size={18} className="text-red-700 align-middle" />
                    <span className="font-medium text-lg text-red-700 uppercase px-2">Email Error</span>
                  </div>
                  <div className="border-t border-b border-dashed border-gray-300 w-full py-3 mb-3 mt-3 px-1 md:px-4 lg:px-5">
                    <p className="text-md">{data?.errorMessage}</p>
                    {/* <Button type="primary" className="my-2 px-4">
                      Send Report To Administrator
                    </Button> */}
                  </div>
                </>
              )}

              <span className="mr-2">Subject:</span>
              <span className="font-medium">{data?.subject}</span>
            </div>

            <div className="flex justify-end absolute top-0 sm:top-1 md:top-4 right-4 space-x-1">
              {config.ENV !== 'uat' && <Button icon={<Reply />} onClick={() => setShowReplyModal(true)} />}

              {showExpand && (
                <Button
                  icon={<Maximize />}
                  onClick={() => window.open(`/messages/${data?.id}`, '_blank')}
                  data-testid="open-in-new-tab-btn"
                  aria-label="Open in new tab"
                />
              )}
            </div>
          </div>
          <div className="flex flex-col pb-2 border-b border-dashed lg:flex-row space-y-3 lg:space-y-0 lg:space-x-3 lg:justify-between ">
            <div className="from-to">
              <div className="from flex flex-col sm:flex-row sm:items-center space-y-2 md:space-x-2">
                <span className="w-12">From:</span>
                <span
                  className="pill bg-primary bg-opacity-10  px-3 py-1 rounded-full block truncate max-w-xs"
                  title={data.fromEmail || 'System Generated'}
                >
                  {data.fromEmail || 'GCC'}
                </span>
              </div>
              <div className="to flex flex-col sm:flex-row sm:items-center space-y-2 md:space-x-2 mt-1">
                <span className="w-12">To:</span>
                {data?.recipients?.map?.((item) => (
                  <span
                    key={item.name}
                    className="pill bg-primary bg-opacity-10 px-3 py-1 rounded-full block truncate max-w-xs"
                  >
                    {item.name}
                  </span>
                ))}
              </div>
            </div>
            <div>
              {data.status === EMAIL_STATUS.PENDING ? (
                <div className="flex space-x-1">
                  <span className="mr-10">Date:</span>
                  <div className="flex flex-col">
                    <span className="font-medium">Scheduled</span>
                    <span>{formatDate(data.scheduledDate)}</span>
                  </div>
                </div>
              ) : (
                <div className="flex space-x-1">
                  <span className="mr-1">{type === EMAIL_TYPES.SENT ? 'Sent' : 'Received'}:</span>
                  <div className="flex flex-col items-start">
                    <span className="font-medium">
                      {deliveryDateTime ? dayjs.utc(deliveryDateTime).fromNow() : 'NA'}
                    </span>
                    <span>{formatDateTimeToLocal(deliveryDateTime)}</span>
                  </div>
                </div>
              )}
              {attachments?.length > 0 && (
                <div className="attachments flex justify-between items-center mt-1">
                  <span className="mr-3">Attachments:</span>
                  <div className="flex space-x-3">
                    <Button
                      className="w-20 !h-8 border-1 !border-gray-300 rounded-full flex items-center justify-center cursor-pointer focus:ring ring-primary"
                      onClick={() => setShowAttachments((v) => !v)}
                      title={showAttachments ? 'Hide Attachments' : 'Show Attachments'}
                      aria-label={showAttachments ? 'Hide Attachments' : 'Show Attachments'}
                      data-testid="show-attachment-btn"
                    >
                      {attachments?.length}{' '}
                      {showAttachments ? (
                        <AiFillCaretUp className="ml-4 text-gray-400" />
                      ) : (
                        <AiFillCaretDown className="ml-4 text-gray-400" />
                      )}
                    </Button>
                    <Button
                      onClick={() => {
                        attachments?.forEach((attachment) => downloadFile(attachment, 'private'));
                      }}
                      className="icon-btn remove-button bordered !w-8 !h-8"
                      icon={<FaDownload className="text-2xl rounded-full text-gray-400" />}
                      title="Download All Attachments"
                      aria-label="Download All Attachments"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {showAttachments && (
            <div
              data-testid="attachments-section"
              className="portal border-b border-dashed py-2 flex flex-wrap space-x-3"
              id={`attachments-${data.id}`}
            >
              {attachments?.map?.((attachment) => (
                <Button
                  key={attachment.id}
                  data-testid={`download-attachment-${attachment.id}`}
                  className="rounded-full h-8 pl-3 pr-2 border-0 bg-primary-faded flex items-center space-x-2 hover:border-1 hover:border-blue"
                  onClick={() => downloadFile(attachment, 'private')}
                >
                  <span className="text-black">{attachment.name}</span> <FaArrowAltCircleDown className="text-white" />
                </Button>
              ))}
            </div>
          )}
          <div data-testid="email-preview-section" className="email-content">
            {data.content && <Iframe content={data.content} />}
          </div>
        </div>
      </Spin>
    </div>
  );
}

const Iframe = (props) => {
  const writeHTML = (frame) => {
    if (!frame) {
      return;
    }
    let doc = frame.contentDocument;
    doc.open();
    doc.write(props.content);
    doc.close();
    frame.style.width = '100%';
    frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
  };
  return <iframe title="Email" src="about:blank" scrolling="no" frameBorder="0" ref={writeHTML} />;
};
