import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DocumentPreview from './DocumentsPreview';
import filesService from 'services/files.service';
import useAsyncFn from 'hooks/useAsyncFn';

export default function DocumentsPreviewPage() {
  const { documentId } = useParams();

  const [{ value: document = {} }, getDocument] = useAsyncFn(async () => {
    return await filesService.getDocumentById(documentId);
  }, [documentId]);

  const [{ value: review = {} }, getReview] = useAsyncFn(async () => {
    return await filesService.getDocumentReview(documentId);
  }, [documentId]);

  useEffect(() => {
    getDocument();
  }, [getDocument]);
  useEffect(() => {
    getReview();
  }, [getReview]);

  return (
    <DocumentPreview
      id={documentId}
      document={document}
      review={review}
      getDocument={async () => {
        await getDocument();
        await getReview();
      }}
    />
  );
}
