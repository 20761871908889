import axios from 'axios';
import config from 'config';
import { CHECKLIST_TYPES } from 'constants/index';
import { setupCache } from './interceptors';

const instance = axios.create({ baseURL: `${config.API_BASE_URL}/checklists` });
const request = setupCache(instance);

const api = {
  getChecklist: async (entityId, type = CHECKLIST_TYPES.PROVIDER) => {
    const { data } = await request.get('/items', { params: { entityId, type } });
    return data;
  },
  saveChecklist: async (entityId, type = CHECKLIST_TYPES.PROVIDER) => {
    const { data } = await request.post('/items', {
      entityId,
      type,
    });
    return data;
  },
  updateChecklist: async (entityId, type = CHECKLIST_TYPES.PROVIDER, items) => {
    const { data } = await request.put('/items', items, {
      params: {
        entityId,
        type,
      },
    });
    return data;
  },
};

export { request };
export default api;
