import React from 'react';
import { Checkbox } from 'antd';
import classNames from 'classnames';

export default function CheckboxField({
  id,
  checked,
  onChange,
  children,
  required = false,
  checkboxProps = {},
  textClassName,
  disabled,
}) {
  return (
    <div className={classNames('switch-field-container transition-all flex items-center min-h-[40px] relative')}>
      <Checkbox
        {...checkboxProps}
        onChange={onChange}
        checked={checked}
        id={id}
        className={classNames('items-center', checkboxProps.className)}
      >
        <span className={classNames('checkbox-label', textClassName)}>
          {required && <span className="required-star">*</span>} {children}
        </span>
      </Checkbox>
    </div>
  );
}
