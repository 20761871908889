import { useAsync } from 'react-async-hook';
import { commonService } from 'services';

export default function useInstallation({ id, programSponsorId, fallback = {} }) {
  const { result: installation, loading } = useAsync(async () => {
    if (!programSponsorId || !id) return fallback;
    const data = await commonService.get(`/common/military-installations?programSponsorId=${programSponsorId}`, {
      cache: { interpretHeader: false },
    });
    return data.find((item) => item.id === id) || fallback;
  }, [id, programSponsorId]);

  return { loading, installation };
}
