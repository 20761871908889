import React from 'react';
import AuthPage from './AuthPage';
import SignIn from './SignIn';
import SignUp from './SignUp';
import BranchSelection from './BranchSelection';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import ForgotUsername from './ForgotUsername';
import ProtectedRoute from 'components/ProtectedRoute';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import VerificationPage from './VerificationPage';

const signupActions = (route) => {
  const isFamily = route.includes('family');
  const signinPath = route.replace(/signup|_/g, 'signin');
  return (
    <div className="mt-4 flex justify-center space-x-3">
      <span>I already have an account?</span>
      <Link className="font-semibold underline" to={isFamily ? signinPath : '/signin'}>
        Sign in
      </Link>
    </div>
  );
};

const forgotPasswordActions = (
  <div className="mt-4 flex justify-center space-x-3">
    <span>Go back to</span>
    <Link className="font-semibold underline" to="/signin">
      Sign in
    </Link>
  </div>
);

const loader = <Loading theme="primary" layoutId="auth-component" style={{ minHeight: 200 }} />;

export default [
  <ProtectedRoute exact path="/signin" message="Navigated to Sign in Page" key="signin">
    <AuthPage className="sign-in" actions={null}>
      <React.Suspense fallback={loader}>
        <SignIn />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
  <ProtectedRoute exact path="/family/signup" message="Navigated to Sign up Page" key="signup-option">
    <React.Suspense fallback={loader}>
      <BranchSelection />
    </React.Suspense>
  </ProtectedRoute>,
  <ProtectedRoute exact path="/family/signup/:branchId" message="Navigated to Sign up Page" key="signup">
    <AuthPage actions={signupActions} formTitle="Sign up as Family User" className="sign-up">
      <React.Suspense fallback={loader}>
        <SignUp />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
  <ProtectedRoute exact path="/family/signin" message="Navigated to Family Sign in Page" key="family-signin">
    <AuthPage formTitle="Sign in as Family User" className="sign-in">
      <React.Suspense fallback={loader}>
        <SignIn />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
  <ProtectedRoute exact path="/provider/signup" message="Navigated to Sign up Page" key="provider-signup">
    <AuthPage actions={signupActions} formTitle="Sign up" className="sign-up">
      <React.Suspense fallback={loader}>
        <SignUp />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
  <ProtectedRoute exact path="/forgot-password" message="Navigated to Forgot Password Page" key="forgot-password">
    <AuthPage className="forgot-password">
      <React.Suspense fallback={loader}>
        <ForgotPassword />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
  <ProtectedRoute
    exact
    path="/reset-password/:username/:code"
    message="Navigated to Reset Password Page"
    key="reset-password"
  >
    <AuthPage
      actions={forgotPasswordActions}
      className="reset-password"
      description={`We have sent you an email containing secutrity code. It may take a minute to arrive.`}
    >
      <React.Suspense fallback={loader}>
        <ResetPassword />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
  <ProtectedRoute exact key="verification-page" path="/verify/:username/:code" message="Navigated to Verification Page">
    <AuthPage>
      <React.Suspense fallback={loader}>
        <VerificationPage />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
  <ProtectedRoute exact key="verification-page" path="/forgot-username" message="Navigated to Forgot Username">
    <AuthPage>
      <React.Suspense fallback={loader}>
        <ForgotUsername />
      </React.Suspense>
    </AuthPage>
  </ProtectedRoute>,
];
