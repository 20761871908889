import { Descriptions, Skeleton } from 'antd';
import useProgram from 'hooks/useProgram';
import numeral from 'numeral';
import React, { useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { familyService } from 'services';
import { formatDate } from 'utils';

export default function CertificateSummaryHeader({ id, householdId, application, loading }) {
  const { program } = useProgram({ id: application?.program?.id });
  const { result: totalAnnualIncome = 0 } = useAsync(async () => {
    if (!id) return 0;
    const { totalAnnualIncome } = await familyService.getApplicationIncome(id);
    return totalAnnualIncome;
  }, [id]);
  const applicationInfoItems = useMemo(
    () => [
      {
        key: 'household',
        label: 'Family ID',
        children: <span>{householdId}</span>,
      },
      {
        key: 'program',
        label: 'Program',
        children: <span>{program?.type?.title || 'N/A'}</span>,
      },
      {
        key: 'status',
        label: 'Status',
        children: application?.status?.status?.description || 'N/A',
      },
      {
        key: 'familyIncome',
        label: 'Family Income',
        children: <span>{numeral(totalAnnualIncome || 0).format('$0,0.00')}</span>,
      },
      {
        key: 'submittedDate',
        label: 'Submission Date',
        children: <span>{formatDate(application.submittedDate)}</span>,
      },
      {
        key: 'applicationYear',
        label: 'Application Year',
        children:
          application?.startDate && application?.endDate
            ? `${formatDate(application?.startDate)} - ${formatDate(application?.endDate)}`
            : 'N/A',
      },
    ],
    [
      application?.endDate,
      application?.startDate,
      application?.status?.status?.description,
      application.submittedDate,
      householdId,
      program?.type?.title,
      totalAnnualIncome,
    ],
  );
  return (
    <div className="px-6 pb-6 -mt-4">
      <Skeleton avatar loading={loading} paragraph={{ rows: 4 }}>
        <div className="mt-4 mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Application ID:</span>{' '}
                <span className=" text-primary font-medium">{id}</span>
              </p>
            }
            size="small"
            items={applicationInfoItems}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {applicationInfoItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}
