import React, { useCallback, useEffect } from 'react';
import produce from 'immer';
import { message, Spin } from 'antd';
import { useAsync } from 'react-async-hook';

import Form from 'components/Form';
import Actions from 'containers/Family/AddApplication/Actions';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import { deepClone } from 'utils';
import useHasRole from 'hooks/useHasRole';

import { ADD_FAMILY_STEPS, ROLES } from 'constants/index';
import { EligibilitySection } from '../Eligibility';

export default function Pograms({ id, setStep, next, household, application, dispatch, actions, onCancel, loading }) {
  const isFamily = useHasRole(ROLES.FAMILY);
  const [form] = Form.useForm();
  const applicant = application?.applicant || {};

  const { loading: programTypesLoading, result: programTypes = [] } = useAsync(
    async (branchId, componentId, statusId) => {
      if (branchId && componentId && statusId) {
        return await commonService.getProgramTypes(branchId, componentId, statusId);
      }

      return [];
    },
    [
      applicant?.position?.programSponsorId,
      applicant?.position?.militaryComponentId,
      applicant?.position?.militaryStatusId,
    ],
  );

  const { result: programs = [] } = useAsync(async () => {
    return await commonService.get(`/common/programs?active=true`, { cache: { interpretHeader: false } });
  }, []);

  useEffect(() => {
    if (application?.program?.id) {
      const program = (programs || []).find((prog) => prog.id === application?.program?.id);
      form.setFieldValue('programTypeId', program?.type?.id);
    }
    // programTypes dependency is required
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application?.applicant?.id, application?.program?.id, programs, programTypes]);

  const isEligibilityChanged = useCallback(
    (values) => {
      if (!application?.applicant?.id) return false;
      const { programSponsorId, militaryComponentId, militaryStatusId } = { ...values.applicant.position };
      const {
        programSponsorId: programId,
        militaryComponentId: milCompId,
        militaryStatusId: milStatusId,
      } = { ...application.applicant.position };
      if (programSponsorId !== programId || militaryComponentId !== milCompId || militaryStatusId !== milStatusId) {
        return true;
      }
      return false;
    },
    [application.applicant],
  );

  const onSubmit = useCallback(
    async (values) => {
      values = deepClone(values);
      dispatch(actions.setIsFormSubmitting(true));
      try {
        if (isFamily) {
          // any actions specific to family
          const { programTypeId } = values;
          const program = programs.find(
            (prog) => prog.type.id === programTypeId && prog.sponsor.id === applicant?.position?.programSponsorId,
          );
          delete values.programTypeId;
          values = {
            ...values,
            applicant: { id: values.applicantId },
            program,
            id,
          };
          if (id && program?.id && application.program?.id !== program?.id) {
            // if program changed, update program via separate api
            await familyService.updatePrograms(id, program?.id);
          }
        } else {
          values = produce({ id: application.id, ...values }, (draft) => {
            draft.applicant = {
              ...(application.applicant || {}),
              ...(values.applicant || {}),
            };
            draft.id = application.id;
          });

          if (isEligibilityChanged(values) && application?.id) await familyService.deleteProgram(application.id);
        }
        const data = await familyService.saveApplication(values);
        dispatch(actions.setApplication(data));
        next({
          paths: { step: ADD_FAMILY_STEPS.PROGRAMS, id: data.id },
          applicationId: data.id,
        });
      } catch (error) {
        message.error(`Unable to ${application?.applicant?.id ? 'update' : 'create'} Sponsor.`);
        newrelic.noticeError(error);
      } finally {
        dispatch(actions.setIsFormSubmitting(false));
      }
    },
    [
      actions,
      applicant?.position?.programSponsorId,
      application.applicant,
      application.id,
      application.program?.id,
      dispatch,
      id,
      isEligibilityChanged,
      isFamily,
      next,
      programs,
    ],
  );

  return (
    <div className="white-box h-full [&_ol]:list-decimal [&_ul]:list-disc [&_hr]:my-5 [&_h4]:text-sm [&_h4]:font-semibold [&_h4]:uppercase [&_h4]:tracking-widest [&_h4]:text-primary [&_h4]:leading-4 [&_h4]:mb-5 [&_br]:last:hidden">
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        {applicant?.id && (
          <Spin spinning={programTypesLoading}>
            <EligibilitySection
              form={form}
              programTypes={programTypes}
              programTypesLoading={programTypesLoading}
              application={{ ...application, applicant }}
              householdId={household?.id}
            />
          </Spin>
        )}

        <Actions
          onBack={() => {
            setStep(id, ADD_FAMILY_STEPS.START);
          }}
          testId={`${ADD_FAMILY_STEPS.SPONSOR}`}
        />
      </Form>
    </div>
  );
}
