import React, { useEffect } from 'react';
import { Checkbox, Button, Spin, message } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useSelector, useDispatch } from 'react-redux';
import { actions, selectors } from 'features/family';

import familyService from 'services/family.service';
import Form from 'components/Form';

export default function SpecialNeeds({
  householdId,
  childId,
  setVisible,
  onClose,
  isFamilyUser,
  reload,
  visibleChildModal,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const application = useSelector(selectors.selectApplication);

  const { result: specialNeeds = [] } = useAsync(async () => {
    try {
      if (householdId && childId) {
        return await familyService.getChildSpecialNeeds(householdId, childId);
      }
      return [];
    } catch (error) {}
  }, [householdId, childId]);

  const { loading: updatingSpecialNeeds, execute: addUpdateChildSpecialNeeds } = useAsyncCallback(
    async (values, lastActionTpe) => {
      try {
        const updatedSpecialNeeds = specialNeeds.map((item, index) => ({
          ...item,
          childId,
          selected: values?.[index]?.selected,
        }));
        await familyService.addUpdateChildSpecialNeeds(householdId, childId, updatedSpecialNeeds);
        dispatch(actions.setApplication({ children: [...application?.children] }));
        // await reload();
        message.success('Child updated successfully.', 5);
        if (isFamilyUser) {
          onClose(lastActionTpe);
        }
      } catch (error) {
        message.error('Something went wrong.', 5);
        newrelic.noticeError(error);
      }
    },
    [],
  );

  useEffect(() => {
    form.setFieldsValue({ ...specialNeeds });
  }, [specialNeeds, form]);

  return (
    <Form form={form} layout="vertical" onFinish={addUpdateChildSpecialNeeds}>
      <Spin spinning={updatingSpecialNeeds}>
        <div className="mb-4">
          <span className="section-title">Step 4: Enter Child's special Needs information</span>
        </div>
        <span className="section-title block mb-4">Special Needs</span>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-x-4">
          {specialNeeds?.map((item, index) => (
            <>
              <Form.Item name={[index, 'selected']} valuePropName="checked">
                <Checkbox>{item?.specialNeed?.title}</Checkbox>
              </Form.Item>
            </>
          ))}
        </div>
        <div className="actions [&_.ant-btn]:min-w-max  flex mt-5">
          <Button onClick={() => setVisible(false)} data-testid="cancel-school-information-btn">
            Back
          </Button>
          {isFamilyUser ? (
            <>
              <Button
                onClick={() => {
                  const values = form.getFieldsValue();
                  addUpdateChildSpecialNeeds(values, 'addingNewChild');
                }}
                data-testid="save-reservation-btn"
                disabled={updatingSpecialNeeds}
                loading={updatingSpecialNeeds}
              >
                Save and Add Another Child
              </Button>
              <Button
                onClick={() => {
                  const values = form.getFieldsValue();
                  addUpdateChildSpecialNeeds(values, 'addingRelationship');
                }}
                type="primary"
                data-testid="save-reservation-btn"
                disabled={updatingSpecialNeeds}
                loading={updatingSpecialNeeds}
              >
                Save and Add Relationships
              </Button>
            </>
          ) : (
            <Button
              onClick={() => {
                const values = form.getFieldsValue();
                addUpdateChildSpecialNeeds(values, '');
              }}
              type="primary"
              data-testid="save-reservation-btn"
              disabled={updatingSpecialNeeds}
              loading={updatingSpecialNeeds}
            >
              Save
            </Button>
          )}
        </div>
      </Spin>
    </Form>
  );
}
