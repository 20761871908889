import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { motion } from 'framer-motion';
import LocationCard from 'components/LocationCard';

import styles from './Marker.module.less';
import { getCompletedAddress } from 'utils';

// Must be a class based component to work with Google Map Component
export default class Marker extends React.PureComponent {
  render() {
    const { onClick, isActive, focused, id, point, handleDirections, setProvider } = this.props;
    const address = point?.address;
    const fullAddress = getCompletedAddress(address);

    return (
      <div
        data-testid={id}
        tabIndex={-1}
        id={id}
        className={classNames(styles.marker, { [styles.active]: isActive })}
        onClick={(e) => {
          e.stopPropagation();
          onClick(e, point);
        }}
      >
        {isActive || focused ? (
          <img src="/images/tracker_icon.png" alt="pointer" className="pointer active" />
        ) : (
          <img src="/images/pointer-dotless.png" alt="pointer" className="pointer" />
        )}
        <img src="/images/tracker_icon_2.png" alt="dot" className="pointer-dot" />

        <motion.div
          className={`pointer-detail-container ${isActive ? 'active' : ''}`}
          animate={isActive ? { opacity: 1, height: 'auto', left: 210, y: -120 } : { opacity: 0, height: 0 }}
          transition={{ delay: isActive ? 0.5 : 0 }}
          title={fullAddress}
        >
          <LocationCard {...point} visible={isActive} setProvider={setProvider} handleDirections={handleDirections} />
        </motion.div>
      </div>
    );
  }
}

Marker.propTypes = {
  id: PropTypes.any,
  isActive: PropTypes.any,
  point: PropTypes.object,
  onClick: PropTypes.func,
  handleDirections: PropTypes.func,
  setProvider: PropTypes.func,
  lat: PropTypes.number,
  lng: PropTypes.number,
  focused: PropTypes.bool,
};
