import React, { useState } from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export default function SecureInput({ className, ...props }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Input
      {...props}
      autoComplete="off"
      className={classNames(className, { 'password-field': !showPassword })}
      suffix={
        showPassword ? (
          <AiOutlineEye size={20} className="text-gray-500 cursor-pointer" onClick={() => setShowPassword(false)} />
        ) : (
          <AiOutlineEyeInvisible
            size={20}
            className="text-gray-500 cursor-pointer"
            onClick={() => setShowPassword(true)}
          />
        )
      }
      style={{ borderRadius: '2px' }}
    />
  );
}
