import React from 'react';

import { hoursRenderer } from 'utils';
import { DAYS_OF_WEEK } from 'constants/index';
import Table from 'components/Table';

export default function ProviderTable({ providersData }) {
  const allColumns = [
    ...DAYS_OF_WEEK.map((day, index) => ({
      title: <span className="action-header">{day.shortName}</span>,
      headerText: 'Mon',
      key: 'monday',
      dataIndex: ['days', index],
      align: 'left',
      width: 62,
      className: 'truncate max-w-xs',
      render: hoursRenderer,
    })),
  ];
  if (providersData.length > 0) {
    return <Table allColumns={allColumns} data={providersData} rowKey={(record) => record?.id} pagination={false} />;
  }
  return <div />;
}
