import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { FaMapMarkerAlt } from 'react-icons/fa';

import styles from './PlaceAutoComplete.module.less';

export const US_COUNTRY_CODES = ['us', 'pr', 'as', 'gu', 'vi', 'mp'];

export default function PlaceAutoComplete({
  id,
  placeholder,
  onChange,
  value,
  setLoading: setPlaceLoading,
  className,
  styles: selectStyles,
}) {
  const service = useRef(new google.maps.places.AutocompleteService());
  const geocoder = useRef(new google.maps.Geocoder());
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const getGeoCodes = useCallback(async (placeId) => {
    return new Promise((resolve, reject) => {
      geocoder.current.geocode({ placeId }, (results, status) => {
        if (status === 'OK' && results.length > 0) {
          const [result] = results;
          const place = {
            ...result,
            point: { lat: result.geometry.location.lat(), lng: result.geometry.location.lng() },
          };
          resolve(place);
        } else {
          reject(null);
        }
      });
    });
  }, []);

  const getPlace = useCallback(
    async (placeId) => {
      try {
        const place = await getGeoCodes(placeId);
        setPlace(place);
        onChange(place);
        setIsInitialized(true);
      } catch (error) {
        message.error('Unable to find requested place.');
      }
    },
    [getGeoCodes, onChange],
  );

  useEffect(() => {
    if ((value?.placeId || value?.place_id || (typeof value === 'string' && value)) && !place && !isInitialized) {
      getPlace(typeof value === 'string' ? value : value?.placeId || value?.place_id);
    }
  }, [value, place, isInitialized, onChange, getPlace]);

  const onClear = useCallback(() => {
    onChange(undefined);
    setPlace(null);
  }, [onChange]);

  const onTextChange = useCallback(
    async (value) => {
      if (value) {
        if (value.length > 25 && /\s/g.test(value) === false) {
          const place = await getGeoCodes(value);
          return [
            { description: 'Current Location', id: 'current-location' },
            { ...place, description: place.formatted_address },
          ];
        }
        return new Promise((res) => {
          service.current.getPlacePredictions(
            { input: value, componentRestrictions: { country: US_COUNTRY_CODES } },
            (results, status) => {
              if (status !== google.maps.places.PlacesServiceStatus.OK || !results) {
                message.error('No record found');
                return;
              }
              res([...results]);
            },
          );
        });
      } else {
        onClear();
        return [{ description: 'Current Location', id: 'current-location' }];
      }
    },
    [getGeoCodes, onClear],
  );
  const getMyLocation = async () => {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { coords } = position;
          geocoder.current.geocode({ location: { lat: coords.latitude, lng: coords.longitude } }, (results, status) => {
            if (status === 'OK' && results.length > 0) {
              resolve(results[0]);
              return;
            }
            resolve(null);
            message.error('Oops! Unable to find your location.', 5);
          });
        },
        (error) => {
          if (error?.message === 'User denied Geolocation') {
            message.error('Enable browser geolocation services.', 5);
          } else {
            message.error('Oops! Unable to find your location.', 5);
          }
          // message.error('Unable to get your location. You can\'t use "distance" filter');
          resolve(null);
        },
      );
    });
  };

  const onSelect = useCallback(
    async (v) => {
      if (v?.id || v === null) {
        setPlace(v);
        onChange(v);
      }
      if (v?.id === 'current-location') {
        setLoading(true);
        setPlaceLoading(true);
        const place = await getMyLocation();
        if (place) {
          const value = {
            ...place,
            point: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() },
          };
          setPlace(value);
          onChange(value);
        } else {
          setPlace(null);
          onChange(undefined);
        }
        setLoading(false);
        setPlaceLoading(false);
      } else if (v?.place_id) {
        const value = await getGeoCodes(v?.place_id);
        setPlace(value);
        onChange(value);
      }
    },
    [setPlaceLoading, onChange, getGeoCodes],
  );

  return (
    <div className={`place-auto-complete ${styles.placeAutoComplete} ${className}`}>
      <AsyncSelect
        cacheOptions
        defaultOptions
        value={place}
        isLoading={loading}
        getOptionLabel={(e) => e.description || e.formatted_address}
        getOptionValue={(e) => e}
        loadOptions={onTextChange}
        onChange={onSelect}
        isClearable
        inputId={id}
        placeholder={placeholder}
        classNamePrefix="select"
        components={{
          MenuList,
          Menu,
          Option,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={selectStyles}
      />
    </div>
  );
}

PlaceAutoComplete.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
};
PlaceAutoComplete.defaultProps = {
  placeholder: 'Search a location...',
  setLoading: () => {},
};

const Option = (props) => {
  const children = (
    <>
      <span className="icon-box">
        <FaMapMarkerAlt className="icon" />
      </span>{' '}
      <span className="suggestion-text" title={props.data.description}>
        {props.data.description}
      </span>
    </>
  );
  return (
    <components.Option {...props} className={`${props.className} suggestion-item`}>
      {children}
    </components.Option>
  );
};

Option.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};
const Menu = (props) => {
  return (
    <components.Menu {...props} className={`${props.className} suggestions-box`}>
      {props.children}
    </components.Menu>
  );
};
Menu.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
const MenuList = (props) => {
  return (
    <components.MenuList {...props} className={`${props.className} suggestions-list`}>
      {props.children}
    </components.MenuList>
  );
};

MenuList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};
