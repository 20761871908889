import { useEffect, useMemo, useState } from 'react';
import { Button, message, Spin } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import { useDispatch } from 'react-redux';

import ScheduleTable from 'containers/Family/AddApplication/Steps/ChildrenInfo/ScheduleTable';
import Tabs from 'components/Tabs';
import Modal from 'components/Modal';
import Form from 'components/Form';
import SwitchField from 'components/Switch/SwitchField';
import familyService from 'services/family.service';
import { actions } from 'features/family';

export default function ChildScheduleContent(props) {
  const { application, child: selectedChild, childId, householdId } = props;
  const [activeTab, , setActiveTab] = useState('0');
  const tabs = useMemo(() => {
    const tabs = Array.from(props?.child?.schedules ?? [{}]).map((child, index) => ({
      label: `Schedule ${index + 1}`,
      key: `${index}`,
      content: (
        <>
          <ScheduleTable
            applicationId={application?.id}
            childSchedules={selectedChild?.schedules?.[index] ? [selectedChild?.schedules?.[index]] : []}
            childId={childId}
            showAddButton={false}
          />
          <ProviderScheduleEligibilityModal
            schedule={selectedChild?.schedules?.[index]}
            applicationId={application?.id}
            childId={childId}
            application={application}
            householdId={householdId}
          />
        </>
      ),
    }));

    return tabs;
  }, [application, childId, householdId, props?.child?.schedules, selectedChild?.schedules]);

  return (
    <div className="p-6">
      <Tabs items={tabs} activeKey={activeTab || '0'} onTabClick={setActiveTab} className="full-width-tabs bordered" />
    </div>
  );
}

function ProviderScheduleEligibilityModal({ schedule, applicationId, childId, application, householdId }) {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const childSchedule = useMemo(
    () => ({
      ...schedule,
    }),
    [schedule],
  );
  useEffect(() => {
    form.setFieldsValue({ ...childSchedule });
  }, [form, childSchedule]);

  const { loading: savingScheduleEligibility, execute: saveScheduleEligibility } = useAsyncCallback(
    async (values) => {
      try {
        values = { id: childSchedule.id, ...values };
        await familyService.saveSchedule(applicationId, childId, values);
        const children = await familyService.getChildren(householdId);
        dispatch(actions.setApplication({ ...application, children }));
        setVisible(false);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to save schedule.', 5);
      }
    },
    [applicationId, childId, application, householdId, childSchedule],
  );

  return (
    <div className="p-6">
      {' '}
      <Button onClick={() => setVisible(true)}> Schedule Eligibility</Button>
      <Modal width={770} visible={visible} setVisible={setVisible}>
        <Spin spinning={savingScheduleEligibility}>
          <Form form={form} onFinish={saveScheduleEligibility} preserve>
            <h3 className="section-title">Select Provider Eligibility</h3>
            <div className="pl-6 py-6">
              <Form.Item name="eligible" valuePropName="checked" className="mb-2">
                <SwitchField>Schedule of Care is eligible</SwitchField>
              </Form.Item>
              <Form.Item name="providerEligible" valuePropName="checked" className="mb-2">
                <SwitchField>Child is Eligible to use this provider</SwitchField>
              </Form.Item>
              <Form.Item name="caseReviewRequired" valuePropName="checked" className="mb-2">
                <SwitchField>Case Review Required</SwitchField>
              </Form.Item>
              <div className="actions flex mt-8">
                <Button
                  onClick={() => setVisible(false)}
                  loading={savingScheduleEligibility}
                  disabled={savingScheduleEligibility}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={savingScheduleEligibility}
                  disabled={savingScheduleEligibility}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}
