import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { AiOutlineFlag } from 'react-icons/ai';

import Table from 'components/Table';
import ColumnTitle from 'components/ColumnTitle';
import { formatDate, getFullName, getProgramsColumn } from 'utils';
import { actions as dashboardActions } from 'features/dashboard';
import { commonService } from 'services';

export default function DashboardTable({
  state,
  colKeys,
  isFamilyDashbaord,
  scroll,
  rowKey,
  rowClassName,
  loading,
  hideSelection,
  onRow,
  breakpoints,
  hidePinAction,
  pinApplication,
  mapper = (data) => data,
  className,
  ...rest
}) {
  const dispatch = useDispatch();
  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys ?? [],
    onChange: (keys) => {
      dispatch(dashboardActions.setSelectedRowKeys(keys));
    },
    // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
  };
  const data = useMemo(() => (state.data || []).map(mapper), [mapper, state.data]);
  const { result: programs = [] } = useAsync(async () => {
    return commonService.getPrograms();
  }, []);
  const allColumns = useMemo(
    () => getDashboardColumns({ colKeys, isFamilyDashbaord, breakpoints, hidePinAction, pinApplication, programs }),
    [breakpoints, colKeys, hidePinAction, isFamilyDashbaord, pinApplication, programs],
  );
  return (
    <div className={className}>
      <Table
        {...rest}
        allColumns={allColumns}
        rowKey={rowKey}
        data={data}
        loading={state.loading || loading}
        pagination={state.pagination}
        onChange={state.onChange}
        rowClassName={rowClassName}
        rowSelection={hideSelection ? undefined : rowSelection}
        expandable={{
          rowExpandable: () => false,
          showExpandColumn: false,
        }}
        onRow={onRow}
        scroll={scroll}
        showColSeparator={false}
      />
    </div>
  );
}

DashboardTable.defaultProps = {
  hidePinAction: false,
  scroll: {
    x: 1200,
  },
  rowKey: (row) => row.id,
  rowClassName: (record, index) =>
    classNames('cursor-pointer !h-16  [&_.ant-table-cell]:!align-middle', {
      'bg-white': index % 2 === 0,
      'bg-table-row': index % 2 !== 0,
    }),
};

function getDashboardColumns({
  colKeys,
  isFamilyDashbaord,
  breakpoints,
  hidePinAction,
  pinApplication,
  programs = [],
}) {
  let statusLastUpdatedAtIndex = ['application', 'status', 'lastModifiedDate'];
  if (isFamilyDashbaord) {
    statusLastUpdatedAtIndex = ['status', 'lastModifiedDate'];
  }
  let colMapping = {
    businessLegalName: {
      title: (props) => (
        <ColumnTitle
          {...props}
          title="Provider Name"
          dataIndex="businessLegalName"
          colKey="businessLegalName"
          showSorting
        />
      ),
      headerText: 'Provider Name',
      dataIndex: 'businessLegalName',
      key: 'businessLegalName',
      sorter: true,
      fixed: 'left',
      width: 200,
      className: 'truncate max-w-xs',
    },
    providerId: {
      title: (props) => <ColumnTitle {...props} title="Provider ID" dataIndex="id" colKey="providerId" showSorting />,
      headerText: 'Provider ID',
      dataIndex: 'id',
      key: 'providerId',
      sorter: true,
      fixed: 'left',
      width: 140,
      render: (id, record) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          className="!text-black hover:underline px-3 py-1"
          to={`/providers/applications/${record.id}`}
        >
          {id}
        </Link>
      ),
    },
    providerStreet: {
      title: (props) => (
        <ColumnTitle {...props} colKey="providerStreet" title="Street" dataIndex={['address', 'street']} />
      ),
      headerText: 'Street',
      dataIndex: ['address', 'street'],
      key: 'providerStreet',
      width: 200,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (value, record) => {
        return value ? (
          <p className="truncate max-w-xs" title={value}>
            {value}
          </p>
        ) : (
          'N/A'
        );
      },
    },
    providerCity: {
      title: (props) => <ColumnTitle {...props} colKey="providerCity" title="City" dataIndex={['address', 'city']} />,
      headerText: 'City',
      dataIndex: ['address', 'city'],
      key: 'providerCity',
      width: 120,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (value) => (value ? value : 'N/A'),
    },
    providerState: {
      title: (props) => (
        <ColumnTitle {...props} colKey="providerState" title="State" dataIndex={['address', 'state']} />
      ),
      headerText: 'State',
      dataIndex: ['address', 'state'],
      key: 'providerState',
      width: 90,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (value) => (value ? value : 'N/A'),
    },
    providerZip: {
      title: (props) => (
        <ColumnTitle {...props} colKey="providerZip" title="Zip" dataIndex={['address', 'postalCode']} />
      ),
      headerText: 'Zip',
      dataIndex: ['address', 'postalCode'],
      key: 'providerZip',
      width: 90,
      sorter: true,
      className: 'truncate max-w-xs',
      render: (value) => (value ? value : 'N/A'),
    },

    caseId: {
      title: (props) => <ColumnTitle {...props} title="App ID" dataIndex="id" colKey="caseId" showSorting />,
      headerText: 'App ID',
      dataIndex: 'id',
      key: 'caseId',
      sorter: true,
      width: 110,
      fixed: 'left',
      render: (id, record) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          className="!text-black hover:underline px-3 py-1"
          to={`/families/${record.householdId}/applications/${id}`}
        >
          {id}
        </Link>
      ),
    },
    familyId: {
      title: (props) => (
        <ColumnTitle {...props} title="Family ID" dataIndex={['householdId']} colKey="familyId" showSorting />
      ),
      headerText: 'Family ID',
      dataIndex: ['householdId'],
      key: 'familyId',
      sorter: true,
      width: 120,
      fixed: breakpoints.lg ? 'left' : undefined,
      render: (householdId) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          className="!text-black hover:underline px-3 py-1"
          to={`/families/${householdId}/applications`}
        >
          {householdId}
        </Link>
      ),
    },
    sponsor: {
      title: (props) => (
        <ColumnTitle {...props} title="Sponsor" dataIndex={['applicant', 'firstName']} colKey="sponsor" showSorting />
      ),
      headerText: 'Sponsor',
      dataIndex: ['applicant', 'firstName'],
      key: 'sponsor',
      sorter: true,
      className: 'truncate max-w-xs',
      render: (firstName, { applicant }) => {
        return (
          <span className="truncate max-w-xs" title={applicant ? getFullName(applicant) : undefined}>
            {getFullName(applicant)}
          </span>
        );
      },
      width: 170,
    },
    submittedDate: (testIdPrefix = '') => ({
      title: (props) => <ColumnTitle {...props} title="Submitted Date" colKey="submittedDate" showSorting />,
      headerText: 'Submitted Date',
      dataIndex: 'submittedDate',
      key: 'submittedDate',
      sorter: true,
      width: 180,
      render: (date) => {
        return <span>{formatDate(date)}</span>;
      },
    }),
    expiryDate: (testIdPrefix = '') => ({
      title: (props) => <ColumnTitle {...props} title="Expiry Date" colKey="expiryDate" />,
      headerText: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (date) => {
        return <span>{formatDate(date)}</span>;
      },
    }),
    assignedTo: () => {
      const dataIndex = isFamilyDashbaord ? ['assignedTo'] : ['application', 'assignedTo'];
      return {
        title: (props) => <ColumnTitle {...props} title="Case Owner" colKey="assignedTo" dataIndex={dataIndex} />,
        width: 170,
        headerText: 'Case Owner',
        dataIndex,
        key: 'assignedTo',
        render: (assignedTo) => {
          return getFullName(assignedTo) || 'Not Assigned';
        },
      };
    },
    status: () => {
      const dataIndex = isFamilyDashbaord
        ? ['status', 'status', 'description']
        : ['application', 'status', 'status', 'description'];
      return {
        title: (props) => <ColumnTitle {...props} colKey="status" title="Status" dataIndex={dataIndex} showSorting />,
        headerText: 'Status',
        dataIndex: dataIndex,
        key: 'status',
        width: 160,
        sorter: true,
        className: 'truncate max-w-xs',
        render: (status) => (status ? <span title={status}>{status}</span> : 'N/A'),
      };
    },
    daysInStatus: () => {
      return {
        title: (props) => (
          <ColumnTitle
            {...props}
            colKey="daysInStatus"
            title="Time In Status"
            dataIndex={statusLastUpdatedAtIndex}
            showSorting
          />
        ),
        headerText: 'Time In Status',
        dataIndex: statusLastUpdatedAtIndex,
        key: 'daysInStatus',
        width: 150,
        sorter: true,
        render: (datetime) => {
          return datetime ? dayjs.utc(datetime).fromNow(true) : 'N/A';
        },
      };
    },
    startDate: () => {
      const dataIndex = isFamilyDashbaord ? ['startDate'] : ['application', 'startDate'];
      return {
        title: (props) => (
          <ColumnTitle {...props} colKey="startDate" title="Start Date" dataIndex={dataIndex} showSorting />
        ),
        headerText: 'Start Date',
        dataIndex: dataIndex,
        key: 'startDate',
        width: 140,
        sorter: true,
        render: (date) => {
          return formatDate(date);
        },
      };
    },
    endDate: () => {
      const dataIndex = isFamilyDashbaord ? ['endDate'] : ['application', 'endDate'];
      return {
        title: (props) => (
          <ColumnTitle {...props} colKey="endDate" title="End Date" dataIndex={dataIndex} showSorting />
        ),
        headerText: 'End Date',
        dataIndex: dataIndex,
        key: 'endDate',
        width: 140,
        sorter: true,
        render: (date) => {
          return formatDate(date);
        },
      };
    },
    contact: {
      title: (props) => <ColumnTitle {...props} colKey="contact" title="Contact" dataIndex="contact" />,
      headerText: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      width: 200,
      render: (value) =>
        value ? (
          <div className="flex flex-col">
            <p>{value}</p>
          </div>
        ) : (
          'N/A'
        ),
    },
    reviewer: {
      title: (props) => (
        <ColumnTitle
          {...props}
          colKey="reviewer"
          title="Reviewer"
          dataIndex={isFamilyDashbaord ? 'reviewer' : ['application', 'reviewer']}
        />
      ),
      headerText: 'Reviewer',
      dataIndex: isFamilyDashbaord ? 'reviewer' : ['application', 'reviewer'],
      key: 'reviewer',
      width: 200,
      render: (reviewer) => getFullName(reviewer) || 'N/A',
    },
    eligibility: getProgramsColumn({ key: 'eligibility', width: 200, title: 'Programs' }),
    careFacilityType: {
      title: (props) => (
        <ColumnTitle
          {...props}
          colKey="careFacilityType"
          title="Facility Type"
          dataIndex={['careFacilityType', 'title']}
        />
      ),
      headerText: 'Facility Type',
      dataIndex: ['careFacilityType', 'title'],
      key: 'careFacilityType',
      width: 200,
      render: (value) =>
        value ? (
          <div className="flex flex-col">
            <p>{value}</p>
          </div>
        ) : (
          'N/A'
        ),
    },
    actions: {
      title: <span className="action-header mt-2 justify-center">Actions</span>,
      headerText: 'Actions',
      key: 'actions',
      width: 100,
      fixed: 'right',
      // eslint-disable-next-line react/display-name
      render: (text, record) => {
        return (
          <div className="actions-cell justify-center space-x-2">
            {!hidePinAction && (
              <Tooltip placement="top" title="Pin Application">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    pinApplication(record);
                  }}
                  icon={
                    <AiOutlineFlag
                      className={classNames({
                        pinned: record?.highPriority,
                      })}
                    />
                  }
                  className="icon-btn"
                  aria-label="Pin Application"
                  data-testid={`pin-button-${record.id}`}
                />
              </Tooltip>
            )}
          </div>
        );
      },
    },
  };
  if (isFamilyDashbaord) {
    colMapping = {
      ...colMapping,
      branchProgram: {
        title: (props) => <ColumnTitle {...props} title="Program" dataIndex="applicant" colKey="branchProgram" />,
        headerText: 'Program',
        dataIndex: ['program', 'name'],
        key: 'branchProgram',
        width: 190,
        className: 'truncate max-w-xs',
        render: (programName) => {
          // const filteredProgram = record?.program && programs?.find?.((item) => item.id === record.program);
          // if (!filteredProgram) return 'N/A';
          // const title = `${filteredProgram?.type?.title} (${filteredProgram?.sponsor?.title})`;
          // return filteredProgram ? <span title={title}>{title}</span> : 'N/A';
          return programName ? <span title={programName}>{programName}</span> : 'N/A';
        },
      },
    };
  }

  const allColumns = colKeys
    .map((key) => (typeof colMapping[key] === 'function' ? colMapping[key]() : colMapping[key]))
    .filter(Boolean);
  return allColumns;
}
