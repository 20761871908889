import React, { useMemo } from 'react';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { SponsorInfo, Forms } from './Tabs';
import useFamilyDisabledTabs from 'hooks/useFamilyDisabledTabs';

export default function SponsorPage({ id, tab, setStep, openSteps, ...rest }) {
  const disabledTabs = useFamilyDisabledTabs({ step: ADD_FAMILY_STEPS.SPONSOR, openSteps });
  const commonProps = useMemo(
    () => ({
      disabledTabs,
      setStep,
      id,
      tab,
      openSteps,
      ...rest,
    }),
    [disabledTabs, id, openSteps, rest, setStep, tab],
  );

  return (
    <div>
      <SponsorInfo {...commonProps} />

      <Forms {...commonProps} />
    </div>
  );
}
