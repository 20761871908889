import React, { useMemo, useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Spin } from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-async-hook';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import Table from 'components/Table';
import ColumnTitle from 'components/ColumnTitle';
import useTablePagination from 'hooks/useTablePagination';
import tableStyles from './AttendanceSheet.module.less';
import { formatDate, getFullName } from 'utils';
import Card from 'components/Card/Card';
import { Renew } from '@carbon/icons-react';

const statusMap = {
  INCOMPLETE: 'Not Submitted',
  PENDING: 'Submitted',
  SUBMITTED: 'Submitted',
  ASSIGNED: 'Received',
  RECEIVED: 'Received',
  VERIFYING_PROVIDER_ELIGIBILITY: 'Verifying Eligibility',
  QC: 'Verifying',
  MANAGER_REVIEW: 'Verifying',
  UNDER_REVIEW: 'Verifying',
  REVIEW_REQUIRED: 'Verifying',
  VERIFYING: 'Verifying',
  FINAL_REVIEW_COMPLETE: 'Approved',
  COMPLETED: 'Completed',
  QC_REJECTED: 'Verifying',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

const PAGE_SIZE = 10;

export default function CareFacilities() {
  const [expandedRows, setExpandedRows] = useState([]);

  const params = useMemo(
    () => ({
      current: 1,
      pageSize: PAGE_SIZE,
      sort: 'id,desc',
      statuses: [
        'PENDING',
        'ASSIGNED',
        'VERIFYING',
        'DOC_REVIEW_REQUIRED',
        'DOC_REVIEW',
        'MANAGER_DOC_REVIEW',
        'READY_FOR_QC',
        'QC_REVIEW',
        'QC_REJECTED',
        'APPROVED',
      ],
    }),
    [],
  );

  const mapper = useCallback((record) => {
    return {
      ...record,
      status: statusMap[record?.application?.status?.status?.title || 'INCOMPLETE'],
      street: record.address?.street,
      location: [record.address?.city, record.address?.state].filter((v) => v).join(', '),
    };
  }, []);
  const applications = useTablePagination({
    url: '/providers/care-facilities',
    params,
    mapper,
  });
  const reload = useCallback(async () => {
    return await applications.fetchMore({
      current: 1,
      pageSize: PAGE_SIZE,
    });
  }, [applications]);

  const allColumns = useMemo(
    () => [
      {
        title: (props) => <ColumnTitle {...props} title="Provider Id" dataIndex="id" showSorting colKey="id" />,
        headerText: 'Provider ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        width: 180,
        render: (id) => <span>{id}</span>,
      },
      {
        title: (props) => (
          <ColumnTitle {...props} title="Name" dataIndex="businessLegalName" showSorting colKey="businessLegalName" />
        ),
        headerText: 'Name',
        dataIndex: 'businessLegalName',
        key: 'businessLegalName',
        sorter: true,
        render: (businessLegalName) => <span>{businessLegalName || 'N/A'}</span>,
      },
      {
        title: (props) => <ColumnTitle {...props} title="Provider Address" dataIndex="location" colKey="location" />,
        headerText: 'Provider Address',
        dataIndex: 'location',
        key: 'location',
        render: (value, record) => {
          return record.address ? (
            <div className="flex flex-col">
              <span>{record.street}</span>
              <span>{value}</span>
            </div>
          ) : (
            'N/A'
          );
        },
      },
      {
        title: (props) => <ColumnTitle {...props} title="Last Modified Date" colKey="lastModifiedDate" showSorting />,
        headerText: 'Last Modified Date',
        dataIndex: ['lastModifiedDate'],
        key: 'lastModifiedDate',
        sorter: true,
        render: (date) => formatDate(date),
      },
      {
        title: (props) => <ColumnTitle {...props} title="Programs" dataIndex="application" colKey="eligibility" />,
        headerText: 'Eligibility Type / Facility',
        dataIndex: 'application',
        key: 'eligibility',
        width: 250,
        render: (value, center) => {
          const title = Object.entries({
            feeAssistance: 'Fee Assistance',
            respite: 'Respite',
            hcc: 'HCC',
            ihcc: 'IHCC',
            efm: 'EFM',
          }).reduce((prev, [key, title]) => {
            if (value[key]) {
              return prev ? `${prev}, ${title}` : title;
            }
            return prev;
          }, '');
          return (
            <div className="flex flex-col max-w-[250px]">
              <span className="text-black truncate" title={title}>
                {title}
              </span>
            </div>
          );
        },
      },
      {
        title: (props) => (
          <ColumnTitle {...props} showSorting title="Status" dataIndex="publicStatus" colKey="publicStatus" />
        ),
        headerText: 'Status',
        dataIndex: ['application', 'status', 'status', 'publicStatus'],
        key: 'publicStatus',
        sorter: true,
        render: (status) => <span className="font-semibold">{status.title || 'INCOMPLETE'}</span>,
      },
      {
        title: <span className="action-header mt-2 justify-center">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 90,
        render: (text, record, index) => {
          return (
            <div className="actions-cell bordered space-x-1 justify-end">
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                  } else {
                    setExpandedRows([record.id]);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                className="icon-btn"
                aria-label="Open"
                data-testid={`collapse-button-${record.id}`}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            </div>
          );
        },
      },
    ],
    [expandedRows],
  );

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: setSelectedRowKeys,
  // };
  return (
    <div className={classNames('layout-page')}>
      <div id="page-top-placeholder" />

      <div className="p-5">
        <Card
          noBodyPadding
          title="My Locations"
          extra={
            <Button
              icon={<Renew />}
              disabled={applications.loading}
              loading={applications.loading}
              onClick={reload}
              aria-label="Reload"
              title="Reload"
              data-testid="reload-btn"
            />
          }
        >
          <Spin spinning={applications.loading} className="w-full">
            <Table
              allColumns={allColumns}
              rowKey="id"
              loading={applications.loading}
              data={applications.data}
              // pagination={{ total: 25 }}
              pagination={applications.pagination}
              onChange={applications.onChange}
              size="large"
              xsCols={['businessLegalName', 'actions']}
              smCols={['id', 'businessLegalName', 'actions']}
              mdCols={['id', 'businessLegalName', 'actions']}
              lgCols={['id', 'businessLegalName', 'application', 'publicStatus', 'actions']}
              xlCols={['id', 'businessLegalName', 'location', 'application', 'publicStatus', 'actions']}
              xxlCols={[
                'id',
                'businessLegalName',
                'location',
                'application',
                'submittedDate',
                'eligibility',
                'publicStatus',
                'actions',
              ]}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <ChildrenTable
                      record={record}
                      onCancel={() => {
                        setExpandedRows([]);
                      }}
                    />
                  );
                },
                rowExpandable: () => true,
                expandedRowKeys: expandedRows,
                expandIcon: () => null,
                columnWidth: 0,
              }}
              rowClassName={(record, index) => {
                if (dayjs(record?.expirationDate).diff(dayjs(Date.now()).format('YYYY-MM-DD'), 'day') < 60) {
                  return '[&_td]:!bg-orange-200 cursor-pointer';
                }
                if (record.status === 'Rejected') return '[&_td]:!bg-red-200 cursor-pointer';
                return index % 2 === 0
                  ? '[&_td]:!bg-white h-9 cursor-pointer'
                  : '[&_td]:!bg-light-bg h-9 cursor-pointer';
              }}
              // onRow={(record) => {
              //   return {
              //     onClick: () => history.push(`/provider/application/${record.id}`),
              //   };
              // }}
            />
          </Spin>
        </Card>
      </div>
    </div>
  );
}

function ChildrenTable({ record }) {
  const { result, loading } = useAsync(async () => {
    return children;
  }, [record.id]);
  const history = useHistory();
  const allColumns = useMemo(
    () => [
      {
        title: 'Family ID',
        dataIndex: ['family', 'id'],
        key: 'familyId',
      },
      {
        title: 'Sponsor Name',
        dataIndex: ['sponsor', 'firstName'],
        key: 'sponsorName',
        render: (_, child) => getFullName(child.sponsor),
      },
      {
        title: 'Guardian Name',
        dataIndex: ['secondParent', 'firstName'],
        key: 'secondParent',
        render: (_, child) => getFullName(child.secondParent),
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: ['firsName'],
        render: (_, record) => {
          return getFullName(record);
        },
      },
      {
        title: 'Branch',
        key: 'branch',
        dataIndex: ['branch', 'title'],
      },
      {
        title: 'Program',
        key: 'program',
        dataIndex: ['program', 'title'],
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: ['actions'],
        render: (_, child) => {
          return (
            <Button size="small" onClick={() => history.push(`/provider/${record.id}/attendance-sheets/${child.id}`)}>
              View Attendance Sheets
            </Button>
          );
        },
        width: 150,
      },
    ],
    [history, record.id],
  );
  const colKeys = useMemo(() => allColumns.map((col) => col.key), [allColumns]);
  return (
    <div className="bg-white border-l-2 border-yellow-500 email-renderer px-4 py-4">
      <h3 className="text-primary text-15 uppercase font-medium">Care Location Children</h3>
      <Table
        className={tableStyles.table}
        data={result}
        loading={loading}
        allColumns={allColumns}
        xsCols={['subject', 'actions']}
        smCols={['subject', 'actions']}
        mdCols={['subject', 'actions']}
        lgCols={['name', 'subject', 'actions']}
        xlCols={colKeys}
        xxlCols={colKeys}
      />
    </div>
  );
}

const children = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    sponsor: { id: 2, firstName: 'Jim', lastName: 'Thomas' },
    secondParent: { id: 3, firstName: 'Kate', lastName: 'Lilliana' },
    family: {
      id: 4,
    },
    branch: {
      id: 5,
      title: 'Army',
    },
    program: {
      id: 6,
      title: 'MCCYN',
    },
  },
];
