import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import MaskedInput from 'react-maskedinput';

import styles from './GccTimePicker.module.less';
import { AM_PM_TIME_FORMAT_REGEX } from 'constants/index';
import { ClearIcon } from 'utils/icons';

export default function GccTimePicker({ className, value, onChange, onClear, ...props }) {
  const ref = useRef();
  const mask = useMemo(() => {
    const startsWithOne = value?.[0] === '1';
    // return `HH:51 mM`;
    return `H${startsWithOne ? 'h' : '1'}:51 mM`;
  }, [value]);
  const showClearIcon = useMemo(() => AM_PM_TIME_FORMAT_REGEX.test(value), [value]);
  const config = useMemo(
    () => ({
      H: {
        validate(char) {
          return /[0-1]/.test(char);
        },
      },
      h: {
        validate(char) {
          return /[0-2]/.test(char);
        },
      },
      5: {
        validate(char) {
          return /[0-5]/.test(char);
        },
      },

      m: {
        validate(char) {
          return /[ap]/i.test(char);
        },
        transform(char) {
          return char.toUpperCase();
        },
      },
      M: {
        validate(char) {
          return char === 'm' || char === 'M';
        },
        transform(char) {
          return 'M';
        },
      },
    }),
    [],
  );
  return (
    <div className={classNames(styles.gccTimePicker, 'w-full relative')}>
      <MaskedInput
        {...props}
        placeholder="hh:mm AM/PM"
        ref={ref}
        value={value}
        onChange={(e) => {
          let value = e.target.value;
          if (/a_|p_$/i.test(value)) {
            /* istanbul ignore else */
            if (!AM_PM_TIME_FORMAT_REGEX.test(ref.current.props.value)) {
              value = value.replace('_', 'M');
            }
          }
          onChange(value);
        }}
        className={classNames(className, 'ant-input')}
        mask={mask}
        formatCharacters={config}
      />
      {showClearIcon && (
        <ClearIcon
          onClick={() => {
            onChange('');
            ref.current.mask.setValue('');
            onClear && onClear();
          }}
          className={`text-gray-400 w-5 h-5 absolute top-2.5 right-2 cursor-pointer clear-icon`}
        />
      )}
    </div>
  );
}
