import React, { useMemo, useContext, useRef } from 'react';
import { Button, Divider, Drawer, message, Tabs } from 'antd';
import { useAsync } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

import CommunicationAlerts from 'components/CommunicationAlerts';
import LoadingPage from 'components/LoadingPage';
import ApplicationReview from '../ApplicationReview';
import ProtectedRoute from 'components/ProtectedRoute';
import ApplicationNavBar from 'components/ApplicationNavBar';
import ProviderContactCard from 'components/ProviderContactCard';
import ApplicationStatusCard from 'components/ApplicationStatusCard';
import { breakpointsContext, parseDocuments } from 'utils';
import providerService from 'services/providers.service';
import filesService from 'services/files.service';
import { selectors as centerSelectors, actions as centerActions } from 'features/add-center';
import { actions as documentActions } from 'features/documents';
import Alerts from 'components/Alerts';
import CaseCoordinatorActivity from '../CaseCoordinatorActivity';
import ProviderChecklist from 'components/Checklist/ProviderChecklist';
import ProviderProfile from 'containers/Provider/ProviderProfile';
import ApplicationEmails from 'components/ApplicationEmails';
import { ENTITY_TYPES } from 'constants/index';
import classNames from 'classnames';
import Card from 'components/Card/Card';
import Space from 'components/Space/Space';
import { ListCheckedMirror } from '@carbon/icons-react';
import CareFacilityFileManager from 'components/CareFacilityFileManager/CareFacilityFileManager';
import PageMeta from 'components/PageMeta/PageMeta';

export default function Application({ match }) {
  const { id } = match.params;
  const dispatch = useDispatch();
  const rootRef = useRef();
  const center = useSelector(centerSelectors.selectCenterState);
  const centerLoading = useSelector(centerSelectors.selectLoading);
  const breakpoints = useContext(breakpointsContext);

  useAsync(async () => {
    let data;
    try {
      dispatch(centerActions.setCenterInfo({}));
      dispatch(centerActions.setLoading(true));
      data = await providerService.getCenterById(id);
      dispatch(centerActions.setCenterInfo(data));
    } catch (error) {
      message.error('Unable to get application info.', 5);
      newrelic.noticeError(error);
    } finally {
      dispatch(centerActions.setLoading(false));
    }
    const allDocuments = await filesService.getDocuments({ entityId: id, entityType: 'CareFacility' });
    dispatch(documentActions.setAllDocuments(allDocuments));
    dispatch(documentActions.setDocuments(parseDocuments(allDocuments, 'documentType')));
  }, [id]);
  const navItems = useMemo(
    () => [
      {
        label: 'GCC Profile',
        key: 'profile',
        navigationKey: ['profile'],
        regex: /^\/providers\/applications\/\d+\/profile$/,
        to: `/providers/applications/${id}/profile`,
      },
      {
        label: 'Application',
        key: 'application',
        navigationKey: ['application'],
        regex: /^\/providers\/applications\/\d+$/,
        to: `/providers/applications/${id}`,
      },
      // {
      //   label: 'Staff',
      //   key: 'staff',
      //   navigationKey: ['staff'],
      //   regex: /^\/providers\/applications\/\d+\/staff$/,
      //   to: `/providers/applications/${id}/staff`,
      // },
      {
        label: 'Documents',
        key: 'documents',
        navigationKey: ['documents'],
        regex: /^\/providers\/applications\/\d+\/documents$/,
        to: `/providers/applications/${id}/documents`,
      },
      // {
      //   label: 'Messages',
      //   key: 'messages',
      //   navigationKey: ['history', 'messages'],
      //   regex: /^\/providers\/applications\/\d+\/messages$/,
      //   to: `/providers/applications/${id}/messages`,
      // },
      // {
      //   label: 'Alerts',
      //   key: 'alerts',
      //   navigationKey: ['history', 'alerts'],
      //   regex: /^\/providers\/applications\/\d+\/alerts$/,
      //   to: `/providers/applications/${id}/alerts`,
      // },
    ],
    [id],
  );

  return (
    <div
      className={classNames(`flex flex-col`, {
        // 'overflow-hidden': hiddenRootScroll,
        // 'overflow-auto': scrollEnabled && !hiddenRootScroll,
      })}
    >
      <PageMeta title="Application Details" />

      {centerLoading && <div className="sr-only" data-testid="test-loading-indicator"></div>}

      <ApplicationNavBar
        breakpoints={breakpoints}
        items={navItems}
        entity={center}
        toMail={center?.businessEmail}
        className="bg-light-bg"
      />

      <Card className="mb-8" title="Provider Contact Card" testId="contact-card-collapse-btn">
        <ProviderContactCard center={center} loading={centerLoading} breakpoints={breakpoints} />
      </Card>

      <div ref={rootRef} data-testid="content-start-point"></div>
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          <ProtectedRoute
            exact
            path="/providers/applications/:id/profile"
            message="Navigated to Application Overview Page"
          >
            <ProviderProfile />
          </ProtectedRoute>
          <ProtectedRoute exact path="/providers/applications/:id" message="Navigated to Application Overview Page">
            <ApplicationHome loading={centerLoading} rootRef={rootRef} />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/providers/applications/:id/review/:tab?"
            message="Navigated to Application Edit View"
          >
            <ApplicationReview loading={centerLoading} rootRef={rootRef} />
          </ProtectedRoute>
          <ProtectedRoute exact path="/providers/applications/:id/messages" message="Navigated to Messages Page">
            <ApplicationEmails className="mx-6 my-6" entityType={ENTITY_TYPES.CARE_FACILITY} entity={center} />
          </ProtectedRoute>
          <ProtectedRoute exact path={['/providers/applications/:id/alerts']} message="Navigated to Alerts Page">
            <Alerts className="px-6 py-6" entityType={ENTITY_TYPES.CARE_FACILITY} entityId={id} />
          </ProtectedRoute>
          <ProtectedRoute path="/providers/applications/:id/documents" message="Navigated to Documents Page">
            <div className="mb-48">
              <PageMeta title="Documents" />

              <CareFacilityFileManager center={center} readonly />
            </div>
          </ProtectedRoute>

          <Redirect to="/providers" />
        </Switch>
      </React.Suspense>
    </div>
  );
}

function ApplicationHome({ rootRef, history, match, loading }) {
  const { id } = match.params;
  const center = useSelector(centerSelectors.selectCenterState);
  const status = useMemo(() => center.application?.status, [center.application?.status]);
  const [checklistOpen, setChecklistOpen] = React.useState(false);

  return (
    <>
      <Divider />

      <Card
        className="bg-gray-100"
        noBodyPadding
        title={
          <span role="heading" aria-level={1}>
            Provider Application Dashboard
          </span>
        }
        extra={
          <Space>
            <Button
              icon={<ListCheckedMirror className="ml-[-2px] mt-[-1px]" />}
              onClick={() => {
                setChecklistOpen(true);
              }}
            >
              Checklist
            </Button>

            <Button
              type="primary"
              onClick={() => history.push(`/providers/applications/${id}/review`)}
              data-testid="application-review-btn"
            >
              Review
            </Button>
          </Space>
        }
      />

      <Card className="mt-6" title="My Application Status">
        <ApplicationStatusCard
          status={status}
          loading={loading}
          getPublicStatusList={providerService.getPublicStatusList}
        />
      </Card>

      <Card className="mt-6" title="Communication" testId="alerts-collapse-btn">
        <Tabs
          items={[
            {
              key: 'alerts',
              label: 'Alerts & Messages',
              content: (
                <div className="p-5">
                  <CommunicationAlerts id={Number(id)} />
                </div>
              ),
            },
          ]}
        />
      </Card>

      <div className="block mt-6 mb-16">
        <CaseCoordinatorActivity
          entity={center}
          entityType={ENTITY_TYPES.CARE_FACILITY}
          collapseHeaderClassName="py-4"
        />
      </div>

      <Drawer
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 0 }}
        open={checklistOpen}
        onClose={() => {
          setChecklistOpen(false);
        }}
      >
        <ProviderChecklist className="pb-4" center={center} />
      </Drawer>
    </>
  );
}
