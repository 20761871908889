import React, { useCallback, useState, useMemo, useContext, useEffect } from 'react';
import { Steps, Spin, message } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { ChildFormFields } from './ChildFamilyMembers';
import { breakpointsContext } from 'utils';
import AddChildAddress from './AddChildAddress';
import { selectActiveRole } from 'features/auth';
import ChildSpecialNeeds from './ChildSpecialNeeds';
import { ChildSchoolInformationFormFields } from './ChildSchoolInformation';
import Form from 'components/Form';
import { actions } from 'features/family';
import familyService from 'services/family.service';
import { FamilyRelationshipModal } from './AddFamilyRelationshipWizard';
import ChildFamilyRelation from './ChildFamilyRelation';

import { DATE_FORMAT, ROLES } from 'constants/index';

function getStepsForCoordinator(steps, current) {
  switch (current) {
    case 0:
      return [steps[0], steps[3], steps[4]];
    case 1:
      return [steps[1]];
    case 2:
      return [steps[2]];
    default:
  }
}

function getServiceBySteps(currentStep, householdId, personId, payload) {
  switch (currentStep) {
    case 0:
      return {
        service: () =>
          familyService.addUpdateFamilyChild(householdId, personId, personId ? { ...payload } : [{ ...payload }]),
      };

    case 1:
      return {
        service: () => familyService.addUpdateFamilyChild(householdId, personId, payload),
      };
    case 2:
      const _parsedStartDate = dayjs(payload.startDate);
      const _parsedEndDate = dayjs(payload.endDate);

      const data = {
        ...payload,
        id: payload.educationId,
        terms: [
          {
            grade: payload.grade,
            type: payload.type,
            startDate: payload.startDate && _parsedStartDate.isValid() ? _parsedStartDate.format(DATE_FORMAT[2]) : null,
            endDate: payload.endDate && _parsedEndDate.isValid() ? _parsedEndDate.format(DATE_FORMAT[2]) : null,
          },
        ],
      };

      payload = data?.id ? { ...data } : [{ ...data }];

      return {
        service: () => familyService.addUpdateSchoolInfo(personId, payload),
      };
    default:
  }
}
const AddChildFamilyMember = ({ reload, profileData, ...rest }) => {
  const [visibleAddChildWizard, setVisibleAddChildWizard] = useState(false);
  return (
    <div>
      <AddFamilyChildMemberModal
        visibleAddChildWizard={visibleAddChildWizard}
        setVisibleAddChildWizard={setVisibleAddChildWizard}
        reload={reload}
        profileData={profileData}
        {...rest}
      />
    </div>
  );
};

export default AddChildFamilyMember;

export const AddFamilyChildMemberModal = ({
  visibleAddChildWizard,
  setVisibleAddChildWizard,
  setVisible,
  reload,
  profileData,
  showFamilyRelationships = true,
  item,
  loading,
  currentStep: currentStepCoordinator,
}) => {
  const [visibleAddRelationshipWizard, setVisibleAddRelationshipWizard] = useState(false);
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [profileInfo, setProfileInfo] = useState({});
  const activeRole = useSelector(selectActiveRole);
  const isFamilyUser = useMemo(() => activeRole === ROLES.FAMILY, [activeRole]);

  const childSteps = useMemo(() => {
    let childSteps = [
      {
        title: <span className="text-xs">Basic Information</span>,
      },
      {
        title: <span className="text-xs">Home Address</span>,
        disabled: isFamilyUser,
      },
      {
        title: <span className="text-xs">School Information</span>,
        disabled: isFamilyUser,
      },
      {
        title: <span className="text-xs">Special Needs</span>,
        disabled: isFamilyUser,
      },
    ];
    if (!isFamilyUser) {
      childSteps.push({
        title: <span className="text-xs">Family Relationships</span>,
      });
    }
    return childSteps;
  }, [isFamilyUser]);

  const stepsData =
    activeRole === ROLES.FAMILY ? childSteps : getStepsForCoordinator(childSteps, currentStepCoordinator);
  const [steps, setSteps] = useState(stepsData);
  const [childInfoForm] = Form.useForm();
  const [childAddressForm] = Form.useForm();
  const [childSchoolForm] = Form.useForm();
  const { sm, md } = useContext(breakpointsContext);
  const child = useMemo(
    () => (item?.id ? item : currentStep === 0 ? null : profileData?.children?.[profileData?.children?.length - 1]),
    [item, profileData?.children, currentStep],
  );
  const { loading: addUpdateLoading, execute: onAddItem } = useAsyncCallback(
    async (payload, lastActionType) => {
      if (payload.homeAddress) {
        delete payload.homeAddress['id'];
      }
      const { service } = getServiceBySteps(
        currentStepCoordinator === 2 ? 2 : currentStep,
        profileData?.id,
        child?.id,
        payload,
      );
      const updatedData = await service();

      if (!isFamilyUser) {
        message.success('Child info updated.', 5);
        let tempChildren = [...profileData.children];
        const childIndex = tempChildren.findIndex((adult) => updatedData.id === adult.id);
        tempChildren[childIndex] = updatedData;
        dispatch(actions.setApplication({ children: tempChildren }));
        dispatch(actions.setHousehold({ ...profileData, children: tempChildren }));
      }
      setProfileInfo({ ...updatedData, ...profileInfo });
      if (isFamilyUser) {
        reload();
        setCurrentStep(currentStep + 1);
        if (currentStep === 3) {
          setCurrentStep(0);
          setVisibleAddChildWizard(false);
          await reload();
        } else {
          let tempSteps = [...steps];
          let tempStepIndex = tempSteps[currentStep + 1];
          tempStepIndex.disabled = false;
          tempSteps[currentStep + 1] = tempStepIndex;
          setSteps(tempSteps);
          setCurrentStep(currentStep + 1);
        }
      }
    },
    [currentStep, profileData?.id],
  );

  const onClose = useCallback(
    (lastActionType) => {
      setSteps(stepsData);
      setCurrentStep(0);

      setVisibleAddChildWizard(false);
      setVisible(false);
      if (lastActionType === 'addingRelationship') {
        setVisibleAddRelationshipWizard(true);
      } else if (lastActionType === 'addingNewChild') {
        setVisibleAddChildWizard(true);
      }
    },
    [setVisible, setVisibleAddChildWizard, stepsData],
  );

  useEffect(() => {
    childInfoForm.setFieldsValue({ ...item });
    childAddressForm.setFieldsValue({ ...item });
    childSchoolForm.setFieldsValue({ ...item });
  }, [childAddressForm, childInfoForm, childSchoolForm, item]);

  return (
    <Spin spinning={loading === true}>
      <div className="flex md:flex-row flex-col [&_.ant-steps]:w-full md:[&_.ant-steps]:w-1/4 mb-4">
        <Steps
          direction={sm && !md ? 'horizontal' : 'vertical'}
          className="mt-6 md:border-r"
          current={currentStep}
          onChange={(step) => setCurrentStep(step)}
          items={steps}
        />
        <div className="mt-6 w-full md:w-3/4  md:pl-10 md:pr-6">
          {
            <div
              className={classNames({
                hidden: isFamilyUser ? currentStep !== 0 : !(currentStep === 0 && currentStepCoordinator === 0),
              })}
            >
              <Form layout="vertical" onFinish={onAddItem} form={childInfoForm}>
                <ChildFormFields
                  addUpdateLoading={addUpdateLoading}
                  setVisible={onClose}
                  form={childInfoForm}
                  loading={loading}
                  isFamilyUser={isFamilyUser}
                />
              </Form>
            </div>
          }
          {
            <div
              className={classNames({
                hidden: isFamilyUser
                  ? currentStep !== 1 && isFamilyUser
                  : !(currentStep === 0 && currentStepCoordinator === 1),
              })}
            >
              <Form layout="vertical" onFinish={onAddItem} form={childAddressForm}>
                <AddChildAddress
                  addUpdateLoading={addUpdateLoading}
                  setVisible={() => setCurrentStep(currentStep - 1)}
                  profileData={profileData}
                  form={childAddressForm}
                  loading={loading}
                  isFamilyUser={isFamilyUser}
                />
              </Form>
            </div>
          }
          {
            <div
              className={classNames({
                hidden: isFamilyUser ? currentStep !== 2 : !(currentStep === 0 && currentStepCoordinator === 2),
              })}
            >
              <Form layout="vertical" onFinish={onAddItem} form={childSchoolForm}>
                <ChildSchoolInformationFormFields
                  addUpdateLoading={addUpdateLoading}
                  profileData={profileData}
                  setVisible={() => setCurrentStep(currentStep - 1)}
                  form={childSchoolForm}
                  childId={child?.id}
                  loading={loading}
                  isFamilyUser={isFamilyUser}
                />
              </Form>
            </div>
          }
          {
            <div
              className={classNames({
                hidden: isFamilyUser ? currentStep !== 3 : !(!isFamilyUser && currentStep === 1),
              })}
            >
              <ChildSpecialNeeds
                householdId={profileData?.id}
                childId={child?.id}
                setVisible={() => setCurrentStep(currentStep - 1)}
                onClose={onClose}
                reload={reload}
                visibleChildModal={setVisibleAddChildWizard}
                isFamilyUser={isFamilyUser}
              />
            </div>
          }
          {
            <div
              className={classNames({
                hidden: !(currentStep === 2 && !isFamilyUser),
              })}
            >
              <ChildFamilyRelation householdId={profileData?.id} profileData={profileData} childId={child?.id} />
            </div>
          }
        </div>
      </div>
      {showFamilyRelationships && (
        <FamilyRelationshipModal
          visible={visibleAddRelationshipWizard}
          setVisible={setVisibleAddRelationshipWizard}
          profileData={profileData}
          childId={child?.id}
        />
      )}
    </Spin>
  );
};
