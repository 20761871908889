import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Input, Skeleton, message, Spin, Divider } from 'antd';
import { useAsyncFn } from 'react-use';
import classNames from 'classnames';
import { IoCloseSharp } from 'react-icons/io5';
import debounce from 'lodash/debounce';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Table from 'components/Table';
import { getFullName } from 'utils';
import Select, { AsyncSelect } from 'components/Select';
import alertsService from 'services/alerts.service';
import usePaginatedApi from 'hooks/usePaginatedApi';
import styles from './AddAlerts.module.less';
import { useAsyncCallback } from 'react-async-hook';
import Space, { Spacer } from 'components/Space/Space';
import { UserFollow } from '@carbon/icons-react';

export default function AddAlert({ entityId, entityType, setVisible, className, reload, data, loading: dataFetching }) {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState(data);
  const [visibleAddWatcherModal, setVisibleAddWatcherModal] = useState(false);

  useEffect(() => {
    setAlert(data);
  }, [data]);
  useEffect(() => {
    form.setFieldsValue({ watchers: alert?.watchers || [] });
  }, [alert?.watchers, form]);
  const { loading: addingAlert, execute: createAlert } = useAsyncCallback(
    async (values) => {
      const { watchers } = values;
      delete values.watchers;
      try {
        const resp = await alertsService.createAlert({
          ...values,
          entityId,
          entityType,
        });
        if (watchers && watchers?.length > 0) {
          await alertsService.updateWatchers(resp?.id, watchers.map((watcher) => watcher.id).join(','));
        }
        setVisible(false);
        /* istanbul ignore else */
        if (typeof reload === 'function') {
          reload(resp);
        }
        message.success('Created an alert successfully.', 5);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to add an alert.', 5);
      }
    },
    [entityId, entityType],
  );

  const { loading: updatingAlert, execute: updateAlert } = useAsyncCallback(
    async (values) => {
      try {
        const resp = await alertsService.updateAlert(data.id, {
          ...values,
          id: data.id,
          entityId,
          entityType,
          type: values?.type,
        });
        setVisible(false);
        /* istanbul ignore else */
        if (typeof reload === 'function') {
          reload(resp);
        }
        message.success('Update alert successfully.', 5);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to update this alert.', 5);
      }
    },
    [data?.id, entityId, entityType],
  );

  const [{ loading: removingWatcher }, removeWatcher] = useAsyncFn(
    async (userId) => {
      try {
        const resp = await alertsService.removeWatchers(data.id, userId);
        form.setFieldsValue({ watchers: resp.watchers });
        setAlert(resp);
        /* istanbul ignore else */
        if (typeof reload === 'function') {
          reload(resp);
        }
        message.success('Watcher removed successfully.', 5);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to remove watcher.', 5);
      }
    },
    [data?.id],
  );

  return (
    <Skeleton loading={dataFetching} active paragraph={{ rows: 8 }}>
      <Form
        layout="vertical"
        onFinish={data?.id ? updateAlert : createAlert}
        form={form}
        initialValues={
          data?.id
            ? {
                ...data,
                highPriority: data.highPriority || false,
                watchers: data.watchers,
                type: data.type,
              }
            : {}
        }
        className={classNames('w-full', className)}
        data-testid="alerts-modal"
      >
        <Spin spinning={removingWatcher}>
          <div className="border-t border-gray-300 mt-4 mb-4 w-full" />
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
            <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Type is required.' }]}>
              <AsyncSelect optionsApiUrl="/alerts/types" apiPrefix="/alerts" placeholder="Select Alert Type" />
            </Form.Item>

            <Form.Item name="highPriority" label="Priority">
              <Select
                placeholder="Select Priority"
                options={[
                  { label: 'High', value: true },
                  { label: 'Low', value: false },
                ]}
                isOptionSelected={(option, [value]) => option?.value === value}
                getOptionLabel={(opt) => opt.label}
                onChangeFormatter={(v) => v?.value}
              />
            </Form.Item>
          </div>

          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Description is required.' }]}
          >
            <Input.TextArea autoSize={{ minRows: 6 }} placeholder="Write Here" autoComplete="off" />
          </Form.Item>

          <Form.Item name={['watchers']} className="border-t border-gray-400 border-b py-2 items-center">
            <Watchers {...{ removeWatcher, data, setVisibleAddWatcherModal, setAlert }} />
          </Form.Item>

          <div className="actions flex">
            <Button
              data-testid="cancel-button"
              onClick={() => {
                setVisible(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="submit-button"
              type="primary"
              loading={addingAlert || updatingAlert}
              disabled={addingAlert || updatingAlert}
              htmlType="submit"
            >
              {data?.id ? 'Update' : 'Create'} Alert
            </Button>
          </div>

          <AddWatchersModal
            visibleAddWatcherModal={visibleAddWatcherModal}
            setVisibleAddWatcherModal={(value) => setVisibleAddWatcherModal(value)}
            alertData={alert}
            setAlert={setAlert}
            reload={reload}
            parentForm={form}
          />
        </Spin>
      </Form>
    </Skeleton>
  );
}

const AddWatchersModal = ({
  visibleAddWatcherModal,
  setVisibleAddWatcherModal,
  alertData,
  setAlert,
  reload,
  parentForm,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [watcherIds, setWatcherIds] = useState(alertData?.watchers || []);
  const commonParams = useMemo(() => ({ query: '', page: 0, size: 5 }), []);
  const [{ loading: addingToWatcher }, addToWatcher] = useAsyncFn(async () => {
    const userIds = watcherIds.map((watcher) => watcher.id).join(',');

    if (alertData?.id) {
      const resp = await alertsService.updateWatchers(alertData?.id, userIds);
      parentForm.setFieldsValue({ watchers: resp.watchers });
      reload();
      return setVisibleAddWatcherModal(false);
    } else {
      parentForm.setFieldsValue({ watchers: watcherIds });
      return setVisibleAddWatcherModal(false);
    }
  }, [watcherIds]);

  useEffect(() => {
    setWatcherIds(alertData?.watchers);
  }, [alertData?.watchers]);

  const {
    data: users,
    loading,
    fetchMore,
    totalCount,
    page,
  } = usePaginatedApi({
    url: `/users/admin/internal/search/quick`,
    returnCurrentPage: true,
    commonParams,
  });

  const columns = useMemo(
    () => [
      {
        className: 'truncate max-w-xs',
        key: 'row',
        render: (user) => {
          const isAddedToWatcher = watcherIds?.find((watcher) => watcher.id === user.id);
          return (
            <div className="flex justify-between">
              <span className="text-xs leading-5">
                <span className="font-medium">{user ? getFullName(user) : 'N/A'}</span> <br />
                {user?.username} <br />
                {user?.userRole}
              </span>

              <Button
                onClick={() => {
                  if (isAddedToWatcher) {
                    const newWatchers = watcherIds.filter((watcher) => watcher.id !== user.id);
                    setWatcherIds(newWatchers);
                    setAlert({ ...alertData, watchers: newWatchers });
                  } else {
                    setWatcherIds((watchers) => {
                      const newWatchers = [...(watchers || []), { ...user }];
                      setAlert({ ...alertData, watchers: newWatchers });
                      return newWatchers;
                    });
                  }
                }}
                data-testid={`update-watcher-${user.id}`}
                data-type={isAddedToWatcher ? 'Remove' : 'Add'}
                danger={isAddedToWatcher}
              >
                {isAddedToWatcher ? 'Remove' : 'Add'}
              </Button>
            </div>
          );
        },
        width: 220,
      },
    ],
    [alertData, setAlert, watcherIds],
  );

  const onPageChange = useCallback(
    async (page, size) => {
      fetchMore({ page: page - 1, size, query: searchQuery });
    },
    [fetchMore, searchQuery],
  );
  const onChange = debounce(({ teamMember }) => {
    fetchMore({ page: 0, size: 5, query: teamMember });
  }, 500);

  return (
    <div>
      <Modal
        className={styles.watchers}
        closable={false}
        width={800}
        visible={visibleAddWatcherModal}
        setVisible={setVisibleAddWatcherModal}
        destroyOnClose
        title="Send Alerts to these team members"
      >
        <Spin spinning={addingToWatcher}>
          <Form layout="vertical" onValuesChange={onChange} onFinish={addToWatcher}>
            <Form.Item name="teamMember" label="Search for team members">
              <Input placeholder="Search member" onChange={(e) => setSearchQuery(e.target.value)} />
            </Form.Item>

            <Divider />

            <div className={classNames(styles.table, 'w-full')}>
              <Table
                className="bg-primary bg-opacity-3"
                allColumns={columns}
                data={users}
                loading={loading}
                pagination={{
                  defaultCurrent: 1,
                  defaultPageSize: 5,
                  current: page + 1,
                  pageSize: 5,
                  total: totalCount,
                  onChange: onPageChange,
                }}
                rowClassName={(record, index) => {
                  return 'h-20';
                }}
                expandable={{
                  rowExpandable: () => false,
                  expandIcon: () => null,
                  columnWidth: 0,
                }}
              />
            </div>

            <Divider />

            <Space>
              <Spacer />

              <Button data-testid="cancel-button" onClick={() => setVisibleAddWatcherModal(false)}>
                Cancel
              </Button>

              <Button data-testid="submit-update-watcher" type="primary" htmlType="submit">
                <Space size={1}>
                  <span>Send to</span>
                  {watcherIds?.length && <span>{watcherIds?.length}</span>}
                  <span>{watcherIds?.length === 1 ? 'Person' : ' People'}</span>
                </Space>
              </Button>
            </Space>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

function Watchers({ value: watchers, onChange, removeWatcher, data, setAlert, setVisibleAddWatcherModal }) {
  return (
    <div className="w-full flex">
      <div
        className={classNames('max-w-[calc(91%)] flex overflow-scroll', styles.hideScrollBar, {
          'mr-4': watchers?.length > 0,
        })}
      >
        {Array.from(watchers || []).map(({ firstName, lastName, id }) => (
          <div
            key={id}
            className="flex bg-primary-light rounded-3xl h-9 pl-4 pr-2 w-max px-0 mr-2 items-center whitespace-nowrap"
          >
            <span className="pr-4 whitespace-nowrap">{`${firstName} ${lastName}`}</span>{' '}
            <Button
              data-testid={`remove-watcher-${id}`}
              onClick={() => {
                if (data.id) {
                  removeWatcher(id);
                } else {
                  const newWatchers = watchers.filter((watcher) => watcher.id !== id);
                  onChange(newWatchers);
                  setAlert((alert) => ({ ...alert, watchers: newWatchers }));
                }
              }}
              aria-label={`${firstName} ${lastName}`}
              className="icon-btn"
              icon={<IoCloseSharp color={'#2e5bff'} className="font-medium" />}
            ></Button>
          </div>
        ))}
      </div>

      <Button
        data-testid="update-watcher-list"
        onClick={() => setVisibleAddWatcherModal(true)}
        type="primary"
        icon={<UserFollow />}
      >
        Add Watchers
      </Button>
    </div>
  );
}
