import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Steps, Button, message } from 'antd';
import { FaPlus } from 'react-icons/fa';

import { getFullName, breakpointsContext } from 'utils';
import AdultFamilyRelationship from './AdultFamilyRelationship';
import ChildFamilyRelation from './ChildFamilyRelation';
import Modal from 'components/Modal';

export default function ({ profileData }) {
  const [visibleAddRelationshipWizard, setVisibleAddRelationshipWizard] = useState(false);

  return (
    <>
      <Button
        className="w-full mt-6"
        icon={<FaPlus className="mr-2 text-primary" />}
        onClick={() => {
          if (profileData?.children?.length === 0 || profileData?.adults?.length === 0) {
            return message.error(
              'You must add at least one Adult and one Child for adding relationship between them.',
              5,
            );
          }
          setVisibleAddRelationshipWizard(true);
        }}
      >
        Add Family Relationships
      </Button>
      <FamilyRelationshipModal
        profileData={profileData}
        visible={visibleAddRelationshipWizard}
        setVisible={setVisibleAddRelationshipWizard}
      />
    </>
  );
}

export const FamilyRelationshipModal = ({ profileData, visible, setVisible, childId }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { sm, md } = useContext(breakpointsContext);
  const familyMembers = useMemo(
    () => [...(profileData?.adults || []), ...(profileData?.children || [])],
    [profileData?.adults, profileData?.children],
  );

  useEffect(() => {
    if (childId) {
      const index = familyMembers.findIndex((item) => item.id === childId);
      setCurrentStep(index);
    }
  }, [childId, familyMembers]);

  const steps = useMemo(
    () =>
      familyMembers?.map((member, index) => ({
        title:
          <span className={index === currentStep ? 'text-black' : 'text-gray-400'}>{getFullName(member)}</span> ||
          'No Name',
        icon: index === currentStep ? null : <></>,
        ...member,
      })),
    [familyMembers, currentStep],
  );
  const currentSelectedMember = useMemo(() => steps[currentStep], [currentStep, steps]);

  return (
    <Modal visible={visible} setVisible={setVisible} width={1400}>
      <div className="flex md:flex-row flex-col [&_.ant-steps_.ant-steps-item]:!overflow-visible [&_.ant-steps-item]:!flex [&_.ant-steps]:overflow-y-auto  [&_.ant-steps]:max-h-screen [&_.ant-steps]:w-full md:[&_.ant-steps]:w-1/4">
        <Steps
          direction={sm && !md ? 'horizontal' : 'vertical'}
          className="mt-6 md:border-r"
          current={currentStep}
          onChange={(changedStep) => {
            setCurrentStep(changedStep);
          }}
          labelPlacement={sm && !md ? 'vertical' : 'horizontal'}
          items={steps}
        />
        <div className="mt-6 w-full md:w-3/4 h-full">
          {currentSelectedMember?.hasOwnProperty('isEfmChild') ? (
            <ChildFamilyRelation
              householdId={profileData?.id}
              profileData={profileData}
              childId={currentSelectedMember?.id}
            />
          ) : (
            <AdultFamilyRelationship
              householdId={profileData?.id}
              profileData={profileData}
              personId={currentSelectedMember?.id}
            />
          )}
          <div className="flex actions mt-[40%]">
            <Button
              onClick={() => {
                if (currentStep === 0) {
                  setVisible(false);
                } else {
                  setCurrentStep(currentStep - 1);
                }
              }}
            >
              {currentStep === 0 ? 'Cancel' : 'Back'}
            </Button>
            <Button
              onClick={() => {
                if (currentStep === steps?.length - 1) {
                  setVisible(false);
                } else {
                  setCurrentStep(currentStep + 1);
                }
              }}
              type="primary"
            >
              {currentStep === steps?.length - 1 ? 'Done' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
