import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Divider, Input, Tooltip, Tree } from 'antd';
import { groupBy, truncate } from 'lodash';
import {
  getProviderFileTypeFromCode,
  getProviderFileTypeTitleFromCode,
  PROVIDER_FILE_TYPES,
} from 'services/providers.service';

const { Search } = Input;

const SORT_ORDER = [
  PROVIDER_FILE_TYPES.W9.code,
  PROVIDER_FILE_TYPES.STATE_LICENSE.code,
  PROVIDER_FILE_TYPES.RATE_SHEET.code,
  PROVIDER_FILE_TYPES.EIN.code,
  PROVIDER_FILE_TYPES.DIRECT_DEPOSIT.code,
  PROVIDER_FILE_TYPES.NATIONAL_ACCREDITATION.code,
  PROVIDER_FILE_TYPES.OTHER.code,
];

export default function DocumentsTree({
  className,
  files,
  testId,
  selectedDocument,
  setSelectedDocument,
  selectedFolder,
  setSelectedFolder,
}) {
  const sections = useMemo(() => {
    const _fileGroups = groupBy(files, (d) => d.fileType);

    const _providerFileTypes = Object.values(PROVIDER_FILE_TYPES);

    const result = Object.keys(_fileGroups)
      .filter((_fileGroup) => {
        return _providerFileTypes.some((_fileType) => _fileType.code === _fileGroup);
      })
      .map((key) => {
        return {
          id: key,
          name: key,
          sections: _fileGroups[key].map((f) => ({
            id: f.id,
            name: f.name,
            description: f.description,
            onClick: () => {
              setSelectedDocument(f);
              setSelectedFolder(null);
            },
          })),
        };
      });

    return [
      ...result,
      ...Object.values(PROVIDER_FILE_TYPES)
        .filter((item) => {
          return result.some((_item) => _item.name === item.code) === false;
        })
        .map((item) => ({
          name: item.code,
          sections: [],
        })),
    ].sort((a, b) => {
      const indexA = SORT_ORDER.indexOf(a.name);
      const indexB = SORT_ORDER.indexOf(b.name);
      return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
    });
  }, [files, setSelectedDocument, setSelectedFolder]);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const onChange = (e) => {
    const { value } = e.target;

    // Early return if no value
    if (!value) {
      setExpandedKeys([]);
      setSearchValue(value);
      setAutoExpandParent(false);

      return;
    }

    const _search = (_item) => {
      if (getItemName(_item).toLowerCase().indexOf(value.toLowerCase()) > -1) {
        return getItemId(_item);
      }

      if (_item.sections) {
        return _item.sections.some(_search) ? getItemId(_item) : false;
      }

      return false;
    };

    const newExpandedKeys = sections.map(_search).filter((item, i, self) => !!(item && self.indexOf(item) === i));

    setExpandedKeys(['all', ...newExpandedKeys]);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const treeData = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        const isFolder = item.sections !== undefined;

        const itemName = isFolder
          ? getProviderFileTypeTitleFromCode(item.name) || getItemName(item)
          : getItemName(item);
        const extension = itemName.includes('.') ? itemName.split('.').pop() || '' : '';

        const isRequired = isFolder && getProviderFileTypeFromCode(item.name)?.required;

        const strTitle = itemName.replace(extension, '');
        const lowerCaseStrTitle = strTitle.toLowerCase();

        const index = searchValue ? lowerCaseStrTitle.indexOf(searchValue.toLowerCase()) : -1;
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);

        const title =
          index > -1 ? (
            <Tooltip
              title={
                `${truncate(beforeStr, { length: !afterStr || afterStr.length < 5 ? 18 : 10 })}${strTitle.substring(
                  index,
                  index + searchValue.length,
                )}${truncate(afterStr, { length: !beforeStr || beforeStr.length < 5 ? 18 : 10 })}${extension}` !==
                itemName
                  ? itemName
                  : undefined
              }
            >
              <span>
                {truncate(beforeStr, { length: !afterStr || afterStr.length < 5 ? 18 : 10 })}
                <span className="text-orange-600 bg-white">
                  {strTitle.substring(index, index + searchValue.length)}
                </span>
                {truncate(afterStr, { length: !beforeStr || beforeStr.length < 5 ? 18 : 10 })}
                {extension}
                {isRequired && <span className="text-red-600 ml-[1px]">*</span>}
              </span>
            </Tooltip>
          ) : extension ? (
            <Tooltip title={`${truncate(strTitle, { length: 24 })}${extension}` !== itemName ? itemName : undefined}>
              <span>
                {truncate(strTitle, { length: 24 })}
                {extension}
                {isRequired && <span className="text-red-600 ml-[1px]">*</span>}
              </span>
            </Tooltip>
          ) : (
            <Tooltip title={truncate(strTitle, { length: 24 }) !== itemName ? itemName : undefined}>
              <span>
                {truncate(strTitle, { length: 24 })}
                {isRequired && <span className="text-red-600 ml-[1px]">*</span>}
              </span>
            </Tooltip>
          );

        if (isFolder) {
          return {
            title,
            key: item.id,
            children: loop(item.sections),
            data: item,
            onClick: () => {
              setSelectedDocument(null);
              setSelectedFolder(item.name);
            },
          };
        }

        return {
          title,
          key: item.id,
          isLeaf: true,
          data: item,
          onClick: () => {
            item?.onClick?.(item);
          },
        };
      });

    return loop(sections);
  }, [searchValue, sections, setSelectedDocument, setSelectedFolder]);

  return (
    <div className={classNames(className)} data-testid={testId}>
      <div className="px-6">
        <Search allowClear placeholder="Search" onChange={onChange} />
      </div>

      <Divider className="mt-[11px] mb-3" />

      <Tree.DirectoryTree
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        treeData={treeData}
        onSelect={(selectedKeys, e) => {
          e.node?.onClick?.();
        }}
      />
    </div>
  );
}

DocumentsTree.propTypes = {
  className: PropTypes.string,
  sections: PropTypes.array,
  depth: PropTypes.number,
  defaultActiveKey: PropTypes.array,
};

// Tree-utilities
const getItemName = (_item) => _item.description || _item.name || '';
const getItemId = (_item) => _item.key || _item.id;
