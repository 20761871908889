import React, { useCallback, useMemo } from 'react';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import IncomeHeader from '../IncomeHeader';
import IncomeContent from '../IncomeContent';
import EmploymentHeader from '../EmploymentHeader';
import useMilitaryInfo from 'hooks/useMilitaryInfo';
import EmploymentContent from '../EmploymentContent';
import PersonalInfoHeader from '../PersonalInfoHeader';
import FamilyReviewContainer from '../FamilyReviewContainer';
import { useAsync } from 'react-async-hook';
import { message } from 'antd';
import { familyService } from 'services';
import AdultFamilyMembers from 'containers/Family/Profile/AdultFamilyMembers';

const SECOND_PARENT_STEPS_ORDER = ['info', 'employment', 'income'];

export default function SecondParentDetails({ application, loading, rootRef }) {
  const { id, householdId, parentId } = useParams();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const parentIndex = useMemo(
    () => (application.additionalParents || []).findIndex((parent) => parent.id === Number(parentId)),
    [application.additionalParents, parentId],
  );

  const secondParent = useMemo(
    () => application.additionalParents?.[parentIndex] || {},
    [application.additionalParents, parentIndex],
  );

  const { installation, branch, component, status } = useMilitaryInfo({ person: secondParent || {} });
  const parent = useMemo(() => {
    const adult = (application.adults || []).find((adult) => adult.id === secondParent?.id);
    return { ...secondParent, ...adult, installation, branch, component, status };
  }, [application.adults, branch, component, installation, secondParent, status]);

  let current = useMemo(() => SECOND_PARENT_STEPS_ORDER.findIndex((key) => pathname.includes(key)) || 0, [pathname]);
  if (pathname.endsWith('/review')) {
    current = 0;
  }
  current = current === -1 ? 0 : current;
  const onChange = useCallback(
    (index) => {
      history.push(`${url}/${SECOND_PARENT_STEPS_ORDER[index]}`);
    },
    [history, url],
  );

  const {
    loading: loadingIncomes,
    result: { incomes = [], totalAnnualIncome = 0 } = { incomes: [], totalAnnualIncome: 0 },
    execute: fetchIncomes,
  } = useAsync(async () => {
    if (!id || !parent?.id) return [];
    try {
      const { totalAnnualIncome = 0, incomes = [] } = await familyService.getIncomes(id, parent?.id);
      return { totalAnnualIncome, incomes };
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to get income.');
      return { totalAnnualIncome: 0, incomes: [] };
    }
  }, [id, parent?.id]);

  const { routePrefix, pathPrefix } = useMemo(() => {
    return {
      routePrefix: `${path}/${SECOND_PARENT_STEPS_ORDER[current]}`,
      pathPrefix: `${url}/${SECOND_PARENT_STEPS_ORDER[current]}`,
    };
  }, [current, path, url]);

  const parentAdultIndex = useMemo(
    () => application?.adults?.findIndex((item) => item.id === Number(parentId)),
    [application?.adults, parentId],
  );

  const commonProps = useMemo(
    () => ({
      profileData: { adults: application?.adults || [], children: application?.children || [], id: householdId },
      application,
      loading: false,
      reload: () => {},
      getItem: () => ({ ...application?.adults?.[parentAdultIndex], index: parentAdultIndex }),
      item: application?.adults?.[parentAdultIndex],
      personId: secondParent?.id,
    }),

    [application, householdId, parentAdultIndex, secondParent?.id],
  );
  const headerExtraElement = useMemo(
    () => <AdultFamilyMembers {...commonProps} hideTable isWrapperWithSection={false} currentStep={current} />,
    [commonProps, current],
  );

  const props = useMemo(
    () => ({
      id,
      householdId,
      parentId,
      application,
      parent,
      loading,
      headerExtraElement,
    }),
    [application, headerExtraElement, householdId, id, loading, parent, parentId],
  );

  const statusPathName = useMemo(() => pathname.replace(/\/documents(.*)$/, ''), [pathname]);
  const infoPrev = useMemo(() => {
    if (parentIndex > 0) {
      return `/families/${householdId}/applications/${application.id}/review/secondParent/${
        application.additionalParents.at(parentIndex - 1).id
      }/income`;
    }
    return `/families/${householdId}/applications/${application.id}/review/sponsor/income`;
  }, [application.additionalParents, application.id, householdId, parentIndex]);

  const stepItems = useMemo(() => {
    let items = [
      {
        title: 'Basic Info',
        status:
          statusPathName.endsWith(`/secondParent/${parentId}`) ||
          statusPathName.endsWith(`/secondParent/${parentId}/info`)
            ? 'process'
            : 'wait',
        header: <PersonalInfoHeader {...props} personLabel="Second Parent" loading={loading} />,
        prev: infoPrev,
        next: `${url}/employment`,
      },
      {
        title: 'Employment/School',
        status: statusPathName.endsWith(`employment`) ? 'process' : 'wait',
        header: <EmploymentHeader {...props} branch={branch} component={component} status={status} />,
        content: <EmploymentContent {...props} isSponsor={false} />,
        prev: `${url}/info`,
        next: `${url}/income`,
      },
      {
        title: 'Income',
        status: statusPathName.endsWith(`income`) ? 'process' : 'wait',
        header: <IncomeHeader {...props} totalAnnualIncome={totalAnnualIncome} loading={loadingIncomes} />,
        content: (
          <IncomeContent
            {...props}
            heading="Second Parent Incomes"
            loading={loadingIncomes}
            incomes={incomes}
            reloadIncomes={fetchIncomes}
          />
        ),
        prev: `${url}/employment`,
        next: application.additionalParents?.[parentIndex + 1]
          ? `/families/${householdId}/applications/${id}/review/secondParent/${
              application.additionalParents[parentIndex + 1].id
            }`
          : application.children?.[0]
          ? `/families/${householdId}/applications/${application.id}/review/children/${application.children[0].id}/info`
          : `/families/${householdId}/applications/${application.id}/review/sponsor/info`,
      },
    ];

    return items;
  }, [
    application.additionalParents,
    application.children,
    application.id,
    branch,
    component,
    fetchIncomes,
    householdId,
    id,
    incomes,
    infoPrev,
    loading,
    loadingIncomes,
    parentId,
    parentIndex,
    props,
    status,
    statusPathName,
    totalAnnualIncome,
    url,
  ]);

  return (
    <div>
      <div>
        <FamilyReviewContainer
          stepItems={stepItems}
          current={current}
          onChange={onChange}
          rootRef={rootRef}
          routePrefix={routePrefix}
          pathPrefix={pathPrefix}
        />
      </div>
    </div>
  );
}
