import { message, Spin } from 'antd';
import Group, { GroupMapContext } from 'components/Group';
import Switch from 'components/Switch';
import Table from 'components/Table';
import React, { useMemo, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { familyService } from 'services';
import { formatDate } from 'utils';

export default function EmploymentContent({ id, parent, isSponsor = true }) {
  const [eligibility, setEligibility] = useState([]);
  const { result: educations = [], loading } = useAsync(async () => {
    if (!parent?.id) return [];
    try {
      const educations = await familyService.getParentEducations(parent?.id);
      return educations;
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to get education info.');
      return [];
    }
  }, []);
  const { loading: loadingEligibility } = useAsync(async () => {
    if (!id || !parent?.id) return {};
    try {
      const eligibility = await familyService.getParentEligibility(id, parent?.id);
      setEligibility(eligibility);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to get parent eligibility.');
      return {};
    }
  }, [id, parent?.id]);

  const { execute: updateParentEligibility } = useAsyncCallback(
    async (eligibility) => {
      if (!id || !parent?.id) return {};
      try {
        await familyService.updateParentEligibility(id, parent?.id, eligibility?.id, eligibility);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to get parent eligibility.');
      }
    },
    [id, parent?.id],
  );

  const allColumns = useMemo(
    () => [
      {
        title: 'Institution Name',
        dataIndex: 'name',
        render: (value) => value || 'N/A',
      },
      {
        title: 'Type of Schooling',
        dataIndex: ['terms', 0, 'type', 'title'],
        render: (value) => value || 'N/A',
      },
      {
        title: 'Credit/Clock Hours',
        dataIndex: ['terms', 0, 'creditHours'],
        render: (value) => value || 'N/A',
      },
      {
        title: 'Start Date',
        dataIndex: ['terms', 0, 'startDate'],
        render: (date) => formatDate(date),
      },
      {
        title: 'End Date',
        dataIndex: ['terms', 0, 'endDate'],
        render: (date) => formatDate(date),
      },
    ],
    [],
  );
  return (
    <div className="p-6 bg-white [&_.ant-table]:!min-h-[100px]">
      {!isSponsor && (
        <>
          <h3 className="text-primary font-bold text-base mb-6 -mt-2">
            {isSponsor ? 'Sponsor' : 'Second Parent'} Educations
          </h3>
          <Table className="" allColumns={allColumns} data={educations} loading={loading} pagination={false} />
        </>
      )}

      <div className="px-2">
        <h3 className="text-primary font-bold text-base mb-6">
          CCAoA {isSponsor ? 'Sponsor' : 'Second Parent'} Eligibility
        </h3>
        <Spin spinning={loadingEligibility}>
          <Group
            id="eligibility"
            multiple
            value={eligibility}
            onChange={(values) => {
              setEligibility(values);
              updateParentEligibility(values);
            }}
          >
            <div className="flex space-x-2 mb-5">
              <GroupMapContext value="woundedWarrior">
                <Switch className="mr-6 mb-2 md:mb-0" />
              </GroupMapContext>
              <p className="text-base">Eligible for Wounded Warrier Bonus</p>
            </div>
            <div className="flex space-x-2 mb-5">
              <GroupMapContext value="deploymentBonus">
                <Switch className="mr-6 mb-2 md:mb-0" />
              </GroupMapContext>
              <p className="text-base">Eligible for Deployment Bonus</p>
            </div>
            <div className="flex space-x-2 mb-5">
              <GroupMapContext value="partTime">
                <Switch className="mr-6 mb-2 md:mb-0" />
              </GroupMapContext>
              <p className="text-base">Work and School Hours are considered Part-Time</p>
            </div>
          </Group>
        </Spin>
      </div>
    </div>
  );
}
