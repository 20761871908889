import { useEffect, useState, useRef } from 'react';
import { message } from 'antd';
import { useCallback } from 'react';
import commonService from 'services/common.service';

export default function useTablePagination({
  url,
  method = 'get',
  params: commonParams,
  mapper = dummyMapper,
  fetchOnMount = true,
  controller,
  body: commonBody,
  errorMessage = 'Unable to get data.',
  hideErrorMessage = false,
}) {
  const onMountCalls = useRef(0);
  const [data, setData] = useState({
    data: [],
    loading: false,
    params: commonParams,
    body: commonBody,
    pagination: { current: 1, pageSize: commonParams.pageSize || 10 },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMore = useCallback(
    async (params, body) => {
      let newParams = { ...commonParams, ...params };
      let newBody = { ...commonBody, ...body };
      const { pageSize, current } = newParams;
      newParams.size = pageSize;
      newParams.page = current - 1;
      delete newParams.pageSize;
      delete newParams.current;
      controller?.abort();
      newParams.signal = controller?.signal;
      setData((v) => ({ ...v, loading: true, params: newParams }));
      try {
        let response = await commonService.getPaginatedData({ url, method, params: newParams, body: newBody });
        setData((v) => {
          return {
            ...v,
            loading: false,
            data: response.content.map(mapper),
            pagination: {
              current: response.number + 1,
              pageSize: response.size,
              total: response.totalElements,
              // onChange: (page, size) => fetchMore({ current: page - 1, pageSize: size }),
            },
          };
        });
      } catch (error) {
        newrelic.noticeError(error);
        if (!hideErrorMessage) {
          message.error(errorMessage, 5);
        }
      } finally {
        setData((v) => ({ ...v, loading: false }));
      }
    },
    [commonBody, commonParams, controller, errorMessage, hideErrorMessage, mapper, method, url],
  );

  const onChange = useCallback(
    (pagination, filters, sorter, customParams, body) => {
      let params = {
        ...pagination,
      };
      if (sorter) {
        const isNestedValues = Array.isArray(sorter.field);
        const fieldSorter = isNestedValues
          ? sorter.field.reduce((total, current) => `${total}.${current}`)
          : sorter.field;

        if (customParams?.sort) {
          params.sort = customParams.sort;
        } else if (sorter.field && !params.sort) {
          params.sort = `${fieldSorter},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
        }
      }

      fetchMore(params, body);
    },
    [fetchMore],
  );

  const reload = useCallback(async () => {
    return await fetchMore({
      current: 1,
      pageSize: data.pagination.pageSize,
    });
  }, [data.pagination.pageSize, fetchMore]);

  useEffect(() => {
    if (fetchOnMount && onMountCalls.current === 0) {
      onMountCalls.current++;
      fetchMore({ current: 1 });
    }
  }, [fetchMore, fetchOnMount, onMountCalls]);

  return {
    ...data,
    setData,
    fetchMore,
    reload,
    onChange,
  };
}

function dummyMapper(data) {
  return data;
}
