import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { List, Button, message } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import { FaCaretDown, FaCaretUp, FaUserCircle } from 'react-icons/fa';

import Html from 'Html';
import styles from './Notes.module.less';
import { familyService, providerService } from 'services';
import { ENTITY_TYPES } from 'constants/index';
import { formatDateTimeToLocal, getFullName, htmlToText } from 'utils';

const initialParams = {
  draft: false,
  page: 0,
  size: 5,
};

export default function Notes({ className, entity, entityType = ENTITY_TYPES.CARE_FACILITY }) {
  const [isExpanded, setIsExpanded] = useState({});

  const onExpandText = (item) => {
    setIsExpanded((v) => ({ ...v, [item.id]: !isExpanded[item.id] }));
  };

  const {
    loading,
    result: notes = {},
    execute: getNotesWithPagination,
  } = useAsyncCallback(
    async (paginationParams) => {
      try {
        if (entityType === ENTITY_TYPES.CARE_FACILITY) {
          if (!entity?.application?.id) return [];
          return await providerService.getNotes(entity.application.id, { ...paginationParams, sort: 'id,desc' });
        } else {
          if (!entity?.id) return [];
          return await familyService.getNotes(entity.id, { ...paginationParams, sort: 'id,desc' });
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to get notes.', 5);
        return [];
      }
    },
    [entity?.id],
  );

  useEffect(() => {
    getNotesWithPagination(initialParams);
  }, [entity, getNotesWithPagination]);

  const onPageChange = (page, size) => {
    const params = {
      ...initialParams,
      page: page - 1,
      size,
    };
    getNotesWithPagination(params);
  };

  return (
    <div className={classNames(styles.notes, className)}>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{ pageSize: initialParams.size, total: notes?.totalElements, onChange: onPageChange }}
        dataSource={notes.content}
        loading={loading}
        renderItem={(item) => {
          const createdBy = getFullName(item.createdBy);
          return (
            <List.Item className={classNames('!grid grid-cols-[60px_1fr_40px]')} key={item.id}>
              <div className="flex justify-start md:justify-center	">
                <FaUserCircle size={32} color="#2E5BFF" />
              </div>
              <div className={classNames('max-w-full overflow-hidden')}>
                {/* <p
                  data-testid={`notes-message-${item.id}`}
                  className={classNames('w-full', !isExpanded[item.id] ? 'whitespace-nowrap truncate' : '')}
                >
                  {item.content}
                </p> */}
                <Html
                  className={classNames('w-full', !isExpanded[item.id] ? 'whitespace-nowrap truncate' : '')}
                  html={isExpanded[item.id] ? item.content : htmlToText(item.content)}
                  data-testid={`notes-message-${item.id}`}
                />
                <p className="added-by">Added By: {createdBy || 'NA'}</p>
                <p style={{ lineHeight: '10px' }} className="date truncate whitespace-nowrap float-right">
                  Date: {formatDateTimeToLocal(item.lastModifiedDate)}
                </p>
              </div>
              <div className="">
                <Button
                  data-testid={`expand-trigger-${item.id}`}
                  className={classNames(styles.btn, 'border-0 icon-btn')}
                  icon={
                    isExpanded[item.id] ? (
                      <FaCaretUp size={20} color="rgba(156, 163, 175)" />
                    ) : (
                      <FaCaretDown size={20} color="rgba(156, 163, 175)" />
                    )
                  }
                  onClick={() => onExpandText(item)}
                />
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
}

Notes.propTypes = {
  notes: PropTypes.array,
  loading: PropTypes.bool,
  className: PropTypes.string,
  center: PropTypes.object,
};

Notes.defaultProps = {
  notes: [],
  loading: false,
  className: '',
  center: {},
};
