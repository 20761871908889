import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { message, Spin } from 'antd';
import { useAsync, useAsyncFn } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';

import GccModal from 'components/Modal';
import RowSelectionTable from 'components/RowSelectionTable';
import providerService from 'services/providers.service';
import { selectors as centerSelectors, actions as centerActions } from 'features/add-center';

export default function PartnerAgencyModal({ visible, setVisible }) {
  const center = useSelector(centerSelectors.selectCenterState);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [results, setResults] = useState([]);
  const dispatch = useDispatch();
  const agencyId = useMemo(() => center?.partnerAgency?.id, [center?.partnerAgency?.id]);

  const { loading, value: partnerAgencies = [] } = useAsync(async () => {
    try {
      const data = await providerService.getPartnerAgencies();
      const index = data.findIndex((v) => v.id === agencyId);
      if (index > -1) {
        setResults([data[index], ...data.slice(0, index), ...data.slice(index + 1)]);
      } else {
        setResults(data);
      }
      return data;
    } catch (error) {
      message.error('Error getting search results', 5);
      newrelic.noticeError(error);
      return [];
    }
  }, []);

  const [{ loading: updatingPartnerAgency }, onSubmit] = useAsyncFn(async () => {
    try {
      if (selectedAgency) {
        const data = await providerService.setPartnerAgency(center?.id, { id: selectedAgency.id });
        setVisible(false);
        dispatch(centerActions.setCenterInfo(data));
        message.success('Partner agency selected successfully.', 5);
        setResults(partnerAgencies);
      } else {
        message.error('Please select a partner agency.', 5);
      }
    } catch (error) {
      setSelectedAgency(agencyId);
      message.error('Unable to set partner agency.', 5);
    }
  }, [selectedAgency, center?.id, partnerAgencies]);

  const onSearch = useCallback(
    ({ searchKeyWord, state, city }) => {
      if (!searchKeyWord && !state && !city) return setResults(partnerAgencies);
      const filteredResult = partnerAgencies.filter((item) => {
        return (
          (searchKeyWord && item.name.toLowerCase().includes(searchKeyWord.toLowerCase() || '')) ||
          (state && item.address.state.toLowerCase().includes(state.toLowerCase() || '')) ||
          (city && item.address.city.toLowerCase().includes(city.toLowerCase() || ''))
        );
      });
      setResults(filteredResult);
    },
    [partnerAgencies],
  );

  const setPartnerAgency = useCallback((partnerAgencies, id) => {
    if (id && partnerAgencies?.length > 0) {
      const agency = partnerAgencies.find((item) => item.id === id);
      setSelectedAgency(agency);
    }
  }, []);
  useEffect(() => {
    setPartnerAgency(partnerAgencies, agencyId);
  }, [setPartnerAgency, partnerAgencies, agencyId]);

  return (
    <GccModal visible={visible} setVisible={setVisible} width={950} closable={false}>
      <Spin spinning={updatingPartnerAgency}>
        <RowSelectionTable
          title="Partner Agency"
          results={results}
          loading={loading}
          onSearch={onSearch}
          onSubmit={onSubmit}
          totalCount={results?.length}
          setSelected={setSelectedAgency}
          selected={selectedAgency}
          onCancel={() => {
            setVisible(false);
            setPartnerAgency(partnerAgencies, agencyId);
            setResults(partnerAgencies);
          }}
        />
      </Spin>
    </GccModal>
  );
}
