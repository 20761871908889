import { useSelector } from 'react-redux';
import { selectParams } from 'features/search';
import useQueryParams from 'hooks/useQueryParams';

export default function useSearchParams({ isPublicSearch }) {
  const { params: queryParams } = useQueryParams();
  const reduxParams = useSelector(selectParams);
  return {
    params: isPublicSearch ? { ...reduxParams, ...queryParams } : reduxParams,
  };
}
