import FamilyCertificatesTable from 'containers/Family/FamilyCertificates/FamilyCertificatesTable';
import React from 'react';

export default function FamilyCertificates({ match }) {
  const { params } = match;
  const { householdId } = params;
  return (
    <div className="p-6">
      <FamilyCertificatesTable
        householdId={householdId}
        className="pb-6"
        headingClassName="text-15 text-primary font-semibold uppercase"
      />
    </div>
  );
}
