import { useCallback, useMemo } from 'react';
import { Descriptions, Skeleton } from 'antd';

import { getCompletedAddress, getFullName } from 'utils';

export default function InstallationHeader({ child, installation, installationInfo, loading, headerExtraElement }) {
  const getDuration = useCallback((minutes) => {
    if (!minutes) return 'N/A';
    const hours = Math.floor(minutes / 60);
    const min = minutes % 60;
    return `${hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''} and ` : ''}${min} minute${minutes > 1 ? 's' : ''}`;
  }, []);
  const items = useMemo(
    () => [
      {
        id: 'installationName',
        title: 'Installation Name',
        value: installation?.name || 'N/A',
      },
      {
        id: 'installationAddress',
        title: 'Installation Address',
        value: getCompletedAddress(installation?.address) || 'N/A',
      },
      {
        id: 'person_homeAddress',
        title: 'Home Address',
        value: getCompletedAddress(child?.homeAddress) || 'N/A',
      },
      {
        id: 'closestInstallationAddress',
        title: 'Installation Closest to Home',
        value: getCompletedAddress(child?.installationClosestToHome) || 'N/A',
      },
      {
        id: 'childrenLivingWithSponsor',
        title: 'Children Living with Parent',
        value: child?.addressSameAsApplicant ? 'Yes' : 'No',
      },
      {
        id: 'distanceToInstallationMiles',
        title: 'Distance to Installation in Miles',
        value: installationInfo?.distanceInMiles ? `${installationInfo?.distanceInMiles} mi` : 'N/A',
      },
      {
        id: 'distanceToInstallationMinutes',
        title: 'Distance to Installation',
        value: getDuration(installationInfo?.distanceInMinutes),
      },
    ],
    [
      installation?.name,
      installation?.address,
      child?.homeAddress,
      child?.installationClosestToHome,
      child?.addressSameAsApplicant,
      installationInfo?.distanceInMiles,
      installationInfo?.distanceInMinutes,
      getDuration,
    ],
  );
  const descItems = useMemo(
    () => items.map((item) => ({ key: item.id, label: item.title, children: item.value })),
    [items],
  );
  return (
    <div className="px-6 pb-6 pt-5">
      <Skeleton avatar loading={loading} paragraph={{ rows: 4 }}>
        <div className="mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Child:</span>{' '}
                <span className=" text-primary font-medium">{getFullName(child)}</span>
              </p>
            }
            size="small"
            items={descItems}
            extra={headerExtraElement}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {descItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}
