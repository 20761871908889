import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async-hook';
import { Col, Row, Button, Input, Alert } from 'antd';

import Form from 'components/Form';
import Select, { AsyncSelect } from 'components/Select';
import useEntityGroup from 'hooks/useEntityGroup';
import useStartEndDates from 'hooks/useStartEndDates';
import useChangeRequestClassName from 'hooks/useChangeRequestClassName';
import { formatDate, getValue } from 'utils';
import FormItem from 'components/FormItem';
import commonService from 'services/common.service';
import dayjs from 'dayjs';
import Card from 'components/Card/Card';
import { Typography } from 'antd';

const { Title } = Typography;

export default function QRIS({ disabled, value: items, onChange, center, state }) {
  const keys = ['id', 'certificateNumber', 'endDate', 'startDate', ['system', 'id'], ['level', 'id'], 'state'];
  const rowClassName = useChangeRequestClassName({ keys, snapshotKey: ['qrisList'] });
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">QRIS System</span>,
        skip: true,
        headerText: 'QRIS System',
        dataIndex: 'system',
        key: 'system',
        ellipsis: true,
        render: (value) => value?.title,
        fixed: 'left',
      },
      {
        title: <span className="action-header">QRIS Level</span>,
        skip: true,
        headerText: 'QRIS Level',
        dataIndex: 'level',
        key: 'level',
        ellipsis: true,
        render: (value) => value?.level ?? 'N/A',
      },
      {
        title: <span className="action-header">Start Date</span>,
        headerText: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (value) => formatDate(value),
        align: 'left',
      },
      {
        title: <span className="action-header">Expiry Date</span>,
        headerText: 'Expiry Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (value) => formatDate(value),
        align: 'left',
      },
      {
        title: <span className="action-header">State</span>,
        skip: true,
        headerText: 'State',
        dataIndex: ['system', 'state'],
        key: 'state',
        ellipsis: true,
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback(({ values, setVisible, item, ...rest }) => {
    return <QrisForm values={values} item={item} setVisible={setVisible} {...rest} />;
  }, []);

  const { section } = useEntityGroup({
    items: (items || []).sort((a, b) => dayjs(a.endDate) - dayjs(b.endDate)),
    onChange,
    entity: center,
    getAllColumns,
    getFormFields,
    entityTitle: 'QRIS rating',
    tableProps: {
      rowClassName,
      scroll: { x: 1000 },
    },
    dateFields: [['startDate'], ['endDate']],
    dateFormat: 'YYYY-MM-DD',
    getFormInitialValues: (item) => ({ ...item, state: item?.system?.state ?? state }),
    entityName: 'qris',
    readOnly: disabled,
  });

  return (
    <Card
      collapsible
      title={
        <Title level={3} className="!mb-0">
          Quality Rating (QRIS)
        </Title>
      }
      className="mb-4"
    >
      <p className="instructions -mt-2">If you have a QRIS level, please provide your QRIS details.</p>

      <Form.Item
        name="qris"
        dependencies={[['qrisList']]}
        // rules={[
        //   (form) => {
        //     return {
        //       validator: () => {
        //         const qrisList = form.getFieldValue(['qrisList']);
        //         if (qrisList?.length > 0) return Promise.resolve();
        //         return Promise.reject('At least one QRIS is required.');
        //       },
        //     };
        //   },
        // ]}
      >
        {section}
      </Form.Item>
    </Card>
  );
}

QRIS.propTypes = {
  center: PropTypes.object,
  onChange: PropTypes.func,
  state: PropTypes.string,
  value: PropTypes.array,
};

const StartEndDates = ({ form, values, getSnapshotValue }) => {
  const { level: selectedLevel } = values;

  const [startDate, endDate] = useStartEndDates({
    form,
    startDateFieldProps: {
      label: 'QRIS Start Date',
      rules: [{ required: true, message: 'QRIS Start Date is required.' }],
      getSnapshotValue,
    },
    startDatePickerProps: {
      'data-testid': 'qrisStartDate',
    },
    endDateFieldProps: {
      label: 'QRIS End Date',
      rules:
        selectedLevel?.nonExpiring === true
          ? []
          : [{ required: !values?.system?.nonExpiring, message: 'QRIS End Date is required.' }],
      dependencies: [['neverExpired']],
      getSnapshotValue,
    },
    endDatePickerProps: {
      disabled: values?.system?.nonExpiring || selectedLevel?.nonExpiring === true,
      'data-testid': 'qrisEndDate',
    },
  });

  return (
    <>
      <Col xs={24} sm={12} md={8}>
        {startDate}
      </Col>
      <Col xs={24} sm={12} md={8}>
        {endDate}
      </Col>
    </>
  );
};

const QrisForm = ({ values, item, setVisible, form }) => {
  const state = useMemo(() => {
    return values?.system?.state || values.state;
  }, [values.state, values?.system?.state]);
  const { result: qrisSystemOptions, loading: loadingOptions } = useAsync(
    async (stateId) => {
      form.resetFields(['system']);
      return await commonService.get(`/providers/qris-systems?state=${stateId}`, { cache: { interpretHeader: false } });
    },
    [values?.state, state],
  );
  const getSnapshotValue = (snapshot, name) => {
    return getValue(
      snapshot.qrisList.find((v) => v.id === item?.id),
      name,
    );
  };

  const selectedQrisSystem = Form.useWatch('system', form);
  return (
    <>
      <Alert
        type="info"
        message={<p className="font-semibold">Note:</p>}
        description={<>If you have enrolled in a QRIS, but not yet received a rating, please put a 0 for level.</>}
        className="mb-8"
        style={{ borderRadius: '2px' }}
      />

      <Row gutter={[20, 0]} className="form-row">
        <Col xs={24} sm={12} md={8}>
          <FormItem
            name="state"
            label="State"
            rules={[{ required: true, message: 'State is required.' }]}
            getSnapshotValue={getSnapshotValue}
          >
            <AsyncSelect
              name="state"
              placeholder="State"
              ariaLabel="State"
              optionsApiUrl="/countries/states/US/"
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt.code}
              isOptionSelected={(option, [value]) => option?.code === value}
              onChangeFormatter={(v) => v?.code}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <FormItem
            name="system"
            label="QRIS System"
            rules={[{ required: true, message: 'QRIS System is required.' }]}
            getSnapshotValue={getSnapshotValue}
            isSame={(prev, curr) => prev?.id === curr?.id}
          >
            <Select
              options={qrisSystemOptions}
              loading={loadingOptions}
              name="system"
              placeholder="QRIS System"
              ariaLabel="QRIS System"
              styles={{
                control: (base) => ({
                  ...base,
                  height: 32, // Set the height you want here
                  minHeight: 32,
                  borderRadius: '2px',
                }),
              }}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} md={8}>
          {selectedQrisSystem && (
            <FormItem
              name="level"
              label="QRIS Level"
              rules={[{ required: true, message: 'QRIS Level is required.' }]}
              getSnapshotValue={getSnapshotValue}
            >
              <Select
                options={selectedQrisSystem.levels}
                isOptionSelected={(opt, [value]) => {
                  return opt.id === value?.id;
                }}
                getOptionValue={(opt) => opt}
                getOptionLabel={(opt) => opt.level}
                onChange={(value) => {
                  if (value?.nonExpiring === true) {
                    form.setFieldsValue({ endDate: null });
                  }
                }}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 32, // Set the height you want here
                    minHeight: 32,
                    borderRadius: '2px',
                  }),
                }}
              />
            </FormItem>
          )}
        </Col>
        <Col xs={24} sm={12} md={8}>
          <FormItem name="certificateNumber" label="Certificate Number" getSnapshotValue={getSnapshotValue}>
            <Input placeholder="Certificate Number" />
          </FormItem>
        </Col>

        <StartEndDates form={form} values={values} getSnapshotValue={getSnapshotValue} />
      </Row>
      <Row gutter={[20, 0]} className="form-row mt-2">
        <Col xs={24}>
          <div className="actions flex mt-5">
            <Button onClick={() => setVisible(false)} data-testid="cancel-qris-btn" style={{ borderRadius: '2px' }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" data-testid="save-qris-btn" style={{ borderRadius: '2px' }}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};
