import React, { useState, useRef, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { useComponentSize } from 'react-use-size';
import { Button, Empty, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineCaretLeft, AiOutlineCaretRight } from 'react-icons/ai';

import styles from './SearchProviders.module.less';
import { actions, selectors } from 'features/search';
import useBreakpoints from 'hooks/useBreakpoints';
import usePlace from './usePlace';
import Map from 'components/Map';
import ProviderCard from 'components/ProviderCard';
import Filters from './Filters';
import Section from 'components/Section';
import Loading from 'components/Loading';
import useSearchParams from './useSearchParams';
import { BsFillFunnelFill } from 'react-icons/bs';

export default function SearchProviders({
  className,
  selectProvider,
  selectedProvider,
  heightClassName = 'min-h-[500px] h-[calc(100vh-80px)]',
  isPublicSearch,
}) {
  const dataDiv = useRef();
  const { ref, height } = useComponentSize();
  const breakpoints = useBreakpoints();
  const listHeight = useMemo(() => height - 80, [height]);
  const dispatch = useDispatch();
  const [provider, setProvider] = useState(null);
  const [mapVisible, setMapVisible] = useState(true);
  const { loading, content, pagination, totalElements } = useSelector(selectors.selectSearchState);

  const { params } = useSearchParams({ isPublicSearch });
  const {
    place = params?.location,
    center,
    loading: placeLoading,
    setCenter,
  } = usePlace({ id: params?.location?.placeId || params.placeId });

  useEffect(() => {
    if (place?.point) {
      dispatch(
        actions.setParams({
          params: { location: place },
          isPublicSearch,
        }),
      );
    }
  }, [dispatch, isPublicSearch, place]);

  const location = useMemo(() => {
    return isPublicSearch ? place : params?.location;
  }, [isPublicSearch, params?.location, place]);

  const hiddenClass = 'col-span-0 hidden';
  let gridClassName = 'grid-cols-2';
  let listClassName = 'col-span-2';
  let mapClassName = hiddenClass;

  if (mapVisible) {
    listClassName = 'col-span-1';
    mapClassName = 'col-span-1';
  }
  if (breakpoints.screen && !breakpoints.lg) {
    gridClassName = 'grid-cols-1';
    listClassName = 'col-span-1';
    mapClassName = hiddenClass;
    if (mapVisible) {
      listClassName = hiddenClass;
      mapClassName = 'col-span-1';
    }
  }
  if (breakpoints.xxl) {
    gridClassName = 'grid-cols-[800px_1fr]';
    listClassName = 'col-span-1';
  }
  useEffect(() => {
    if (breakpoints.screen) {
      setMapVisible(breakpoints.lg);
    }
  }, [breakpoints.lg, breakpoints.screen]);

  const mapTrigger = (
    <Button
      className={classNames(
        'show-map-trigger !w-10 !h-10 !rounded-full !p-0 !flex justify-center items-center absolute z-10',
        {
          '-right-5': mapVisible && listClassName !== hiddenClass,
          'left-2 top-12': mapVisible && listClassName === hiddenClass,
          'right-5 top-12': !mapVisible,
        },
      )}
      type="primary"
      onClick={() => {
        setMapVisible((v) => !v);
      }}
    >
      {mapVisible ? <AiOutlineCaretRight size={25} /> : <AiOutlineCaretLeft size={25} />}
    </Button>
  );

  let filters = (
    <Filters
      isPublicSearch={isPublicSearch}
      mapVisible={mapVisible}
      parentRef={dataDiv}
      className={classNames({ 'px-4': breakpoints.md })}
    />
  );

  if (!breakpoints.md) {
    filters = (
      <Section
        heading={
          <div className="flex items-center space-x-3">
            <BsFillFunnelFill size={25} />
            <p className="text-lg font-bold leading-6">Filters</p>
          </div>
        }
        headerContainerClassName="bg-light-bg !py-4 !px-6 shadow-lg shadow-light-bg "
        contentClassName="section-px -mt-2 pb-2 bg-light-bg pt-2"
        // extra={mapTrigger}
        triggerClassName="absolute !top-4 right-5 !mr-0"
        className="relative bg-light-bg !border-b-0"
      >
        <Filters isPublicSearch={isPublicSearch} mapVisible={mapVisible} parentRef={dataDiv} />
      </Section>
    );
  }
  let header = (
    <div className="sticky top-0 pt-5 bg-light-bg z-[1] shadow-lg shadow-light-bg">
      {filters}

      <div className="title-container px-6 mt-6">
        <h2 className="text-sm text-gray-500 uppercase font-normal" id="results-title">
          Near {location ? <>{location?.description || location.formatted_address}</> : ''}
        </h2>

        {!breakpoints.xl && mapTrigger}
      </div>

      <p className="text-black uppercase tracking-wider font-semibold mb-4 px-6">{totalElements} providers found</p>
    </div>
  );

  return (
    <div className={classNames(styles.searchProviders, 'bg-light-bg', className)} ref={ref}>
      <div className={classNames('grid overflow-hidden bg-light-bg', gridClassName, heightClassName)}>
        <div
          ref={dataDiv}
          id="data-container"
          className={classNames(heightClassName, listClassName, 'flex flex-col z-[1] relative shadow-2xl shadow-black')}
        >
          {(loading || placeLoading) && <Loading className={classNames(heightClassName, 'absolute top-0')} />}

          {header}

          <div className="flex-1 px-5 overflow-auto flex flex-col justify-between" style={{ height: listHeight }}>
            <ul className="mt-4">
              {content.map((item) => {
                const isActive = provider ? provider.id === item.id : false;
                return (
                  <ProviderCard
                    key={`${item.id}`}
                    provider={item}
                    isActive={isActive}
                    onCardClick={setProvider}
                    onSelectClick={selectProvider}
                    selectedProvider={selectedProvider}
                    isPublicSearch={isPublicSearch}
                    lat={item.lat}
                    lng={item.lng}
                  />
                );
              })}

              {totalElements === 0 && !(loading || placeLoading) && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <p className="w-full sm:w-1/2 text-center mx-auto text-lg text-black">{`We can't find any child care providers that meet your search criteria${
                      place ? ` in "${place?.formatted_address}"` : ''
                    }`}</p>
                  }
                />
              )}
            </ul>

            <div className="text-center">
              {totalElements > pagination?.size && (
                <Pagination
                  total={totalElements}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Providers`}
                  defaultPageSize={pagination.size}
                  defaultCurrent={1}
                  current={(pagination?.page || 0) + 1}
                  pageSize={pagination?.size}
                  onChange={(page, size) => {
                    dispatch(actions.setParams({ pagination: { page: page - 1, size }, isPublicSearch }));
                  }}
                  size="small"
                  className="text-center"
                  showSizeChanger={false}
                />
              )}
              <div className="h-3" />
            </div>
          </div>
        </div>

        <div id="map-container" className={classNames(heightClassName, mapClassName, 'relative')}>
          {listClassName === hiddenClass && mapTrigger}
          <Map
            zoom={13}
            className="providers-map"
            dropdownClassName="theme-selector"
            pointers={content}
            onMarkerClick={setProvider}
            selected={provider}
            place={place}
            center={center}
            setCenter={setCenter}
            setSelectedMarker={setProvider}
            visible={mapVisible}
            // screen={screen}
          />
        </div>
      </div>
    </div>
  );
}
