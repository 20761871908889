import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button, Input } from 'antd';

import {
  getCompletedAddress,
  getFormattedPhoneNumber,
  getMilitaryEmailValidator,
  getPhoneNumberValidator,
} from 'utils';
import Form from 'components/Form';
import Select from 'components/Select';
import useEntityGroup from 'hooks/useEntityGroup';
import Section from 'components/Section';
import familyService from 'services/family.service';
import AddressFields from 'components/AddressFields';
import PhoneNumber from 'components/PhoneNumber';
import { PHONE_TYPES } from 'constants/index';

export default function AdultContactInformationContainer(props) {
  return <AdultContactInformation {...props} />;
}

export function AdultContactInformation({
  value,
  application,
  className,
  isWorkEmailRequired = false,
  militaryEmployee = false,
  record,
  householdId,
  adultId,
  reload,
}) {
  const items = !record?.homeAddress ? [] : [{ ...value }];
  const onAddItem = useCallback(
    async (data) => {
      await familyService.addUpdateAdult(householdId, adultId, data);
      return await reload();
    },
    [adultId, householdId, reload],
  );
  const onDeleteItem = useCallback(
    async (item) => familyService.deleteAdultContactInfo(application?.id, item.id),
    [application?.id],
  );
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: (
          <span className="action-header">
            Preferred Phone
            <br />
            Number
          </span>
        ),
        headerText: 'Preferred Phone Number',
        dataIndex: 'phone',
        key: 'preferredPhone',
        ellipsis: true,
        width: 160,
        fixed: 'left',
        render: (preferredPhone) => getFormattedPhoneNumber(preferredPhone) || 'N/A',
      },
      {
        title: (
          <span className="action-header">
            Phone
            <br />
            Type
          </span>
        ),
        headerText: 'Phone Type',
        dataIndex: 'phoneType',
        key: 'phoneType',
        ellipsis: true,
        width: 100,
        render: (phoneType) => phoneType || 'N/A',
      },
      {
        title: (
          <span className="action-header">
            Secondary
            <br />
            Phone
          </span>
        ),
        headerText: 'Secondary Phone',
        dataIndex: 'secondaryPhone',
        key: 'secondaryPhone',
        ellipsis: true,
        width: 150,
        render: (preferredPhone) => getFormattedPhoneNumber(preferredPhone) || 'N/A',
      },
      {
        title: (
          <span className="action-header">
            Phone
            <br />
            Type
          </span>
        ),
        headerText: 'Phone Type',
        dataIndex: 'secondaryPhoneType',
        key: 'secondaryPhoneType',
        ellipsis: true,
        width: 150,
        render: (secondaryPhoneType) => secondaryPhoneType || 'N/A',
      },
      {
        title: (
          <span className="action-header">
            Primary
            <br />
            Email
          </span>
        ),
        headerText: 'Primary Email',
        dataIndex: 'email',
        key: 'email',
        ellipsis: true,
        width: 160,
        render: (workEmail) => workEmail || 'N/A',
      },
      {
        title: (
          <span className="action-header">
            Secondary/
            <br />
            Military Email
          </span>
        ),
        headerText: 'Secondary/Military Email',
        dataIndex: 'workEmail',
        key: 'workEmail',
        ellipsis: true,
        width: 160,
        render: (workEmail) => workEmail || 'N/A',
      },
      {
        title: <span className="action-header">Address</span>,
        headerText: 'Address',
        dataIndex: 'homeAddress',
        key: 'address',
        ellipsis: true,
        width: 180,
        fixed: 'right',
        render: (address) => getCompletedAddress(address),
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback(
    (props) => {
      return (
        <AdultContactInformationFormFields
          isWorkEmailRequired={isWorkEmailRequired}
          militaryEmployee={militaryEmployee}
          {...props}
        />
      );
    },
    [isWorkEmailRequired, militaryEmployee],
  );

  const { section } = useEntityGroup({
    items,
    onChange: () => {},
    onAddItem,
    onDeleteItem,
    entity: application,
    getAllColumns,
    getFormFields,
    entityTitle: 'Contact information',
    tableProps: {
      scroll: { x: 1200 },
    },
    readOnly: true,
    hideDeleteButton: true,
    entityName: 'contactInformation',
  });
  return (
    <div className={classNames(className)}>
      <Section heading="Contact Information" collapsible={false} className={className}>
        <div>{section}</div>
      </Section>
    </div>
  );
}

export const AdultContactInformationFormFields = ({
  values,
  setVisible,
  items,
  form,
  item,
  index,
  profileData,
  addUpdateLoading,
  isWorkEmailRequired,
  militaryEmployee,
  isFamilyUser,
}) => {
  return (
    <div>
      <h3 className="section-title mb-6">Step 2: Enter Adult's Contact Information</h3>
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-8 border-b">
        <div>
          <Form.Item
            name={'email'}
            label="Preferred Email Address"
            rules={[
              { required: true, message: 'Preferred Email is required' },
              { type: 'email', message: 'Preferred Email Address in invalid' },
              // Validation for duplicate email addresses
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const _emailAddress = value || getFieldValue('email');

                  if (
                    (profileData?.adults || []).some(
                      ({ email, id }) => id !== item.id && email?.toLowerCase() === _emailAddress?.toLowerCase(),
                    )
                  ) {
                    return Promise.reject('This email address is used in an existing profile of an adult');
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input placeholder="Preferred Email" />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            validateFirst
            name="workEmail"
            label={'Secondary/Military Email Address'}
            rules={[
              {
                required: isWorkEmailRequired || militaryEmployee,
                message: `${
                  isWorkEmailRequired || militaryEmployee ? 'Military Email' : 'Secondary Email'
                } is required`,
              },
              {
                type: 'email',
                message: `${isWorkEmailRequired || militaryEmployee ? 'Military Email' : 'Secondary Email'} is invalid`,
              },
              isWorkEmailRequired || militaryEmployee
                ? getMilitaryEmailValidator('Invalid Military Email Address')
                : {},
            ]}
          >
            <Input
              placeholder={isWorkEmailRequired || militaryEmployee ? 'Military Email Address' : 'Secondary Email'}
            />
          </Form.Item>
        </div>

        <div className="lg:col-start-1">
          <Form.Item
            name={'phone'}
            label="Preferred Phone Number"
            rules={[
              { required: true, message: 'Preferred Phone Number is required' },
              getPhoneNumberValidator('Invalid Preferred Phone Number!'),
              // Validation for duplicate phone numbers
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const _phoneNumber = value || getFieldValue('phone');

                  if (
                    (profileData?.adults || []).some(
                      ({ phone, id }) =>
                        id !== item.id && phone?.replace(/\D+/g, '') === _phoneNumber?.replace(/\D+/g, ''),
                    )
                  ) {
                    return Promise.reject('This phone number is used in an existing profile of an adult');
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <PhoneNumber />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="phoneType" label="Phone Type">
            <Select
              placeholder={'Preferred Phone Type'}
              ariaLabel="Preferred Phone Type"
              options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
              isOptionSelected={(opt, [value]) => opt?.title === value}
              onChangeFormatter={(v) => v?.title}
            />
          </Form.Item>
        </div>
        <div className="lg:col-start-1">
          <Form.Item
            name="secondaryPhone"
            label="Secondary Phone Number"
            rules={[getPhoneNumberValidator('Invalid Secondary Phone Number!')]}
          >
            <PhoneNumber />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="secondaryPhoneType" label="Phone Type">
            <Select
              placeholder={'Secondary Phone Type'}
              ariaLabel="Secondary Phone Type"
              options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
              isOptionSelected={(opt, [value]) => opt?.title === value}
              onChangeFormatter={(v) => v?.title}
            />
          </Form.Item>
        </div>
      </div>
      <div className="mt-4">
        <AddressFields id="adult-current-address" addressKey="homeAddress" />
      </div>
      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-contactInformation-btn">
          Back
        </Button>
        <Button
          type="primary"
          data-testid="save-contactInformation-btn"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
          onClick={() => form.submit()}
        >
          {isFamilyUser ? 'Next' : 'Save'}
        </Button>
      </div>
    </div>
  );
};
