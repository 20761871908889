import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, message } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useSelector } from 'react-redux';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Select from 'components/Select';
import UserOption from 'components/UserOption';
import { getFullName, hasPermission } from 'utils';
import { ASSIGNMENT_TYPES, ROLES } from 'constants/index';
import { actions as dashboardActions } from 'features/dashboard';
import { familyService, providerService, userService } from 'services';
import { selectUser } from 'features/auth';

export default function BatchAssignment({ visible, setVisible, state, roles, isFamilyDashbaord, reload }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const assignmentType = Form.useWatch('assignmentType', form);
  const assignee = Form.useWatch('assignee', form);
  const selectedRowKeys = useMemo(() => state.selectedRowKeys ?? [], [state.selectedRowKeys]);
  const batchAssignCount = useMemo(() => selectedRowKeys.length, [selectedRowKeys]);
  const userInfo = useSelector(selectUser);

  const setSelectedRowKeys = useCallback(
    (keys) => {
      dispatch(dashboardActions.setSelectedRowKeys(keys));
    },
    [dispatch],
  );
  const { loading: batchAssignmentLoading, execute: batchAssign } = useAsyncCallback(
    async ({ assignmentType, ...values }) => {
      try {
        let data = [];
        if (isFamilyDashbaord) {
          data = selectedRowKeys.map((applicationId) => ({
            applicationId,
            userId: values.assignee?.id,
          }));
        } else {
          data = selectedRowKeys.map((id) => ({
            applicationId: state.data.find((item) => item.id === id)?.application?.id,
            userId: values.assignee?.id,
          }));
        }

        let setAssignee = providerService.assignCoordinator;
        if (assignmentType === ASSIGNMENT_TYPES.CASE_OWNER) {
          setAssignee = isFamilyDashbaord ? familyService.assignCoordinator : providerService.assignCoordinator;
        } else if (
          assignmentType === ASSIGNMENT_TYPES.BGC_MANAGER_REVIEW ||
          assignmentType === ASSIGNMENT_TYPES.BGC_REVIEW
        ) {
          setAssignee = providerService.assignReviewer;
        } else if (
          assignmentType === ASSIGNMENT_TYPES.DOC_REVIEW ||
          assignmentType === ASSIGNMENT_TYPES.MANAGER_DOC_REVIEW
        ) {
          setAssignee = providerService.assignReviewer;
        } else if (
          assignmentType === ASSIGNMENT_TYPES.QC_REVIEW ||
          assignmentType === ASSIGNMENT_TYPES.QC_MANAGER_REVIEW
        ) {
          setAssignee = isFamilyDashbaord ? familyService.assignReviewer : providerService.assignReviewer;
        } else if (assignmentType === ASSIGNMENT_TYPES.MANAGER_REVIEW) {
          setAssignee = familyService.assignReviewer;
        }

        const response = await setAssignee(data);
        const failedApplications = response.filter((item) => !item.success).map((item) => item.applicationId);
        if (failedApplications.length > 0) {
          message.error({
            content: 'Assignment for some applications failed.',
            duration: 5,
          });
          setSelectedRowKeys((v) => v.filter((id) => failedApplications.indexOf(id) > -1));
        } else {
          message.success({
            content: 'Applications have been assigned.',
            duration: 5,
          });
          setSelectedRowKeys([]);
          reload();
          setVisible(false);
          form.resetFields();
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error({
          content: 'Assignment failed.',
          duration: 5,
        });
      }
    },
    [selectedRowKeys, setSelectedRowKeys, roles, isFamilyDashbaord, state, reload],
  );
  const assignmentTypes = useMemo(() => {
    let assigmentTypes = [];
    if (hasPermission(roles, [ROLES.PROVIDER_MANAGER])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.CASE_OWNER);
    }
    if (hasPermission(roles, [ROLES.FAMILY_MANAGER])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.CASE_OWNER);
    }
    if (hasPermission(roles, [ROLES.FAMILY_QC_MANAGER])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.QC_REVIEW);
    }
    if (hasPermission(roles, [ROLES.PROVIDER_QC_MANAGER])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.QC_REVIEW);
    }
    if (hasPermission(roles, [ROLES.FAMILY_QC_COORDINATOR, ROLES.FAMILY_COORDINATOR])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.QC_MANAGER_REVIEW);
    }
    if (hasPermission(roles, [ROLES.PROVIDER_BGC_MANAGER, ROLES.PROVIDER_COORDINATOR])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.BGC_REVIEW);
    }
    if (hasPermission(roles, [ROLES.PROVIDER_BGC_COORDINATOR, ROLES.PROVIDER_COORDINATOR])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.BGC_MANAGER_REVIEW);
    }
    if (hasPermission(roles, [ROLES.PROVIDER_REVIEWER_MANAGER])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.DOC_REVIEW);
    }
    if (hasPermission(roles, [ROLES.FAMILY_COORDINATOR])) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.MANAGER_REVIEW);
    }

    if (
      hasPermission(roles, [
        ROLES.PROVIDER_REVIEWER,
        ROLES.PROVIDER_REVIEWER_MANAGER,
        ROLES.PROVIDER_BGC_COORDINATOR,
        ROLES.PROVIDER_BGC_MANAGER,
        ROLES.PROVIDER_COORDINATOR,
        ROLES.PROVIDER_MANAGER,
      ])
    ) {
      assigmentTypes = assigmentTypes.concat(ASSIGNMENT_TYPES.MANAGER_DOC_REVIEW);
    }
    return assigmentTypes;
  }, [roles]);
  const roleTypes = useMemo(() => {
    let roleTypes = [];
    if (assignmentType === ASSIGNMENT_TYPES.CASE_OWNER && hasPermission(roles, [ROLES.PROVIDER_MANAGER])) {
      roleTypes = [ROLES.PROVIDER_COORDINATOR];
    }
    if (assignmentType === ASSIGNMENT_TYPES.CASE_OWNER && hasPermission(roles, [ROLES.FAMILY_MANAGER])) {
      roleTypes = [ROLES.FAMILY_COORDINATOR];
    }
    if (assignmentType === ASSIGNMENT_TYPES.MANAGER_REVIEW && hasPermission(roles, [ROLES.FAMILY_COORDINATOR])) {
      roleTypes = [ROLES.FAMILY_MANAGER];
    }
    if (assignmentType === ASSIGNMENT_TYPES.QC_REVIEW) {
      roleTypes = [isFamilyDashbaord ? ROLES.FAMILY_QC_COORDINATOR : ROLES.PROVIDER_QC_COORDINATOR];
    }
    if (assignmentType === ASSIGNMENT_TYPES.QC_MANAGER_REVIEW) {
      roleTypes = [isFamilyDashbaord ? ROLES.FAMILY_QC_MANAGER : ROLES.PROVIDER_QC_MANAGER];
    }
    if (assignmentType === ASSIGNMENT_TYPES.BGC_REVIEW) {
      roleTypes = [ROLES.PROVIDER_BGC_COORDINATOR];
    }
    if (assignmentType === ASSIGNMENT_TYPES.BGC_MANAGER_REVIEW) {
      roleTypes = [ROLES.PROVIDER_BGC_MANAGER];
    }
    if (assignmentType === ASSIGNMENT_TYPES.DOC_REVIEW) {
      roleTypes = [ROLES.PROVIDER_REVIEWER];
    }
    if (assignmentType === ASSIGNMENT_TYPES.MANAGER_DOC_REVIEW) {
      roleTypes = [ROLES.PROVIDER_REVIEWER_MANAGER, ROLES.PROVIDER_BGC_MANAGER];
    }
    return roleTypes;
  }, [assignmentType, roles, isFamilyDashbaord]);

  const { loading: loadingAssignees, result: assignees = [] } = useAsync(
    async (roles) => {
      const ids = userInfo?.teams?.reduce((prev, team) => `${prev}${prev ? ',' : ''}${team.id}`, '');
      return await userService.getRolesByTeam(ids);
    },
    [userInfo?.teams],
  );

  const assignmentTypeOptions = useMemo(
    () => assignmentTypes.map((title) => ({ id: title, title })),
    [assignmentTypes],
  );

  return (
    <Modal visible={visible} setVisible={setVisible} width={800} destroyOnClose>
      <h3 className="section-title text-primary">Batch Assign</h3>
      <div className="flex flex-col justify-end mt-4">
        {assignee && (
          <p className="text-primary font-medium mb-2">
            You are about to assign {batchAssignCount} new file{batchAssignCount > 1 ? 's' : ''} to{' '}
            <b>{getFullName(assignee)}</b>.
          </p>
        )}
      </div>
      <Form
        form={form}
        id="batch-assign-form"
        layout="vertical"
        className="grid grid-cols-1"
        onFinish={(values) => {
          batchAssign(values);
        }}
        onValuesChange={(changedValues) => {
          if (changedValues.assignmentType) {
            form.setFieldValue('assignee', undefined);
          }
        }}
      >
        <>
          <div className="flex flex-col justify-end mt-2">
            <div>
              <Form.Item label="Assignment Type" name="assignmentType" className="w-full">
                <Select
                  ariaLabel="Assignment Type"
                  options={assignmentTypeOptions}
                  onChangeFormatter={(v) => v?.id}
                  isOptionSelected={(option, [value]) => option.id === value}
                  getOptionValue={(option) => option.id}
                />
              </Form.Item>
              <Form.Item label="Assign To" name="assignee" className="w-full" data-testid="batch-assign-select">
                <AssignmentSelection
                  name="assignee"
                  ariaLabel="Assignee"
                  options={assignees.map((item) => ({
                    ...item,
                    title: getFullName(item),
                  }))}
                  isLoading={loadingAssignees}
                  requiredRoles={roleTypes}
                />
              </Form.Item>
            </div>
          </div>

          <div className="actions">
            <Button
              disabled={batchAssignmentLoading}
              onClick={() => {
                setVisible(false);
                form.resetFields();
              }}
              className="flex-1"
              data-testid="batch-assign-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              loading={batchAssignmentLoading}
              disabled={!assignee || !assignmentType || batchAssignmentLoading}
              htmlType="submit"
              type="primary"
              className="flex-1"
              data-testid="batch-assign-submit-btn"
            >
              Assign
            </Button>
          </div>
        </>
      </Form>
    </Modal>
  );
}

function AssignmentSelection({ options, value, onChange, name, ariaLabel, isLoading, id, requiredRoles, ...rest }) {
  return (
    <Select
      name={name}
      ariaLabel={ariaLabel}
      className="w-full"
      onChange={onChange}
      value={value}
      options={options}
      isLoading={isLoading}
      id={id}
      {...rest}
      components={{ Option: (props) => <UserOption {...props} requiredRoles={requiredRoles} /> }}
      // components={{
      //   Option: (props) => {
      //     const roles = props.data?.roles.filter((role) => requiredRoles.includes(role)).join(', ');
      //     return (
      //       <div
      //         className={classNames(
      //           'flex flex-col justify-between w-full hover:bg-primary-faded focus:bg-primary-faded py-2 px-4 shadow-sm cursor-pointer',
      //           { 'bg-primary-faded': props.isFocused },
      //         )}
      //         onClick={() => {
      //           props.selectOption(props.data);
      //         }}
      //         aria-label={props.data.label}
      //         data-testid={`option-${props.data.id}`}
      //       >
      //         <p className="text-md">{props.data.title}</p>
      //         <p className="text-xs text-gray-400">{props.data?.username}</p>
      //         <p title={roles} className="text-xs text-gray-400 truncate">
      //           {roles}
      //         </p>
      //       </div>
      //     );
      //   },
      // }}
    />
  );
}
