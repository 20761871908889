import React, { useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Button, InputNumber, message, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';

import Form from 'components/Form';
import Modal from 'components/Modal';
import { INCOME_FREQUENCIES } from 'constants/index';
import { familyService } from 'services';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import { FaPlus } from 'react-icons/fa';
import Table from 'components/Table';
import Select, { AsyncSelect } from 'components/Select';

export default function IncomeContent({ parent, heading = 'Parent Incomes', incomes = [], loading, reloadIncomes }) {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const [item, setItem] = useState({});
  const [form] = Form.useForm();

  const { loading: deleting, execute: deleteIncome } = useAsyncCallback(
    async (income) => {
      try {
        await familyService.deleteIncome(id, parent.id, income.id);
        message.success('Income deleted successfully');
        reloadIncomes(id, parent.id);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to delete income.');
      }
    },
    [id, parent?.id],
  );
  const allColumns = useMemo(
    () => [
      {
        title: <span className="action-header">Income Amount</span>,
        headerText: 'Income Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (value) => (value ? `$${value.toLocaleString()}` : 'N/A'),
      },
      {
        title: <span className="action-header">Income Type</span>,
        headerText: 'Income Type',
        dataIndex: ['type', 'title'],
        key: 'type',
        ellipsis: true,
      },

      {
        title: <span className="action-header">Payment Frequency</span>,
        headerText: 'Payment Frequency',
        dataIndex: ['frequency'],
        key: 'frequency',
        render: (frequency) => {
          return INCOME_FREQUENCIES.find((f) => f.id === frequency)?.title || 'N/A';
        },
      },

      {
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        align: 'center',
        width: 100,
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          return (
            <div className="actions-cell justify-center">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    form.setFieldsValue(record);
                    setVisible(true);
                  }}
                  style={{ width: 'auto' }}
                  icon={<AiOutlineEdit />}
                  aria-label="Edit"
                />
              </Tooltip>

              <Tooltip placement="top" title="Remove">
                <Button
                  className="icon-btn alert remove-button"
                  icon={<BiTrash />}
                  aria-label="Remove"
                  onClick={() => deleteIncome(record)}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [deleteIncome, form],
  );

  const { loading: updating, execute: updateIncome } = useAsyncCallback(
    async (values) => {
      try {
        await familyService.updateIncome(id, parent.id, values);
        setVisible(false);
        message.success(`Income ${values.id ? 'updated' : 'added'} successfully`);
        reloadIncomes(id, parent.id);
        form.resetFields();
      } catch (error) {
        newrelic.noticeError(error);
        message.error(`Unable to ${values.id ? 'update' : 'add'} income.`);
      }
    },
    [id, parent?.id],
  );

  return (
    <div className="bg-white px-6 pb-6 pt-4">
      <h3 className="text-primary text-base font-medium uppercase mb-4">{heading}</h3>
      <Button
        className="flex items-center w-full border-dashed mb-3"
        icon={<FaPlus className="mr-2" />}
        onClick={() => {
          setVisible(true);
          form.resetFields();
        }}
      >
        Add Income
      </Button>
      <Table
        id="incomes-table"
        className="separated"
        data={incomes || []}
        pagination={{ pageSize: 5, hideOnSinglePage: true }}
        allColumns={allColumns}
        loading={deleting || loading}
        showColSeparator
        showRowSeparator
        rowKey="id"
        hideEmptyBox={incomes?.length === 0}
        // {...tableProps}
      />

      <Modal width={900} visible={visible} setVisible={setVisible} destroyOnClose>
        <div className="section">
          <Form preserve form={form} layout="vertical" onFinish={updateIncome}>
            <h3 className="text-primary font-bold text-base uppercase mb-6">{item.id ? 'Update' : 'Add'} Income</h3>
            <Form.Item name="id" hidden>
              <InputNumber />
            </Form.Item>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">
              <div>
                <Form.Item name="type" label="Income Type">
                  <AsyncSelect
                    placeholder="Income Type"
                    getOptions={familyService.getOptions}
                    apiPrefix=""
                    optionsApiUrl="/options/10"
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item name="frequency" label="Income Frequency">
                  <Select
                    placeholder="Income Frequency"
                    options={INCOME_FREQUENCIES}
                    isOptionSelected={(option, [value]) => option.id === value}
                    onChangeFormatter={(value) => value.id}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item name="amount" label="Income Amount">
                  <InputNumber min={0} />
                </Form.Item>
              </div>
            </div>
            <div className="actions mt-6">
              <Button
                onClick={() => {
                  setVisible(false);
                  setItem({});
                }}
                disabled={updating}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" disabled={updating} loading={updating}>
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
