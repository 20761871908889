import { useState } from 'react';

import Button from 'components/Button';
import { MdOutlineOpenInFull } from 'react-icons/md';
import DetailsDrawer from 'components/DetailsDrawer';
import { IoMdTrash } from 'react-icons/io';

export default function EntityReview({ className, title, description, items, onDelete }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <div
        className={'border border-gray-300 m-8 p-8 rounded-sm flex items-center' + (className ? ` ${className}` : '')}
      >
        <div className="flex-grow">
          <h2 className="font-bold">{title}</h2>
          <h5>{description}</h5>
        </div>

        <div className="flex gap-4">
          {onDelete && (
            <Button
              className={`icon-btn alert remove-button bordered`}
              icon={<IoMdTrash className="w-5 h-5" />}
              onClick={onDelete}
              style={{ width: '40px', height: '40px' }}
            />
          )}

          <Button
            icon={<MdOutlineOpenInFull className="w-4 h-4" />}
            onClick={() => {
              setShowDetails(true);
            }}
            style={{ width: '40px' }}
          />
        </div>
      </div>

      <DetailsDrawer
        title={title}
        description={description}
        items={items}
        open={showDetails}
        setOpen={setShowDetails}
      />
    </>
  );
}
