import { useAsync } from 'react-async-hook';
import { commonService } from 'services';

export default function useProgram({ id, fallback = {} }) {
  const { result: program = [], loading } = useAsync(async () => {
    if (!id) return fallback;
    const programs = await commonService.get(`/common/programs?active=true`);
    return programs.find((item) => item.id === id) || fallback;
  }, [id]);

  return { program, loading };
}
