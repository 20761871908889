import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, message, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { BiTrash } from 'react-icons/bi';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Table from 'components/Table';
import Select from 'components/Select';
import useModalState from 'hooks/useModalState';
import DragDropUpload from 'components/DragDropUpload';
import { filesService } from 'services';
import { AiOutlineEdit } from 'react-icons/ai';
import { selectors } from 'features/documents';
import DocumentPreview from 'containers/Internal/DocumentsReview/DocumentsPreview';

const DocumentsTable = memo(function ({
  className,
  entityId,
  entityType,
  subEntityType,
  subEntityId,
  typeId,
  filter = () => true,
  readOnly = false,
  showTypeFallback = false,
  pagination = false,
  hideAddButton = false,
  pathPrefix = '',
}) {
  const { onOpen, setVisible, visible } = useModalState();
  const [item, setItem] = useState({});
  const onClose = useCallback(() => {
    setVisible(false);
    setItem({});
  }, [setVisible]);
  const documentTypes = useSelector(selectors.selectDocumentTypes);
  const type = useMemo(() => documentTypes.find((type) => type.id === typeId), [typeId, documentTypes]);

  const [previewingDocument, setPreviewingDocument] = useState(null);

  const {
    result: documents,
    loading: loadingDocuments,
    merge: mergeState,
  } = useAsync(
    async (entityType, entityId, subEntityType, subEntityId, typeId) => {
      try {
        const documents = await filesService.getDocuments({
          entityType,
          entityId,
          subEntityType,
          subEntityId,
          type: typeId,
        });
        return documents.filter(filter);
      } catch (error) {
        message.error('Unable to fetch documents.');
        return [];
      }
    },
    [entityType, entityId, subEntityType, subEntityId, typeId],
  );
  const setDocuments = useCallback(
    (documents) => {
      mergeState({ result: documents });
    },
    [mergeState],
  );

  const onAdd = useCallback(
    (newDocuments) => {
      const ids = newDocuments.map((doc) => doc.id);
      const prevDocs = documents.filter((doc) => !ids.includes(doc.id));
      setDocuments([...prevDocs, ...newDocuments]);
    },
    [documents, setDocuments],
  );
  const onDelete = useCallback(
    (document) => {
      setDocuments(documents.filter((doc) => doc.id !== document.id));
    },
    [documents, setDocuments],
  );
  const allColumns = useMemo(() => {
    const items = [
      {
        title: 'Name',
        dataIndex: 'name',
        fixed: 'left',
        ellipsis: true,
        width: 300,
        render: (name, record) => {
          return (
            <a
              href="#"
              className="text-blue-600 hover:underline block truncate"
              title={name}
              onClick={(e) => {
                e.preventDefault();
                setPreviewingDocument(record);
              }}
            >
              {name}
            </a>
          );
        },
      },
      {
        title: 'Category',
        dataIndex: 'type',
        ellipsis: true,
        width: 200,
        render: (type) => type?.description || 'N/A',
      },
      {
        title: 'Type',
        dataIndex: ['subtype', 'description'],
        render: (subtype, doc) => (subtype ? subtype : showTypeFallback ? doc?.type?.title || 'N/A' : 'N/A'),
        width: 200,
      },
    ];
    if (!readOnly) {
      items.push({
        title: 'Actions',
        width: 120,
        fixed: 'right',
        render: (document, index) => {
          return (
            <div className="flex justify-start space-x-3">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    setItem({ ...document, index });
                    onOpen();
                  }}
                  icon={<AiOutlineEdit />}
                  aria-label="Edit"
                  data-testid={`edit-document-${typeId}-${document?.id}`}
                />
              </Tooltip>

              <DeleteButton
                file={document}
                onDelete={onDelete}
                data-testid={`delete-document-${typeId}-${document?.id}`}
              />
            </div>
          );
        },
      });
    }

    return items;
  }, [onDelete, onOpen, readOnly, showTypeFallback, typeId]);

  return (
    <div className={classNames(className)}>
      <div>
        {!readOnly && !hideAddButton && (
          <Button className="border-dashed" onClick={onOpen} data-testid={`add-document-${typeId}`}>
            Add Document
          </Button>
        )}

        {Array.isArray(documents) && documents.length !== 0 ? (
          <Table
            data-testid={`documents-table-${typeId}`}
            className="my-5 [&_.ant-table]:!min-h-[100px]"
            allColumns={allColumns}
            data={documents}
            showColSeparator={false}
            loading={loadingDocuments}
            pagination={pagination}
            scroll={documents?.length > 0 ? { x: 1000 } : undefined}
          />
        ) : (
          <div className="mb-4" />
        )}
      </div>

      <Modal width={700} visible={!!previewingDocument} setVisible={setPreviewingDocument}>
        <DocumentPreview document={previewingDocument} />
      </Modal>

      <AddDocumentModal
        visible={visible}
        setVisible={onClose}
        entityId={entityId}
        entityType={entityType}
        subEntityId={subEntityId}
        subEntityType={subEntityType}
        onAdd={onAdd}
        item={item}
        type={type}
        showHoursField={type?.hoursRequired}
        showDateField={type?.datesRequired}
        documentTypes={documentTypes}
        typeId={typeId}
      />
    </div>
  );
});

export default DocumentsTable;

export const AddDocumentModal = memo(function ({
  visible,
  setVisible,
  entityId,
  entityType,
  subEntityId,
  subEntityType,
  onAdd,
  item,
  documentTypes,
  typeId,
  loadingSubtypes,
  showHoursField = false,
  showDateField = false,
}) {
  const [form] = Form.useForm();

  let type = Form.useWatch('type', form);
  if (typeId) {
    type = documentTypes.find((type) => type.id === typeId);
  }
  const onClose = useCallback(() => {
    setVisible(false);
    form.resetFields();
  }, [setVisible, form]);

  const showSubTypeField = useMemo(() => type?.subtypes?.length > 0, [type]);

  useEffect(() => {
    form.setFieldsValue({
      documents: item.id ? [item] : [],
      documentType: item.documentType,
      subtype: { id: item.subtype?.id, description: item.subtype?.title },
      ...item,
      type: typeId ? { id: typeId } : item.type,
    });
    form.setFieldsValue({
      type: typeId ? { id: typeId } : item.type,
      subtype: { id: item.subtype?.id, description: item.subtype?.title },
    });
  }, [form, item, typeId]);
  const { execute: onFinish, loading } = useAsyncCallback(
    async (values) => {
      const documents = values.documents.map((doc) => ({
        id: item.id,
        ...doc,
        entityId: Number(doc.entityId),
        subtype: values.subtype ?? null,
        type,
      }));
      try {
        if (item?.id) {
          const document = await filesService.updateDocument(item.id, documents[0]);
          onAdd([document]);
        } else {
          const newDocuments = await filesService.saveDocuments(documents);
          onAdd(newDocuments);
        }
        onClose();
      } catch (error) {
        message.error(`Unable to ${item.id ? 'update' : 'save'} documents.`);
      }
    },
    [onClose, onAdd, type],
  );
  return (
    <Modal visible={visible} setVisible={onClose} width={800}>
      <div className="section" data-testid="add-document-modal">
        <h3 className="section-title">Upload Document</h3>
        <Form onFinish={onFinish} form={form}>
          <div className="grid gap-x-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <Form.Item
              name="type"
              label="Categories"
              rules={[{ required: !typeId, message: 'Category is required.' }]}
              hidden={typeId}
            >
              <Select
                options={documentTypes.filter((docType) => docType.domain === 'Family')}
                isOptionSelected={(opt, [value]) => {
                  return opt.id === value?.id;
                }}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.description}
              />
            </Form.Item>
            {showSubTypeField && (
              <Form.Item name="subtype" label="Type">
                <Select
                  options={type?.subtypes || []}
                  isOptionSelected={(opt, [value]) => {
                    return opt.id === value?.id;
                  }}
                  getOptionValue={(opt) => opt.id}
                  // key={type?.id}
                />
              </Form.Item>
            )}
          </div>

          <Form.Item label="Upload Documents" required>
            <DragDropUpload
              name="documents"
              abbr="OTHER"
              entityId={entityId}
              entityType={entityType}
              subEntityType={subEntityType}
              subEntityId={subEntityId}
              keyPrefix="forms/family-details/documents"
              rules={[{ required: true, message: 'Document is required.' }]}
            />
          </Form.Item>
          <div className="actions">
            <Button onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={loading}
              loading={loading}
              data-testid={`save-document-${type?.id}`}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
});

function DeleteButton({ file, onDelete, ...rest }) {
  const { execute: deleteDocument, loading } = useAsyncCallback(async () => {
    try {
      await filesService.deleteDocument([file.id]);
      onDelete(file);
    } catch (error) {
      message.error('Unable to delete document.');
    }
  }, [file, onDelete]);
  return (
    <Tooltip placement="top" title="Remove">
      <Button
        className="icon-btn alert remove-button bordered"
        icon={<BiTrash size={16} />}
        aria-label="Remove"
        onClick={deleteDocument}
        loading={loading}
        disabled={loading}
        {...rest}
      />
    </Tooltip>
  );
}
