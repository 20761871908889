import React from 'react';

export default function PhoneNumberContainer({ phoneNumber, extension }) {
  return (
    <div className="flex-row flex space-x-4">
      <div className="w-3/5">{phoneNumber}</div>
      <div className="w-2/5">{extension}</div>
    </div>
  );
}
