import React from 'react';
import PropTypes from 'prop-types';
import CollapsePanel from 'components/CollapsePanel';
import classNames from 'classnames';

export default function Section({
  className,
  contentClassName = 'section-px -mt-2 pb-2',
  triggerClassName,
  headingClassName = 'section-title',
  heading,
  children,
  headerContainerClassName,
  collapsible = true,
  id,
  ...rest
}) {
  const header = (
    <div className={classNames('px-8 py-6 bg-white flex flex-row items-center', headerContainerClassName)}>
      <h4 className={classNames(headingClassName)}>{heading}</h4>
    </div>
  );
  const content = (
    <div id={id} className={classNames(contentClassName)}>
      {children}
    </div>
  );
  if (!collapsible) {
    return (
      <div className={classNames(className)}>
        {header}
        {content}
      </div>
    );
  }
  return (
    <CollapsePanel
      header={header}
      triggerClassName={classNames('absolute top-5 right-4', triggerClassName)}
      className={classNames('section-border', className)}
      {...rest}
    >
      {content}
    </CollapsePanel>
  );
}

Section.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  heading: PropTypes.any,
  headingClassName: PropTypes.string,
  triggerClassName: PropTypes.string,
  testId: PropTypes.string,
};
