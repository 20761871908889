import { Grid } from 'antd';

const { useBreakpoint } = Grid;

export default function useBreakpoints(props) {
  const screens = useBreakpoint();
  if (props?.collapsed === true || props?.collapsed === false) {
    if (screens.xxl) {
      screens.xxl = false;
    } else if (screens.xl) {
      screens.xl = false;
    } else if (screens.lg) {
      screens.lg = false;
    } else if (screens.md) {
      screens.md = false;
    } else if (screens.sm) {
      screens.sm = false;
    }
  }
  let screen = '';
  if (screens.xxl) screen = 'xxl';
  else if (screens.xl) screen = 'xl';
  else if (screens.lg) screen = 'lg';
  else if (screens.md) screen = 'md';
  else if (screens.sm) screen = 'sm';
  else if (screens.xs) screen = 'xs';
  return { ...screens, screen };

  // const { collapsed } = useContext(LayoutContext);
  // let screen = '';
  // if (screens.xxl) screen = collapsed ? 'xl' : 'xxl';
  // else if (screens.xl) screen = collapsed ? 'lg' : 'xl';
  // else if (screens.lg) screen = collapsed ? 'md' : 'lg';
  // else if (screens.md) screen = collapsed ? 'sm' : 'md';
  // else if (screens.sm) screen = collapsed ? 'xs' : 'sm';
  // else if (screens.xs) screen = 'xs';
  // return { ...screens, screen };
}
