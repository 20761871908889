import React, { useCallback, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import useMilitaryInfo from 'hooks/useMilitaryInfo';

import IncomeHeader from '../IncomeHeader';
import IncomeContent from '../IncomeContent';
import EmploymentHeader from '../EmploymentHeader';
import PersonalInfoHeader from '../PersonalInfoHeader';
import FamilyReviewContainer from '../FamilyReviewContainer';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import EmploymentContent from '../EmploymentContent';
import { useAsync } from 'react-async-hook';
import { message } from 'antd';
import { familyService } from 'services';
import AdultFamilyMembers from 'containers/Family/Profile/AdultFamilyMembers';

const SPONSOR_STEPS_ORDER = ['info', 'employment', 'income'];

export default function SponsorDetails({ application, loading, rootRef }) {
  const { pathname } = useLocation();
  const { id, householdId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { applicant } = application;

  const { installation, branch, component, status } = useMilitaryInfo({ person: applicant || {} });
  const parent = useMemo(() => {
    return { ...applicant, installation, branch, component, status };
  }, [applicant, branch, component, installation, status]);

  let current = useMemo(() => SPONSOR_STEPS_ORDER.findIndex((key) => pathname.includes(key)) || 0, [pathname]);
  if (pathname.endsWith('/review')) {
    current = 0;
  }
  current = current === -1 ? 0 : current;
  const onChange = useCallback(
    (index) => {
      history.push(`${url}/sponsor/${SPONSOR_STEPS_ORDER[index]}`);
    },
    [history, url],
  );

  const { routePrefix, pathPrefix } = useMemo(() => {
    return {
      routePrefix: `${path}/sponsor/${SPONSOR_STEPS_ORDER[current]}`,
      pathPrefix: `${url}/sponsor/${SPONSOR_STEPS_ORDER[current]}`,
    };
  }, [current, path, url]);

  const {
    loading: loadingIncomes,
    result: { incomes = [], totalAnnualIncome = 0 } = { incomes: [], totalAnnualIncome: 0 },
    execute: fetchIncomes,
  } = useAsync(async () => {
    if (!id || !parent?.id) return [];
    try {
      const { totalAnnualIncome = 0, incomes = [] } = await familyService.getIncomes(id, parent?.id);
      return { totalAnnualIncome, incomes };
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to get income.');
      return { totalAnnualIncome: 0, incomes: [] };
    }
  }, [id, parent?.id]);

  const sponsorIndex = useMemo(
    () => application?.adults?.findIndex((item) => item.id === application?.applicant?.id),
    [application?.adults, application?.applicant?.id],
  );
  const commonProps = useMemo(
    () => ({
      profileData: { adults: application?.adults || [], children: application?.children || [], id: householdId },
      application,
      loading: false,
      reload: () => {},
      getItem: () => ({ ...application?.adults?.[sponsorIndex], index: sponsorIndex }),
      item: application?.applicant,
      personId: application?.applicant?.id,
    }),

    [application, householdId, sponsorIndex],
  );

  const headerExtraElement = useMemo(
    () => <AdultFamilyMembers {...commonProps} hideTable isWrapperWithSection={false} currentStep={current} />,
    [commonProps, current],
  );

  const props = useMemo(
    () => ({
      id,
      householdId,
      application,
      parent,
      loading,
      headerExtraElement,
    }),
    [application, headerExtraElement, householdId, id, loading, parent],
  );

  const statusPathName = useMemo(() => pathname.replace(/\/documents(.*)$/, ''), [pathname]);
  const incomeNext = useMemo(() => {
    if (application.additionalParents?.[0]) {
      return `${url}/secondParent/${application.additionalParents[0].id}/info`;
    } else if (application?.children?.[0]) {
      return `${url}/children/${application.children[0].id}/info`;
    }
    return `${url}/sponsor/info`;
  }, [application.additionalParents, application.children, url]);
  const stepItems = useMemo(
    () => [
      {
        title: 'Basic Info',
        status:
          statusPathName.endsWith('/review') ||
          statusPathName.endsWith('/sponsor') ||
          statusPathName.endsWith('/sponsor/info')
            ? 'process'
            : 'wait',
        header: <PersonalInfoHeader {...props} />,
        headerClassName: '-mt-4 pt-4',
        prev: `/families/${householdId}/applications/${application.id}`,
        next: `${url}/sponsor/employment`,
      },
      {
        title: 'Employment',
        status: statusPathName.endsWith('/sponsor/employment') ? 'process' : 'wait',
        header: <EmploymentHeader {...props} branch={branch} component={component} status={status} />,
        content: <EmploymentContent {...props} />,
        prev: `${url}/sponsor/info`,
        next: `${url}/sponsor/income`,
      },
      {
        title: 'Income',
        status: statusPathName.endsWith('/sponsor/income') ? 'process' : 'wait',
        header: <IncomeHeader {...props} loading={loadingIncomes} totalAnnualIncome={totalAnnualIncome} />,
        content: (
          <IncomeContent
            {...props}
            heading="Sponsor Incomes"
            incomes={incomes}
            loading={loadingIncomes}
            reloadIncomes={fetchIncomes}
          />
        ),
        prev: `${url}/sponsor/employment`,
        next: incomeNext,
      },
    ],
    [
      statusPathName,
      props,
      householdId,
      application.id,
      url,
      branch,
      component,
      status,
      loadingIncomes,
      totalAnnualIncome,
      incomes,
      fetchIncomes,
      incomeNext,
    ],
  );

  return (
    <div>
      <FamilyReviewContainer
        stepItems={stepItems}
        current={current}
        onChange={onChange}
        rootRef={rootRef}
        routePrefix={routePrefix}
        pathPrefix={pathPrefix}
      />
    </div>
  );
}
