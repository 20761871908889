import React, { useMemo, useCallback } from 'react';
import { Button, Col, Divider, Input, InputNumber, Row } from 'antd';

import useEntityGroup from 'hooks/useEntityGroup';
import useChangeRequestClassName from 'hooks/useChangeRequestClassName';
import { getAgeInWeeks, getValue, renderAgeRange } from 'utils';
import { YEAR_TO_WEEKS } from 'constants/index';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import AgeSelect from 'components/AgeSelect/AgeSelectInputNumber';
import Card from 'components/Card/Card';

export default function AgeGroups({ value: items = [], onChange, center, readOnly }) {
  const programs = useMemo(
    () => center?.application?.programCategories || [],
    [center?.application?.programCategories],
  );
  const getAllColumns = useCallback(() => {
    const allColumns = [
      {
        title: <span className="action-header">Name</span>,
        headerText: 'Name',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
      },
      {
        title: <span className="action-header">Age Range</span>,
        headerText: 'Vacancies',
        dataIndex: 'vacancies',
        key: 'vacancies',
        ellipsis: true,
        render: (_, { minAge, maxAge }) => {
          return <div>{renderAgeRange({ minAge, maxAge })}</div>;
        },
      },
      {
        title: <span className="action-header">Vacancies</span>,
        headerText: 'Vacancies',
        dataIndex: 'vacancies',
        key: 'vacancies',
        ellipsis: true,
        render: (value) => {
          return value || 0;
        },
      },
    ];

    return allColumns;
  }, []);

  const keys = useMemo(() => ['id', 'name', ['type', 'id'], 'minAge', 'maxAge', 'vacancies'], []);
  const rowClassName = useChangeRequestClassName({ keys, snapshotKey: ['ageGroups'] });
  const getFormFields = useCallback(
    ({ values, setVisible, items, form, item, index }) => {
      const getSnapshotValue = (snapshot, name) => {
        return getValue(
          snapshot.ageGroups.find((v) => v.id === item?.id),
          name,
        );
      };
      let minAllowedAge = 0; // 0 weeks
      let maxAllowedAge = 13; // 13 years

      if (programs.find((prog) => prog.title === 'EFM Respite')) {
        maxAllowedAge = 19; // 19 years
      }
      if (programs.find((prog) => prog.title === 'Child Care in Your Home')) {
        minAllowedAge = 2; // 2 weeks
      }
      return (
        <>
          <Row gutter={[20, 0]} className="form-row">
            <Col xs={24} md={12} xl={16}>
              <FormItem
                name="name"
                label="Age Group Name"
                rules={[
                  { required: true, message: 'Age Group Name is required.' },
                  { max: 30, message: 'Group name is too long.' },
                ]}
                getSnapshotValue={getSnapshotValue}
              >
                <Input placeholder="Age Group Name" />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                name={['minAge']}
                label="Age Range Low"
                dependencies={['maxAge']}
                rules={[
                  { required: true, message: 'Age Range Low is required.' },
                  () => ({
                    async validator(_, value) {
                      if (value === undefined) {
                        return;
                      }
                      const minAge = value || {};
                      const minAgeWeeks = getAgeInWeeks(minAge);
                      if (minAgeWeeks < minAllowedAge) {
                        throw new Error('Age Range Low must be greater/equal than minimum allowed age.');
                      }
                    },
                  }),
                ]}
                getSnapshotValue={getSnapshotValue}
              >
                <AgeSelect
                  help={
                    minAllowedAge > 0 && (
                      <p className="text-gray-500 text-xs mt-0.5" id={`help-minAge`}>
                        Eligibility is from {minAllowedAge} weeks up to the day before the child's 13th birthday
                      </p>
                    )
                  }
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                name={['maxAge']}
                label="Age Range High"
                dependencies={['minAge']}
                rules={[
                  { required: true, message: 'Age Range High Served is required.' },
                  ({ getFieldValue }) => ({
                    async validator(_, value) {
                      const minAge = getFieldValue(['minAge']) || {};
                      const maxAge = value || {};
                      const minAgeWeeks = getAgeInWeeks(minAge);
                      const maxAgeWeeks = getAgeInWeeks(maxAge);
                      if (maxAgeWeeks > maxAllowedAge * YEAR_TO_WEEKS) {
                        throw new Error('Age Range High must be less than allowed Age Range High.');
                      }
                      if (value === undefined || minAgeWeeks < getAgeInWeeks(maxAge)) {
                        return Promise.resolve();
                      }
                      if (minAgeWeeks > maxAgeWeeks) {
                        throw new Error('Age Range High must be greater than Age Range Low.');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                getSnapshotValue={getSnapshotValue}
              >
                <AgeSelect
                  help={
                    maxAllowedAge > 0 && (
                      <p className="text-gray-500 text-xs mt-0.5" id={`help-maxAge`}>
                        Eligibility is up to the day before the child's {maxAllowedAge}th birthday
                      </p>
                    )
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <Row className="form-row" gutter={[20, 0]}>
            <Col xs={32} sm={16} md={12}>
              <FormItem label="Vacancies" name={['vacancies']} getSnapshotValue={getSnapshotValue}>
                <InputNumber placeholder="0" min={0} controls={false} />
              </FormItem>
            </Col>
          </Row>

          <Divider className="mt-0" />

          <Divider className="mt-0" />

          <div className="actions flex mt-5">
            <Button onClick={() => setVisible(false)} data-testid="cancel-ageGroup-btn">
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" data-testid="save-ageGroup-btn">
              Save
            </Button>
          </div>
        </>
      );
    },
    [programs],
  );

  const { section } = useEntityGroup({
    items,
    onChange,
    entity: center,
    getAllColumns,
    getFormFields,
    readOnly,
    entityTitle: 'age group',
    tableProps: {
      rowKey: (record) => {
        return `${record.id}-${record.uid}`;
      },
      rowClassName,
    },
    entityName: 'ageGroup',
    getFormInitialValues: (item, index) => {
      return { ...item, rates: items[index]?.rates };
    },
  });
  return (
    <Card collapsible title="Age Groups">
      <Form.Item
        name="rates"
        dependencies={[['ageGroups']]}
        rules={[
          (form) => {
            return {
              validator: () => {
                const ageGroups = form.getFieldValue(['ageGroups']);
                if (ageGroups?.length > 0) return Promise.resolve();
                return Promise.reject('At least one age group is required.');
              },
            };
          },
        ]}
      >
        {section}
      </Form.Item>
    </Card>
  );
}
