import { useMemo, memo, useState, useCallback } from 'react';
import { Button } from 'antd';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import Table from 'components/Table';
import { formatDate, getFullName, hoursRenderer } from 'utils';
import { DAYS_OF_WEEK } from 'constants/index';

function SchedulesTable({ data, selectedProviderId, value: schedules, onChange }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const allColumns = useMemo(
    () => [
      {
        title: <span className="action-header">Child Name</span>,
        headerText: 'Child Name',
        dataIndex: ['child'],
        key: 'child',
        ellipsis: true,
        className: 'truncate max-w-xs',
        width: 120,
        fixed: 'left',
        render: (child) => <span>{getFullName(child) || 'N/A'}</span>,
      },
      {
        title: <span className="action-header">Date of Birth</span>,
        headerText: 'Date of Birth',
        dataIndex: ['child', 'dateOfBirth'],
        key: 'child',
        ellipsis: true,
        className: 'truncate max-w-xs',
        width: 120,
        render: (dateOfBirth) => <span>{dateOfBirth || 'N/A'}</span>,
      },
      {
        title: <span className="action-header">Type of Child Care</span>,
        headerText: 'Type of Child Care',
        dataIndex: ['careDurationType', 'title'],
        key: 'careType',
        width: 140,
        align: 'left',
        className: 'truncate max-w-xs',
      },

      {
        title: <span className="action-header">Care Start Date</span>,
        headerText: 'Care Start Date',
        dataIndex: 'startDate',
        key: 'careStartDate',
        width: 120,
        align: 'left',
        render: (careStartDate) => <span>{formatDate(careStartDate)}</span>,
      },
      {
        title: <span className="action-header">Care End Date</span>,
        headerText: 'Care End Date',
        key: 'careEndDate',
        dataIndex: 'endDate',
        align: 'left',
        width: 120,
        render: (careEndDate) => <span>{formatDate(careEndDate)}</span>,
      },
      {
        title: <span className="action-header">Provider Name</span>,
        headerText: 'Provider Name',
        dataIndex: 'careFacility',
        key: 'providerName',
        width: 150,
        ellipsis: true,
        render: (careFacility) => careFacility?.businessLegalName || 'N/A',
      },
      {
        title: <span className="action-header">Provider Id</span>,
        headerText: 'Provider Id',
        dataIndex: ['careFacility', 'id'],
        key: 'providerId',
        width: 100,
        ellipsis: true,
        render: (careFacilityId) => careFacilityId || 'N/A',
      },
      {
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 80,
        fixed: 'right',
        render: (record) => {
          return (
            <div className="flex justify-center">
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                  } else {
                    setExpandedRows([record.id]);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                className="icon-btn"
                aria-label="Open"
                data-testid={`collapse-button-${record.id}`}
                // disabled={loading}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            </div>
          );
        },
      },
    ],
    [expandedRows],
  );

  const isRowDisabled = useCallback(
    (record) => {
      const [schedule] = schedules || [];
      const scheduleTitle = schedule?.careDurationType?.title;

      let disabled = false;
      if (!schedule || schedule?.id === record.id) {
        disabled = false;
      } else {
        if (scheduleTitle !== 'Before School' && scheduleTitle !== 'After School') {
          disabled = true;
        }
        if (scheduleTitle === 'Before School' && record.careDurationType.title !== 'After School') {
          disabled = true;
        }
        if (scheduleTitle === 'After School' && record.careDurationType.title !== 'Before School') {
          disabled = true;
        }
      }
      disabled =
        disabled || !record.careFacility?.id || (selectedProviderId && record.careFacility?.id !== selectedProviderId);
      return disabled;
    },
    [schedules, selectedProviderId],
  );

  const rowSelection = {
    selectedRowKeys: schedules?.map?.((v) => v.id) || [],
    onChange: (keys, rows) => {
      onChange(rows);
    },
    hideSelectAll: true,
    getCheckboxProps: (record) => ({
      disabled: isRowDisabled(record),
    }),
  };
  return (
    <Table
      className="w-full [&_.ant-table]:!min-h-[100px] mb-2"
      allColumns={allColumns}
      data={data}
      rowSelection={rowSelection}
      showColSeparator={false}
      pagination={false}
      scroll={{ x: 1200 }}
      expandable={{
        expandedRowKeys: expandedRows,
        expandedRowRender: (record) => <ExpandedRow record={record} />,
        rowExpandable: (record) => true,
        expandIcon: () => null,
        columnWidth: 0,
      }}
      // rowClassName={(record, index) => {
      //   const isDisabled = isRowDisabled(record);
      //   return classNames(index % 2 === 0 ? 'bg-blue-700 bg-opacity-5 h-9' : 'bg-blue-700 bg-opacity-2 h-9', {
      //     '[&_.ant-table-cell]:!text-gray-400': isDisabled,
      //   });
      // }}
    />
  );
}

export default memo(SchedulesTable);

function ExpandedRow({ record }) {
  const allColumns = useMemo(() => {
    return DAYS_OF_WEEK.map((day, index) => ({
      title: <span className="action-header">{day.shortName}</span>,
      headerText: 'Mon',
      key: 'monday',
      dataIndex: ['days', index],
      align: 'left',
      width: 65,
      className: 'truncate max-w-xs',
      render: hoursRenderer,
    }));
  }, []);

  return (
    <div className="px-4 py-6 [&_.ant-table]:!min-h-[100px]">
      <h4 className="text-primary font-semibold text-15">Schedule</h4>
      <Table allColumns={allColumns} data={[record]} showColSeparator={false} pagination={false} rowKey="id" />
    </div>
  );
}
