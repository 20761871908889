import React from 'react';
import { Divider, Input, Select as AntdSelect } from 'antd';

import Section from 'components/Section';
import { getFullName } from 'utils';
import Form from 'components/Form';
import classNames from 'classnames';
import Select, { AsyncSelect } from 'components/Select';
import { familyService } from 'services';
import AddressFields from 'components/AddressFields';
import AriaDatePicker from 'components/AriaDatePicker';

export default function ChildCreation({ application, childId, childIds, index }) {
  const form = Form.useFormInstance();
  const prefix = Form.useWatch([childId, 'prefix'], form);
  const firstName = Form.useWatch([childId, 'firstName'], form);
  const lastName = Form.useWatch([childId, 'lastName'], form);

  // const values = Form.useWatch([childId], form);

  const _fullName = [prefix?.title, firstName, lastName].filter(Boolean).join(' ') || '-';
  const _sponsorFullName = getFullName(application?.applicant) || 'N/A';

  // const fillWithDemoData = () => {
  //   form.setFieldValue(childId, APPLICATION_DEMO_DATA[6]);
  // };

  return (
    <>
      <Section
        className={classNames('bg-white', {
          'mb-6': index !== childIds.length - 1,
          'mt-6': index !== 0,
        })}
        headingClassName="page-title"
        heading={`Additional Child #${index + 1}`}
        contentClassName=""
      >
        {/* <div className="px-8 mb-8">
          <Button
            type="primary"
            className="mt-[2px]"
            icon={<ImMagicWand className="align-middle mt-[-1px]" />}
            onClick={fillWithDemoData}
          >
            Fill with Demo Data
          </Button>
        </div> */}

        <Section headingClassName="page-title" heading="Basic Information" collapsible={false}>
          <div className="flex flex-wrap gap-4 w-[900px]">
            <Form.Item
              name={[childId, 'firstName']}
              label="First Name"
              rules={[{ required: true, message: 'First Name is required' }]}
              className="w-[32%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item name={[childId, 'middleName']} label="Middle Name/Initial" className="w-[20%]">
              <Input />
            </Form.Item>

            <Form.Item
              name={[childId, 'lastName']}
              label="Last Name"
              rules={[{ required: true, message: 'Last Name is required.' }]}
              className="w-[32%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item name={[childId, 'suffix']} label="Select Suffix" className="w-[32%]">
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/20"
                placeholder="Select..."
                ariaLabel="Select Suffix"
              />
            </Form.Item>

            <Form.Item
              name={[childId, 'gender']}
              label="Sex"
              rules={[{ required: true, message: 'Sex is required.' }]}
              className="w-[20%]"
            >
              <Select
                options={[
                  { id: 'MALE', title: 'Male' },
                  { id: 'FEMALE', title: 'Female' },
                  { id: 'INTERSEX', title: 'Intersex' },
                  { id: 'PREFER_NOT_TO_SAY', title: 'Prefer Not To Say' },
                ]}
                placeholder="Select Sex"
                ariaLabel="Select Sex"
                onChangeFormatter={(v) => v.id}
                isOptionSelected={(option, [value]) => option.id === value}
              />
            </Form.Item>

            <Form.Item
              name={[childId, 'dateOfBirth']}
              label="Date of Birth"
              rules={[{ required: true, message: 'Date of Birth is required.' }]}
              className="w-[32%]"
            >
              <AriaDatePicker placeholder="Date of Birth" />
            </Form.Item>
          </div>
        </Section>

        <Section headingClassName="page-title" heading="Home Address" collapsible={false}>
          <div className="ant-form-item">
            <div className="ant-row ant-form-item-row">
              <div className="ant-col ant-form-item-label">
                <label htmlFor="address_existing" className="ant-form-item-required" title="Use existing address from:">
                  Use existing address from:
                </label>
              </div>

              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <AntdSelect
                      id="address_existing"
                      allowClear
                      placeholder="Select..."
                      options={[
                        { value: 'sponsor', label: _sponsorFullName },
                        ...application?.additionalParents?.map((_parent) => ({
                          value: _parent.id,
                          label: getFullName(_parent),
                        })),
                      ]}
                      onChange={(value) => {
                        if (value) {
                          if (value === 'sponsor') {
                            form.setFieldValue([childId, 'homeAddress'], application?.applicant?.homeAddress);
                          } else {
                            form.setFieldValue(
                              [childId, 'homeAddress'],
                              application?.additionalParents?.find((_parent) => _parent.id === value)?.homeAddress,
                            );
                          }
                        } else {
                          form.setFieldValue([childId, 'homeAddress'], null);
                        }
                      }}
                      style={{ width: '250px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Divider orientation="left" className="block py-4">
            OR
          </Divider>

          <div className="flex flex-wrap gap-4 w-[900px]">
            <AddressFields addressKey={[childId, 'homeAddress']} />
          </div>
        </Section>

        <Section headingClassName="page-title" heading="Relationship to Sponsor" collapsible={false}>
          <div className="flex gap-3 mb-6 items-center">
            <span className="font-bold">{_sponsorFullName}</span>

            <span>is the</span>

            <Form.Item
              name={[childId, 'relationshipType']}
              rules={[{ required: true, message: 'This field is required.' }]}
              className="m-0"
            >
              <AsyncSelect
                name={[childId, 'relationshipType']}
                placeholder="Relation"
                ariaLabel="Relationship to Sponsor"
                optionsApiUrl="/options/26"
                apiPrefix="/families"
                isClearable={false}
              />
            </Form.Item>

            <span>of</span>

            <span className="font-bold">{_fullName}</span>
          </div>
        </Section>
      </Section>
    </>
  );
}
