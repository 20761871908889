import React, { useMemo } from 'react';
import { Button } from 'antd';
import { AiOutlineEdit } from 'react-icons/ai';

import Table from 'components/Table';

export default function SponsorInstallationTable({ installation, setVisible }) {
  const columns = useMemo(
    () => [
      {
        title: <span className="action-header">Name</span>,
        headerText: 'Name',
        dataIndex: ['name'],
        key: 'name',
        ellipsis: true,
        className: 'truncate max-w-xs',
      },
      // {
      //   title: <span className="action-header">Abbreviation</span>,
      //   headerText: 'Abbreviation',
      //   dataIndex: 'abbreviation',
      //   key: 'abbreviation',
      //   ellipsis: true,
      // },
      {
        title: <span className="action-header">State</span>,
        headerText: 'State',
        dataIndex: 'state',
        key: 'state',
        ellipsis: true,
        className: 'truncate max-w-xs',
        render: (state, record) => record?.address?.state || 'N/A',
      },
      {
        title: <span className="action-header">Installation Cost</span>,
        headerText: 'Low/High Cost',
        dataIndex: 'highCost',
        key: 'instBhighCost',
        ellipsis: true,
        render: (highCost) => <span>{highCost ? 'High' : 'Standard'}</span>,
      },
      {
        title: <span className="action-header mt-2">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 100,
        align: 'center',
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
          return (
            <div className="actions-cell bordered space-x-1 justify-center">
              <Button
                onClick={() => setVisible(true)}
                icon={<AiOutlineEdit />}
                className="icon-btn"
                aria-label="Edit"
                data-testid={`edit-btn-${record?.id}`}
                title="Edit"
              />
            </div>
          );
        },
      },
    ],
    [setVisible],
  );

  return (
    <Table
      allColumns={columns}
      xsCols={['name', 'abbreviation', 'actions']}
      smCols={['name', 'abbreviation', 'actions']}
      mdCols={['name', 'abbreviation', 'state', 'actions']}
      lgCols={['name', 'abbreviation', 'state', 'instBhighCost', 'actions']}
      data={[installation]}
      showColSeparator={false}
      pagination={false}
    />
  );
}
