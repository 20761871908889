import { Typography } from 'antd';
import Form from './Form';

export const MAX_INPUT_PX_WIDTH = 600;
export default Form;

export const FormItemLabel = ({ title, subTitle, showAsterisk }) => (
  <span>
    {showAsterisk && <span className="text-red-500 mr-1">*</span>}

    {title}

    <Typography.Text
      type="secondary"
      style={{
        display: 'block',
        fontSize: '14px',
        fontWeight: 'normal',
        textTransform: 'none',
        letterSpacing: '0px',
        wordSpacing: '1px',
        textIndent: '0',
        whiteSpace: 'normal',
      }}
    >
      {subTitle}
    </Typography.Text>
  </span>
);

export const DatePickerFormItemLabel = ({ title }) => <FormItemLabel title={title} subTitle="mm/dd/yyyy" />;
