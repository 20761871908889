import axios from 'axios';
import config from 'config';
import { setupCache } from './interceptors';

const instance = axios.create({
  baseURL: `${config.API_BASE_URL}/alerts/alerts`,
});
const request = setupCache(instance);

const api = {
  createAlert: async (body) => {
    const { data } = await request.post(`/`, body);
    return data;
  },
  updateAlert: async (id, body) => {
    const { data } = await request.put(`/${id}`, body);
    return data;
  },
  getAlertDetails: async (id) => {
    const { data } = await request.get(`/${id}`);
    return data;
  },
  getWatchers: async (alertId) => {
    const { data } = await request.put(`/${alertId}/watchers`);
    return data;
  },
  updateWatchers: async (alertId, userIds) => {
    const { data } = await request.put(`/${alertId}/watchers?userIds=${userIds}`);
    return data;
  },
  getAlertWatchers: async (id) => {
    const { data } = await request.get(`/${id}/watchers`);
    return data;
  },
  removeWatchers: async (id, userIds) => {
    const { data } = await request.delete(`/${id}/watchers?userIds=${userIds}`);
    return data;
  },
  createAlertNote: async (alertId, body) => {
    const { data } = await request.post(`/${alertId}/notes`, body);
    return data;
  },
  updateAlertNote: async (alertId, noteId, body) => {
    const { data } = await request.put(`/${alertId}/notes/${noteId}`, body);
    return data;
  },
  deleteAlertNote: async (alertId, noteId) => {
    const { data } = await request.delete(`/${alertId}/notes/${noteId}`);
    return data;
  },
  resolveAlert: async (id, resolved) => {
    const { data } = await request.put(`/${id}/${resolved ? 'unresolve' : 'resolve'}`);
    return data;
  },
};
export { request };
export default api;
