import React from 'react';
import { message } from 'antd';
import { useAsync } from 'react-async-hook';

import EmailPreview from 'components/EmailPreview';
import communicationsService from 'services/communications.service';

export default function EmailPreviewPage({ match }) {
  const { messageId } = match?.params || {};
  const { loading, result: communicationDetails } = useAsync(async () => {
    try {
      return await communicationsService.getCommunicationDetails(messageId);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Email not found.', 5);
      return {};
    }
  }, [messageId]);
  return (
    <div className="bg-white border-l border-gray-200">
      <EmailPreview loading={loading} data={communicationDetails} messageId={messageId} />
    </div>
  );
}
