export default function getTemplate({ fullName }) {
  return `
  <p>
    Dear ${fullName},
  </p>
  <p><br></p>
  <p>Thank you for your patience while the Army reviewed your case. Your family has been approved to use your state-licensed, non-accredited child care provider to apply for fee assistance. Please see the attached letter for additional information.</p>
  <p><br></p>
  <p>Under the Continuity of Care policy, you are able to continue use of your MCCYN-Ex (Tier 2 or 3) state-licensed only provider and receive fee assistance for as long as they remain at this physical location and within the AFA program eligibility guidelines. You may also add additional new dependent children at this location. However, 90 days must not lapse in resolving an expired certificate at this provide nor can there be absence of care of 90 or more days, or eligibility may be lost.</p>
  <p><br></p>
  <p>Please review the attached letter in regards to the provider you would like to use. Please note: this is not your final approval. A separate email was sent for the additional information needed to complete your file.</p>
  <p><br></p>
`;
}
