import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { actions } from 'features/application-state';

export default function useBreadcrumbParams(key) {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(actions.setBreadcrumbsParams({ [key]: params[key] }));
    return () => {
      dispatch(actions.setBreadcrumbsParams({}));
    };
  }, [params, dispatch, key]);
}
