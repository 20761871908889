import React, { useCallback, useMemo } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { IoMdRefresh } from 'react-icons/io';

import Emails from 'components/Emails';
import NestedPage from 'components/NestedPage';
import useModalState from 'hooks/useModalState';
import { EMAIL_TYPES, ENTITY_TYPES } from 'constants/index';
import SendEmailModal from 'components/Modals/SendEmailModal';
import useTablePagination from 'hooks/useTablePagination';

const PAGE_SIZE = 10;

export default function ApplicationEmails({ match, entityType, entity, className }) {
  const { id } = match.params;
  const { visible, onClose, onOpen } = useModalState();
  const mapper = useCallback((obj) => obj, []);

  const scheduledEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      scheduled: true,
      incoming: false,
      statuses: ['PENDING'],
      sort: 'id,desc',
      entityType,
      entityId: id,
    }),
    [entityType, id],
  );
  const scheduledEmailsData = useTablePagination({
    url: `/communications/email`,
    params: scheduledEmailParams,
    mapper,
  });

  const sentEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      scheduled: false,
      incoming: false,
      statuses: ['DELIVERED', 'QUEUED', 'ERROR'],
      sort: 'id,desc',
      entityType,
      entityId: id,
    }),
    [entityType, id],
  );
  const sentEmailsData = useTablePagination({
    url: `/communications/email`,
    params: sentEmailParams,
    mapper,
  });

  const incomingEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      incoming: true,
      scheduled: false,
      statuses: ['DELIVERED', 'QUEUED', 'ERROR'],
      sort: 'id,desc',
      entityType,
      entityId: id,
    }),
    [entityType, id],
  );
  const incomingEmailsData = useTablePagination({
    url: `/communications/email`,
    params: incomingEmailParams,
    mapper,
  });
  const reload = useCallback(() => {
    scheduledEmailsData.reload();
    sentEmailsData.reload();
    incomingEmailsData.reload();
  }, [incomingEmailsData, scheduledEmailsData, sentEmailsData]);

  const actions = (
    <>
      <Button
        onClick={onOpen}
        className="border border-gray-100 flex items-center justify-center"
        data-testid="schedule-email-btn"
      >
        Schedule Email
      </Button>
      <Button
        className="!w-10 h-10 border border-gray-100 flex items-center justify-center"
        icon={
          <IoMdRefresh
            size={24}
            className={classNames('text-primary', {
              'animate-spin': scheduledEmailsData.loading || sentEmailsData.loading,
            })}
          />
        }
        data-testid="reload-btn"
        onClick={reload}
        loading={scheduledEmailsData.loading || sentEmailsData.loading}
        disabled={scheduledEmailsData.loading || sentEmailsData.loading}
      />
    </>
  );
  return (
    <NestedPage heading="Messages" actions={actions} className={className}>
      <div className="px-4 py-4">
        <h3 className="text-primary font-medium text-md pl-3">Scheduled Emails</h3>
        <Emails state={scheduledEmailsData} type={EMAIL_TYPES.SCHEDULED} />
        <h3 className="text-primary font-medium text-md pl-3">Sent Emails</h3>
        <Emails state={sentEmailsData} type={EMAIL_TYPES.SENT} />
        <h3 className="text-primary font-medium text-md pl-3">Incoming Emails</h3>
        <Emails state={incomingEmailsData} type={EMAIL_TYPES.INCOMING} />
      </div>
      <SendEmailModal
        visible={visible}
        setVisible={onClose}
        entityId={id}
        entityType={entityType}
        toMail={entityType === ENTITY_TYPES.CARE_FACILITY ? entity?.businessEmail : entity?.applicant?.email}
        showSchedule
        reload={reload}
      />
    </NestedPage>
  );
}
