import { useLocation } from 'react-router-dom';

export default function useFamilyDisabledTabs({ step, openSteps }) {
  let disabledTabs = {};
  const location = useLocation();
  if (location.pathname.startsWith('/family')) {
    const stepTabs = openSteps.steps[step].steps;
    disabledTabs = Object.keys(stepTabs).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: !stepTabs[curr]?.open || false,
      }),
      {},
    );
  }

  return disabledTabs;
}
