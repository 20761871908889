import { useEffect, useRef, useState, useMemo } from 'react';
import { message } from 'antd';
import { useAsyncFn } from 'react-use';
import groupBy from 'lodash/groupBy';

export default function useChecklist({ saveChecklist, id, data }) {
  const inProgressItems = useRef({});
  const [checklistData, setChecklistData] = useState([]);

  const [requestInProgressItems, setRequestInProgressItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [time, setTime] = useState(Date.now());

  const checklist = useMemo(() => (data ? groupBy(data, (item) => item?.item?.section?.title) : []), [data]);
  const isSaving = Object.getOwnPropertySymbols(inProgressItems.current).length > 0;
  useEffect(() => {
    setChecklistData(
      Object.keys(checklist).map((name) => ({
        name,
        items: [...(checklist[name]?.map((item) => ({ ...item })) || [])],
      })),
    );
  }, [checklist]);

  // eslint-disable-next-line no-unused-vars
  const [_, saveChecklistItems] = useAsyncFn(
    async (rootIndex, nestedIndex, checklistNestedItem, value, qcApproved) => {
      const key = Symbol();
      inProgressItems.current[key] = checklistNestedItem;
      setTime(Date.now());
      let checklistSections = [...checklistData];
      setRequestInProgressItems([...requestInProgressItems, checklistNestedItem]);
      const previousValue = checklistSections[rootIndex].items[nestedIndex]?.status;
      try {
        checklistSections[rootIndex].items[nestedIndex].status = value;
        checklistSections[rootIndex].items[nestedIndex].qcApproved = qcApproved;
        setChecklistData(checklistSections);
        await saveChecklist(id, checklistSections[rootIndex].items);
      } catch (error) {
        message.error('Unable to  update checklist.', 5);
        newrelic.noticeError(error);
        checklistSections = [...checklistData];
        checklistSections[rootIndex].items[nestedIndex].status = previousValue;
        setChecklistData(checklistSections);
      } finally {
        delete inProgressItems.current[key];
        setTime(Date.now());
      }
    },
    [id, checklistData],
  );

  return {
    saveChecklistItems,
    checklistData,
    isSaving,
  };
}
