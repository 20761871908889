import { useEffect } from 'react';
import { Button, message, Spin, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import Form from 'components/Form';
import SwitchField from 'components/Switch/SwitchField';
import familyService from 'services/family.service';
import { actions } from 'features/family';
import { formatDate } from 'utils';
import { DATE_FORMAT } from 'constants/index';
import AriaDatePicker from 'components/AriaDatePicker';

export default function EligibilityContent(props) {
  const { application, householdId, prev, next, loading } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const eligibleForProgram = Form.useWatch('eligibleForProgram', form);

  const { loading: savingApplicationEligibility, execute: saveApplicationEligibility } = useAsyncCallback(
    async (payload) => {
      try {
        payload = {
          ...payload,
          startDate: eligibleForProgram ? formatDate(payload.startDate, DATE_FORMAT[2]) : null,
          endDate: eligibleForProgram ? formatDate(payload.endDate, DATE_FORMAT[2]) : null,
        };
        const data = await familyService.saveApplicationDates(payload);
        dispatch(actions.setApplication(data));
        history.push(next);
      } catch (error) {
        newrelic.noticeError(error);
        message.error(error?.apierror?.message || 'Unable to update application.', 5);
      }
    },
    [],
  );

  useEffect(() => {
    form.setFieldsValue({
      id: application?.id,
      startDate: application?.startDate ? dayjs(application?.startDate) : application?.submittedDate,
      endDate: application?.endDate ? dayjs(application?.endDate) : dayjs(application?.submittedDate).add(364, 'd'),
      eligibleForProgram: application?.eligibleForProgram,
    });
  }, [form, application]);

  return (
    <Spin spinning={loading}>
      <Form
        className="p-6"
        layout="vertical"
        form={form}
        onFinish={saveApplicationEligibility}
        onValuesChange={({ startDate, eligibleForProgram }) => {
          if (startDate) {
            form.setFieldsValue({
              endDate: dayjs.isDayjs(startDate) ? startDate?.add(364, 'd') : dayjs(startDate).add(364, 'd'),
            });
          } else if (eligibleForProgram) {
            form.setFieldsValue({
              startDate: application?.startDate ? dayjs(application?.startDate) : application?.submittedDate,
              endDate: application?.endDate
                ? dayjs(application?.endDate)
                : dayjs(application?.submittedDate).add(364, 'd'),
            });
          }
        }}
      >
        <h3 className="text-primary font-bold text-base mb-6">Eligibility Review</h3>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center">
          <Form.Item name="eligibleForProgram" valuePropName="checked">
            <SwitchField>Eligible For Program</SwitchField>
          </Form.Item>
          <Form.Item name="id" hidden>
            <InputNumber />
          </Form.Item>
          {eligibleForProgram && (
            <>
              <Form.Item name="startDate" label="Start Date">
                <AriaDatePicker />
              </Form.Item>

              <Form.Item name="endDate" label="End Date">
                <AriaDatePicker disabled />
              </Form.Item>
            </>
          )}
        </div>
        <div className="p4-6 pt-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
          <Button
            onClick={() => history.push(`/families/${householdId}/applications/${application.id}`)}
            disabled={savingApplicationEligibility}
          >
            Cancel
          </Button>
          <Button onClick={() => history.push(prev)}>Back</Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={savingApplicationEligibility}
            loading={savingApplicationEligibility}
          >
            Next
          </Button>
        </div>
      </Form>
    </Spin>
  );
}
