import React from 'react';
import { Spin } from 'antd';

export default function LoadingPage() {
  return (
    <Spin size="large" spinning>
      <div style={{ height: 'calc(100vh - 80px)' }}></div>
    </Spin>
  );
}
