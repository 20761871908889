import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Button, message } from 'antd';
import { useAsync } from 'react-async-hook';
import Header from 'components/Header';
import commonService from 'services/common.service';
import PageMeta from 'components/PageMeta/PageMeta';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';

export default function BranchSelection() {
  const { showFamilySignupLinks } = useFlags();

  const { result: branches = [] } = useAsync(async () => {
    try {
      let data = await commonService.getSponsors();
      data = data.filter((branch) => branch.active);
      return data.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Programs not found.', 5);
      return [];
    }
  }, []);

  if (!showFamilySignupLinks) {
    return <Redirect to="/" />;
  }

  return (
    <div className="relative overflow-auto" id="branch-container">
      <PageMeta title="Family Signup" />

      <Header theme="primary" className="shadow-md bg-white" />

      <div className="flex justify-center flex-col items-center" role="main">
        <div className="mt-32">
          <Link to="/" className="home-link block" title="Home">
            <img src="/images/ccaoa-logo.png" alt="log" className="h-28" />
          </Link>

          <h1 className="text-center">Family Signup</h1>
        </div>

        <div className="py-5 my-24 mt-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {branches?.map?.((item) => (
              <BranchItem {...item} key={item.img} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const BranchItem = ({ branchName, iconUrl, alias, description, id: branchId }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const location = pathname.split('/family/')?.[1];
  return (
    <Button
      className="h-56 w-56 border justify-center items-center flex-col flex rounded-2xl ring-2 ring-gray-200 hover:ring-primary hover:ring-2"
      onClick={() => history.push(`/family/${location}/${branchId}`)}
      data-testid={`/family/${location}/${branchId}`}
    >
      <picture>
        <source srcSet={iconUrl} type="image/webp" />
        <img src={iconUrl} alt="Background" className="object-fill h-24 w-24" />
      </picture>

      <p className="leading-6 text-center mt-2 text-gray-700 text-[15px] text-ellipsis whitespace-normal line-clamp-2">
        {description}
      </p>
    </Button>
  );
};
