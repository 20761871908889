import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spin, Input } from 'antd';
import { Editor as TinyMceEditor } from '@tinymce/tinymce-react';

import config from 'config';
import styles from './Editor.module.less';
import useStateCallback from 'hooks/useStateCallback';

export default function Editor({
  id,
  idPrefix,
  className,
  value = '',
  onChange,
  height,
  compact,
  templates,
  templateData,
  visible,
  customConfig,
  ...rest
}) {
  const editor = useRef();
  const [key, setKey] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const [fullScreen, setFullScreen] = useStateCallback(false);
  let toolbar = 'undo redo fontsizeselect formatgroup paragraphgroup insertgroup expand';
  if (!compact) {
    toolbar =
      'undo redo | insert fontsizeselect | bold italic forecolor backcolor removeformat ' +
      `| listgroup | aligngroup | insertgroup | ${templates?.length > 0 ? 'template' : ''} expand help`;
  }
  if (fullScreen) {
    toolbar =
      'undo redo | formatselect fontsizeselect | outdent indent |' +
      'bold italic forecolor backcolor superscript subscript removeformat | alignleft aligncenter ' +
      `alignright alignjustify | bullist numlist hr | image media table ${
        templates?.length > 0 ? 'template' : ''
      } emoticons charmap hr | fullscreen help`;
  }
  const props = {};
  if (typeof onChange === 'function') {
    props.value = value;
    props.onEditorChange = onChange;
  }
  const editorConfig = {
    height: height,
    menubar: fullScreen,
    toolbar_groups: {
      formatgroup: {
        icon: 'format',
        tooltip: 'Formatting',
        items: 'bold italic underline strikethrough | forecolor backcolor | superscript subscript | removeformat',
      },
      aligngroup: {
        icon: 'indent',
        tooltip: 'Alignment',
        items: 'indent outdent alignleft aligncenter alignright',
      },
      listgroup: {
        icon: 'unordered-list',
        tooltip: 'List Types',
        items: 'bullist numlist',
      },
      insertgroup: {
        icon: 'plus',
        tooltip: 'Insert',
        items: `link image media table ${templates?.length > 0 ? 'template' : ''} emoticons charmap hr`,
      },
    },
    fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
    deprecation_warnings: false,
    skin_url: '/tinymce/ui',
    content_css: '/tinymce/content/content.min.css',
    content_style: `
    body { font-family:Rubik,sans-serif; font-size:14px; }
    `,
    template_mdate_format: '%m/%d/%Y : %H:%M',
    template_replace_values: templateData,
    template_preview_replace_values: templateData,
    templates,
    setup: function (editorInstance) {
      editor.current = editorInstance;
      editorInstance.on('FullscreenStateChanged', function (e) {
        if (e.state === false) {
          setFullScreen(false);
        }
      });
      editorInstance.ui.registry.addToggleButton('expand', {
        icon: 'fullscreen',
        tooltip: 'Fullscreen',
        onAction: function () {
          setFullScreen(true, () => {
            setTimeout(function () {
              /* global tinymce */
              tinymce.activeEditor.execCommand('mceFullScreen');
            }, 50);
          });
        },
      });
    },
  };

  useEffect(() => {
    if (visible) {
      setLoading(true);
      setKey(Date.now());
    }
  }, [visible, fullScreen]);

  if (window.Cypress) {
    return <Input.TextArea id={id} value={value} onChange={onChange} />;
  }
  return (
    <div className={classNames(styles.editor, className)}>
      <Spin spinning={loading}>
        {visible && (
          <TinyMceEditor
            key={`${key}-${JSON.stringify(templateData || {})}`}
            apiKey={config.REACT_APP_TINYMCE_KEY}
            init={{ ...editorConfig, ...customConfig }}
            onInit={() => {
              setLoading(false);
            }}
            toolbar={toolbar}
            plugins={[
              'advlist autolink lists link textcolor image charmap print preview anchor hr',
              'searchreplace visualblocks',
              'media table paste help template emoticons fullscreen',
            ]}
            {...rest}
            {...props}
          />
        )}
      </Spin>
    </div>
  );
}

Editor.propTypes = {
  className: PropTypes.any,
  compact: PropTypes.bool,
  height: PropTypes.number,
  id: PropTypes.any,
  idPrefix: PropTypes.any,
  onChange: PropTypes.any,
  templateData: PropTypes.any,
  templates: PropTypes.array,
  value: PropTypes.string,
  visible: PropTypes.bool,
  customConfig: PropTypes.object,
};

Editor.defaultProps = {
  templates: [],
  templateData: {},
  height: 150,
  compact: true,
  visible: true,
  className: '',
  customConfig: {},
};
