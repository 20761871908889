import React from 'react';
import { Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import Form from 'components/Form';
import Modal from 'components/Modal';
import useStartEndDates from 'hooks/useStartEndDates';
import providerService from 'services/providers.service';
import familyService from 'services/family.service';
import { actions as centerActions } from 'features/add-center';
import { actions as familyActions } from 'features/family';
import { formatDates } from 'utils';

const ApplicationDateRangeModal = ({
  visible,
  setVisible,
  entity,
  entityType = 'CareFacility',
  startDate: startDateInitialValue,
  endDate: endDateInitialValue,
  startDateKey,
  endDateKey,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [startDate, endDate] = useStartEndDates({
    form,
    startDateFieldProps: {
      name: startDateKey,
    },
    endDateFieldProps: {
      name: endDateKey,
    },
    hideChangeRequestHighlights: true,
  });
  const [{ loading }, updateDate] = useAsyncFn(
    async (values) => {
      values = formatDates(values, [startDateKey, endDateKey]);
      try {
        if (entityType === 'CareFacility') {
          const data = await providerService.updateCenter(values, entity.id);
          dispatch(centerActions.setCenterInfo(data));
        } else {
          const data = await familyService.saveApplication({ ...values, id: entity.id });
          dispatch(familyActions.setApplication(data));
        }
        setVisible(false);
      } catch (e) {
        message.error({
          content: 'Unable to update application.',
          duration: 5,
        });
      }
    },
    [entity.id],
  );

  return (
    <Modal width={600} visible={visible} setVisible={setVisible}>
      <Form
        layout="vertical"
        onFinish={updateDate}
        initialValues={{ [startDateKey]: startDateInitialValue, [endDateKey]: endDateInitialValue }}
        form={form}
      >
        <div className="section">
          <h3 className="section-title">Select Application Dates</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 py-4">
          <div>{startDate}</div>
          <div>{endDate}</div>
        </div>
        <div className="actions flex">
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button data-testid="submit-btn" loading={loading} disabled={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ApplicationDateRangeModal;
