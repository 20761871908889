import { useMemo } from 'react';
import { Button, Steps } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { selectors } from 'features/family';
import { ENTITY_TYPES } from 'constants/index';
import useScrollLock from 'hooks/useScrollLock';
import DocumentsReview from '../DocumentsReview';

import ContentLayout from 'components/ContentLayout';
import ApplicationNavBar from 'components/ApplicationNavBar';
import CaseCoordinatorActivity from '../CaseCoordinatorActivity';
import FamilyChecklist from 'components/Checklist/FamilyChecklist';
import { selectors as documentSelectors } from 'features/documents';
import useBreakpoints from 'hooks/useBreakpoints';
import { getFamilyApplicationReviewNavItems } from 'utils';

export default function FamilyReviewContainer({
  stepItems,
  singleStep,
  current,
  onChange,
  rootRef,
  routePrefix,
  pathPrefix,
  stepsClassName,
  headerClassName,
}) {
  const { id, householdId, parentId, childId } = useParams();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const application = useSelector(selectors.selectApplication);
  const isFormSubmitting = useSelector(selectors.selectIsFormSubmitting);
  const documentTypes = useSelector(documentSelectors.selectDocumentTypes);

  const { contentRef, sidebarRef, layoutClassName, contentClassName, sidebarClassName } = useScrollLock({
    topLimit: 90,
    rootRef,
  });
  const step = singleStep || stepItems[current];

  const selectedParent = useMemo(() => {
    if (parentId) {
      return (application.additionalParents || []).find((parent) => parent.id === Number(parentId));
    }
    return application.additionalParents?.[0];
  }, [application.additionalParents, parentId]);

  const selectedChild = useMemo(() => {
    if (childId) {
      return application?.children?.find?.((child) => child.id === Number(childId));
    }
    return application.children?.[0];
  }, [application.children, childId]);

  const navItems = useMemo(
    () =>
      getFamilyApplicationReviewNavItems({
        id,
        householdId,
        application,
        selectedParent,
        selectedChild,
      }),
    [application, householdId, id, selectedChild, selectedParent],
  );

  return (
    <div>
      <ApplicationNavBar
        breakpoints={breakpoints}
        items={navItems}
        entity={application}
        entityType={ENTITY_TYPES.HOUSEHOLD}
        toMail={''}
        className="bg-light-bg"
        hideEmail
      />
      <div className="bg-white pt-6 shadow-inner -mt-0.5">
        <Steps
          current={current}
          onChange={onChange}
          labelPlacement="vertical"
          className={classNames(
            'pl-6 pb-3 md:pl-0 [&_.ant-steps-item-wait_.ant-steps-item-title]:!text-black [&_.ant-steps-item_.ant-steps-item-content]:!mt-0',
            stepsClassName,
          )}
          items={stepItems}
        />
      </div>
      <ContentLayout
        layoutClassName={classNames(layoutClassName, 'mt-0')}
        contentClassName={contentClassName}
        sidebarClassName={sidebarClassName}
        sidebarContent={
          <div className="shadow-md h-full w-full bg-white">
            <div ref={sidebarRef}></div>
            <FamilyChecklist application={application} id={id} className="py-4" isFormSubmitting={isFormSubmitting} />
          </div>
        }
      >
        <div ref={contentRef}>
          {/* This div must be inside this main div inn order to scroll to top on each submit */}
          <div id="content-area-locator"></div>
        </div>
        {/* {headerExtraElement} */}
        <div className={classNames('bg-white', headerClassName)}>{step.header}</div>
        <div className="bg-white">
          <div className="p-6 border-b-2">
            <p>
              In order to verify the application's information, open any relevant document below (to preview, click on
              the Document Name or select the 'Expand' button under Actions column and the document will open in a new
              window) to compare for accuracy and determine results.
            </p>
          </div>
          {step.content}

          <DocumentsReview
            entityType={ENTITY_TYPES.FAMILY}
            entityId={id}
            routePrefix={routePrefix}
            pathPrefix={pathPrefix}
            documentTypes={documentTypes.filter((docType) => docType.domain === 'Family')}
            className="border-t-2"
          />
          {step.next && step.prev && (
            <div className="p-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
              <CaseCoordinatorActivity
                entity={application}
                entityType={ENTITY_TYPES.FAMILY}
                className="mt-5 mb-5"
                collapseHeaderClassName="py-4"
              />
              <Button
                onClick={() => history.push(`/families/${householdId}/applications/${application.id}`)}
                disabled={isFormSubmitting}
              >
                Cancel
              </Button>
              <Button onClick={() => history.push(step.prev)} disabled={isFormSubmitting}>
                Back
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => history.push(step.next)}
                disabled={isFormSubmitting}
                loading={isFormSubmitting}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </ContentLayout>
    </div>
  );
}
