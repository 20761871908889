import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Divider, Input, Button, message, Spin } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useAsyncFn } from 'react-use';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { IoArrowBack } from 'react-icons/io5';

import Form from 'components/Form';
import Editor from 'components/Editor';
import Modal from 'components/Modal';
import ScheduleEmailModal from '../ScheduleEmailModal';
import DragDropUpload from 'components/DragDropUpload';
import communicationService from 'services/communications.service';
import styles from './ChecklistModal.module.less';
import { CHECKLIST_TYPES } from 'constants/index';

const subjects = [
  '',
  '15 Day Checklist Reminder',
  '30 Day Checklist Reminder',
  '60 Day Checklist Reminder',
  '90 Day Your checklist time has expired',
];

const GenerateChecklist = ({ setVisible, visible, title, entity, type = CHECKLIST_TYPES.PROVIDER }) => {
  const timeNow = Date.now();
  const format = 'YYYY-MM-DD';
  const scheduleDates = [
    dayjs(timeNow).format(format),
    dayjs(timeNow).add(15, 'day').format(format),
    dayjs(timeNow).add(30, 'day').format(format),
    dayjs(timeNow).add(60, 'day').format(format),
    dayjs(timeNow).add(90, 'day').format(format),
  ];
  const [templatesData, setTemplatesData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [checklistForm] = Form.useForm();
  const preview = useMemo(() => {
    return (
      <div className="flex flex-col" data-testid="preview-container">
        <Button
          type="primary"
          className="w-40 !flex items-center justify-center text-center relative mb-4"
          icon={
            <div className="h-5 w-5 absolute left-3 top-2">
              <IoArrowBack className="h-5 w-5" />
            </div>
          }
          onClick={() => setShowPreview(false)}
          id="back-btn"
        >
          Back
        </Button>

        <Editor
          id="email-preview"
          value={templatesData?.body}
          onChange={() => {}}
          customConfig={{
            menubar: false,
            toolbar: false,
            statusbar: false,
            readonly: true,
            setup: function (ed) {
              ed.settings.readonly = true;
            },
          }}
          height={900}
        />
      </div>
    );
  }, [templatesData]);

  const getEmailTemplates = useCallback(async () => {
    try {
      let data;
      let toMail = [{ email: entity?.businessEmail }];
      if (type === CHECKLIST_TYPES.PROVIDER) {
        data = await communicationService.getProviderChecklistTemplate(entity.id);
      } else if (type === CHECKLIST_TYPES.FAMILY) {
        data = await communicationService.getFamilyChecklistTemplate(entity?.householdId, entity?.id);
        toMail = [{ email: entity.applicant?.email }];
      } else {
        data = await communicationService.getBgcChecklistTemplate(entity?.id);
      }

      setTemplatesData(data);
      checklistForm.setFieldsValue({
        ...data,
        toMail,
        subjects: ['Checklist Notice'],
        customMessage: '',
      });
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to load check list template.');
    }
  }, [entity, type, checklistForm]);

  useEffect(() => {
    if (visible) {
      getEmailTemplates();
    }
  }, [getEmailTemplates, visible]);
  useEffect(() => {
    if (!visible) {
      setShowPreview(false);
    }
  }, [visible]);

  const [{ loading: sendingEmail }, sendEmail] = useAsyncFn(async () => {
    let { attachments, ...values } = await checklistForm.getFieldsValue(true);
    attachments = Array.from(attachments || []).map((doc) => ({ ...doc, entityType: 'Attachment' }));
    try {
      const data = {
        ...templatesData,
        ...values,
        body: values.customMessage,
        subjects: values.subjects,
        toMail: values.toMail.map((i) => i.email).join(','),
        attachments,
      };
      if (type === CHECKLIST_TYPES.PROVIDER) {
        await communicationService.sendProviderChecklistEmail(entity.id, data);
      } else if (type === CHECKLIST_TYPES.FAMILY) {
        await communicationService.sendFamilyChecklistEmail(entity.householdId, entity.id, data);
      } else {
        await communicationService.sendBgcChecklistEmail(entity.id, data);
      }
      message.success('Checklist email will be sent in few seconds.');
      setVisible(false);
      // checklistForm.setFieldsValue(templatesData);
      checklistForm.resetFields();
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to send email.', 5);
    }
  }, [entity, templatesData, checklistForm]);

  const [{ loading: schedulingEmail }, onScheduleEmail] = useAsyncFn(async () => {
    let { attachments, ...values } = await checklistForm.getFieldsValue(true);
    attachments = Array.from(attachments || []).map((doc) => ({ ...doc, entityType: 'Attachment' }));
    const injectSubject = subjects.map((reminder, index) =>
      index === 0 ? values.subjects?.[0] : `${values.subjects?.[0]} - ${reminder}`,
    );
    const data = {
      ...templatesData,
      ...values,
      body: values.customMessage,
      attachments,
      subjects: injectSubject,
      scheduleDates,
      toMail: values.toMail.map((i) => i.email).join(','),
    };
    try {
      if (type === CHECKLIST_TYPES.PROVIDER) {
        await communicationService.scheduleProviderChecklistEmail(entity.id, data);
      } else if (type === CHECKLIST_TYPES.FAMILY) {
        await communicationService.scheduleFamilyChecklistEmail(entity.householdId, entity.id, data);
      } else {
        await communicationService.scheduleBgcChecklistEmail(entity.id, data);
      }
      message.success('Checklist email reminders have been scheduled.');
      setScheduleVisible(false);
      setVisible(false);
      checklistForm.resetFields();
      // checklistForm.setFieldsValue(templatesData);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to schedule email.', 5);
    }
  }, [entity, templatesData, checklistForm]);

  const submitForm = async (submitType) => {
    try {
      await checklistForm.validateFields();
      if (submitType === 'sendEmail') {
        sendEmail();
      } else {
        setScheduleVisible(true);
      }
    } catch (errors) {}
  };

  return (
    <>
      <Modal
        visible={visible}
        setVisible={() => {
          checklistForm.resetFields();
          setVisible(false);
        }}
        width={900}
      >
        <>
          <div
            tabIndex="0"
            role="button"
            onClick={() => setVisible(false)}
            className="flex-end absolute right-5 z-[100] top-5 bg-[#ac2e2e33] rounded-full text-center h-8 w-8 justify-center items-center flex "
          >
            <MdClose size={20} className="text-red-700" />
          </div>

          <Spin spinning={sendingEmail}>
            {showPreview ? (
              preview
            ) : (
              <Form form={checklistForm} layout="vertical" initialValues={templatesData} data-testid="checklist-form">
                <div className={styles.header}>
                  <div className="section-title uppercase">{title}</div>
                </div>
                <Divider className="bg-black" />
                <div className="flex flex-col sm:flex-row md:items-baseline width-full md:w-3/4 xl:1/2">
                  <div className="label mr-3 mb-3 md:mb-0">
                    <span className="text-md font-semibold uppercase">Send To:</span>
                  </div>
                  <div className="emails flex-1" data-testid="recipients-container">
                    <Form.List name="toMail">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                            <div key={key} className="flex items-start">
                              <Form.Item
                                {...restField}
                                name={[name, 'email']}
                                fieldKey={[fieldKey, 'email']}
                                rules={[
                                  { required: true, message: 'Email is required.' },
                                  { type: 'email', message: 'Email is invalid' },
                                ]}
                                className={classNames(restField.className, 'flex-1 md:w-3/4')}
                              >
                                <Input data-testid={`subjects_${fieldKey}`} placeholder="Email" />
                              </Form.Item>

                              {index > 0 && (
                                <Button
                                  className="!w-9 !h-9 ml-2 rounded-full border-none"
                                  onClick={() => remove(name)}
                                  icon={<FaMinus />}
                                  data-testid="remove-recipient"
                                />
                              )}
                              {index === 0 && (
                                <Button
                                  className="!w-9 !h-9 ml-2 rounded-full border-none"
                                  onClick={() => add()}
                                  icon={<FaPlus />}
                                  data-testid="add-recipient"
                                />
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </div>
                </div>

                <Divider className="bg-black !mt-1" />
                <Form.Item
                  name={['subjects', 0]}
                  data-testid="subject"
                  label="Subject"
                  rules={[{ required: true, message: 'Subject is required.' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  id="message"
                  name="customMessage"
                  data-testid="checklist-message"
                  rules={[{ required: true, message: 'Message is required' }]}
                  label="Custom Message"
                >
                  <Editor />
                </Form.Item>
                <Form.Item
                  data-testid="checklist-attachments"
                  label="Attach any supporting documents"
                  name={'attachments'}
                  className="w-full"
                >
                  <DragDropUpload
                    name="attachments"
                    entityType="Attachment"
                    abbr="OTHER"
                    keyPrefix="forms/center-details/accreditation"
                  />
                </Form.Item>

                <span className="text-primary font-bold text-sm tracking-widest capitalize">SCHEDULE EMAIL</span>
                <div className="grid grid-rows-1 gap-2 xl:gap-0 xl:grid-cols-2 mt-2">
                  <div className="xl:col-span-1">
                    <Button
                      type="primary"
                      data-testid="schedule-email-button"
                      disabled={sendingEmail}
                      onClick={() => submitForm('schedule')}
                      className="w-full xl:w-fit"
                    >
                      Schedule Checklist emails
                    </Button>
                  </div>
                  <div className="xl:col-span-1">
                    <div className="grid grid-cols-1 gap-2 xl:grid-cols-3 xl:flex">
                      <Button
                        data-testid="cancel-button"
                        onClick={() => setVisible(false)}
                        className="xl:w-28"
                        disabled={sendingEmail}
                      >
                        Cancel
                      </Button>
                      <Button
                        data-testid="preview-button"
                        className="xl:w-28"
                        disabled={sendingEmail}
                        onClick={() => setShowPreview((v) => !v)}
                      >
                        Preview
                      </Button>
                      <Button
                        data-testid="send-email-button"
                        onClick={() => submitForm('sendEmail')}
                        className="xl:w-44"
                        loading={sendingEmail}
                        disabled={sendingEmail}
                      >
                        Send this Email
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Spin>

          <ScheduleEmailModal
            visible={scheduleVisible}
            setVisible={setScheduleVisible}
            schedulingEmail={schedulingEmail}
            form={checklistForm}
            onScheduleEmail={onScheduleEmail}
          />
        </>
      </Modal>
    </>
  );
};

export default GenerateChecklist;
