import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Tooltip, message } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { FaPlus } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';
import { AiOutlineEdit } from 'react-icons/ai';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import Table from 'components/Table';
import ProvidersTable from './ProviderTable';
import SelectProvidersModal from './SelectProvider';
import AddScheduleModal from 'components/Modals/AddScheduleModal';
import familyService from 'services/family.service';
import { formatDate } from 'utils';

export default function ScheduleTable({
  applicationId,
  childId,
  applicationValues,
  index,
  parentForm,
  showAddButton = true,
  childSchedules,
  readOnly = false,
}) {
  const [showEditSchedule, setShowEditSchedule] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState({});
  const [showProviderOptionsModal, setShowProviderOptionsModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [key, setKey] = useState(Date.now());
  const {
    loading,
    result,
    execute: fetchSchedules,
  } = useAsync(
    async (appId, childId) => {
      if (appId && childId && !childSchedules) {
        return await familyService.getChildSchedules(appId, childId);
      }
    },
    [applicationId, childId, childSchedules],
  );
  const schedules = useMemo(() => (childSchedules ? childSchedules : result), [childSchedules, result]);

  useEffect(() => {
    setExpandedRows(schedules?.map?.((item) => item.id) || []);
  }, [schedules]);

  const onAddSchedule = useCallback(() => {
    setSelectedSchedule({});
    setKey(Date.now());
    setShowEditSchedule(true);
  }, []);

  const reload = useCallback(
    async (isAddingMore, schedule) => {
      await fetchSchedules(applicationId, childId);
      if (isAddingMore) {
        onAddSchedule();
      } else if (schedule) {
        setSelectedSchedule(schedule);
      }
    },
    [applicationId, childId, fetchSchedules, onAddSchedule],
  );

  const { loading: deletingSchedule, execute: deleteSchedule } = useAsyncCallback(
    async (scheduleId) => {
      try {
        await familyService.deleteSchedule(applicationId, childId, scheduleId);
        message.success('Schedule removed successfully.', 5);
        await reload();
      } catch (error) {
        newrelic.noticeError(error);

        if (error?.apierror?.status === 'CONFLICT') {
          message.error('Schedule cannot be deleted, because it is associated with a certificate.', 5);
        } else {
          message.error('Unable to delete schedule.', 5);
        }
      }
    },
    [applicationId, childId, reload],
  );

  const allColumns = useMemo(() => {
    let cols = [
      {
        title: <span className="action-header">Type of Child care</span>,
        headerText: 'Type of Child care',
        dataIndex: ['careDurationType', 'title'],
        key: 'type',
        ellipsis: true,
        width: 120,
        className: 'truncate max-w-xs',
        render: (type) => <span>{type || 'N/A'}</span>,
        fixed: 'left',
      },
      {
        title: <span className="action-header">Care Start Date</span>,
        headerText: 'Care Start Date',
        dataIndex: ['startDate'],
        key: 'careStartDate',
        ellipsis: true,
        width: 120,
        className: 'truncate max-w-xs',
        render: (date) => formatDate(date),
      },
      {
        title: <span className="action-header">Care End Date</span>,
        headerText: 'Care End Date',
        key: 'careEndDate',
        dataIndex: ['endDate'],
        align: 'left',
        width: 120,
        className: 'truncate max-w-xs',
        render: (date) => formatDate(date),
      },
      {
        title: <span className="action-header">Provider Name</span>,
        headerText: 'Provider Name',
        key: 'providerName',
        dataIndex: ['careFacility', 'businessLegalName'],
        align: 'left',
        width: 120,
        className: 'truncate max-w-xs',
        render: (businessLegalName) => <span>{businessLegalName || 'N/A'}</span>,
      },
      {
        title: <span className="action-header">Provider ID</span>,
        headerText: 'Provider ID',
        key: 'providerId',
        // dataIndex: ['careFacility', 'id'],
        align: 'left',
        width: 100,
        className: 'truncate max-w-xs',
        render: (schedule) => (
          <span>
            {schedule?.careFacility ? schedule?.careFacility?.id : schedule?.request?.careFacilityRequestId || 'N/A'}
          </span>
        ),
      },
      {
        title: <span className="action-header">Provider Status</span>,
        headerText: 'Provider Status',
        key: 'providerStatus',
        // dataIndex: ['careFacility', 'application', 'status', 'status', 'publicStatus', 'description'],
        align: 'left',
        width: 130,
        className: 'truncate max-w-xs',
        render: (schedule) => (
          <span>
            {schedule?.careFacility
              ? schedule?.careFacility?.application?.status?.status?.publicStatus?.description
              : schedule?.request?.status?.description || 'N/A'}
          </span>
        ),
      },
    ];

    if (!readOnly) {
      cols.push({
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        align: 'center',
        width: 70,
        fixed: 'right',
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          const isExpanded = expandedRows.indexOf(record.id) > -1;
          return (
            <div className="actions-cell justify-center">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    setSelectedSchedule({ ...record });
                    setShowEditSchedule(true);
                  }}
                  style={{ width: 'auto' }}
                  icon={<AiOutlineEdit />}
                  aria-label="Edit"
                />
              </Tooltip>

              {record.attachedToCert !== true && (
                <Tooltip placement="top" title="Remove">
                  <Button
                    className="icon-btn alert remove-button"
                    icon={<BiTrash />}
                    aria-label="Remove"
                    onClick={() => deleteSchedule(record.id)}
                  />
                </Tooltip>
              )}

              <Tooltip placement="top" title={isExpanded ? 'Close' : 'Open'}>
                <Button
                  onClick={() => {
                    const isExpanded = expandedRows.some((id) => id === record.id);
                    if (isExpanded) {
                      setExpandedRows(expandedRows.filter((id) => id !== record.id));
                    } else {
                      setExpandedRows([...expandedRows, record.id]);
                    }
                  }}
                  icon={isExpanded ? <AiFillCaretUp /> : <AiFillCaretDown />}
                  className="icon-btn expand-btn"
                  aria-label={isExpanded ? 'Close' : 'Open'}
                  data-testid={`collapse-button-${record.name}`}
                  title={isExpanded ? 'Close' : 'Open'}
                />
              </Tooltip>
            </div>
          );
        },
      });
    }
    return cols;
  }, [deleteSchedule, expandedRows, readOnly]);

  return (
    <>
      {showAddButton && !readOnly && (
        <Button
          className={'flex items-center border-dashed mb-8'}
          icon={<FaPlus className="mr-2" />}
          onClick={onAddSchedule}
        >
          Add Schedule of care
        </Button>
      )}

      <Table
        allColumns={allColumns}
        data={schedules}
        loading={loading || deletingSchedule}
        showColSeparator={false}
        rowKey={(record) => record.id}
        pagination={false}
        scroll={{ x: 1000 }}
        expandable={{
          expandIcon: () => null,
          rowExpandable: () => true,
          columnWidth: 0,
          expandedRowKeys: expandedRows,
          expandedRowRender: (record) => {
            return (
              <div className="[&_.ant-table]:!min-h-[100px] pt-4 pb-8">
                <h4 className="text-primary text-md font-semibold mb-4 mt-2">Days and Hours of Care</h4>
                <ProvidersTable providersData={[{ ...record }] || []} />
                {!readOnly && (
                  <div className="flex items-center gap-4">
                    <Button
                      onClick={() => {
                        setShowProviderOptionsModal(true);
                      }}
                    >
                      Select provider
                    </Button>

                    <span className="text-sm mt-2">
                      <strong>NOTE:</strong> Only one provider can be associated with one schedule of care.
                    </span>
                  </div>
                )}
              </div>
            );
          },
        }}
      />

      <AddScheduleModal
        visible={showEditSchedule}
        setVisible={() => {
          setShowEditSchedule(false);
          setSelectedSchedule({});
        }}
        applicationId={applicationId}
        childId={childId}
        schedule={selectedSchedule}
        reload={reload}
        key={key}
        setVisibleProviderSelectionModal={setShowProviderOptionsModal}
        programId={applicationValues?.program?.id}
      />

      <SelectProvidersModal
        parentForm={parentForm}
        index={index}
        visible={showProviderOptionsModal}
        setVisible={setShowProviderOptionsModal}
        applicationId={applicationId}
        childId={childId}
        schedule={selectedSchedule}
        applicationValues={applicationValues}
        reload={reload}
      />
    </>
  );
}
