import React, { useEffect, useRef, useMemo } from 'react';
import produce from 'immer';
import { Button, Col, Input, message, Radio, Row, Space, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  sleep,
  getStringValidator,
  getNumericValidator,
  getPhoneNumberValidator,
  getPhoneEXTValidator,
  setDefaultCountry,
} from 'utils';
import { selectUser } from 'features/auth';
import { BUSINESS_NAME_REGEX, FACILITY_DETAILS_TABS, ROLES, ADD_FACILITY_STEPS } from 'constants/index';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import { AsyncSelect } from 'components/Select';
import PhoneNumber from 'components/PhoneNumber';
import SecureInput from 'components/SecureInput';
import AddressFields from 'components/AddressFields';
import SwitchField from 'components/Switch/SwitchField';
import providerService from 'services/providers.service';
import CheckPermissions from 'components/CheckPermissions';
import useProviderEditDisabled from 'hooks/useProviderEditDisabled';
import PhoneNumberContainer from 'components/PhoneNumberContainer';
import Card from 'components/Card/Card';
import PointsOfContact from './BusinessInfo/PointsOfContact';

export default function BusinessInfo({ setTab, setOpenTabs, center, actions, isFormSubmitting, loading, setStep }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const mailingAddressRef = useRef();
  const billingAddressRef = useRef();
  const mailingAddressKey = 'address';
  const billingAddressKey = 'billingAddress';
  const isSubmitDisabled = useProviderEditDisabled();
  const isInHomeProvider = useMemo(() => center?.type?.title === 'In-Home Provider', [center?.type?.title]);

  useEffect(() => {
    form.setFieldsValue(
      produce(center, (draft) => {
        delete draft.application;
        draft.paymentViaPaperCheck = !center.paymentViaDirectDeposit;
        draft.application = {
          billingSameAsPhysical: center?.application?.billingSameAsPhysical,
        };
      }),
    );
  }, [center, form]);

  // Keeps track of whether `next` is pressed or `save`
  const shouldGoToNextStep = useRef(false);

  const onFinish = async (values) => {
    if (values.banking?.accountHolder) {
      values.banking.accountHolder.personType = 'Account Holder';
      values.banking.accountHolder.id = center.banking?.accountHolder?.id;
    }
    values[mailingAddressKey].id = center[mailingAddressKey]?.id;
    if (values.application.billingSameAsPhysical) {
      values[billingAddressKey] = { ...values[mailingAddressKey] };
    }
    values[billingAddressKey].id = center[billingAddressKey]?.id;

    // This is to replace already saved values to US
    values = setDefaultCountry(values, mailingAddressKey);
    values = setDefaultCountry(values, billingAddressKey);
    values = { ...values };

    try {
      dispatch(actions.setIsFormSubmitting(true));

      const data = await providerService.updateCenter(values, center.id);

      dispatch(actions.setCenterInfo(data));
      setOpenTabs(FACILITY_DETAILS_TABS.CENTER_DETAILS);

      if (shouldGoToNextStep.current) {
        await sleep(100);
        setTab(FACILITY_DETAILS_TABS.CENTER_DETAILS);
      }

      message.success('Saved');
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to save business information.');
    } finally {
      dispatch(actions.setIsFormSubmitting(false));
    }
  };

  return (
    <div id="business-info-tab" data-testid="businessInfoTab">
      <Form
        layout="vertical"
        initialValues={{ employerIdNumber: '' }}
        onKeyDown={(e) => (e.name === 'Enter' ? e.preventDefault() : '')}
        form={form}
        onValuesChange={(values) => {
          if ('paymentViaDirectDeposit' in values) {
            form.setFieldsValue({
              paymentViaPaperCheck: !values['paymentViaDirectDeposit'],
            });
          } else if ('paymentViaPaperCheck' in values) {
            form.setFieldsValue({
              paymentViaDirectDeposit: !values['paymentViaPaperCheck'],
            });
          }
          if ('taxExempt' in values) {
            if (!values.taxExempt) {
              form.setFieldsValue({
                taxExemptCode: null,
              });
            }
          }
        }}
        onFinish={onFinish}
        scrollToFirstError
      >
        {(values) => {
          return (
            <Spin spinning={isFormSubmitting || loading}>
              <Card collapsible title="Facility Info" className="mb-4">
                <p className="instructions mb-5">
                  Please enter your details to register. The information provided must match was is on file with the
                  IRS. All fields marked with * are mandatory.
                </p>

                <FormItem
                  name="businessLegalName"
                  label="Business Legal Name"
                  rules={[
                    { required: true, message: 'Business legal name is required' },
                    { type: 'string', message: 'Invalid business legal name' },
                    { max: 256, message: 'Business legal name is too long' },
                    { min: 3, message: 'Business legal name is too short' },
                    {
                      pattern: BUSINESS_NAME_REGEX,
                      message: 'Business legal name is invalid',
                    },
                    {
                      validator: getStringValidator('Business Legal Name is invalid.'),
                    },
                  ]}
                >
                  <Input placeholder="Business Legal Name" data-testid="businessLegalName" />
                </FormItem>
                <Row gutter={[20, 0]} className="form-row">
                  <Col xs={24} sm={12} md={8}>
                    <FormItem
                      name="taxNumberType"
                      label="Tax ID Type"
                      isSame={(prev, curr) => prev?.id === curr?.id}
                      rules={[{ required: true, message: 'Tax ID Type is required.' }]}
                    >
                      <AsyncSelect
                        name="taxNumberType"
                        optionsApiUrl={`/tax-number-type?typeOfCare=${center?.type?.id}`}
                        placeholder="Tax ID Type"
                        ariaLabel="Tax ID Type"
                        autoComplete="off"
                        isSearchable={false}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <FormItem
                      name="taxIdNumber"
                      label="Tax number"
                      rules={[
                        { required: true, message: 'Tax number is required.' },
                        { pattern: /^\d{9}$/g, message: 'Tax number must have 9 digits.' },
                      ]}
                    >
                      <SecureInput size="middle" />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <FormItem
                      name="taxClassification"
                      label="TAX Classification"
                      rules={[{ required: true, message: 'This is required.' }]}
                      isSame={(prev, curr) => prev?.id === curr?.id}
                    >
                      <AsyncSelect
                        name="taxClassification"
                        optionsApiUrl="/options/21"
                        placeholder="TAX Classification"
                        ariaLabel="TAX Classification"
                      />
                    </FormItem>
                  </Col>

                  <Col xs={24}>
                    <FormItem data-testid="has-exempt-code" name="taxExempt" valuePropName="checked" className="mb-2">
                      <SwitchField>Exempt form backup withholding</SwitchField>
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                    <FormItem
                      name="taxExemptCode"
                      label="Exempt Code"
                      rules={[{ required: values.taxExempt, message: 'Exempt Code is required.' }]}
                    >
                      <AsyncSelect
                        name="tax_exempt_code"
                        optionsApiUrl="/options/31"
                        placeholder="Exempt Code"
                        ariaLabel="Exempt Code"
                        autoComplete="off"
                        isSearchable={false}
                        disabled={!values.taxExempt}
                        // onChangeFormatter={(option) => option.title}
                        // isOptionSelected={(option, [value]) => option?.title === value}
                        getOptionLabel={(option) => option?.description}
                        // getOptionValue={(option) => option?.title}
                      />
                    </FormItem>
                  </Col>
                  <CheckPermissions requiredRoles={ROLES.PROVIDER_COORDINATOR}>
                    <Col xs={24} sm={12} md={8}>
                      <FormItem name="irsVerificationNum" label="IRS Verification Number">
                        <Input className="ant-input" placeholder="Number" />
                      </FormItem>
                    </Col>
                  </CheckPermissions>
                </Row>
              </Card>

              <PointsOfContact center={center} />

              <Card
                collapsible
                title={isInHomeProvider ? 'Mailing Address for In-Home Providers' : 'Facility Address'}
                className="mb-4"
              >
                <AddressFields
                  id="physical-address"
                  form={form}
                  searchRef={mailingAddressRef}
                  addressKey={mailingAddressKey}
                  disableFields={userInfo.sameCareFacilityAddress}
                />

                <Row gutter={[20, 0]} className="form-row">
                  <Col xs={24} sm={12} md={8}>
                    <PhoneNumberContainer
                      phoneNumber={
                        <FormItem
                          name={['businessPhone']}
                          label="Business Phone Number"
                          rules={[
                            { required: true, message: 'Business Phone Number is required.' },
                            getPhoneNumberValidator('Invalid Phone Number!'),
                          ]}
                        >
                          <PhoneNumber />
                        </FormItem>
                      }
                      extension={
                        <FormItem
                          name={['businessPhoneExt']}
                          label="Extension"
                          rules={[getPhoneEXTValidator('Invalid Extension!')]}
                        >
                          <Input placeholder="Extension" />
                        </FormItem>
                      }
                    />
                  </Col>
                </Row>
              </Card>

              <Card collapsible title="Billing Address" className="mb-4">
                <FormItem name={['application', 'billingSameAsPhysical']} valuePropName="checked" className="mb-0">
                  <SwitchField>
                    My billing address is the same as my{' '}
                    {isInHomeProvider ? 'mailing address for In-Home Providers' : 'physical address'}.
                  </SwitchField>
                </FormItem>

                {!values.application?.billingSameAsPhysical && (
                  <AddressFields
                    formValues={values}
                    id="billing-address"
                    form={form}
                    searchRef={billingAddressRef}
                    addressKey={billingAddressKey}
                  />
                )}
              </Card>

              <Card collapsible title="Payment" className="mb-4">
                <p className="instructions mb-6">
                  Please note your first payment will always be made via paper check. To ensure timely receipt of
                  reimbursement, we highly recommend choosing direct deposit.
                </p>

                <Row gutter={[26, 0]} className="flex-wrap">
                  <Col xs={12}>
                    <FormItem
                      name={['banking', 'paymentName']}
                      rules={[
                        {
                          required: true,
                          message: 'Cheque payable to is required',
                        },
                      ]}
                      label="Make Check Payable To"
                    >
                      <Input />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={[26, 0]} className="flex-wrap">
                  <Col xs={12}>
                    <PhoneNumberContainer
                      phoneNumber={
                        <FormItem
                          name={['banking', 'paymentPhone']}
                          label="Payment Phone (If Different from business)"
                          rules={[getPhoneNumberValidator('Invalid Phone Number!')]}
                        >
                          <PhoneNumber />
                        </FormItem>
                      }
                      extension={
                        <FormItem
                          name={['banking', 'paymentPhoneExt']}
                          label="Extension"
                          rules={[getPhoneEXTValidator('Invalid Extension!')]}
                        >
                          <Input />
                        </FormItem>
                      }
                    />
                  </Col>
                </Row>

                <FormItem label="Payment method">
                  <Radio.Group
                    onChange={(e) => {
                      form.setFieldsValue({
                        paymentViaPaperCheck: e.target.value === 'paper_checks',
                        paymentViaDirectDeposit: e.target.value === 'direct_deposit',
                      });
                    }}
                    value={
                      values.paymentViaPaperCheck
                        ? 'paper_checks'
                        : values.paymentViaDirectDeposit
                        ? 'direct_deposit'
                        : 'paper_checks'
                    }
                  >
                    <Space direction="vertical">
                      <Radio value="paper_checks">Paper checks</Radio>
                      <Radio value="direct_deposit">Direct Deposit to bank account</Radio>
                    </Space>
                  </Radio.Group>
                </FormItem>

                {values?.paymentViaDirectDeposit && (
                  <>
                    <ul className="list-disc ml-4">
                      <li className="text-15">
                        Please upload a voided check from your indicated bank with your business/legal name matching
                        your application.
                      </li>

                      <li className="text-15">
                        Please do not submit bank information for any attached families. Payments must be made directly
                        to your provider account.
                      </li>
                    </ul>

                    <Row gutter={[26, 0]} className="flex-wrap mt-4">
                      <Col xs={24} sm={12} md={8}>
                        <FormItem
                          name={['banking', 'accountType']}
                          label="Account Type"
                          rules={[{ required: true, message: 'Account Type is required.' }]}
                          isSame={(prev, curr) => prev?.id === curr?.id}
                        >
                          <AsyncSelect
                            name="banking_accountType"
                            optionsApiUrl="/options/7"
                            placeholder="Account Type"
                            ariaLabel="Account Type"
                          />
                        </FormItem>
                      </Col>

                      <Col xs={24} sm={12} md={8}>
                        <FormItem
                          name={['banking', 'accountNumber']}
                          label="Account Number"
                          rules={[
                            { required: true, message: 'Account Number is required.' },
                            { validator: getNumericValidator('Invalid Account Number.') },
                          ]}
                        >
                          <SecureInput />
                        </FormItem>
                      </Col>

                      <Col xs={24} sm={12} md={8}>
                        <FormItem
                          name={['banking', 'routingNumber']}
                          label="Routing Number"
                          rules={[
                            { required: true, message: 'Routing Number is required.' },
                            { validator: getNumericValidator('Invalid Routing Number.') },
                          ]}
                        >
                          <Input />
                        </FormItem>
                      </Col>
                    </Row>
                  </>
                )}
              </Card>

              <Card>
                <div className="flex space-x-2">
                  <Button onClick={() => setStep(ADD_FACILITY_STEPS.PROGRAM)}>Back</Button>

                  <div className="flex-grow"></div>

                  <Button
                    onClick={() => {
                      shouldGoToNextStep.current = false;

                      form.submit();
                    }}
                    disabled={isFormSubmitting || isSubmitDisabled}
                    loading={isFormSubmitting}
                  >
                    Save
                  </Button>

                  <Button
                    type="primary"
                    role="button"
                    onClick={() => {
                      shouldGoToNextStep.current = true;

                      form.submit();
                    }}
                    disabled={isFormSubmitting || isSubmitDisabled}
                    loading={isFormSubmitting}
                    data-testid="business-info-form-submit"
                  >
                    Next
                  </Button>
                </div>
              </Card>
            </Spin>
          );
        }}
      </Form>
    </div>
  );
}
