import useBranch from './useBranch';
import useComponent from './useComponent';
import useInstallation from './useInstallation';
import useStatus from './useStatus';

export default function useMilitaryInfo({ person }) {
  const position = person?.position || {};
  const { programSponsorId, militaryComponentId, militaryStatusId, militaryInstallationId } = position;

  const { branch } = useBranch({
    id: programSponsorId,
  });
  const { component } = useComponent({
    id: militaryComponentId,
    programSponsorId,
  });

  const { status } = useStatus({
    id: militaryStatusId,
    programSponsorId,
    componentId: militaryComponentId,
  });

  const { installation } = useInstallation({
    id: militaryInstallationId,
    programSponsorId,
  });

  return {
    branch,
    component,
    status,
    installation,
  };
}
