// import * as config from 'app-config';

// export default {
//   ...config,
//   REACT_APP_GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
//   REACT_APP_RELEASE_ID: process.env.REACT_APP_RELEASE_ID,
//   REACT_APP_ENV: process.env.REACT_APP_ENV,
// };

const env = process.env.REACT_APP_ENV || 'dev';
const config = require(`./${env}`);
export default {
  ...config,
  REACT_APP_GOOGLE_MAP_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  REACT_APP_TINYMCE_KEY: process.env.REACT_APP_TINYMCE_KEY,
  REACT_APP_RELEASE_ID: process.env.REACT_APP_RELEASE_ID,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
};
