import React from 'react';
import { Divider, Input } from 'antd';

import Section from 'components/Section';
import { EMPLOYMENT_STATUS, PHONE_TYPES, employmentStatusTypes } from 'constants/index';
import { getFullName, getPhoneNumberValidator } from 'utils';
import Form from 'components/Form';
import classNames from 'classnames';
import Select, { AsyncSelect } from 'components/Select';
import { familyService } from 'services';
import AddressFields from 'components/AddressFields';
import PhoneNumber from 'components/PhoneNumber';
import Group, { GroupContext } from 'components/Group';
import Switch from 'components/Switch';

export default function SecondParentCreation({ application, parentId, parentIds, profileData, index }) {
  const form = Form.useFormInstance();
  const prefix = Form.useWatch([parentId, 'prefix'], form);
  const firstName = Form.useWatch([parentId, 'firstName'], form);
  const lastName = Form.useWatch([parentId, 'lastName'], form);

  const values = Form.useWatch([parentId], form);

  const _fullName = [prefix?.title, firstName, lastName].filter(Boolean).join(' ') || '-';
  const _sponsorFullName = getFullName(application?.applicant) || 'N/A';

  // const fillWithDemoData = () => {
  //   form.setFieldValue(parentId, APPLICATION_DEMO_DATA[5]);
  // };

  return (
    <>
      <Section
        className={classNames('bg-white', {
          'mb-6': index !== parentIds.length - 1,
          'mt-6': index !== 0,
        })}
        headingClassName="page-title"
        heading={`Additional Parent #${index + 1}`}
        contentClassName=""
      >
        {/* <div className="px-8 mb-8">
          <Button
            type="primary"
            className="mt-[2px]"
            icon={<ImMagicWand className="align-middle mt-[-1px]" />}
            onClick={fillWithDemoData}
          >
            Fill with Demo Data
          </Button>
        </div> */}

        <Section headingClassName="page-title" heading="Basic Information" collapsible={false}>
          <div className="flex flex-wrap gap-4 w-[900px]">
            <Form.Item
              name={[parentId, 'prefix']}
              label="Select Prefix"
              rules={[{ required: true, message: 'Prefix is required.' }]}
              isSame={(prev, curr) => prev?.id === curr?.id}
              className="w-[20%]"
            >
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/19"
                placeholder="Select..."
                ariaLabel="Select Prefix"
              />
            </Form.Item>

            <Form.Item
              name={[parentId, 'firstName']}
              label="First Name"
              rules={[{ required: true, message: 'First Name is required' }]}
              className="w-[35%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item name={[parentId, 'middleName']} label="Middle Name/Initial" className="w-[35%]">
              <Input />
            </Form.Item>

            <Form.Item
              name={[parentId, 'lastName']}
              label="Last Name"
              rules={[{ required: true, message: 'Last Name is required.' }]}
              className="w-[35%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item name={[parentId, 'suffix']} label="Select Suffix" className="w-[20%]">
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/20"
                placeholder="Select..."
                ariaLabel="Select Suffix"
              />
            </Form.Item>

            <Form.Item
              name={[parentId, 'maritalStatus']}
              label="Marital Status"
              rules={[{ required: true, message: 'Marital Status is required' }]}
              className="w-[35%]"
            >
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/6"
                placeholder="Select..."
                ariaLabel="Marital Status"
              />
            </Form.Item>
          </div>
        </Section>

        <Section headingClassName="page-title" heading="Contact Information" collapsible={false}>
          <div className="flex flex-wrap gap-4 w-[900px]">
            <Form.Item
              name={[parentId, 'email']}
              label="Preferred Email Address"
              rules={[
                { required: true, message: 'Preferred Email is required' },
                { type: 'email', message: 'Preferred Email Address in invalid' },
              ]}
              className="w-[55%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item
              validateFirst
              name={[parentId, 'workEmail']}
              label={'Military Email Address'}
              rules={[
                {
                  type: 'email',
                  message: `${'Secondary Email'} is invalid`,
                },
              ]}
              className="w-[37%]"
            >
              <Input autoComplete="nope" />
            </Form.Item>

            <Form.Item
              name={[parentId, 'phone']}
              label="Preferred Phone Number"
              rules={[
                { required: true, message: 'Preferred Phone Number is required' },
                getPhoneNumberValidator('Invalid Preferred Phone Number!'),
              ]}
              className="w-[32%]"
            >
              <PhoneNumber placeholder />
            </Form.Item>

            <Form.Item name={[parentId, 'phoneType']} label="Phone Type" className="w-[15%]">
              <Select
                ariaLabel="Preferred Phone Type"
                options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
                isOptionSelected={(opt, [value]) => opt?.title === value}
                onChangeFormatter={(v) => v?.title}
              />
            </Form.Item>

            <Form.Item
              name={[parentId, 'secondaryPhone']}
              label="Secondary Phone Number"
              rules={[getPhoneNumberValidator('Invalid Secondary Phone Number!')]}
              className="w-[32%]"
            >
              <PhoneNumber placeholder />
            </Form.Item>

            <Form.Item name={[parentId, 'secondaryPhoneType']} label="Phone Type" className="w-[15%]">
              <Select
                ariaLabel="Secondary Phone Type"
                options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
                isOptionSelected={(opt, [value]) => opt?.title === value}
                onChangeFormatter={(v) => v?.title}
              />
            </Form.Item>

            <AddressFields addressKey={[parentId, 'homeAddress']} />
          </div>
        </Section>

        <Divider />

        <Section headingClassName="page-title" heading="Employment Information" collapsible={false}>
          <div className="flex flex-wrap gap-4 w-[900px]">
            <Form.Item
              name={[parentId, 'employmentStatus']}
              rules={[{ required: true, message: 'Choose at least one option.' }]}
              className="mb-0 mt-2"
            >
              <Group comparator={(v) => v} as="div">
                {employmentStatusTypes.map((type, _index) => (
                  <div className="" key={type.id}>
                    <div key={type} className="flex space-x-2 mb-5">
                      <GroupContext value={type.id}>
                        <Switch className="mr-6 mb-2 md:mb-0" />
                      </GroupContext>

                      <p className="text-base">{type.description}</p>
                    </div>

                    {_index === 0 && values?.employmentStatus === EMPLOYMENT_STATUS.EMPLOYED && (
                      <div>
                        <Form.Item
                          name={[parentId, 'position', 'selfEmployed']}
                          className="mb-2"
                          valuePropName="checked"
                        >
                          <SwitchWrapper title="Self-Employed" />
                        </Form.Item>

                        <Form.Item name={[parentId, 'militaryEmployee']} className="mb-6" valuePropName="checked">
                          <SwitchWrapper title="Military Personnel" name="militaryEmployee" />
                        </Form.Item>
                      </div>
                    )}

                    {_index === 2 && values?.employmentStatus === EMPLOYMENT_STATUS.NONEMPLOYED && (
                      <Form.Item name={[parentId, 'reasonForUnemployment']} className="mb-6">
                        <Group comparator={(v) => v} as="div">
                          {nestedUnEmploymentTypes.map((type) => (
                            <div className="flex justify-between w-4/5">
                              <span className="text-base block mt-4 pl-4">{type.title}</span>

                              <GroupContext value={type.id}>
                                <Switch className="mr-6 mb-4 md:mb-0" />
                              </GroupContext>
                            </div>
                          ))}
                        </Group>
                      </Form.Item>
                    )}
                  </div>
                ))}
              </Group>
            </Form.Item>
          </div>
        </Section>

        <Divider />

        <Section headingClassName="page-title" heading="Relationship to Sponsor" collapsible={false}>
          <div className="flex gap-3 mb-6 items-center">
            <span className="font-bold">{_sponsorFullName}</span>

            <span>is the</span>

            <Form.Item
              name={[parentId, 'relationshipType']}
              rules={[{ required: true, message: 'This field is required.' }]}
              className="m-0"
            >
              <AsyncSelect
                name={[parentId, 'relationshipType']}
                placeholder="Relation"
                ariaLabel="Relationship to Sponsor"
                optionsApiUrl="/options/24"
                apiPrefix="/families"
                isClearable={false}
              />
            </Form.Item>

            <span>of</span>

            <span className="font-bold">{_fullName}</span>
          </div>
        </Section>
      </Section>
    </>
  );
}

const nestedUnEmploymentTypes = [
  {
    id: 'LookingForWork',
    title: 'Are you looking for work?',
  },
  {
    id: 'MedicalReason',
    title: 'Is there a medical reason preventing you from working?',
  },
  {
    id: 'PowerOfAttorney',
    title: 'Are you a power of Attorney?',
  },
];

const SwitchWrapper = ({ onChange, checked, title, name }) => {
  return (
    <div className="flex justify-between w-4/5">
      <span className="text-base block mt-4 pl-4">{title}</span>
      <Switch id={name} className="mr-6 mb-4 md:mb-0" checked={checked} onChange={onChange} />
    </div>
  );
};
