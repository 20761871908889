import React from 'react';
import Form from 'components/Form';
import Editor from 'components/Editor';

export default function AutoSaveNotes({ notesKey = 'notes', draftNoteSaving, draftNote }) {
  return (
    <Form.Item noStyle shouldUpdate>
      {(form) => {
        const formNotes = form.getFieldValue(notesKey);
        return (
          <div className="px-6">
            <Form.Item name="notes" className="-mt-3">
              <Editor />
            </Form.Item>
            <div className="-mt-5">
              {draftNoteSaving ? (
                <div className="bg-blue-600 h-1 w-full rounded-full animate-pulse"></div>
              ) : !draftNote || draftNote?.content === formNotes ? (
                <div className="bg-green-600 h-1 w-full rounded-full"></div>
              ) : (
                <div className="bg-yellow-700 h-1 w-full rounded-full"></div>
              )}
            </div>
            <div className="flex justify-end">
              {draftNoteSaving ? (
                <span>Saving...</span>
              ) : !draftNote || draftNote?.content === formNotes ? (
                <span>Saved</span>
              ) : (
                <span>Not Saved</span>
              )}
            </div>
          </div>
        );
      }}
    </Form.Item>
  );
}
