import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ADD_FACILITY_STEPS, FACILITY_DETAILS_TABS, RESET_ALL } from 'constants/index';
// import { call, put, all, takeEvery } from 'redux-saga/effects';

const initialState = {
  providerOnboardingOpenTabs: {
    [FACILITY_DETAILS_TABS.BUSINESS_INFO]: true,
  },
  providerOnboardingOpenSteps: {
    [ADD_FACILITY_STEPS.PROGRAM]: true,
  },
  breadcrumbsParams: {},
};

export const selectProviderOnboardingOpenTabs = createSelector(
  (state) => {
    return state.applicationState;
  },
  (applicationState) => applicationState?.providerOnboardingOpenTabs,
);
export const selectProviderOnboardingOpenSteps = createSelector(
  (state) => {
    return state.applicationState;
  },
  (applicationState) => applicationState?.providerOnboardingOpenSteps,
);
export const selectBreadcrumbsParams = createSelector(
  (state) => {
    return state.applicationState;
  },
  (applicationState) => applicationState?.breadcrumbsParams,
);

export const applicationStateSlice = createSlice({
  name: 'applicationState',
  initialState,
  reducers: {
    setProviderOnboardingOpenTabs: (state, action) => {
      state.providerOnboardingOpenTabs = { ...state.providerOnboardingOpenTabs, ...action.payload };
    },
    setProviderOnboardingOpenSteps: (state, action) => {
      state.providerOnboardingOpenSteps = { ...state.providerOnboardingOpenSteps, ...action.payload };
    },
    setBreadcrumbsParams: (state, { payload }) => {
      state.breadcrumbsParams = payload;
    },
  },
  extraReducers: {
    [RESET_ALL]: () => {
      return initialState;
    },
  },
});
const reducer = applicationStateSlice.reducer;
const actions = {
  ...applicationStateSlice.actions,
};
const selectors = {
  selectProviderOnboardingOpenTabs,
  selectProviderOnboardingOpenSteps,
  selectBreadcrumbsParams,
};
export { reducer, actions, selectors, initialState };
export default applicationStateSlice;
