import { useAsync } from 'react-async-hook';

import { commonService } from 'services';

export default function useStatus({ id, programSponsorId, componentId, fallback = {} }) {
  const { result: status, loading } = useAsync(async () => {
    if (!programSponsorId || !componentId || !id) return fallback;
    const data = await commonService.get(
      `/common/program-types/family/statuses/by-component-branch?programSponsorId=${programSponsorId}&componentId=${componentId}`,
      { cache: { interpretHeader: false } },
    );
    return data.find((item) => item.id === id) || fallback;
  }, [id, programSponsorId, componentId]);
  return { status, loading };
}
