export default function FormattedAddress({ address }) {
  if (!address) {
    return null;
  }

  return (
    <div className="flex-col">
      <p className="!mb-0">{address.street}</p>

      {address.street2 && <p className="!mb-0">{address.street2}</p>}

      <p className="!mb-0">
        {address.city}, {address.state} {address.postalCode}
      </p>
    </div>
  );
}
