import configureIdleMonitor from 'redux-idle-monitor';
import { actions as authActions } from './auth';

const activeEvents = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
];
export const IDLESTATUS_AWAY = 'AWAY';
export const IDLESTATUS_INACTIVE = 'INACTIVE';
export const IDLESTATUS_EXPIRED = 'EXPIRED';

export const AWAY_TIMEOUT = 10 * 60 * 1000;
export const INACTIVE_TIMEOUT = 40 * 60 * 1000;
export const EXPIRED_TIMEOUT = 10 * 60 * 1000;
export const LOGOUT_TIMEOUT = AWAY_TIMEOUT + INACTIVE_TIMEOUT + EXPIRED_TIMEOUT;

export const IDLE_STATUSES = [IDLESTATUS_AWAY, IDLESTATUS_INACTIVE, IDLESTATUS_EXPIRED];

export const idleStatusDelay = (idleStatus) => () => {
  if (idleStatus === IDLESTATUS_AWAY) return AWAY_TIMEOUT; // User becomes away after 10 minutes inactivity
  if (idleStatus === IDLESTATUS_INACTIVE) return INACTIVE_TIMEOUT; // Mark inactive if user is away after 40 minutes of inactivity after entering into away state
  if (idleStatus === IDLESTATUS_EXPIRED) return EXPIRED_TIMEOUT; // Log them out after another 10 minutes after they enter the inactive status
};

export const idleStatusAction = (idleStatus) => (dispatch, getState) => {
  // if (idleStatus === IDLESTATUS_AWAY) console.info('user is away...');
  // if (idleStatus === IDLESTATUS_INACTIVE) console.info('You still there or what??');
  if (idleStatus === IDLESTATUS_EXPIRED) {
    const rememberMe = getState().auth.rememberMe;
    if (!rememberMe) dispatch(authActions.logout.pending());
  }
};

const opts = {
  appName: 'GCC_APP',
  IDLE_STATUSES,
  idleStatusDelay,
  idleStatusAction,
  activeEvents,
};

const { middleware, reducer, actions } = configureIdleMonitor(opts);
// export const idleReducer = reducer;
// export const idleMiddleware = middleware;
// export const idleActions = actions;

export { actions, middleware, reducer };
