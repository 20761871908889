import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useAsyncCallback } from 'react-async-hook';
import { FaPlus } from 'react-icons/fa';
import { BiTrash } from 'react-icons/bi';
import { IoWarning } from 'react-icons/io5';
import { RiErrorWarningLine } from 'react-icons/ri';
import { AiFillCaretDown, AiFillCaretUp, AiOutlineEdit } from 'react-icons/ai';
import { Button, message, Modal as AntdModal, Skeleton } from 'antd';

import Table from 'components/Table';
import Modal from 'components/Modal';
import useBreakpoints from 'hooks/useBreakpoints';
import ExpandedRowList from 'components/ExpandedRowList';
import AddAlertNote from 'components/forms/AddAlertNote';
import alertsService from 'services/alerts.service';
import { formatDateTimeToLocal, getFullName } from 'utils';

export default function AlertNotes({ record, loading, resolveAlert, resolvingAlert, reload }) {
  const breakpoints = useBreakpoints();
  const [expandedRows, setExpandedRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const [note, setNote] = useState({});
  const { loading: deleting, execute: deleteAlertNote } = useAsyncCallback(
    async (id) => {
      try {
        setNote(record);
        await alertsService.deleteAlertNote(record.id, id);
        /* istanbul ignore else */
        if (typeof reload === 'function') {
          reload(record);
        }
        message.success('Deleted note successfully.', 5);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to delete the note.', 5);
      } finally {
        setNote(null);
      }
    },
    [record.id],
  );

  const confirmDelete = useCallback(
    (record) => {
      AntdModal.confirm({
        title: (
          <div className="flex space-x-3 items-center">
            <RiErrorWarningLine size={20} className="text-yellow-700 inline-block" />
            <span>Are you sure you want to delete?</span>
          </div>
        ),
        icon: null,
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => deleteAlertNote(record.id),
      });
    },
    [deleteAlertNote],
  );

  const columns = useMemo(
    () => [
      {
        title: 'NOTES',
        headerText: 'NOTES',
        dataIndex: 'content',
        key: 'content',
        render: (message, record, showAll) => {
          return (
            <p
              className={classNames(
                'truncate overflow-ellipsis max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg 2xl:max-w-2xl ml-2',
              )}
            >
              {message}
            </p>
          );
        },
        fixed: 'left',
      },
      {
        title: 'COORDINATOR',
        headerText: 'COORDINATOR',
        dataIndex: ['createdBy', 'firstName'],
        key: 'createdBy',
        width: 150,
        align: 'center',
        render: (_, { createdBy }) => {
          return getFullName(createdBy) || 'System Generated';
        },
      },
      {
        title: 'DATE',
        headerText: 'DATE',
        dataIndex: 'lastModifiedDate',
        key: 'lastModifiedDate',
        width: 220,
        render: (date) => {
          return formatDateTimeToLocal(date);
        },
      },
      {
        title: 'ACTIONS',
        headerText: 'ACTIONS',
        key: 'actions',
        align: 'center',
        width: 100,
        fixed: 'right',
        render: (text, record) => {
          return (
            <div className="actions-cell bordered space-x-1 justify-center">
              <Button
                onClick={() => {
                  setNote(record);
                  setVisible(true);
                }}
                icon={<AiOutlineEdit />}
                className="icon-btn"
                aria-label="Edit Note"
                data-testid={`edit-note-button-${record.id}`}
                title="Edit Note"
              />
              <Button
                onClick={() => confirmDelete(record)}
                icon={<BiTrash />}
                className="icon-btn alert remove-button"
                aria-label="Delete Note"
                data-testid={`delete-note-button-${record.id}`}
                title="Delete Note"
                loading={note?.id === record.id && deleting}
                disabled={deleting}
              />
              {!breakpoints.lg && (
                <Button
                  onClick={() => {
                    if (expandedRows[0] === record.id) {
                      setExpandedRows([]);
                    } else {
                      setExpandedRows([record.id]);
                    }
                  }}
                  icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                  className="icon-btn"
                  aria-label={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
                  data-testid={`nested-collapse-button-${record.id}`}
                  title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
                />
              )}
            </div>
          );
        },
      },
    ],
    [breakpoints.lg, confirmDelete, deleting, expandedRows, note?.id],
  );
  return (
    <div className="w-full notes-table">
      <Skeleton active loading={loading} className="px-4 py-5">
        <Table
          rowKey="id"
          className={classNames('w-full p-3 pt-0', {
            'expanded-rows-table': !breakpoints.lg,
          })}
          allColumns={columns}
          data={record.notes}
          loading={loading}
          pagination={false}
          expandable={{
            expandedRowRender: (note) => {
              return (
                <div className="border border-gray-300 border-b-0">
                  <p className="p-3">{note.message}</p>
                  <ExpandedRowList data={note} columns={columns.slice(1)} className="p-3" />
                </div>
              );
            },
            expandedRowKeys: expandedRows,
            rowExpandable: (record) => !breakpoints.lg,
            expandIcon: () => null,
            columnWidth: 0,
          }}
          rowClassName={(record, index) => {
            return classNames('h-9', {
              '[&_td]:!bg-purple-50 [&_td]:!bg-opacity-25': index % 2 !== 0,
              '[&_td]:!bg-purple-50': index % 2 === 0,
            });
          }}
          scroll={{ x: 1200 }}
        />
      </Skeleton>
      <div className="flex flex-col space-y-2 md:flex-row md:justify-end md:space-y-0 md:space-x-2 px-3 pb-3">
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <Button
            onClick={() => setVisible(true)}
            className="flex flex-row items-center"
            icon={<FaPlus size={16} className="text-primary mr-2" />}
            data-testid={`add-note-btn-${record.id}`}
          >
            Add Note
          </Button>
          <Button
            type="primary"
            className="flex items-center"
            icon={<IoWarning color="rgb(245, 158, 11)" size={16} className="text-yellow-500 mr-2" />}
            onClick={() => resolveAlert(record)}
            loading={resolvingAlert}
            disabled={resolvingAlert}
            data-testid={`resolve-alert-btn-${record.id}`}
          >
            {record.status === 'Resolved' ? 'Unresolve' : 'Resolve'}
          </Button>
        </div>
      </div>

      <Modal width={700} visible={visible} setVisible={setVisible}>
        <AddAlertNote
          alertId={record.id}
          setVisible={(visible) => {
            setVisible(visible);
            setNote(null);
          }}
          data={note}
          reload={() => reload(record)}
        />
      </Modal>
    </div>
  );
}
