import React from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { Button, Col, Divider, Input, Row } from 'antd';

import Form from 'components/Form';
import UserOption from 'components/UserOption';
import PhoneNumber from 'components/PhoneNumber';
import Select, { AsyncSelect } from 'components/Select';
import { selectUser } from 'features/auth';
import { getFullName, getPhoneNumberValidator } from 'utils';
import { ROLES } from 'constants/index';
import { commonService } from 'services';
import { selectDashboardType } from 'features/dashboard';
import ClearableWrapper from 'components/ClearableWrapper';

export default function AdvancedSearchForm({ state, form, isFamilyDashbaord, onSubmit }) {
  const currentUser = useSelector(selectUser);
  const dashboardType = useSelector(selectDashboardType);
  const { loading: loadingAssignees, result: assignees = [] } = useAsync(async () => {
    return commonService.get(
      `/users/all?role=${isFamilyDashbaord ? ROLES.FAMILY_COORDINATOR : ROLES.PROVIDER_COORDINATOR}`,
    );
  }, []);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} data-testid={`advanced-search-${dashboardType}`}>
      <Row className="" gutter={[20, 5]}>
        {isFamilyDashbaord ? (
          <>
            <Col xs={24} md={8} xl={6}>
              <Form.Item label="Person Type" name={['personType']}>
                <Select
                  options={[
                    { id: 'applicant', title: 'Applicant' },
                    { id: 'additionalParent', title: 'Additional Parent' },
                    { id: 'child', title: 'Child' },
                  ]}
                  isOptionSelected={(opt, [value]) => opt.id === value}
                  onChangeFormatter={(v) => v?.id}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={4}>
              <Form.Item label="First Name" name={['person', 'firstName']}>
                <ClearableWrapper>
                  <Input placeholder="First Name" />
                </ClearableWrapper>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={4}>
              <Form.Item label="Last Name" name={['person', 'lastName']}>
                <ClearableWrapper>
                  <Input placeholder="Last Name" />
                </ClearableWrapper>
              </Form.Item>
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} md={8} xl={6}>
              <Form.Item label="Business Name" name={['businessName']}>
                <ClearableWrapper>
                  <Input placeholder="Business Name" />
                </ClearableWrapper>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={4}>
              <Form.Item label="Owner/Staff First Name" name={['person', 'firstName']}>
                <ClearableWrapper>
                  <Input placeholder="First Name" />
                </ClearableWrapper>
              </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={4}>
              <Form.Item label="Owner/Staff Last Name" name={['person', 'lastName']}>
                <ClearableWrapper>
                  <Input placeholder="Last Name" />
                </ClearableWrapper>
              </Form.Item>
            </Col>
          </>
        )}
        <Col xs={24} md={8} xl={5}>
          <Form.Item
            name={['phoneNumber']}
            label="Phone Number"
            rules={[getPhoneNumberValidator('Invalid Phone Number!')]}
          >
            <ClearableWrapper>
              <PhoneNumber />
            </ClearableWrapper>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xl={5}>
          <Form.Item label="Assignee" name={['assigneeId']}>
            <Select
              options={[
                { id: undefined, title: 'Any User', roles: [] },
                { id: currentUser?.id, title: 'Me', roles: [], username: currentUser?.email },
                ...(assignees || []).map((assignee) => ({ ...assignee, title: getFullName(assignee) })),
              ]}
              isOptionSelected={(opt, [value]) => opt.id === value}
              onChangeFormatter={(v) => v?.id}
              isLoading={loadingAssignees}
              components={{ Option: UserOption }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xl={5}>
          <Form.Item label="Program" name="programId" className="col-span-2 mb-0">
            <AsyncSelect
              optionsApiUrl="/programs?active=true"
              apiPrefix="/common"
              getOptionLabel={(option) => `${option.type.title} ${option.sponsor.title}`}
              getOptionValue={(option) => option.id}
              isOptionSelected={(option, [value]) => option.id === value}
              onChangeFormatter={(v) => v?.id}
              className="[&_.select__menu]:z-10 status-select"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xl={7}>
          <Form.Item label="Street Address" name={['address', 'street']}>
            <ClearableWrapper>
              <Input placeholder="Street Address" />
            </ClearableWrapper>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xl={4}>
          <Form.Item label="City" name={['address', 'city']}>
            <ClearableWrapper>
              <Input placeholder="City" />
            </ClearableWrapper>
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xl={4}>
          <Form.Item label="State" name={['address', 'state']}>
            <AsyncSelect
              name="mailingAddress.state"
              placeholder="State"
              ariaLabel="State"
              optionsApiUrl="/countries/states/US"
              getOptionLabel={(opt) => opt.name}
              getOptionValue={(opt) => opt.code}
              isOptionSelected={(option, [value]) => option?.code === value}
              onChangeFormatter={(v) => v?.code}
              isClearable
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8} xl={4}>
          <Form.Item label="Zip Code" name={['address', 'postalCode']}>
            <ClearableWrapper>
              <Input placeholder="Zip Code" />
            </ClearableWrapper>
          </Form.Item>
        </Col>
      </Row>

      <Divider className="mt-0" />

      <div className="actions mb-4">
        <Button
          data-testid="clear-all-filters"
          onClick={() => {
            form.resetFields();
          }}
        >
          Clear All
        </Button>

        <Button
          type="primary"
          htmlType="submit"
          loading={state.loading}
          disabled={state.disabled}
          data-testid="advanced-search-submit-btn"
        >
          Search
        </Button>
      </div>
    </Form>
  );
}
