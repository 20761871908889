import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Divider, Row, Col } from 'antd';
import Button from 'components/Button';
import { MdPhone } from 'react-icons/md';

import useOnClickOutside from 'hooks/useOnClickOutside';
import styles from './LocationCard.module.less';
import { getCompletedAddress, getFormattedPhoneNumber, concatLatLng, renderAgeRange } from 'utils';
import { SiGooglemaps } from 'react-icons/si';

export default function LocationCard({
  style,
  id,
  businessName,
  name,
  sponsorAddress,
  address,
  phone,
  className,
  handleDirections,
  setProvider,
  visible,
  photos,
  lat,
  lng,
  minAgeServed,
  maxAgeServed,
  careLocationType,
  isPublicSearch,
  facilityType,
}) {
  console.log('minAgeServed', minAgeServed, maxAgeServed);
  const ref = useRef();
  useOnClickOutside(
    ref,
    () => {
      /* istanbul ignore else */
      if (visible) {
        setProvider(null);
      }
    },
    [],
    ['mousedown', 'touchstart'],
  );
  /* istanbul ignore else */
  if (!visible) return null;
  const formattedPhone = getFormattedPhoneNumber(phone || '');
  const fullAddress = getCompletedAddress(address || sponsorAddress);
  return (
    <div
      ref={ref}
      style={style}
      className={`ant-card  location-card ${styles.card} ${className}`}
      id={`location-card-${id}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="card-arrow" />
      <div className="ant-card-body shadow-lg rounded-xl">
        {formattedPhone && (
          <Button className="telephone-number">
            <a href={`tel:${phone}`}>
              {/* <img src="/images/phone.svg" alt="phone" />  */}
              {/* <Icon component={Phone} className="phone-icon" /> {phone} */}
              <MdPhone className="phone-icon" /> {formattedPhone}
            </a>
          </Button>
        )}
        <h3 className="heading">{name || businessName}</h3>
        <p className="address">{fullAddress}</p>
        <Divider />
        <Row>
          <Col xs={20} className="details">
            {facilityType && <span>Facility Type: {facilityType}</span>}
            {minAgeServed && maxAgeServed && (
              <span>
                Age:{' '}
                {renderAgeRange({
                  minAge: minAgeServed,
                  maxAge: maxAgeServed,
                  showFullDuration: true,
                })}
              </span>
            )}
          </Col>

          <Col xs={4} className="flex-vrt"></Col>
          <Button
            type="primary"
            className="directions rounded-full relative"
            onClick={(e) => handleDirections(e, { lat, lng, address: fullAddress })}
          >
            <a
              href={`http://maps.google.com/maps?daddr=${concatLatLng({
                lat,
                lng,
              })}`}
              // href={`http://maps.google.com/maps?saddr=${concatLatLng(startPosition)}&daddr=${concatLatLng(
              //   endPosition,
              // )}`}
              target="_blank"
              // disabled={disabled}
              // {...tabIndexProps}
              className="flex items-center justify-center gap-2 text-white hover:text-white absolute top-0 left-0 w-full h-full"
              rel="noreferrer"
              data-testid="directionsBtn"
            >
              <SiGooglemaps className="h-5 w-5 mt-[-2px]" /> Directions (Google Map)
            </a>
          </Button>
        </Row>
      </div>
    </div>
  );
}

LocationCard.propTypes = {
  address: PropTypes.any,
  // ageGroups: PropTypes.any,
  // amenities: PropTypes.array,
  // careLocationHours: PropTypes.any,
  // childCareTypes: PropTypes.any,
  minAgeServed: PropTypes.number,
  maxAgeServed: PropTypes.number,
  facilityType: PropTypes.string,
  className: PropTypes.any,
  handleDirections: PropTypes.func,
  lat: PropTypes.any,
  lng: PropTypes.any,
  name: PropTypes.any,
  phone: PropTypes.any,
  photos: PropTypes.any,
  id: PropTypes.any,
  setProvider: PropTypes.func,
  style: PropTypes.object,
  visible: PropTypes.any,
};

LocationCard.defaultProps = {
  style: {},
  handleDirections: () => {},
};
