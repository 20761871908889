import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';

import Form from 'components/Form';
import Switch from 'components/Switch';
import Group, { GroupContext } from 'components/Group';
import { AsyncSelect } from 'components/Select';
import useEntityGroup from 'hooks/useEntityGroup';
import Section from 'components/Section';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import SponsorInstallationModal from 'components/Modals/SponsorInstallationModal';
import { employmentStatusTypes, EMPLOYMENT_STATUS } from 'constants/index';
import CommanderInfo from 'components/CommanderInfo';
import AddressFields from 'components/AddressFields';
import { getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';

const nestedUnEmploymentTypes = [
  {
    id: 'LookingForWork',
    title: 'Are you looking for work?',
  },
  {
    id: 'MedicalReason',
    title: 'Is there a medical reason preventing you from working?',
  },
  {
    id: 'PowerOfAttorney',
    title: 'Are you a power of Attorney?',
  },
];
export default function AdultEmploymentInfoContainer(props) {
  return <EmploymentInfo {...props} />;
}

export function EmploymentInfo({ value: parent = {}, application, className, reload, householdId, adultId, record }) {
  const items = !record?.employmentStatus ? [] : [{ ...parent }];

  const onAddItem = useCallback(
    async (data) => {
      let employmentInfo = data?.employmentType?.reduce((a, v) => ({ ...a, [v]: true }), {});
      let position = {
        ...record?.position,
        ...data.position,
      };
      if (!data?.militaryEmployee) {
        position = {
          ...position,
          militaryComponentId: null,
          militaryStatusId: null,
          programSponsorId: null,
          grade: null,
          recruiterType: null,
          militaryInstallationId: null,
        };
      }
      await familyService.addUpdateAdult(householdId, adultId, { ...employmentInfo, ...data, position });
      return await reload();
    },
    [record, householdId, adultId, reload],
  );
  const onDeleteItem = useCallback(
    async (item) => familyService.deleteAdultEmploymentInfo(application?.id, item.id),
    [application?.id],
  );
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">Details</span>,
        headerText: 'Details',
        dataIndex: 'employmentStatus',
        key: 'employmentStatus',
        ellipsis: true,
        width: 150,
        render: (employmentStatus) => employmentStatus || 'N/A',
        fixed: 'left',
      },
      {
        title: <span className="action-header">Status</span>,
        headerText: 'Status',
        dataIndex: 'militaryEmployee',
        key: 'militaryEmployee',
        ellipsis: true,
        width: 130,
        render: (_, record) =>
          record?.employmentStatus === EMPLOYMENT_STATUS.EMPLOYED
            ? record?.employmentType
            : record?.unEmploymentType || 'N/A',
      },
      {
        title: <span className="action-header">Supervisor Name</span>,
        headerText: 'Commander Supervisor Name',
        dataIndex: ['position'],
        key: 'name',
        ellipsis: true,
        width: 150,
        render: (value) => getFullName(value) || 'N/A',
      },
      {
        title: <span className="action-header">Supervisor Phone Number</span>,
        headerText: 'Supervisor Phone Number',
        dataIndex: ['position', 'supervisorCommanderPhone'],
        key: 'supervisorCommanderPhone',
        ellipsis: true,
        width: 150,
        render: (supervisorCommanderPhone) => getFormattedPhoneNumber(supervisorCommanderPhone) || 'N/A',
      },
      {
        title: <span className="action-header">Unit or Company Name</span>,
        headerText: 'Unit or Company Name',
        dataIndex: ['position', 'unitOfAssignment'],
        key: 'unitOfAssignment',
        ellipsis: true,
        render: (unitOfAssignment) => unitOfAssignment || 'N/A',
        width: 130,
      },
      {
        title: <span className="action-header">Unit Code</span>,
        headerText: 'Unit Code or Employer Type',
        dataIndex: ['position', 'unitIdCode'],
        key: 'unitIdCode',
        ellipsis: true,
        render: (unitIdCode) => unitIdCode || 'N/A',
        width: 100,
      },
      {
        title: <span className="action-header">Address</span>,
        headerText: 'Address',
        dataIndex: 'workAddress',
        key: 'address',
        ellipsis: true,
        render: (address) => getCompletedAddress(address) || 'N/A',
        width: 150,
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback((props) => {
    return (
      <AdultEmploymentInformationFormFields
        // programSponsorId={programSponsorId}
        // militaryComponentId={militaryComponentId}
        // employmentTypes={employmentTypes}
        // visibleInstallationModal={visibleInstallationModal}
        {...props}
      />
    );
  }, []);

  const { section } = useEntityGroup({
    items,
    onChange: () => {},
    onAddItem,
    onDeleteItem,
    entity: application,
    getAllColumns,
    getFormFields,
    entityTitle: 'Employment information',
    tableProps: { scrollX: 1000 },
    disableAddButton: items?.length === 1,
    hideDeleteButton: true,
    initialValues: parent,
    entityName: 'adultEmploymentInformation',

    readOnly: true,
  });
  return (
    <div className={classNames(className)}>
      <Section heading="Employment Information" collapsible={false} className={className}>
        <div>{section}</div>
      </Section>
    </div>
  );
}

const SwitchWrapper = ({ onChange, checked, title, name }) => {
  return (
    <div className="flex justify-between w-4/5">
      <span className="text-base block mt-4 pl-4">{title}</span>
      <Switch id={name} className="mr-6 mb-4 md:mb-0" checked={checked} onChange={onChange} />
    </div>
  );
};

export const AdultEmploymentInformationFormFields = ({
  values,
  setVisible,
  items,
  form,
  item,
  index,
  addUpdateLoading,
  militaryEmployee,
  isFamilyUser,
}) => {
  const employmentTypes = useMemo(
    () => employmentStatusTypes.filter((item) => item.id !== EMPLOYMENT_STATUS.UNEMPLOYED),
    [],
  );
  return (
    <div>
      <h3 className="section-title mb-6">Step 3: Enter Adult's Employment Information</h3>
      <Form.Item
        name={'employmentStatus'}
        rules={[{ required: true, message: 'Choose at least one option.' }]}
        className="mb-0 mt-2"
      >
        <Group comparator={(v) => v} as="div">
          {employmentTypes.map((type, index) => (
            <div className="" key={type.id}>
              <div key={type} className="flex space-x-2 mb-5">
                <GroupContext value={type.id}>
                  <Switch className="mr-6 mb-2 md:mb-0" />
                </GroupContext>
                <p className="text-base">{type.description}</p>
              </div>
              {index === 0 && values?.employmentStatus === EMPLOYMENT_STATUS.EMPLOYED && (
                <div>
                  <Form.Item name={['position', 'selfEmployed']} className="mb-2" valuePropName="checked">
                    <SwitchWrapper title="Self-Employed" />
                  </Form.Item>
                  <Form.Item name="militaryEmployee" className="mb-6" valuePropName="checked">
                    <SwitchWrapper title="Military Personnel" name="militaryEmployee" />
                  </Form.Item>
                </div>
              )}
              {index === 1 && values?.employmentStatus === EMPLOYMENT_STATUS.NONEMPLOYED && (
                <Form.Item name="reasonForUnemployment" className="mb-6">
                  <Group comparator={(v) => v} as="div">
                    {nestedUnEmploymentTypes.map((type, index) => (
                      <div className="flex justify-between w-4/5">
                        <span className="text-base block mt-4 pl-4">{type.title}</span>
                        <GroupContext value={type.id}>
                          <Switch className="mr-6 mb-4 md:mb-0" />
                        </GroupContext>
                      </div>
                    ))}
                  </Group>
                </Form.Item>
              )}
            </div>
          ))}
        </Group>
      </Form.Item>
      {values?.employmentStatus === EMPLOYMENT_STATUS.EMPLOYED && militaryEmployee && (
        <CommanderInfo
          heading="Commander Information"
          prefixName={[]}
          collapsible={false}
          contentClassName=""
          headerContainerClassName="!px-0 md:!px-0 xl:!px-0 !py-4"
        />
      )}
      {!militaryEmployee && values?.employmentStatus === EMPLOYMENT_STATUS.EMPLOYED && (
        <CommanderInfo
          heading="Supervisor Information"
          prefixName={[]}
          isSupervisor
          readOnly={false}
          contentClassName=""
          collapsible={false}
          headerContainerClassName="!px-0 md:!px-0 xl:!px-0 !py-4"
        />
      )}

      {(values?.employmentStatus === EMPLOYMENT_STATUS.EMPLOYED || militaryEmployee) && (
        <>
          <div className="pt-2 pb-4">
            <span className="section-title">Current Work Address</span>
            <span className="text-primary">
              (Enter address if you need assistance search for childcare near this location)
            </span>
          </div>
          <AddressFields id="adult-work-address" addressKey="workAddress" required={false} />
        </>
      )}
      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-employment-information-btn">
          Back
        </Button>
        <Button
          type="primary"
          onClick={() => form.submit()}
          data-testid="save-employment-information-btn"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
        >
          {isFamilyUser ? 'Next' : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export const AdultMilitaryInformationFormFields = ({ values, form, addUpdateLoading, onCancel, isFamilyUser }) => {
  const { militaryComponentId, programSponsorId } = { ...values?.position };
  const [visibleInstallationModal, setVisibleInstallationModal] = useState(false);
  return (
    <>
      <div className="mb-6">
        <span className="section-title">Step 4: Enter Adult's Military Information</span>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8">
        <div>
          <Form.Item
            name={['position', 'programSponsorId']}
            label="Branch"
            rules={[{ required: true, message: 'Branch is required' }]}
          >
            <AsyncSelect
              apiPrefix="/common"
              optionsApiUrl="/program-sponsors"
              placeholder="Select"
              ariaLabel="Select Branch"
              isOptionSelected={(option, [value]) => option?.id === value}
              onChangeFormatter={(v) => v?.id}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['position', 'militaryComponentId']}
            label="Component"
            rules={[{ required: true, message: 'Component is required' }]}
          >
            <AsyncSelect
              key={programSponsorId}
              getOptions={commonService.get}
              apiPrefix="/common"
              optionsApiUrl={
                programSponsorId
                  ? `/program-types/family/components?branchId=${programSponsorId}`
                  : `/program-types/family/components`
              }
              placeholder="Select"
              ariaLabel="Select Component"
              isOptionSelected={(option, [value]) => option?.id === value}
              onChangeFormatter={(v) => v?.id}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['position', 'militaryStatusId']}
            label="Status"
            rules={[{ required: true, message: 'Status is required' }]}
          >
            <AsyncSelect
              key={`${programSponsorId}-${militaryComponentId}`}
              getOptions={commonService.get}
              apiPrefix="/common"
              optionsApiUrl={
                programSponsorId && militaryComponentId
                  ? `/program-types/family/statuses/by-component-branch?programSponsorId=${programSponsorId}&componentId=${militaryComponentId}`
                  : `/program-types/family/statuses`
              }
              placeholder="Select"
              ariaLabel="Select Status"
              isOptionSelected={(option, [value]) => option?.id === value}
              onChangeFormatter={(v) => v?.id}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['position', 'grade']}
            label="Military grade"
            rules={[{ required: true, message: 'Military grade is required' }]}
          >
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/options/3"
              placeholder="Grade"
              ariaLabel="Select Grade"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={['position', 'recruiterType']} label="Recruiter Type (If Applicable)">
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/options/4"
              placeholder="Recruiter Type"
              ariaLabel="Recruiter Type"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={['position', 'militaryInstallationId']} label="Installation">
            <SponsorInstallationModal
              visible={visibleInstallationModal}
              setVisible={setVisibleInstallationModal}
              parentForm={form}
              programSponsorId={programSponsorId}
              showSelect={true}
              value={values?.militaryInstallationId?.id}
              onChange={(militaryInstallationId, selectedInstallation) => {
                form.setFieldsValue({ position: { militaryInstallationId } });
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div className="actions flex mt-5">
        <Button onClick={onCancel} data-testid="cancel-school-information-btn">
          Cancel
        </Button>
        <Button
          type="primary"
          data-testid="save-school-info-btn"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
          onClick={() => form.submit()}
        >
          Save
        </Button>
      </div>
    </>
  );
};
