import React, { useMemo } from 'react';
import { Divider } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FaRegFolderOpen, FaSearchLocation, FaUsers } from 'react-icons/fa';
import { IoMdHelp } from 'react-icons/io';
import { FcTodoList } from 'react-icons/fc';
import { BiEnvelope } from 'react-icons/bi';
// import { MdAutorenew } from 'react-icons/md';
import { FiCalendar } from 'react-icons/fi';
import { useAsync } from 'react-async-hook';

// import useModalState from 'hooks/useModalState';
import Page from 'components/Layout/Page';
// import ApplicationRenewalPopup from '../ApplicationRenewalPopup';
import config from 'config';
import { CenterIcon } from 'utils/icons';
import { familyService } from 'services';
import Modal from 'components/Modal';
import useModalState from 'hooks/useModalState';

const links = [
  {
    title: 'My Applications',
    icon: <FcTodoList className="[&_g]:!fill-primary" />,
    to: '/family/applications',
    description: 'Manage Sponsor, Second Parent, Children profile',
  },
  {
    title: 'Messages',
    icon: <BiEnvelope />,
    to: '/family/messages',
    description: 'Your emails, and communications related to your applications',
  },
  config.ENV !== 'uat' && {
    title: 'Attendance Sheets',
    icon: <FiCalendar />,
    to: '/family/attendance-sheets',
    description: 'Active care certificate attendance for your children',
  },
  config.ENV !== 'uat' && {
    title: 'My Past Applications',
    icon: <FaRegFolderOpen />,
    to: '/family/applications',
    description: 'Review older applications',
  },
  config.ENV !== 'uat' && {
    title: 'My Providers',
    icon: <CenterIcon />,
    to: '/family/providers',
    description: 'Provider selected for your application',
  },
  {
    title: 'Provider Search',
    icon: <FaSearchLocation />,
    to: '/search',
    description: 'Find providers in your area',
  },
  {
    title: 'Help',
    icon: <IoMdHelp />,
    to: '/family/help',
    description: 'Find resources and documents to help you with the application',
  },
].filter(Boolean);

export default function LandingPage({ history }) {
  const { visible, setVisible } = useModalState();
  // logic for renewal will be implemented later
  // const isRenewalRequired = true;
  // const renewal = useMemo(
  //   () => ({
  //     title: 'Annual Application Renewal',
  //     titleClassName: 'text-red-700',
  //     icon: <MdAutorenew className="text-red-800" />,
  //     to: '/family',
  //     description: 'Review my sponsor, second parent, children information for renewal',
  //     onClick: () => {
  //       onOpen();
  //     },
  //   }),
  //   [onOpen],
  // );
  const familyProfile = useMemo(
    () => ({
      title: 'My Family Profile',
      icon: <FaUsers className="text-primary" />,
      to: '/family/profile',
      description: 'Manage Sponsor, Second Parent, and Children profiles',
      onClick: () => {
        history.push('/family/profile');
      },
    }),
    [history],
  );
  const { result: houseHold } = useAsync(async () => {
    return await familyService.getHouseHold();
  }, []);
  return (
    <>
      <Page>
        <div className="flex flex-col md:flex-row md:items-center md:space-x-3  -mt-4 mb-3 ">
          <div className="h-16 w-32">
            <img className="logo block h-[70] w-32" src="/images/ccaoa-logo.png" alt="Logo" />
          </div>
          <h1 className="font-medium">Welcome to CCAoA Portal</h1>
        </div>
        <div className="section white-box">
          <h3 className="section-title uppercase">My Landing Page</h3>
          {houseHold?.id && (
            <div className="mb-3 -mt-2">
              <p className="text-lg">
                Family ID: <span className="font-semibold">{houseHold.id}</span>
              </p>
            </div>
          )}
          {/* {isRenewalRequired && config.ENV !== 'uat' && (
            <>
              <p className="instructions mb-4">Please prepare your annual renewal for next application year.</p>
              <Grid items={[renewal]} />
              <Divider />
            </>
          )} */}
          <Grid items={[familyProfile]} />
          <Divider />
          <div className={classNames('relative')}>
            <p className="instructions mb-4 max-w-4xl">
              {/* FAMILY ID: {application} (Please make sure to remember this ID number in order to reference when calling in for
              updates). <br />*/}
              Review your current or old application or your provider application. <br />
              If you want assistance please reach out to CCAoA team to understand the process.
            </p>
            <Grid items={links} />
            <Divider />
            <p className="italic">
              If you need any assistance, please reach out to the CCAoA team to understand the process at 1-800-424-2246
            </p>
          </div>
        </div>
      </Page>
      {/* <ApplicationRenewalPopup visible={visible} setVisible={setVisible} /> */}
      <Modal visible={visible} setVisible={setVisible}>
        <h3 className="text-primary text-lg font-semibold">Family Profile Placeholder</h3>
      </Modal>
    </>
  );
}

function Grid({ items }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10 gap-x-5 my-8">
      {items.map((link) => {
        const Tag = link.onClick ? 'a' : Link;
        return (
          <div className="center-box flex flex-col w-full items-center xxl:px-4" key={link.title}>
            <Tag
              to={link.to}
              onClick={link.onClick}
              className={`flex justify-center items-center text-6xl w-36 h-36 mb-5 rounded-full focus:ring-primary bg-primary-light text-primary`}
            >
              {link.icon}
            </Tag>
            <Tag
              to={link.to}
              onClick={link.onClick}
              className={classNames('text-15 text-primary font-medium text-center', link.titleClassName)}
              tabIndex="-1"
            >
              <span className="">{link.title}</span>
            </Tag>
            <p className="text-center mt-2">{link.description}</p>
          </div>
        );
      })}
    </div>
  );
}
