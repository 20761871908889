import React, { Component } from 'react';
// import 'source-map-support/register';

import GeneralErrorState from './GeneralErrorState';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <GeneralErrorState
          error={this.state.error ? { message: this.state.error.toString() } : { message: 'Unknown error' }}
          extra={this.state.errorInfo?.componentStack}
        />
      );
    }

    return this.props.children;
  }
}
