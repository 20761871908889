import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { IoWarning } from 'react-icons/io5';
import { useAsyncCallback } from 'react-async-hook';

import { Button, message, Spin } from 'antd';
import { BiTrash } from 'react-icons/bi';
import { FaArrowLeft, FaEnvelope, FaRegEnvelopeOpen } from 'react-icons/fa';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from 'react-icons/md';
import ColumnTitle from 'components/ColumnTitle';
import { EMAIL_STATUS, ROLES, EMAIL_TYPES } from 'constants/index';
import { formatDate, formatDateTimeToLocal, getFullName } from 'utils';
import Table from 'components/Table';
import ScheduleEmail from 'components/Modals/SendEmailModal/ScheduleEmail';
import EmailPreview from 'components/EmailPreview';
import Modal from 'components/Modal';
import communicationsService from 'services/communications.service';
import useHasRole from 'hooks/useHasRole';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Card from 'components/Card/Card';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';
import EmptyState from 'components/EmptyState/EmptyState';

export default function Emails({ className, state, entityLabel, type, isExternal = false }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const isProvider = useHasRole(ROLES.PROVIDER);
  const isFamily = useHasRole(ROLES.FAMILY);
  const { loading: canceling, execute: cancelScheduleEmail } = useAsyncCallback(
    async (record) => {
      try {
        await communicationsService.cancelScheduleEmail(record.id);
        await state.fetchMore(state.pagination);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to cancel email.', 5);
        return record;
      }
    },
    [state],
  );
  const {
    loading: detailsLoading,
    result: details,
    execute: getItemDetails,
    merge: setCommunicationDetails,
  } = useAsyncCallback(async (record) => {
    try {
      return await communicationsService.getCommunicationDetails(record.id);
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to cancel email.', 5);
      return record;
    }
  }, []);
  const { loading: updatingReadFlag, execute: markAsRead } = useAsyncCallback(
    async (record) => {
      try {
        await communicationsService.markEmailRead(record.id, !record.read);
        if (details?.id === record.id) {
          setCommunicationDetails({ result: { ...details, read: !record.read } });
        }
        state.fetchMore({ current: state.pagination.current, pageSize: state.pagination.pageSize });

        message.success(`Email has been marked as ${record.read ? 'unread' : 'read'}.`);
      } catch (error) {
        newrelic.noticeError(error);
        message.error(`Unable to mark email as ${record.read ? 'unread' : 'read'}.`);
      }
    },
    [setCommunicationDetails, state.pagination],
  );
  const columns = useMemo(() => {
    let columns = [
      {
        title: (props) => <ColumnTitle showSorting {...props} title="Type" dataIndex={['status']} colKey="type" />,
        headerText: 'Type',
        dataIndex: ['status'],
        key: 'type',
        width: 220,
        sorter: true,
        render: (status, { type, read }) => {
          let directionIcon = <FaArrowLeft className="text-green-500" />;
          let Icon = read ? FaRegEnvelopeOpen : FaEnvelope;
          let icon;
          /* istanbul ignore else */
          if (status === EMAIL_STATUS.PENDING) {
            icon = <Icon className="text-primary" />;
          } else if (status === EMAIL_STATUS.QUEUED || status === EMAIL_STATUS.DELIVERED) {
            icon = <Icon className="text-primary" />;
          } else if (status === EMAIL_STATUS.ERROR) {
            directionIcon = <IoWarning className="text-red-700 text-base" />;
            icon = <Icon className="text-red-700" />;
          }

          return (
            <div className="flex items-center space-x-3">
              <span className="w-6 flex justify-center items-center">{directionIcon}</span>
              <div className="flex items-center space-x-2">
                {icon}
                {status === EMAIL_STATUS.ERROR ? (
                  <span className="text-red-700">Email Error</span>
                ) : (
                  <span>{capitalize(type)}</span>
                )}
              </div>
            </div>
          );
        },
      },
    ];
    if (entityLabel) {
      columns = columns.concat({
        title: (props) => (
          <ColumnTitle showSorting {...props} title={entityLabel} dataIndex={['entityId']} colKey="entityId" />
        ),
        headerText: entityLabel,
        dataIndex: ['entityId'],
        key: 'entityId',
        sorter: true,
        className: 'truncate max-w-xs',
        render: (id, record) => {
          let to = `/applications/${id}`;
          if (isProvider) {
            to = `/providers/application/${id}`;
          } else if (isFamily) {
            to = `/family/application/${id}`;
          }
          return (
            <Link
              className={classNames(
                {
                  '!text-red-700': record.status === EMAIL_STATUS.ERROR,
                  '!text-black': record.status !== EMAIL_STATUS.ERROR,
                },
                'underline',
              )}
              to={to}
            >
              {id}
            </Link>
          );
        },
      });
    }
    columns = columns.concat(
      {
        title: (props) => <ColumnTitle {...props} title="Sender Name" dataIndex={['createdBy']} colKey="name" />,
        headerText: 'Sender Name',
        dataIndex: ['createdBy'],
        key: 'name',
        className: 'truncate max-w-xs',
        render: (createdBy, record) => {
          const fullName = getFullName(createdBy) || 'System Generated';
          return (
            <span className={classNames({ 'text-red-700': record.status === EMAIL_STATUS.ERROR })} title={fullName}>
              {fullName}
            </span>
          );
        },
      },
      {
        title: (props) => <ColumnTitle showSorting {...props} title="Subject" dataIndex="subject" colKey="subject" />,
        headerText: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        sorter: true,
        className: 'truncate max-w-xs',
        render: (value, record) => (
          <span
            className={classNames('font-medium', { 'text-red-700': record.status === EMAIL_STATUS.ERROR })}
            title={value}
          >
            {value}
          </span>
        ),
      },
    );
    if (type === EMAIL_TYPES.SCHEDULED) {
      columns = columns.concat({
        title: (props) => (
          <ColumnTitle showSorting {...props} title="Date Scheduled" dataIndex="scheduledDate" colKey="createdAt" />
        ),
        headerText: 'Date Scheduled',
        dataIndex: 'scheduledDate',
        key: 'createdAt',
        sorter: true,
        render: (date) => formatDate(date),
        width: 200,
      });
    } else if (type === EMAIL_TYPES.INCOMING) {
      const headerText = isExternal ? 'Date/Time Sent' : 'Date/Time Received';
      columns = columns.concat({
        title: (props) => (
          <ColumnTitle showSorting {...props} title={headerText} dataIndex="deliveryDateTime" colKey="createdAt" />
        ),
        headerText,
        dataIndex: 'deliveryDateTime',
        key: 'createdAt',
        sorter: true,
        render: (date) => formatDateTimeToLocal(date),
        width: 220,
      });
    } else {
      const headerText = isExternal ? 'Date/Time Received' : 'Date/Time Sent';
      columns = columns.concat({
        title: (props) => (
          <ColumnTitle showSorting {...props} title={headerText} dataIndex="deliveryDateTime" colKey="createdAt" />
        ),
        headerText,
        dataIndex: 'deliveryDateTime',
        key: 'createdAt',
        sorter: true,
        render: (date, record) => {
          return record.status === EMAIL_STATUS.QUEUED ? 'Pending' : formatDateTimeToLocal(date);
        },
        width: 220,
      });
    }
    columns = columns.concat({
      title: <span className="action-header mt-2 justify-center">Actions</span>,
      headerText: 'Actions',
      key: 'actions',
      width: 120,
      // eslint-disable-next-line react/display-name
      render: (text, record, index) => {
        return (
          <div className="flex space-x-1">
            {!isExternal && (
              <Button
                className="icon-btn"
                icon={
                  record.read ? (
                    <MdOutlineMarkEmailRead className="!text-green-600" />
                  ) : (
                    <MdOutlineMarkEmailUnread className="!text-primary text-opacity-50" />
                  )
                }
                title={record.read ? 'Mark As Unread' : 'Mark As Read'}
                onClick={() => markAsRead(record)}
                disabled={updatingReadFlag}
                data-testid={`read-status-button-${record.id}`}
              />
            )}

            {record.status === EMAIL_STATUS.PENDING ? (
              <>
                <Button
                  onClick={() => {
                    if (expandedRows[0] === record.id) {
                      setExpandedRows([]);
                    } else {
                      setExpandedRows([record.id]);
                      getItemDetails(record);
                    }
                  }}
                  icon={<AiOutlineEdit />}
                  className="icon-btn"
                  aria-label="Edit"
                  data-testid={`edit-button-${record.id}`}
                  title="Edit"
                />
                <Button
                  icon={<BiTrash />}
                  className="icon-btn alert remove-button"
                  aria-label="Remove"
                  data-testid={`remove-button-${record.id}`}
                  title="Remove"
                  onClick={() => cancelScheduleEmail(record)}
                />
              </>
            ) : (
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                  } else {
                    setExpandedRows([record.id]);
                    getItemDetails(record);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <ChevronUp /> : <ChevronDown />}
                className="icon-btn"
                aria-label="Open"
                data-testid={`collapse-button-${record.id}`}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            )}
          </div>
        );
      },
    });
    return columns;
  }, [
    cancelScheduleEmail,
    entityLabel,
    expandedRows,
    getItemDetails,
    isExternal,
    isFamily,
    isProvider,
    markAsRead,
    type,
    updatingReadFlag,
  ]);
  const colKeys = columns.map((col) => col.key);
  const commonProps = {
    rowKey: 'id',
    className: classNames('w-full'),
    allColumns: columns,
    xsCols: ['subject', 'actions'],
    smCols: ['subject', 'actions'],
    mdCols: ['subject', 'actions'],
    lgCols: ['name', 'subject', 'actions'],
    xlCols: colKeys,
    xxlCols: colKeys,
    pagination: { ...state.pagination, showSizeChanger: false, hideOnSinglePage: true },
    loading: state.loading,
    expandable: {
      expandedRowRender: (record) => {
        return (
          <ExpandedRow
            record={{ ...record, ...(details || {}) }}
            loading={detailsLoading}
            type={type}
            onCancel={() => {
              setExpandedRows([]);
            }}
          />
        );
      },
      rowExpandable: () => true,
      expandedRowKeys: expandedRows,
      expandIcon: () => null,
      columnWidth: 0,
    },
    rowClassName: (record, index) => {
      let className = '';
      if (record.status === EMAIL_STATUS.PENDING) {
        className = index % 2 === 0 ? 'bg-primary bg-opacity-5' : 'bg-primary bg-opacity-1';
      } else {
        className = index % 2 === 0 ? 'bg-blue-100 border-b border-dashed' : 'bg-blue-50';
      }
      /* istanbul ignore else */
      if (record.status === EMAIL_STATUS.ERROR) {
        className = index % 2 === 0 ? 'bg-red-100' : 'bg-red-50';
      }
      return classNames(className, 'h-10', { expanded: expandedRows.indexOf(record.id) > -1 });
    },
  };

  if (state.loading || canceling) {
    return (
      <div className="py-24">
        <Spin className="block m-auto" />
      </div>
    );
  }

  if (!state.data || !state.data.length) {
    return <EmptyState descriptions />;
  }

  return (
    <div className={classNames(className)}>
      <Table {...commonProps} data={state.data} onChange={state.onChange} />
    </div>
  );
}

function ExpandedRow({ record, type, ...props }) {
  const [visible, setVisible] = useState(false);
  /* istanbul ignore else */
  if (record.status === EMAIL_STATUS.PENDING) {
    return (
      <Card className="bg-white border-yellow-500 email-renderer">
        <div className="p-8" data-testid="schedule-email-edit-form">
          <ScheduleEmail
            setVisible={() => {}}
            showSchedule
            {...props}
            entityType={record.entityType}
            entityId={record.entityId}
            record={record}
          />
        </div>
      </Card>
    );
  }

  return (
    <Card className="bg-white email-renderer">
      <EmailPreview {...props} data={record} type={type} showExpand />

      <Modal width="90%" visible={visible} setVisible={setVisible}>
        <EmailPreview {...props} data={record} type={type} />
      </Modal>
    </Card>
  );
}
