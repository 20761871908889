export const ENV = 'prod';
export const API_BASE_URL = 'https://api.greatchildcare.org';

export const AMPLIFY_CONFIG = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:94d920ea-c7f0-4f20-8fcf-b96d98110763',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_ZDI7vfDic',
  aws_user_pools_web_client_id: '35j29am1nsn0jcce10c8gs08fi',
  oauth: {},
  federationTarget: 'COGNITO_IDENTITY_POOLS',
  aws_user_files_s3_bucket: 'prod-gcc-uploads-bucket',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_content_delivery_bucket: 'prod-gcc-hosting-bucket',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d3asgaatu79p3i.cloudfront.net',
};
