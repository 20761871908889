import React from 'react';

import Form from 'components/Form';
import Section from 'components/Section';
import { getFullName } from 'utils';
import ApplicationEntityReview from '../../__components/ApplicationEntityReview';
import { AsyncSelect } from 'components/Select';

export default function ChildInfoTab({
  application,

  childId,
  profileData,
}) {
  const child = profileData?.children?.find((_child) => _child.id === childId);

  const _fullName = [
    (child?.prefix?.title || '').toLowerCase() !== 'prefer not to say' ? child?.prefix?.title : null,
    getFullName(child) || 'N/A',
  ]
    .filter(Boolean)
    .join(' ');
  const _sponsorFullName = getFullName(application?.applicant) || 'N/A';

  // const [form] = Form.useForm();
  // const isInternal = activeRole !== ROLES.FAMILY;
  // const { allDocuments = [] } = useSelector(documentSelectors.selectDocumentsState);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     children: {
  //       [index]: {
  //         ...(application.children[index] || {}),
  //       },
  //     },
  //   });
  //   // Note: this should be separate, otherwise form will not set address values
  //   form.setFieldsValue({ homeAddress: child?.homeAddress });
  // }, [application, child, form, index]);

  // useEffect(() => {
  //   const documents = allDocuments?.filter(
  //     (doc) =>
  //       ['PVF', 'ADC', 'BC', 'DF', 'CSC'].includes(doc.type.title) &&
  //       doc.subEntityId === child.id &&
  //       doc.subEntityType === ENTITY_TYPES.CHILD,
  //   );
  //   form.setFieldsValue({
  //     children: {
  //       [index]: {
  //         documents,
  //         documentsMetaData: documents?.map((doc) => ({ type: doc.type, alias: doc.alias })),
  //       },
  //     },
  //   });
  // }, [allDocuments, child.id, form, index]);

  return (
    <div className="white-box">
      {/* {application?.status?.status?.title === 'INCOMPLETE' && (
        <Tooltip placement="top" title="Archive This Child">
          <Button
            onClick={onDeleteChild}
            className={`icon-btn alert remove-button bordered absolute top-3 right-5 !w-10 !h-10`}
            icon={<BiTrash />}
            aria-label="Archive This Child"
            loading={deletingChild}
            disabled={deletingChild}
          />
        </Tooltip>
      )} */}

      <ApplicationEntityReview className="rounded-b-none" entity={child} isChild />

      <Section headingClassName="page-title" heading="Relationship to Sponsor" collapsible={false}>
        <div className="flex gap-3 mb-6 items-center">
          <span className="font-bold">{_sponsorFullName}</span>

          <span>is the</span>

          <Form.Item
            name={[childId, 'relationshipType']}
            rules={[{ required: true, message: 'This field is required.' }]}
            className="m-0"
          >
            <AsyncSelect
              name={[childId, 'relationshipType']}
              placeholder="Relation"
              ariaLabel="Relationship to Sponsor"
              optionsApiUrl="/options/26"
              apiPrefix="/families"
              isClearable={false}
            />
          </Form.Item>

          <span>of</span>

          <span className="font-bold">{_fullName}</span>
        </div>
      </Section>
    </div>
  );
}
