import React from 'react';
import PropTypes from 'prop-types';
import { Slider } from 'antd';
import FilterContainer from '../FilterContainer';

import styles from './RangeFilter.module.less';

const marks = {
  0: '0',
  10: '10',
  20: '20',
  30: '30',
  40: '40',
  50: '50',
};
export default function RangeFilter({
  id,
  title,
  min,
  max,
  tipFormatter,
  range,
  value,
  step,
  defaultValue,
  visible,
  titleClassName,
  onChange,
}) {
  const tabIndexProps = {};
  tabIndexProps['aria-hidden'] = (!visible).toString();
  if (!visible) {
    tabIndexProps.tabIndex = '-1';
  }
  return (
    <FilterContainer id={id} title={title} className={styles.rangeFilter} titleClassName={titleClassName}>
      <div className="range-container" id={`range-tool-tip-container-${id}`}>
        <span className="min">Miles</span>
        {visible && (
          <Slider
            range={range}
            value={value}
            min={min}
            max={max}
            marks={marks}
            step={step}
            onChange={onChange}
            tipFormatter={tipFormatter}
            tooltipVisible
            // autoFocus
            defaultValue={undefined}
            getTooltipPopupContainer={() => document.getElementById(`range-tool-tip-container-${id}`)}
            // aria-labelledby={`${id}-title`}
            aria-label={title}
            {...tabIndexProps}
          />
        )}
      </div>
    </FilterContainer>
  );
}

RangeFilter.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  tipFormatter: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  range: PropTypes.bool,
  visible: PropTypes.bool,
  sliderRef: PropTypes.any,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
};
RangeFilter.defaultProps = {
  step: 10,
  range: false,
  visible: true,
  defaultValue: 0,
  tipFormatter: (v) => v,
};
