import classNames from 'classnames';

export default function Space({ size, children, direction, vCentered, className }) {
  return (
    <div
      className={classNames('flex', {
        'gap-2': size === undefined,
        [`gap-${size}`]: size,
        'items-center': vCentered !== false,
        'flex-col': direction === 'vertical',
        [className]: className,
      })}
    >
      {children}
    </div>
  );
}

export function Spacer() {
  return <div className="flex-grow" />;
}
