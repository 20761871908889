import React, { useCallback, useEffect } from 'react';
import { Button, InputNumber, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';

import Form from 'components/Form';
import Select from 'components/Select';
import Switch from 'components/Switch';
import Section from 'components/Section';
import Group, { GroupMapContext } from 'components/Group';
import CertificateSummaryModal from 'containers/Family/FamilyCertificates/CertificateSummaryModal';
import { familyService } from 'services';
import { dollarFormatterParserProps } from 'utils';
import useModalState from 'hooks/useModalState';

export default function CalculateCertificate({
  id,
  householdId,
  certificateId,
  certificate,
  application,
  secondParent,
  child,
  installation,
  prev,
  next,
}) {
  const [form] = Form.useForm();
  const history = useHistory();
  const { visible: summaryVisible, onOpen: showSummary, setVisible } = useModalState();
  const managerOverride = true;

  const onSave = useCallback(
    async (values) => {
      const payload = {
        id: certificateId,
        ...values,
        ...values.bonus,
      };
      return await familyService.updateCertificate(certificateId, payload);
    },
    [certificateId],
  );
  const { loading: submitting, execute: onSubmit } = useAsyncCallback(
    async (values) => {
      try {
        await onSave(values);
        history.push(next);
      } catch (error) {
        message.error(`Unable to ${certificateId ? 'update' : 'create'} certificate.`, 5);
        newrelic.noticeError(error);
      }
    },
    [certificateId, householdId, id, next],
  );
  const { loading: calculating, execute: calculateFeeAssistance } = useAsyncCallback(async () => {
    try {
      const values = await form.validateFields();
      await onSave(values);
      await familyService.caculateCertificacteFeeAssistance(certificateId);
      showSummary();
    } catch (error) {
      message.error(`Unable to ${certificateId ? 'update' : 'create'} certificate.`, 5);
      newrelic.noticeError(error);
    }
  }, [certificateId]);

  useEffect(() => {
    form.setFieldsValue({
      ...certificate,
      bonus: {
        firstChild: certificate.firstChild,
        woundedWarrior: certificate.woundedWarrior,
        deploymentBonus: certificate.deploymentBonus,
        partTime: certificate.partTime,
      },
    });
  }, [certificate, form]);

  return (
    <Form form={form} className="pb-6" onFinish={onSubmit}>
      <Section
        heading="Provider Rates"
        headerContainerClassName="!px-4 py-4"
        triggerClassName="!top-3 !right-3"
        contentClassName="px-6 pb-6"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-6 items-center">
          <Form.Item name="inputProviderRateFrequency" label="Provider Rate Frequency">
            <Select
              name="billingFrequency"
              options={[
                { id: 'BI_WEEKLY', title: 'Bi-Weekly' },
                { id: 'WEEKLY', title: 'Weekly' },
                { id: 'TWICE_MONTHLY', title: 'Twice Monthly' },
                { id: 'MONTHLY', title: 'Monthly' },
                { id: 'QUARTERLY', title: 'Quarterly' },
                { id: 'ANNUALLY', title: 'Annually' },
              ]}
              hideSeparator
              placeholder="Weekly/Monthly/Annually"
              ariaLabel="Weekly/Monthly/Annually"
              isOptionSelected={(opt, [value]) => opt?.id === value}
              onChangeFormatter={(v) => v?.id}
            />
          </Form.Item>
          <Form.Item name="inputProviderRate" label="Provider Rate">
            <InputNumber min={0} placeholder="$" />
          </Form.Item>
          <Form.Item name="providerDiscount" label="Provider Discount">
            <InputNumber min={0} placeholder="% or $" />
          </Form.Item>
          <Form.Item name="providerRegistrationFee" label="Provider Registration Fee">
            <InputNumber min={0} placeholder="$" />
          </Form.Item>
        </div>
        <Form.Item name="bonus">
          <Group multiple>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-6">
              <div className="flex justify-start space-x-2 mb-5">
                <GroupMapContext value="firstChild">
                  <Switch yesLabel="" noLabel="" className="mr-6 mb-2 md:mb-0 min-w-min" />
                </GroupMapContext>
                <p className="text-base">Older Child Bonus</p>
              </div>
              <div className="flex justify-start space-x-2 mb-5">
                <GroupMapContext value="woundedWarrior">
                  <Switch yesLabel="" noLabel="" className="mr-6 mb-2 md:mb-0 min-w-min" />
                </GroupMapContext>
                <p className="text-base">Wounded Warrier Bonus</p>
              </div>
              <div className="flex justify-start space-x-2 mb-5">
                <GroupMapContext value="deployed">
                  <Switch yesLabel="" noLabel="" className="mr-6 mb-2 md:mb-0 min-w-min" />
                </GroupMapContext>
                <p className="text-base">Deployment Bonus</p>
              </div>
              <div className="flex justify-start space-x-2 mb-5">
                <GroupMapContext value="partTime">
                  <Switch yesLabel="" noLabel="" className="mr-6 mb-2 md:mb-0 min-w-min" />
                </GroupMapContext>
                <p className="text-base">Part-Time Calculation</p>
              </div>
            </div>
          </Group>
        </Form.Item>
        <div>
          <Button
            className="w-full md:w-60"
            onClick={calculateFeeAssistance}
            loading={calculating}
            disabled={calculating}
          >
            Calculate Fee Assistance
          </Button>
        </div>
      </Section>
      <Section
        heading="Fee Assistance Calculation"
        headerContainerClassName="!px-4 py-4"
        triggerClassName="!top-3 !right-3"
        contentClassName="px-6 pb-6"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 items-center">
          <Form.Item name="providerRate" label="Provider Rate">
            <InputNumber min={0} placeholder="$" disabled={managerOverride} {...dollarFormatterParserProps} />
          </Form.Item>
          <Form.Item name="sponsorRate" label="Sponsor Rate">
            <InputNumber min={0} placeholder="$" disabled={managerOverride} {...dollarFormatterParserProps} />
          </Form.Item>
          <Form.Item name="feeAssistanceRate" label="Fee Assistance Amount">
            <InputNumber min={0} placeholder="$" disabled={managerOverride} {...dollarFormatterParserProps} />
          </Form.Item>
        </div>
        <div>
          <Button className="w-full md:w-60" onClick={showSummary}>
            View Summary
          </Button>
        </div>
      </Section>
      <div className="px-6 pt-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
        <Button
          onClick={() => history.push(`/families/${householdId}/applications/${application.id}`)}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button onClick={() => history.push(prev)} disabled={submitting}>
          Back
        </Button>
        <Button type="primary" htmlType="submit" disabled={submitting} loading={submitting}>
          Next
        </Button>
      </div>
      <CertificateSummaryModal
        id={id}
        visible={summaryVisible}
        setVisible={setVisible}
        certificate={certificate}
        application={application}
        secondParent={secondParent}
        child={child}
        installation={installation}
      />
    </Form>
  );
}
