import React, { useMemo } from 'react';
import { Button } from 'antd';

import Form from 'components/Form';
import AddressFields from 'components/AddressFields';
import Select from 'components/Select';
import { getFullName } from 'utils';

export default function AddChildAddress({ profileData, form, setVisible, addUpdateLoading, isFamilyUser }) {
  const adults = useMemo(() => profileData?.adults || [], [profileData?.adults]);
  return (
    <div>
      <div className="mb-6">
        <span className="section-title">Step 2: Enter Child's Home Address</span>
      </div>
      <Form.Item name="selectedAdult" label="Select Adult">
        <Select
          name="selectedAdult"
          placeholder="Select Adult"
          options={adults}
          getOptionLabel={(opt) => getFullName(opt)}
          onChange={(value) => {
            form.setFieldsValue({ homeAddress: value?.homeAddress });
            return value;
          }}
        />
      </Form.Item>
      <AddressFields addressKey="homeAddress" id="homeAddress" form={form} />
      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-adult-btn">
          Back
        </Button>
        <Button
          type="primary"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
          onClick={() => form.submit()}
          data-testid="save-adult-btn"
        >
          {isFamilyUser ? 'Next' : 'Save'}
        </Button>
      </div>
    </div>
  );
}
