import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { MdClose } from 'react-icons/md';
import { Prompt, useHistory } from 'react-router-dom';

import { sleep } from 'utils';

export default function ConfirmRouteChange({
  when,
  title,
  content,
  okText,
  cancelText,
  onOk,
  decider,
  destroyOnClose,
}) {
  const history = useHistory();
  const [allow, setAllow] = useState(false);
  return (
    <Prompt
      when={!allow && when}
      message={(location, action) => {
        const decision = (decider && decider(location, action)) || allow;
        if (!decision) {
          Modal.confirm({
            title,
            content,
            destroyOnClose,
            okText,
            cancelText,
            onOk: async () => {
              setAllow(true);
              await sleep(100);
              if (onOk) {
                onOk(location, action);
              } else {
                history.push(location.pathname);
              }
            },
            closable: true,
            // getContainer: '.layout-page',
            width: 600,
            closeIcon: <MdClose />,
          });
        }

        return decision;
      }}
    />
  );
}

ConfirmRouteChange.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  when: PropTypes.bool,
  destroyOnClose: PropTypes.bool,
  decider: PropTypes.func,
  onOk: PropTypes.func,
};

ConfirmRouteChange.defaultProps = {
  when: true,
  destroyOnClose: true,
  decider: () => false,
  cancelText: 'Cancel',
  okText: 'Confirm',
  title: 'Unsaved Changes',
  content: <p className="instructions font-semibold py-8">Are you sure you want to leave?</p>,
};
