import React, { useMemo } from 'react';

import Modal from 'components/Modal';
import InfoSection from 'components/InfoSection';
import useProgram from 'hooks/useProgram';
import { formatDate, getFullName } from 'utils';
import useMilitaryInfo from 'hooks/useMilitaryInfo';
import { useAsync } from 'react-async-hook';
import { familyService } from 'services';
import numeral from 'numeral';
import { Divider } from 'antd';

export default function CertificateSummaryModal({
  id,
  visible,
  setVisible,
  certificate,
  application,
  secondParent,
  installation,
  child,
}) {
  const { program } = useProgram({ id: certificate?.programId });
  const { branch, component, status } = useMilitaryInfo({ person: application?.applicant });
  const { result: totalAnnualIncome } = useAsync(async () => {
    const { totalAnnualIncome } = await familyService.getApplicationIncome(id);
    return totalAnnualIncome;
  }, []);
  const provider = useMemo(() => certificate?.schedules?.[0]?.careFacility, [certificate?.schedules]);
  const { branch: spouseBranch, component: spouseComponent } = useMilitaryInfo({ person: secondParent });

  const sponsorInfoItems = useMemo(
    () => [
      {
        id: 'branch_title',
        title: 'Branch',
        value: branch?.title || 'N/A',
      },
      {
        id: 'installation_name',
        title: 'Installation',
        value: installation?.name || 'N/A',
      },
      {
        id: 'person_position_grade_title',
        title: 'Military Grade',
        value: application?.applicant?.position?.grade?.title || 'N/A',
      },
      {
        id: 'component_title',
        title: 'Component',
        value: component?.title || 'N/A',
      },
      {
        id: 'status_title',
        title: 'Sponsor Status',
        value: status?.title || 'N/A',
      },
      {
        id: 'person_state',
        title: 'Residence State',
        value: application?.applicant?.homeAddress?.state || 'N/A',
      },
    ],
    [
      application?.applicant?.homeAddress?.state,
      application?.applicant?.position?.grade?.title,
      branch?.title,
      component?.title,
      installation?.name,
      status?.title,
    ],
  );

  const secondParentInfoItems = useMemo(
    () => [
      {
        title: 'Employment Status',
        value: secondParent?.employmentStatus || 'N/A',
      },
      {
        title: 'Branch',
        value: spouseBranch?.title || 'N/A',
      },
      {
        id: 'person_position_grade_title',
        title: 'Military Grade',
        value: secondParent?.position?.grade?.title || 'N/A',
      },
      {
        id: 'component_title',
        title: 'Component',
        value: spouseComponent?.title || 'N/A',
      },
      {
        title: 'Start Date',
        value: formatDate(certificate.startDate),
      },
      {
        id: 'person_state',
        title: 'Residence State',
        value: secondParent?.homeAddress?.state || 'N/A',
      },
    ],
    [
      certificate.startDate,
      secondParent?.employmentStatus,
      secondParent?.homeAddress?.state,
      secondParent?.position?.grade?.title,
      spouseBranch?.title,
      spouseComponent?.title,
    ],
  );
  const childInfoItems = useMemo(
    () => [
      {
        title: 'Name',
        value: getFullName(child) || 'N/A',
      },
      {
        title: 'Date of Birth',
        value: formatDate(child?.dateOfBirth),
      },
      {
        title: 'Age Group',
        value: 'N/A',
      },
      {
        title: 'Program',
        value: program?.type?.title,
      },
      {
        title: 'Service Type Of Care',
        value: certificate?.schedules?.[0]?.careDurationType?.title || 'N/A',
      },
      {
        title: 'Applicable Bonuses',
        value: 'N/A',
      },
      {
        title: 'Bonus Amount',
        value: 'N/A',
      },
      {
        title: 'Part-Time Calculation',
        value: numeral(certificate?.partTimeCalculation || 0).format('$0,0.00'),
      },
      {
        title: 'Installation',
        value: installation?.name || 'N/A',
      },
    ],
    [certificate?.partTimeCalculation, certificate?.schedules, child, installation?.name, program?.type?.title],
  );
  const providerInfoItems = useMemo(
    () => [
      {
        id: 'provider_id',
        title: 'Provider ID',
        value: provider?.id,
      },
      {
        id: 'provider_name',
        title: 'Provider Name',
        value: provider?.businessLegalName,
      },
      {
        id: 'provider_installation',
        title: 'Installation',
        value: 'N/A',
      },
      {
        id: 'provider_init_rate',
        title: 'Initial Provider Rate',
        value: numeral(certificate?.inputProviderRate || 0).format('$0,0.00'),
      },
      {
        id: 'provider_discount',
        title: 'Discount Amount',
        value: 'N/A',
      },
      {
        id: 'provider_reg_fee',
        title: 'Provider Reg Fee',
        value: numeral(certificate?.providerRegistrationFee || 0).format('$0,0.00'),
      },
    ],
    [certificate?.inputProviderRate, certificate?.providerRegistrationFee, provider?.businessLegalName, provider?.id],
  );
  const onPostCalculationInfoItems = useMemo(
    () => [
      {
        id: 'family_income',
        title: 'Total Family Income',
        value: numeral(totalAnnualIncome || 0).format('$0,0.00'),
      },
      {
        id: 'income_category',
        title: 'Income Category',
        value: 'N/A',
      },
      {
        id: 'installation_rates_year',
        title: 'Installation Rates Year',
        value: 'N/A',
      },
      {
        id: 'installation_cost_high',
        title: 'Installation Cost (High)',
        value: 'N/A',
      },
      {
        id: 'on_post_sponsor_rate',
        title: 'On-Post Sponsor Rate',
        value: numeral(certificate?.sponsorRate || 0).format('$0,0.00'),
      },
    ],
    [certificate?.sponsorRate, totalAnnualIncome],
  );

  const certificateCalculationItems = useMemo(
    () => [
      {
        id: 'calculated_date',
        title: 'Calculated Date',
        value: 'N/A',
      },
      {
        id: 'certificate_start_date',
        title: 'Certificate Start Date',
        value: formatDate(certificate?.startDate),
      },
      {
        id: 'creation_reason',
        title: 'Creation Reason',
        value: certificate?.certificateType?.description || 'N/A',
      },
      {
        id: 'provider_rate_after_discount',
        title: 'Provider Rate After Discount',
        value: numeral(certificate.providerRate || 0).format('$0,0.00'),
      },
      {
        id: 'provider_rate_cap',
        title: 'Provider Rate Cap',
        value: numeral(certificate.providerRateCap || 0).format('$0,0.00'),
      },
      {
        id: 'sponsor_min_rate',
        title: (
          <p>
            Minimum Monthly Rate Sponsor is <br /> requried to pay (After Bonuses)
          </p>
        ),
        value: numeral(certificate.providerRateCap || 0).format('$0,0.00'),
      },
      <Divider className="my-2" />,
      {
        id: 'total_fee_assistance',
        title: <p className="text-red-500 font-medium">Total Monthly CCA Fee Assistance</p>,
        value: 'N/A',
        itemClassName: 'mb-1',
      },
      {
        id: 'certificate_endDate',
        title: <p className="text-red-500 font-medium">Certificate End Date</p>,
        value: formatDate(certificate?.endDate),
        itemClassName: 'mb-1',
      },
      {
        id: 'expiration_reason',
        title: <p className="text-red-500 font-medium">Expiration Reason</p>,
        value: certificate?.expirationReason?.description || 'N/A',
        itemClassName: 'mb-1',
      },
    ],
    [
      certificate?.certificateType?.description,
      certificate?.endDate,
      certificate?.expirationReason?.description,
      certificate.providerRate,
      certificate.providerRateCap,
      certificate?.startDate,
    ],
  );

  const zeroDollarItems = useMemo(
    () => [
      {
        id: 'zero_dollar_cert',
        title: 'Zero Dollar Cert',
        value: certificate?.zeroDollar || 'N/A',
      },
      {
        id: 'zero_dollar_amount',
        title: 'Zero Dollar Amount',
        value: numeral(certificate?.zeroDollarAmount || 0).format('$0,0.00'),
      },
    ],
    [certificate?.zeroDollar, certificate?.zeroDollarAmount],
  );

  return (
    <Modal visible={visible} setVisible={setVisible} width={1100}>
      <h3 className="text-primary text-base uppercase font-medium mb-6 -mt-2">Calculated Fee Assistance Overview</h3>
      <div className="grid grid-cols-1 xl:grid-cols-[1fr_350px] gap-x-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-6 xl:border-r-2">
          <Section title="Sponsor Info" items={sponsorInfoItems} />
          <Section title="Second Parent Info" items={secondParentInfoItems} />
          <Section title="Child Info" items={childInfoItems} />
          <Section title="Provider Info" items={providerInfoItems} />
          {/* <Section title="Applicable Discounts" items={discountItems} />
          <Section title="Sponsor Income" items={sponsorIncomeItems} />
          <Section title="Spouse Income" gridClassName="grid grid-cols-1" items={secondParentIncomeItems} /> */}
          {/* <Section title="Program Type" items={programItems} /> */}
        </div>
        <div>
          <Section
            title="On-Post Calculation Info"
            colsClassName="grid-cols-[250px_1fr]"
            items={onPostCalculationInfoItems}
          />
          <Section
            title="Certificate Calculation Info"
            colsClassName="grid-cols-[250px_1fr]"
            items={certificateCalculationItems}
          />
          <Section title="Zero Dollar Calculation Info" colsClassName="grid-cols-[250px_1fr]" items={zeroDollarItems} />
        </div>
      </div>
    </Modal>
  );
}

function Section({ title, items, ...rest }) {
  return (
    <div>
      <h4 className="text-primary font-semibold uppercase text-15 mb-2">{title}</h4>
      <InfoSection
        title="Program Type"
        items={items}
        className="pl-2"
        itemClassName="mb-2"
        gridClassName="grid grid-cols-1"
        valueClassName="font-semibold text-sm"
        titleClassName=""
        {...rest}
      />
    </div>
  );
}
