// /*
//  * store.js
//  */

import { fork, all } from 'redux-saga/effects';
import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import { reducer as authReducer, sagas as authSagas, actions as authActions } from 'features/auth';
import { reducer as idleReducer, middleware as idleMiddleware } from 'features/activity';
import { reducer as applicationStateReducer, actions as applicationStateActions } from 'features/application-state';
import { reducer as centerStateReducer, sagas as centerSagas } from 'features/add-center';
import { reducer as familyStateReducer, sagas as familySagas, actions as familyActions } from 'features/family';
import { reducer as providersReducer, sagas as providersSagas } from 'features/providers';
import { reducer as searchReducer, sagas as searchSagas } from 'features/search';
import { reducer as dashboardReducer, actions as dashboardActions, sagas as dashboardSagas } from 'features/dashboard';
import { reducer as documentReducer, actions as documentActions, sagas as documentSagas } from 'features/documents';
import createSagaMiddleware from 'redux-saga';
import * as reduxStorage from 'redux-storage';
import createEngine from 'redux-storage-engine-localstorage';
import { RESET_ALL } from 'constants/index';

function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(centerSagas),
    fork(familySagas),
    fork(providersSagas),
    fork(searchSagas),
    fork(dashboardSagas),
    fork(documentSagas),
  ]);
}

const engine = createEngine('redux-state');
const whiteListActions = [
  ['auth', authActions],
  ['family', familyActions],
  ['applicationState', applicationStateActions],
  ['dashboard', dashboardActions],
  ['document', documentActions],
].reduce((acc, [reducerPrefix, actions]) => {
  return acc.concat(Object.keys(actions).map((action) => `${reducerPrefix}/${action}`));
}, []);
const blackListActions = [`dashboard/toggleBatchAssign`];
const storageMiddleware = reduxStorage.createMiddleware(
  engine,
  [RESET_ALL, ...blackListActions],
  [...whiteListActions.filter((action) => !blackListActions.includes(action))],
  {
    disableDispatchSaveAction: true,
  },
);

const sagaMiddleware = createSagaMiddleware();
const middlewares = getDefaultMiddleware({
  serializableCheck: false,
  immutableStateInvariant: false,
});
const nullActionHandlerMiddleware = () => (next) => (action) => next(action || { type: 'NONE' });
const reducer = reduxStorage.reducer(createReducer());
const store = configureStore({
  reducer,
  middleware: [nullActionHandlerMiddleware, ...middlewares, sagaMiddleware, idleMiddleware, storageMiddleware],
});
// sagaMiddleware.run(sagas);
store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

store.asyncReducers = {};

// Create an inject reducer function
// This function adds the async reducer, and creates a new combined reducer
store.injectReducer = (key, asyncReducer) => {
  store.asyncReducers[key] = asyncReducer;
  // store.replaceReducer(persistReducer(rootPersistConfig, createReducer(store.asyncReducers)));
  // store.persistor.persist();
};
// const persistor = persistStore(store);
// store.persistor = persistor;

const load = reduxStorage.createLoader(engine);

// Notice that our load function will return a promise that can also be used
// to respond to the restore event.
load(store).catch((error) => {
  newrelic.noticeError(error);
});

export { store };
export default store;

function createReducer(asyncReducers) {
  return combineReducers({
    auth: authReducer,
    applicationState: applicationStateReducer,
    idle: idleReducer,
    center: centerStateReducer,
    family: familyStateReducer,
    providers: providersReducer,
    search: searchReducer,
    dashboard: dashboardReducer,
    documents: documentReducer,
    ...asyncReducers,
  });
}

function createSagaInjector(runSaga, rootSaga) {
  // Create a dictionary to keep track of injected sagas
  const injectedSagas = new Map();

  const isInjected = (key) => injectedSagas.has(key);

  const injectSaga = (key, saga) => {
    // We won't run saga if it is already injected
    if (isInjected(key)) return;

    // Sagas return task when they executed, which can be used
    // to cancel them
    const task = runSaga(saga);

    // Save the task if we want to cancel it in the future
    injectedSagas.set(key, task);
  };

  // Inject the root saga as it a statically loaded file,
  injectSaga('root', rootSaga);

  return injectSaga;
}
