import React, { useState, useEffect } from 'react';
import { Button, Divider } from 'antd';
import { HiOutlineArrowsExpand } from 'react-icons/hi';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Editor from 'components/Editor';
import CollapsePanel from 'components/CollapsePanel';
import useAutoSaveNotes from 'hooks/useAutoSaveNotes';
import AutoSaveNotes from './AutoSaveNotes';
import commonApi from 'services/common.service';
import DisqualifiedReasonModal from 'components/Modals/DisqualifiedReasonsModal';

export default function StatusNotes({
  id,
  statusState,
  updateStatus,
  notesState,
  getNote,
  saveNote,
  optionsApiUrl = '/application-status-types/internal?systemStatus=false',
  optionsApiPrefix = '/providers',
  getOptions = commonApi.get,
  className,
}) {
  const [notesPopupVisible, setNotesPopupVisible] = useState(false);

  const {
    draftNote,
    draftNoteSaving,
    saveDraft,
    form: notesForm,
    formSubmitting,
    submitForm,
    visibleDQModal,
    setVisibleDQModal,
  } = useAutoSaveNotes({ id, notesState, getNote, saveNote, statusState, updateStatus });

  useEffect(() => {
    notesForm.setFieldsValue({ status: statusState?.value });
  }, [statusState?.value, notesForm, statusState]);
  return (
    <div className={className}>
      <Form
        form={notesForm}
        layout="vertical"
        onFinish={submitForm}
        onKeyDown={(e) => (e.name === 'Enter' ? e.preventDefault() : '')}
        onValuesChange={({ status, ...rest }) => {
          if (status) {
            statusState.setValue(status);
          } else {
            saveDraft(rest);
          }
        }}
      >
        <>
          <CollapsePanel
            header={
              <div className="py-3 pl-6 bg-white flex justify-between items-center">
                <span className="text-xs text-gray-600 font-medium uppercase leading-2">Quick Notes</span>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setNotesPopupVisible(true);
                  }}
                  className="icon-btn mr-11"
                  icon={<HiOutlineArrowsExpand size={20} className="" />}
                  data-testid="notes-expand-btn"
                />
              </div>
            }
            triggerClassName="right-1 top-3"
            testId="form-section"
          >
            <AutoSaveNotes draftNoteSaving={draftNoteSaving} draftNote={draftNote} />
          </CollapsePanel>
          <div className="form-section px-6 border-t-2 border-dashed border-gray-300 pt-3">
            {/* <Form.Item
              rules={[{ required: true, message: 'Select a Status.' }]}
              name="status"
              label={<h3 className="text-gray-500 text-md font-semibold">Status</h3>}
            >
              <AsyncSelect
                optionsApiUrl={optionsApiUrl}
                apiPrefix={optionsApiPrefix}
                getOptions={getOptions}
                getOptionLabel={(opt) => opt.description}
              />
            </Form.Item> */}
            <div className="w-full">
              <Button
                htmlType="submit"
                type="primary"
                className="w-full"
                loading={formSubmitting}
                disabled={formSubmitting}
              >
                Next/Save
              </Button>
            </div>
          </div>
        </>
      </Form>
      <DisqualifiedReasonModal
        visible={visibleDQModal}
        setVisible={setVisibleDQModal}
        onSubmit={submitForm}
        notesForm={notesForm}
      />
      <Modal width={1000} visible={notesPopupVisible} setVisible={setNotesPopupVisible} destroyOnClose>
        <div className="flex flex-col">
          <h3 className="block text-md font-semibold uppercase text-primary mt-2">Notes</h3>
          <Divider className="!mt-2 !mb-3" />
          <Form form={notesForm} layout="vertical" onFinish={submitForm} onValuesChange={saveDraft}>
            {(values) => {
              return (
                <div className="">
                  <Form.Item name="notes" className="">
                    <Editor compact={false} height={300} placeholder="internal notes" />
                    {/* <Input.TextArea placeholder="internal notes" autoSize={{ minRows: 5 }} /> */}
                  </Form.Item>
                  <div className="-mt-5">
                    {draftNoteSaving ? (
                      <div className="bg-blue-600 h-1 w-full rounded-full animate-pulse"></div>
                    ) : !draftNote || draftNote?.content === values.notes ? (
                      <div className="bg-green-600 h-1 w-full rounded-full"></div>
                    ) : (
                      <div className="bg-yellow-700 h-1 w-full rounded-full"></div>
                    )}
                  </div>
                  <div className="flex justify-end">
                    {draftNoteSaving ? (
                      <span>Saving...</span>
                    ) : !draftNote || draftNote?.content === values.notes ? (
                      <span>Saved</span>
                    ) : (
                      <span>Not Saved</span>
                    )}
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
        <div className="flex justify-end mt-3">
          <Button data-testid="close-save-note-modal" onClick={() => setNotesPopupVisible(false)}>
            Close
          </Button>
        </div>
      </Modal>
    </div>
  );
}
