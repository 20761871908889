import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Avatar, Button, Divider, message, Spin, Table, Tooltip } from 'antd';
import Card from 'components/Card/Card';
import DocumentsTree from './__components/DocumentsTree';
import DocumentPreview from './__components/DocumentPreview';
import { ArrowLeft, CloudUpload, Document, DocumentPdf, Image, TableAlias, TrashCan } from '@carbon/icons-react';
import { providerService } from 'services';
import EmptyState from 'components/EmptyState/EmptyState';
import { Upload } from 'antd';
import { getProviderFileTypeTitleFromCode } from 'services/providers.service';
import classNames from 'classnames';
import ProviderDocumentFolderInstructions from './__components/ProviderDocumentFolderInstructions';
import dayjs from 'dayjs';
import Space, { Spacer } from 'components/Space/Space';
import Modal from 'components/Modal';
import {
  checkIfFileSizeIsSupported,
  checkIfFileTypeIsSupported,
  handleUnsupportedFileSize,
  handleUnsupportedFileType,
} from 'components/UploadFile/UploadFile';

const { Dragger } = Upload;

export default function CareFacilityFileManager({ center, readonly }) {
  const [currentFiles, setCurrentFiles] = React.useState(null);

  const fetchFiles = useCallback(() => {
    providerService.getCareFacilityFiles(center.id).then((_files) => {
      setCurrentFiles(_files);
      setUploading(false);
    });
  }, [center]);

  const madeInitialFetch = useRef(false);
  useEffect(() => {
    if (madeInitialFetch.current === true || !center?.id) return;

    madeInitialFetch.current = true;
    fetchFiles();
  }, [center, fetchFiles]);

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const selectedFolderName = getProviderFileTypeTitleFromCode(selectedFolder);
  const selectedFolderHasFiles = currentFiles && currentFiles.some((f) => f.fileType === selectedFolder);

  const [uploading, setUploading] = useState(false);

  const tableData = useMemo(() => {
    if (!selectedFolder) return [];

    return currentFiles.filter((f) => f.fileType === selectedFolder);
  }, [currentFiles, selectedFolder]);

  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileDeletionLoading, setFileDeletionLoading] = useState(false);
  const handleDeleteFile = async (fileId) => {
    setFileDeletionLoading(true);

    providerService
      .deleteCareFacilityFile(center.id, fileId)
      .then(() => {
        message.success('File deleted');

        setSelectedDocument(null);
        fetchFiles();
      })
      .catch((error) => {
        console.log('Failed to delete file', error);

        message.error('Failed to delete file');
      })
      .finally(() => {
        setFileToDelete(null);
        setFileDeletionLoading(false);
      });
  };

  return (
    <Card noBodyPadding>
      <div className="flex">
        <div className="border-solid border-r border-gray-200 py-6 pt-[12px] shrink-0 !w-[290px] max-w-[30%]">
          <Spin spinning={currentFiles === null || uploading}>
            <DocumentsTree
              files={currentFiles}
              selectedDocument={selectedDocument}
              setSelectedDocument={setSelectedDocument}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />
          </Spin>
        </div>

        <div className="flex-grow">
          {selectedDocument ? (
            <Card
              bordered={false}
              className="!shadow-none"
              title={
                <div className="flex items-center gap-2">
                  <Button
                    type="text"
                    icon={<ArrowLeft />}
                    onClick={() => {
                      setSelectedDocument(null);
                      setSelectedFolder(selectedDocument.fileType);
                    }}
                  />

                  <span>{selectedDocument.name}</span>

                  <Spacer />

                  {!readonly && (
                    <Tooltip title="Delete">
                      <Button
                        onClick={() => {
                          setFileToDelete(selectedDocument);
                        }}
                        icon={<TrashCan size={16} />}
                        danger
                        size="small"
                      />
                    </Tooltip>
                  )}
                </div>
              }
            >
              <DocumentPreview document={selectedDocument} />
            </Card>
          ) : selectedFolder ? (
            <Card bordered={false} className="!shadow-none" title={selectedFolderName}>
              <div className="flex flex-col justify-center">
                {!selectedFolderHasFiles ? (
                  <EmptyState
                    description={`No files inside "${
                      getProviderFileTypeTitleFromCode(selectedFolder) || selectedFolder
                    }"`}
                  />
                ) : (
                  <div className="flex-grow">
                    <Table
                      dataSource={tableData}
                      columns={[
                        {
                          title: 'Name',
                          dataIndex: 'name',
                          key: 'name',
                          render: (_text, record) => {
                            const _extension = _text.includes('.') ? _text.split('.').pop() : '';
                            const fileName = _text.replace(`.${_extension}`, '');
                            const truncatedTitle =
                              fileName.length > 20 ? `${fileName.slice(0, 20)}${_extension ? '..' : '...'}` : fileName;

                            let icon;
                            if (record.name.includes('.pdf')) {
                              icon = <DocumentPdf size={20} color="#000" className="align-middle mr-[-3px]" />;
                            } else if (record.name.includes('.doc') || record.name.includes('.docx')) {
                              icon = <Document size={20} color="#000" className="align-middle mt-[-1px]" />;
                            } else if (record.name.includes('.jpg') || record.name.includes('.png')) {
                              icon = <Image size={20} color="#000" className="align-middle mt-[-1px]" />;
                            } else if (record.name.includes('.xls')) {
                              icon = <TableAlias size={20} color="#000" className="align-middle mt-[-1px]" />;
                            }

                            return (
                              <Tooltip
                                title={
                                  truncatedTitle !== fileName
                                    ? `${fileName}${_extension ? `.${_extension}` : ''}`
                                    : undefined
                                }
                              >
                                <div className="flex items-center gap-2">
                                  <Avatar src={icon} className="border border-gray-300 bg-gray-100" />
                                  {truncatedTitle}
                                  {_extension ? `.${_extension}` : ''}
                                </div>
                              </Tooltip>
                            );
                          },
                        },
                        {
                          title: 'Created date',
                          dataIndex: 'createdDate',
                          key: 'createdDate',
                          render: (_text) => {
                            return dayjs(_text).format('MMM DD, YYYY');
                          },
                        },
                        {
                          title: 'Created by',
                          dataIndex: 'createdBy',
                          key: 'createdBy',
                        },
                        {
                          title: 'Actions',
                          dataIndex: 'actions',
                          key: 'actions',
                          render: (_text, record) => {
                            if (!readonly) {
                              return (
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e?.preventDefault?.();
                                    e?.stopPropagation?.();

                                    setFileToDelete(record);
                                  }}
                                  className="underline"
                                >
                                  Remove
                                </a>
                              );
                            }

                            return null;
                          },
                        },
                      ]}
                      pagination={{
                        hideOnSinglePage: true,
                      }}
                      rowClassName="cursor-pointer"
                      onRow={(record) => {
                        return {
                          onClick: () => {
                            setSelectedFolder(null);
                            setSelectedDocument(record);
                          },
                        };
                      }}
                      scroll={{ x: 'max-content' }}
                    />
                  </div>
                )}

                {readonly !== true && (
                  <Card bordered={false} className="!shadow-none mt-12" title="Upload new document">
                    {selectedFolder && (
                      <div className="p-6">
                        <ProviderDocumentFolderInstructions folder={selectedFolder} />
                      </div>
                    )}

                    <Dragger
                      accept=".pdf, .jpg, .jpeg, .png"
                      className={classNames('block w-[calc(100%-3rem)] mx-auto', {
                        'mb-10': !selectedFolderHasFiles,
                        'mb-4': selectedFolderHasFiles,
                      })}
                      action={null}
                      fileList={null}
                      beforeUpload={(file) => {
                        if (checkIfFileTypeIsSupported(file) === false) {
                          handleUnsupportedFileType(file);
                          return false;
                        }

                        if (checkIfFileSizeIsSupported(file) === false) {
                          handleUnsupportedFileSize(file);
                          return false;
                        }

                        return true;
                      }}
                      onDrop={(e) => {
                        const files = Array.from(e.dataTransfer.files);

                        if (files.length !== 0) {
                          if (checkIfFileTypeIsSupported(files[0]) === false) {
                            handleUnsupportedFileType(files[0]);
                          }
                        }
                      }}
                      customRequest={async ({ file }) => {
                        setUploading(true);

                        const _uploadedFile = await providerService
                          .uploadCareFacilityFile(center.id, file, selectedFolder)
                          .catch((error) => {
                            console.log('Failed to upload file', error);

                            message.error('Failed to upload file');

                            setUploading(false);
                          });

                        if (_uploadedFile) {
                          message.success({
                            content: (
                              <p className="inline-flex gap-1">
                                <span>File uploaded, and application is saved.</span>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e?.preventDefault?.();
                                    setSelectedDocument(_uploadedFile);
                                  }}
                                >
                                  View file
                                </a>
                              </p>
                            ),
                          });

                          fetchFiles();
                        }
                      }}
                      aria-label="Upload file"
                    >
                      <div className="px-8">
                        <div
                          className={
                            uploading ? 'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' : 'hidden'
                          }
                        >
                          <Spin tip="Uploading..." />
                          <p className="mt-4">Uploading...</p>
                        </div>

                        <div className={uploading ? 'opacity-0' : undefined}>
                          <CloudUpload size={22} />

                          <p className="text-sm font-semibold">Click or drag files to upload</p>
                          <p className="text-12">Supported formats: jpg, png, pdf</p>
                        </div>
                      </div>
                    </Dragger>
                  </Card>
                )}
              </div>
            </Card>
          ) : null}
        </div>
      </div>

      {!!fileToDelete && (
        <Modal
          title={
            <Space>
              <TrashCan className="mt-[-1px]" />
              Are you sure?
            </Space>
          }
          visible
          setVisible={setFileToDelete}
        >
          <b className="font-semibold">{fileToDelete.name}</b> will be deleted. This cannot be undone.
          <Divider />
          <Space>
            <Button
              onClick={() => {
                setFileToDelete(null);
              }}
            >
              Cancel
            </Button>
            <Spacer />
            <Button
              type="primary"
              onClick={() => handleDeleteFile(fileToDelete.id)}
              loading={fileDeletionLoading}
              danger
            >
              Delete
            </Button>
          </Space>
        </Modal>
      )}
    </Card>
  );
}
