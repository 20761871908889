import React, { useMemo } from 'react';
import { Button, List } from 'antd';
import dayjs from 'dayjs';
import Modal from 'components/Modal';
import styles from './ScheduleEmailModal.module.less';

const ScheduleEmailModal = ({ getContainer, setVisible, visible, schedulingEmail, onScheduleEmail }) => {
  const timeFormat = 'MMM D, YYYY';
  const today = useMemo(() => dayjs(), []);

  const data = useMemo(
    () => [
      { name: '0 Day Checklist Reminder ', date: today.format(timeFormat), id: 0 },
      { name: '15 Day Checklist Reminder', date: today.add(15, 'day').format(timeFormat), id: 1 },
      { name: '30 Day Checklist Reminder', date: today.add(30, 'day').format(timeFormat), id: 2 },
      { name: '60 Day Checklist Reminder', date: today.add(60, 'day').format(timeFormat), id: 3 },
      {
        name: '90 Day Your checklist time has expired.',
        date: today.add(90, 'day').format(timeFormat),
        id: 4,
      },
    ],
    [today],
  );
  return (
    <Modal
      destroyOnClose
      getContainer={getContainer}
      setVisible={setVisible}
      width={640}
      visible={visible}
      closable
      data-testid="schedule-email-modal"
    >
      <div className={styles.customList} data-testid="schedule-email-container">
        <div className="section-title">Scheduled Checklist Email Confirmation</div>
        <div className="mb-4">
          <span className="text-md font-bold">
            {`You are about to create scheduled emails going out to the providers on regular intervals starting today ${dayjs(
              Date.now(),
            ).format('MMM D, YYYY')}`}
          </span>
        </div>

        <List
          dataSource={data}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <div className="sm:w-full md:w-3/4">
                <div>
                  <span className="text-md font-medium">{item.id + 1}.&ensp;</span>
                  <span className="text-md font-medium">{item.name}</span>
                </div>
                <span className="text-md font-medium">-&ensp;{item.date}</span>
              </div>
            </List.Item>
          )}
        ></List>
        <div className="mb-8 mt-2">
          <span className="text-md font-bold">Do you want to create this scheduled email timeline.</span>
        </div>
        <div className="flex flex-col-reverse space-y-reverse space-y-3 md:flex-row md:justify-end md:space-x-3 md:space-y-0">
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button
            data-testid="schedule-request-button"
            form="checklist-form"
            onClick={onScheduleEmail}
            loading={schedulingEmail}
            disabled={schedulingEmail}
          >
            Create Scheduled Emails
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleEmailModal;
