import { useSelector } from 'react-redux';
import { hasPermission } from 'utils';
import { selectors } from 'features/auth';

export default function CheckPermissions({ requiredRoles, hideFor, children }) {
  const userActiveRole = useSelector(selectors.selectActiveRole);
  if (hasPermission([userActiveRole], requiredRoles)) {
    return children;
  }
  if (!requiredRoles && hideFor && !hasPermission([userActiveRole], hideFor)) {
    return children;
  }
  return null;
}
