import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ImCheckboxChecked } from 'react-icons/im';
import { MdOutlineCancelPresentation } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';
import { AiFillCaretDown } from 'react-icons/ai';
import { useLocation, useHistory } from 'react-router-dom';
import { getFullName } from 'utils';

import PartnerAgencyModal from 'components/Modals/PartnerAgencyModal';
import CardItem from 'components/ContactCardItem';

const checkRoutePattern = new RegExp('/providers/applications/\\d+/review', 'i');

export default function EFMProviderContactCard({ center }) {
  const [showParentAgencyModal, setShowParentAgencyModal] = useState(false);
  const history = useHistory();
  const efmIndividual = useMemo(() => center?.staff?.find((item) => item.efmDesignate), [center?.staff]);
  const efmRate = useMemo(() => center?.ageGroups?.find((item) => item.type?.title === 'SPECIAL_NEEDS'), [center]);
  const hourlyRateProvided = efmRate?.ftHourly || efmRate?.ptHourly;
  const isCompliant = useMemo(() => center?.partnerAgency?.compliant, [center?.partnerAgency?.compliant]);
  const location = useLocation();
  const disableEditMode = useMemo(() => !checkRoutePattern.test(location.pathname), [location.pathname]);
  return (
    <>
      <CardItem
        className={classNames('w-full md:w-1/2 2xl:w-1/5')}
        label="EFM individual:"
        value={efmIndividual ? getFullName(efmIndividual?.person) : 'Not Designated'}
        icon={<FaUserCircle size={32} color="#2E5BFF" />}
        disabled={disableEditMode}
        onClick={() => history.push(`staff`)}
      />
      <CardItem
        className={classNames('w-full md:w-1/2 2xl:w-1/5')}
        label="Individual BGC"
        value={efmIndividual?.backgroundCheckStatus?.title || 'Incomplete'}
        testId="accreditation-popup-trigger"
        disabled={disableEditMode}
      />
      <CardItem
        className={classNames('w-full md:w-1/2 2xl:w-1/5')}
        label="Partner Agency:"
        value={
          <span className="w-11/12 text text-base font-semibold truncate">{center?.partnerAgency?.name || 'N/A'}</span>
        }
        icon={<AiFillCaretDown size={32} color="#2E5BFF" />}
        onClick={() => setShowParentAgencyModal(true)}
        disabled={disableEditMode}
      />
      <CardItem
        className={classNames('w-full md:w-1/2 2xl:w-1/5')}
        label={<span className="label-text">PA Inspection:</span>}
        value={isCompliant ? 'Compliant' : 'Non-Compliant'}
        icon={
          isCompliant ? (
            <ImCheckboxChecked color="green" size={16} />
          ) : (
            <MdOutlineCancelPresentation color="red" size={16} />
          )
        }
        testId="compliance-popup-trigger"
        disabled={disableEditMode}
      />
      <CardItem
        className={classNames('w-full 2xl:w-1/5')}
        label="EFM Hourly rate"
        value={hourlyRateProvided ? `$${hourlyRateProvided}` : 'Not Provided'}
        testId="compliance-popup-trigger"
        disabled={disableEditMode}
      />
      <PartnerAgencyModal visible={showParentAgencyModal} setVisible={setShowParentAgencyModal} />
    </>
  );
}

EFMProviderContactCard.propTypes = {
  center: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
};
EFMProviderContactCard.defaultProps = {
  center: {},
  className: '',
  loading: false,
};
