import React from 'react';
import { Button, Input, Skeleton, InputNumber, message } from 'antd';
import { useAsyncFn } from 'react-use';
import classNames from 'classnames';

import Form from 'components/Form';
import alertsService from 'services/alerts.service';

export default function AddAlertNote({ alertId, setVisible, className, reload, data, loading: dataFetching }) {
  const [form] = Form.useForm();
  const [{ loading: addingAlertNote }, createAlertNote] = useAsyncFn(
    async (values) => {
      try {
        const resp = await alertsService.createAlertNote(alertId, {
          ...values,
        });
        setVisible(false);
        /* istanbul ignore else */
        if (typeof reload === 'function') {
          reload(resp);
        }
        message.success('Created a note successfully.', 5);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to create a note.', 5);
      }
    },
    [alertId],
  );
  const [{ loading: updatingAlertNote }, updateAlertNote] = useAsyncFn(
    async (values) => {
      try {
        const resp = await alertsService.updateAlertNote(alertId, data.id, {
          ...values,
          id: data.id,
        });
        setVisible(false);
        /* istanbul ignore else */
        if (typeof reload === 'function') {
          reload(resp);
        }
        message.success('Updated note successfully.', 5);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to update this note.', 5);
      }
    },
    [alertId],
  );

  return (
    <Skeleton loading={dataFetching} active paragraph={{ rows: 8 }}>
      <Form
        layout="vertical"
        onFinish={data?.id ? updateAlertNote : createAlertNote}
        form={form}
        initialValues={data}
        className={classNames('w-full', className)}
      >
        <h3 className="text-primary text-lg font-semibold uppercase -mt-3">
          {data?.id ? 'Edit Alert Notes' : 'Add Alert Notes'}
        </h3>
        <div className="border-t border-gray-300 mt-4 mb-4 w-full" />
        <Form.Item name="id" hidden>
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="content"
          label={data?.id ? 'Edit Alert Notes' : 'Add Alert Notes'}
          rules={[{ required: true, message: 'Note is required.' }]}
        >
          <Input.TextArea autoSize={{ minRows: 6 }} placeholder="Write Here" autoComplete="off" />
        </Form.Item>
        <div className="actions flex">
          <Button
            data-testid="cancel-button"
            onClick={() => {
              setVisible(false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="submit-button"
            type="primary"
            loading={addingAlertNote || updatingAlertNote}
            disabled={addingAlertNote || updatingAlertNote}
            htmlType="submit"
          >
            {data?.id ? 'Update' : 'Create'} Note
          </Button>
        </div>
      </Form>
    </Skeleton>
  );
}
