import React, { useCallback } from 'react';
import { InputNumber } from 'antd';
import styles from './AgeSelectInputNumber.module.less';
import { uniqueId } from 'lodash';

export default function AgeSelectInputNumber(props) {
  const { value, onChange, id: _id, help } = props;
  const [id] = React.useState(_id || uniqueId('age-select-input-number-'));

  const { years = 0, months = 0, weeks = 0 } = value || {};

  const onYearChange = useCallback(
    (val) => {
      val = val || 0;
      onChange({ ...value, years: val });
    },
    [onChange, value],
  );
  const onMonthChange = useCallback(
    (val) => {
      val = val || 0;
      onChange({ ...value, months: val });
    },
    [onChange, value],
  );
  const onWeekChange = useCallback(
    (val) => {
      val = val || 0;
      onChange({ ...value, weeks: val });
    },
    [onChange, value],
  );

  return (
    <div>
      <div className="flex justify-around pt-1 pb-2 bg-black bg-opacity-15 px-2 gap-4 mb-[-7px] text-xs rounded-md">
        <span
          onClick={() => {
            const _input = document.getElementById(`${id}-years`);

            if (_input && _input.select) {
              _input.select();
            }
          }}
          className="w-1/3 cursor-pointer"
        >
          Years
        </span>

        <span
          onClick={() => {
            const _input = document.getElementById(`${id}-months`);

            if (_input && _input.select) {
              _input.select();
            }
          }}
          className="w-1/3 cursor-pointer"
        >
          Months
        </span>

        <span
          onClick={() => {
            const _input = document.getElementById(`${id}-weeks`);

            if (_input && _input.select) {
              _input.select();
            }
          }}
          className="w-1/3 cursor-pointer"
        >
          Weeks
        </span>
      </div>

      <div className={styles.ageInput}>
        <InputNumber
          placeholder="years"
          min={0}
          value={years}
          controls={false}
          onChange={onYearChange}
          id={`${id}-years`}
          style={{
            backgroundColor: '#fff',
          }}
        />

        <InputNumber
          placeholder="months"
          min={0}
          max={11}
          value={months}
          controls={false}
          onChange={onMonthChange}
          id={`${id}-months`}
          style={{
            backgroundColor: '#fff',
          }}
        />

        <InputNumber
          placeholder="weeks"
          min={0}
          max={3}
          value={Math.ceil(weeks)}
          controls={false}
          onChange={onWeekChange}
          id={`${id}-weeks`}
          style={{
            backgroundColor: '#fff',
          }}
        />
      </div>
      {help}
    </div>
  );
}
