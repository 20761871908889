import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import styles from './NavigationTabs.module.less';
import { ArrowUpRight } from '@carbon/icons-react';
import Space from 'components/Space/Space';
import { useSelector } from 'react-redux';
import { AUTH_STATES } from 'constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ROLES } from 'constants';
import { selectActiveRole } from 'features/auth';

export default function NavigationTabs({ theme, className }) {
  const { pathname } = useLocation();
  const history = useHistory();

  const { authState } = useSelector((state) => state.auth);
  const activeRole = useSelector(selectActiveRole);

  const goToDashboard = useCallback(
    async (role) => {
      if (role === ROLES.PROVIDER) {
        history.push('/provider');
      } else if (role === ROLES.SYSTEM_ADMIN) {
        history.push('/admin');
      } else {
        history.push('/dashboard');
      }
    },
    [history],
  );

  return (
    <nav
      role="navigation"
      className={classNames(styles.navigationTabs, className, { [styles.primary]: theme === 'primary' })}
    >
      <Menu selectedKeys={[pathname]} mode="horizontal" overflowedIndicator={null} className="justify-center">
        <Menu.Item key="/">
          <Link to="/">Home</Link>
        </Menu.Item>

        <Menu.Item key="/about">
          <Link to="/about">About</Link>
        </Menu.Item>

        {authState === AUTH_STATES.LOGGED_IN && (
          <Menu.Item key="/dashboard">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                goToDashboard(activeRole);
              }}
            >
              <Space size={2}>
                <ArrowUpRight /> Dashboard
              </Space>
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </nav>
  );
}

NavigationTabs.propTypes = {
  selected: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
};
NavigationTabs.defaultProps = {
  className: '',
  selected: 'childcare',
  theme: 'secondary',
};
