import { Alert, Button, Checkbox, Col, Divider, Form, Input, message, Row, Tag } from 'antd';
import Card from 'components/Card/Card';
import FormItem from 'components/FormItem';
import PhoneNumber from 'components/PhoneNumber';
import PhoneNumberContainer from 'components/PhoneNumberContainer';
import { AsyncSelect } from 'components/Select';
import useEntityGroup from 'hooks/useEntityGroup';
import { useCallback, useEffect, useState } from 'react';
import { providerService } from 'services';
import { getFormattedPhoneNumber, getPhoneEXTValidator, getPhoneNumberValidator } from 'utils';

export default function PointsOfContact({ center }) {
  const [pointsOfContact, setPointsOfContact] = useState([]);
  useEffect(() => {
    if (center?.id) {
      providerService
        .getAllApplicationPointsOfContact(center?.id)
        .then((res) => {
          setPointsOfContact(res);
        })
        .catch((e) => {
          console.log('Error fetching points of contact', e);

          message.error('Error fetching points of contact');
        });
    }
  }, [center?.id]);

  const getAllColumns = useCallback(() => {
    const allColumns = [
      {
        title: <span className="action-header">Contact type</span>,
        headerText: 'Type',
        dataIndex: ['type', 'title'],
        key: 'type',
        render: (text, record) => {
          return (
            <>
              {record.receiveNotifications && <Tag>Business</Tag>}
              {record.receiveBilling && <Tag>Payments</Tag>}
            </>
          );
        },
      },
      {
        title: <span className="action-header">Name</span>,
        dataIndex: 'displayName',
        key: 'displayName',
        elipsis: true,
      },
      {
        title: <span className="action-header">Position</span>,
        dataIndex: 'position',
        key: 'position',
        elipsis: true,
        render: (text, record) => {
          return text.title;
        },
      },
      {
        title: <span className="action-header">Email</span>,
        dataIndex: 'email',
        key: 'email',
        elipsis: true,
      },
      {
        title: <span className="action-header">Phone number</span>,
        dataIndex: 'phone',
        key: 'phone',
        elipsis: true,
        render: (text, record) => {
          return text ? getFormattedPhoneNumber(text) : '–';
        },
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback(({ values, setVisible, items, form, item, index }) => {
    return (
      <>
        {item.locked && (
          <Alert
            className="mb-6"
            type="info"
            message={<p className="font-semibold">This is a locked entry</p>}
            description="You can only change the email address for this POC."
          />
        )}

        <Form.Item
          name="notifications"
          label="Contact Type"
          dependencies={['receiveNotifications', 'receiveBilling']}
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                const _values = getFieldValue();

                if (_values.receiveBilling || _values.receiveNotifications) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(new Error('Please select at least 1'));
                }
              },
            }),
          ]}
        >
          <FormItem
            name="receiveNotifications"
            valuePropName="checked"
            isSame={(prev, curr) => prev?.id === curr?.id}
            noStyle
          >
            <Checkbox disabled={item.locked === true}>Business</Checkbox>
          </FormItem>

          <FormItem
            name="receiveBilling"
            valuePropName="checked"
            isSame={(prev, curr) => prev?.id === curr?.id}
            noStyle
          >
            <Checkbox disabled={item.locked === true}>Payments</Checkbox>
          </FormItem>
        </Form.Item>

        <Divider className="mt-0" />

        <Row gutter={[20, 0]} className="form-row">
          <Col xs={24} md={12} xl={8}>
            <FormItem
              name="position"
              label="Position/relation"
              rules={[{ required: true, message: 'This is required' }]}
              isSame={(prev, curr) => prev?.id === curr?.id}
            >
              <AsyncSelect optionsApiUrl="/options/39" name="position" disabled={item.locked === true} />
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem name="firstName" label="First name" rules={[{ required: true, message: 'This is required' }]}>
              <Input disabled={item.locked === true} />
            </FormItem>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <FormItem name="lastName" label="Last name" rules={[{ required: true, message: 'This is required' }]}>
              <Input disabled={item.locked === true} />
            </FormItem>
          </Col>
        </Row>

        <Divider className="mt-0" />

        <Row gutter={[20, 0]} className="form-row">
          <Col xs={24} sm={12} md={8}>
            <FormItem
              name="email"
              label="Email address"
              rules={[
                { required: true, message: 'This is required' },
                {
                  type: 'email',
                  message: 'Invalid email address',
                },
              ]}
            >
              <Input />
            </FormItem>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <PhoneNumberContainer
              phoneNumber={
                <FormItem name="phone" label="Phone number" rules={[getPhoneNumberValidator('Invalid Phone Number')]}>
                  <PhoneNumber disabled={item.locked === true} />
                </FormItem>
              }
              extension={
                <FormItem name="phoneExt" label="Extension" rules={[getPhoneEXTValidator('Invalid Extension')]}>
                  <Input placeholder="Extension" disabled={item.locked === true} />
                </FormItem>
              }
            />
          </Col>
        </Row>

        <div className="actions flex mt-5">
          <Button onClick={() => setVisible(false)} data-testid="cancel-ageGroup-btn">
            Cancel
          </Button>

          <Button type="primary" htmlType="submit" data-testid="save-ageGroup-btn">
            Save
          </Button>
        </div>
      </>
    );
  }, []);

  const hasGeneralContact = pointsOfContact.some((poc) => poc.receiveNotifications);
  const hasPaymentContact = pointsOfContact.some((poc) => poc.receiveBilling);

  const { section } = useEntityGroup({
    items: pointsOfContact,
    onChange: () => null,
    onAddItem: async (_values) => {
      const values = { ..._values };

      const isEditing = values.id !== undefined && values.id !== null;

      if (isEditing) {
        const _updatedPOC = await providerService.updateApplicationPointOfContact(center?.id, values);
        setPointsOfContact(pointsOfContact.map((poc) => (poc.id === _updatedPOC.id ? _updatedPOC : poc)));

        return _updatedPOC;
      } else {
        values.middleName = values.middleName || '';

        const _newPOC = await providerService.addApplicationPointOfContact(center?.id, values);
        setPointsOfContact([...pointsOfContact, _newPOC]);

        return _newPOC;
      }
    },
    onDeleteItem: async (item) => {
      await providerService.deleteApplicationPointOfContact(center?.id, item.id);

      setPointsOfContact(pointsOfContact.filter((poc) => poc.id !== item.id));
    },
    disableDelete: (item) => item.locked,
    deleteTooltipText: (item) => (item.locked ? 'This is a locked field' : undefined),
    getAllColumns,
    getFormFields,
    entityTitle: 'point of contact',
    tableProps: {
      rowKey: (record) => {
        return `${record.id}-${record.uid}`;
      },
    },
    rules:
      !hasGeneralContact || !hasPaymentContact
        ? [
            {
              required: true,
              message:
                !hasGeneralContact && !hasPaymentContact
                  ? 'You must add both a general contact and a payment contact'
                  : !hasGeneralContact
                  ? 'You must add a general contact'
                  : 'You must add a payment contact',
            },
          ]
        : undefined,
    entityName: 'pointOfContact',
  });

  return (
    <Card collapsible title="Points of Contact" className="mb-4">
      {section}
    </Card>
  );
}
