import React from 'react';
import { ClearIcon } from 'utils/icons';

export default function ClearableWrapper({ clearIconClassName, children, value, onChange, emptyValue, ...props }) {
  return (
    <div className="relative">
      {React.cloneElement(children, {
        ...props,
        value,
        onChange,
      })}

      {value && (
        <ClearIcon
          onClick={() => onChange(emptyValue)}
          className={`text-gray-400 w-5 h-5 absolute top-1.5 right-2 cursor-pointer ${clearIconClassName}`}
        />
      )}
    </div>
  );
}

ClearableWrapper.defaultProps = {
  emptyValue: null,
  clearIconClassName: '',
};
