import React, { useMemo, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { message, Space, Spin, Tag } from 'antd';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';
import { Popover, Transition } from '@headlessui/react';
import { useAsync } from 'react-async-hook';

import { formatDate, formatDateTimeToLocal, getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';
import Select from 'components/Select';
import Section from 'components/Section';
import FamilyProgramAndEligibilityModal from 'components/Modals/FamilyProgramAndEligibilityModal';
import ApplicationDateRangeModal from 'components/Modals/ApplicationDateRangeModal';
import commonService from 'services/common.service';
import { selectCommonStyles } from 'constants/index';
import useBranch from 'hooks/useBranch';
import useProgram from 'hooks/useProgram';
import { List } from 'components/DetailsDrawer/DetailsDrawer';

const checkRoutePattern = new RegExp('/families/\\d+/applications/\\d+/review', 'i');

export default function FamilyCoordinatorContactCard({ householdId, application = {}, loading, className }) {
  const [isVisibleDateRangeModal, setIsVisibleDateRangeModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState({});
  const location = useLocation();
  const [visibleFamilyProgramModal, setVisibleFamilyProgramModal] = useState(false);
  const disableEditMode = useMemo(() => !checkRoutePattern.test(location.pathname), [location.pathname]);
  const SECOND_PARENT_OPTIONS = useMemo(() => application?.additionalParents || [], [application?.additionalParents]);
  const programSponsorId = useMemo(
    () => application?.applicant?.position?.programSponsorId,
    [application?.applicant?.position?.programSponsorId],
  );
  const militaryComponentId = useMemo(
    () => application?.applicant?.position?.militaryComponentId,
    [application?.applicant?.position?.militaryComponentId],
  );
  const militaryStatusId = useMemo(
    () => application?.applicant?.position?.militaryStatusId,
    [application?.applicant?.position?.militaryStatusId],
  );

  const { result: sponsorMilitaryStatuses = [] } = useAsync(
    async (programSponsorId, militaryComponentId) => {
      try {
        if (programSponsorId && militaryComponentId) {
          return await commonService.get(
            `common/program-types/family/statuses/by-component-branch?programSponsorId=${programSponsorId}&componentId=${militaryComponentId}`,
            { cache: { interpretHeader: false } },
          );
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Sponsor military statuses not found.', 5);
        return [];
      }
    },
    [programSponsorId, militaryComponentId],
  );
  const { result: militaryComponentOptions = [] } = useAsync(
    async (programSponsorId) => {
      try {
        if (programSponsorId) {
          return await commonService.get(`common/program-types/family/components?branchId=${programSponsorId}`, {
            cache: { interpretHeader: false },
          });
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Components not found.', 5);
        return [];
      }
    },
    [programSponsorId],
  );

  const { branch, loading: branchLoading } = useBranch({ id: programSponsorId });
  const { program, loading: programsLoading } = useProgram({ id: application?.program?.id });

  const selectedMilitaryStatus = useMemo(
    () => sponsorMilitaryStatuses.find((item) => item.id === militaryStatusId),
    [sponsorMilitaryStatuses, militaryStatusId],
  );

  const selectedMilitaryComponent = useMemo(
    () => militaryComponentOptions.find((item) => item.id === militaryComponentId),
    [militaryComponentId, militaryComponentOptions],
  );

  useEffect(() => {
    if (!loading) setSelectedParent(application?.additionalParents?.[0]);
  }, [application?.additionalParents, application.id, loading]);
  // if (location.pathname.includes('review'))
  //   return <FamilyInfoCard householdId={householdId} application={application} loading={loading} />;
  return (
    <Section
      heading="Family Information/Contact Card"
      headingClassName="text-lg font-medium uppercase"
      headerContainerClassName="py-4 pl-[19px]"
      contentClassName=""
      className="!border-b-0"
      extra={
        <div className="absolute right-16 top-[22px]">
          <span className="text-gray-800 uppercase">
            Last Modified:{' '}
            <span className="text-gray-800 uppercase font-medium text-sm">
              {formatDateTimeToLocal(application?.lastModifiedDate)}
            </span>
          </span>
        </div>
      }
    >
      <div className="bg-white">
        <div className={classNames('contact-card  px-3 md:px-5 pt-6 pb-10', className)} id="application-contact-card">
          <div className="flex gap-4 lg:gap-32 flex-col lg:flex-row mb-12 lg:mb-0 mt-2">
            <List
              className="lg:w-1/3 lg:max-w-[270px]"
              items={[
                {
                  title: 'Application',
                  items: [
                    {
                      label: 'App ID',
                      value: application.id || 'N/A',
                    },
                    {
                      label: 'Program',
                      value: programsLoading ? (
                        <Spin spinning={programsLoading} size="small" />
                      ) : (
                        program?.type?.title || 'N/A'
                      ),
                    },
                    {
                      label: 'Period',
                      value:
                        application?.startDate && application?.endDate
                          ? `${application?.startDate} - ${application?.endDate}`
                          : 'N/A',
                      disabled: disableEditMode,
                    },
                    {
                      label: 'Currently with',
                      value: application?.currentlyWith ? getFullName(application.currentlyWith) : 'N/A',
                    },
                    {
                      label: 'Submission Date',
                      value: formatDate(application?.submittedDate),
                    },
                    {
                      label: 'Status',
                      value: <Tag className="m-0">{application.status?.status?.title || 'N/A'}</Tag>,
                    },
                  ],
                },
              ]}
            />

            <List
              className="lg:w-1/3 lg:max-w-[270px]"
              items={[
                {
                  title: 'Sponsor',
                  items: [
                    {
                      label: 'Name',
                      value:
                        getFullName(application?.applicant).length > 1 ? getFullName(application?.applicant) : 'N/A',
                    },
                    {
                      label: 'Branch',
                      value: branchLoading ? <Spin spinning={branchLoading} size="small" /> : branch?.title || 'N/A',
                    },
                    {
                      label: 'Component',
                      value: selectedMilitaryComponent?.title || 'N/A',
                    },
                    {
                      label: 'Status',
                      value: selectedMilitaryStatus?.title || 'N/A',
                    },
                    {
                      label: 'Phone',
                      value: (
                        <Space>
                          {getFormattedPhoneNumber(application?.applicant?.phone) || 'N/A'}

                          {application?.applicant?.secondaryPhone && (
                            <PopOver
                              items={[
                                { label: 'Phone', value: application?.applicant?.phone },
                                { label: 'Secondary Phone', value: application?.applicant?.secondaryPhone },
                              ]}
                            />
                          )}
                        </Space>
                      ),
                    },
                    {
                      label: 'Email',
                      value: (
                        <Space>
                          <div>{application?.applicant?.email || 'N/A'}</div>

                          {application?.applicant?.workEmail && (
                            <PopOver
                              items={[
                                { label: 'Email', value: application?.applicant?.email },
                                { label: 'Work Email', value: application?.applicant?.workEmail },
                              ]}
                            />
                          )}
                        </Space>
                      ),
                    },
                    {
                      label: 'Address',
                      value: getCompletedAddress(application?.applicant?.homeAddress) || 'N/A',
                    },
                  ],
                },
              ]}
            />

            <div className="lg:w-1/3 lg:max-w-[270px]">
              <List
                items={[
                  {
                    title: 'Family',
                    items: [
                      {
                        label: 'ID',
                        value: application.householdId || 'N/A',
                      },
                      {
                        label: 'Specialist',
                        value: application?.assignedTo ? getFullName(application.assignedTo) : 'N/A',
                      },
                      {
                        label: 'Employment Eligibility',
                        value: selectedParent?.employmentStatus || 'N/A',
                      },
                      {
                        label: 'School Eligibility',
                        value: selectedParent?.inSchool ? 'Yes' : 'No' || 'N/A',
                      },
                    ],
                  },
                ]}
              />

              <List
                className="mt-8"
                items={[
                  {
                    title: 'Second Parent',
                    items: [
                      {
                        label: 'Name',
                        value: (
                          <div className="w-full">
                            <Select
                              defaultValue={selectedParent}
                              value={selectedParent?.id}
                              options={SECOND_PARENT_OPTIONS}
                              name="additionalParents"
                              hideSeparator
                              isClearable={false}
                              getOptionLabel={(opt) => getFullName(opt)}
                              isOptionSelected={(option, [value]) => option.id === value}
                              styles={selectCommonStyles}
                              onChange={setSelectedParent}
                            />
                          </div>
                        ),
                      },
                      {
                        label: 'Phone',
                        value: (
                          <Space>
                            {getFormattedPhoneNumber(selectedParent?.phone) || 'N/A'}

                            <PopOver
                              items={[
                                { label: 'Phone', value: selectedParent?.phone },
                                { label: 'Secondary Phone', value: selectedParent?.secondaryPhone },
                              ]}
                            />
                          </Space>
                        ),
                      },
                      {
                        label: 'Email',
                        value: (
                          <Space>
                            {selectedParent?.email || 'N/A'}

                            <PopOver
                              panelClassName="-left-32 -right-10"
                              items={[
                                { label: 'Email', value: selectedParent?.email },
                                { label: 'Work Email', value: selectedParent?.workEmail },
                              ]}
                            />
                          </Space>
                        ),
                      },
                      {
                        label: 'Address',
                        value: getCompletedAddress(selectedParent?.homeAddress) || 'N/A',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <FamilyProgramAndEligibilityModal
        visible={visibleFamilyProgramModal}
        setVisible={setVisibleFamilyProgramModal}
        application={application}
      />
      <ApplicationDateRangeModal
        visible={isVisibleDateRangeModal}
        setVisible={(value) => setIsVisibleDateRangeModal(value)}
        startDate={application?.startDate}
        endDate={application?.endDate}
        startDateKey={['startDate']}
        endDateKey={['endDate']}
        entity={application}
        entityType="FamilyApplication"
      />
    </Section>
  );
}

FamilyCoordinatorContactCard.defaultProps = {
  application: {},
  className: '',
  loading: false,
  breakpoints: { xs: false, screen: 'xxl', sm: true, md: true, lg: true, xl: true, xxl: true },
};

const PopOver = ({ panelClassName, items }) => {
  return (
    <div className="w-full max-w-sm">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button size="small" className="icon-btn">
              {open ? <AiFillCaretUp /> : <AiFillCaretDown />}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={classNames(
                  'absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0 sm:max-w-20',
                  panelClassName,
                )}
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative bg-white py-4 overflow-x-scroll">
                    {items?.map?.(({ label, value }) => (
                      <div className="ml-2 mb-2 flex" key={label}>
                        <span className="text-sm font-medium px-2 text-gray-900 block">
                          {label}: <span className="ml-2">{value}</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

FamilyCoordinatorContactCard.propTypes = {
  application: PropTypes.object,
  className: PropTypes.any,
  loading: PropTypes.any,
  breakpoints: PropTypes.object,
};

FamilyCoordinatorContactCard.defaultProps = {
  application: {},
  className: '',
  loading: false,
  breakpoints: { xs: false, screen: 'xxl', sm: true, md: true, lg: true, xl: true, xxl: true },
};
