import { useMemo } from 'react';
import dayjs from 'dayjs';
import Form from 'components/Form';
import FormItem from 'components/FormItem';
import { getDisabledDateValidator, isSameDate } from 'utils';
import AriaDatePicker from 'components/AriaDatePicker';

export default function useStartEndDates({
  form,
  startDateFieldProps = {},
  endDateFieldProps = {},
  startDatePickerProps = {},
  endDatePickerProps = {},
  hideChangeRequestHighlights = false,
}) {
  const startDateRule = useMemo(() => [{ required: true, message: 'Start Date is required' }], []);
  const endDateRule = useMemo(() => [{ required: true, message: 'End Date is required' }], []);
  const startDateRules = useMemo(
    () => startDateFieldProps.rules || startDateRule,
    [startDateFieldProps.rules, startDateRule],
  );
  const endDateRules = useMemo(() => endDateFieldProps.rules || endDateRule, [endDateFieldProps.rules, endDateRule]);

  const startDate = Form.useWatch(startDateFieldProps.name || 'startDate', form);
  const endDate = Form.useWatch(endDateFieldProps.name || 'endDate', form);

  return [
    <FormItem
      name="startDate"
      label="Start Date"
      isSame={isSameDate}
      {...startDateFieldProps}
      rules={[
        ...startDateRules,
        getDisabledDateValidator((date) => {
          if (!endDate) {
            return false;
          }
          const end = dayjs(endDate);
          return date && dayjs(date).startOf('day')?.isSameOrAfter(end.startOf('day'));
        }, 'Start Date should be before End Date.'),
      ]}
      hideChangeRequestHighlights={hideChangeRequestHighlights}
      dependencies={[endDateFieldProps.name, ...(startDateFieldProps.dependencies || [])]}
    >
      <AriaDatePicker placeholder="Start date" {...startDatePickerProps} />
    </FormItem>,
    <FormItem
      name="endDate"
      label="End Date"
      isSame={isSameDate}
      {...endDateFieldProps}
      rules={[
        ...endDateRules,
        getDisabledDateValidator((date) => {
          if (!startDate) {
            return false;
          }
          const start = dayjs(startDate);
          return date && dayjs(date).startOf('day').isSameOrBefore(start.startOf('day'));
        }, 'End Date should be after Start Date.'),
      ]}
      hideChangeRequestHighlights={hideChangeRequestHighlights}
      dependencies={[startDateFieldProps.name, ...(endDateFieldProps.dependencies || [])]}
    >
      <AriaDatePicker placeholder="End date" {...endDatePickerProps} />
    </FormItem>,
  ];
}
