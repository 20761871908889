import { Drawer } from 'antd';

import Button from 'components/Button';
import { CgClose } from 'react-icons/cg';

export const DetailSectionTitle = ({ className, title }) => (
  <p className={`block text-sm font-bold text-gray-500 opacity-80 uppercase ${className}`}>{title}</p>
);
export const DetailLineItem = ({ title, value }) => (
  <div className="flex items-start gap-5">
    <div className="flex-grow font-medium">{title}</div>

    <div className="text-gray-700 text-right">{value}</div>
  </div>
);

export function List({ items, className, style }) {
  return items.map((item, index) => (
    <div key={index} className={className} style={style}>
      <DetailSectionTitle title={item.title} className="mb-4" />

      <div className="flex flex-col gap-2">
        {item.items?.map((_item, _index) => (
          <DetailLineItem key={_index} title={_item.label} value={_item.value} className="!items-start" />
        ))}
      </div>
    </div>
  ));
}

export default function DetailsDrawer({ title, description, items, open, setOpen }) {
  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      headerStyle={{ display: 'none' }}
    >
      <div className="flex items-center m-[-24px] pt-[24px] px-[24px] pb-6 border-b mb-4">
        <div className="flex-grow">
          <h2 className="font-bold text-[15px]">{title}</h2>
          <h5 className="text-gray-600">{description}</h5>
        </div>

        <div>
          <Button
            size="small"
            icon={<CgClose className="w-[0.85rem] h-[0.85rem]" />}
            onClick={() => {
              setOpen(false);
            }}
            style={{ width: '25px', height: '25px' }}
          />
        </div>
      </div>

      {items?.map((item, index) => (
        <div key={index}>
          <DetailSectionTitle title={item.title} className="mt-6 mb-4" />

          <div className="flex flex-col gap-2">
            {item.items?.map((item, index) => (
              <DetailLineItem key={index} title={item.label} value={item.value} />
            ))}
          </div>
        </div>
      ))}
    </Drawer>
  );
}
