import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Input, Button, message } from 'antd';

import Form from 'components/Form';
import PhoneNumber from 'components/PhoneNumber';
import Modal from 'components/Modal';
import { NAME_REGEX } from 'constants/index';
import { getPhoneNumberValidator } from 'utils';
import { selectUser } from 'features/auth';
import communicationService from 'services/communications.service';

export default function ProviderHelpEmailModal({ visible, setVisible, ...rest }) {
  const [helpLoading, setHelpLoading] = useState(false);

  const userInfo = useSelector(selectUser);
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onCancel={() => setVisible(false)}
      {...rest}
      footer={null}
      width={800}
    >
      <span className="title">Contact Provider Services</span>
      <p className="text text-left md:mr-16 mt-6 text-highlight">
        Call us at +1-800-424-2246 opt. 4 Or you can fill out the form below to leave a message and we will get back to
        you.
      </p>
      <Form
        layout="vertical"
        initialValues={{
          email: userInfo?.email,
          name: `${userInfo?.firstName} ${userInfo?.lastName}`,
          phone: userInfo?.phone,
        }}
        onFinish={async (values) => {
          try {
            setHelpLoading(true);
            await communicationService.sendHelpEmail(values);
            message.success('Your request submitted successfully.', 5);
            setVisible(false);
          } catch (error) {
            message.error('Unable to submit this request.', 5);
            newrelic.noticeError(error);
          } finally {
            setHelpLoading(false);
          }
        }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              label="email"
              rules={[
                // { required: true, message: 'Email is required' },
                { type: 'email', message: 'Email is invalid' },
              ]}
            >
              <Input placeholder="Email Address (abc@gmail.com)" disabled={!!userInfo?.email} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="phone" label="Phone Number" rules={[getPhoneNumberValidator('Invalid Phone Number!')]}>
              <PhoneNumber disabled={!!userInfo?.phone} />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name="name"
              label="Full Name"
              rules={[
                { pattern: NAME_REGEX, message: 'Invalid Full Name' },
                { min: 3, message: 'Full Name is too short' },
                { max: 60, message: 'Full Name is too long' },
              ]}
            >
              <Input
                placeholder="Full Name"
                autoComplete="off"
                disabled={!!(userInfo?.firstName || userInfo?.lastName)}
              />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              name="comments"
              label="Note"
              rules={[
                { required: true, message: 'Note is required' },
                { max: 1000, message: 'Note is too long' },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                placeholder="Leave your Note Here"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="flex flex-col-reverse sm:flex-row sm:justify-end space-x-0 sm:space-x-3 space-y-reverse space-y-2 sm:space-y-0">
          <Button
            className="w-full sm:w-40"
            onClick={() => setVisible(false)}
            disabled={helpLoading}
            data-testid="form-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="w-full sm:w-40"
            htmlType="submit"
            loading={helpLoading}
            disabled={helpLoading}
            data-testid="form-submit-btn"
          >
            Send
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
