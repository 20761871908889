import React, { useEffect, useCallback } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { Amplify, Auth, Hub, Storage } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';

import { store } from 'store';
import { actions } from 'features/auth';

import { actions as activityActions } from 'features/activity';
import { AUTH_STATES, RESET_ALL } from 'constants/index';

const { setAuthState, setUser } = actions;

export default function Authenticator({ amplifyConfig }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { rememberMe } = auth;

  const getUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      dispatch(activityActions.start());
      return user;
    } catch (error) {
      newrelic.noticeError(error);
      return Promise.resolve();
    }
  }, [dispatch]);

  useEffect(() => {
    Amplify.configure(amplifyConfig);
    Storage.configure({
      level: 'private',
    });
    getUser();
    // dispatch(refreshToken());
  }, [amplifyConfig, dispatch, getUser]);
  useEffect(() => {
    message.config({
      top: 100,
      duration: 3,
      rtl: false,
    });
  }, []);

  const onEvent = useCallback(
    async (event) => {
      const { payload /* , source */ } = event;
      switch (payload.event) {
        case 'signIn':
          // const cognitoUser = await Auth.currentUserPoolUser();
          if (!rememberMe) dispatch(activityActions.start());
          break;
        case 'signOut':
          dispatch(setAuthState(AUTH_STATES.SIGN_IN));
          dispatch(setUser({}));
          dispatch(activityActions.stop());
          break;
        case RESET_ALL:
          store.dispatch(actions.logout.pending());
          store.dispatch(actions.resetState());
          break;
        default:
          break;
      }
    },
    [dispatch, rememberMe],
  );
  useEffect(() => {
    Hub.listen('auth', onEvent);
    Hub.listen('redux', onEvent);
    return () => {
      Hub.remove('auth', onEvent);
      Hub.remove('redux', onEvent);
    };
  }, [onEvent]);
  return <div />;
}

Authenticator.propTypes = {
  amplifyConfig: PropTypes.object.isRequired,
};

// const PROVIDER_NAME = 'ArtefactsStorageProvider';

// class GccStorageProvider {
//   // category and provider name
//   static category = 'Storage';
//   static providerName = PROVIDER_NAME;

//   // you need to implement these seven methods
//   // configure your provider
//   configure(config) {}

//   // get object/pre-signed url from storage
//   async get(key, options = {}) {}

//   // upload storage object
//   put(key, object, options = {}) {}

//   // remove object
//   remove(key, options = {}) {}

//   // list objects for the path
//   list(path, options = {}) {}

//   // return 'Storage';
//   getCategory() {
//     return 'Storage';
//   }

//   // return the name of you provider
//   getProviderName() {
//     return PROVIDER_NAME;
//   }
// }

// Storage.addPluggable(new GccStorageProvider());
