import { Empty } from 'antd';
import classNames from 'classnames';

export default function EmptyState(props) {
  return (
    <div className={classNames('py-10', props.wrapperClassName)}>
      <Empty className={props.className} description={props.description} />

      {props.children}
    </div>
  );
}
