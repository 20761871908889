import React, { useCallback, useMemo, useState } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import useEntityGroup from 'hooks/useEntityGroup';
import { AsyncSelect } from 'components/Select';
import Form from 'components/Form';
import Section from 'components/Section';
import { formatDate, getCompletedAddress, getFullName } from 'utils';
import familyService from 'services/family.service';
import { selectActiveRole } from 'features/auth';
import AdultContactInformation from './AdultContactInformation';
import AdultEmploymentInformation from './AdultEmploymentInformation';
import AdultSchoolInfoTable from './AdultSchoolInfoTable';
import AdultFamilyRelationship from './AdultFamilyRelationship';
import AddAdultFamilyMemberWizard from './AddAdultFamilyMemberWizard';
import { ROLES } from 'constants/index';
import AdultMilitaryInfoTable from './AdultMilitaryInfoTable';
import AriaDatePicker from 'components/AriaDatePicker';

export default function AdultsContainer(props) {
  return <Adults {...props} />;
}

function Adults({
  application,
  profileData,
  reload,
  loading,
  hideTable,
  isWrapperWithSection = true,
  getItem,
  currentStep,
  personId,
  item,
}) {
  const [expandedRows, setExpandedRows] = useState([]);
  const activeRole = useSelector(selectActiveRole);
  const isFamilyUser = useMemo(() => activeRole === ROLES.FAMILY, [activeRole]);
  const items = useMemo(() => {
    return Array.from(profileData?.adults || []).sort((a, b) => b.id - a.id);
  }, [profileData]);

  const expandedRowRender = (record, index) => {
    return (
      <div className="flex flex-col border border-t-0 -mt-0.5 [&_.ant-table]:!min-h-[100px] mb-5">
        <AdultContactInformation
          record={record}
          householdId={profileData?.id}
          adultId={record?.id}
          reload={reload}
          value={record}
        />
        <AdultEmploymentInformation
          record={record}
          householdId={profileData?.id}
          adultId={record?.id}
          reload={reload}
          value={record}
        />
        <AdultMilitaryInfoTable
          record={record}
          householdId={profileData?.id}
          adultId={record?.id}
          reload={reload}
          value={record}
        />
        <AdultSchoolInfoTable
          record={record}
          householdId={profileData?.id}
          adultId={record?.id}
          reload={reload}
          value={record}
        />
        <AdultFamilyRelationship
          householdId={profileData?.id}
          personId={record?.id}
          profileData={profileData}
          customAddButton={() => null}
        />
      </div>
    );
  };
  const expandable = {
    expandIcon: () => null,
    rowExpandable: () => true,
    expandedRowKeys: expandedRows,
    expandedRowRender,
  };
  const adultsTable = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">Prefix</span>,
        headerText: 'Prefix',
        dataIndex: 'prefix',
        key: 'prefix',
        ellipsis: true,
        width: 100,
        render: (value) => <span>{value?.title || 'N/A'}</span>,
      },
      {
        title: <span className="action-header">Name</span>,
        headerText: 'Name',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        flex: 'left',
        width: 120,
        render: (name, record) => <span>{getFullName(record)}</span>,
      },
      {
        title: <span className="action-header">Address</span>,
        headerText: 'Address',
        dataIndex: 'homeAddress',
        key: 'address',
        ellipsis: true,
        width: 220,
        render: (address) => getCompletedAddress(address) || 'N/A',
        align: 'left',
        className: 'xs-max',
      },
      {
        title: <span className="action-header">Marital Status</span>,
        headerText: 'Marital Status',
        dataIndex: 'maritalStatus',
        key: 'maritalStatus',
        align: 'left',
        width: 150,
        render: (maritalStatus) => maritalStatus?.title || 'N/A',
      },
      {
        title: <span className="action-header">Date of Birth</span>,
        headerText: 'Date of Birth',
        dataIndex: 'dateOfBirth',
        key: 'dateOfBirth',
        align: 'left',
        width: 150,
        render: (dateOfBirth) => formatDate(dateOfBirth),
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback(
    (props) => {
      return (
        <AddAdultFamilyMemberWizard
          {...props}
          item={item || props.item}
          profileData={profileData}
          reload={reload}
          loading={loading}
          currentStep={currentStep}
          personId={personId}
          application={application}
        />
      );
    },
    [item, profileData, reload, loading, currentStep, personId, application],
  );
  const onAddItem = useCallback(
    async (data) => {
      const payload = data?.id ? { ...data } : [{ ...data }];
      await familyService.addUpdateAdult(profileData.id, data?.id, payload);
      await reload();
      return data;
    },
    [profileData?.id, reload],
  );
  const onDeleteItem = useCallback(
    async (record) => {
      return familyService.deleteAdult(application?.id, record);
    },
    [application?.id],
  );

  const {
    section: adultsTableSection,
    setVisible,
    setItem,
  } = useEntityGroup({
    items,
    onChange: () => {},
    onAddItem,
    onDeleteItem,
    entity: application,
    getAllColumns: adultsTable,
    getFormFields,
    entityTitle: 'Adult',
    tableProps: {
      scroll: { x: 1200 },
      rowKey: (record) => {
        return `${record.id}-${record.uid}`;
      },

      expandedRowClassName: 'px-4 py-2',
      expandable,
    },
    hideTable,
    customAddButton: isFamilyUser ? null : (
      <Button
        type="primary"
        onClick={() => {
          setItem(getItem?.());
          setVisible(true);
        }}
        className="w-48"
      >
        Edit
      </Button>
    ),
    dateFields: [['dateOfBirth']],
    hideDeleteButton: true,
    entityName: 'adult',
    modalWidth: 1400,
    onMount: (_, setItem) => {
      if (profileData?.adults?.length === 0 && profileData?.children?.length === 0 && isFamilyUser) {
        setVisible(true);
      }
    },
    getRowActions: (record) => {
      const isExpanded = expandedRows.indexOf(`${record.id}-${record.uid}`) > -1;
      return (
        <Tooltip placement="top" title={isExpanded ? 'Close' : 'Open'}>
          <Button
            onClick={() => {
              if (expandedRows[0] === `${record.id}-${record.uid}`) {
                setExpandedRows([]);
              } else {
                setExpandedRows([`${record.id}-${record.uid}`]);
              }
            }}
            icon={isExpanded ? <AiFillCaretUp /> : <AiFillCaretDown />}
            className="icon-btn expand-btn"
            aria-label={isExpanded ? 'Close' : 'Open'}
            data-testid={`collapse-button-${record.uid}`}
            title={isExpanded ? 'Close' : 'Open'}
          />
        </Tooltip>
      );
    },
  });

  return (
    <>
      {isWrapperWithSection ? (
        <Section heading="Adult Summary" testId="family-adult-section" className="mt-6">
          {adultsTableSection}
        </Section>
      ) : (
        adultsTableSection
      )}
    </>
  );
}

export const AdultFormFields = ({ values, setVisible, item, addUpdateLoading, form, isFamilyUser }) => {
  return (
    <>
      <h3 className="section-title mb-6">Step 1: Enter Adult's Basic Information</h3>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8">
        <Form.Item
          name={['prefix']}
          label="Select Prefix"
          rules={[{ required: true, message: 'Prefix is required.' }]}
          isSame={(prev, curr) => prev?.id === curr?.id}
        >
          <AsyncSelect
            getOptions={familyService.getOptions}
            apiPrefix=""
            optionsApiUrl="/options/19"
            placeholder="Select Prefix"
            ariaLabel="Select Prefix"
          />
        </Form.Item>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8">
        <div>
          <Form.Item
            name={['firstName']}
            label="First Name"
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={['middleName']} label="Middle Name/Initial">
            <Input placeholder="Middle Name" />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['lastName']}
            label="Last Name"
            rules={[{ required: true, message: 'Last Name is required.' }]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={['suffix']} label="Select Suffix">
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/options/20"
              placeholder="Select Suffix"
              ariaLabel="Select Suffix"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['maritalStatus']}
            label="Marital Status"
            rules={[{ required: true, message: 'Marital Status is required' }]}
          >
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/options/6"
              placeholder="Marital Status"
              ariaLabel="Marital Status"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['dateOfBirth']}
            label="Date of Birth"
            rules={[{ required: true, message: 'Date of birth is required.' }]}
          >
            <AriaDatePicker placeholder="Date of birth" onlyPast />
          </Form.Item>
        </div>
      </div>

      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-adult-btn">
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
          onClick={() => form.submit()}
          data-testid="save-adult-btn"
        >
          {isFamilyUser ? 'Next' : 'Save'}
        </Button>
      </div>
    </>
  );
};
