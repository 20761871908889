import { Auth, Hub } from 'aws-amplify';
import dayjs from 'dayjs';
import { RESET_ALL } from 'constants/index';
import { Modal } from 'antd';
import { buildWebStorage, setupCache as setupCacheInterceptor } from 'axios-cache-interceptor';

export const errorInterceptor = (error) => {
  if (error.message === 'Network Error') {
    if (error?.response?.status === 504) {
      // eslint-disable-nextline
      throw {
        message: 'Something went wrong. Please try again later.',
      };
    } else {
      // eslint-disable-nextline
      throw {
        message: 'You are not connected to the internet. Verify your connection and then try again.',
      };
    }
  }
  if (error.response) {
    if (error.response.status === 500) {
      // eslint-disable-nextline
      throw {
        message: 'Something went wrong. Please try again later.',
      };
    }
    // if (error.response.status === 401) {
    //   // Dispatch event to logout
    //   throw {
    //     message: 'Logged out',
    //   };
    // }
    if (error.response.status === 403 || (error.response.status === 401 && !window.Cypress)) {
      if (error.config.url.includes('/communications/alerts')) {
        // Avoid showing error msgs for alerts endpoint failures
        return;
      }

      // Dispatch event to redirect to Home
      const modal = document.querySelector('.permission-denied-modal');
      if (!modal) {
        Modal.error({
          title: 'Permission Denied',
          content: 'Your role is not authorized to perform this action.',
          wrapClassName: 'permission-denied-modal',
          zIndex: 1011,
          width: 500,
        });
      }

      throw {
        message: 'Permission Denied',
      };
    }
    // eslint-disable-nextline
    throw { ...error.response.data, statusCode: error.response.status };
  }
  // eslint-disable-nextline
  throw {
    message: 'Something went wrong. Please try again later.',
  };
};

export const requestInterceptor = async (config) => {
  try {
    // Authorization management
    const { accessToken } = await Auth.currentSession();
    config.headers.Authorization = `Bearer ${accessToken.jwtToken}`;
    // Set active role
    try {
      // const rootState = JSON.parse(localStorage.getItem('persist:root'));
      // const { activeRole } = JSON.parse(rootState.auth);
      // const authState = JSON.parse(localStorage.getItem('persist:auth'));
      // const { activeRole } = authState;
      // config.headers.common['Active-Role'] = activeRole.replace(/"/g, '');
      const { auth } = JSON.parse(localStorage.getItem('redux-state'));
      config.headers['Active-Role'] = auth.activeRole;
    } catch (error) {
      newrelic.noticeError(error);
    }
  } catch (error) {
    newrelic.noticeError(error);
    // Dispatch event to logout
    Hub.dispatch('redux', {
      event: RESET_ALL,
    });
  }
  config.transformRequest = [formatDayjsData, ...config.transformRequest];
  return config;
};

function formatDayjsData(data) {
  if (Array.isArray(data)) {
    return data.map((item) => formatDayjsData(item));
  }

  if (typeof data === 'object' && data !== null) {
    if (Object.keys(data).length === 0) return data;
    return Object.keys(data).reduce((prev, key) => {
      if (key === 'createdBy') return prev;
      if (key === 'lastModifiedBy') return prev;
      if (key === 'createdDate') return prev;
      if (key === 'lastModifiedDate') return prev;
      if (dayjs.isDayjs(data[key])) {
        prev[key] = data[key].toISOString();
      } else if (Array.isArray(data[key])) {
        prev[key] = data[key].map((item) => formatDayjsData(item));
      } else if (data[key] !== null && typeof data[key] === 'object') {
        prev[key] = formatDayjsData(data[key]);
      } else {
        prev[key] = data[key];
      }
      return prev;
    }, {});
  }
  return data;
}

export const setupCache = (instance, config = {}) => {
  instance.interceptors.response.use(null, errorInterceptor);
  instance.interceptors.request.use(requestInterceptor);

  const request = setupCacheInterceptor(instance, {
    methods: ['get'],
    cache: {
      ttl: 1000 * 60 * 60 * 60, // 1 hour
      cacheTakeover: false,
      cachePredicate: (res) => true,
    },
    buildWebStorage: buildWebStorage(localStorage, 'axios-cache:'),
    ...config,
  });
  return request;
};

export const cache = { interpretHeader: false };
