import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { Button, Checkbox, Skeleton, InputNumber, message } from 'antd';

import Form from 'components/Form';
import Modal from 'components/Modal';
import { useAsyncFn } from 'react-use';
import providerService from 'services/providers.service';
import styles from './ProgramEligibilityModal.module.less';
import { getCenter } from 'features/add-center';
import Editor from 'components/Editor';

const ProgramEligibilityModal = ({ visible, setVisible, id, applicationId, getContainer }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [{ loading, value: programs = {} }, fetchPrograms] = useAsyncFn(async () => {
    if (id) {
      const data = await providerService.getPrograms(id);
      const programs = {};
      let sponsors = {};
      sponsors = data.reduce((prev, curr) => {
        prev[curr.type.title] = sortBy(Array.from(prev[curr.type.title] || []).concat(curr), [
          (obj) => obj.sponsor.title,
        ]);
        programs[curr.type.title] |= curr.selected;
        return prev;
      }, {});
      const activeKeys = Object.keys(sponsors).filter((program) => sponsors[program].find((sponsor) => sponsor.active));
      const activeItems = activeKeys.sort().reduce((prev, key) => ({ ...prev, [key]: sponsors[key] }), {});

      form.setFieldsValue({
        sponsors: { ...activeItems },
        programs,
      });
      return { ...activeItems };
    }
  }, [id, visible]);

  const [{ loading: updating }, updatePrograms] = useAsyncFn(
    async (values) => {
      let { sponsors, notes } = values;
      sponsors = Object.values(sponsors).reduce((prev, curr) => prev.concat(curr), []);
      try {
        const data = await providerService.updatePrograms(id, sponsors);
        /* istanbul ignore else */
        if (notes && data) {
          await providerService.saveNote(applicationId, { content: notes, draft: false });
        }
        dispatch(getCenter(id));
        form.resetFields();
        setVisible(false);
        message.success('Approved program updated successfully.');
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to update changes.');
      }
    },
    [id],
  );

  useEffect(() => {
    if (visible) {
      fetchPrograms();
    }
  }, [fetchPrograms, visible]);
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      onCancel={() => setVisible(false)}
      width={750}
      destroyOnClose={true}
      data-testid="program-modal"
    >
      <div className={styles.programmesForm}>
        <div style={{ pointerEvents: 'auto' }} className={classNames(styles.checkBox, 'bg-white rounded-lg')}>
          <h3 className="text-primary font-semibold uppercase mb-6 mt-2">Approved Programs</h3>
          <div className="mb-4">
            <span className="font-medium">
              Please select the programs this provider is approved for. You can only select program(s) that appear in
              the provider's application.
            </span>
          </div>
          <Form
            form={form}
            layout="vertical"
            onFinish={updatePrograms}
            onValuesChange={({ programs, sponsors }, values) => {
              if (programs && !sponsors) {
                const [key, checked] = Object.entries(programs)?.[0] || [];
                /* istanbul ignore else */
                if (!checked) {
                  form.setFieldsValue({
                    sponsors: {
                      [key]: values.sponsors[key].map((sponsor) => ({ ...sponsor, selected: false })),
                    },
                  });
                }
              } else if (sponsors && !programs) {
                let program = Object.keys(sponsors)?.[0];
                /* istanbul ignore else */
                if (program) {
                  form.setFieldsValue({
                    programs: {
                      [program]: Array.from(values.sponsors[program] || []).filter((v) => v.selected).length > 0,
                    },
                  });
                }
              }
            }}
            initialValues={programs}
          >
            {loading ? (
              <Skeleton paragraph={{ rows: 10 }} />
            ) : (
              <div className="w-full">
                <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                  {Object.keys(programs).map((program) => {
                    const isDisabled = !programs[program].some((sponsor) => sponsor.active);
                    return (
                      <div key={program}>
                        <Form.Item name={['programs', program]} valuePropName="checked">
                          <Checkbox disabled={isDisabled} className="checkbox-label">
                            {program}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          name={['programSelections', program]}
                          rules={[
                            (form) => ({
                              validator: async (rule, value) => {
                                /* istanbul ignore else */
                                if (form.getFieldValue(['programs', program])) {
                                  const sponsors = form.getFieldValue(['sponsors', program]);
                                  /* istanbul ignore else */
                                  if (sponsors.filter((sponsor) => sponsor.selected).length === 0) {
                                    return Promise.reject('Select at least one Branch.');
                                  }
                                }
                              },
                            }),
                          ]}
                          shouldUpdate
                        >
                          <div className="ml-5">
                            {programs[program].map((sponsor, index) => {
                              return (
                                <div key={sponsor.sponsor.title}>
                                  <Form.Item
                                    name={['sponsors', program, index, 'selected']}
                                    valuePropName="checked"
                                    className="mb-0"
                                  >
                                    <Checkbox disabled={!sponsor.active} className="checkbox-label">
                                      {sponsor.sponsor.title}
                                    </Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    disabled={!sponsor.active}
                                    name={['sponsors', program, index, 'id']}
                                    hidden
                                  >
                                    <InputNumber />
                                  </Form.Item>
                                </div>
                              );
                            })}
                          </div>
                        </Form.Item>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <Form.Item label="Notes" name="notes" className="mt-5 mb-8">
              <Editor compact={false} height={150} visible={visible} />
              {/* <Input.TextArea autoSize={{ minRows: 5 }} placeholder="Notes" data-testid="program-notes" /> */}
            </Form.Item>
            <div className="actions flex mt-8">
              <Button
                onClick={() => setVisible(false)}
                className="flex-1"
                //   disabled={loadingUpdateCenter}
                data-testid="program-popup-close-btn"
              >
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                className="flex-1"
                disabled={updating}
                loading={updating}
                data-testid="program-popup-save-btn"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default ProgramEligibilityModal;
