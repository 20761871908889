import React from 'react';
import classNames from 'classnames';

import styles from './NestedPage.module.less';

export default function NestedPage({ className, children, heading, headingClassName, actions }) {
  return (
    <div className={classNames(styles.nestedPage, className)}>
      <div className="px-6 pt-3 pb-3 bg-white w-full border-b-2 border-primary border-opacity-25">
        <div className="flex flex-row justify-between items-center">
          <div className="flex">
            <h1 className={classNames(headingClassName)}>{heading}</h1>
          </div>
          <div className="flex flex-row space-x-2 justify-end">{actions}</div>
        </div>
      </div>
      <div className="white-box">{children}</div>
    </div>
  );
}

NestedPage.defaultProps = {
  className: '',
  headingClassName: 'text-lg text-primary font-semibold uppercase',
};
