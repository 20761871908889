import React, { memo } from 'react';
import { Button, Tag } from 'antd';
import classNames from 'classnames';
import { useComponentSize } from 'react-use-size';
import { getCompletedAddress, getFormattedPhoneNumber, renderAgeRange } from 'utils';
import useBreakpoints from 'hooks/useBreakpoints';
import { FaPhone } from 'react-icons/fa';
import { uniqBy } from 'lodash';

export const FacilityTypeMapping = {
  CENTER: 'Child Care Center',
  SCHOOL: 'School',
  IN_HOME: 'Family Child Care Home',
  OTHER: 'Other',
};

function ProviderCard({ provider, className, isActive, onCardClick, isPublicSearch, onSelectClick, selectedProvider }) {
  const {
    id,
    businessName,
    businessEmail,
    email,
    address,
    sponsorAddress,
    businessPhone,
    minAgeServed,
    maxAgeServed,
    facilityType,
    programs = [],
  } = provider;
  const { ref, width } = useComponentSize();
  const breakpoints = useBreakpoints();
  const formattedPhone = getFormattedPhoneNumber(businessPhone || '');
  const isDisabledProvider = selectedProvider?.id === provider.id;
  const tooSmall = width < 450;
  const age = minAgeServed && maxAgeServed ? `${minAgeServed} - ${maxAgeServed} Months` : undefined;
  const fullAddress = getCompletedAddress(address || sponsorAddress);
  const title = (
    <h3 className={classNames('font-medium text-base md:text-lg col-span-2 mt-3 md:mt-0')} title={businessName}>
      {businessName || 'NA'}
    </h3>
  );

  const phoneBtn = (
    <a
      data-testid="call-provider"
      className="rounded-full focus:ring-2 ring-primary ring-offset-1"
      href={`tel:${formattedPhone}`}
    >
      <Button
        type="primary"
        className="btn w-48 !rounded-full pointer-events-none flex items-center gap-2 justify-center"
        onClick={(e) => e.stopPropagation()}
        tabIndex={-1}
      >
        <FaPhone className="w-4 h-4 -scale-x-100" />
        {formattedPhone}
      </Button>
    </a>
  );
  const selectBtn = (
    <Button
      disabled={isDisabledProvider}
      className="btn w-40 !rounded-full focus:ring-2 ring-primary ring-offset-1"
      type="primary"
      onClick={(e) => {
        e.stopPropagation();
        onSelectClick(provider);
      }}
    >
      Select Provider
    </Button>
  );

  const btns = (
    <div
      className={classNames(
        'flex justify-between items-end sm:flex-col sm:items-center sm:justify-end  space-x-3 md:space-x-0  md:-mt-4',
      )}
    >
      <div className="flex flex-col md:items-center space-y-2 mt-2">
        {!isPublicSearch && selectBtn}
        {isPublicSearch && phoneBtn}
      </div>
    </div>
  );
  return (
    <li
      className={classNames(
        className,
        'bg-white shadow-2xl rounded-3xl mb-6 px-6 pt-6 pb-6 flex flex-col focus:ring-2 ring-primary ring-offset-2 focus:outline-none cursor-pointer hover:ring-2 hover:ring-primary transition-all duration-30',
        { 'hover:ring-opacity-50': !isActive },
      )}
      id={`provider-card-${id}`}
      data-testid={`provider-card-${id}`}
      ref={ref}
      tabIndex={0}
      aria-label={businessName}
      onFocus={() => onCardClick(provider)}
      onClick={() => onCardClick(provider)}
    >
      {!breakpoints.sm && btns}

      <div className="flex">
        <div className="flex-grow">{title}</div>
        {breakpoints.sm && btns}
      </div>

      <div
        className={classNames('flex md:justify-between md:space-x-3 text-sm mt-4', {
          // 'md:justify-between md:space-x-3': !isSmall,
        })}
      >
        <div className={classNames('flex flex-col gap-2')}>
          {(email || businessEmail) && (
            <div className="flex items-center gap-1">
              <span>Email:</span>{' '}
              <span className="font-medium inline-block truncate text-ellipsis">{businessEmail || email}</span>
            </div>
          )}

          {facilityType && (
            <div className="flex items-center gap-1">
              <span>{!tooSmall && 'Facility'} Type:</span>{' '}
              <span className="font-medium inline-block">
                <Tag className="font-medium inline-block">{FacilityTypeMapping[facilityType]}</Tag>
              </span>
            </div>
          )}
          {fullAddress && (
            <div className="flex items-center gap-1">
              <span>Address:</span>
              <span className="font-medium inline-block line-clamp-2">{fullAddress}</span>
            </div>
          )}
          {age && (
            <div className="flex items-center gap-1">
              <span>Ages Served:</span>
              <span className="font-medium inline-block">
                {renderAgeRange({
                  minAge: minAgeServed,
                  maxAge: maxAgeServed,
                  showFullDuration: true,
                })}
              </span>
            </div>
          )}
          {programs?.length > 0 && (
            <div className="flex items-center gap-1">
              <span>Programs:</span>{' '}
              <span className="font-medium inline-block">
                {Array.isArray(programs)
                  ? (uniqBy(programs, (d) => d?.type?.title) || [])?.map?.((obj) => obj.type.title).join(', ')
                  : ''}
              </span>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}

export default memo(ProviderCard);
