import React, { useMemo } from 'react';
import { Checkbox, Button, Input } from 'antd';
import { useAsync } from 'react-async-hook';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Group, { GroupContext } from 'components/Group';
import { DATE_FORMAT } from 'constants';
import providersService from 'services/providers.service';
import styles from './DisqualifiedReasonsModal.module.less';

export default function DisqualifiedReasonsModal({ visible, setVisible, onSubmit, notesForm }) {
  const [form] = Form.useForm();
  const selectedReasons = Form.useWatch('reasons', form);
  /* 12 id for others option */
  const isShowOtherReasons = useMemo(() => selectedReasons?.some?.((item) => item.id === 12), [selectedReasons]);
  const { loading, result: reasons = [] } = useAsync(async () => await providersService.getDQReasons(), []);

  return (
    <Modal visible={visible} setVisible={setVisible} width={800} closable={false}>
      <h3 className="section-title mb-8">Disqualification Reason</h3>
      <Form layout="vertical" form={form} className={styles.reasons}>
        <Form.Item
          name="reasons"
          rules={[
            {
              required: true,
              message: 'At least one disqualification reason is required.',
            },
          ]}
        >
          <Group comparator={(v) => v.id} multiple>
            <div className="grid grid-cols-2 gap-y-6 mb-4">
              {reasons.map(({ reason, id }) => (
                <GroupContext value={{ id, reason }} key={id}>
                  <CheckboxWrapper>{reason}</CheckboxWrapper>
                </GroupContext>
              ))}
            </div>
          </Group>
        </Form.Item>
        <div className="grid grid-cols-2 gap-x-8 ">
          <Form.Item
            name="disqualificationDate"
            label="Disqualification Date"
            rules={[{ required: true, message: 'Disqualification date is required.' }]}
          >
            <Input placeholder={DATE_FORMAT[1]} />
          </Form.Item>
          {isShowOtherReasons && (
            <Form.Item name="otherReason" label="Add information here">
              <Input placeholder="other reason" />
            </Form.Item>
          )}
        </div>

        <div className="actions flex mt-6">
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={() => {
              const values = notesForm.getFieldsValue();
              setVisible(false);
              onSubmit?.(values);
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

function CheckboxWrapper({ children, onChange, checked }) {
  return (
    <Checkbox className="ml-0" checked={checked} onChange={(e) => onChange(e.target.checked)}>
      {children}
    </Checkbox>
  );
}
