import React, { memo } from 'react';
import classNames from 'classnames';
import useAttachmentFile from 'hooks/useAttachmentFile';

function AttachmentItem({
  id,
  file,
  name,
  percent,
  status,
  type,
  thumbUrl,
  actions,
  setPreview,
  lastModifiedDate,
  className,
  hideDelete,
}) {
  const { thumbnail, preview, downloadbtn, removeBtn } = useAttachmentFile({
    id,
    file,
    name,
    percent,
    status,
    type,
    thumbUrl,
    actions,
    setPreview,
    lastModifiedDate,
  });

  return (
    <div
      className={classNames(
        'flex items-center w-full border border-gray-200 rounded mt-1 px-2 py-2 md:py-0 md:h-10 relative',
        className,
      )}
    >
      <div className="w-full flex flex-col md:flex-row md:items-center justify-between">
        <div className="flex items-center space-x-2">
          {preview}
          <span>
            <a href={thumbnail} rel="noreferrer" target="_blank" className="underline">
              {name}
            </a>
          </span>
        </div>
        <div className="flex items-center justify-end space-x-2">
          {downloadbtn}
          {!hideDelete && removeBtn}
        </div>
      </div>
    </div>
  );
}

export default memo(AttachmentItem);
