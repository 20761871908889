import React, { useMemo } from 'react';
import { Input } from 'antd';

import Form from 'components/Form';
import Section from 'components/Section';
import PhoneNumber from 'components/PhoneNumber';
import PhoneNumberContainer from 'components/PhoneNumberContainer';
import { getPhoneEXTValidator } from 'utils';
import { AsyncSelect } from 'components/Select';
import { familyService } from 'services';

function CommanderInfo({
  heading,
  prefixName = ['applicant'],
  isSupervisor,
  infoText,
  readOnly = false,
  collapsible = true,
  contentClassName = 'section-px -mt-2 pb-2',
  headerContainerClassName,
}) {
  const firsNameFieldName = useMemo(() => [...prefixName, 'position', 'supervisorCommanderFn'], [prefixName]);
  const lastNameFieldName = useMemo(() => [...prefixName, 'position', 'supervisorCommanderLn'], [prefixName]);
  const emailFieldName = useMemo(() => [...prefixName, 'position', 'supervisorCommanderEmail'], [prefixName]);
  const phoneFieldName = useMemo(() => [...prefixName, 'position', 'supervisorCommanderPhone'], [prefixName]);
  const phoneExtFieldName = useMemo(() => [...prefixName, 'position', 'supervisorCommanderPhoneExt'], [prefixName]);
  const unitOfAssignmentFieldName = useMemo(() => [...prefixName, 'position', 'unitOfAssignment'], [prefixName]);
  const unitIdFieldName = useMemo(() => [...prefixName, 'position', 'unitIdCode'], [prefixName]);
  const companyFieldName = useMemo(() => [...prefixName, 'position', 'companyName'], [prefixName]);
  const employerTypeFieldName = useMemo(() => [...prefixName, 'position', 'employerType'], [prefixName]);
  return (
    <Section
      heading={heading}
      collapsible={collapsible}
      contentClassName={contentClassName}
      headerContainerClassName={headerContainerClassName}
    >
      {infoText}
      <div className="grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-2 mt-2">
        <div>
          <Form.Item name={firsNameFieldName} label="First Name">
            <Input placeholder="First Name" readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={lastNameFieldName} label="Last Name">
            <Input placeholder="Last Name" readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={emailFieldName} label="Email Address">
            <Input placeholder="Email Address" readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <PhoneNumberContainer
            phoneNumber={
              <Form.Item name={phoneFieldName} label="Phone Number">
                <PhoneNumber placeholder="Phone Number" readOnly={readOnly} />
              </Form.Item>
            }
            extension={
              <Form.Item
                name={phoneExtFieldName}
                label="Extension"
                rules={[getPhoneEXTValidator('Invalid Extension!')]}
              >
                <Input placeholder="Extension" readOnly={readOnly} />
              </Form.Item>
            }
          />
        </div>
        {!isSupervisor && (
          <>
            <div>
              <Form.Item name={unitOfAssignmentFieldName} label="Unit of Assignment">
                <Input placeholder="Unit of Assignment" readOnly={readOnly} />
              </Form.Item>
            </div>
            <div>
              <Form.Item name={unitIdFieldName} label="unit Identification Code">
                <Input placeholder="Unit Identification Code" readOnly={readOnly} />
              </Form.Item>
            </div>
          </>
        )}
        {isSupervisor && (
          <>
            <div>
              <Form.Item name={companyFieldName} label="Company Name">
                <Input placeholder="Company Name" readOnly={readOnly} />
              </Form.Item>
            </div>
            <div>
              <Form.Item name={employerTypeFieldName} label="Employer Type">
                <AsyncSelect
                  getOptions={familyService.getOptions}
                  apiPrefix=""
                  optionsApiUrl="/options/16"
                  placeholder="Employer Type"
                  ariaLabel="Employer Type"
                  name="employerType"
                  readOnly={readOnly}
                />
              </Form.Item>
            </div>
          </>
        )}
      </div>
    </Section>
  );
}

export default CommanderInfo;
