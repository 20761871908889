export const ENV = 'uat';
export const API_BASE_URL = 'https://api.uat.greatchildcare.org';

export const AMPLIFY_CONFIG = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:5ee819f4-bd3d-4c03-a2e3-9ef78b5a1717',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_QoYlzlfek',
  aws_user_pools_web_client_id: '40lnhval1ksur6q69ccvd1optk',
  oauth: {},
  federationTarget: 'COGNITO_IDENTITY_POOLS',
  aws_user_files_s3_bucket: 'uat-gcc-uploads-bucket',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_content_delivery_bucket: 'uat-gcc-hosting-bucket',
  aws_content_delivery_bucket_region: 'us-east-1',
  aws_content_delivery_url: 'https://d12fljmgcetosq.cloudfront.net',
};
