import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { userService } from 'services';
import { useAsync } from 'react-async-hook';
import { getFullName } from 'utils';

export default function RenderUserName({ className, id, size, fallback = 'N/A' }) {
  const { loading, result: user = {} } = useAsync(
    async (id) => {
      if (!id) return null;
      return await userService.getUserById(id, { cache: { interpretHeader: false } });
    },
    [id],
  );
  return (
    <Spin size={size} spinning={loading}>
      <span className={className}>{getFullName(user) || fallback}</span>
    </Spin>
  );
}

RenderUserName.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  size: PropTypes.oneOf(['small', 'default', 'large']),
};
RenderUserName.defaultProps = {
  className: '',
  size: 'small',
};
