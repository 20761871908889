import React from 'react';
import ContentLayout from 'components/ContentLayout';
import ProviderHelpButtons from 'components/ProviderHelpButtons';
import AddProfile from './AddProfile';

function UserProfile() {
  return (
    <div className="layout-page">
      <div className="px-5 sm:px-9 pt-9">
        <h1 className="text-2xl sm:text-3xl">Welcome, Please fill in the following details.</h1>
      </div>

      <ContentLayout sidebarClassName="p-5" sidebarContent={<ProviderHelpButtons />}>
        <AddProfile />
      </ContentLayout>
    </div>
  );
}

export default UserProfile;
