import React, { useCallback, useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Form from 'components/Form';
import styles from './InternalSearchBar.module.less';
import { selectors as dashboardSelectors, actions as dashboardActions } from 'features/dashboard';
import { DASHBOARD_TYPES } from 'constants/index';

export default function InternalSearchBar() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  let { search } = useLocation();
  const dashboardType = useSelector(dashboardSelectors.selectDashboardType);
  const [type, setType] = useState(dashboardType || DASHBOARD_TYPES.PROVIDERS);

  const params = new URLSearchParams(search);
  const query = params.get('query');

  useEffect(() => {
    setType(dashboardType);
  }, [dashboardType]);

  const onSearch = useCallback(
    ({ query }) => {
      if (query) {
        history.push({
          pathname: type === DASHBOARD_TYPES.FAMILIES ? '/families' : '/providers',
          search: `query=${query}`,
        });
        dispatch(dashboardActions.setDashboardType(type));
      }
    },
    [dispatch, history, type],
  );
  const selectBefore = (
    <Select value={type} onSelect={(value) => setType(value)} aria-label="Select a user type">
      <Select.Option value="Providers">Providers</Select.Option>
      <Select.Option value="Families">Families</Select.Option>
    </Select>
  );
  useEffect(() => {
    form.setFieldValue('query', query);
  }, [form, query]);
  return (
    <Form className={styles.searchBar} form={form} onFinish={onSearch}>
      <Form.Item className="mt-5" name="query">
        <Input.Search
          placeholder={
            type === DASHBOARD_TYPES.FAMILIES
              ? 'Search by app ID, family ID or sponsor name'
              : 'Search by provider ID or business name'
          }
          onSearch={() => {
            form.submit();
          }}
          addonBefore={selectBefore}
          size="middle"
        />
      </Form.Item>
    </Form>
  );
}
