import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useAsyncCallback } from 'react-async-hook';

import styles from './Alerts.module.less';
import useTablePagination from 'hooks/useTablePagination';
// import useSwitchFilters from 'hooks/useSwitchFilters';
import alertsService from 'services/alerts.service';
import { Button, Divider, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { AiFillCaretDown, AiFillCaretUp, AiOutlineEdit } from 'react-icons/ai';
import { formatDateTimeToLocal, getFullName, getNumberSorter, getTextSorter } from 'utils';
import ColumnTitle from 'components/ColumnTitle';
import { BACKEND_DATE_TIME_FORMAT } from 'constants/index';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import useModalState from 'hooks/useModalState';
import Modal from 'components/Modal';
import Table from 'components/Table';
import ExpandedRowList from 'components/ExpandedRowList';
import AddAlert from 'components/forms/AddAlert';
import AlertNotes from 'components/AlertNotes';
import useBreakpoints from 'hooks/useBreakpoints';
import Card from 'components/Card/Card';
import { Renew } from '@carbon/icons-react';
import Space from 'components/Space/Space';

const PAGE_SIZE = 10;

export default function Alerts({ entityType, entityId, pageSize, entityLabel }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandedItem, setExpandedItem] = useState({});
  const { visible, onClose, onOpen } = useModalState();
  const params = useMemo(
    () => ({
      current: 1,
      pageSize: pageSize || PAGE_SIZE,
      sort: 'id,desc',
      entityType,
      entityId,
    }),
    [entityId, entityType, pageSize],
  );

  const alertsList = useTablePagination({
    url: `/alerts/alerts`,
    params,
  });
  const { loading: resolvingAlert, execute: resolveAlert } = useAsyncCallback(
    async (record) => {
      const message = `Unable to ${record.status === 'Resolved' ? 'unresolve' : 'resolve'} an alert.`;
      try {
        await alertsService.resolveAlert(record.id, record.status === 'Resolved');
        const updatedAlert = {
          ...record,
          status: record.status === 'Resolved' ? 'New' : 'Resolved',
          resolvedAt: record.status === 'Resolved' ? null : dayjs().utc().format(BACKEND_DATE_TIME_FORMAT),
        };
        setExpandedItem(updatedAlert);
        const index = alertsList.data.findIndex((v) => v.id === record.id);
        if (index > -1) {
          const data = [...alertsList.data.slice(0, index), updatedAlert, ...alertsList.data.slice(index + 1)];
          alertsList.setData((v) => ({ ...v, data }));
        }
      } catch (error) {
        newrelic.noticeError(error);
        message.error(message, 5);
      }
    },
    [alertsList.data],
  );

  const columns = useMemo(() => {
    let columns = [
      {
        title: (props) => <ColumnTitle showSorting {...props} title="Status" dataIndex="status" />,
        headerText: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        width: 100,
        fixed: 'left',
        render: (value, record) => {
          return value === 'Resolved' ? (
            <MdOutlineCheckBox size={24} className="text-green-600 mt-1" data-testid={`resolved-${record.id}`} />
          ) : (
            <MdCheckBoxOutlineBlank size={24} className="text-gray-400 mt-1" data-testid={`unresolved-${record.id}`} />
          );
        },
      },
    ];

    if (entityLabel) {
      columns = columns.concat({
        title: (props) => (
          <ColumnTitle showSorting {...props} title={entityLabel} dataIndex={['entityId']} colKey="entityId" />
        ),
        headerText: entityLabel,
        dataIndex: ['entityId'],
        key: 'entityId',
        sorter: getTextSorter('entityId'),
        className: 'truncate max-w-xs',
      });
    } else {
      columns = columns.concat({
        title: (props) => <ColumnTitle showSorting {...props} title="Alert Id" dataIndex="id" />,
        headerText: 'Alert Id',
        dataIndex: 'id',
        key: 'id',
        width: 150,
        sorter: getNumberSorter('id'),
      });
    }
    columns = columns.concat(
      {
        title: (props) => <ColumnTitle {...props} title="Created By" dataIndex="createdBy" />,
        headerText: 'Created By',
        dataIndex: ['createdBy', 'firstName'],
        key: 'createdBy',
        render: (_, { createdBy }) => {
          return getFullName(createdBy) || 'System Generated';
        },
      },
      {
        title: (props) => <ColumnTitle showSorting {...props} title="Type" dataIndex={['type', 'title']} />,
        headerText: 'Type',
        dataIndex: ['type', 'title'],
        key: 'type',
        sorter: true,
        render: (title) => <span className="capitalize">{title}</span>,
      },
      {
        title: (props) => <ColumnTitle showSorting {...props} title="Date Created" dataIndex="createdDate" />,
        headerText: 'Date Created',
        dataIndex: 'createdDate',
        key: 'createdDate',
        sorter: true,
        render: (date) => formatDateTimeToLocal(date),
        width: 250,
      },
      {
        title: (props) => <ColumnTitle showSorting {...props} title="Date Resolved" dataIndex="resolvedAt" />,
        headerText: 'Date Resolved',
        dataIndex: 'resolvedAt',
        key: 'resolvedAt',
        sorter: true,
        render: (date) => formatDateTimeToLocal(date),
        width: 250,
      },

      {
        title: <span className="action-header mt-2 justify-center">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 90,
        fixed: 'right',
        // eslint-disable-next-line react/display-name
        render: (text, record) => {
          return (
            <div className="actions-cell bordered space-x-4 justify-end">
              {/* <Button
              // onClick={() => history.push(`/provider/facility/${record.id}`)}
              icon={
                record.type === 'incoming' ? (
                  <IoMdArrowRoundDown className="yellow" />
                ) : (
                  <IoMdArrowRoundUp className="red" />
                )
              }
              className="icon-btn"
              aria-label={record.type}
              title={record.type === 'incoming' ? 'Incoming' : 'Outgoing'}
            /> */}
              <Button
                onClick={() => {
                  setExpandedItem(record);
                  onOpen();
                }}
                icon={<AiOutlineEdit />}
                className="icon-btn"
                aria-label="Edit"
                data-testid={`edit-button-${record.id}`}
                title="Edit"
              />
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                    setExpandedItem(null);
                  } else {
                    setExpandedRows([record.id]);
                    setExpandedItem(record);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                className="icon-btn"
                aria-label="Open"
                data-testid={`collapse-button-${record.id}`}
                // disabled={loading}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            </div>
          );
        },
      },
    );
    return columns;
  }, [entityLabel, expandedRows, onOpen]);

  const actions = (
    <Space>
      {entityType && (
        <Button type="primary" onClick={onOpen} data-testid="create-alert-btn">
          Create Alert
        </Button>
      )}

      <Button
        icon={<Renew />}
        data-testid="reload-btn"
        onClick={alertsList.reload}
        loading={alertsList.loading}
        disabled={alertsList.loading}
      />
    </Space>
  );
  return (
    <Card noBodyPadding title="Alerts" extra={actions}>
      <Table
        rowKey="id"
        className={classNames(styles.table, 'w-full')}
        allColumns={columns}
        data={alertsList.data}
        loading={alertsList.loading}
        pagination={alertsList.pagination}
        onChange={alertsList.onChange}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandedRow
              record={record}
              columns={columns}
              resolveAlert={resolveAlert}
              resolvingAlert={resolvingAlert}
              reload={alertsList.reload}
              loading={false}
            />
          ),
          rowExpandable: (record) => true,
          expandedRowKeys: expandedRows,
          expandIcon: () => null,
          columnWidth: 0,
        }}
        rowClassName={(record, index) => {
          let className = '';
          return classNames(className, 'h-9', {
            expanded: expandedRows.indexOf(record.id) > -1,
            '[&_td]:!bg-light-bg': index % 2 !== 0,
            '[&_td]:!bg-white': index % 2 === 0,
          });
        }}
      />

      <Modal
        width={700}
        visible={visible}
        setVisible={onClose}
        title={(expandedItem || {})?.id ? 'Update An Alert' : 'Create A New Alert'}
      >
        <AddAlert
          entityId={entityId}
          entityType={entityType}
          setVisible={(visible) => {
            onClose();
            /* istanbul ignore else */
            if (!visible) {
              setExpandedItem(null);
            }
          }}
          data={expandedItem ? expandedItem : {}}
          loading={false}
          reload={alertsList.reload}
        />
      </Modal>
    </Card>
  );
}

function ExpandedRow({ record, loading, columns, resolveAlert, resolvingAlert, reload }) {
  const breakpoints = useBreakpoints();
  return (
    <div className="border border-b-0 border-t-0 border-gray-300 bg-white border-l-2 border-l-yellow-500 email-renderer">
      <Skeleton active loading={loading} className="px-4 py-5">
        <div className="h-3">{/* Empty div to add some spacing at top, margin-top did not work */}</div>
        <div className="mx-3 p-2 bg-white border border-gray-200" data-testid="alert-description">
          {record.description || 'NA'}
        </div>
        {!breakpoints.lg && <ExpandedRowList data={record} columns={columns} className="p-3" />}
      </Skeleton>
      <div className="mx-3 pt-4">
        <Divider className="my-4 border-dashed border-gray-300" />
      </div>
      <AlertNotes
        record={record}
        loading={loading}
        breakpoints={breakpoints}
        resolveAlert={resolveAlert}
        resolvingAlert={resolvingAlert}
        reload={reload}
      />
    </div>
  );
}
