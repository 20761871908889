import React from 'react';
import Section from 'components/Section';
import { getFullName } from 'utils';
import Form from 'components/Form';
import ApplicationEntityReview from 'containers/Family/AddApplication/__components/ApplicationEntityReview';
import classNames from 'classnames';
import { AsyncSelect } from 'components/Select';

export default function Summary({
  application,
  household,
  profileData,
  parentId,
  parentIds,
  index,
  setStep,
  id,
  onCancel,
  next,
  loading,
  onDelete,
}) {
  const parent = profileData?.adults?.find((adult) => adult.id === parentId);

  const _fullName = [
    (parent?.prefix?.title || '').toLowerCase() !== 'prefer not to say' ? parent?.prefix?.title : null,
    getFullName(parent) || 'N/A',
  ]
    .filter(Boolean)
    .join(' ');
  const _sponsorFullName = getFullName(application?.applicant) || 'N/A';

  return (
    <>
      <Section
        className={classNames('bg-white', {
          'mb-6': index !== parentIds.length - 1,
          'mt-6': index !== 0,
        })}
        headingClassName="page-title"
        heading={`Additional Parent #${index + 1}`}
        contentClassName=""
      >
        <ApplicationEntityReview className="rounded-b-none" onDelete={onDelete} entity={parent} />

        <Section headingClassName="page-title" heading="Relationship to Sponsor" collapsible={false}>
          <div className="flex gap-3 mb-6 items-center">
            <span className="font-bold">{_fullName}</span>
            <span>is the</span>
            <Form.Item
              name={[parentId, 'relationshipType']}
              rules={[{ required: true, message: 'This field is required.' }]}
              className="m-0"
            >
              <AsyncSelect
                name={[parentId, 'relationshipType']}
                placeholder="Relation"
                ariaLabel="Relationship to Sponsor"
                optionsApiUrl="/options/24"
                apiPrefix="/families"
                isClearable={false}
              />
            </Form.Item>

            <span>of</span>

            <span className="font-bold">{_sponsorFullName}</span>
          </div>
        </Section>
      </Section>

      {/* <Form onFinish={onFinish}>
        <Actions
          onBack={() => {
            if (index > 0) {
              setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS, index - 1, ADD_FAMILY_TABS.DOCUMENTS);
            } else {
              setStep(id, ADD_FAMILY_STEPS.SPONSOR, ADD_FAMILY_TABS.DOCUMENTS);
            }
          }}
          // onClear={activeRole === ROLES.FAMILY ? null : () => form.resetFields()}
          onCancel={onCancel}
          testId={`${ADD_FAMILY_STEPS.SECOND_PARENTS}-${index}-${ADD_FAMILY_TABS.INFO}`}
        />
      </Form> */}
      {/* </div> */}
    </>
  );
}
