import { useEffect, useMemo, useState } from 'react';
import { Button, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';

import Form from 'components/Form';
import Select from 'components/Select';
import Section from 'components/Section';
import SchedulesTable from 'containers/Family/FamilyCertificates/SchedulesTable';
import SponsorInstallationModal from 'components/Modals/SponsorInstallationModal';
import EditCertificateScheduleModal from 'containers/Family/FamilyCertificates/EditCertificateScheduleModal';
import { familyService } from 'services';
import { formatDate, getFullName } from 'utils';

export default function CreateCertificate({
  id,
  householdId,
  application,
  certificate,
  loading: applicationLoading,
  prev,
  next,
}) {
  const [form] = Form.useForm();
  const history = useHistory();
  const { certificateId } = useParams();
  const [visibleSchedulesSelection, setVisibleSchedulesSelection] = useState(false);
  const [visibleInstallationModal, setVisibleInstallationModal] = useState(false);
  const schedules = Form.useWatch(['schedules'], form);
  const programSponsorId = useMemo(
    () => application?.applicant?.position?.programSponsorId,
    [application?.applicant?.position?.programSponsorId],
  );

  const allSchedules = useMemo(() => {
    return (
      application?.children?.reduce?.(
        (prev, child) => prev.concat(child.schedules?.map?.((sch) => ({ ...sch, child })) || []),
        [],
      ) || []
    );
  }, [application?.children]);

  const selectedProviderId = useMemo(() => schedules?.[0]?.careFacility?.id, [schedules]);

  const childrenOptions = useMemo(
    () =>
      application?.children?.map?.((child) => ({
        id: child.id,
        firstName: child.firstName,
        lastName: child.lastName,
        schedules: child.schedules || [],
        dateOfBirth: child.dateOfBirth,
      })) || [],
    [application?.children],
  );
  const selectedChild = Form.useWatch(['child'], form);
  const selectedChildSchedules = useMemo(
    () => (selectedChild?.schedules || []).map((sch) => ({ ...sch, child: selectedChild })) || [],
    [selectedChild],
  );
  const selectedSchedules = Form.useWatch(['schedules'], form);

  const { loading: submitting, execute: onSubmit } = useAsyncCallback(
    async (values) => {
      try {
        const payload = {
          ...values,
          application: { id: application.id },
          applicationId: application.id,
        };
        if (certificateId) {
          await familyService.updateCertificate(certificateId, payload);
          message.success('Certificate updated successfully.', 5);
          history.push(next);
        } else {
          const { id: newCertificateId } = await familyService.createCertificate({
            ...payload,
            application: { id: application.id },
            careFacilityId: selectedProviderId,
            programId: application?.program?.id,
            countryId: 123,
            documentId: 123,
            household: {
              id: householdId,
            },
          });
          history.push(`/families/${householdId}/applications/${id}/review/certificates/${newCertificateId}/summary`);
          message.success('Certificate created successfully.', 5);
        }
      } catch (error) {
        message.error(`Unable to ${certificateId ? 'update' : 'create'} certificate.`, 5);
        newrelic.noticeError(error);
      }
    },
    [certificateId, householdId],
  );

  useEffect(() => {
    let formData = {};
    if (certificate?.id) {
      formData = { ...certificate };
      const child = allSchedules.find((sch) => certificate.schedules.some((s) => s.id === sch.id))?.child;
      formData.child = child;
    }
    form.setFieldsValue({
      programSponsorId: application?.applicant?.position?.programSponsorId,
      militaryStatusId: application?.applicant?.position?.militaryStatusId,
      militaryInstallationId: application?.applicant?.position?.militaryInstallationId,
      // programType: program?.type,
      ...formData,
    });
  }, [
    allSchedules,
    application?.applicant?.position?.militaryInstallationId,
    application?.applicant?.position?.militaryStatusId,
    application?.applicant?.position?.programSponsorId,
    certificate,
    form,
  ]);
  return (
    <Form form={form} className="pb-6" onFinish={onSubmit}>
      <Section
        heading="Child(ren)'s Schedule of Care and Provider"
        headerContainerClassName="!px-4 py-4"
        triggerClassName="!top-3 !right-3"
        contentClassName="px-6"
      >
        <div>
          <Form.Item
            label="Select Child(ren)"
            name={['child']}
            rules={[{ required: true, message: 'Select at least one child' }]}
          >
            <Select
              placeholder="Select Child"
              ariaLabel="Select Child"
              options={childrenOptions}
              getOptionLabel={(option) =>
                option.firstName
                  ? `${getFullName(option)}${option.dateOfBirth ? ` (${formatDate(option.dateOfBirth)})` : ''}`
                  : 'N/A'
              }
              isLoading={applicationLoading}
              menuPlacement="bottom"
            />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-8 mb-5 items-center">
          <Button onClick={() => setVisibleSchedulesSelection(true)}>Change Schedule of Care</Button>
          {/* {certificateStartDate}
          {certificateEndDate} */}
          {/* <Button onClick={() => setVisibleSchedulesSelection(true)}>Add/Remove Schedule</Button> */}
        </div>
        {/* <div className="mt-4 [&_.ant-table]:!min-h-[100px]">
                  <ScheduleCareTable />
                </div> */}
        <Form.Item name="schedules" rules={[{ required: true, message: 'Select at least one schedule of care' }]}>
          <SchedulesTable data={selectedSchedules} selectedProviderId={selectedProviderId} />
        </Form.Item>
        <div className="w-full md:w-1/2 xxl:w-1/3">
          <Form.Item name="installationId" label="Installation">
            <SponsorInstallationModal
              visible={visibleInstallationModal}
              setVisible={setVisibleInstallationModal}
              parentForm={form}
              programSponsorId={programSponsorId}
              buttonClassName="w-full"
              showSelect
            />
          </Form.Item>
        </div>
        <EditCertificateScheduleModal
          visible={visibleSchedulesSelection}
          setVisible={setVisibleSchedulesSelection}
          allSchedules={selectedChildSchedules}
          application={application}
          certificateForm={form}
        />
      </Section>
      <div className="px-6 pt-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
        <Button
          onClick={() => history.push(`/families/${householdId}/applications/${application.id}`)}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button onClick={() => history.push(prev)} disabled={submitting}>
          Back
        </Button>
        <Button type="primary" htmlType="submit" disabled={submitting} loading={submitting}>
          Next
        </Button>
      </div>
    </Form>
  );
}
