import { useMemo } from 'react';
import { Button, message, Spin, InputNumber, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';

import Form from 'components/Form';
import ApplicationNavBar from 'components/ApplicationNavBar';
import { getFamilyApplicationReviewNavItems } from 'utils';
import familyService from 'services/family.service';
import { AsyncSelect } from 'components/Select';
import { actions } from 'features/family';
import { selectors } from 'features/family';
import useBreakpoints from 'hooks/useBreakpoints';
import { ENTITY_TYPES } from 'constants/index';

export default function FinalizeReview(props) {
  const application = useSelector(selectors.selectApplication);
  const { householdId } = props;
  const breakpoints = useBreakpoints();
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const applicationStatus = Form.useWatch(['status'], form);

  const { loading: savingApplicationLoading, execute: saveApplication } = useAsyncCallback(
    async (payload) => {
      try {
        const data = await familyService.saveApplicationStatus(id, payload.status.id, payload.statusChangeReason?.id);
        if (payload.notes && id) {
          await familyService.saveNote(id, { content: payload.notes, draft: false });
        }
        message.success('Application Status updated.', 5);
        dispatch(actions.setApplication(data));
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to update application status.', 5);
      }
    },
    [id],
  );

  const navItems = useMemo(
    () =>
      getFamilyApplicationReviewNavItems({
        id,
        householdId,
        application,
        selectedParent: application?.additionalParents?.[0],
        selectedChild: application?.children?.[0],
      }),
    [application, householdId, id],
  );

  return (
    <Spin spinning={false}>
      <ApplicationNavBar
        breakpoints={breakpoints}
        items={navItems}
        entity={application}
        entityType={ENTITY_TYPES.HOUSEHOLD}
        toMail={''}
        className="bg-light-bg"
        hideEmail
      />

      <Form className="p-6 bg-white m-6" layout="vertical" form={form} onFinish={saveApplication}>
        <h3 className="text-primary font-bold text-base mb-4">Finalize Review</h3>
        <p className="text-base mb-6">
          Current Status:{' '}
          <span className="text-xl font-semibold">{application?.status?.status?.description || 'N/A'}</span>
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 items-center">
          <Form.Item
            name={['status']}
            label="New Status"
            className="w-full md:w-1/2 xl:w-1/3"
            rules={[{ required: true, message: 'Application Status is required.' }]}
          >
            <AsyncSelect
              isOptionSelected={(opt, [value]) => {
                return opt.id === value?.id;
              }}
              getOptionValue={(opt) => opt.id}
              apiPrefix="/families"
              optionsApiUrl={`/applications/${id}/application-status-types/internal`}
              isOptionDisabled={(opt) => opt.systemStatus}
              getOptionLabel={(opt) => opt.description}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: applicationStatus?.reasonRequired, message: 'Application Status is required.' }]}
            name={['statusChangeReason']}
            label="Reason for Status Change"
            className="w-full md:w-1/2 xl:w-1/3"
          >
            <AsyncSelect
              optionsApiUrl={`/applications/status-change-reasons?statusId=${applicationStatus?.id}`}
              apiPrefix="/families"
              key={applicationStatus?.id}
              getOptionValue={(opt) => opt.id}
            />
          </Form.Item>
          <Form.Item
            name="notes"
            label="Status change Notes"
            className="w-full md:w-1/2 xl:w-1/3"
            rules={[{ required: true, message: 'Notes are required.' }]}
          >
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="Write Note Here" autoComplete="off" />
          </Form.Item>
          <Form.Item name="id" hidden>
            <InputNumber />
          </Form.Item>
        </div>
        <div className="p4-6 pt-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
          <Button onClick={() => history.goBack()} disabled={savingApplicationLoading}>
            Back
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={savingApplicationLoading}
            loading={savingApplicationLoading}
          >
            Save and Finish
          </Button>
        </div>
      </Form>
    </Spin>
  );
}
