import React, { useEffect, useContext, useMemo } from 'react';
import { Button, Col, Input, InputNumber, message, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { sleep, breakpointsContext, copy, getValue, SnapshotContext } from 'utils';
import { ADD_FACILITY_STEPS, AM_PM_TIME_FORMAT_REGEX, FACILITY_DETAILS_TABS, TIME_FORMAT } from 'constants/index';
import providerService from 'services/providers.service';
import Form from 'components/Form';
import Section from 'components/Section';
import FormItem from 'components/FormItem';
import TimePicker from 'components/GccTimePicker';
import SwitchField from 'components/Switch/SwitchField';
import useProviderEditDisabled from 'hooks/useProviderEditDisabled';
import { BsBoxArrowDown } from 'react-icons/bs';

const filter = (item) => (item.openingTime && item.closingTime) || item.overnightCare || item.twentyFourHourCare;

export default function Schedule({
  setTab,
  onCancel,
  setOpenTabs,
  center,
  actions,
  isFormSubmitting,
  loading,
  setStep,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isSubmitDisabled = useProviderEditDisabled();
  const hoursOfOperation = useMemo(() => {
    let hoursOfOperation = center?.hoursOfOperation || [];
    const hasId = hoursOfOperation.some((item) => item.id);
    if (hasId) {
      hoursOfOperation = hoursOfOperation.filter((item) => {
        return item.id;
      });
    }
    return hoursOfOperation.map((item) => {
      const data = { ...item };
      if (item.openingTime) {
        data.openingTime = dayjs(item.openingTime, TIME_FORMAT.HOURS_MINUTES).format(TIME_FORMAT.HOURS_MINUTES_AM_PM);
      }
      if (item.closingTime) {
        data.closingTime = dayjs(item.closingTime, TIME_FORMAT.HOURS_MINUTES).format(TIME_FORMAT.HOURS_MINUTES_AM_PM);
      }
      return data;
    });
  }, [center?.hoursOfOperation]);

  const standardHours = useMemo(() => hoursOfOperation?.filter((item) => item.type === 'REGULAR'), [hoursOfOperation]);
  const beforeSchoolCareHours = useMemo(
    () => hoursOfOperation?.filter((item) => item.type === 'BEFORE_SCHOOL'),
    [hoursOfOperation],
  );
  const afterSchoolCareHours = useMemo(
    () => hoursOfOperation?.filter((item) => item.type === 'AFTER_SCHOOL'),
    [hoursOfOperation],
  );
  const respiteCareHours = useMemo(
    () => hoursOfOperation?.filter((item) => item.type === 'RESPITE'),
    [hoursOfOperation],
  );

  useEffect(() => {
    const beforeSchoolCare = beforeSchoolCareHours?.some(filter);
    const afterSchoolCare = afterSchoolCareHours?.some(filter);
    const respiteCare = respiteCareHours?.some(filter);
    const weekendCareProvidedstandardHours = standardHours?.slice(5, 7)?.some(filter);
    const weekendCareProvidedrespiteCareHours = respiteCareHours?.slice(5, 7)?.some(filter);
    form.setFieldsValue({
      ...center,
      standardHours,
      beforeSchoolCareHours,
      afterSchoolCareHours,
      respiteCareHours,
      beforeSchoolCare,
      afterSchoolCare,
      respiteCare,
      weekendCareProvidedstandardHours,
      weekendCareProvidedrespiteCareHours,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, standardHours, beforeSchoolCareHours, afterSchoolCareHours, respiteCareHours]);

  const resetValues = (values) => {
    return values.map((item) => {
      const data = { ...item };
      data.openingTime = null;
      data.closingTime = null;
      data.overnightCare = false;
      data.twentyFourHourCare = false;
      return data;
    });
  };

  return (
    <div>
      <Spin spinning={isFormSubmitting || loading}>
        <Form
          form={form}
          validateFirst
          layout="vertical"
          onValuesChange={(changedValues, allValues) => {
            const keys = ['standardHours', 'beforeSchoolCareHours', 'afterSchoolCareHours', 'respiteCareHours'];
            const key = keys.find((key) => changedValues[key]);
            if (key) {
              let schedule = changedValues[key];
              const indexToUpdate = schedule.length - 1;
              const { twentyFourHourCare } = schedule[indexToUpdate];
              if (twentyFourHourCare) {
                schedule = allValues[key];
                schedule = schedule.map((item, index) => {
                  if (index === indexToUpdate) {
                    return {
                      ...item,
                      overnightCare: true,
                      twentyFourHourCare: true,
                    };
                  }
                  return item ?? {};
                });
                form.setFieldsValue({ [key]: schedule });
              }
            }
          }}
          onFinish={async (values) => {
            dispatch(actions.setIsFormSubmitting(true));
            try {
              let {
                standardHours: standardCareHours = standardHours,
                beforeSchoolCareHours: beforeSchool = values.beforeSchoolCareHours || beforeSchoolCareHours,
                afterSchoolCareHours: afterSchool = values.afterSchoolCareHours || afterSchoolCareHours,
                respiteCareHours: respiteCare = values.respiteCareHours || respiteCareHours,
                beforeSchoolCare,
                afterSchoolCare,
                respiteCare: respiteCareEnabled,
              } = values;
              if (!beforeSchoolCare) {
                beforeSchool = resetValues(beforeSchoolCareHours);
              }
              if (!afterSchoolCare) {
                afterSchool = resetValues(afterSchoolCareHours);
              }

              if (!respiteCareEnabled) {
                respiteCare = resetValues(respiteCareHours);
              }

              const isWeekendIncluded = standardCareHours.slice(5, 7).some(filter);

              if (!values?.weekendCareProvidedstandardHours) {
                const resetArray = resetValues(standardHours.slice(5, 7));
                standardCareHours.splice(5, 2, ...resetArray);
              }
              if (values?.weekendCareProvidedstandardHours && isWeekendIncluded) {
                standardCareHours.splice(5, 2, ...values.standardHours.slice(5, 7));
              }
              const isWeekendIncludedForRespite = respiteCare.slice(5, 7).some(filter);

              if (!values?.weekendCareProvidedrespiteCareHours || !isWeekendIncludedForRespite) {
                const resetArray = resetValues(respiteCareHours.slice(5, 7));
                respiteCare.splice(5, 2, ...resetArray);
              }
              if (values?.weekendCareProvidedrespiteCareHours && isWeekendIncluded) {
                respiteCare.splice(5, 2, ...values.respiteCareHours.slice(5, 7));
              }
              let hoursOfOperation = [
                ...standardCareHours,
                ...(values?.beforeSchoolCare
                  ? [...beforeSchool, ...beforeSchoolCareHours.slice(5, 7)]
                  : [...beforeSchool]),
                ...(values?.afterSchoolCare ? [...afterSchool, ...afterSchoolCareHours.slice(5, 7)] : [...afterSchool]),
                ...(values?.respiteCare && values?.respiteCareHours?.length < 6
                  ? [...respiteCare, ...values.respiteCareHours.slice(5, 7)]
                  : [...respiteCare]),
              ];

              hoursOfOperation = hoursOfOperation.map((item, index) => {
                const data = { ...center.hoursOfOperation[index], ...item };
                data.openingTime = item.openingTime
                  ? dayjs(item.openingTime, TIME_FORMAT.HOURS_MINUTES_AM_PM).format(TIME_FORMAT.HOURS_MINUTES)
                  : null;
                data.closingTime = item.closingTime
                  ? dayjs(item.closingTime, TIME_FORMAT.HOURS_MINUTES_AM_PM).format(TIME_FORMAT.HOURS_MINUTES)
                  : null;
                return data;
              });
              values.hoursOfOperation = hoursOfOperation;
              const resp = await providerService.updateCenter({ ...values }, center.id);
              dispatch(actions.setCenterInfo(resp));
              setOpenTabs(FACILITY_DETAILS_TABS.DISCOUNTS_INFO);
              await sleep(100);
              setTab(FACILITY_DETAILS_TABS.DISCOUNTS_INFO);
            } catch (error) {
              newrelic.noticeError(error);
              message.error('Unable to save center details.');
            } finally {
              dispatch(actions.setIsFormSubmitting(false));
            }
          }}
        >
          {(values) => {
            return (
              <>
                <Section
                  heading="Current Hours of Availability For Fee Assistance and Respite Care"
                  headingClassName="page-title"
                  testId="intro-section"
                  className="section-border"
                >
                  <p className="instructions -mt-2 pb-4">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </p>
                </Section>
                <Section heading="Standard Hours of Operation" testId="hoursOfOperation-section">
                  <div className="mb-5">
                    <p className="instructions mt-2">
                      Please let us know your hours of operation and any special hours you serve (ie. before school
                      hours and after school hours)
                    </p>
                  </div>
                  <OperationHours
                    enabledSchoolCare={false}
                    enabledWeekendCare
                    data={values?.standardHours || []}
                    formValues={values}
                    storedHoursOfOperation={standardHours}
                    name="standardHours"
                    type="REGULAR"
                    form={form}
                  />
                </Section>
                <Section heading="Before School Care Schedule" testId="before-section">
                  <FormItem name="beforeSchoolCare" className="pb-2 mb-0 -mt-2" valuePropName="checked">
                    <SwitchField>I offer before school care schedules.</SwitchField>
                  </FormItem>
                  {values.beforeSchoolCare && (
                    <OperationHours
                      enabledSchoolCare={true}
                      enabledWeekendCare={false}
                      data={values?.beforeSchoolCareHours || []}
                      formValues={values}
                      storedHoursOfOperation={beforeSchoolCareHours}
                      name="beforeSchoolCareHours"
                      type="BEFORE_SCHOOL"
                      form={form}
                    />
                  )}
                </Section>
                <Section heading="After School Care Schedule" testId="afterSchool-section">
                  <FormItem name="afterSchoolCare" className="pb-2 mb-0 -mt-2" valuePropName="checked">
                    <SwitchField>I offer after school care schedules.</SwitchField>
                  </FormItem>
                  {values.afterSchoolCare && (
                    <OperationHours
                      enabledSchoolCare={true}
                      enabledWeekendCare={false}
                      data={values?.afterSchoolCareHours || []}
                      formValues={values}
                      storedHoursOfOperation={afterSchoolCareHours}
                      name="afterSchoolCareHours"
                      type="AFTER_SCHOOL"
                      form={form}
                    />
                  )}
                </Section>
                <Section heading="Current Hours of Availability for Respite Care" testId="respite-section">
                  <FormItem name="respiteCare" valuePropName="checked" className="pb-2 mb-0 -mt-2">
                    <SwitchField>I want to add EFM Respite Care current hours of availability separately.</SwitchField>
                  </FormItem>

                  {values.respiteCare && (
                    <OperationHours
                      enabledSchoolCare={false}
                      enabledWeekendCare
                      data={values?.respiteCareHours || []}
                      formValues={values}
                      storedHoursOfOperation={respiteCareHours}
                      name="respiteCareHours"
                      type="RESPITE"
                      form={form}
                    />
                  )}
                </Section>
                <FormItem
                  className="py-6 px-5 md:px-7"
                  name="scheduleNotes"
                  label="Please list special schedule considerations here."
                >
                  <Input.TextArea placeholder="List times here." autoSize={{ minRows: 5 }} />
                </FormItem>
                <div className="actions flex p-6">
                  <Button data-testid="cancel-button" onClick={onCancel} disabled={isFormSubmitting}>
                    Cancel
                  </Button>
                  <Button data-testid="reset-button" onClick={() => form.resetFields()} disabled={isFormSubmitting}>
                    Clear
                  </Button>
                  <Button onClick={() => setStep(ADD_FACILITY_STEPS.DETAILS, FACILITY_DETAILS_TABS.CENTER_DETAILS)}>
                    Back
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    data-testid="submit-schedule"
                    loading={isFormSubmitting}
                    disabled={isFormSubmitting || isSubmitDisabled}
                  >
                    Save and Next
                  </Button>
                </div>
              </>
            );
          }}
        </Form>
      </Spin>
    </div>
  );
}

export const OperationHours = ({
  data,
  formValues,
  storedHoursOfOperation,
  enabledWeekendCare,
  enabledSchoolCare,
  name,
  type,
  openingTimeKey,
  closingTimeKey,
  weekendSwitchLabel = 'We offer Weekend Care.',
  hideTwentyFourHourCare = false,
  format = TIME_FORMAT.HOURS_MINUTES,
  form,
}) => {
  const { xs, md, sm } = useContext(breakpointsContext);
  const openingTimeCopy = useMemo(() => {
    return data.find((item) => {
      return dayjs(item[openingTimeKey], 'hh:mm A', true).isValid() && item.dayOfWeek === 'Monday';
    });
  }, [data, openingTimeKey]);
  const closingTimeCopy = useMemo(() => {
    return data.find((item) => dayjs(item[closingTimeKey], 'hh:mm A', true).isValid() && item.dayOfWeek === 'Monday');
  }, [closingTimeKey, data]);
  // const overnightCareCopy = useMemo(() => {
  //   return data.find((item) => item.overnightCare && item.dayOfWeek !== 'Monday');
  // }, [data]);
  // const twentyFoursHourCopy = useMemo(() => {
  //   return data.find((item) => item.twentyFourHourCare && item.dayOfWeek !== 'Monday');
  // }, [data]);
  return (
    <div className="pb-4">
      {md && (
        <Row
          gutter={20}
          className="md:h-10 flex flex-col space-y-4 md:space-y-0 md:flex md:items-center md:flex-row mb-2"
        >
          <Col xs={24} sm={6} md={4} className="flex items-center">
            <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 mt-2 md:mt-0 font-medium pl-5">
              Day
            </p>
          </Col>
          <Col xs={24} sm={3} md={4} className="flex md:justify-center">
            {!enabledSchoolCare && !hideTwentyFourHourCare && (
              <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 mt-2 md:mt-0 font-medium">
                24 Hour care
                {/* <CopyControl
                  source={twentyFoursHourCopy}
                  name={name}
                  field="twentyFourHourCare"
                  data={data}
                  form={form}
                /> */}
              </p>
            )}
          </Col>
          <Col xs={24} sm={5} md={7} className="flex items-center">
            <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 mt-2 md:mt-0 font-medium">
              Start Time
              <CopyControl source={openingTimeCopy} name={name} field={openingTimeKey} data={data} form={form} />
            </p>
          </Col>
          <Col xs={24} sm={5} md={7} className="flex items-center">
            <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 mt-2 md:mt-0 font-medium">
              End Time
              <CopyControl source={closingTimeCopy} name={name} field={closingTimeKey} data={data} form={form} />
            </p>
          </Col>
          <Col xs={24} sm={5} md={2} className="flex md:justify-end">
            {!enabledSchoolCare && (
              <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 mt-2 md:mt-0 font-medium">
                Overnight Care
                {/* <CopyControl source={overnightCareCopy} name={name} field="overnightCare" data={data} form={form} /> */}
              </p>
            )}
          </Col>
        </Row>
      )}
      {data.slice(0, 5).map((item, index) => (
        <OperationHoursItem
          key={item.dayOfWeek}
          {...item}
          xs={xs}
          md={md}
          sm={sm}
          index={index}
          formValues={formValues}
          storedHoursOfOperation={storedHoursOfOperation?.[index]}
          enabledSchoolCare={enabledSchoolCare}
          name={name}
          type={type}
          openingTimeKey={openingTimeKey}
          closingTimeKey={closingTimeKey}
          hideTwentyFourHourCare={hideTwentyFourHourCare}
          format={format}
        />
      ))}
      {enabledWeekendCare && (
        <FormItem
          name={[`weekendCareProvided${name}`]}
          valuePropName="checked"
          className={classNames('pt-4 mb-0', { 'pb-4': enabledWeekendCare })}
        >
          <SwitchField>{weekendSwitchLabel}</SwitchField>
        </FormItem>
      )}
      {enabledWeekendCare &&
        formValues?.[`weekendCareProvided${name}`] &&
        data?.slice(5, 7).map((item, index) => (
          <OperationHoursItem
            // key={item.dayOfWeek}
            {...item}
            xs={xs}
            md={md}
            sm={sm}
            index={index + 5}
            formValues={formValues}
            storedHoursOfOperation={storedHoursOfOperation?.[index + 5]}
            weekendCare
            enabledSchoolCare={enabledSchoolCare}
            name={name}
            type="type"
            openingTimeKey={openingTimeKey}
            closingTimeKey={closingTimeKey}
            hideTwentyFourHourCare={hideTwentyFourHourCare}
            format={format}
          />
        ))}
    </div>
  );
};

OperationHours.propTypes = {
  data: PropTypes.array,
};

const CopyControl = ({ name, source, field, data, form }) => {
  return source ? (
    <Button
      className="icon-btn ml-6"
      onClick={() => {
        const newHours = data.map((item) => {
          return {
            ...item,
            [field]: source[field],
          };
        });
        form.setFieldsValue({ [name]: newHours });
      }}
      icon={<BsBoxArrowDown size={20} className="text-gray-400  cursor-pointer" title="Autofill column" />}
    ></Button>
  ) : null;
};

const keys = ['id', 'fullDayCare', 'twentyFourHourCare', 'openingTime', 'closingTime', 'overnightCare'];

const OperationHoursItem = ({
  dayOfWeek,
  index,
  xs,
  md,
  sm,
  formValues,
  weekendCare,
  enabledSchoolCare,
  name,
  type,
  openingTimeKey = 'openingTime',
  closingTimeKey = 'closingTime',
  hideTwentyFourHourCare = false,
  format,
}) => {
  const { snapshotSelector } = useContext(SnapshotContext);
  const snapshot = useSelector(snapshotSelector || (() => null));

  const bgColor = index % 2 === 0 ? 'bg-blue-700 bg-opacity-5' : 'bg-blue-700 bg-opacity-2';
  const bgColorWeekend = index % 2 === 0 ? 'bg-gray-700 bg-opacity-3' : 'bg-gray-700 bg-opacity-6';
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, validateFields }) => {
        let className = '';
        if (snapshot) {
          const curr = copy(getFieldValue([name, index]), keys);
          const prev = copy(
            getValue(snapshot, 'hoursOfOperation').find((v) => v.id === curr.id),
            keys,
          );
          curr.openingTime = dayjs.isDayjs(curr[openingTimeKey])
            ? dayjs(curr.openingTime).format(TIME_FORMAT.HOURS_MINUTES)
            : curr.openingTime;
          curr.closingTime = dayjs.isDayjs(curr[closingTimeKey])
            ? dayjs(curr.closingTime).format(TIME_FORMAT.HOURS_MINUTES)
            : curr.closingTime;
          const isSame = isEqual(curr, prev);
          className = classNames({
            'change-request-item': !isSame,
          });
        }
        return (
          <div className="w-full px-[10px]">
            <Form.Item name={[name, index, 'type']} initialValue={type} hidden>
              <Input value={type} />
            </Form.Item>
            <Row
              gutter={[20, 0]}
              className={classNames(
                'md:h-20 flex flex-col space-y-4 md:space-y-0 md:flex md:items-center md:flex-row mb-0.5 px-4',
                className ? className : formValues.weekendCareProvided && weekendCare ? bgColorWeekend : bgColor,
              )}
              id={`${dayOfWeek}-row`}
            >
              <div className="hidden">
                <Form.Item name={[name, index, 'id']}>
                  <InputNumber />
                </Form.Item>
              </div>
              <div>
                <Form.Item name={[name, index, 'dayOfWeek']} hidden initialValue={dayOfWeek}>
                  <Input value={dayOfWeek} />
                </Form.Item>
              </div>
              <Col xs={24} sm={24} md={4} className="flex items-center">
                {(xs || sm) && !md && (
                  <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 mt-2 mr-2 md:mt-0 font-medium">
                    Day of Week:{' '}
                  </p>
                )}
                <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 mt-2 md:mt-0 font-medium">
                  {dayOfWeek}
                </p>
              </Col>
              {(xs || sm) && !md ? (
                <Col xs={24} sm={24} md={4} className="flex">
                  {!enabledSchoolCare && (
                    <FormItem name={[name, index, 'fullDayCare']} className="py-2 mb-0" valuePropName="checked">
                      <SwitchField
                        switchProps={{ showLabels: false, className: 'mt-2' }}
                        textClassName="-ml-2"
                        className="flex-col-reverse space-y-0"
                      >
                        {(xs || sm) && !md ? (
                          <span className="uppercase font-medium text-sm text-gray-500">24 Hour Care:</span>
                        ) : (
                          ''
                        )}
                      </SwitchField>
                    </FormItem>
                  )}
                </Col>
              ) : (
                <Col xs={24} sm={4} md={4} className="flex">
                  {!enabledSchoolCare && !hideTwentyFourHourCare && (
                    <Form.Item name={[name, index, 'twentyFourHourCare']} className="py-2 mb-0" valuePropName="checked">
                      <SwitchField switchProps={{ showLabels: false }} className="min-w-max" />
                    </Form.Item>
                  )}
                </Col>
              )}
              <Col xs={24} sm={24} md={7} className="flex">
                <Form.Item
                  name={[name, index, openingTimeKey]}
                  className="w-full max-w-[200px] self-center md:mt-5 md:ml-2"
                  dependencies={[
                    [name, index, closingTimeKey],
                    [name, index, 'overnightCare'],
                  ]}
                  label={
                    (xs || sm) && !md ? (
                      <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 md:mt-0 font-medium">
                        Start Time:
                      </p>
                    ) : undefined
                  }
                  rules={[
                    // { required: true, message: 'Please select start time' },
                    ({ getFieldValue }) => {
                      return {
                        validator(_, value) {
                          try {
                            if (value === undefined) {
                              value = '';
                            }
                            if (value && !dayjs(value, 'hh:mm A', true).isValid()) {
                              return Promise.reject('Invalid time.');
                            }
                            const closingTime = getFieldValue([name, index, closingTimeKey]);
                            if (!closingTime) {
                              return Promise.resolve();
                            }
                            if (closingTime) {
                              if (!value) {
                                return Promise.reject('Start Time cannot be empty.');
                              }
                              if (!AM_PM_TIME_FORMAT_REGEX.test(value)) {
                                return Promise.reject('Invalid time.');
                              }
                            }

                            const overnight = getFieldValue([name, index, 'overnightCare']);
                            if (overnight) {
                              return Promise.resolve();
                            }
                            const today = dayjs().startOf('day').format('YYYY-MM-DD');
                            const start = dayjs(`${today} ${value}`);
                            const end = dayjs(`${today} ${closingTime}`);

                            if (end.isSameOrBefore(start)) {
                              return Promise.reject('Start Time must be before End Time.');
                            }
                            return Promise.resolve();
                          } catch (e) {}
                        },
                      };
                    },
                  ]}
                >
                  <TimePicker
                    format={format}
                    className="w-full max-w-[200px] self-center"
                    onClear={() => validateFields([[name, index, closingTimeKey]])}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={7} className="flex">
                <Form.Item
                  name={[name, index, closingTimeKey]}
                  className="w-full max-w-[200px] self-center md:mt-5 md:ml-2"
                  dependencies={[name, index, openingTimeKey, [name, index, 'overnightCare']]}
                  label={
                    (xs || sm) && !md ? (
                      <p className="h-8 flex items-center  md:flex-1 uppercase text-sm text-gray-500 md:mt-0 font-medium">
                        End Time:{' '}
                      </p>
                    ) : undefined
                  }
                  rules={[
                    ({ getFieldValue }) => {
                      return {
                        validator(_, value) {
                          if (value === undefined) {
                            value = '';
                          }
                          const openingTime = getFieldValue([name, index, openingTimeKey]);
                          if (openingTime) {
                            if (!value) {
                              return Promise.reject('End Time cannot be empty.');
                            }
                            if (!AM_PM_TIME_FORMAT_REGEX.test(value)) {
                              return Promise.reject('Invalid time.');
                            }
                          }
                          const overnight = getFieldValue([name, index, 'overnightCare']);
                          if (overnight) {
                            return Promise.resolve();
                          }

                          const today = dayjs().startOf('day').format('YYYY-MM-DD');
                          const start = dayjs(`${today} ${openingTime}`);
                          const end = dayjs(`${today} ${value}`);
                          if (!openingTime) {
                            return Promise.resolve();
                          }
                          if (openingTime && !value) {
                            return Promise.reject('End Time cannot be empty.');
                          }
                          if (start.isSameOrAfter(end)) {
                            return Promise.reject('End Time must be after Start Time.');
                          }
                          return Promise.resolve();
                        },
                      };
                    },
                  ]}
                >
                  <TimePicker
                    format={format}
                    className="w-full max-w-[200px] self-center"
                    onClear={() => validateFields([[name, index, openingTimeKey]])}
                  />
                </Form.Item>
              </Col>
              {(xs || sm) && !md ? (
                <Col xs={24} sm={24} md={4} className="flex">
                  {!enabledSchoolCare && (
                    <Form.Item name={[name, index, 'overnightCare']} className="py-2 mb-0" valuePropName="checked">
                      <SwitchField
                        switchProps={{ showLabels: false, className: 'mt-2 md:-ml-16' }}
                        className="flex-col-reverse"
                      >
                        {(xs || sm) && !md ? (
                          <span className="uppercase font-medium text-sm text-gray-500">Overnight Care:</span>
                        ) : (
                          ''
                        )}
                      </SwitchField>
                    </Form.Item>
                  )}
                </Col>
              ) : (
                <Col xs={24} sm={12} md={2} className="flex md:justify-start">
                  {!enabledSchoolCare && (
                    <Form.Item name={[name, index, 'overnightCare']} className="py-2 mb-0" valuePropName="checked">
                      <SwitchField switchProps={{ showLabels: false }} className="min-w-max" />
                    </Form.Item>
                  )}
                </Col>
              )}
            </Row>
          </div>
        );
      }}
    </Form.Item>
  );
};

OperationHours.propTypes = {
  data: PropTypes.array,
};

OperationHoursItem.propTypes = {
  closingTime: PropTypes.any,
  dayOfWeek: PropTypes.string,
  openingTime: PropTypes.any,
  index: PropTypes.number,
  xs: PropTypes.bool,
};
