import React from 'react';
import { Button, Progress } from 'antd';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { CgFileDocument } from 'react-icons/cg';

import { ROLES } from 'constants/index';
import { selectors } from 'features/add-center';
import { selectActiveRole } from 'features/auth';
import { b64toBlob, getBase64String } from 'utils';
import filesService from 'services/files.service';
import { Close, Download } from '@carbon/icons-react';

export default function useAttachmentFile({ id, file, percent, status, type, thumbUrl, actions, setPreview }) {
  const activeRole = useSelector(selectActiveRole);
  const appStatus = useSelector(selectors.selectInternalStatus);
  const { result: thumbnail = thumbUrl } = useAsync(
    async (file) => {
      let blob;
      if (file?.id) {
        blob = await filesService.getS3File(file);
      } else {
        blob = await getBase64String(file.originFileObj);
        blob = await b64toBlob(blob);
      }
      return URL.createObjectURL(blob);
    },
    [file],
  );

  let preview;
  if (status === 'uploading') {
    preview = (
      <Progress
        type="circle"
        percent={percent}
        size="small"
        status={percent === 100 ? 'success' : 'active'}
        className="[&_.ant-progress-inner]:!w-7 [&_.ant-progress-inner]:!h-7"
      />
    );
  } else if (status === 'error') {
    preview = (
      <Progress
        type="circle"
        percent={100}
        size="small"
        status="exception"
        className="[&_.ant-progress-inner]:!w-7 [&_.ant-progress-inner]:!h-7"
      />
    );
  } else {
    preview = /image\/*/.test(type) ? (
      <img className="h-8 w-8 object-cover overflow-hidden rounded-sm" src={thumbnail} alt="thumbnail" />
    ) : (
      <CgFileDocument size={28} className="text-primary" />
    );
    if (/image\/*/.test(type) || type === 'application/pdf') {
      preview = (
        <span
          tabIndex="0"
          className="flex items-center space-x-3 cursor-pointer"
          onClick={() => setPreview(file, thumbnail)}
          onKeyDown={(e) => {
            /* istanbul ignore else */
            if (e.key === 'Enter' || e.key === 'Space') {
              e.preventDefault();
              setPreview(file, thumbnail);
            }
          }}
        >
          {preview}
        </span>
      );
    }
  }

  const downloadbtn = (
    <Button icon={<Download />} aria-label="Download File" title="Download" onClick={actions.download} />
  );

  let removeBtn = (
    <Button
      onClick={async (e) => {
        actions.remove(file);
      }}
      icon={<Close size={24} />}
      aria-label="Remove File"
      title="Remove"
      data-testid={`remove-document-${file.index}`}
      disabled={activeRole === ROLES.PROVIDER && appStatus?.order > 2 && Number.isInteger(id) && id > 0}
    />
  );
  return { preview, thumbnail, actions, removeBtn, downloadbtn, status };
}
