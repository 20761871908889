import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import ReactSelect, { components as rsComponents } from 'react-select';

const { IndicatorSeparator, DropdownIndicator, ClearIndicator } = rsComponents;

const Select = React.forwardRef(
  (
    {
      id,
      name,
      value,
      options: optionsProp,
      ariaLabel,
      onChange,
      theme,
      hideDropdownIcon,
      disabled,
      hideSeparator,
      components,
      onChangeFormatter,
      getOptionValue,
      getOptionLabel,
      isOptionSelected,
      isOptionDisabled,
      isMulti,
      isClearable = true,
      isSearchable = true,
      readOnly,
      menuIsOpen,
      optionFilter = () => true,
      inputProps,
      ...rest
    },
    ref,
  ) => {
    const options = useMemo(() => Array.from(optionsProp || []), [optionsProp]).filter(optionFilter);
    const values = isMulti
      ? options.filter((opt) => isOptionSelected(opt, value || []))
      : options.find((opt) => isOptionSelected(opt, [value]));
    return (
      <ReactSelect
        key={value}
        aria-label={ariaLabel}
        classNamePrefix="select"
        id={`select-container-${id}`}
        inputId={id}
        name={name}
        menuPlacement="auto"
        options={
          typeof isOptionDisabled === 'function'
            ? options.map((opt) => ({ ...opt, isDisabled: isOptionDisabled(opt) }))
            : options
        }
        value={values}
        onChange={(value) => {
          onChange(onChangeFormatter(value));
        }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isOptionSelected={isOptionSelected}
        isMulti={isMulti}
        theme={theme}
        isDisabled={disabled}
        isSearchable={isSearchable || !readOnly}
        isClearable={readOnly ? !readOnly : isClearable}
        menuIsOpen={readOnly ? !readOnly : menuIsOpen}
        inputProps={{ ...inputProps, readonly: readOnly }}
        readOnly={readOnly}
        components={{
          ...(components || {}),
          IndicatorSeparator: (props) => {
            if (hideSeparator) return null;
            if (components?.IndicatorSeparator) {
              return <components.IndicatorSeparator {...props} />;
            }
            return <IndicatorSeparator {...props} />;
          },
          DropdownIndicator: (props) => {
            if (hideDropdownIcon) return null;
            if (components?.DropdownIndicator) {
              return <components.DropdownIndicator {...props} />;
            }
            return <DropdownIndicator {...props} />;
          },
          ClearIndicator: (props) => {
            if (!isClearable) return null;
            if (components?.DropdownIndicator) {
              return <components.ClearIndicator {...props} />;
            }
            return <ClearIndicator {...props} />;
          },
        }}
        {...rest}
        styles={{
          control: (base) => ({
            ...base,
            height: 32, // Set the height you want here
            minHeight: 32,
          }),
          valueContainer: (base) => ({
            ...base,
            height: 32,
            padding: '0 6px',
          }),
          input: (base) => ({
            ...base,
            margin: 0,
            padding: 0,
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: 32,
          }),
          ...rest.styles,
        }}
      />
    );
  },
);

Select.propTypes = {
  ariaLabel: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.any,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  hideSeparator: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  styles: PropTypes.any,
  theme: PropTypes.any,
  onChangeFormatter: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isOptionSelected: PropTypes.func,
  isOptionDisabled: PropTypes.func,
  optionFilter: PropTypes.func,
};

Select.defaultProps = {
  isClearable: true,
  disabled: false,
  isMulti: false,
  onChangeFormatter: (v) => v,
  getOptionValue: (v) => v.id,
  getOptionLabel: (opt) => opt.title,
  isOptionSelected: (option, values) => values.find((val) => val?.id === option.id),
  optionFilter: () => true,
};

export default Select;
