import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { useAsyncCallback } from 'react-async-hook';

import Modal from 'components/Modal';
import SearchProviders from 'components/SearchProviders';
import SearchBar from 'components/SearchProviders/SearchBar';
import useBreakpoints from 'hooks/useBreakpoints';
import familyService from 'services/family.service';

export default function SearchProviderModal({
  visible,
  setVisible,
  selectedProviders,
  childId,
  applicationId,
  scheduleId,
}) {
  const [spinning, setSpinning] = useState(true);
  const breakpoints = useBreakpoints();
  useEffect(() => {
    const timeOut = setTimeout(() => setSpinning(false), 3000);
    return () => clearTimeout(timeOut);
  }, []);

  const { loading, execute: onProviderSelection } = useAsyncCallback(
    async (provider) => {
      try {
        const payload = { careFacility: { id: provider.id }, id: scheduleId };
        await familyService.saveSchedule(applicationId, childId, payload);
        setVisible(false);
        message.success('Provider successfully attached with the schedule.', 5);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to select a provider.', 5);
      }
    },
    [applicationId, childId],
  );
  return (
    <Modal
      destroyOnClose
      width={1700}
      visible={visible}
      setVisible={setVisible}
      bodyStyle={{ padding: 20, overflow: 'visible' }}
    >
      <Spin spinning={spinning || loading}>
        <div className="overflow-y-auto overflow-x-hidden">
          {breakpoints.lg && (
            <div className="flex justify-center items-center h-16 mb-2 bg-white">
              <SearchBar className="" isPublicSearch={false} />
            </div>
          )}
          <SearchProviders
            className=""
            selectProvider={onProviderSelection}
            selectedProvider={selectedProviders}
            isPublicSearch={false}
            heightClassName="min-h-[500px] h-[calc(100vh-150px)]"
          />
        </div>
      </Spin>
    </Modal>
  );
}
