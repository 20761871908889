import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Actions from '../../Actions';
import Section from 'components/Section';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { selectors } from 'features/family';
import ApplicationEntityReview from '../../__components/ApplicationEntityReview';

export default function SecondParentDocs({
  id,
  application,
  next,
  setStep,
  actions,
  dispatch,
  tab = '0',
  openSteps,
  onCancel,
  household,
  ...rest
}) {
  const isLoading = useSelector(selectors.selectLoading);

  const { additionalParents } = application;

  return (
    <div>
      <Spin spinning={isLoading}>
        {additionalParents?.map((_additionalParents, i) => (
          <div className={classNames('white-box pb-6', { 'mt-12': i !== 0 })}>
            <ApplicationEntityReview className="rounded-b-none" entity={_additionalParents} />

            <Section
              contentClassName="px-8"
              heading="Spouse Looking For Work"
              className="section-border"
              defaultOpen={false}
            >
              <p className="mb-6 -mt-2">Spouse Looking For Work</p>
            </Section>

            <Section
              contentClassName="px-8"
              heading="Spouse Schooling Schedule"
              className="section-border"
              defaultOpen={false}
            >
              <p className="-mt-2 mb-6">Spouse Schooling Schedule</p>
            </Section>

            <Section contentClassName="px-8" heading="Spouse Paystub" className="section-border" defaultOpen={false}>
              <p className="-mt-2 mb-6">Spouse Paystub</p>
            </Section>

            <Section
              contentClassName="px-8"
              heading="Spouse Employment Verification"
              className="section-border"
              defaultOpen={false}
            >
              <p className="mb-6 -mt-2">Spouse Employment Verification</p>
            </Section>

            <Section
              contentClassName="px-8"
              heading="Other Spouse Documents"
              className="section-border"
              defaultOpen={false}
            >
              <p className="-mt-2 mb-6">Other Spouse Documents</p>
            </Section>
          </div>
        ))}

        {/* <div className="white-box pb-6 mt-12">
          <Section
            headingClassName="page-title"
            heading="Documents"
            headerContainerClassName="pt-8"
            collapsible={false}
          >
            <Documents {...{ ...rest, id, application, next, setStep, actions, dispatch, tab, openSteps }} />
          </Section>
        </div> */}
      </Spin>

      <Actions
        onSubmit={async () => {
          next({
            paths: {
              step: ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS,
              id,
            },
          });
        }}
        onBack={() => {
          setStep(id, ADD_FAMILY_STEPS.SECOND_PARENTS);
        }}
        style={{ marginTop: '1rem' }}
      />
    </div>
  );
}
