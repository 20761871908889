import React, { useCallback } from 'react';
import { message } from 'antd';
import { useSelector } from 'react-redux';

import Form from 'components/Form';
import Section from 'components/Section';
import Actions from 'containers/Family/AddApplication/Actions';
import { parseDocumentsForServer, parseDocuments } from 'utils';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { actions as documentActions, selectors as documentSelectors } from 'features/documents';

export default function Documents({ id, application, next, setStep, dispatch, actions, onCancel }) {
  const [form] = Form.useForm();
  const { documents = {}, allDocuments } = useSelector(documentSelectors.selectDocumentsState);

  const onSubmit = useCallback(
    async (values) => {
      try {
        dispatch(actions.setIsFormSubmitting(true));
        const { allDocuments: newAllDocuments } = await parseDocumentsForServer(values, { documents, allDocuments });
        dispatch(actions.setApplication(application));
        dispatch(documentActions.setAllDocuments(newAllDocuments));
        dispatch(documentActions.setDocuments(parseDocuments(newAllDocuments)));

        next({
          paths: { step: ADD_FAMILY_STEPS.SPONSOR, id: application?.id },
        });
      } catch (error) {
        message.error(`Unable to update Sponsor documents.`);
        newrelic.noticeError(error);
      } finally {
        dispatch(actions.setIsFormSubmitting(false));
      }
    },
    [actions, allDocuments, application, dispatch, documents, next],
  );
  return (
    <div className="white-box h-full">
      <Section
        contentClassName="px-6"
        heading="Orders, Income and Additional Income documents"
        headingClassName="page-title"
        className="section-border"
        collapsible={false}
      >
        <p className="instructions mb-4">
          For all forms and documents, full social security number MUST be redacted (removed, whited out, blacked out).
          Fields marked with * are mandatory.
        </p>
      </Section>

      <div>
        <Form onFinish={onSubmit} form={form} layout="vertical">
          <Section
            contentClassName="px-6"
            heading={
              <>
                Sponsor Employment Verification <span className="text-red-500 normal-case">(required)</span>
              </>
            }
            className="section-border"
          >
            <p className="-mt-2">Upload a current copy of your military orders showing:</p>
            <ol className="list-decimal mt-4 pl-6 max-w-6xl">
              <li>
                Deployment and/or activation from the National Guard/Reserves (if you are a member of the Guard or
                Reserve, include a copy of the original orders recalling you to Activated Status).
              </li>
              <li>
                Assignment to a Warriors in Transition Unit (WTU) or Warriors in Transition Battalion (WTB) and DA 3349
                Form to verify Wounded Warrior status (South Com only).
              </li>
              <li>
                Assignment for duty as an Army Recruiter (if you are a recruiter, but your orders do not indicate that
                you are assigned to recruiting duty, please supply a memorandum from your commanding officer to verify
                your recruiter status).
              </li>
              <li>
                A current year SF-50 to verify your status as an Army, Air Force, or Marine Corps Civilian (if you do
                not have a current year SF-50, please log into your MyBiz account and print off an Employment
                Verification Form with salary included).
              </li>
              <li>
                A Commander Verification Form, yearly training schedule, and battle assembly or annual training orders,
                (if you are assigned to weekend drill for the National Guard/Reserves).
              </li>
            </ol>

            <p className="my-6">
              Please note that you may be asked to provide additional information if the orders submitted are missing
              any information that we require to determine eligibility.
            </p>
          </Section>

          <Section
            defaultOpen={false}
            contentClassName="px-6"
            heading={
              <>
                Sponsor Income Verification <span className="text-gray-500 normal-case">(optional)</span>
              </>
            }
            className="section-border"
          >
            <p className="-mt-2 mb-6">
              Upload at least one month's worth of recent, normal income (LES or CLES and Drill LES), including any
              additional pay, such as Retirement, Disability, or self-employment income earned by you alone.
            </p>
          </Section>

          <Section
            defaultOpen={false}
            contentClassName="px-6"
            heading={
              <>
                Other Sponsor Documents <span className="text-gray-500 normal-case">(optional)</span>
              </>
            }
            className="section-border"
          >
            <p className="-mt-2 mb-6">
              Upload any additional information that may be relevant to your application, such as Divorce Documents,
              Custody Agreements, and Parenting Plans.
            </p>
          </Section>

          <Actions onBack={() => setStep(id, ADD_FAMILY_STEPS.PROGRAMS)} testId={`${ADD_FAMILY_STEPS.SPONSOR}`} />
        </Form>
      </div>
    </div>
  );
}
