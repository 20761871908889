import EntityReview from 'components/EntityReview';
import useMilitaryInfo from 'hooks/useMilitaryInfo';
import { formatDate, getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';

export default function ApplicationEntityReview({ className, entity, isChild, title, description, onDelete }) {
  const { status, installation, component, branch } = useMilitaryInfo({ person: entity });

  const _fullName = [
    (entity?.prefix?.title || '').toLowerCase() !== 'prefer not to say' ? entity?.prefix?.title : null,
    getFullName(entity) || 'N/A',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <EntityReview
        className={className}
        title={title || _fullName}
        description={description}
        onDelete={onDelete}
        items={
          isChild
            ? [
                {
                  title: 'Profile',
                  items: [
                    {
                      label: 'Full Name',
                      value: _fullName,
                    },
                    {
                      label: 'Date of Birth',
                      value: formatDate(entity?.dateOfBirth),
                    },
                  ],
                },
                {
                  title: 'Contact Information',
                  items: [
                    {
                      label: 'Home Address',
                      value: getCompletedAddress(entity?.homeAddress) || 'N/A',
                    },
                  ],
                },
              ]
            : [
                {
                  title: 'Profile',
                  items: [
                    {
                      label: 'Full Name',
                      value: _fullName,
                    },
                    {
                      label: 'Date of Birth',
                      value: formatDate(entity?.dateOfBirth),
                    },
                    {
                      label: 'Marital Status',
                      value: entity?.maritalStatus?.title,
                    },
                  ],
                },
                {
                  title: 'Contact Information',
                  items: [
                    {
                      label: 'Prefered Phone',
                      value: getFormattedPhoneNumber(entity?.phone) || 'N/A',
                    },
                    {
                      label: 'Secondary Phone',
                      value: getFormattedPhoneNumber(entity?.secondaryPhone) || 'N/A',
                    },
                    {
                      label: 'Prefered Email',
                      value: entity?.email || 'N/A',
                    },
                    {
                      label: 'Secondary Email',
                      value: entity?.workEmail || 'N/A',
                    },
                    {
                      label: 'Home Address',
                      value: getCompletedAddress(entity?.homeAddress) || 'N/A',
                    },
                  ],
                },
                [
                  {
                    title: 'Military',
                    items: [
                      {
                        label: 'Branch',
                        value: branch?.title || 'N/A',
                      },
                      {
                        label: 'Component',
                        value: component?.title || 'N/A',
                      },
                      {
                        label: 'Status',
                        value: status?.title || 'N/A',
                      },
                      {
                        label: 'Grade',
                        value: entity?.position?.grade?.title || 'N/A',
                      },
                      {
                        label: 'Recruiter Type',
                        value: entity?.position?.recruiterType?.title || 'N/A',
                      },
                      {
                        label: 'Installation',
                        value: installation?.name || 'N/A',
                      },
                    ],
                  },
                  {
                    title: 'Employment',
                    items: [
                      {
                        label: 'Employment Status',
                        value: entity?.employmentStatus || 'N/A',
                      },
                      {
                        label: 'Commander Name',
                        value:
                          [entity?.position?.supervisorCommanderFn, entity?.position?.supervisorCommanderln]
                            .filter(Boolean)
                            .join(' ') || 'N/A',
                      },
                      {
                        label: 'Employment Details',
                        value: entity?.militaryEmployee ? 'Military Personnel' : entity?.employmentStatus || 'N/A',
                      },
                      {
                        label: 'Commander Phone',
                        value: getFormattedPhoneNumber(entity?.position?.supervisorCommanderPhone) || 'N/A',
                      },
                      {
                        label: 'Work Address',
                        value: getCompletedAddress(entity?.workAddress) || 'N/A',
                      },
                      {
                        label: 'Unit of Assignment',
                        value: entity?.position?.unitOfAssignment || 'N/A',
                      },
                      {
                        label: 'Unit ID Number',
                        value: entity?.position?.unitIdCode || 'N/A',
                      },
                    ],
                  },
                ],
              ]
        }
      />
    </>
  );
}
