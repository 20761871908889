import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';

import Form from 'components/Form';
import Select, { AsyncSelect } from 'components/Select';
import useEntityGroup, { GetEntityListData } from 'hooks/useEntityGroup';
import Section from 'components/Section';
import familyService from 'services/family.service';
import SwitchField from 'components/Switch/SwitchField';
import { getFullName } from 'utils';

export default function ChildFamilyRelationContainer(props) {
  const getData = useCallback(async () => {
    if (props.householdId && props.childId) {
      return familyService.getFamilyRelation(props.householdId, props.childId);
    }
  }, [props.householdId, props.childId]);

  return (
    <Form.Item name="childRelation">
      <GetEntityListData getData={getData} name="childRelation">
        <ChildFamilyRelation {...props} />
      </GetEntityListData>
    </Form.Item>
  );
}

export function ChildFamilyRelation({ value: items = [], onChange, profileData, className, childId }) {
  const nameOptions = profileData?.adults
    ?.filter?.((item) => item.id !== childId)
    ?.map?.((item) => ({ title: getFullName(item) || 'No Name', id: item.id }));
  const onAddItem = useCallback(
    async (data) =>
      await familyService.addUpdateFamilyRelationship(profileData?.id, childId, { ...data, person: { id: childId } }),
    [profileData?.id, childId],
  );
  const onDeleteItem = useCallback(
    async (item) => familyService.deleteChildFamilyRelation(profileData?.id, item.id),
    [profileData?.id],
  );
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">Relationship</span>,
        headerText: 'Relationship',
        dataIndex: 'relationshipType',
        key: 'relationship',
        ellipsis: true,
        width: 150,
        fixed: 'left',
        render: (relationship) => relationship?.title || 'N/A',
      },
      {
        title: <span className="action-header">Name</span>,
        headerText: 'Name',
        dataIndex: 'relatedPerson',
        key: 'name',
        ellipsis: true,
        width: 150,
        render: (relatedPerson) => getFullName(relatedPerson) || 'N/A',
      },
      {
        title: (
          <span className="action-header">
            Same House
            <br />
            Address
          </span>
        ),
        headerText: 'Same House Address',
        dataIndex: 'livesWith',
        key: 'isLivingAlong',
        ellipsis: true,
        width: 150,
        fixed: 'right',
        render: (livesWith) => (livesWith ? 'Yes' : 'No' || 'N/A'),
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback(
    ({ values, setVisible, items, form, item, index, addUpdateLoading }) => {
      return (
        <div>
          <h3 className="section-title">{item?.id ? 'Edit' : 'Add'} Family Relationship</h3>
          <div className="grid grid-cols-2 gap-x-8">
            <Form.Item name="relatedPerson" label="Name">
              <Select name="relatedPerson" options={nameOptions} placeholder="Select" ariaLabel="Select Person" />
            </Form.Item>
            <Form.Item name="relationshipType" label="Relationship to Adult">
              <AsyncSelect
                name={['relationshipType']}
                placeholder="Relation"
                ariaLabel="Relationship to Adult"
                optionsApiUrl="/options/26"
                apiPrefix="/families"
              />
            </Form.Item>
          </div>
          <div className=" flex flex-row justify-between">
            <span className="text-base block md:mt-3 mr-2">Do they live with this individual?</span>
            <Form.Item name={'livesWith'} valuePropName="checked">
              <SwitchField className="md:mr-16 mr-0" switchProps={{ showLabels: false }} />
            </Form.Item>
          </div>
          <div className="actions flex mt-5">
            <Button onClick={() => setVisible(false)} data-testid="cancel-family-relation-btn">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              data-testid="save-family-relation-btn"
              disabled={addUpdateLoading}
              loading={addUpdateLoading}
            >
              Save
            </Button>
          </div>
        </div>
      );
    },
    [nameOptions],
  );

  const { section } = useEntityGroup({
    items,
    onChange,
    onAddItem,
    onDeleteItem,
    entity: {},
    getAllColumns,
    getFormFields,
    // customAddButton,
    entityTitle: 'Family relationship information',
    tableProps: {
      scroll: { x: 800 },
    },
    entityName: 'childFamilyRelation',
    hideDeleteButton: true,
  });
  return (
    <div className={classNames(className)}>
      <Section heading="Family Relationship" collapsible={false} className={className}>
        <div>{section}</div>
      </Section>
    </div>
  );
}
