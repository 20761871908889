import React, { useMemo, useCallback } from 'react';
import { Button } from 'antd';

import Form from 'components/Form';
import Modal from 'components/Modal';

import SchedulesTable from './SchedulesTable';

export default function EditCertificateScheduleModal({
  visible,
  setVisible,
  data,
  application,
  householdId,
  reload,
  allSchedules,
  setSelectedKeys,
  selectedKeys,
  certificateForm,
}) {
  const [form] = Form.useForm();
  const schedules = Form.useWatch(['schedules'], form);
  const selectedRows = Form.useWatch(['selectedRows'], form);

  const selectedProviderId = useMemo(() => schedules?.[0]?.careFacilityId, [schedules]);
  const saveSelectedSchedules = useCallback(
    async (values) => {
      certificateForm.setFieldValue('schedules', selectedRows);
      setVisible(false);
    },
    [certificateForm, selectedRows, setVisible],
  );

  return (
    <Modal destroyOnClose visible={visible} setVisible={setVisible} width={1100}>
      <Form onFinish={saveSelectedSchedules} layout="vertical" form={form}>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-3">
          <div className="col-span-3">
            <Form.Item
              name="selectedRows"
              rules={[{ required: true, message: 'Select at least one schedule of care' }]}
            >
              <SchedulesTable
                data={allSchedules}
                selectedProviderId={selectedProviderId}
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
              />
            </Form.Item>
          </div>
        </div>

        <div className="actions flex">
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button data-testid="submit-request-certificate" htmlType="submit" type="primary">
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
