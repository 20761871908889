import { useEffect, useMemo, useRef, useState } from 'react';
import { Descriptions, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useAsync } from 'react-async-hook';

import Select from 'components/Select';
import familyService from 'services/family.service';
import { selectCommonStyles } from 'constants/index';

import { formatDate, getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';

export default function ChildInfoHeader({
  id,
  child,
  childId,
  application,
  householdId,
  installation,
  loading,
  headerExtraElement,
}) {
  const [selectedSibling, setSelectedSibling] = useState({});
  const [selectedEmergencyContact, setSelectedEmergencyContact] = useState({});
  const efmChild = child?.efmChild || {};
  const { loading: emergencyContactLoading, result: emergencyContact = [] } = useAsync(async () => {
    if (child.isEfmChild && childId) return await familyService.getEmergencyContact(id, childId);
    return [];
  }, [id, childId]);

  const { result: specialNeeds = [] } = useAsync(async () => {
    if (householdId && child?.id) {
      return await familyService.getChildSpecialNeeds(householdId, child?.id);
    }
    return [];
  }, [application?.children, childId, householdId]);

  const selectedSpecialNeeds = useMemo(
    () =>
      (specialNeeds || [])
        .filter((item) => item.selected)
        .map((item) => item.specialNeed.title)
        .join(','),
    [specialNeeds],
  );

  const siblingOptions = useMemo(
    () => (application?.children || []).filter((item) => item.id !== Number(childId)),
    [application?.children, childId],
  );

  const mounted = useRef(null);
  useEffect(() => {
    if (!mounted.current) {
      setSelectedSibling(siblingOptions?.[0]);
      mounted.current = true;
    }
  }, [siblingOptions]);

  const efmItems = useMemo(
    () => [
      {
        id: 'efm_expiration_date',
        title: 'EFMP Expiration Date',
        value: formatDate(efmChild.expirationDate),
      },
      {
        id: 'efm_category',
        title: 'EFMP Category',
        value: efmChild.efmCategory || 'N/A',
      },
      {
        id: 'emergency_contact_name',
        title: 'Emergency Contact Name',
        className: 'max-w-[250px] w-[250px]',
        value:
          (
            <div className="w-full">
              <Select
                id="relatedPerson"
                name="relatedPerson"
                options={emergencyContact}
                placeholder="Select"
                ariaLabel="Select Person"
                styles={selectCommonStyles}
                getOptionLabel={(item) => getFullName(item.contactPerson) || 'N/A'}
                onChange={(value) => setSelectedEmergencyContact(value)}
                value={selectedSibling}
                className="w-full"
              />
            </div>
          ) || 'N/A',
      },
      {
        id: 'emergency_contact_type',
        title: 'Emergency Contact Type',
        value: selectedEmergencyContact?.relationshipType?.title || 'N/A',
      },
      {
        id: 'emergency_contact_phone',
        title: 'Emergency Contact Phone Number',
        value: selectedEmergencyContact?.contactPerson?.phone
          ? getFormattedPhoneNumber(selectedEmergencyContact?.contactPerson?.phone)
          : 'N/A',
      },
      {
        id: 'emergency_contact_release_auth',
        title: 'Emergency Contact Release Authorization',
        value: selectedEmergencyContact?.isAuthorized ? 'Yes' : 'No' || 'N/A',
      },
    ],
    [
      efmChild.efmCategory,
      efmChild.expirationDate,
      emergencyContact,
      selectedEmergencyContact?.contactPerson?.phone,
      selectedEmergencyContact?.isAuthorized,
      selectedEmergencyContact?.relationshipType?.title,
      selectedSibling,
    ],
  );
  const items = useMemo(
    () => [
      {
        id: 'date_of_birth',
        title: 'Date of Birth',
        value: formatDate(child?.dateOfBirth) || 'N/A',
      },
      {
        id: 'sibling_name',
        title: 'Sibling Name',
        className: 'max-w-[250px] w-[250px]',
        value:
          (
            <div className="w-full">
              <Select
                name="selectedSibling"
                options={siblingOptions}
                placeholder="Select"
                ariaLabel="Select Sibling"
                styles={selectCommonStyles}
                getOptionLabel={(item) => getFullName(item) || 'N/A'}
                onChange={(value) => setSelectedSibling(value)}
                value={selectedSibling}
              />
            </div>
          ) || 'N/A',
      },
      {
        id: 'parent_installation',
        title: 'Parent Installation',
        value: installation?.name || 'N/A',
      },

      {
        id: 'age',
        title: 'Age',
        value: child?.dateOfBirth ? dayjs(child?.dateOfBirth).toNow(true) : 'N/A',
      },

      {
        id: 'sibling_age',
        title: 'Sibling Age',
        value: selectedSibling?.dateOfBirth ? dayjs(selectedSibling?.dateOfBirth).toNow(true) : 'N/A',
      },

      {
        id: 'person_workAddress',
        title: 'Home Address',
        value: getCompletedAddress(child?.homeAddress) || 'N/A',
      },

      {
        id: 'special_needs',
        title: 'Special Needs',
        value: selectedSpecialNeeds || 'N/A',
      },
    ],
    [child?.dateOfBirth, child?.homeAddress, siblingOptions, installation?.name, selectedSibling, selectedSpecialNeeds],
  );

  const combinedItem = useMemo(
    () => (child.isEfmChild ? items.concat(efmItems) : items),
    [child?.isEfmChild, efmItems, items],
  );
  const descItems = useMemo(
    () =>
      combinedItem.map((item) => ({
        ...item,
        key: item.id,
        label: item.title,
        children: item.value,
      })),
    [combinedItem],
  );
  return (
    <div className="px-6 pb-6 pt-5">
      <Skeleton avatar loading={loading || emergencyContactLoading} paragraph={{ rows: 4 }}>
        <div className="mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Child:</span>{' '}
                <span className=" text-primary font-medium">{getFullName(child) || 'N/A'}</span>
              </p>
            }
            size="small"
            items={descItems}
            extra={headerExtraElement}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {descItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}
