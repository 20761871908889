import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { providerService } from 'services';
import { useAsync } from 'react-async-hook';

export default function RenderProviderName({ className, id, size }) {
  const { loading, result: provider = {} } = useAsync(
    async (id) => {
      if (!id) return null;
      return await providerService.getCenterById(id, { cache: { interpretHeader: false } });
    },
    [id],
  );
  return (
    <Spin size={size} spinning={loading}>
      <span className={className}>{provider?.businessLegalName ?? 'N/A'}</span>
    </Spin>
  );
}

RenderProviderName.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  size: PropTypes.oneOf(['small', 'default', 'large']),
};
RenderProviderName.defaultProps = {
  className: '',
  size: 'small',
};
