import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, message, Popover, Space, Tag, Divider } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import {
  formatDate,
  formatDates,
  formatDateTimeToLocal,
  getCompletedAddress,
  getDateSorter,
  getFormattedPhoneNumber,
} from 'utils';
import { actions as centerActions } from 'features/add-center';
import providerService from 'services/providers.service';
import styles from './ProviderContactCard.module.less';
import Form from 'components/Form';
import ProgramEligibilityModal from 'components/Modals/ProgramEligibilityModal';
import CountPill from 'components/CountPill';
import EFMProviderContactCard from 'components/EFMProviderContactCard';
import { List } from 'components/DetailsDrawer/DetailsDrawer';
import { BiInfoCircle } from 'react-icons/bi';
import { userService } from 'services';

// const checkRoutePattern = new RegExp('/providers/applications/\\d+/review', 'i');

export default function ProviderContactCard({ center, loading, className, breakpoints }) {
  const dispatch = useDispatch();
  const [showProgramPopup, setShowProgramPopup] = useState(false);

  const [complianceForm] = Form.useForm();

  useAsyncCallback(
    async (values, dateFields) => {
      values = formatDates(values, dateFields);
      try {
        const data = await providerService.updateCenter(values, center.id);

        if (values.notes) {
          try {
            await providerService.saveNote(center?.application?.id, { content: values.notes, draft: false });
          } catch (error) {
            message.error('Unable to save notes.', 5);
            newrelic.noticeError(error);
          }
        }
        dispatch(centerActions.setCenterInfo(data));
      } catch (error) {
        message.error({
          content: 'Unable to update application.',
          duration: 5,
        });
        newrelic.noticeError(error);
      }
    },
    [center.id],
  );

  const { result: programs = [], execute: fetchPrograms } = useAsyncCallback(
    async (id) => {
      if (id) {
        const programs = await providerService.getPrograms(id);
        return programs.reduce((prev, curr) => {
          if (curr.selected) {
            prev[curr.type.title] = Array.from(prev[curr.type.title] || []).concat(curr);
          }
          return prev;
        }, {});
      }
      return {};
    },
    [center.id],
  );

  const { result: tier } = useAsync(
    async (id) => {
      if (!id) return null;
      const { tier } = await providerService.getTier(id);
      return tier;
    },
    [center?.id],
  );

  useEffect(() => {
    fetchPrograms(center?.id);
  }, [center?.id, fetchPrograms]);

  useEffect(() => {
    complianceForm.resetFields();
  }, [complianceForm]);

  const assignee = center.application?.assignedTo;
  let caseOwnerName = '–';
  if (assignee) {
    caseOwnerName = [assignee.firstName, assignee.lastName].join(' ');
  }

  const currentlyWith = center?.application?.currentlyWith;
  const license = [...(center.licenses || [])].sort(getDateSorter('endDate', true)).at(0);
  const qris = [...(center.qrisList || [])].sort(getDateSorter('endDate', true)).at(0);
  const accreditation = [...(center.accreditations || [])].sort(getDateSorter('endDate', true)).at(0);
  const isEfm = (center?.application?.programCategories || []).find((item) => item.id === 5 /* EFM */);

  const [providerBeingInvited, setProviderBeingInvited] = React.useState(false);
  const [providerCanBeInvited, setProviderCanBeInvited] = React.useState(false);
  useAsync(async () => {
    const organization = await providerService.getOrganizationById(center.id);
    if (!organization.admin) {
      setProviderCanBeInvited(true);
    }
  }, [center]);

  // TODO: Staff should be used form Staff API but may be we can fetch staff while fetching the CF and put in that object
  return (
    <div className={classNames('contact-card', className, styles.providerContactCard)} id="application-contact-card">
      {(center?.application?.status?.status?.title === 'COMPLETED' || tier) && (
        <>
          <Space>
            {center?.application?.status?.status?.title === 'COMPLETED' && (
              <Tag className="h-6 text-[14px] flex items-center" color="green">
                Approved
              </Tag>
            )}

            {tier && (
              <Tag className="h-6 text-[14px] flex items-center" color="blue">
                Tier {tier}
              </Tag>
            )}
          </Space>

          <Divider />
        </>
      )}

      <div className="flex gap-4 lg:gap-32 flex-col lg:flex-row mb-12 lg:mb-0 mt-2">
        <List
          className="lg:w-1/3 lg:max-w-[270px]"
          items={[
            {
              title: 'Provider',
              items: [
                {
                  label: 'Provider ID #',
                  value: center.careFacilityId || center.id,
                },
                {
                  label: 'Business Name',
                  value: center.businessLegalName,
                },
                {
                  label: 'Point Of Contact',
                  value: center?.pointOfContacts?.[0]?.displayName || '-',
                },
                {
                  label: 'Phone No',
                  value: center?.pointOfContacts?.[0]?.phone
                    ? getFormattedPhoneNumber(center?.pointOfContacts?.[0]?.phone)
                    : '-',
                },
                {
                  label: 'Email',
                  value: center?.pointOfContacts?.[0]?.email || '-',
                },
                {
                  label: 'Address',
                  value: getCompletedAddress(center.address),
                },
              ],
            },
          ]}
        />

        <List
          className="lg:w-1/3 lg:max-w-[270px]"
          items={[
            {
              title: 'Application',
              items: [
                {
                  label: 'Status',
                  value: (
                    <Tag className="m-0">
                      {center.application?.status?.status?.title ||
                        center.application?.status?.status?.description ||
                        '–'}
                    </Tag>
                  ),
                },
                {
                  label: 'Case Owner',
                  value: caseOwnerName,
                },
                {
                  label: 'Currently with',
                  value: currentlyWith ? `${currentlyWith.firstName} ${currentlyWith.lastName}` : '–',
                },
                {
                  label: 'Last Updated',
                  value:
                    center?.lastModifiedDate &&
                    dayjs(center?.lastModifiedDate).isAfter(dayjs(center?.application?.lastModifiedDate))
                      ? formatDateTimeToLocal(center?.lastModifiedDate)
                      : formatDateTimeToLocal(center?.application?.lastModifiedDate),
                },
              ],
            },
          ]}
        />

        <List
          className="lg:w-1/3 lg:max-w-[270px]"
          items={[
            {
              title: 'Program(s)',
              items: [
                {
                  // label: (
                  //   <Space>
                  //     <div>Approved Programs</div>

                  //     <Button
                  //       type="text"
                  //       className="h-8 icon-btn"
                  //       onClick={() => setShowProgramPopup(true)}
                  //       icon={<AiOutlineEdit className="text-2xl text-gray-400 cursor-pointer" />}
                  //       data-testid="program-popup-trigger"
                  //       disabled={disableEditMode}
                  //     />
                  //   </Space>
                  // ),
                  label: 'Approved Programs',
                  value: (
                    <Popover
                      content={
                        Object.entries(programs)?.length !== 0 ? (
                          <Space direction="vertical">
                            {Object.entries(programs)?.map(([program, sponsors]) => {
                              return (
                                <div className="flex flex-row h-4" key={program}>
                                  <span className="uppercase font-medium text-sm mr-2">{program}:</span>

                                  {sponsors.map((sponsor, index) => (
                                    <span className="ml-1" key={sponsor.id}>
                                      {sponsor.sponsor.title}
                                      {index !== sponsors.length - 1 && ','}
                                    </span>
                                  ))}
                                </div>
                              );
                            })}

                            {center?.programs?.length === 0 && 'No Program Selected'}
                          </Space>
                        ) : undefined
                      }
                    >
                      <CountPill
                        className={styles.count}
                        count={
                          <div className="flex items-center gap-1">
                            <span>{Object.entries(programs).length}</span>

                            {Object.entries(programs)?.length !== 0 && (
                              <BiInfoCircle className="w-4 h-4 mr-[-2px] mt-[-1px]" />
                            )}
                          </div>
                        }
                      />
                    </Popover>
                  ),
                },
                {
                  label: 'License',
                  value: (
                    <Space direction="vertical" size={1}>
                      <span className="flex justify-end gap-2">
                        {license?.licenseNumber || 'No License'}

                        <CountPill className={styles.count} count={center?.licenses?.length || 0} />
                      </span>

                      {license && (
                        <div className="help-text text-xs">
                          Exp On: <span className="text-red-700">{formatDate(license?.endDate)}</span>
                        </div>
                      )}
                    </Space>
                  ),
                },
                {
                  label: 'Accreditation',
                  value: (
                    <Space direction="vertical" size={1}>
                      {accreditation?.type?.title || 'No Accreditation'}

                      {accreditation && (
                        <div className="help-text text-xs">
                          Exp On: <span className="text-red-700">{formatDate(accreditation?.endDate)}</span>
                        </div>
                      )}
                    </Space>
                  ),
                },
                {
                  label: 'Compliance',
                  value: (
                    <Space direction="vertical" size={1}>
                      {center?.complianceLevel?.statusType?.title || '–'}

                      {center?.complianceLevel && (
                        <div className="help-text text-xs">
                          Exp On: <span className="text-red-700">{formatDate(center?.complianceLevel?.endDate)}</span>
                        </div>
                      )}
                    </Space>
                  ),
                },
                {
                  label: 'QRIS',
                  value: (
                    <Space direction="vertical">
                      {qris?.system?.title ? (
                        <span
                          title={`${qris?.certificateNumber ? qris?.certificateNumber + ': ' : ''}${
                            qris?.system?.title
                          }`}
                          className="truncate block font-semibold"
                        >
                          {qris?.certificateNumber ? qris?.certificateNumber + ': ' : ''}
                          {qris?.system?.title}
                        </span>
                      ) : (
                        'No QRIS'
                      )}

                      {qris && (
                        <div className="help-text text-xs">
                          Exp On: <span className="text-red-700">{formatDate(qris?.endDate)}</span>
                        </div>
                      )}
                    </Space>
                  ),
                },
              ],
            },
          ]}
        />
      </div>

      {isEfm && (
        <div className="mt-4 flex-1 flex flex-wrap border border-gray-200 border-opacity-50">
          <EFMProviderContactCard center={center} loading={loading} breakpoints={breakpoints} />
        </div>
      )}

      <ProgramEligibilityModal
        visible={showProgramPopup}
        setVisible={(visible) => {
          setShowProgramPopup(visible);
          if (!visible) {
            fetchPrograms(center?.id);
          }
        }}
        id={center?.id}
        applicationId={center?.application?.id}
        getContainer="#application-contact-card"
      />

      {providerCanBeInvited && (
        <>
          <Divider />

          <Button
            className="mt-6 table w-max ml-auto"
            onClick={async () => {
              setProviderBeingInvited(true);

              try {
                await userService.inviteProviderUser(center.id);
                message.success('Done');

                setProviderCanBeInvited(false);
              } catch (error) {
                newrelic.noticeError(error);

                if (error.apierror?.debugMessage === 'Organization already has an admin') {
                  message.error('User is already onboarded');
                } else if (error.apierror?.debugMessage === 'User account already exists') {
                  message.error('A user account already exists with that email address. Try a new address');
                } else {
                  message.error('Something went wrong');
                }
              } finally {
                setProviderBeingInvited(false);
              }
            }}
            loading={providerBeingInvited}
            type="primary"
          >
            Invite this Provider
          </Button>
        </>
      )}
    </div>
  );
}

ProviderContactCard.defaultProps = {
  center: {},
  className: '',
  loading: false,
  breakpoints: { xs: false, screen: 'xxl', sm: true, md: true, lg: true, xl: true, xxl: true },
};
