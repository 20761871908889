import { useContext } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { copy, getValue, SnapshotContext } from 'utils';

export default function useChangeRequestClassName({ keys, snapshotKey, getSnapshotCollection, className }) {
  const { snapshotSelector } = useContext(SnapshotContext);
  const snapshot = useSelector(snapshotSelector || (() => null));
  return (record, index) => {
    if (snapshot) {
      const curr = copy(record, keys);
      const prev = copy(
        (getSnapshotCollection ? getSnapshotCollection(snapshot, record) : getValue(snapshot, snapshotKey)).find(
          (item) => item.id === record.id,
        ),
        keys,
      );
      const isSame = isEqual(curr, prev);
      return classNames(
        'h-10 !border-b  !border-white',
        {
          'bg-blue-700 bg-opacity-5': isSame,
          'bg-orange-100': !isSame && index % 2 === 0,
          'bg-orange-100 bg-opacity-50': !isSame && index % 2 !== 0,
        },
        className,
      );
    }
    return classNames(index % 2 === 0 ? 'bg-blue-700 bg-opacity-5 h-9' : 'bg-blue-700 bg-opacity-2 h-9', className);
  };
}
