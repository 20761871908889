import React, { useEffect } from 'react';
import { Button, Input, message } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router-dom';

import Form from 'components/Form';
import { AsyncSelect } from 'components/Select';
import { familyService } from 'services';

export default function CertificateStatusContent({ certificate, certificateId, householdId, application, prev, next }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const { execute: onSubmit, loading: submitting } = useAsyncCallback(
    async (values) => {
      try {
        await familyService.updateCertificate(certificateId, { ...values });
        message.success('Certificate Status updated successfully.', 5);
      } catch (error) {
        message.success('Failed to update Certificate Status.', 5);
      }
    },
    [certificateId],
  );
  useEffect(() => {
    form.setFieldsValue({
      certificateStatus: certificate.certificateStatus,
      notes: certificate.notes,
    });
  }, [certificate.certificateStatus, certificate.notes, certificateId, form]);
  return (
    <Form form={form} onFinish={onSubmit} className="p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <div className="col-span-1">
          <Form.Item name={['certificateStatus', 'certificateStatusType']} label="Certificate Status">
            <AsyncSelect
              placeholder="Select Status"
              apiPrefix="families"
              optionsApiUrl="/certificates/status-types"
              getOptionLabel={(opt) => opt.description}
            />
          </Form.Item>
        </div>
        <div className="col-span-full">
          <Form.Item name="notes" label="Status Change Notes">
            <Input.TextArea placeholder="Add Note" />
          </Form.Item>
        </div>
      </div>
      <div className="pt-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
        <Button
          onClick={() => history.push(`/families/${householdId}/applications/${application.id}`)}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button onClick={() => history.push(prev)} disabled={submitting}>
          Back
        </Button>
        <Button type="primary" htmlType="submit" disabled={submitting} loading={submitting}>
          Next
        </Button>
      </div>
    </Form>
  );
}
