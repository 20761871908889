import React, { useCallback, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import EligibilityHeader from './EligibilityHeader';
import EligibilityContent from './EligibilityContent';
import FamilyReviewContainer from '../FamilyReviewContainer';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

export default function EligibilityDetails({ application, loading, rootRef }) {
  const { pathname } = useLocation();
  const { id, householdId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const onChange = useCallback(
    (index) => {
      history.push(`${url}/eligibility`);
    },
    [history, url],
  );

  const { routePrefix, pathPrefix } = useMemo(() => {
    return {
      routePrefix: `${path}/eligibility`,
      pathPrefix: `${url}/eligibility`,
    };
  }, [path, url]);

  const props = useMemo(
    () => ({
      id,
      householdId,
      application,
      loading,
    }),
    [application, householdId, id, loading],
  );
  const statusPathName = useMemo(() => pathname.replace(/\/documents(.*)$/, ''), [pathname]);

  const prev = useMemo(() => {
    if (application.children?.length > 0) {
      return `/families/${householdId}/applications/${application.id}/review/children/${
        application.children.at(-1).id
      }/schedule`;
    } else if (application.additionalParents?.length > 0) {
      return `/families/${householdId}/applications/${application.id}/review/secondParent/${
        application.additionalParents.at(-1).id
      }/income`;
    }
    return `/families/${householdId}/applications/${application.id}/review/sponsor/income`;
  }, [application.additionalParents, application.children, application.id, householdId]);

  const step = useMemo(
    () => ({
      title: 'Basic Info',
      status: statusPathName.endsWith('/review') || statusPathName.endsWith('/eligibility') ? 'process' : 'wait',
      header: <EligibilityHeader {...props} />,
      content: (
        <EligibilityContent {...props} next={`/families/${householdId}/applications/${id}/certificates`} prev={prev} />
      ),
    }),
    [statusPathName, props, householdId, id, prev],
  );

  return (
    <div>
      <div>
        <FamilyReviewContainer
          onChange={onChange}
          rootRef={rootRef}
          routePrefix={routePrefix}
          pathPrefix={pathPrefix}
          singleStep={step}
        />
      </div>
    </div>
  );
}
