import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './CaseCoordinatorActivity.module.less';
import Tabs from 'components/Tabs';
import Notes from 'components/Notes';
import { ENTITY_TYPES } from 'constants/index';
import StatusChangeLogs from 'components/StatusChangeLogs';
import { Button, Drawer } from 'antd';
import useModalState from 'hooks/useModalState';

export default function CaseCoordinatorActivity({
  className,
  collapseClassName,
  collapseHeaderClassName,
  entity,
  entityType = ENTITY_TYPES.CARE_FACILITY,

  placement = 'bottom',
  buttonLabel = 'Notes & Change Log',
}) {
  const { visible, onClose, onToggle } = useModalState();
  return (
    <>
      <Button onClick={onToggle}>{buttonLabel}</Button>
      <Drawer
        title="Case Coordinator Recent Activity"
        placement={placement}
        width={500}
        onClose={onClose}
        open={visible}
      >
        <div className={classNames(styles.caseCoordinatorActivity)}>
          <Tabs
            className=""
            items={[
              {
                key: 'notes',
                label: 'Notes',
                content: (
                  <div className="p-5 shadow-lg">
                    <Notes entity={entity} entityType={entityType} />
                  </div>
                ),
              },
              {
                key: 'change-logs',
                label: 'Change Log',
                content: (
                  <div className="p-5 shadow-lg">
                    <StatusChangeLogs entity={entity} entityType={entityType} />
                  </div>
                ),
              },
            ]}
          />
        </div>
      </Drawer>
    </>
  );
}

CaseCoordinatorActivity.propTypes = {
  center: PropTypes.object,
  className: PropTypes.string,
  collapseClassName: PropTypes.string,
};
