import React from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import Section from 'components/Section';
import { getFullName } from 'utils';

export default function ChildCareInYourHomeInstructions({
  application,
  household,
  onContinueToDashboard,
  onReturnToHome,
}) {
  return (
    <div className="mb-6">
      <div className="text-center bg-white p-6">
        <h1 className="font-bold text-2xl mt-2 tracking-normal">Thank You for your service to our country!</h1>
        <h3 className="text-lg py-6 px-8 tracking-normal font-medium">Great! Your application has been submitted.</h3>
        <p className="">
          Congratulations, {getFullName(application?.applicant)}, we have received your application for Child Care in
          Your Home Fee Assistance on submission date, {dayjs().format('MM/DD/YYYY')}!
        </p>
        <p className="mt-4">
          If you have questions, you may contact Child Care Aware® of America, at the information below, between 8:00 am
          and 7:00 pm Eastern Standard Time by the contact information listed below. <br />
        </p>
        <p className="mt-4">
          Please reference your Family ID on the fax cover sheet, or in the subject line of your email, when sending in
          documents or calling to discuss your account
        </p>
        <div className="flex actions mt-6">
          <Button data-testId="return-home-btn" onClick={onReturnToHome}>
            Return to Home Screen{' '}
          </Button>
          <Button data-testId="application-dashboard-btn" onClick={onContinueToDashboard} type="primary">
            Continue to Application Dashboard
          </Button>
        </div>
      </div>
      <Section heading="Family Application Reference Information:" className="mt-6 !border-0">
        <ul className="list-disc ml-8 block pb-2">
          <li>
            Family Number: {household?.id}
            <ul className="list-disc ml-8 block pb-2">
              <ul className="list-disc ml-8 block pb-2">
                {application?.children?.map((child) => (
                  <>
                    <li>Child Name: {getFullName(child)}</li>
                  </>
                ))}
              </ul>
            </ul>
          </li>
        </ul>
      </Section>
      <Section
        heading="Child Care Aware® of America Contact Information:"
        className="mt-6 !border-0"
        contentClassName="section-px -mt-2 pb-6"
      >
        <span>1515 N. Courthouse Road, 3rd Floor, Arlington, VA 22201</span>
        <table>
          <tbody>
            <tr>
              <td width="181">
                <p>
                  <strong>Parent Services</strong>
                </p>
              </td>
              <td width="443">
                <p>Phone: 1(800) 424-2246 ext. 312</p>
                <p>Fax: (703) 341-4103</p>
                <p>
                  Email: <a href="mailto:CCYH@usa.childcareaware.org">CCYH@usa.childcareaware.org</a>
                </p>
              </td>
            </tr>
            <tr>
              <td width="181">
                <p>
                  <strong>Provider Services</strong>
                </p>
              </td>
              <td width="443">
                <p>Phone: 1(800) 424-2246 ext. 345</p>
                <p>Fax: (703) 341-4146</p>
                <p>
                  Email:{' '}
                  <a href="mailto:providerservices@usa.childcareaware.org">providerservices@usa.childcareaware.org</a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Section>
      <div className="mt-6 bg-white p-6">
        <h3 className="text-primary">
          <strong>Next Steps: </strong>
        </h3>
        <div className="ml-16 my-2">
          <h3 className="text-primary">When will I receive a response from Child Care Aware&reg; of America?</h3>
          <p>
            You will receive a response about the status of your submitted application after the initial review. You can
            also check your status online at any time.
          </p>
          <h3 className="text-primary mt-2">When will I receive approval for Fee Assistance?</h3>
          <p>
            The processing time for your application may vary and is dependent upon the timely submission and receipt of
            ALL family and childcare Provider documents required to approve your application or recertification.
          </p>
        </div>
      </div>
    </div>
  );
}
