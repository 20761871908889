import { useCallback, useState, useRef } from 'react';
import { useMountedState } from 'react-use';
// import { useAsyncCallback } from 'react-async-hook';

export default function useAsyncFn(fn, deps, initialState = { loading: false }) {
  const lastCallId = useRef(0);
  const isMounted = useMountedState();
  const [state, setState] = useState(initialState);

  const callback = useCallback(
    (...args) => {
      const callId = ++lastCallId.current;
      if (!state.loading) {
        setState((prevState) => ({ ...prevState, loading: true }));
      }

      return fn(...args).then(
        (value) => {
          isMounted() && callId === lastCallId.current && setState({ value, loading: false });

          return value;
        },
        (error) => {
          isMounted() && callId === lastCallId.current && setState({ error, loading: false });

          return error;
        },
      );
    },
    // Note: Don't remove *deps* from dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  const setValue = useCallback((cb) => {
    setState((state) => ({ ...state, value: typeof cb === 'function' ? cb(state.value) : cb }));
  }, []);

  return [{ ...state, setValue }, callback];
}
// export default function useAsyncFn(fn, deps, initialState = { loading: false }) {
//   const lastCallId = useRef(0);
//   const isMounted = useMountedState();
//   const [state, setState] = useState(initialState);

//   const callback = useCallback(
//     (...args) => {
//       const callId = ++lastCallId.current;
//       if (!state.loading) {
//         setState((prevState) => ({ ...prevState, loading: true }));
//       }

//       return fn(...args).then(
//         (value) => {
//           isMounted() && callId === lastCallId.current && setState({ value, loading: false });

//           return value;
//         },
//         (error) => {
//           isMounted() && callId === lastCallId.current && setState({ error, loading: false });

//           return error;
//         },
//       );
//     },
//     // Note: Don't remove *deps* from dependency list
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     deps,
//   );

//   const setValue = useCallback((cb) => {
//     setState((state) => ({ ...state, value: typeof cb === 'function' ? cb(state.value) : cb }));
//   }, []);

//   return [{ ...state, setValue }, callback];
// }

// export default function useAsyncHook(fn, deps = []) {
//   const { execute, loading, error, result } = useAsyncCallback(fn, deps);
//   return [{ loading, error, value: result }, execute];
// }
