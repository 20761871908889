import React, { useCallback, useMemo, useState, useContext } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import useEntityGroup from 'hooks/useEntityGroup';
import Select, { AsyncSelect } from 'components/Select';
import Form from 'components/Form';
import Section from 'components/Section';
import { selectActiveRole } from 'features/auth';
import { formatDate, getFullName, breakpointsContext, getCompletedAddress } from 'utils';
import familyService from 'services/family.service';
import ChildFamilyMemberRelation from './ChildFamilyRelation';
import AddChildFamilyMemberWizard from './AddChildFamilyMemberWizard';
import { ROLES } from 'constants/index';
import AriaDatePicker from 'components/AriaDatePicker';

export default function ChildFamilyContainer(props) {
  return <ChildFamily {...props} />;
}
function ChildFamily({
  application,
  profileData,
  reload,
  loading,
  hideTable,
  isWrapperWithSection = true,
  currentStep,
  getItem,
}) {
  const breakpoints = useContext(breakpointsContext);
  const activeRole = useSelector(selectActiveRole);
  const isFamilyUser = useMemo(() => activeRole === ROLES.FAMILY, [activeRole]);
  const items = useMemo(() => {
    return Array.from(profileData?.children || []).sort((a, b) => b.id - a.id);
  }, [profileData]);
  const [expandedRows, setExpandedRows] = useState([]);

  const expandedRowRender = (record, index) => {
    return (
      <div className="flex flex-col border border-t-0 -mt-0.5 [&_.ant-table]:!min-h-[100px] mb-5">
        <ChildFamilyMemberRelation
          profileData={profileData}
          childId={record?.id}
          householdId={profileData?.id}
          reload={reload}
          customAddButton={() => null}
        />
      </div>
    );
  };
  const expandable = {
    expandIcon: () => null,
    rowExpandable: () => true,
    expandedRowKeys: expandedRows,
    expandedRowRender,
  };
  const getAllColumns = useCallback(
    ({ addItem, deleteItem, setItem, setVisible }) => {
      const allColumns = [
        {
          title: <span className="action-header">Name</span>,
          headerText: 'Name',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          width: 150,
          render: (name, record) => <span>{getFullName(record)}</span>,
          fixed: breakpoints.md ? 'left' : undefined,
        },
        {
          title: <span className="action-header">Suffix</span>,
          headerText: 'Suffix',
          dataIndex: 'suffix',
          key: 'suffix',
          width: 120,
          render: (suffix) => <span>{suffix?.title || 'N/A'}</span>,
        },
        {
          title: <span className="action-header">Address</span>,
          headerText: 'Address',
          dataIndex: 'homeAddress',
          key: 'address',
          ellipsis: true,
          width: 220,
          render: (address) => getCompletedAddress(address) || 'N/A',
          align: 'left',
          className: 'xs-max',
        },
        {
          title: <span className="action-header">Date of Birth</span>,
          headerText: 'Date of Birth',
          dataIndex: 'dateOfBirth',
          key: 'dateOfBirth',
          width: 150,
          render: (dateOfBirth) => formatDate(dateOfBirth),
        },
        {
          title: <span className="action-header">Sex</span>,
          headerText: 'Sex',
          dataIndex: 'gender',
          key: 'sex',
          width: 120,
          render: (gender) => gender,
        },
      ];
      return allColumns;
    },
    [breakpoints.md],
  );

  const getFormFields = useCallback(
    (props) => {
      return (
        <AddChildFamilyMemberWizard
          {...props}
          profileData={profileData}
          reload={reload}
          loading={loading}
          currentStep={currentStep}
        />
      );
    },
    [profileData, reload, loading, currentStep],
  );
  const onAddItem = useCallback(
    async (data) => {
      const payload = data.id ? data : [{ ...data }];
      await familyService.addUpdateFamilyChild(profileData?.id, data.id, payload);
      return await reload();
    },
    [profileData?.id, reload],
  );
  const onDeleteItem = useCallback(
    async (item) => {
      return familyService.deleteFamilyChild(application?.id, item.id);
    },
    [application?.id],
  );

  const {
    section: childTableSection,
    setItem,
    setVisible,
  } = useEntityGroup({
    items,
    onChange: () => {},
    onAddItem,
    onDeleteItem,
    entity: application,
    getAllColumns,
    getFormFields,
    customAddButton: isFamilyUser ? null : (
      <Button
        type="primary"
        onClick={() => {
          setItem(getItem?.());
          setVisible(true);
        }}
        className="w-48"
      >
        Edit
      </Button>
    ),
    entityTitle: 'Child',
    tableProps: {
      scroll: { x: 1200 },
      rowKey: (record) => {
        return `${record.id}-${record.uid}`;
      },
      expandedRowClassName: 'px-4 py-2',
      expandable,
      pagination: { pageSize: 10, hideOnSinglePage: true },
    },
    hideTable,
    dateFields: [['dateOfBirth']],
    hideDeleteButton: true,
    entityName: 'family-child-table',
    modalWidth: 1400,
    getRowActions: (record) => {
      const isExpanded = expandedRows.indexOf(`${record?.id}-${record?.uid}`) > -1;
      return (
        <Tooltip placement="top" title={isExpanded ? 'Close' : 'Open'}>
          <Button
            onClick={() => {
              if (expandedRows[0] === `${record?.id}-${record?.uid}`) {
                setExpandedRows([]);
              } else {
                setExpandedRows([`${record?.id}-${record?.uid}`]);
              }
            }}
            icon={isExpanded ? <AiFillCaretUp /> : <AiFillCaretDown />}
            className="icon-btn expand-btn"
            aria-label={isExpanded ? 'Close' : 'Open'}
            data-testid={`collapse-button-${record?.uid}`}
            title={isExpanded ? 'Close' : 'Open'}
          />
        </Tooltip>
      );
    },
  });

  return (
    <>
      {isWrapperWithSection ? (
        <Section heading="Child(ren) Summary" headerContainerClassName="px-4" testId="family-child-section">
          {childTableSection}
        </Section>
      ) : (
        childTableSection
      )}
    </>
  );
}

export const ChildFormFields = ({ values, setVisible, item, addUpdateLoading, loading, form, isFamilyUser }) => {
  return (
    <>
      <div className="mb-6">
        <span className="section-title">Step 1: Enter Child's Basic Information</span>
      </div>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8">
        <div>
          <Form.Item
            name={['firstName']}
            label="First Name"
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input placeholder="First Name" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={['middleName']} label="Middle Name/Initial">
            <Input placeholder="Middle Name" />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['lastName']}
            label="Last Name"
            rules={[{ required: true, message: 'Last Name is required.' }]}
          >
            <Input placeholder="Last Name" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="suffix" label="Select Suffix">
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/options/20"
              placeholder="Select Suffix"
              ariaLabel="Select Suffix"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={'gender'} label="Sex" rules={[{ required: true, message: 'Sex is required.' }]}>
            <Select
              options={[
                { id: 'MALE', title: 'Male' },
                { id: 'FEMALE', title: 'Female' },
                { id: 'INTERSEX', title: 'Intersex' },
                { id: 'PREFER_NOT_TO_SAY', title: 'Prefer Not To Say' },
              ]}
              placeholder="Select Sex"
              ariaLabel="Select Sex"
              onChangeFormatter={(v) => v.id}
              isOptionSelected={(option, [value]) => option.id === value}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={['dateOfBirth']}
            label="Date of Birth"
            rules={[{ required: true, message: 'Date of Birth is required.' }]}
          >
            <AriaDatePicker placeholder="Date of Birth" />
          </Form.Item>
        </div>
      </div>
      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-adult-btn">
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={addUpdateLoading || loading}
          loading={addUpdateLoading || loading}
          onClick={() => form.submit()}
          data-testid="save-adult-btn"
        >
          {isFamilyUser ? 'Next' : 'Save'}
        </Button>
      </div>
    </>
  );
};
