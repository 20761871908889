import { Spin } from 'antd';
import numeral from 'numeral';

export default function IncomeHeader({ totalAnnualIncome = 0, loading }) {
  return (
    <div className="px-6 pb-6 pt-1">
      <div className="mt-4 mx-auto max-w-[1600px]">
        <div className="flex items-center space-x-4">
          <span className="text-lg font-medium">Total Annual Salary:</span>
          <Spin spinning={loading}>
            <span data-testid="total-income">
              {totalAnnualIncome ? numeral(totalAnnualIncome).format('$0,0.00') : 'N/A'}
            </span>
          </Spin>
        </div>
      </div>
    </div>
  );
}
