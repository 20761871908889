import config from 'config';
import { commonHead, ENTITY_TYPES } from 'constants/index';

export default function getTemplate({ entityType = ENTITY_TYPES.CARE_FACILITY, subject, content }) {
  let supportEmail =
    entityType === ENTITY_TYPES.CARE_FACILITY
      ? `providers@${config.REACT_APP_ENV}.greatchildcare.org`
      : `msp@${config.REACT_APP_ENV}.greatchildcare.org`;
  return `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <title>${subject}</title>
    ${commonHead}
  </head>
  <body style="background-color:#F7F7F7;padding:0;margin:0;text-align:center;">
    <div style="max-width:600px;text-align:center;margin:0 auto;background-color:#ffffff;">
      <div style="padding:6px 0;background-color:#F7F7F7;">
        <p style="margin:-1px 0 0;font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;line-height:13px;color:#999999;font-size:11px">${subject}</p>
      </div>
      <div style="margin:0;padding-top:20px;padding-bottom:20px;padding-left:20px;padding-right:20px;background-color:#f4f6fc">
        <img src="https://dev.greatchildcare.org/images/ccaoa-logo.png" alt="Great Child Care Website" style="text-align:center;" width="183" title="Great Child Care Website" />
      </div>
      <div style="background-color:#fafafa;padding:20px 20px 40px;">
        <div style="text-align: left; font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;line-height:18px;color:#2d384f;font-size:16px;">
        ${content}
        <br/>
        <p style="margin-bottom:30px">
            Sincerely,
        </p>
        <p style="line-height:22px;">
            Child Care Aware® of America
            <br/>
            1515 N. Courthouse Road
            <br/>
            3rd Floor
            <br/>
            Arlington, VA 22201
            <br/>
            Phone: 1-800-424-2246
            <br/>
            Fax: 703-341-4103
          <br/>
            msp@usa.childcareaware.org
        </p>
      </div>
      <div style="background:#2caae2;width:100%;padding:10px;clear: both;box-sizing: border-box;margin-top:50px;float:right;">
        <div class="float-container">
          <div class="float-child left" style="border-right: 0 !important;">
            <div class="">
              <img src="https://ybkwch.stripocdn.email/content/guids/CABINET_66498ea076b5d00c6f9553055acdb37a/images/35681527588356492.png" alt="" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic" width="24" />
              <p class="email-phone">
                <a target="_blank" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#ffffff;font-size:18px" href="mailto:${supportEmail}">${supportEmail}</a>
              </p>
            </div>
          </div>
          <div class="float-child">
            <div class="">
              <img src="https://ybkwch.stripocdn.email/content/guids/CABINET_66498ea076b5d00c6f9553055acdb37a/images/50681527588357616.png" alt="" style="display:block;border:0;outline:none;text-decoration:none;-ms-interpolation-mode:bicubic" width="24" />
              <p class="email-phone">
                <a target="_blank" style="-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;text-decoration:underline;color:#ffffff;font-size:16px" href="tel:18004242246">1-800-424-2246</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="padding: 15px;clear: both;box-sizing: border-box;background-color:#F7F7F7;">
        <h5 style="Margin:0;line-height:120%;mso-line-height-rule:exactly;font-family:arial, 'helvetica neue', helvetica, sans-serif;color:#666666">Child Care Aware of America®<br />1515 N. Courthouse Rd, 3rd Floor, Arlington, VA 22202</h5>
      </div>
    </div>
  </body>
</html>
`;
}
