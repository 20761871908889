import { message } from 'antd';
import produce from 'immer';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';

import familyService from 'services/family.service';
import { createAsyncAction } from 'utils';
import { selectActiveRole } from 'features/auth';
import { ADD_FAMILY_STEPS, RESET_ALL, ROLES } from 'constants/index';

export const OPEN_STATUS = false;

const secondParentInitialState = {
  open: true,
  steps: {},
};

const getDefaultOpenSteps = (isOpen) => ({
  steps: {
    [ADD_FAMILY_STEPS.START]: {
      open: true,
      steps: {},
    },
    [ADD_FAMILY_STEPS.PROGRAMS]: {
      open: false,
      steps: {},
    },
    [ADD_FAMILY_STEPS.ELIGIBILITY]: {
      open: true,
      steps: {},
    },
    [ADD_FAMILY_STEPS.SPONSOR]: {
      open: false,
      steps: {},
    },
    [ADD_FAMILY_STEPS.SECOND_PARENTS]: {
      open: isOpen,
      steps: {},
    },
    [ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS]: {
      open: isOpen,
      steps: {},
    },
    [ADD_FAMILY_STEPS.CHILDREN]: {
      open: isOpen,
      steps: {},
    },
    [ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS]: {
      open: isOpen,
      steps: {},
    },
    [ADD_FAMILY_STEPS.AGREEMENT]: {
      open: isOpen,
    },
    [ADD_FAMILY_STEPS.SUBMITTED]: {
      open: isOpen,
    },
  },
});

const initialState = {
  family: {},
  application: {
    additionalParents: [],
    children: [],
    status: null,
    applicant: {},
  },
  householdId: null,
  loading: false,
  error: false,
  isFormSubmitting: false,
  documents: [],
  snapshot: null,
  isEditable: true,
  openSteps: getDefaultOpenSteps(OPEN_STATUS),
};

const selectFamily = createSelector(
  (state) => state.family,
  (familyState) => familyState?.family,
);
const selectApplication = createSelector(
  (state) => state.family,
  (familyState) => familyState?.application,
);
const selectApplicationSubmitted = createSelector(
  (state) => state.family,
  (familyState) => {
    const order = familyState.application?.status?.status?.order || 1;
    const isSubmitted = order > 1;
    return isSubmitted;
  },
);

const selectSnapshot = createSelector(
  (state) => state.family,
  (state) => state?.snapshot,
);
const selectHousehold = createSelector(
  (state) => state.family,
  (state) => state?.household || {},
);
const selectLoading = createSelector(
  (state) => state.family,
  (state) => state?.loading || false,
);
const selectOpenSteps = createSelector(
  (state) => state.family,
  (state) => state?.openSteps || {},
);
const selectOpenTabs = createSelector(
  (state) => state.family,
  (state) => state?.openTabs || {},
);

const selectIsFormSubmitting = createSelector(
  (state) => state.family,
  (state) => state.isFormSubmitting || false,
);

export const getApplication = createAsyncAction('family/getApplication');

export const familyStateSlice = createSlice({
  name: 'family',
  initialState,
  reducers: {
    setApplication: (state, { payload }) => {
      if (isEmpty(payload)) {
        state.application = initialState.application;
      } else {
        let application = { ...state.application, ...payload };
        if (!application.additionalParents) {
          application.additionalParents = [];
        }
        if (!application.children) {
          application.children = [];
        }
        const order = application?.status?.status?.order || 1;
        const isSubmitted = order > 1;
        updateOpenStepsState(state.openSteps, isSubmitted, application);
        state.application = application;
      }
      state.loading = false;
    },
    setFamily: (state, { payload }) => {
      if (isEmpty(payload)) {
        state.family = initialState.family;
      } else {
        let family = { ...state.family, ...payload };
        state.family = family;
      }
    },
    setHousehold: (state, { payload }) => {
      state.household = payload;
    },
    setSnapshot: (state, { payload }) => {
      state.snapshot = payload;
    },
    setIsFormSubmitting: (state, { payload }) => {
      state.isFormSubmitting = payload;
    },
    setIsEditable: (state, { payload }) => {
      state.isEditable = payload;
    },
    setOpenSteps: (state, { payload }) => {
      state.openSteps = payload;
    },
    setOpenTabs: (state, { payload }) => {
      state.openTabs = { ...state.openTabs, ...payload };
    },
    updateOpenSteps: (state, { payload }) => {
      const application = state.application;
      const order = application?.status?.status?.order || 1;
      const isSubmitted = order > 1;

      updateOpenStepsState(state.openSteps, payload || isSubmitted, application);
      state.openSteps.steps[ADD_FAMILY_STEPS.PROGRAMS].open = true;
      state.openSteps.steps[ADD_FAMILY_STEPS.SPONSOR].open = true;
    },
    resetOpenSteps: (state) => {
      state.openSteps = getDefaultOpenSteps(false);
    },
    goNext: () => {},
  },
  extraReducers: {
    [RESET_ALL]: () => {
      return initialState;
    },
    [getApplication]: (state) => {
      state.loading = true;
      state.application = {};
    },
    [getApplication.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.application = payload;
    },
    [getApplication.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
const reducer = familyStateSlice.reducer;
const actions = {
  ...familyStateSlice.actions,
  getApplication,
};
const selectors = {
  selectApplication,
  selectFamily,
  selectApplicationSubmitted,
  selectLoading,
  selectIsFormSubmitting,
  selectSnapshot,
  selectOpenSteps,
  selectOpenTabs,
  selectHousehold,
};

export function* getApplicationSaga({ payload }) {
  const role = yield select(selectActiveRole);
  try {
    if (payload) {
      const data = yield call(familyService.getApplication, payload);
      // const adults = yield call(familyService.getAdults, data.householdId);
      // const children = yield call(familyService.getChildren, data.householdId);
      const [adults, children] = yield all([
        call(familyService.getAdults, data.householdId),
        call(familyService.getChildren, data.householdId),
      ]);

      const application = data || initialState.application;
      application.adults = adults;
      application.applicant = {
        ...application.applicant,
        ...adults.find((adult) => adult.id === application.applicant?.id),
      };
      application.additionalParents = application.additionalParents.map((parent) => {
        const adult = adults.find((adult) => adult.id === parent.id);
        return { ...parent, ...adult };
      });
      application.children = (application.children || []).map((child) => {
        const person = children.find((ch) => ch.id === child.id);
        return { ...child, ...person };
      });
      yield put(getApplication.fulfilled(application));
      yield put(actions.updateOpenSteps(role !== ROLES.FAMILY));
      yield put(actions.setIsFormSubmitting(false));
    } else {
      yield put(getApplication.fulfilled(initialState.application));
      yield put(actions.updateOpenSteps(role !== ROLES.FAMILY));
      yield put(actions.setIsFormSubmitting(false));
    }
  } catch (error) {
    newrelic.noticeError(error);
    yield put(getApplication.rejected(error));
    yield put(getApplication.fulfilled(initialState.application));
    message.error({
      content: 'Unable to get application data.',
      duration: 2,
      onClose: () => {
        window.location.replace('/applications');
      },
    });
  }
}

function updateOpenStepsState(openSteps, isOpen, application) {
  const eligibiltyState = openSteps.steps[ADD_FAMILY_STEPS.ELIGIBILITY];
  const sponsorState = openSteps.steps[ADD_FAMILY_STEPS.SPONSOR];
  const secondParentsState = openSteps.steps[ADD_FAMILY_STEPS.SECOND_PARENTS];
  const childrenState = openSteps.steps[ADD_FAMILY_STEPS.CHILDREN];

  eligibiltyState.open = true;
  sponsorState.open = isOpen;

  // Update the open status for the new steps
  const startState = openSteps.steps[ADD_FAMILY_STEPS.START];
  const programsState = openSteps.steps[ADD_FAMILY_STEPS.PROGRAMS];
  startState.open = true;
  programsState.open = isOpen || programsState.open;

  if (application.additionalParents?.length > 0) {
    application.additionalParents.forEach((_, index) => {
      secondParentsState.steps[index.toString()] =
        secondParentsState.steps[index.toString()] ??
        produce(secondParentInitialState, getCallback(openSteps, isOpen, application));
    });
    function getCallback(openSteps, isOpen, application) {
      return (draft) => {
        draft.open = isOpen || draft.open || false;
      };
    }
  }
  if (application.children?.length > 0) {
    application.children.forEach((_, index) => {
      childrenState.steps[index.toString()] = {
        open: index === 0 || childrenState.steps[index.toString()]?.open || isOpen || false,
      };
    });

    childrenState.open = isOpen || false;
  }
}
export function* goNextSaga({ payload }) {
  const openSteps = yield select(selectors.selectOpenSteps);
  const application = yield select(selectors.selectApplication);
  const { paths, prefixUrl, history } = payload;
  const { step } = paths;
  let nextStep;
  nextStep = step;

  // 1st Step - Start
  if (step === ADD_FAMILY_STEPS.START) {
    nextStep = ADD_FAMILY_STEPS.PROGRAMS;
  }
  // 2nd Step - Programs
  else if (step === ADD_FAMILY_STEPS.PROGRAMS) {
    nextStep = ADD_FAMILY_STEPS.SPONSOR;
  }
  // 1st Step
  if (step === ADD_FAMILY_STEPS.ELIGIBILITY) {
    nextStep = ADD_FAMILY_STEPS.SPONSOR;
  }
  // 2nd Step
  else if (step === ADD_FAMILY_STEPS.SPONSOR) {
    nextStep = ADD_FAMILY_STEPS.SECOND_PARENTS;

    // 3rd Step
  } else if (step === ADD_FAMILY_STEPS.SECOND_PARENTS) {
    if ((application.additionalParents || []).length !== 0) {
      nextStep = ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS;
    } else {
      nextStep = ADD_FAMILY_STEPS.CHILDREN;
    }
  } else if (step === ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS) {
    nextStep = ADD_FAMILY_STEPS.CHILDREN;
  } else if (step === ADD_FAMILY_STEPS.CHILDREN) {
    nextStep = ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS;
  } else if (step === ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS) {
    nextStep = ADD_FAMILY_STEPS.AGREEMENT;
  } else if (step === ADD_FAMILY_STEPS.AGREEMENT) {
    nextStep = ADD_FAMILY_STEPS.SUBMITTED;
  } else if (step === ADD_FAMILY_STEPS.SUBMITTED) {
    nextStep = ADD_FAMILY_STEPS.SPONSOR;
  }

  let nextUrl = `${prefixUrl}/${nextStep}`;

  const newOpenSteps = produce(openSteps, (draft) => {
    if (nextStep === ADD_FAMILY_STEPS.SPONSOR) {
      draft.steps[ADD_FAMILY_STEPS.SPONSOR].open = true;
    } else if (nextStep === ADD_FAMILY_STEPS.SECOND_PARENTS) {
      draft.steps[ADD_FAMILY_STEPS.SECOND_PARENTS].open = true;
    } else if (nextStep === ADD_FAMILY_STEPS.ELIGIBILITY) {
      draft.steps[ADD_FAMILY_STEPS.ELIGIBILITY].open = true;
    } else if (nextStep === ADD_FAMILY_STEPS.CHILDREN) {
      draft.steps[ADD_FAMILY_STEPS.CHILDREN].open = true;
    } else if (nextStep === ADD_FAMILY_STEPS.AGREEMENT) {
      draft.steps[ADD_FAMILY_STEPS.AGREEMENT].open = true;
    } else if (nextStep === ADD_FAMILY_STEPS.SUBMITTED) {
      if (/\/applications\/\d+\/review/.test(prefixUrl)) {
        nextUrl = `${prefixUrl}/${ADD_FAMILY_STEPS.SPONSOR}`;
      }
      draft.steps[ADD_FAMILY_STEPS.SUBMITTED].open = true;
    }
  });

  history.push(nextUrl);
  yield put(actions.setOpenSteps(newOpenSteps));
}

function* sagas() {
  yield all([takeEvery(getApplication, getApplicationSaga), takeEvery(familyStateSlice.actions.goNext, goNextSaga)]);
}

export { reducer, actions, selectors, initialState, sagas };
export default familyStateSlice;
