import React from 'react';
import classNames from 'classnames';
import { List } from 'antd';

import useBreakpoints from 'hooks/useBreakpoints';
import { getValue } from 'utils';
import styles from './ExpandedRowList.module.less';

export default function ExpandedRowList({ className, data, columns }) {
  const { screen } = useBreakpoints();
  return (
    <div className={classNames(styles.expandedRowList, className)}>
      <List
        itemLayout="horizontal"
        dataSource={columns}
        renderItem={({ dataIndex, render, headerText, skip }) => {
          const value = getValue(data, Array.isArray(dataIndex) ? dataIndex : [dataIndex]);
          if (skip === true || skip?.[screen]) {
            return null;
          }
          return (
            <List.Item className="py-0.5 min-h-full">
              <div className="flex-col w-full h-full">
                <span className="text-gray-300 text-xs">{headerText}</span>
                <div className="-mt-1 w-full">{typeof render === 'function' ? render(value, data) : value}</div>
              </div>
            </List.Item>
          );
        }}
      />
    </div>
  );
}
