import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveRole } from 'features/auth';
import { selectors as providerSelectors } from 'features/add-center';
import { selectors as familySelectors } from 'features/family';
import { ENTITY_TYPES } from 'constants/index';

export default function useApplicationOwner({ entityType = ENTITY_TYPES.CARE_FACILITY }) {
  const activeRole = useSelector(selectActiveRole);
  const provider = useSelector(providerSelectors.selectCenterState);
  const family = useSelector(familySelectors.selectApplication);
  const providerLoading = useSelector(providerSelectors.selectLoading);
  const familyLoading = useSelector(familySelectors.selectLoading);
  const isProvider = entityType ? entityType === ENTITY_TYPES.CARE_FACILITY : activeRole?.includes('Provider');
  const owner = useMemo(() => {
    let owner;
    if (isProvider) {
      owner = provider?.staff?.[0]?.person;
    } else {
      owner = family?.applicant;
    }
    return owner;
  }, [family?.applicant, isProvider, provider?.staff]);
  const loading = useMemo(() => {
    return isProvider ? providerLoading : familyLoading;
  }, [familyLoading, isProvider, providerLoading]);

  return { owner, loading };
}
