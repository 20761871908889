import PropTypes from 'prop-types';
import React from 'react';
import Form from 'components/Form';

import styles from './FormField.module.less';

export default function FormField({ name, label, rules, children, className }) {
  return (
    <div className={styles.formField}>
      <Form.Item name={name} label={label} rules={rules} className={className}>
        {children}
      </Form.Item>
    </div>
  );
}

FormField.propTypes = {
  children: PropTypes.any,
  autoFocus: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  rules: PropTypes.array,
};

FormField.defaultProps = {
  rules: [],
  className: '',
};
