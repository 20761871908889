import ProviderHelpEmailModal from 'components/Modals/ProviderHelpEmailModal';
import React, { useState } from 'react';
import classNames from 'classnames';
import { AiOutlineMail } from 'react-icons/ai';

import styles from './ProviderHelpButtons.module.less';

export default function ProviderHelpButtons({ className }) {
  const [helpPopupVisible, setHelpPopupVisible] = useState(false);
  return (
    <div className={classNames(styles.helpButtons, className)}>
      {/* <div className={classNames(styles.helpButtons, className)}> */}
      {/* <button className="support-btn mb-5 flex items-center" data-testid="provider-chat-btn">
        <span className="text">Chat with a representative</span>
        <div className="box shadow-sm">
          <AiFillMessage />
        </div>
      </button> */}
      <button
        className="support-btn flex items-center mt-[2px]"
        onClick={() => setHelpPopupVisible(true)}
        data-testid="provider-email-help-btn"
      >
        <span className="text">Email a representative</span>
        <div className="box shadow-sm">
          <AiOutlineMail />
        </div>
      </button>
      <ProviderHelpEmailModal visible={helpPopupVisible} setVisible={setHelpPopupVisible} />
    </div>
  );
}
