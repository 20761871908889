import React, { useMemo } from 'react';
import Table from 'components/Table';
import useMilitaryInfo from 'hooks/useMilitaryInfo';

export default function AdultMilitaryInfoTable({ record, value }) {
  const { component, installation, status, branch } = useMilitaryInfo({ person: value });
  const columns = useMemo(
    () => [
      {
        title: <span className="action-header">Branch</span>,
        headerText: 'Branch',
        dataIndex: ['position', 'programSponsorId'],
        key: 'branch',
        ellipsis: true,
        width: 120,
        render: () => branch?.title || 'N/A',
      },
      {
        title: <span className="action-header">Component</span>,
        headerText: 'Component',
        dataIndex: ['position', 'militaryComponentId'],
        key: 'component',
        ellipsis: true,
        width: 150,
        render: (componentId) => component?.title || 'N/A',
      },
      {
        title: <span className="action-header">Status</span>,
        headerText: 'Status',
        dataIndex: ['position', 'militaryStatusId'],
        key: 'status',
        ellipsis: true,
        render: (militaryStatusId) => status?.title || 'N/A',
        width: 150,
      },
      {
        title: <span className="action-header">Grade</span>,
        headerText: 'Grade',
        dataIndex: ['position', 'grade'],
        key: 'grade',
        ellipsis: true,
        render: (value) => value?.title || 'N/A',
        width: 150,
      },
      {
        title: <span className="action-header">Recruiter Type</span>,
        headerText: 'Recruiter Type',
        dataIndex: ['position', 'recruiterType'],
        key: 'recruiterType',
        ellipsis: true,
        render: (value) => value?.title || 'N/A',
        width: 150,
      },
      {
        title: <span className="action-header">Installation</span>,
        headerText: 'Installation',
        dataIndex: ['position', 'militaryInstallationId'],
        key: 'installation',
        ellipsis: true,
        render: () => installation?.name || 'N/A',
        width: 150,
      },
    ],
    [branch?.title, component?.title, installation?.name, status?.title],
  );
  return (
    <div className="p-6">
      <h3 className="section-title mb-4">Military Information</h3>
      <Table
        rowKey="id"
        allColumns={columns}
        data={[record]}
        loading={false}
        pagination={false}
        showColSeparator={false}
        scroll={{ x: 1200 }}
      ></Table>
    </div>
  );
}
