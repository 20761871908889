import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import ProtectedRoute from 'components/ProtectedRoute';
import FamilyApplications from './FamilyApplications';
import ApplicationNavBar from 'components/ApplicationNavBar';
import { selectors } from 'features/family';
import useBreakpoints from 'hooks/useBreakpoints';
import { ENTITY_TYPES } from 'constants/index';
import FamilyApplication from './FamilyApplication';
import FamilyCertificates from './FamilyCertificates';

export default function FamilyHouseHold({ match }) {
  const { params } = match;
  const { householdId } = params;
  const application = useSelector(selectors.selectFamily);
  const breakpoints = useBreakpoints();

  const navItems = useMemo(
    () => [
      {
        label: 'Applications',
        key: 'applications',
        navigationKey: ['applications'],
        regex: /^\/families\/\d+$|\/families\/\d+\/applications$/,
        to: `/families/${householdId}/applications`,
      },
      {
        label: 'Documents',
        key: 'documents',
        navigationKey: ['documents'],
        regex: /^\/families\/\d+\/documents$/,
        to: `/families/${householdId}/documents`,
      },
      {
        label: 'Certificates',
        key: 'certificates',
        navigationKey: ['certificates'],
        regex: /^\/families\/\d+\/certificates$/,
        to: `/families/${householdId}/certificates`,
      },
      {
        label: 'Messages',
        key: 'messages',
        navigationKey: ['messages'],
        regex: /^\/families\/\d+\/messages$/,
        to: `/families/${householdId}/messages`,
      },
      {
        label: 'Alerts',
        key: 'alerts',
        navigationKey: ['alerts'],
        regex: /^\/families\/\d+\/alerts$/,
        to: `/families/${householdId}/alerts`,
      },
      {
        label: 'Family Profile',
        key: 'profile',
        navigationKey: ['profile'],
        regex: /^\/families\/\d+\/profile$/,
        to: `/families/${householdId}/profile`,
      },
    ],
    [householdId],
  );

  return (
    <div>
      <ApplicationNavBar
        breakpoints={breakpoints}
        items={navItems}
        entity={application}
        entityType={ENTITY_TYPES.HOUSEHOLD}
        toMail={''}
        className="bg-light-bg"
      />

      <Switch>
        <ProtectedRoute
          exact
          path={['/families/:householdId', '/families/:householdId/applications']}
          message="Navigated to Family Applications Page"
        >
          <FamilyApplications householdId={householdId} />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={['/families/:householdId/certificates']}
          message="Navigated to Family Certificates Page"
        >
          <FamilyCertificates householdId={householdId} />
        </ProtectedRoute>

        <ProtectedRoute exact path={['/families/:householdId/documents']}>
          <div>WIP</div>
        </ProtectedRoute>

        <ProtectedRoute
          path={['/families/:householdId/applications/:id']}
          message="Navigated to Application Overview Page"
        >
          <FamilyApplication />
        </ProtectedRoute>
      </Switch>
    </div>
  );
}
