import React, { useCallback, useMemo, useState } from 'react';
import { Button, Segmented } from 'antd';

import Emails from 'components/Emails';
import useTablePagination from 'hooks/useTablePagination';
import { EMAIL_TYPES } from 'constants/index';
import Card from 'components/Card/Card';
import { Renew } from '@carbon/icons-react';

const PAGE_SIZE = 10;

export default function ExternalAllEmailsPage({ className, entityLabel }) {
  const mapper = useCallback((obj) => obj, []);

  const sentEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      scheduled: false,
      incoming: true, // incoming for system/internal users but outgoing for Provider/Family
      statuses: ['DELIVERED', 'QUEUED', 'ERROR'],
      sort: 'id,desc',
    }),
    [],
  );
  const sentEmailsData = useTablePagination({
    url: `/communications/email`,
    params: sentEmailParams,
    mapper,
  });

  const incomingEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      scheduled: false,
      incoming: false, // outgoing for system/internal users but incoming for Provider/Family
      statuses: ['DELIVERED', 'QUEUED', 'ERROR'],
      sort: 'id,desc',
    }),
    [],
  );
  const incomingEmailsData = useTablePagination({
    url: `/communications/email`,
    params: incomingEmailParams,
    mapper,
  });

  const [activeTab, setActiveTab] = useState('Inbox');

  return (
    <div className={className}>
      <Card
        noBodyPadding
        title={
          <Segmented
            options={['Inbox', 'Sent']}
            onChange={(value) => {
              setActiveTab(value);
            }}
          />
        }
        extra={activeTab === 'Inbox' ? <Actions state={incomingEmailsData} /> : <Actions state={sentEmailsData} />}
      >
        {activeTab === 'Inbox' ? (
          <Emails entityLabel={entityLabel} state={sentEmailsData} type={EMAIL_TYPES.INCOMING} isExternal />
        ) : (
          <Emails entityLabel={entityLabel} state={incomingEmailsData} type={EMAIL_TYPES.SENT} isExternal />
        )}
      </Card>
    </div>
  );
}

const Actions = ({ state }) => (
  <>
    <Button
      icon={<Renew />}
      data-testid="reload-btn"
      onClick={state.reload}
      loading={state.loading}
      disabled={state.loading}
    />
  </>
);
