import { createSelector, createSlice, createAction } from '@reduxjs/toolkit';
import { call, put, throttle, all } from 'redux-saga/effects';
import commonService from 'services/common.service';

const initialState = {
  params: {},
  data: [],
  dataMap: {},
  loading: false,
  hasMore: true,
  total: 0,
  page: 0,
  error: '',
};

export const selectProviders = createSelector(
  (state) => state.providers,
  (providers) => providers || initialState,
);

export const getProviders = {
  pending: createAction('providers/getProviders/pending'),
  fulfilled: createAction('providers/getProviders/fulfilled'),
  rejected: createAction('providers/getProviders/rejected'),
};

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {},
  extraReducers: {
    [getProviders.pending]: (state, { payload: { params } }) => {
      state.loading = true;
      state.params = params;
      state.page = params.page || 0;
    },
    [getProviders.fulfilled]: (state, { payload: { count, data, last } }) => {
      state.loading = false;
      state.hasMore = !last;
      state.total = count;
      state.data = data;
      // if (state.page === 0) {
      //   state.dataMap = {};
      //   state.data = [];
      // }
      // data.forEach((element) => {
      //   if (!state.dataMap[element.id]) {
      //     state.dataMap[element.id] = true;
      //     state.data.push(element);
      //   }
      // });
    },
    [getProviders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
const reducer = providersSlice.reducer;
const actions = {
  getProviders,
  ...providersSlice.actions,
};
const selectors = {
  selectProviders,
};
export function* getProvidersSaga({ payload }) {
  let { params } = payload;
  try {
    const data = yield call(commonService.getGccProviders, params);
    yield put(getProviders.fulfilled(data));
  } catch (error) {
    newrelic.noticeError(error);
    yield put(getProviders.rejected(error));
  }
}

function* sagas() {
  yield all([throttle(500, getProviders.pending, getProvidersSaga)]);
}

export { reducer, actions, selectors, initialState, sagas };
export default providersSlice;
