import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Input, Button, message, Spin } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';

import Form from 'components/Form';
import Modal from 'components/Modal';
import providerService from 'services/providers.service';
import DragDropUpload from 'components/DragDropUpload';
import styles from './ReplyEmailModal.module.less';
import { parseUploadData } from 'utils';
import { selectUser } from 'features/auth';
import { Add, Subtract } from '@carbon/icons-react';

const EmailReplyModal = ({ setVisible, visible, getContainer, center }) => {
  const userInfo = useSelector(selectUser);
  const [replyEmailForm] = Form.useForm();

  const [{ loading: sendingEmail }, sendEmail] = useAsyncFn(
    async (values) => {
      values = { ...parseUploadData(values, 'attachments', center, userInfo) };
      try {
        await providerService.sendReplyEmail(values);
        message.success('Email will be sent in few seconds.');
        setVisible(false);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to reply.', 5);
      }
    },
    [center?.id],
  );

  useEffect(() => {
    if (center?.businessEmail) {
      replyEmailForm.setFieldsValue({ toMail: [{ email: center.businessEmail }] });
    } else {
      replyEmailForm.setFieldsValue({ toMail: [{ email: '' }] });
    }
  }, [center, replyEmailForm]);

  // TODO: Subject and email body are not required in the mock up but i think without subject and body email will not be sent, need to confirm.

  return (
    <Modal
      destroyOnClose={false}
      setVisible={setVisible}
      width={900}
      visible={visible}
      closable
      data-testid="reply-email-modal"
      getContainer={getContainer}
      title="Editing Automatic Email Response"
    >
      <div className={styles.customForm}>
        <Spin spinning={sendingEmail}>
          {
            <Form form={replyEmailForm} layout="vertical" id="reply-form" onFinish={sendEmail}>
              <div className="flex flex-col sm:flex-row md:items-baseline width-full md:w-3/4 xl:1/2">
                <div className="label mr-3 mb-3 md:mb-0">
                  <span className="text-md font-semibold uppercase">Sent To:</span>
                </div>

                <div className="emails flex-1" data-testid="recipients-container">
                  <Form.List name="toMail">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, index) => (
                          <div key={key} className="flex items-start toMail">
                            <Form.Item
                              {...restField}
                              name={[name, 'email']}
                              rules={[
                                { required: true, message: 'Email is required.' },
                                { type: 'email', message: 'Email is invalid' },
                              ]}
                              className={classNames(restField.className, 'flex-1 md:w-3/4')}
                            >
                              <Input placeholder="Email" />
                            </Form.Item>

                            {index > 0 && (
                              <Button
                                className="!w-9 !h-9 ml-2 rounded-full"
                                onClick={() => remove(name)}
                                icon={<Subtract />}
                                data-testid="remove-recipient"
                              />
                            )}
                            {index === 0 && (
                              <Button
                                className="!w-9 !h-9 ml-2 rounded-full"
                                onClick={() => add()}
                                icon={<Add />}
                                data-testid="add-recipient"
                              />
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </Form.List>
                </div>
              </div>

              <Divider className="!mt-1" />

              <Form.Item
                name={['subjects', 0]}
                label="Subject"
                rules={[{ required: true, message: 'Subject is required.' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                id="message"
                name="body"
                label="Email Message"
                rules={[{ required: true, message: 'Message is required' }]}
              >
                <Input.TextArea autoSize={{ minRows: 8 }} placeholder="Please add your custom message here" />
              </Form.Item>
              <Form.Item label="Attach any supporting documents" name="attachments" className="w-full">
                <DragDropUpload
                  name="attachments"
                  entityType="Attachment"
                  abbr="OTHER"
                  keyPrefix="forms/center-details/accreditation"
                />
              </Form.Item>
              <div className="actions flex">
                <Button data-testid="cancel-button" onClick={() => setVisible(false)} disabled={sendingEmail}>
                  Cancel
                </Button>
                <Button
                  data-testid="send-email-button"
                  htmlType="submit"
                  loading={sendingEmail}
                  disabled={sendingEmail}
                  type="primary"
                  className="w-20"
                >
                  Send
                </Button>
              </div>
            </Form>
          }
        </Spin>
      </div>
    </Modal>
  );
};

export default EmailReplyModal;

EmailReplyModal.propTypes = {
  setVisible: PropTypes.func,
  visible: PropTypes.bool,
  getContainer: PropTypes.string,
  center: PropTypes.object,
};
EmailReplyModal.defaultProps = {
  visible: false,
  setVisible: () => {},
  getContainer: '',
  center: {},
};
