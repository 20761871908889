import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function FilterContainer({ id, title, children, className, titleClassName }) {
  return (
    <div className={classNames('filter-content', className)}>
      <h3 className={titleClassName} id={`${id}-title`}>
        {title}
      </h3>
      {children}
    </div>
  );
}

FilterContainer.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};
