import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import GoogleMapReact from 'google-map-react';

import Marker from 'components/Marker';
// import usePrevious from 'hooks/usePrevious';
import styles from './Map.module.less';
import { getCompletedAddress } from 'utils';
import config from 'config';

function Map({ className, pointers, onMarkerClick, selected, place, center, setCenter, setSelectedMarker }) {
  const [zoom, setZoom] = useState(12);
  const picker = useRef();
  const gmap = useRef();
  const [mapReference, setMapReference] = useState(null);
  const props = {
    center: selected ? { lat: selected.lat, lng: selected.lng + 0.05 } : center,
  };

  useEffect(() => {
    if (mapReference) {
      google.maps.event.addListener(mapReference, 'dragend', function () {
        setSelectedMarker(null);
      });
    }
  }, [mapReference, setSelectedMarker]);

  // Whenever selected provider changes, reset zoom level
  useEffect(() => {
    setZoom(12);
  }, [selected]);

  useEffect(() => {
    const map = gmap.current.map_;
    if (map && Array.isArray(pointers) && pointers.length !== 0) {
      // Set bounds
      const bounds = new google.maps.LatLngBounds();
      pointers.forEach((pointer) => {
        bounds.extend(new google.maps.LatLng(pointer.lat, pointer.lng));
      });

      // Fit map to bounds
      map.fitBounds(bounds, 70);

      // Reset zoom level
      const _zoomLevel = getZoomLevelBasedOnBounds(
        bounds,
        gmap.current.boundingRect_?.width,
        gmap.current.boundingRect_?.height,
      );
      setZoom(_zoomLevel);
    }
  }, [pointers, setCenter]);

  useEffect(() => {
    setTimeout(() => {
      // This is to fix accessibility issue with
      document.querySelectorAll('.gm-control-active > img').forEach((img) => {
        img.alt = '';
      });
    }, 3000);
  }, []);

  return (
    <div className={styles.map} data-testid="map-container">
      <div className={classNames('map-container', className)} id="map-container">
        <GoogleMapReact
          ref={gmap}
          bootstrapURLKeys={{
            key: config.REACT_APP_GOOGLE_MAP_API_KEY,
            libraries: ['geometry', 'drawing', 'places'],
          }}
          zoom={zoom}
          defaultZoom={5}
          defaultCenter={{ lat: 32.705002, lng: -97.12278 }}
          // defaultCenter={pointers[0]}
          yesIWantToUseGoogleMapApiInternals
          options={{
            // styles: MAP_THEMES[theme],
            gestureHandling: 'greedy',
            fullscreenControl: false,
          }}
          {...props}
          onGoogleApiLoaded={({ map, maps }) => {
            setMapReference(map);
            // const bounds = getMapBounds(maps, pointers);
            // Fit map to bounds
            // map.fitBounds(bounds);
          }}
          onChange={(event) => {
            if (event.zoom !== zoom) {
              setZoom(event.zoom);
            }
            const notSelectedCenter =
              Math.abs(event.center.lat - (selected?.lat || 0)) > 0.0000001 ||
              Math.abs(event.center.lng - (selected?.lng || 0)) > 0.0000001;
            if (mapReference && notSelectedCenter) {
              // const bounds = mapReference?.getBounds();
              // const { g: latMin, i: latMax } = bounds.Ua;
              // const { g: lngMin, i: lngMax } = bounds.La;
              // const center = { lat: (latMin + latMax) / 2, lng: (lngMin + lngMax) / 2 };
              setCenter(event.center);
            }
          }}
          onClick={(location) => {
            if (picker.current) {
              picker.current(location);
            }
          }}
        >
          {/* Current location marker */}
          {place && <CurrentLocationMarker lat={place.point.lat} lng={place.point.lng} />}

          {pointers.map((point) => {
            if (selected && selected.id === point.id) return null;
            return (
              <Marker
                lat={point.lat}
                lng={point.lng}
                key={`marker-${point.id}`}
                id={`marker-${point.id}`}
                isActive={false}
                onClick={(e, provider) => {
                  if (picker.current) {
                    picker.current({
                      lat: provider.lat,
                      lng: provider.lng,
                      address: getCompletedAddress(provider?.address),
                    });
                  }
                  onMarkerClick(provider);
                  // setVisible(true);
                }}
                setProvider={onMarkerClick}
                point={point}
              />
            );
          })}

          {/* to move it on top of all pointers */}
          {selected && (
            <Marker
              lat={selected.lat}
              lng={selected.lng}
              id={`marker-${selected.id}`}
              isActive
              focused
              onClick={(e, provider) => {
                if (picker.current) {
                  picker.current({
                    lat: provider.address?.lat,
                    lng: provider.address?.lng,
                    address: getCompletedAddress(provider?.address),
                  });
                }
                onMarkerClick(provider);
              }}
              setProvider={onMarkerClick}
              point={selected}
            />
          )}
        </GoogleMapReact>
      </div>
    </div>
  );
}

Map.propTypes = {
  selected: PropTypes.object,
  place: PropTypes.object,
  center: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  onMarkerClick: PropTypes.func,
  setSelectedMarker: PropTypes.func,
  setCenter: PropTypes.func,
  pointers: PropTypes.array,
  zoom: PropTypes.number,
  screen: PropTypes.string,
};
Map.defaultProps = {
  className: '',
  dropdownClassName: '',
  pointers: [],
  zoom: 14,
  onMarkerClick: () => {},
};

export default memo(Map);

function CurrentLocationMarker() {
  return (
    <div
      style={{
        height: '18px',
        width: '18px',
        backgroundColor: 'rgb(46, 91, 255)',
        border: '3px solid white',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0px 0px 0px 6px rgba(46, 91, 255, 0.3)',
        userSelect: 'none',
      }}
    />
  );
}

function getZoomLevelBasedOnBounds(bounds, width, height) {
  const REFERENCE_DIMENSIONS = { height: 256, width: 256 };
  const MAX_ZOOM_LEVEL = 21;

  const calculateLatRad = (lat) => Math.log(Math.tan((lat * Math.PI) / 180 / 2 + Math.PI / 4));

  const northeast = bounds.getNorthEast();
  const southwest = bounds.getSouthWest();

  const latFraction = (calculateLatRad(northeast.lat()) - calculateLatRad(southwest.lat())) / Math.PI;

  const lngDifference = northeast.lng() - southwest.lng();
  const lngFraction = (lngDifference < 0 ? lngDifference + 360 : lngDifference) / 360;

  const latZoomLevel = Math.floor(Math.log(height / REFERENCE_DIMENSIONS.height / latFraction) / Math.LN2);
  const lngZoomLevel = Math.floor(Math.log(width / REFERENCE_DIMENSIONS.width / lngFraction) / Math.LN2);

  return Math.min(latZoomLevel, lngZoomLevel, MAX_ZOOM_LEVEL);
}
