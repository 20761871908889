import React, { useState, useEffect, useRef } from 'react';
import produce from 'immer';
import { Button, Checkbox, Input, InputNumber, message, Radio, Spin } from 'antd';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  dependencyRequiredValidator,
  dependencyNumberValidator,
  parseDocumentsForServer,
  parseDocuments,
  copy,
} from 'utils';
import Form from 'components/Form';
import Select from 'components/Select';
import FeeRates from 'components/FeeRates';
import FormItem from 'components/FormItem';
// import AgeGroups from 'components/AgeGroups';
// import SwitchField from 'components/Switch/SwitchField';
import providerService from 'services/providers.service';
import { actions as documentActions, selectors as documentSelectors } from 'features/documents';
import { ADD_FACILITY_STEPS } from 'constants/index';
import useProviderEditDisabled from 'hooks/useProviderEditDisabled';
import Card from 'components/Card/Card';

export default function RateAndDiscounts({
  applicationSubmitted,
  center,
  actions,
  isFormSubmitting,
  loading,
  setStep,
}) {
  const [form] = Form.useForm();
  const [discountTypes, setDiscountTypes] = useState([]);
  const dispatch = useDispatch();
  const isSubmitDisabled = useProviderEditDisabled();
  const { documents = {}, allDocuments } = useSelector(documentSelectors.selectDocumentsState);

  useEffect(() => {
    // form.setFieldsValue({ ...center, chargeRegistrationFee: Boolean(center.registrationFee) });
    form.setFieldsValue(
      produce(center, (draft) => {
        draft.chargeRegistrationFee = Boolean(center.registrationFee);
        draft.application = copy(draft.application, [
          'id',
          'agreeRatePolicies',
          'rateNotes',
          'billingFrequency',
          'discountNotes',
        ]);
        // draft.hasFederalSubsidies = (draft.federalSubsidies || []).length > 0;
        // draft.hasStateLocalSubsidies = (draft.stateLocalSubsidies || []).length > 0;
        // draft.otherFederalSubsidies = (draft.federalSubsidies || [])
        //   .filter((item) => !item.type)
        //   .map((item) => ({ ...item, label: item.subsidyTitle }));
        // draft.federalSubsidies = (draft.federalSubsidies || []).filter((item) => item.type);

        // draft.otherFederalSubsidiesVisible = draft.otherFederalSubsidies.length > 0;
        // draft.stateLocalSubsidies = (draft.stateLocalSubsidies || []).map((item) => ({
        //   ...item,
        //   label: item.subsidyName,
        // }));
        draft.documents = documents;
      }),
    );
  }, [center, documents, form]);
  useEffect(() => {
    const discounts = discountTypes.map((type) => {
      const discount = (center.discounts ?? []).find((discount) => discount.type?.id === type.id);
      return discount || {};
    });

    form.setFieldsValue({
      discounts,
    });
  }, [center, form, discountTypes]);

  useEffect(() => {
    getDiscountTypes();
    async function getDiscountTypes() {
      try {
        const discountTypes = await providerService.getDiscountTypes();
        setDiscountTypes(discountTypes);
      } catch (error) {
        newrelic.noticeError(error);
        setDiscountTypes([]);
      }
    }
  }, []);

  // Keeps track of whether `next` is pressed or `save`
  const shouldGoToNextStep = useRef(false);

  const doYouOfferDiscounts = Form.useWatch('doYouOfferDiscounts', form);
  const hasActiveDiscounts = (center.discounts ?? []).some((discount) => !!discount.rate);
  useEffect(() => {
    if (hasActiveDiscounts) {
      form.setFieldsValue({ doYouOfferDiscounts: true });
    }
  }, [form, hasActiveDiscounts]);

  return (
    <div id="rates-and-discounts-tab">
      <Form
        layout="vertical"
        form={form}
        onKeyDown={(e) => (e.code === 'Enter' ? e.preventDefault() : '')}
        onFinish={async (values) => {
          let discounts = doYouOfferDiscounts
            ? values.discounts
                // order of map and filter is important
                .map((dis, index) => {
                  const discountType = discountTypes[index];
                  delete discountType.discounts;
                  return {
                    ...dis,
                    careFacility: {
                      id: center.id,
                    },
                    type: discountType,
                    discountOffered: true,
                    id: dis.id || discountTypes[index].id,
                  };
                })
                .filter((dis) => !!dis.id && !!dis.rate && !!dis.unit)
            : discountTypes;

          // let federalSubsidies = [];
          // let stateLocalSubsidies = [];
          // if (values.hasFederalSubsidies) {
          //   federalSubsidies = [
          //     ...values.federalSubsidies.map((item) => ({
          //       ...item,
          //       careFacility: {
          //         id: center?.id,
          //       },
          //     })),
          //     ...Array.from(values.otherFederalSubsidiesVisible ? values.otherFederalSubsidies || [] : []).map((v) => ({
          //       ...v,
          //       subsidyTitle: v.label,
          //       type: null,
          //       careFacility: {
          //         id: center?.id,
          //       },
          //     })),
          //   ];
          // }
          // if (values.hasStateLocalSubsidies) {
          //   stateLocalSubsidies = Array.from(values.stateLocalSubsidies || []).map((v) => ({
          //     ...v,
          //     subsidyName: v.label,
          //     careFacility: {
          //       id: center?.id,
          //     },
          //   }));
          // }

          // values.federalSubsidies = federalSubsidies;
          // values.stateLocalSubsidies = stateLocalSubsidies;

          try {
            dispatch(actions.setIsFormSubmitting(true));

            values.discounts = discounts;
            const data = await providerService.updateCenter(values, center.id);
            const { allDocuments: newAllDocuments } = await parseDocumentsForServer(values, {
              documents,
              allDocuments,
            });

            dispatch(actions.setCenterInfo(data));
            dispatch(documentActions.setAllDocuments(newAllDocuments));
            dispatch(documentActions.setDocuments(parseDocuments(newAllDocuments)));

            if (shouldGoToNextStep.current) {
              setStep(ADD_FACILITY_STEPS.DOCUMENTS);
            }

            message.success('Saved');
          } catch (error) {
            newrelic.noticeError(error);
            message.error('Unable to save rates and discounts.');
          } finally {
            dispatch(actions.setIsFormSubmitting(false));
          }
        }}
        scrollToFirstError
      >
        {(values) => {
          return (
            <Spin spinning={isFormSubmitting || loading}>
              <div className="mb-4">
                <FeeRates center={center} name="feeRates" className="mb-0" readOnly={applicationSubmitted} />
              </div>

              <Card collapsible title="Discounts" className="mb-4">
                {center?.id && (
                  <Form.Item
                    name="doYouOfferDiscounts"
                    label="do you offer discounts of any kind?"
                    rules={[
                      {
                        required: true,
                        message: 'This is required',
                      },
                    ]}
                    initialValue={applicationSubmitted ? false : undefined}
                  >
                    <Radio.Group disabled={applicationSubmitted}>
                      <Radio value={false}>No</Radio>
                      <Radio value={true}>Yes</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}

                {doYouOfferDiscounts && (
                  <>
                    {discountTypes.map((discountType, i) => (
                      <Card title={`${discountType.title} discounts`} className="mb-6">
                        <div
                          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-x-6"
                          key={discountType.id}
                        >
                          <FormItem name={['discounts', i, 'id']} label="id" hidden>
                            <InputNumber min={1} disabled />
                          </FormItem>

                          <div>
                            <FormItem
                              name={['discounts', i, 'unit']}
                              label="Unit"
                              dependencies={[['discounts', i, 'discountOffered']]}
                              rules={[
                                dependencyRequiredValidator(['discounts', i, 'discountOffered'], 'Type is required.'),
                              ]}
                              className={classNames({ 'cursor-not-allowed': !values?.discounts?.[i]?.discountOffered })}
                            >
                              <Select
                                name={`unit`}
                                options={[
                                  { title: '$ - Dollars', id: 'AMOUNT' },
                                  { title: '% - Percentage', id: 'RATE' },
                                ]}
                                isOptionSelected={(opt, [value]) => opt?.id === value}
                                onChangeFormatter={(v) => v?.id}
                                ariaLabel="Rate/Amount"
                                disabled={applicationSubmitted}
                              />
                            </FormItem>
                          </div>

                          <div>
                            <FormItem
                              name={['discounts', i, 'rate']}
                              label="Amount"
                              rules={[
                                dependencyRequiredValidator(['discounts', i, 'discountOffered'], 'Rate is required.'),
                                dependencyNumberValidator(['discounts', i, 'discountOffered']),
                              ]}
                              dependencies={[
                                ['discounts', i, 'discountOffered'],
                                ['discounts', i, 'unit'],
                              ]}
                            >
                              <InputNumber
                                min={0}
                                max={values?.discounts?.[i]?.unit === 'RATE' ? 100 : undefined}
                                disabled={applicationSubmitted}
                              />
                            </FormItem>
                          </div>

                          <div className="lg:col-start-2 lg:col-span-2 xxl:col-start-3 xxl:col-span-2">
                            <FormItem name={['discounts', i, 'additionalComments']} label="Additional Comments">
                              <Input.TextArea rows={3} disabled={applicationSubmitted} />
                            </FormItem>
                          </div>
                        </div>
                      </Card>
                    ))}

                    <FormItem name={['application', 'discountNotes']} label="Additional Information (If Required)">
                      <Input disabled={applicationSubmitted} />
                    </FormItem>
                  </>
                )}
              </Card>

              <Card collapsible title="Included costs" className="mb-4">
                <Form.Item
                  name="lunchIncluded"
                  label="does your facility include the cost of lunch in tuition?"
                  rules={[
                    {
                      required: true,
                      message: 'This is required',
                    },
                  ]}
                >
                  <Radio.Group disabled={applicationSubmitted}>
                    <Radio value={false}>No</Radio>
                    <Radio value={true}>Yes</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="formulaFeeIncluded"
                  label="does your facility include the cost of formula for infants (6 weeks - 12 months) in tuition?"
                  rules={[
                    {
                      required: true,
                      message: 'This is required',
                    },
                  ]}
                >
                  <Radio.Group disabled={applicationSubmitted}>
                    <Radio value={false}>No</Radio>
                    <Radio value={true}>Yes</Radio>
                  </Radio.Group>
                </Form.Item>
              </Card>

              <Card collapsible title="Confirm answers" className="mb-4">
                <Form.Item
                  name="agreeToDiscountTerms"
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (value) {
                          return Promise.resolve();
                        }

                        return Promise.reject('Please confirm your answers');
                      },
                    }),
                  ]}
                  valuePropName="checked"
                >
                  <Checkbox disabled={applicationSubmitted}>
                    <span style={{ color: 'red' }}>*</span>I certify that all above information is correct and all
                    discounts that I offer are listed. I understand that any changes to the discounts listed on this
                    form must be reported to Child Care Aware of America. I certify that any registration fees listed on
                    this form are used solely to hold the spot for a family and are not applied to their child care
                    tuition, facility maintenance/supplies, or activities.
                  </Checkbox>
                </Form.Item>
              </Card>

              <div className="flex space-x-2">
                <Button onClick={() => setStep(ADD_FACILITY_STEPS.CREDENTIALS)} style={{ borderRadius: '0px' }}>
                  Back
                </Button>

                <div className="flex-grow"></div>

                {!applicationSubmitted && (
                  <Button
                    loading={isFormSubmitting}
                    disabled={isFormSubmitting || isSubmitDisabled}
                    onClick={() => {
                      shouldGoToNextStep.current = false;

                      setTimeout(() => {
                        form.submit();
                      });
                    }}
                    style={{ borderRadius: '0px' }}
                  >
                    Save
                  </Button>
                )}

                <Button
                  type="primary"
                  loading={isFormSubmitting}
                  disabled={isFormSubmitting || isSubmitDisabled}
                  data-testid="center-details-submit"
                  htmlType={!applicationSubmitted ? 'submit' : undefined}
                  onClick={() => {
                    if (applicationSubmitted) {
                      setStep(ADD_FACILITY_STEPS.DOCUMENTS);
                      return;
                    }

                    shouldGoToNextStep.current = true;
                  }}
                  style={{ borderRadius: '0px' }}
                >
                  Next
                </Button>
              </div>
            </Spin>
          );
        }}
      </Form>
    </div>
  );
}
