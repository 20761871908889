import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';

import Form from 'components/Form';
import Select, { AsyncSelect } from 'components/Select';
import useEntityGroup, { GetEntityListData } from 'hooks/useEntityGroup';
import Section from 'components/Section';
import familyService from 'services/family.service';
import SwitchField from 'components/Switch/SwitchField';
import { getFullName } from 'utils';
import { FAMILY_MEMBER_TYPES } from 'constants/index';

export default function AdultFamilyRelationContainer(props) {
  const getData = useCallback(async () => {
    if (props.householdId && props.personId) {
      return familyService.getFamilyRelation(props.householdId, props.personId);
    }
  }, [props.householdId, props.personId]);

  return (
    <Form>
      <Form.Item name="family-relation" noStyle>
        <GetEntityListData getData={getData} name="family-relation" noStyle>
          <AdultFamilyRelation {...props} />
        </GetEntityListData>
      </Form.Item>
    </Form>
  );
}

export function AdultFamilyRelation({
  value,
  onChange,
  application,
  className,
  householdId,
  profileData,
  personId,
  noWrapper = false,
  customAddButton,
}) {
  const items = useMemo(
    () =>
      value?.map?.((item) => {
        const isAdult = profileData?.adults?.some((adult) => adult.id === item?.relatedPerson?.id);
        return {
          ...item,
          typeOfMember: isAdult ? { title: 'Adult', id: 'adults' } : { title: 'Child', id: 'children' },
        };
      }),
    [profileData?.adults, value],
  );

  const onAddItem = useCallback(
    async (data) =>
      familyService.addUpdateFamilyRelationship(householdId, personId, {
        ...data,
        person: { id: personId },
      }),
    [householdId, personId],
  );
  const onDeleteItem = useCallback(
    async (item) => familyService.deleteFamilyRelationship(householdId, personId, item.id),
    [householdId, personId],
  );

  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">Relationship</span>,
        headerText: 'Relationship',
        dataIndex: 'relationshipType',
        key: 'relationship',
        ellipsis: true,
        width: 160,
        render: (relationship) => relationship?.title || 'N/A',
        fixed: 'left',
      },
      {
        title: <span className="action-header">Name</span>,
        headerText: 'Name',
        dataIndex: 'relatedPerson',
        key: 'name',
        ellipsis: true,
        width: 150,
        render: (relatedPerson) => getFullName(relatedPerson) || 'N/A',
      },
      {
        title: (
          <span className="action-header">
            Same House
            <br />
            Address
          </span>
        ),
        headerText: 'Same House Address',
        dataIndex: 'livesWith',
        key: 'isLivingAlong',
        ellipsis: true,
        width: 150,
        render: (isLivingAlong) => (isLivingAlong ? 'Yes' : 'No' || 'N/A'),
      },
      {
        title: (
          <span className="action-header">
            Authorized to discuss
            <br />
            account information
          </span>
        ),
        headerText: 'Authorized to discuss account information',
        dataIndex: 'authorizedToDiscussAccountInfo',
        key: 'authDiscussion',
        ellipsis: true,
        width: 150,
        render: (authorizedToDiscussAccountInfo) => (authorizedToDiscussAccountInfo ? 'Yes' : 'No' || 'N/A'),
      },
      {
        title: (
          <span className="action-header">
            Authorized to discuss
            <br />
            my personal information
          </span>
        ),
        headerText: ' Authorized to discuss my personal information',
        dataIndex: 'authorizedToDiscussPersonalInfo',
        key: 'authPersonalInfo',
        ellipsis: true,
        render: (authorizedToDiscussPersonalInfo) => (authorizedToDiscussPersonalInfo ? 'Yes' : 'No' || 'N/A'),
        width: 150,
      },
      {
        title: (
          <span className="action-header">
            Authorized to make decisions on my behalf
            <br />
            decisions on my behalf
          </span>
        ),
        headerText: 'Authorized to make decisions on my behalf',
        dataIndex: 'authorizedToMakeDecisions',
        key: 'authDecisions',
        ellipsis: true,
        render: (authorizedToMakeDecisions) => (authorizedToMakeDecisions ? 'Yes' : 'No' || 'N/A'),
        width: 150,
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback(
    (props) => {
      return <AdultRelationshipFormFields {...props} profileData={profileData} personId={personId} />;
    },
    [personId, profileData],
  );

  const { section } = useEntityGroup({
    items,
    onChange,
    onAddItem,
    onDeleteItem,
    entity: application,
    getAllColumns,
    getFormFields,
    entityTitle: 'Family relationship',
    tableProps: {},
    customAddButton,
    onValuesChange:
      (form) =>
      ({ typeOfMember }) => {
        if (typeOfMember && typeOfMember.id === FAMILY_MEMBER_TYPES.CHILD) {
          form.setFieldsValue({
            relatedPerson: null,
            relationshipType: null,
            authorizedToDiscussAccountInfo: null,
            authorizedToDiscussPersonalInfo: null,
            authorizedToMakeDecisions: null,
          });
        } else if (typeOfMember && typeOfMember.id === FAMILY_MEMBER_TYPES.ADULT) {
          form.setFieldsValue({
            relatedPerson: null,
            relationshipType: null,
          });
        }
      },
    entityName: 'adultFamilyRelation',
  });
  if (noWrapper) return section;
  return (
    <div className={classNames(className)}>
      <Section heading="Family Relationship" collapsible={false} className={className}>
        <div>{section}</div>
      </Section>
    </div>
  );
}

function SwitchWrapper({ label, name }) {
  return (
    <div className=" flex flex-row justify-between">
      <span className="text-base block md:mt-3 mr-2">{label}</span>
      <Form.Item name={name} valuePropName="checked">
        <SwitchField className="md:mr-16 mr-0" switchProps={{ showLabels: false }} />
      </Form.Item>
    </div>
  );
}

export const AdultRelationshipFormFields = ({
  values,
  setVisible,
  items,
  form,
  item,
  index,
  addUpdateLoading,
  profileData,
  personId,
}) => {
  const options = profileData?.[values?.typeOfMember?.id] || [];
  const nameOptions = options
    ?.filter((item) => item.id !== personId)
    ?.map((item) => ({ title: getFullName(item) || 'No Name', id: item.id }));

  return (
    <div>
      <h3 className="section-title">{item?.id ? 'Edit' : 'Add'} Family Relationship</h3>
      <div className="grid grid-cols-2 gap-x-8">
        <Form.Item name="typeOfMember" label="Type of Family Member" defaultValue={{ title: 'Adult', id: 'adults' }}>
          <Select
            name="typeOfMember"
            options={[
              { title: 'Adult', id: FAMILY_MEMBER_TYPES.ADULT },
              { title: 'Child', id: FAMILY_MEMBER_TYPES.CHILD },
            ]}
            placeholder="Select"
            ariaLabel="Select Person"
          />
        </Form.Item>
        <div></div>
        <Form.Item name="relatedPerson" label="Name">
          <Select name="relatedPerson" options={nameOptions} placeholder="Select" ariaLabel="Select Person" />
        </Form.Item>
        <Form.Item name="relationshipType" label="Relationship to Adult">
          <AsyncSelect
            key={values?.typeOfMember?.id}
            name={['relationshipType']}
            placeholder="Relation"
            ariaLabel="Relationship to Child"
            optionsApiUrl={`/options/${values?.typeOfMember?.id === 'adults' ? 24 : 26}`}
            apiPrefix="/families"
          />
        </Form.Item>
      </div>
      <SwitchWrapper label="Do they live with this individual?" name="livesWith" />
      {values?.typeOfMember?.title === 'Adult' && (
        <div>
          <SwitchWrapper
            label="Are they authorized to discuss information related to your account?"
            name="authorizedToDiscussAccountInfo"
          />
          <SwitchWrapper
            label="Are they authorized to discuss your personal information?"
            name="authorizedToDiscussPersonalInfo"
          />
          <SwitchWrapper
            label="Are they authorized to make decisions on your behalf?"
            name="authorizedToMakeDecisions"
          />
        </div>
      )}
      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-family-relation-btn">
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          data-testid="save-family-relation-btn"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
