import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Button, message, Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretUp, AiOutlineEdit } from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';
import { useAsync } from 'react-async-hook';

import Table from 'components/Table';
import ColumnTitle from 'components/ColumnTitle';
import useTablePagination from 'hooks/useTablePagination';
import { formatDate, getFullName, hasApplicationExpired } from 'utils';
import { actions } from 'features/family';
import { commonService, familyService } from 'services';
import config from 'config';
import classNames from 'classnames';

export default function FamilyApplications({ householdId, className }) {
  return householdId ? (
    <FamilyProgramsApplications householdId={householdId} className={className} />
  ) : (
    <Spin className="h-screen" spinning size="large">
      <div className="h-screen flex justify-center items-center">Loading Household Information...</div>
    </Spin>
  );
}

function FamilyProgramsApplications({ householdId, className }) {
  const {
    result: householdPrograms,
    execute,
    loading,
  } = useAsync(
    async (id) => {
      try {
        return await familyService.getHouseholdPrograms(id);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Programs not found.', 5);
        return [];
      }
    },
    [householdId],
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const allColumns = useMemo(
    () => [
      {
        title: (props) => <ColumnTitle {...props} title="Program" dataIndex="program" colKey="program" showSorting />,
        headerText: 'Porgram',
        dataIndex: 'program',
        key: 'program',
        sorter: true,
        width: 100,
        fixed: 'left',
      },
      {
        title: (props) => <ColumnTitle {...props} title="Branch" dataIndex="branch" colKey="branch" />,
        headerText: 'Branch',
        dataIndex: 'branch',
        key: 'branch',
        width: 130,
        render: (branch) => branch?.title || 'N/A',
      },
      {
        title: (props) => (
          <ColumnTitle {...props} title="Installation" dataIndex="installation" colKey="installation" showSorting />
        ),
        headerText: 'Installation',
        dataIndex: 'installation',
        key: 'installation',
        sorter: true,
        width: 100,
      },
      {
        title: (props) => (
          <ColumnTitle {...props} title="ULA Complete" dataIndex="ulaComplete" colKey="ulaComplete" showSorting />
        ),
        headerText: 'ULA Complete',
        dataIndex: 'ulaComplete',
        key: 'ulaComplete',
        sorter: true,
        width: 100,
        render: (ulaComplete) => (ulaComplete ? 'Yes' : 'No'),
      },
      {
        title: (props) => <ColumnTitle {...props} title="Renewal Date" dataIndex="renewalDate" colKey="renewalDate" />,
        headerText: 'Renewal Date',
        dataIndex: 'renewalDate',
        key: 'renewalDate',
        width: 100,
      },
      {
        title: (props) => <ColumnTitle {...props} title="Status" dataIndex="status" colKey="status" showSorting />,
        headerText: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        width: 100,
      },
      {
        title: <span className="action-header mt-2 justify-center">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 90,
        render: (text, record, index) => {
          return (
            <div className="actions-cell bordered space-x-1 justify-end">
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                  } else {
                    setExpandedRows([record.id]);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                className="icon-btn"
                aria-label="Open"
                data-testid={`collapse-button-${record.id}`}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            </div>
          );
        },
      },
    ],
    [expandedRows],
  );
  const reload = useCallback(async () => {
    return await execute(householdId);
  }, [execute, householdId]);
  return (
    <div className={(className, 'p-6')}>
      <div className="white-box p-5">
        <div className="flex flex-col md:flex-row md:justify-between mb-3">
          <h3 className="text-2xl text-primary font-medium">Programs</h3>
          <div className="flex space-x-2">
            <Button type="primary" className="px-10 mt-5 md:mt-0">
              Apply To A New Program
            </Button>
            <Button
              icon={<IoMdRefresh className="!text-primary w-7 h-7" />}
              className="icon-btn bordered !h-10 !w-10"
              disabled={loading}
              loading={loading}
              onClick={reload}
              data-testid="reload-btn"
            />
          </div>
        </div>
        <Table
          allColumns={allColumns}
          data={householdPrograms}
          pagination={false}
          showColSeparator={false}
          scroll={{ x: 1200 }}
          expandable={{
            expandedRowRender: (record) => {
              return <Applications program={record} householdId={householdId} />;
            },
            rowExpandable: () => true,
            expandedRowKeys: expandedRows,
            expandIcon: () => null,
            columnWidth: 0,
          }}
          className="[&_.ant-table-tbody_.ant-table-expanded-row_>_.ant-table-cell]:!bg-gray-100 [&_.ant-table-wrapper_.ant-table-tbody_.expanded-row_td]:!bg-gray-100"
          rowClassName={(record, index) => {
            return classNames({
              'expanded-row': expandedRows[0] === record.id,
              '[&_td]:!bg-light-bg h-12': index % 2 === 0,
              '[&_td]:!bg-white h-12': index % 2 !== 0,
            });
          }}
        />
      </div>
    </div>
  );
}

const APPLICATIONS_PAGE_SIZE = 2;

function Applications({ className, householdId, program }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);

  const { result: programs } = useAsync(async () => {
    try {
      return await commonService.getPrograms();
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Programs not found.', 5);
      return [];
    }
  }, []);

  const params = useMemo(
    () => ({
      current: 1,
      pageSize: APPLICATIONS_PAGE_SIZE,
      sort: 'id,desc',
    }),
    [],
  );
  const applications = useTablePagination({
    url: `/families/households/${householdId}/applications?programId=${program?.id}`,
    params,
  });
  const reload = useCallback(async () => {
    return await applications.fetchMore({
      current: 1,
      pageSize: APPLICATIONS_PAGE_SIZE,
    });
  }, [applications]);

  useEffect(() => {
    dispatch(actions.setApplication(null));
  }, [dispatch]);

  const commonColumns = useMemo(() => getApplicationColumn(programs), [programs]);
  const allColumns = useMemo(
    () => [
      ...commonColumns,
      {
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          const isExpired = hasApplicationExpired(record, index);
          return (
            <div className="actions-cell justify-end">
              {isExpired && config.ENV !== 'uat' && (
                <Tooltip placement="top" title="Edit">
                  <Button
                    onClick={() => {
                      if (expandedRows[0] === record.id) {
                        setExpandedRows([]);
                      } else {
                        setExpandedRows([record.id]);
                      }
                    }}
                    icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    className="icon-btn"
                    aria-label="Open"
                    data-testid={`collapse-button-${record.id}`}
                    title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
                  />
                </Tooltip>
              )}
              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    history.push(`/family/application/${record.id}/dashboard`);
                    dispatch(actions.setApplication({}));
                  }}
                  icon={<AiOutlineEdit />}
                  aria-label="Edit"
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [commonColumns, expandedRows, history, dispatch],
  );

  return (
    <div className={(className, 'bg-gray-200')}>
      <div className="white-box p-5 my-5">
        <div className="flex flex-col md:flex-row md:justify-between mb-3">
          <h3 className="text-2xl">Applications</h3>
          <div className="flex space-x-2">
            <Button type="primary" className="px-10 mt-5 md:mt-0" onClick={() => history.push('/family/application')}>
              Add New Application
            </Button>
            <Button
              icon={<IoMdRefresh className="!text-primary w-7 h-7" />}
              className="icon-btn bordered !h-10 !w-10"
              disabled={applications.loading}
              loading={applications.loading}
              onClick={reload}
              data-testid="reload-btn"
            />
          </div>
        </div>
        <Table
          className="[&_.ant-table]:!min-h-[150px]"
          allColumns={allColumns}
          data={applications.data}
          loading={applications.loading}
          pagination={applications.pagination}
          onChange={applications.onChange}
          rowClassName={(record, index) => {
            const isExpired = hasApplicationExpired(record, index);
            return isExpired && config.ENV !== 'uat'
              ? '[&_td]:!bg-red-100 h-12'
              : index % 2 === 0
              ? '[&_td]:!bg-light-bg h-12'
              : '[&_td]:!bg-white h-12';
          }}
          xsCols={['id', 'actions']}
          rowKey="id"
          showColSeparator={false}
        />
      </div>
    </div>
  );
}

function getApplicationColumn(programs) {
  return [
    {
      title: (props) => <ColumnTitle {...props} title="ID" dataIndex="id" colKey="id" showSorting />,
      headerText: 'App ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: 110,
    },
    {
      title: (props) => (
        <ColumnTitle
          {...props}
          title={<span>Sponsor Name</span>}
          dataIndex={['applicant', 'firstName']}
          colKey="applicant"
          showSorting
        />
      ),
      headerText: 'Sponsor Name',
      dataIndex: ['applicant', 'firstName'],
      sorter: true,
      key: 'applicant',
      render: (value, { applicant }) => getFullName(applicant),
    },
    {
      title: (props) => (
        <ColumnTitle {...props} title="Submitted Date" dataIndex="submittedDate" colKey="submittedDate" showSorting />
      ),
      headerText: 'Submitted Date',
      dataIndex: 'submittedDate',
      key: 'submittedDate',
      sorter: true,
      render: (submittedDate) => {
        return submittedDate ? <span className="font-medium">{formatDate(submittedDate)}</span> : 'Not Submitted';
      },
      width: 250,
    },
    {
      title: (props) => (
        <ColumnTitle {...props} title="App Start Date" dataIndex="startDate" colKey="startDate" showSorting />
      ),
      headerText: 'App Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      render: (startDate) => {
        return formatDate(startDate);
      },
      width: 170,
    },
    {
      title: (props) => (
        <ColumnTitle {...props} title="App End Date" dataIndex="endDate" colKey="endDate" showSorting />
      ),
      headerText: 'App End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: true,
      render: (endDate) => {
        return formatDate(endDate);
      },
      width: 170,
    },
    // {
    //   title: (props) => <ColumnTitle {...props} title="Programs" dataIndex="program" colKey="programs" />,
    //   headerText: 'Programs',
    //   dataIndex: 'program',
    //   key: 'programs',
    //   render: (programId) => {
    //     const filteredProgram = programId && programs?.find?.((item) => item.id === programId);
    //     return (
    //       <span>
    //         {filteredProgram ? `${filteredProgram?.type?.title} (${filteredProgram?.sponsor?.title})` : 'N/A'}
    //       </span>
    //     );
    //   },
    //   width: 250,
    // },
    {
      title: (props) => (
        <ColumnTitle
          {...props}
          colKey="status"
          title="Status"
          dataIndex={['status', 'status', 'publicStatus', 'title']}
          showSorting
        />
      ),
      width: 180,
      headerText: 'Status',
      dataIndex: ['status', 'status', 'publicStatus', 'title'],
      key: 'status',
      render: (status) => status || 'N/A',
      sorter: true,
    },
  ];
}
