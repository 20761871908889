import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip, Modal as AntdModal } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { TiPlus } from 'react-icons/ti';
import { BiTrash } from 'react-icons/bi';
import { IoMdRefresh } from 'react-icons/io';
import { AiOutlineEdit } from 'react-icons/ai';
import uniqBy from 'lodash/uniqBy';

import Table from 'components/Table';
import NestedPage from 'components/NestedPage';
import familyService from 'services/family.service';
import { formatDate, getFullName } from 'utils';

export default function FamilyCertificatesTable({
  application,
  householdId,
  className,
  headingClassName,
  loading: applicationLoading,
  hideAddCertificate,
  filter = (item) => true,
}) {
  // const [key, setKey] = useState('');
  const history = useHistory();
  const {
    loading,
    result: certificates = [],
    execute: reload,
  } = useAsync(
    async (householdId) => {
      if (!householdId) return [];
      const certificates = await familyService.getHouseHoldCertificates(householdId);
      return certificates.filter(filter).sort((a, b) => b.id - a.id);
    },
    [householdId],
  );
  const { loading: deletingCertificate, execute: deleteCertificate } = useAsyncCallback(async (id) => {
    await familyService.deleteCertificate(id);
    reload(householdId);
  }, []);
  const deleteScheduleConfirm = useCallback(
    (id) => {
      AntdModal.confirm({
        title: 'Are you sure you want to delete this certificate?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => deleteCertificate(id),
      });
    },
    [deleteCertificate],
  );
  // const [visibleNewCertModal, setVisibleNewCertModal] = useState(false);
  // const allSchedules = useMemo(() => {
  //   return (
  //     application?.children?.reduce?.(
  //       (prev, child) => prev.concat(child.schedules?.map?.((sch) => ({ ...sch, child })) || []),
  //       [],
  //     ) || []
  //   );
  // }, [application?.children]);
  const allColumns = useMemo(
    () => [
      {
        title: <span className="action-header">ID</span>,
        headerText: 'id',
        dataIndex: ['id'],
        key: 'id',
        ellipsis: true,
        width: 60,
        className: 'truncate max-w-xs',
        fixed: 'left',
      },
      {
        title: <span className="action-header">Child Name</span>,
        headerText: 'Child Name',
        dataIndex: 'schedules',
        key: 'childName',
        ellipsis: true,
        width: 150,
        className: 'truncate max-w-xs',
        fixed: 'left',
        render: (schedules, record) => {
          const allSchedules =
            record.application?.children?.reduce?.(
              (prev, child) => prev.concat(child.schedules?.map?.((sch) => ({ ...sch, child })) || []),
              [],
            ) || [];
          const children = uniqBy(
            schedules?.map?.((sch) => allSchedules.find((s) => s.id === sch.id)?.child).filter(Boolean) || [],
            (obj) => obj.id,
          );
          return children?.[0] ? (
            <span title={children.map((child) => getFullName(child)).join('\n')}>
              {`${getFullName(children?.[0])}`}{' '}
              {children.length > 1 ? (
                <Tooltip
                  title={children.map((child) => (
                    <p key={child.id}>{getFullName(child) || 'Missing Name'}</p>
                  ))}
                >
                  <span className="w-4 h-4 ml-1 rounded-full bg-primary text-white text-xs cursor-pointer inline-flex justify-center items-center">
                    {children.length - 1}
                  </span>
                </Tooltip>
              ) : (
                ''
              )}
            </span>
          ) : (
            'N/A'
          );
        },
      },
      {
        title: <span className="action-header">Status</span>,
        headerText: 'Status',
        dataIndex: ['certificateStatus', 'certificateStatusType', 'description'],
        key: 'status',
        ellipsis: true,
        width: 120,
        className: 'truncate max-w-xs',
        render: (status) => <span>{status}</span>,
      },

      {
        title: <span className="action-header">Start Date</span>,
        headerText: 'Care Start Date',
        dataIndex: 'startDate',
        key: 'careStartDate',
        ellipsis: true,
        width: 100,
        className: 'truncate max-w-xs',
        render: (date) => formatDate(date),
      },
      {
        title: <span className="action-header">End Date</span>,
        headerText: 'Care End Date',
        key: 'careEndDate',
        dataIndex: 'endDate',
        align: 'left',
        width: 100,
        className: 'truncate max-w-xs',
        render: (date) => formatDate(date),
      },
      {
        title: <span className="action-header">Provider ID</span>,
        headerText: 'Provider ID',
        dataIndex: 'careFacility',
        key: 'careFacilityId',
        ellipsis: true,
        width: 120,
        render: (careFacilityId) => careFacilityId || 'N/A',
      },
      {
        title: <span className="action-header">Provider Name</span>,
        headerText: 'Provider Name',
        dataIndex: 'careFacility',
        key: 'providerName',
        ellipsis: true,
        width: 150,
        render: (careFacility) => careFacility?.businessLegalName || 'N/A',
      },
      {
        title: <span className="action-header">Care Type</span>,
        headerText: 'Care Type',
        dataIndex: ['schedules'],
        key: 'careType',
        ellipsis: true,
        width: 160,
        className: 'truncate max-w-xs',
        render: (schedules) => {
          const title = schedules?.map((sch) => sch.careDurationType?.title).join(', ');
          return schedules?.[0] ? <span title={title}>{title}</span> : 'N/A';
        },
      },
      {
        title: (
          <span className="action-header">
            Provider <br />
            Rate
          </span>
        ),
        headerText: 'Provider Rate',
        dataIndex: 'providerRate',
        key: 'providerRate',
        ellipsis: true,
        width: 90,
        className: 'truncate max-w-xs',
        render: (providerRate) => <span>{providerRate ? `$${providerRate?.toFixed?.(2)}` : 'N/A'}</span>,
      },
      {
        title: (
          <span className="action-header">
            Sponsor <br />
            Rate
          </span>
        ),
        dataIndex: 'sponsorRate',
        key: 'sponsorRate',
        ellipsis: true,
        width: 100,
        className: 'truncate max-w-xs',
        render: (sponsorRate) => <span>{sponsorRate ? `$${sponsorRate?.toFixed?.(2)}` : 'N/A'}</span>,
      },
      {
        title: (
          <span className="action-header">
            Fee <br />
            Assistance
          </span>
        ),
        headerText: 'feeAssistance',
        dataIndex: 'feeAssistance',
        key: 'feeAssistance',
        ellipsis: true,
        width: 100,
        className: 'truncate max-w-xs',
        render: (feeAssistance) => <span>{feeAssistance ? `$${feeAssistance?.toFixed?.(2)}` : 'N/A'}</span>,
      },
      {
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        align: 'center',
        width: 80,
        fixed: 'right',
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          return (
            <div className="actions-cell justify-center">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    history.push(
                      `/families/${householdId}/applications/${record?.application?.id}/review/certificates/${record.id}/update`,
                    );
                  }}
                  style={{ width: 'auto' }}
                  icon={<AiOutlineEdit />}
                  aria-label="Edit"
                />
              </Tooltip>

              <Tooltip placement="top" title="Remove">
                <Button
                  className="icon-btn alert remove-button"
                  icon={<BiTrash />}
                  aria-label="Remove"
                  onClick={() => deleteScheduleConfirm(record.id)}
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [deleteScheduleConfirm, history, householdId],
  );
  const actions = (
    <>
      {!hideAddCertificate && (
        <Button
          onClick={() => {
            history.push(`/families/${householdId}/applications/${application?.id}/review/certificates/add`);
          }}
          icon={<TiPlus className="text-primary top-2 absolute left-2" size={20} />}
        >
          <span className="px-4">Add a New Certificate</span>
        </Button>
      )}
      <Button
        className="!w-10 h-10 border border-gray-100 flex items-center justify-center"
        icon={
          <IoMdRefresh
            size={24}
            className={classNames('text-primary', {
              'animate-spin': loading,
            })}
          />
        }
        data-testid="reload-btn"
        onClick={async () => await reload(householdId)}
        loading={loading}
        disabled={loading}
      />
    </>
  );
  return (
    <NestedPage heading="Certificates" actions={actions} className={className} headingClassName={headingClassName}>
      <Table
        className="px-6 mt-6 pb-6"
        allColumns={allColumns}
        data={certificates}
        loading={loading || deletingCertificate || applicationLoading}
        scroll={{ x: 1200 }}
        showColSeparator={false}
        pagination={{ pageSize: 10 }}
        expandable={{
          rowExpandable: () => false,
          expandIcon: () => null,
          columnWidth: 0,
        }}
      />
    </NestedPage>
  );
}

FamilyCertificatesTable.propTypes = {
  householdId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  headingClassName: PropTypes.string,
  loading: PropTypes.bool,
  hideAddCertificate: PropTypes.bool,
};
FamilyCertificatesTable.defaultProps = {
  className: '',
  headingClassName: 'text-lg text-primary font-semibold uppercase',
  loading: false,
  hideAddCertificate: true,
};
