import { Alert } from 'antd';
import { PROVIDER_FILE_TYPES } from 'services/providers.service';

export default function ProviderDocumentFolderInstructions({ folder }) {
  let message;
  let title;

  switch (folder) {
    case PROVIDER_FILE_TYPES.STATE_LICENSE.code:
      message =
        'Upload a copy of a valid license issued by your state. The license should cover all ages served at the facility or home.';
      title = 'State License';

      break;
    case PROVIDER_FILE_TYPES.W9.code:
      message = (
        <>
          <p>Only the 2024 version of this form is acceptable.</p>

          <p className="mt-2">
            A "Substitute W-9" form cannot be accepted.{' '}
            <a href="https://info.childcareaware.org/hubfs/W-9%202024.pdf" target="_blank" rel="noreferrer">
              Download W-9 Form
            </a>
          </p>
        </>
      );
      title = 'W-9 form';

      break;
    case PROVIDER_FILE_TYPES.RATE_SHEET.code:
      message =
        'Upload a copy of your Rates for all age groups served. Current and future rates with date ranges they are effective';
      title = 'Rate sheet';

      break;
    case PROVIDER_FILE_TYPES.EIN.code:
      message = (
        <>
          Upload a copy of the EIN Certification Letter that was sent to you when your business was assigned an EIN
          (Example A).{' '}
          <p className="mt-3">
            If you do not have a copy, please contact the Internal Revenue Service (IRS) Business & Specialty Tax Line
            at 1-800-829-4933 to request a replacement certification letter. The IRS refers to this replacement letter
            as "LTR-147C" (Example B).{' '}
            <a
              href="https://www.childcareaware.org/wp-content/uploads/2021/10/EIN-Documentation-Requirement.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Download EIN Instructions
            </a>
          </p>
        </>
      );
      title = 'Employer Identification Number (EIN)';

      break;
    case PROVIDER_FILE_TYPES.NATIONAL_ACCREDITATION.code:
      message = (
        <>
          Upload your National Accreditation Certificate or self-study pursuing accreditation with a DoD approved
          agency. Check list of approved accreditations under.
          <p className="mt-2">
            <a
              href="https://www.childcareaware.org/fee-assistancerespite/feeassistancerespiteproviders/feeassistance/"
              target="_blank"
              rel="noreferrer"
            >
              Why is Accredited Care Important?
            </a>
          </p>
        </>
      );
      title = 'National accreditation';

      break;
    case PROVIDER_FILE_TYPES.DIRECT_DEPOSIT.code:
      message = (
        <>
          Upload a voided check or bank letter with a business/legal name matching the application and printed by the
          bank must be attached in order to successfully enroll your facility in direct deposit. Please do not submit
          family bank information; it will not be accepted.
          <p className="mt-2">All payments are made directly to provider.</p>
        </>
      );
      title = 'Direct deposit';

      break;
    case PROVIDER_FILE_TYPES.OTHER.code:
      message = <>Upload any additional supporting documents.</>;
      title = 'Other additional documents';

      break;
    default:
  }

  if (!message && !title) {
    return null;
  }

  return (
    <Alert
      // message={<p className="text-md font-semibold">{title}</p>}
      description={<p className="text-sm -mt-1">{message}</p>}
    />
  );
}
