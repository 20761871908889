import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { List, Button, message } from 'antd';
import { useAsyncFn } from 'react-use';
import { FaCaretDown, FaCaretUp, FaUserCircle } from 'react-icons/fa';

import EmailPreview from 'components/EmailPreview';
import communicationsService from 'services/communications.service';
import styles from './CommunicationAlerts.module.less';
import { ENTITY_TYPES } from 'constants';
import useTablePagination from 'hooks/useTablePagination';
import { formatDateTimeToLocal, getFullName } from 'utils';

const PAGE_SIZE = 5;
export default function CommunicationAlerts({ className, id }) {
  const [expandedRowId, setExpandedRowId] = useState(null);

  const params = useMemo(
    () => ({
      current: 1,
      pageSize: PAGE_SIZE,
      sort: 'id,desc',
      entityType: ENTITY_TYPES.CARE_FACILITY,
      entityId: id,
    }),
    [id],
  );
  const mapper = useCallback((item) => {
    return {
      ...item,
      name: getFullName(item.createdBy),
    };
  }, []);

  const alertsList = useTablePagination({
    url: '/communications/alerts',
    mapper,
    params,
    hideErrorMessage: true,
  });

  console.log('alertsList', alertsList);

  const [{ loading: detailsLoading, value: communicationDetails }, getCommunicationDetails] = useAsyncFn(
    async (record) => {
      try {
        return await communicationsService.getCommunicationDetails(record.id);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to get details.', 5);
        return record;
      }
    },
    [id],
  );
  const onExpandText = useCallback(
    (item) => {
      setExpandedRowId(item.id === expandedRowId ? null : item.id);
      getCommunicationDetails(item);
    },
    [expandedRowId, getCommunicationDetails],
  );

  return (
    <div className={classNames(styles.notes, className)}>
      <List
        itemLayout="vertical"
        size="large"
        pagination={alertsList.pagination}
        dataSource={alertsList.data}
        loading={alertsList.loading}
        renderItem={(item) => {
          return (
            <>
              <List.Item className="grid grid-cols-12 gap-2 md:gap-0" key={item.id}>
                <div className="col-span-1 flex items-center justify-start md:justify-center	">
                  <FaUserCircle size={32} color="#2E5BFF" />
                </div>
                <div className={classNames('col-span-10')}>
                  <p
                    data-testid={`communication-message-${item.id}`}
                    className={classNames('w-full', expandedRowId !== item.id ? 'whitespace-nowrap truncate' : '')}
                  >
                    {item.subject}
                  </p>
                  <p className="added-by">Added By: {item.name}</p>
                  <p style={{ lineHeight: '10px' }} className="date truncate whitespace-nowrap float-right">
                    Date: {formatDateTimeToLocal(item.createdDate)}
                  </p>
                </div>
                <div className="col-span-1">
                  <Button
                    data-testid={`expand-trigger-${item.id}`}
                    className={classNames(styles.btn, 'icon-btn')}
                    icon={
                      expandedRowId === item.id ? (
                        <FaCaretUp size={20} color="rgba(156, 163, 175)" />
                      ) : (
                        <FaCaretDown size={20} color="rgba(156, 163, 175)" />
                      )
                    }
                    onClick={() => onExpandText(item)}
                  />
                </div>
                {expandedRowId === item.id && (
                  <div className="col-span-12">
                    <EmailPreview data={communicationDetails} facilityId={id} showExpand loading={detailsLoading} />
                  </div>
                )}
              </List.Item>
              {/* {isExpanded[item.id] && communicationDetails && (
                <EmailPreview data={communicationDetails} facilityId={id} showExpand loading={detailsLoading} />
              )} */}
            </>
          );
        }}
      />
    </div>
  );
}

CommunicationAlerts.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
};

CommunicationAlerts.defaultProps = {
  className: '',
  id: null,
};
