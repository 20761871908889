import React from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';

import Section from 'components/Section';
import { getFullName } from 'utils';

export default function NavyInstructions({ application, household, onContinueToDashboard, onReturnToHome }) {
  return (
    <div className="mb-6">
      <div className="text-center bg-white p-6">
        <h1 className="font-bold text-2xl mt-2 tracking-normal">Thank You for your service to our country!</h1>
        <h3 className="text-lg py-6 px-8 tracking-normal font-medium">Great! Your application has been submitted.</h3>
        <p className="">
          Congratulations, {getFullName(application?.applicant)}, we have received your application for Exceptional
          Family Member Respite Care on submission date, {dayjs().format('MM/DD/YYYY')}!
        </p>
        <p className="mt-4">
          If you have questions, you may contact Child Care Aware® of America, at the information below, between 8:00 am
          and 7:00 pm Eastern Standard Time by the contact information listed below. <br />
        </p>
        <p className="mt-4">
          Please reference your Family ID on the fax cover sheet, or in the subject line of your email, when sending in
          documents or calling to discuss your account
        </p>
        <div className="flex actions mt-6">
          <Button data-testId="return-home-btn" onClick={onReturnToHome}>
            Return to Home Screen{' '}
          </Button>
          <Button data-testId="application-dashboard-btn" onClick={onContinueToDashboard} type="primary">
            Continue to Application Dashboard
          </Button>
        </div>
      </div>
      <Section heading="Family Application Reference Information:" className="mt-6 !border-0">
        <ul className="list-disc ml-8 block pb-2">
          <li>
            Family Number: {household?.id}
            <ul className="list-disc ml-8 block pb-2">
              {application?.children?.map((child) => (
                <li key={child.id}>
                  Child Name: {getFullName(child)};
                  <ul className="list-disc ml-6 block pb-2">
                    {uniqBy(
                      (child?.schedules || []).filter((sch) => sch.careFacility?.id),
                      (sch) => sch.careFacility?.id,
                    ).map((sch) => (
                      <li key={sch.id}>
                        <ul className="block pb-2">
                          <li key={`${sch.id}-name`} className="grid grid-cols-[130px_auto]">
                            <span>Provider Name:</span> {sch.careFacility?.businessLegalName || 'N/A'}
                          </li>
                          <li key={`${sch.id}-number`} className="grid grid-cols-[130px_auto]">
                            <span>Provider Number:</span> <span>{sch.careFacility?.id}</span>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </Section>
      <Section
        heading="Child Care Aware® of America Contact Information:"
        className="mt-6 !border-0"
        contentClassName="section-px -mt-2 pb-6"
      >
        <span>1515 N. Courthouse Road, 3rd Floor, Arlington, VA 22201</span>
        <table>
          <tbody>
            <tr>
              <td width="169">
                <p>
                  <strong>Parent Services</strong>
                </p>
              </td>
              <td width="455">
                <p>Phone: 1(800) 424-2246 ext. 317</p>
                <p>Fax: (571) 255-4881</p>
                <p>
                  Email:{' '}
                  <a href="mailto:NavyEFMPrespite@usa.childcareaware.org">NavyEFMPrespite@usa.childcareaware.org</a>
                </p>
              </td>
            </tr>
            <tr>
              <td width="169">
                <p>
                  <strong>Provider Services</strong>
                </p>
              </td>
              <td width="455">
                <p>Phone: 1(800) 424-2246 ext. 345</p>
                <p>Fax: (703) 341-4146</p>
                <p>
                  Email:{' '}
                  <a href="mailto:providerservices@usa.childcareaware.org">providerservices@usa.childcareaware.org</a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Section>
      <div className="mt-6 bg-white p-6">
        <h3 className="text-primary">
          <strong>Next Steps: </strong>
        </h3>
        <div className="ml-16 my-2">
          <h3 className="text-primary">When will I receive a response from Child Care Aware&reg; of America?</h3>
          <p>
            You will receive a response about the status of your submitted application after the initial review. You can
            also check your status online at any time.
          </p>
          <h3 className="text-primary mt-2">How do a provider and family know when they can start care?</h3>
          <p>
            When a family selects an “approved” provider (one who has completed all required pre-service criteria), the
            agency confirms and completes the selected provider’s information section on the family application. This
            requires the “start date” for care which is when care can begin. Each family and provider receive a
            “Certificate of Approval” and attendance sheet per child via email once the family has made their provider
            selection. “Certificates of Approval” are issued for periods no longer than one year, but the period can be
            shorter. EFMP updates that are due within the certificate year would affect the approval dates as well.
          </p>
        </div>
      </div>
    </div>
  );
}
