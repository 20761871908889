import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { getValue } from '@testing-library/user-event/dist/utils';
import { Button, Col, Divider, Input, InputNumber, Row } from 'antd';

import Form from 'components/Form';
import Section from 'components/Section';
import FormItem from 'components/FormItem';
import PhoneNumber from 'components/PhoneNumber';
import DragDropUpload from 'components/DragDropUpload';
import useEntityGroup, { GetEntityListData } from 'hooks/useEntityGroup';
import useChangeRequestClassName from 'hooks/useChangeRequestClassName';
import useStartEndDates from 'hooks/useStartEndDates';
import providerService from 'services/providers.service';
import { formatDate } from 'utils';
import styles from './Insurances.module.less';

export default function InsurancesContainer(props) {
  const getData = useCallback(async () => {
    if (props.center?.id) {
      return providerService.getInsurances(props.center?.id);
    }
  }, [props.center?.id]);

  return (
    <GetEntityListData name="insurances" getData={getData} className="mb-0">
      <Insurances {...props} />
    </GetEntityListData>
  );
}
const StartEndDates = ({ form, getSnapshotValue }) => {
  const [startDate, endDate] = useStartEndDates({
    form,
    startDateFieldProps: {
      label: 'Policy Start Date',
      rules: [{ required: true, message: 'Policy Start Date is required.' }],
      getSnapshotValue,
    },
    endDateFieldProps: {
      label: 'Policy End Date',
      rules: [{ required: true, message: 'Policy End Date is required.' }],
      getSnapshotValue,
    },
  });
  return (
    <>
      <Col xs={24} sm={12} md={8}>
        {startDate}
      </Col>
      <Col xs={24} sm={12} md={8}>
        {endDate}
      </Col>
    </>
  );
};

export function Insurances({ value: items = [], onChange, center, readOnly, className }) {
  const onAddItem = useCallback(async (data) => providerService.addUpdateInsurance(center?.id, data), [center?.id]);
  const onDeleteItem = useCallback(async (item) => providerService.deleteInsurance(center?.id, item.id), [center?.id]);
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">Insurance Company</span>,
        headerText: 'Insurance Company',
        dataIndex: 'issuedBy',
        key: 'issuedBy',
        ellipsis: true,
        render: (value) => value || 'N/A',
        fixed: 'left',
      },
      {
        title: <span className="action-header">Start Date</span>,
        headerText: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        ellipsis: true,
        render: (startDate) => formatDate(startDate),
      },
      {
        title: <span className="action-header">End Date</span>,
        headerText: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        ellipsis: true,
        render: (endDate) => formatDate(endDate),
      },
      {
        title: <span className="action-header">Policy Amount</span>,
        headerText: 'Policy Amount',
        dataIndex: 'coverageAmount',
        key: 'amount',
        ellipsis: true,
        render: (value) => `$${value}`,
      },
    ];
    return allColumns;
  }, []);
  const keys = useMemo(
    () => ['id', 'issuedBy', 'startDate', 'endDate', 'amount', 'agentName', 'agentPhone', 'agentEmail', 'notes'],
    [],
  );
  const rowClassName = useChangeRequestClassName({ keys, snapshotKey: ['insurances'] });
  const getFormFields = useCallback(({ values, setVisible, items, form, item, index, addUpdateLoading }) => {
    const getSnapshotValue = (snapshot, name) => {
      return getValue(
        snapshot.insurances.find((v) => v.id === item?.id),
        name,
      );
    };
    return (
      <>
        <h3 className="section-title">Personal Liability Insurance</h3>
        <Row gutter={[20, 0]} className="form-row">
          <Col xs={24} sm={12} md={16}>
            <FormItem
              name="issuedBy"
              label="Name of Insurance Company"
              rules={[{ max: 225, message: 'Name is too long.' }]}
              getSnapshotValue={getSnapshotValue}
            >
              <Input placeholder="Name" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem
              label="Coverage Amount"
              name="coverageAmount"
              rules={[{ required: true, message: 'Coverage Amount is required.' }]}
              getSnapshotValue={getSnapshotValue}
            >
              <InputNumber
                placeholder="Coverage Amount"
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </FormItem>
          </Col>
          <StartEndDates form={form} />
        </Row>
        <h3 className="section-title">Insurance Agent Information</h3>
        <Row gutter={[20, 0]} className="form-row">
          <Col xs={24} sm={12} md={8}>
            <FormItem
              name="agentName"
              label="Agent Name"
              rules={[{ max: 225, message: 'Agent Name is too long.' }]}
              getSnapshotValue={getSnapshotValue}
            >
              <Input placeholder="Agent Name" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem name="agentPhone" label="Phone Number" getSnapshotValue={getSnapshotValue}>
              <PhoneNumber placeholder="Phone Number" />
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <FormItem name="agentEmail" label="Email Address" getSnapshotValue={getSnapshotValue}>
              <Input placeholder="Email" />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem name="notes" label="Insurance Notes" getSnapshotValue={getSnapshotValue}>
              <Input.TextArea placeholder="Notes" />
            </FormItem>
          </Col>
        </Row>
        <Divider className="mt-0" />
        <div className="actions flex mt-5">
          <Button onClick={() => setVisible(false)} data-testid="cancel-insurance-btn">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            data-testid="save-insurance-btn"
            disabled={addUpdateLoading}
            loading={addUpdateLoading}
          >
            Save
          </Button>
        </div>
      </>
    );
  }, []);

  const { section } = useEntityGroup({
    items,
    onChange,
    onAddItem,
    onDeleteItem,
    entity: center,
    getAllColumns,
    getFormFields,
    readOnly,
    entityTitle: 'Personal Liability Insurance information',
    tableProps: {
      rowClassName,
    },
    entityName: 'insurance',
    dateFields: [['startDate'], ['endDate']],
  });
  return (
    <div className={classNames(styles.insurances, className)}>
      <Section heading="Personal Liability Insurance" className={className}>
        <p className="-mt-2 mb-5">
          Personal liability insurance coverage of $5000,000 is required to be an NTC provider.
        </p>
        <div className="mb-6">{section}</div>
        <Form.Item
          label="Attach Liability Insurance supporting documents to validate information provided above."
          className="mt-5"
        >
          <DragDropUpload
            name={['documents', 'INS']}
            entityId={center?.id}
            entityType="CareFacility"
            abbr="LIC"
            keyPrefix="forms/center-details/insurance"
          />
        </Form.Item>
      </Section>
    </div>
  );
}
