import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { components as rsComponents } from 'react-select';
import CreatableSelect from 'react-select/creatable';

const { IndicatorSeparator, DropdownIndicator } = rsComponents;

const Select = React.forwardRef(
  (
    {
      id,
      name,
      placeholder,
      value,
      options,
      ariaLabel,
      onChange,
      styles,
      theme,
      className,
      isClearable,
      hideDropdownIcon,
      disabled,
      hideSeparator,
      components,
      isMulti,
      getOptionValue,
      ...rest
    },
    ref,
  ) => {
    const [optionsState, setOptions] = useState(options || []);
    useEffect(() => {
      setOptions((prev) => {
        if (value) {
          if (prev?.length === options?.length) {
            if (
              prev.findIndex((opt) => {
                if (typeof value === 'string') {
                  return opt.value === value;
                } else if (Array.isArray(value)) {
                  return value.includes((v) => v.value === opt.value);
                }
                return value?.value === opt.value;
              }) === -1
            ) {
              let option = [value];
              if (typeof value === 'string') {
                option = [{ value, label: value }];
              } else if (Array.isArray(value)) {
                option = value;
              }
              return [...prev, ...option];
            }
            return prev;
          }
        }
        return prev;
      });
    }, [options?.length, value]);
    return (
      <div
        onKeyPress={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <CreatableSelect
          aria-label={ariaLabel}
          classNamePrefix="select"
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable
          id={id}
          inputId={id}
          name={name}
          menuPlacement="auto"
          value={isMulti ? value : optionsState.find((opt) => opt.value === value)}
          options={optionsState}
          onChange={(selected, action) => {
            if (action.action === 'create-option' && !isMulti) {
              setOptions((prev) => [...prev, action.option]);
            }
            onChange(isMulti ? selected.map(getOptionValue) : selected ? getOptionValue(selected) : selected);
          }}
          styles={styles}
          theme={theme}
          className={className}
          isDisabled={disabled}
          components={{
            ...(components || {}),
            IndicatorSeparator: (props) => {
              if (hideSeparator) return null;
              if (components?.IndicatorSeparator) {
                return <components.IndicatorSeparator {...props} />;
              }
              return <IndicatorSeparator {...props} />;
            },
            DropdownIndicator: (props) => {
              if (hideDropdownIcon) return null;
              if (components?.DropdownIndicator) {
                return <components.DropdownIndicator {...props} />;
              }
              return <DropdownIndicator {...props} />;
            },
          }}
          isMulti={isMulti}
          getOptionValue={getOptionValue}
          {...rest}
        />
      </div>
    );
  },
);

Select.propTypes = {
  ariaLabel: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.array,
  placeholder: PropTypes.any,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  hideSeparator: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  styles: PropTypes.any,
  theme: PropTypes.any,
  getOptionValue: PropTypes.func,
};

Select.defaultProps = {
  isClearable: true,
  disabled: false,
  getOptionValue: (v) => v.label,
  options: [],
};

export default Select;
