import React, { useEffect, useMemo } from 'react';
import { Input, Button } from 'antd';
import classNames from 'classnames';
import { MdSearch } from 'react-icons/md';

import { getCompletedAddress, getTextSorter } from 'utils';
import { AsyncSelect } from 'components/Select';
import Form from 'components/Form';
import Table from 'components/Table';
import ColumnTitle from 'components/ColumnTitle';
import styles from './RowSelectionTable.module.less';

export default function RowSelectionTable({
  onSearch,
  onSubmit,
  selected,
  results,
  loading,
  totalCount,
  setSelected,
  onCancel,
  title,
  defaultState,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (defaultState) {
      form.setFieldsValue({ state: defaultState });
    }
  }, [defaultState, form]);

  const columns = useMemo(
    () => [
      {
        title: (props) => (
          <ColumnTitle
            className="mb-4 uppercase text-lg text-gray-400"
            showSorting
            {...props}
            title={title}
            dataIndex="name"
            colKey="name"
          />
        ),
        headerText: title,
        dataIndex: 'name',
        key: 'name',
        className: 'truncate max-w-xs',
        sorter: getTextSorter('name'), // client side sorting
        render: (_, value) => {
          if (!value) return '';
          const isSelected = selected?.id === value.id;
          return (
            <div className="grid grid-cols-[1fr_100px] gap-x-3">
              <div className="overflow-hidden">
                <div className="text-xs leading-5">
                  <p className="font-medium">{value?.name}</p>
                  <div>
                    <p className="whitespace-pre-wrap" title={getCompletedAddress(value.address)}>
                      {getCompletedAddress(value.address) || 'N/A'}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <Button
                  className="mt-4"
                  onClick={() => {
                    if (isSelected) setSelected(null);
                    else setSelected(value);
                  }}
                  type={isSelected && 'primary'}
                  data-testid={`select-${value.id}`}
                >
                  {isSelected ? 'Unselect' : 'Select'}
                </Button>
              </div>
            </div>
          );
        },
        width: 220,
      },
    ],
    [selected?.id, setSelected, title],
  );

  return (
    <div className={classNames(styles.table, 'w-full')}>
      <h4 className="section-title mb-4">{title}</h4>
      <Form onValuesChange={onSearch} onFinish={onSubmit} form={form} layout="vertical">
        <div className="flex-col sm:flex-row flex">
          <div className="w-full sm:w-48">
            <Form.Item
              data-testid="search-keyWord"
              name="searchKeyWord"
              placeholder="Search Partners"
              label={title === 'Installation' ? 'Search for installation' : 'Search for Partner'}
            >
              <Input placeholder="Search" suffix={<MdSearch size={24} />} />
            </Form.Item>
            <Form.Item name="state" label="State" placeholder="State">
              <AsyncSelect
                name="state"
                placeholder="State"
                ariaLabel="State"
                optionsApiUrl="/countries/states/US/"
                getOptionLabel={(opt) => opt.name}
                getOptionValue={(opt) => opt.code}
                isOptionSelected={(option, [value]) => option?.code === value}
                onChangeFormatter={(v) => v?.code}
              />
            </Form.Item>
            <Form.Item name="city" label="City" placeholder="City">
              <Input placeholder="City" />
            </Form.Item>
          </div>
          <div className="w-full sm:pl-6">
            <Table
              className={classNames(styles.table, 'bg-primary bg-opacity-3')}
              allColumns={columns}
              loading={loading}
              data={results}
              pagination={{ defaultCurrent: 1, defaultPageSize: 5, total: totalCount }}
              rowClassName={(record, index) => {
                let className = '';
                className = index % 2 === 0 ? '[&_td]:!bg-white' : '[&_td]:!bg-light-bg';
                return classNames(className, 'h-20');
              }}
              expandable={{
                rowExpandable: () => false,
                expandIcon: () => null,
                columnWidth: 0,
              }}
            />
          </div>
        </div>
        <div className="actions flex mt-4">
          <Button data-testid="cancel-button" onClick={onCancel}>
            Cancel
          </Button>
          <Button data-testid="submit-search-query" type="primary" htmlType="submit">
            Apply
          </Button>
        </div>
      </Form>
    </div>
  );
}
