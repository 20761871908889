import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as AntButton } from 'antd';

import styles from './Button.module.less';

export default function Button(props) {
  return <AntButton data-testid="button" {...props} className={classNames(styles.button, props.className)} />;
}

Button.propTypes = {
  className: PropTypes.any,
};

Button.defaultProps = {
  className: '',
};
