import React, { useCallback, useEffect } from 'react';
import PDFObject from 'pdfobject';
import classNames from 'classnames';

function PdfViewer({ id, url, className }) {
  const embedPdf = useCallback(
    async function () {
      PDFObject.embed(url, `#pdf-container-${id}`);
    },
    [id, url],
  );
  useEffect(() => {
    embedPdf();
  }, [embedPdf, id, url]);
  return (
    <div
      id={`pdf-container-${id}`}
      style={{ height: 600 }}
      className={classNames(className, { 'flex p-4 items-center justify-center': !PDFObject.supportsPDFs })}
    />
  );
}

export default PdfViewer;
