import { useAsync } from 'react-async-hook';
import { commonService } from 'services';

export default function useBranch({ id, fallback = {} }) {
  const { result: branch, loading } = useAsync(async () => {
    if (!id) return fallback;
    const data = await commonService.get('/common/program-sponsors');
    return data.find((item) => item.id === id) || fallback;
  }, [id]);
  return { branch, loading };
}
