import classNames from 'classnames';
import { useEffect, useContext, useRef, useMemo } from 'react';
import { useBoundingBox } from 'react-measured-dom';
import { ScrollContext } from 'utils';
import usePrevious from './usePrevious';

export default function useScrollLock({ topLimit, rootRef }) {
  const [hiddenRootScroll, setHiddenRootScroll, scrollEnabled] = useContext(ScrollContext);
  const contentRef = useRef();
  const sidebarRef = useRef();
  const rootBoundingBox = useBoundingBox(rootRef);
  const prevRootBoundingBox = usePrevious(rootBoundingBox);
  const contentBoundingBox = useBoundingBox(contentRef);
  const contentPrevBoundingBox = usePrevious(contentBoundingBox);
  const sidebarBoundingBox = useBoundingBox(sidebarRef);
  const sidebarPrevBoundingBox = usePrevious(sidebarBoundingBox);
  // useEffect(() => {
  //   if (scrollEnabled && !hiddenRootScroll && prevRootBoundingBox?.top > topLimit && rootBoundingBox?.top <= topLimit) {
  //     setHiddenRootScroll(true);
  //   }
  //   if (scrollEnabled && hiddenRootScroll && prevRootBoundingBox?.top < topLimit && rootBoundingBox?.top >= topLimit) {
  //     setHiddenRootScroll(false);
  //   }
  // }, [rootBoundingBox, prevRootBoundingBox?.top, hiddenRootScroll, topLimit, setHiddenRootScroll, scrollEnabled]);
  useEffect(() => {
    const contentTopLimit = topLimit + 28;
    if (
      scrollEnabled &&
      hiddenRootScroll &&
      ((contentPrevBoundingBox?.top < contentTopLimit && contentBoundingBox?.top >= contentTopLimit) ||
        (sidebarPrevBoundingBox?.top < contentTopLimit && sidebarBoundingBox?.top >= contentTopLimit))
    ) {
      setHiddenRootScroll(false);
    }
  }, [
    contentBoundingBox,
    hiddenRootScroll,
    contentPrevBoundingBox?.top,
    setHiddenRootScroll,
    sidebarPrevBoundingBox?.top,
    sidebarBoundingBox?.top,
    topLimit,
    scrollEnabled,
  ]);
  useEffect(() => {
    if (hiddenRootScroll && !scrollEnabled) {
      setHiddenRootScroll(false);
    }
  }, [hiddenRootScroll, scrollEnabled, setHiddenRootScroll]);

  const layoutClassName = useMemo(
    () => classNames('mt-2 pt-5 h-[calc(100vh-98px)]', { 'overflow-hidden': scrollEnabled }),
    [scrollEnabled],
  );
  const contentClassName = useMemo(
    () =>
      classNames(
        'pr-3',
        // 'pr-3 pl-5 h-[calc(100vh-98px)] overflow-auto',
        // {
        //   'overflow-hidden': scrollEnabled && !hiddenRootScroll,
        //   'overflow-auto': hiddenRootScroll,
        // }
      ),
    [],
  );
  const sidebarClassName = useMemo(
    () =>
      classNames(
        '',
        // 'h-[calc(100vh-98px)] overflow-auto',
        // {
        //   'overflow-hidden': scrollEnabled && !hiddenRootScroll,
        //   'overflow-auto': hiddenRootScroll,
        // }
      ),
    [],
  );
  return {
    hiddenRootScroll,
    setHiddenRootScroll,
    contentRef,
    sidebarRef,
    rootBoundingBox,
    prevRootBoundingBox,
    contentBoundingBox,
    contentPrevBoundingBox,
    sidebarBoundingBox,
    sidebarPrevBoundingBox,
    scrollEnabled,
    layoutClassName,
    contentClassName,
    sidebarClassName,
  };
}
