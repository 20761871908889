import { useCallback, useState } from 'react';

export default function useModalState() {
  const [visible, setVisible] = useState();

  const onOpen = useCallback(() => setVisible(true), []);
  const onClose = useCallback(() => setVisible(false), []);
  const onToggle = useCallback(() => setVisible((v) => !v), []);

  return { visible, onClose, onOpen, onToggle, setVisible };
}
