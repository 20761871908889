import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, message, Spin } from 'antd';
import GoogleMapReact from 'google-map-react';
import { RadioGroup } from '@headlessui/react';
import { HiCheck } from 'react-icons/hi';
import { BiTrash } from 'react-icons/bi';
import { SiGooglemaps } from 'react-icons/si';
import { MdDirections } from 'react-icons/md';

import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useHistory } from 'react-router-dom';

import usePrevious from 'hooks/usePrevious';
import CollapsePanel from 'components/CollapsePanel';
import SearchPlaces from 'components/Map/SearchPlaces';
import config from 'config';
import { commonService, familyService } from 'services';
import { concatLatLng, getCompletedAddress } from 'utils';

export default function SponsorInstallationContent({
  id,
  householdId,
  child,
  installation,
  setInstallationInfo,
  application,
  prev,
  next,
}) {
  const [selectedInstallation, setSelectedInstallation] = useState(null);
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const gmap = useRef();
  const [mapReference, setMapReference] = useState(null);
  const [mapsReference, setMapsReference] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startPosition, setStartPosition] = useState(null);
  const [endPosition, setEndPosition] = useState(null);
  const [startPositionMarker, setStartPositionMarker] = useState(null);
  const [endPositionMarker, setEndPositionMarker] = useState(null);
  const picker = useRef();
  const startInput = useRef();
  const endInput = useRef();
  const history = useHistory();

  const directionsService = useRef(null);
  const directionsRenderer = useRef(null);
  const disabled = !startPosition || !endPosition || loading;

  const setMyHomeLocation = useCallback(() => {
    if (child?.homeAddress) {
      const location = {
        lat: child?.homeAddress.location.lat,
        lng: child?.homeAddress.location.lon,
        address: getCompletedAddress(child.homeAddress),
      };
      setStartPosition(location);
      return;
    }
    message.error('Home address is not set for this child.');
  }, [child?.homeAddress]);

  const handleDirections = useCallback(() => {
    if (
      !startPosition?.lat ||
      !startPosition?.lng ||
      !endPosition?.lat ||
      !endPosition?.lng ||
      !directionsRenderer.current ||
      !directionsService.current
    )
      return;
    setLoading(true);
    directionsRenderer.current.set('directions', null);
    directionsRenderer.current.setMap(null);
    directionsRenderer.current.setMap(mapReference);
    if (startPositionMarker) {
      startPositionMarker.setMap(null);
    }
    if (endPositionMarker) {
      endPositionMarker.setMap(null);
    }
    directionsService.current.route(
      {
        origin: `${startPosition.lat},${startPosition.lng}`,
        destination: `${endPosition.lat},${endPosition.lng}`,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          setLoading(false);
          directionsRenderer.current.setDirections(response);
          const Marker = google.maps.Marker;

          setStartPositionMarker(
            new Marker({
              position: startPosition,
              label: 'O',
              title: 'Home',
              // icon,
              animation: google.maps.Animation.DROP,
              map: mapReference,
            }),
          );
          setEndPositionMarker(
            new Marker({
              position: endPosition,
              label: 'D',
              title: 'Installation',
              // icon,
              animation: google.maps.Animation.DROP,
              map: mapReference,
            }),
          );
          try {
            const route = response.routes[0].legs[0];
            if (route) {
              setDistance(route.distance);
              setDuration(route.duration);
            }
          } catch {}
        } else {
          setLoading(false);
          message.error('Directions request failed');
        }
      },
    );
  }, [startPosition, endPosition, mapReference, startPositionMarker, endPositionMarker]);

  const removeDirections = useCallback(async () => {
    if (directionsRenderer.current) {
      directionsRenderer.current.set('directions', null);
      directionsRenderer.current.setMap(null);
    }
    if (startPositionMarker) {
      startPositionMarker.setMap(null);
    }
    setStartPositionMarker(null);
    if (endPositionMarker) {
      endPositionMarker.setMap(null);
    }
    setEndPositionMarker(null);
    setDistance(null);
    setDuration(null);
  }, [endPositionMarker, startPositionMarker]);

  const onSelect = (setter) => (place) => {
    if (place && !place.address) {
      if (!place.address) {
        place.address = concatLatLng(place);
      }
    }
    setter(place);
    if (setter === setStartPosition && !place) {
      setDistance(null);
      setDuration(null);
    }
    if (setter === setEndPosition && !place) {
      setDistance(null);
      setDuration(null);
      setSelectedInstallation(null);
      removeDirections();
    }
  };

  const onInstallationSelection = useCallback(
    (installation) => {
      setSelectedInstallation(installation);
      const address = installation?.address;
      if (address) {
        setEndPosition({
          lat: address.location.lat,
          lng: address.location.lon,
          address: getCompletedAddress(address),
        });
      } else {
        setEndPosition(null);
        setDistance(null);
        setDuration(null);
        removeDirections();
      }
    },
    [removeDirections],
  );

  const onInstallationClick = useCallback(
    (installation) => () => {
      if (selectedInstallation?.id === installation.id) {
        setSelectedInstallation(null);
        setEndPosition(null);
        setDistance(null);
        setDuration(null);
        removeDirections();
      }
      // handleDirections();
    },
    [removeDirections, selectedInstallation?.id],
  );

  const { result: nearestInstallations = [] } = useAsync(async () => {
    const payload = {
      point: child.homeAddress?.location,
      stateCode: child.homeAddress?.state,
      programSponsorId: application?.applicant?.position?.programSponsorId,
    };
    if (!child.homeAddress || !application?.applicant?.position?.programSponsorId) return [];
    try {
      const installations = await commonService.post('/provider-search/military-installations/search', payload);
      return installations;
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to saerch nearest installations for child home address.', 5);
    }
  }, [application, child]);

  const initialSet = useRef(false);
  useEffect(() => {
    if (!initialSet.current && installation) {
      // setKey(Date.now());
      let inst = nearestInstallations?.find((ins) => ins.id === installation.id) || null;
      if (!inst) {
        inst = nearestInstallations?.[0];
      }
      setSelectedInstallation(inst);
      const address = inst?.address;
      if (address) {
        setEndPosition({
          lat: address.location.lat,
          lng: address.location.lon,
          address: getCompletedAddress(address),
        });
        initialSet.current = true;
      }
    }
  }, [installation, nearestInstallations]);

  useEffect(() => {
    if (child?.homeAddress?.id) {
      setStartPosition({
        lat: child.homeAddress.location.lat,
        lng: child.homeAddress.location.lon,
        address: getCompletedAddress(child.homeAddress),
      });
    }
  }, [child.homeAddress]);

  const prevStartPosition = usePrevious(startPosition);
  const prevEndPosition = usePrevious(endPosition);
  useEffect(() => {
    if (startPosition !== prevStartPosition || endPosition !== prevEndPosition) {
      handleDirections();
    }
  }, [endPosition, handleDirections, prevEndPosition, prevStartPosition, startPosition]);

  const { loading: submitting, execute: onSubmit } = useAsyncCallback(async () => {
    try {
      const payload = {
        personId: child?.id,
        applicationId: id,
        installation: selectedInstallation,
        // meters to miles
        distanceInMiles: distance?.value ? Math.round(distance.value * 0.000621371) : 0,
        distanceInMinutes: duration?.value ? Math.round(duration.value / 60) : 0,
      };
      const installationInfo = await familyService.updatePersonInstallation(id, child.id, payload);
      setInstallationInfo({ result: installationInfo });
      history.push(next);
    } catch (error) {
      message.error(`Unable to update child's installation.`, 5);
      newrelic.noticeError(error);
    }
  }, [id, child, next, duration, distance, selectedInstallation, setInstallationInfo]);

  const centerPoint = useMemo(() => {
    let selectedInstallationLatLng = null;
    if (selectedInstallation?.address?.location) {
      selectedInstallationLatLng = {
        lat: selectedInstallation.address.location.lat,
        lng: selectedInstallation.address.location.lon,
      };
    }
    return (
      endPosition?.location ||
      selectedInstallationLatLng ||
      startPosition?.location || { lat: 32.705002, lng: -97.12278 }
    );
  }, [endPosition?.location, selectedInstallation?.address.location, startPosition?.location]);

  return (
    <div className="bg-white">
      <CollapsePanel
        triggerClassName="top-4 right-3"
        header={
          <div className="px-4 py-4 bg-white w-full flex flex-row items-center space-x-2">
            <h3 className="text-base text-primary font-semibold uppercase">Nearest Installations</h3>
          </div>
        }
      >
        <div className="px-6 pb-6 grid grid-cols-1 md:grid-cols-[300px_1fr]">
          <div id="controls" className="mr-4 space-y-2">
            <div id="nearestInstallations">
              <RadioGroup value={selectedInstallation} onChange={onInstallationSelection}>
                <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
                <div className="space-y-2">
                  {nearestInstallations.map((installation) => (
                    <RadioGroup.Option
                      key={installation.id}
                      value={installation}
                      className={({ active, checked }) =>
                        `${active ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-primary' : ''}
                  ${checked ? 'bg-primary-faded bg-opacity-75 text-black' : 'bg-white'}
                    relative flex cursor-pointer rounded-lg py-3 shadow-lg focus:outline-none`
                      }
                    >
                      {({ active, checked }) => {
                        return (
                          <>
                            <div
                              className="flex w-full items-center justify-between"
                              onClick={onInstallationClick(installation)}
                            >
                              <div className="flex items-center relative">
                                {checked && <HiCheck className="h-6 w-6 absolute -top-0 right-2 text-primary" />}
                                <div className="text-sm">
                                  <RadioGroup.Label
                                    as="p"
                                    className={`font-medium flex justify-between pl-5 ${
                                      checked ? 'text-primary' : 'text-gray-900'
                                    }`}
                                    title={installation.name}
                                  >
                                    <span className="block truncate max-w-[calc(100%-40px)]">{installation.name}</span>
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="div"
                                    className={`w-full px-5 ${checked ? 'text-black' : 'text-gray-500'}`}
                                  >
                                    <span className="text-xs">{getCompletedAddress(installation.address)}</span>
                                  </RadioGroup.Description>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <Button
              className="flex items-center justify-center w-full"
              type="primary"
              onClick={setMyHomeLocation}
              disabled={!child?.homeAddress?.id}
            >
              <SiGooglemaps className="mr-3" /> Set My Location
            </Button>
            <SearchPlaces
              id="start-position"
              googleMaps={mapsReference}
              initialValue={startPosition?.address ? startPosition?.address : ''}
              forwardRef={startInput}
              onSelect={(place) => {
                setDistance(null);
                setDuration(null);
                onSelect(setStartPosition)(place);
              }}
              placeholder="My Location"
            />
            <SearchPlaces
              id="end-position"
              googleMaps={mapsReference}
              initialValue={endPosition?.address ? endPosition?.address : ''}
              ref={endInput}
              picker={picker}
              onSelect={(place) => {
                setDistance(null);
                setDuration(null);
                onSelect(setEndPosition)(place);
              }}
              placeholder="Select Destination"
              disabled
            />
            <Button
              className="w-full flex items-center justify-center space-x-3"
              type="primary"
              onClick={handleDirections}
              disabled={disabled}
              loading={loading}
            >
              <MdDirections /> Get Directions
            </Button>
            {startPositionMarker && endPositionMarker && (
              <Button
                className="w-full flex items-center justify-center space-x-3"
                type="primary"
                onClick={removeDirections}
                disabled={disabled}
              >
                <BiTrash /> Clear Directions
              </Button>
            )}
            <Button className="w-full flex items-center justify-center space-x-3" type="primary" disabled={disabled}>
              <a
                href={`http://maps.google.com/maps?saddr=${concatLatLng(startPosition)}&daddr=${concatLatLng(
                  endPosition,
                )}`}
                target="_blanck"
                disabled={disabled}
                loading={loading}
                className="hover:text-white font-medium"
              >
                Open Directions in Google Map
              </a>
            </Button>
          </div>
          <div id="map" className="flex flex-col h-full flex-1 relative">
            {distance && duration && (
              <div className="absolute top-2 right-2 bg-white bg-opacity-75 p-5 z-20">
                <div className="flex flex-col">
                  <span>
                    Distance: <span className="text-black font-medium">{distance?.text}</span>
                  </span>
                  <span>
                    Duration: <span className="text-black font-medium">{duration?.text}</span>
                  </span>
                </div>
              </div>
            )}
            {loading && (
              <Spin
                spinning={loading}
                size="large"
                className="h-full absolute top-0 left-0 z-10 bottom-0 right-0 flex items-center justify-center"
              ></Spin>
            )}
            <div className="flex-1">
              <GoogleMapReact
                // key={key}
                // id="installation-map"
                mapId="installation-map"
                ref={gmap}
                bootstrapURLKeys={{
                  key: config.REACT_APP_GOOGLE_MAP_API_KEY,
                  libraries: ['geometry', 'drawing', 'places'],
                }}
                zoom={12}
                defaultZoom={5}
                center={centerPoint}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  gestureHandling: 'greedy',
                  fullscreenControl: false,
                }}
                onGoogleApiLoaded={async ({ map, maps }) => {
                  setMapReference(map);
                  setMapsReference(maps);

                  const polylineOptions = new google.maps.Polyline({
                    // strokeColor: '#4CB5EB',
                    strokeColor: '#1c73e8',
                    strokeOpacity: 1.0,
                    strokeWeight: 7,
                  });
                  directionsService.current = new maps.DirectionsService();
                  directionsRenderer.current = new maps.DirectionsRenderer({
                    map,
                    polylineOptions,
                    suppressMarkers: true,
                    suppressInfoWindows: true,
                  });
                  handleDirections();
                }}
              >
                {startPosition && (
                  <Marker lat={startPosition.lat} lng={startPosition.lng} id={`home-marker`} label="Home" />
                )}
                {endPosition && (
                  <Marker lat={endPosition.lat} lng={endPosition.lng} id={`home-marker`} label="Installation" />
                )}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </CollapsePanel>
      <div className="p-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
        <Button
          onClick={() => history.push(`/families/${householdId}/applications/${application.id}`)}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button onClick={() => history.push(prev)} disabled={submitting}>
          Back
        </Button>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={submitting || !child?.id || !selectedInstallation?.id}
          loading={submitting}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

class Marker extends React.PureComponent {
  render() {
    return (
      <div className="flex items-center justify-center p-2 min-w-min">
        <span className="text-base font-medium drop-shadow-[0_1.2px_1.2px_rgb(ff,ff,ff)]">{this.props.label}</span>
      </div>
    );
  }
}
