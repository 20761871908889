import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FiCheck } from 'react-icons/fi';
import { Row, Steps, Button } from 'antd';

import { breakpointsContext } from 'utils';
import styles from './StepsLayout.module.less';
import { ADD_FAMILY_STEPS_ORDER } from 'constants/index';

const getProgressDot =
  ({ openSteps, isSubmitted }) =>
  (dot, { status, index, title }) => {
    const disabled = !(openSteps[ADD_FAMILY_STEPS_ORDER[index]]?.open || isSubmitted);

    return (
      <div className={classNames('dot', styles.completeDot, 'step-dot')}>
        <Button
          className={classNames('dot-btn', {
            'cursor-not-allowed': status === 'process',
          })}
          disabled={disabled && status !== 'process'}
          tabIndex={disabled ? '-1' : '0'}
          aria-label={title}
          title={title}
        >
          {status === 'finish' && <FiCheck />}
          {(status === 'process' || status === 'wait' || status === 'active') && (
            <span className="step-count">{index + 1}</span>
          )}
        </Button>
      </div>
    );
  };

export default function StepsLayout({ id, className, heading, step, steps, setStep, openSteps, isSubmitted }) {
  const stepNumber = ADD_FAMILY_STEPS_ORDER.findIndex((item) => item === step);
  const breakpoints = useContext(breakpointsContext);
  const { xs } = breakpoints;

  const getStepProps = useCallback(
    (currentStep, index) => {
      const stepProps = {};
      if (openSteps[currentStep.key]?.open || isSubmitted) {
        stepProps.status = 'process';

        if (currentStep.key === step) {
          stepProps.status = 'active';
        } else if (index < stepNumber || index > stepNumber || isSubmitted) {
          stepProps.status = 'finish';
        }
      }
      if (openSteps[currentStep.key]?.open || isSubmitted) {
        stepProps.disabled = false;
        stepProps.className = 'cursor-pointer';
        stepProps.onClick = () => {
          if (index !== stepNumber) {
            setStep(currentStep);
          }
        };
      } else {
        stepProps.disabled = true;
        stepProps.className = 'cursor-not-allowed';
      }

      return stepProps;
    },
    [isSubmitted, openSteps, setStep, step, stepNumber],
  );
  const items = useMemo(() => {
    return steps.map((item, index) => ({ ...item, ...getStepProps(item, index), key: item.title }));
  }, [getStepProps, steps]);

  return (
    <div className={classNames(styles.stepsLayout, className)} id={id}>
      <div id="page-top-placeholder" />

      <div className="section-px py-8 w-full">
        <h1 className="text-xl font-normal">{heading}</h1>

        <Row className="steps-row mt-8 mb-8">
          <Steps
            key={JSON.stringify(openSteps)}
            current={stepNumber}
            progressDot={getProgressDot({ openSteps, isSubmitted })}
            className="gcc-steps"
            direction={xs ? 'vertical' : 'horizontal'}
            items={items}
            onChange={(index) => {
              setStep(ADD_FAMILY_STEPS_ORDER[index]);
            }}
          />
        </Row>
      </div>
    </div>
  );
}

StepsLayout.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  heading: PropTypes.any,
  id: PropTypes.string,
  openSteps: PropTypes.any,
  setOpenSteps: PropTypes.any,
  setStep: PropTypes.func,
  step: PropTypes.string,
  isSubmitted: PropTypes.bool,
  steps: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, description: PropTypes.string, key: PropTypes.string }),
  ),
};
