import React from 'react';
import classNames from 'classnames';
import Modal from 'components/Modal';
import styles from './SendEmailModal.module.less';
import ScheduleEMail from './ScheduleEmail';

export default function SendEmailModal({
  className,
  visible,
  setVisible,
  entityId,
  entityType,
  toMail,
  showSchedule,
  reload,
  ...rest
}) {
  return (
    <div className={classNames(styles.sendEmailModal, className)}>
      <Modal
        destroyOnClose
        setVisible={setVisible}
        width={700}
        visible={visible}
        maskClosable={false}
        data-testid="send-email-modal"
        title={showSchedule ? (rest.record?.id ? 'Edit Scheduled Email' : 'Schedule Email') : 'New Email'}
        {...rest}
      >
        <ScheduleEMail
          visible={visible}
          setVisible={setVisible}
          modalMode
          className={className}
          entityId={entityId}
          entityType={entityType}
          toMail={toMail}
          showSchedule={showSchedule}
          reload={reload}
          {...rest}
        />
      </Modal>
    </div>
  );
}
