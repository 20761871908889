import React from 'react';
import { store } from 'store';

export default React.lazy(() =>
  /* webpackPrefetch: true */
  import('features/add-center').then(({ reducer }) => {
    // store.injectSaga('center', sagas);
    store.injectReducer('center', reducer);
    return import('./AddCenter');
  }),
);
