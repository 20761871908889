import React from 'react';
import classNames from 'classnames';

export default function UserOption({ data, isFocused, selectOption, requiredRoles = [], showRoles = true, className }) {
  const roles = data?.roles.filter((role) => requiredRoles.includes(role)).join(', ');
  return (
    <div
      className={classNames(
        'flex flex-col justify-between w-full hover:bg-primary-faded focus:bg-primary-faded py-2 px-4 shadow-sm cursor-pointer',
        { 'bg-primary-faded': isFocused },
        className,
      )}
      onClick={() => {
        selectOption(data);
      }}
      aria-label={data.label}
      data-testid={`option-${data.id}`}
    >
      <p className="text-md">{data.title}</p>
      <p className="text-xs text-gray-400 truncate text-ellipsis block" title={data?.username}>
        {data?.username}
      </p>
      {showRoles && (
        <p title={roles} className="text-xs text-gray-400 truncate">
          {roles}
        </p>
      )}
    </div>
  );
}
