import { Launch } from '@carbon/icons-react';

export default function ExternalLink({ href, text }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      className="underline hover:underline inline-flex items-center gap-1"
    >
      {text}
      <Launch />
    </a>
  );
}
