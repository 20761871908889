import React, { useEffect, useMemo, useRef, useContext } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';

// Antd
import { Drawer } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import ProtectedRoute from 'components/ProtectedRoute';
import { ENTITY_TYPES } from 'constants/index';
import FamilyContactCard from 'components/FamilyContactCard';
import useBreadcrumbsParams from 'hooks/useBreadcrumbsParams';
import { selectors, actions } from 'features/family';
import useBreakpoints from 'hooks/useBreakpoints';
import FamilyApplicationReview from './FamilyApplicationReview';
import Section from 'components/Section';
import DocumentsReview from '../DocumentsReview';
import ApplicationEmails from 'components/ApplicationEmails';
import Alerts from 'components/Alerts';
import { ScrollContext } from 'utils';
import CollapsePanel from 'components/CollapsePanel';
import ApplicationStatusCard from 'components/ApplicationStatusCard';
import { familyService } from 'services';
import { Button } from 'antd';
import FamilyChecklist from 'components/Checklist/FamilyChecklist';
import useScrollLock from 'hooks/useScrollLock';
import CaseCoordinatorActivity from '../CaseCoordinatorActivity';

export default function FamilyApplication({ match }) {
  useBreadcrumbsParams('id');
  let { householdId, id } = match.params;
  const dispatch = useDispatch();
  const application = useSelector(selectors.selectApplication);
  const isLoading = useSelector(selectors.selectLoading);
  const breakpoints = useBreakpoints();
  const rootRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [hiddenRootScroll, _, scrollEnabled] = useContext(ScrollContext);

  const isMounted = useRef(false);

  useEffect(
    function () {
      if (!isMounted.current) {
        dispatch(actions.getApplication(id));
      }
    },
    [dispatch, id],
  );
  useEffect(() => {
    isMounted.current = true;
  }, []);

  return (
    <div
      className={classNames(`flex flex-col h-[calc(100vh-50px)]`, {
        'overflow-hidden': hiddenRootScroll,
        'overflow-auto': scrollEnabled && !hiddenRootScroll,
      })}
    >
      <div className="w-full pl-0.5">
        <FamilyContactCard householdId={householdId} application={application || {}} loading={isLoading} />
      </div>
      <div id="page-top-placeholder" className="absolute -top-16 left-0"></div>
      <div ref={rootRef}></div>
      <Switch>
        <ProtectedRoute
          exact
          path="/families/:householdId/applications/:id"
          message="Navigated to Application Overview Page"
        >
          <ApplicationHome loading={isLoading} rootRef={rootRef} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/providers']}
          message="Navigated to Providers Page"
        >
          <Page>Providers</Page>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/children']}
          message="Navigated to Children Page"
        >
          <Page>Children</Page>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/notes']}
          message="Navigated to Notes Page"
        >
          <Page>Notes</Page>
        </ProtectedRoute>
        <ProtectedRoute path="/families/:householdId/applications/:id/documents" message="Navigated to Documents Page">
          <DocumentsReview breakpoints={breakpoints} entityType={ENTITY_TYPES.FAMILY} />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/profile']}
          message="Navigated to Profile Page"
        >
          <Page>Family Profile</Page>
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/messages']}
          message="Navigated to Messages Page"
        >
          <ApplicationEmails className="px-6 py-6" entityType={ENTITY_TYPES.FAMILY} entity={application} />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={['/families/:householdId/applications/:id/alerts']}
          message="Navigated to Alerts Page"
        >
          <Alerts className="px-6 py-6" entityType={ENTITY_TYPES.FAMILY} entityId={id} />
        </ProtectedRoute>
        <ProtectedRoute
          // exact
          path={['/families/:householdId/applications/:id/review']}
          message="Navigated to Family Application Page"
        >
          <FamilyApplicationReview rootRef={rootRef} />
        </ProtectedRoute>
        <Redirect to="/families/:householdId/applications/:id/review" />
      </Switch>
    </div>
  );
}

function Page({ children }) {
  return (
    <div className="white-box">
      <Section heading={children}>
        <div className="py-6">
          <h3 className=" !mb-0">{children}</h3>
        </div>
      </Section>
    </div>
  );
}

function ApplicationHome({ rootRef, history, match, loading }) {
  const { id, householdId } = match.params;
  const application = useSelector(selectors.selectApplication);
  const isFormSubmitting = useSelector(selectors.selectIsFormSubmitting);
  const status = useMemo(() => application?.status, [application?.status]);
  const { contentRef } = useScrollLock({
    topLimit: 90,
    rootRef,
  });

  const [checklistOpen, setChecklistOpen] = React.useState(false);

  return (
    <div>
      <div className="mt-12">
        <div ref={contentRef}></div>
        <div className="flex flex-col justify-center space-y-3 md:space-y-0 md:flex-row mb-5">
          <div className="flex-grow">
            <h3 className="text-xl">Family Application Dashboard</h3>
          </div>

          <div className="actions">
            <Button
              icon={<CheckCircleOutlined />}
              onClick={() => {
                setChecklistOpen(true);
              }}
            >
              Checklist
            </Button>

            <Button
              type="primary"
              onClick={() => history.push(`/families/${householdId}/applications/${id}/review`)}
              data-testid="application-review-btn"
            >
              Review
            </Button>
          </div>
        </div>

        <CollapsePanel
          header={
            <div className="px-4 py-4 bg-white flex items-center">
              <h4 className="text-base text-primary font-semibold uppercase">My Application Status</h4>
            </div>
          }
          collapseClassName="application-status-collapse"
        >
          <ApplicationStatusCard
            status={status}
            loading={loading}
            getPublicStatusList={familyService.getPublicStatusList}
          />
        </CollapsePanel>

        <div className="block mt-6">
          <CaseCoordinatorActivity
            entity={application}
            entityType={ENTITY_TYPES.FAMILY}
            collapseHeaderClassName="py-4"
          />
        </div>
      </div>

      <Drawer
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 0 }}
        open={checklistOpen}
        onClose={() => {
          setChecklistOpen(false);
        }}
      >
        <FamilyChecklist application={application} id={id} className="py-4" isFormSubmitting={isFormSubmitting} />
      </Drawer>
    </div>
  );
}
