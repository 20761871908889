import React from 'react';
import { List } from 'antd';
import { useAsync } from 'react-async-hook';

import { formatDateTimeToLocal, getFullName } from 'utils';
import { familyService, providerService } from 'services';
import { ENTITY_TYPES } from 'constants/index';

import styles from './StatusChangeLogs.module.less';

export default function StatusChangeLogs({ entity, entityType = ENTITY_TYPES.CARE_FACILITY }) {
  const { result: logs, loading } = useAsync(
    async (entity) => {
      if (entityType === ENTITY_TYPES.CARE_FACILITY) {
        if (!entity?.id) return [];
        return await providerService.getStatusChangelog(entity.id, entity.application?.id);
      } else {
        if (!entity?.id) return [];
        return await familyService.getStatusChangelog(entity.id);
      }
    },
    [entity],
  );
  return (
    <div className={styles.changeLogs}>
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: () => {},
          pageSize: 3,
        }}
        loading={loading}
        dataSource={logs}
        renderItem={(item) => {
          return (
            <div id="change-logs-container">
              <List.Item key={item.id}>
                <div className="flex flex-col h-auto lg:h-20 md:flex-row md:flex-wrap lg:flex-nowrap w-full items-start md:items-center p-5 justify-between log-item">
                  <div className="box pt-3 lg:pt-0">
                    <p className="label">Status</p>
                    <p className="font-semibold">{item.status.description}</p>
                  </div>
                  <div className="box pt-3 lg:pt-0">
                    <p className="label">Modified By</p>
                    <p className="font-semibold">{getFullName(item.lastModifiedBy)}</p>
                  </div>
                  <div className="box pt-3 lg:pt-0">
                    <p className="label">Modified Date</p>
                    <p className="font-semibold truncate overflow-ellipsis">
                      {formatDateTimeToLocal(item.lastModifiedDate)}
                    </p>
                  </div>
                </div>
              </List.Item>
            </div>
          );
        }}
      />
    </div>
  );
}
