import { Card as AntdCard, Button } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './Card.module.less';
import { useState } from 'react';
import { ChevronDown, ChevronUp } from '@carbon/icons-react';
import Space from 'components/Space/Space';

export default function Card(props) {
  const { noBodyPadding, className, children, collapsible, ...restProps } = props;

  const [open, setOpen] = useState(true);

  return (
    <AntdCard
      className={classNames(
        {
          'no-body-padding': noBodyPadding || (collapsible && !open),
          'no-heading-border': !children || (collapsible && !open),
        },
        className,
      )}
      {...restProps}
      extra={
        collapsible ? (
          <Space>
            {restProps.extra}

            <Button
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
              icon={open ? <ChevronUp /> : <ChevronDown />}
            />
          </Space>
        ) : (
          restProps.extra
        )
      }
    >
      {open ? children : null}
    </AntdCard>
  );
}

Card.propTypes = {
  noBodyPadding: PropTypes.bool,
};
