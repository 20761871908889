import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse, Button } from 'antd';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import styles from './CollapsePanel.module.less';

export default function CollapsePanel({
  header,
  testId,
  children,
  extra,
  className,
  collapseClassName,
  panelClassName,
  triggerClassName,
  defaultOpen,
  panelKey = 'panel',
  activeKey,
  setActiveKey,
  expandIcon,
  ...rest
}) {
  const [activeKeyState, setActiveStateKey] = useState(defaultOpen ? panelKey : undefined);
  const items = useMemo(() => {
    return [
      {
        className: panelClassName,
        label: (
          <div className="w-full">
            {React.cloneElement(header, {
              onClick: (e) => {
                /* istanbul ignore else */
                if (e.currentTarget?.contains(e.target)) {
                  setActiveStateKey((v) => {
                    /* istanbul ignore else */
                    if (typeof setActiveKey === 'function') {
                      setActiveKey(v ? undefined : panelKey);
                    }
                    return v ? undefined : panelKey;
                  });
                }
              },
            })}
          </div>
        ),
        key: panelKey,
        extra: extra,
        children,
      },
    ];
  }, [children, extra, header, panelClassName, panelKey, setActiveKey]);
  return (
    <div className={classNames('w-full', className)}>
      <Collapse
        className={classNames(styles.collapse, collapseClassName)}
        accordion
        bordered={false}
        collapsible="disabled"
        activeKey={typeof setActiveKey === 'function' ? activeKey : activeKeyState}
        expandIcon={
          expandIcon
            ? ({ isActive }) => expandIcon({ isActive, setActiveStateKey, setActiveKey, panelKey })
            : ({ isActive }) => {
                return (
                  <Button
                    className={classNames('icon-btn', triggerClassName)}
                    data-testid={testId}
                    icon={isActive ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    onClick={() => {
                      setActiveStateKey(isActive ? undefined : panelKey);
                      /* istanbul ignore else */
                      if (typeof setActiveKey === 'function') {
                        setActiveKey(isActive ? undefined : panelKey);
                      }
                    }}
                  />
                );
              }
        }
        {...rest}
        items={items}
      />
    </div>
  );
}

CollapsePanel.propTypes = {
  activeKey: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  collapseClassName: PropTypes.string,
  defaultOpen: PropTypes.bool,
  extra: PropTypes.any,
  header: PropTypes.any,
  panelClassName: PropTypes.string,
  panelKey: PropTypes.string,
  setActiveKey: PropTypes.func,
  testId: PropTypes.string,
  triggerClassName: PropTypes.string,
};

CollapsePanel.defaultProps = {
  className: '',
  defaultOpen: true,
  panelClassName: '',
  testId: '',
  triggerClassName: 'right-1 top-4',
  extra: null,
};
