import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectors } from 'features/family';

export default function Actions({
  className = 'w-full p-4 md:p-5 xl:p-6 2xl:flex 2xl:justify-between space-y-3 2xl:space-y-0',
  actionsClassName = 'w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end 2xl:w-3/4',
  btnClassName = 'sm:basis-1/4 2xl:basis-48',
  hideSaveBtn,
  saveBtnLabel,
  onCancel,
  onClear,
  onBack,
  onSubmit,
  pre,
  post,
  testId = '',
  style,
}) {
  const isFormSubmitting = useSelector(selectors.selectIsFormSubmitting);
  return (
    <div className={classNames(className)} style={style}>
      <div>{pre}</div>
      <div className={classNames(actionsClassName)}>
        {onCancel && (
          <Button
            data-testid={`cancel-${testId}`}
            onClick={onCancel}
            disabled={isFormSubmitting}
            className={btnClassName}
          >
            Cancel
          </Button>
        )}
        {onClear && (
          <Button
            data-testid={`clear-${testId}`}
            onClick={onClear}
            disabled={isFormSubmitting}
            className={btnClassName}
          >
            Clear Form
          </Button>
        )}
        {onBack && (
          <Button data-testid={`back-${testId}`} onClick={onBack} disabled={isFormSubmitting} className={btnClassName}>
            Back
          </Button>
        )}

        {hideSaveBtn !== true && (
          <Button
            data-testid={`next-${testId}`}
            type="primary"
            htmlType="submit"
            onClick={onSubmit}
            disabled={isFormSubmitting}
            loading={isFormSubmitting}
            className={btnClassName}
          >
            {saveBtnLabel || 'Save & Next'}
          </Button>
        )}
      </div>
      {post}
    </div>
  );
}
