import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import ApplicationNavBar from 'components/ApplicationNavBar';
import useBreakpoints from 'hooks/useBreakpoints';
import { getFamilyApplicationReviewNavItems } from 'utils';
import { ENTITY_TYPES } from 'constants/index';
import { selectors } from 'features/family';
import FamilyCertificatesTable from 'containers/Family/FamilyCertificates/FamilyCertificatesTable';

export default function FamilyCertificates() {
  const { id, householdId } = useParams();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const application = useSelector(selectors.selectApplication);
  const loading = useSelector(selectors.selectLoading);
  const isFormSubmitting = useSelector(selectors.selectIsFormSubmitting);
  const navItems = useMemo(
    () =>
      getFamilyApplicationReviewNavItems({
        id,
        householdId,
        application,
        selectedParent: application?.additionalParents?.[0],
        selectedChild: application?.children?.[0],
      }),
    [application, householdId, id],
  );

  return (
    <div>
      <ApplicationNavBar
        breakpoints={breakpoints}
        items={navItems}
        entity={application}
        entityType={ENTITY_TYPES.HOUSEHOLD}
        toMail={''}
        className="bg-light-bg"
        hideEmail
      />

      <div className="p-6">
        <div className="bg-white">
          <FamilyCertificatesTable
            id={id}
            householdId={householdId}
            application={application}
            loading={loading}
            hideAddCertificate={false}
            filter={(item) => item.application.id === Number(id)}
          />
          <div className="p-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
            <Button
              onClick={() => history.push(`/families/${householdId}/applications/${application.id}`)}
              disabled={isFormSubmitting}
            >
              Cancel
            </Button>
            <Button
              onClick={() => history.push(`/families/${householdId}/applications/${id}/review/eligibility`)}
              disabled={isFormSubmitting}
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => history.push(`/families/${householdId}/applications/${id}/review/finalReview`)}
              disabled={isFormSubmitting}
              loading={isFormSubmitting}
            >
              Finalize Review
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
