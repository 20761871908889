import React from 'react';
import classNames from 'classnames';

import SearchProviders from 'components/SearchProviders';
import Header from 'components/Header';
import SearchBar from 'components/SearchProviders/SearchBar';
import useBreakpoints from 'hooks/useBreakpoints';

export default function PublicSearch({ className }) {
  const breakpoints = useBreakpoints();
  return (
    <div className={classNames('h-screen overflow-hidden', className)}>
      <Header
        className="bg-white shadow-md top-0 flex justify-between items-center z-20"
        centerContent={breakpoints.lg ? <SearchBar className="min-w-max" isPublicSearch /> : null}
        theme="primary"
      />
      <SearchProviders className="mt-20" isPublicSearch />
    </div>
  );
}
