import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './CountPill.module.less';

export default function CountPill({ count, className, formatter, ...rest }) {
  return (
    <div className={classNames(styles.count, className)} {...rest}>
      {formatter(count)}
    </div>
  );
}

CountPill.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
  formatter: PropTypes.func,
};
CountPill.defaultProps = {
  count: 0,
  className: '',
  formatter: (v) => v,
};
