import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import Checklist from './Checklist';
import useAsyncFn from 'hooks/useAsyncFn';
import { actions } from 'features/add-center';
import { CHECKLIST_TYPES } from 'constants/index';
import providerService from 'services/providers.service';
import checklistService from 'services/checklist.service';
import ChecklistModal from 'components/Modals/ChecklistModal';

const keys = [
  'Program',
  'Business Info',
  'Contact Information',
  'Facility Details',
  'Credentials',
  'Schedule of Care',
  'Rates & Discounts',
  'Special Program Respite Rates',
  'Staff BGC',
  'Liability Insurance',
  'Additional Docs',
  'Provider Agreement',
];

export default function ProviderChecklist({ center, isFormSubmitting }) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [statusState, changeApplicationStatus] = useAsyncFn(
    async (newStatus) => {
      const status = await providerService.changeApplicationStatus(center.id, center.application?.id, newStatus.id);
      dispatch(actions.setCenterInfo({ ...center, application: { ...(center.application || {}), status } }));
      return status;
    },
    [center, dispatch],
    {
      value: center?.application?.status?.status,
    },
  );
  const [notesState, getNote] = useAsyncFn(async () => {
    return await providerService.getDraftNote(center.application?.id);
  }, [center?.application]);
  const saveNote = useCallback(
    async (values) => {
      return await providerService.saveNote(center?.application?.id, values);
    },
    [center?.application?.id],
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (center?.application?.id) {
      getNote(center?.application?.id);
    }
  }, [center?.application?.id, getNote]);
  return (
    <>
      <Checklist
        id={center.application?.id}
        center={center}
        saveChecklist={(id, items) => checklistService.updateChecklist(id, CHECKLIST_TYPES.PROVIDER, items)}
        sectionKeys={keys}
        isFormSubmitting={isFormSubmitting}
        notesState={notesState}
        getNote={getNote}
        saveNote={saveNote}
        statusState={statusState}
        updateStatus={changeApplicationStatus}
        type={CHECKLIST_TYPES.PROVIDER}
        onGenerateChecklist={() => setVisible(true)}
      />

      <ChecklistModal
        setVisible={setVisible}
        visible={visible}
        title="generate checklist email"
        entity={center}
        type={CHECKLIST_TYPES.PROVIDER}
      />
    </>
  );
}
