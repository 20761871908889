import React, { useEffect } from 'react';
// import { Button } from 'antd';
import { useDispatch } from 'react-redux';
// import AddProviderModal from 'components/Modals/AddProviderModal';
// import EnhancedReferralRequestModal from 'components/Modals/EnhancedReferralRequestModal';
import SearchProviderModal from 'components/Modals/SearchProviderModal';
// import Modal from 'components/Modal';
import { actions } from 'features/search';

const SelectProvider = ({
  parentForm,
  applicationValues,
  childValues,
  applicationId,
  index,
  setVisible,
  visible,
  schedule,
  childId,
  reload,
}) => {
  const dispatch = useDispatch();
  // const [visibleAddProviderModal, setVisibleAddProviderModal] = useState(false);
  // const [visibleEnhancedReferralModal, setVisibleEnhancedReferralModal] = useState(false);
  // const [visibleSearchProviderModal, setVisibleSearchProviderModal] = useState(false);

  useEffect(() => {
    if (visible) {
      dispatch(actions.resetSearch());
    }
  }, [dispatch, visible]);

  return (
    <>
      <SearchProviderModal
        // parentForm={form}
        visible={visible}
        setVisible={() => {
          // setVisibleSearchProviderModal(false);
          setVisible(false);
          reload();
        }}
        selectedProviders={[]}
        childId={childId}
        applicationId={applicationId}
        scheduleId={schedule?.id}
      />

      {/* <div className="divide-y space-y-2"> */}
      {/* <div className="flex flex-col md:flex-row py-3 md:space-x-10">
          <div className="w-full sm:w-60">
            <Button
              onClick={() => {
                dispatch(actions.resetSearch());
                setVisibleSearchProviderModal(true);
              }}
              className="w-full"
              type="primary"
            >
              Search for a Provider
            </Button>
          </div>
          <div className="md:flex-1 mt-6 md:mt-0 pl-6 md:pl-0">
            <p className="relative">
              For <b>already approved and accredited</b> providers, use our <b>GreatChildCare.org</b> provider search to
              search for child care that meets your family's needs. Search our provider database for providers who have
              applied for programs in the past. <br />
              <b>Please note:</b> providers in this search may or may not qualify for fee assistance based on your
              individual program requirements.
            </p>
          </div>
        </div> */}

      {/* id for MCCYN === 1 program, ER request options will only shows for MCCYNC */}
      {/* {applicationValues?.program?.type?.id === PROGRAM_TYPES.MCCYN && (
          <div className="flex flex-col md:flex-row py-3 md:space-x-10">
            <div className="w-full sm:w-60">
              <Button onClick={onCreatingErRequest} className="w-full">
                Need help finding a provider
              </Button>
            </div>
            <div className="md:flex-1 mt-6 md:mt-0 pl-6 md:pl-0">
              <p className="relative">
                <span className="absolute top-0 -left-6">2. </span>
                To assist families seeking qualified care CCAoA representatives can work with you directly to find a
                child care provider in your area that meets your family's needs.
              </p>
            </div>
          </div>
        )}
        <div className="flex flex-col md:flex-row py-3 md:space-x-10">
          <div className="w-full sm:w-60">
            <Button onClick={() => setVisibleAddProviderModal(true)} className="w-full">
              Provider not listed in search
            </Button>
          </div>
          <div className="md:flex-1 mt-6 md:mt-0 pl-6 md:pl-0">
            <p className="relative">
              <span className="absolute top-0 -left-6">
                {applicationValues?.program?.type?.id === PROGRAM_TYPES.MCCYN ? '3' : '2'}.{' '}
              </span>
              If your current provider is not listed, fill out this form and we will assist in determining if they are
              eligible to provide care in your fee assistance program.
            </p>
          </div>
        </div> */}

      {/* <AddProviderModal
          // parentForm={form}
          visible={visibleAddProviderModal}
          setVisible={() => {
            setVisibleAddProviderModal(false);
            setVisible(false);
            reload();
          }}
          childId={childId}
          applicationId={applicationId}
          index={index}
          scheduleId={schedule?.id}
        />

        <EnhancedReferralRequestModal
          visible={visibleEnhancedReferralModal}
          setVisible={() => {
            setVisibleEnhancedReferralModal(false);
            setVisible(false);
            reload();
          }}
          rootForm={parentForm}
          applicationValues={applicationValues}
          childValues={childValues}
          applicationId={applicationId}
          index={index}
          scheduleId={schedule?.id}
        />
      </div> */}
    </>
  );
};
export default SelectProvider;
