import React, { useMemo } from 'react';
import { Descriptions, Skeleton } from 'antd';
import { formatDate, getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';

export default function PersonalInfoHeader({ personLabel = 'Sponsor', parent, loading, headerExtraElement }) {
  const items = useMemo(
    () => [
      {
        id: 'person_dateOfBirth',
        title: 'Date of Birth',
        value: formatDate(parent?.dateOfBirth),
      },
      {
        id: 'person_phone',
        title: 'Preferred Phone',
        value: getFormattedPhoneNumber(parent?.phone) || 'N/A',
      },
      {
        id: 'person_secondaryPhone',
        title: 'Secondary Phone',
        value: getFormattedPhoneNumber(parent?.secondaryPhone) || 'N/A',
      },
      {
        id: 'person_maritalStatus_title',
        title: 'Marital Status',
        value: parent?.maritalStatus?.title || 'N/A',
      },
      {
        id: 'person_email',
        title: 'Prefered Email',
        value: parent?.email || 'N/A',
      },
      {
        id: 'person_workEmail',
        title: 'Secondary Email',
        value: parent?.workEmail || 'N/A',
      },
      {
        id: 'person_soleParent',
        title: 'Sole Parent',
        value: parent?.singleParent ? 'Yes' : 'No',
      },
      {
        id: 'person_homeAddress',
        title: 'Home Address',
        value: getCompletedAddress(parent?.homeAddress) || 'N/A',
        className: 'col-span-1 lg:col-span-2',
      },
    ],
    [parent],
  );
  const descItems = useMemo(
    () => items.map((item) => ({ key: item.id, label: item.title, children: item.value })),
    [items],
  );
  return (
    <div className="px-6 pb-6 pt-5">
      <Skeleton avatar loading={loading} paragraph={{ rows: 4 }}>
        <div className="mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Sponsor:</span>{' '}
                <span className=" text-primary font-medium">{getFullName(parent)}</span>
              </p>
            }
            size="small"
            items={descItems}
            extra={headerExtraElement}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {descItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}
