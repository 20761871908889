import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';

import { SnapshotContext } from 'utils';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { actions, selectors } from 'features/family';
import ChildDetails from './Child/ChildDetails';
import SponsorDetails from './Sponsor/SponsorDetails';
import EligibilityDetails from './Eligibility/EligibilityDetails';
import ProtectedRoute from 'components/ProtectedRoute';
import SecondParentDetails from './SecondParent/SecondParentDetails';
import FamilyCertificates from 'containers/Internal/FamilyApplication/Certificates/FamilyCertificates';
import FinalizeReview from 'containers/Internal/FamilyApplication/FinalizeReview/FinalizeReview';
import AddCertificate from 'containers/Internal/FamilyApplication/Certificates/CertificateInfo';

export default function FamilyApplicationReview({ match }) {
  let { id, householdId, step = ADD_FAMILY_STEPS.SPONSOR, tab, nestedTab } = match.params;
  const application = useSelector(selectors.selectApplication);
  const isLoading = useSelector(selectors.selectLoading);
  const rootRef = useRef(null);
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  useEffect(
    function () {
      if (!isMounted.current) {
        dispatch(actions.getApplication(id));
      }
    },
    [dispatch, id],
  );
  useEffect(() => {
    isMounted.current = true;
  }, []);

  useEffect(() => {
    const selector = '#content-area-locator';
    // if (!isInViewport(selector) && (step || tab || nestedTab)) {
    if (step || tab || nestedTab) {
      /* istanbul ignore else */
      setTimeout(() => {
        const container = document.querySelector(selector);
        container?.scrollIntoView?.(
          {
            behavior: 'smooth',
            block: 'nearest',
          },
          300,
        );
      }, 500);
    }
  }, [nestedTab, step, tab]);

  return (
    <div>
      <div ref={rootRef}></div>

      <SnapshotContext.Provider value={{ snapshotSelector: () => null }}>
        <Switch>
          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review/certificates/add']}
            message="Navigated to Add Certificate Page"
          >
            <AddCertificate householdId={householdId} application={application} loading={isLoading} rootRef={rootRef} />
          </ProtectedRoute>
          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review/certificates/:certificateId']}
            message="Navigated to Add Certificate Page"
          >
            <AddCertificate householdId={householdId} application={application} loading={isLoading} rootRef={rootRef} />
          </ProtectedRoute>

          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review/certificates']}
            message="Navigated to Certificates Page"
          >
            <FamilyCertificates
              householdId={householdId}
              application={application}
              loading={isLoading}
              rootRef={rootRef}
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review/finalReview']}
            message="Navigated to Review Page"
          >
            <FinalizeReview householdId={householdId} application={application} loading={isLoading} rootRef={rootRef} />
          </ProtectedRoute>
          <Redirect
            exact
            from="/families/:householdId/applications/:id/review/sponsor"
            to="/families/:householdId/applications/:id/review/sponsor/info"
          />
          <Redirect
            exact
            from="/families/:householdId/applications/:id/review"
            to="/families/:householdId/applications/:id/review/sponsor/info"
          />
          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review/children/:childId']}
            message="Navigated to Child Review Page"
          >
            <ChildDetails householdId={householdId} application={application} loading={isLoading} rootRef={rootRef} />
          </ProtectedRoute>
          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review/secondParent/:parentId']}
            message="Navigated to Second Parent Review Page"
          >
            <SecondParentDetails
              householdId={householdId}
              application={application}
              loading={isLoading}
              rootRef={rootRef}
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review/eligibility']}
            message="Navigated to Eligibility Review Page"
          >
            <EligibilityDetails
              householdId={householdId}
              application={application}
              loading={isLoading}
              rootRef={rootRef}
            />
          </ProtectedRoute>
          <ProtectedRoute
            path={['/families/:householdId/applications/:id/review']}
            message="Navigated to Sponsor Review Page"
          >
            <SponsorDetails householdId={householdId} application={application} loading={isLoading} rootRef={rootRef} />
          </ProtectedRoute>
          <Redirect to="/families/:householdId/applications/:id/review/sponsor/info" />
        </Switch>
      </SnapshotContext.Provider>
    </div>
  );
}
