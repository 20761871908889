import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Tabs } from 'antd';
import classNames from 'classnames';
import { Menu as MenuUi, Transition } from '@headlessui/react';
import CountPill from 'components/CountPill';
import { BiDotsVerticalRounded } from 'react-icons/bi';

function getNextActiveTab(tabs, disabledTabs, activeIndex, direction) {
  if (Object.values(disabledTabs).filter(Boolean).length < tabs.length) {
    const nextIndex =
      direction === 'ArrowLeft' ? (activeIndex + tabs.length - 1) % tabs.length : (activeIndex + 1) % tabs.length;
    const nextTab = tabs[nextIndex];
    if (disabledTabs[nextTab.key]) {
      return getNextActiveTab(tabs, disabledTabs, nextIndex, direction);
    }
    return nextTab;
  }
  return tabs[0];
}

export default function TabsComponent({
  id,
  items,
  activeKey,
  onTabClick,
  className,
  showCount,
  disabledTabs,
  destroyInactiveTabPane,
}) {
  const [key, setActiveKey] = useState(activeKey || items[0]?.key);
  const onTabClickAction = (tab) => {
    if (!disabledTabs[tab]) {
      if (onTabClick) {
        onTabClick(tab);
      } else {
        setActiveKey(tab);
      }
    }
  };

  return (
    <Tabs
      id={id}
      defaultActiveKey={activeKey}
      type="card"
      activeKey={onTabClick ? activeKey : key}
      onTabClick={onTabClickAction}
      className={className}
      animated={{ inkBar: false, tabPane: false }}
      destroyInactiveTabPane={destroyInactiveTabPane}
      renderTabBar={(props) => {
        return (
          <div role="tablist" className="ant-tabs-nav">
            <div className="ant-tabs-nav-wrap">
              <div className="ant-tabs-nav-list overflow-x-auto !pl-0.5">
                {items.map((item, index) => (
                  <div
                    className={classNames('ant-tabs-tab first:!pl-0.5 !w-auto !border-t-2 !border-l-2 !border-r-2', {
                      'ant-tabs-tab-active': item.key === props.activeKey,
                      'cursor-not-allowed pointer-events-none !bg-gray-300 !bg-opacity-50': disabledTabs?.[item.key],
                      '!w-auto': items?.length > 6,
                    })}
                    disabled={disabledTabs?.[item.key]}
                    tabIndex={-1} //disabledTabs?.[item.key] ? -1 : 0}
                    onClick={() => !disabledTabs?.[item.key] && props.onTabClick(item.key)}
                    key={item.key}
                    onKeyDown={(e) => {
                      if (e.code === 'ArrowLeft' || e.code === 'ArrowRight') {
                        const tab = document.getElementById(
                          `rc-tabs-0-tab-${getNextActiveTab(items, disabledTabs, index, e.code).key}`,
                        );
                        tab.focus();
                        tab.click();
                      }
                    }}
                  >
                    <div
                      role="tab"
                      aria-selected={item.key === props.activeKey}
                      id={`rc-tabs-0-tab-${item.key}`}
                      className={classNames('ant-tabs-tab-btn', {
                        '!px-8': items?.length < 6,
                        '!px-6': items?.length >= 6,
                        '!px-5': items?.length > 10,
                      })}
                      aria-controls={`rc-tabs-0-panel-${item.key}`}
                      tabIndex={item.key === props.activeKey ? 0 : -1}
                    >
                      <span>{item.label}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="ant-tabs-nav-operations z-10">
              <MenuUi as="div" className={classNames('z-10')}>
                <div>
                  <MenuUi.Button
                    className={classNames(
                      'ant-tabs-nav-more h-10 !w-10 nav-action sm:w-auto !px-0 flex justify-center items-center text-sm text-gray-600 font-medium hover:bg-white hover:text-primary focus:bg-white focus:ring-2 focus:ring-inset outline-none focus:text-primary focus:font-semibold',
                    )}
                    tabIndex="-1"
                    aria-hidden="true"
                    aria-haspopup="listbox"
                    aria-controls="rc-tabs-0-more-popup"
                    id="rc-tabs-0-more"
                    aria-expanded="false"
                  >
                    <BiDotsVerticalRounded size={18} color="#8798AD" />
                  </MenuUi.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuUi.Items className="absolute top-10 z-10 w-64 right-0 bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none flex flex-col p-3">
                    {items.map((item, index) => (
                      <MenuUi.Item key={item.key} disabled={disabledTabs?.[item.key]}>
                        {({ active }) => (
                          <button
                            title={item.label}
                            className={classNames(`group h-8 w-full px-2 text-left truncate ${className}`, {
                              'text-primary font-semibold ring-2 ring-inset': active || props.activeKey === item.key,
                              'cursor-not-allowed pointer-events-none text-gray-500': disabledTabs?.[item.key],
                            })}
                            onClick={() => {
                              const tab = document.getElementById(`rc-tabs-0-tab-${items[index].key}`);
                              tab.focus();
                              tab.click();
                            }}
                          >
                            {item.label}
                          </button>
                        )}
                      </MenuUi.Item>
                    ))}
                  </MenuUi.Items>
                </Transition>
              </MenuUi>
            </div>
          </div>
        );
      }}
      items={items.map((item) => ({
        ...item,
        children: item.content,
        label: showCount ? (
          <div className="w-full px-3 flex items-center justify-between">
            <span>{item.label}</span>
            <CountPill
              className={classNames('h-7 ml-4', { 'bg-gray-200 text-gray-500': activeKey !== item.key })}
              count={item.count || 0}
            />
          </div>
        ) : (
          <span>{item.label}</span>
        ),
      }))}
    />
  );
}

TabsComponent.propTypes = {
  activeKey: PropTypes.string,
  disabledTabs: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, content: PropTypes.any })),
  onTabClick: PropTypes.func,
  showCount: PropTypes.bool,
  openTabs: PropTypes.object,
  destroyInactiveTabPane: PropTypes.bool,
};

TabsComponent.defaultProps = {
  disabledTabs: {},
  items: [],
  showCount: false,
  destroyInactiveTabPane: false,
};
