import { message } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import { getGooglePlace, getPlaceLatLng } from 'utils';

export default function usePlace({ id }) {
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(null);
  const [center, setCenter] = useState(null);
  const setPlaceLatLng = useCallback(async (id) => {
    setLoading(true);
    try {
      const place = await getGooglePlace(id);
      const point = getPlaceLatLng(place);
      setPlace({ ...(place || {}), point });
      setCenter(point);
    } catch (error) {
      message.error('Invalid location');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id) setPlaceLatLng(id);
  }, [id, setPlaceLatLng]);
  return { place, center, loading, setCenter, setPlace };
}
