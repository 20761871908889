import React, { useEffect, useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Messages from './Messages';
import AddCenter from './AddCenter';
import UserProfile from './UserProfile';
import HelpTree from 'components/HelpTree';
import LayoutPage from 'components/Layout';
import Applications from './Applications';
import LoadingPage from 'components/LoadingPage';
import AttendanceSheets, { CareLocations } from './AttendanceSheets';
import ProtectedRoute from 'components/ProtectedRoute';
import { Home, Result } from '@carbon/icons-react';
import BusinessProfile from './v2/BusinessProfile/BusinessProfile';
import { useSelector } from 'react-redux';
import { selectors } from 'features/auth';
import { Spin } from 'antd';
import SelectProgram from './SelectProgram';
import ApplicationProgramWrapper from './Applications/ApplicationProgramWrapper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const rootBreadcrumb = { text: 'Provider', to: '/provider' };
const addFacilityRegex = /^\/provider\/facility(.*)$/;
const centerProfileRegex = /^\/provider\/profile\/(.*)$/;
const applicationDashboardRegex = /^\/provider\/application(.*)$/;
const breadcrumbs = [
  ['/provider', [rootBreadcrumb]],
  ['/provider/facilities', [rootBreadcrumb, { text: 'Facilities', to: '/provider/facilities' }]],
  ['/provider/user-profile', [rootBreadcrumb, { text: 'Profile', to: '/provider/user-profile' }]],
  [
    centerProfileRegex,
    [rootBreadcrumb, { text: 'Profile', to: '/provider/profile' }, { text: (params) => params.id, to: '' }],
  ],
  ['/provider/profile', [rootBreadcrumb, { text: 'Profile', to: '/provider/profile' }]],
  [
    applicationDashboardRegex,
    [rootBreadcrumb, { text: 'Application', to: '/provider/application' }, { text: (params) => params.id, to: '' }],
  ],
  ['/provider/messages', [rootBreadcrumb, { text: 'Messages', to: '/provider/messages' }]],
  [
    addFacilityRegex,
    [rootBreadcrumb, { text: 'Add Facility', to: '/provider/facility' }, { text: (params) => params.id, to: '' }],
  ],
];

export default function Provider(props) {
  const organization = useSelector(selectors.selectOrganization);

  // Organization data
  const history = useHistory();

  useEffect(() => {
    if (organization && organization.profileValid === false) {
      history.push('/provider/business-profile');
    }
  }, [history, organization]);

  const menuOptions = useMemo(() => {
    return [
      {
        label: 'Locations and programs',
        icon: Home,
        to: '/provider/locations-and-programs',
        match: [
          '/provider/locations-and-programs',
          /^\/provider\/applications\/\d+(.*)/,
          '/provider/applications',
          /^\/provider\/application\/\d+(.*)/,
          /^\/provider\/\d+(.*)\/add-application/,
          '/provider/facility',
          /^\/provider\/facility\/\d+(.*)/,
        ],
        disabled: !organization || organization?.profileValid === false,
      },
      {
        label: 'Business profile',
        icon: Result,
        to: '/provider/business-profile',
        match: ['/provider/business-profile'],
      },
    ];
  }, [organization]);

  return (
    <LayoutPage {...props} menuOptions={menuOptions} breadcrumbs={breadcrumbs}>
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          <ProtectedRoute exact path="/provider" roleRequired="Provider" message="Navigated to Provider Landing Page">
            <>
              {!organization && <Spin />}
              {organization && (
                <Redirect
                  to={!!organization.profileValid ? '/provider/locations-and-programs' : '/provider/business-profile'}
                />
              )}
            </>
          </ProtectedRoute>

          <ProtectedRoute exact path="/provider/help" roleRequired="Provider" message="Navigated to Help Page">
            <HelpTree />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/locations-and-programs"
            roleRequired="Provider"
            message="Navigated to Applications Page"
          >
            <Applications />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/business-profile"
            roleRequired="Provider"
            message="Navigated to Provider Landing Page"
          >
            <BusinessProfile />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/messages"
            roleRequired="Provider"
            message="Navigated to Provider Messages Page"
          >
            <Messages />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/attendance-sheets"
            roleRequired="Provider"
            message="Navigated to Provider Attendance Sheets Page"
          >
            <CareLocations />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/:id/attendance-sheets/:childId"
            roleRequired="Provider"
            message="Navigated to Provider Attendance Sheets Page"
          >
            <AttendanceSheets />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/:providerId/add-application"
            roleRequired="Provider"
            message="Navigated to Application Creation Page"
          >
            <SelectProgram />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path={['/provider/applications/:id/', '/provider/applications/:id/:step']}
            roleRequired="Provider"
            message="Navigated to Application Wrapper Page"
          >
            <ApplicationProgramWrapper />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/facility/:id/:step/:tab?"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/facility/:id"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/facility"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/user-profile"
            roleRequired="Provider"
            message="Navigated to User Profile Page"
          >
            <UserProfile />
          </ProtectedRoute>

          <Redirect to="/provider" />
          {/* <ProtectedRoute message="Navigated to Parent Dashboard">
            <h3>No Content yet </h3>
          </ProtectedRoute> */}
        </Switch>
      </React.Suspense>
    </LayoutPage>
  );
}
