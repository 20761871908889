import React, { useCallback, useEffect, useState, useMemo, useContext } from 'react';
import { Steps, message, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import { useAsyncCallback } from 'react-async-hook';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { AdultFormFields } from './AdultFamilyMembers';
import { breakpointsContext } from 'utils';
import { AdultContactInformationFormFields } from './AdultContactInformation';
import { AdultEmploymentInformationFormFields, AdultMilitaryInformationFormFields } from './AdultEmploymentInformation';
import AdultSchoolInformation from './AdultSchoolInformation';
import { AddFamilyChildMemberModal } from './AddChildFamilyMemberWizard';
import Form from 'components/Form';
import { actions } from 'features/family';
import familyService from 'services/family.service';
import { selectActiveRole } from 'features/auth';
import { ROLES } from 'constants/index';
import AdultFamilyRelationship from './AdultFamilyRelationship';
import { FamilyRelationshipModal } from './AddFamilyRelationshipWizard';

const AddAdultFamilyMemberWizard = ({
  reload,
  profileData,
  setVisible,
  visible,
  loading,
  item,
  currentStep: currentStepCoordinator,
  personId,
  application,
  ...rest
}) => {
  const [showChildWizard, setShowChildWizard] = useState(false);
  const [visibleAddRelationshipWizard, setVisibleAddRelationshipWizard] = useState(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [currentStep, setCurrentStep] = useState(0);
  const activeRole = useSelector(selectActiveRole);
  const isFamilyUser = useMemo(() => activeRole === ROLES.FAMILY, [activeRole]);
  const isInfoSection = useMemo(
    () => activeRole !== ROLES.FAMILY && currentStepCoordinator === 0,
    [activeRole, currentStepCoordinator],
  );

  const adultInfoSteps = useMemo(
    () => [
      {
        title: <span className="text-xs">Basic Information</span>,
      },
      {
        title: <span className="text-xs">Contact Information</span>,
        disabled: isFamilyUser,
      },
      {
        title: <span className="text-xs">Employment Information</span>,
        disabled: isFamilyUser,
      },
      {
        title: <span className="text-xs">School Information</span>,
        disabled: false,
      },
    ],
    [isFamilyUser],
  );

  const stepsData = useMemo(() => {
    let tempSteps =
      activeRole === ROLES.FAMILY ? adultInfoSteps : adultInfoSteps.slice(isInfoSection ? 0 : 2, isInfoSection ? 2 : 5);
    if (isInfoSection) {
      tempSteps.push({
        title: <span className="text-xs">Family Relationships</span>,
      });
    }
    return tempSteps;
  }, [activeRole, adultInfoSteps, isInfoSection]);

  const [steps, setSteps] = useState(stepsData);
  const [profileInfo, setProfileInfo] = useState({});
  const [adultInfoForm] = Form.useForm();
  const [contactInfoForm] = Form.useForm();
  const [employmentInfoForm] = Form.useForm();
  const [militaryInfoForm] = Form.useForm();
  const [schoolInfoForm] = Form.useForm();
  const { sm, md } = useContext(breakpointsContext);

  useEffect(() => {
    adultInfoForm.setFieldsValue({ ...item });
    contactInfoForm.setFieldsValue({ ...item });
    employmentInfoForm.setFieldsValue({ ...item });
    militaryInfoForm.setFieldsValue({ ...item });
    schoolInfoForm.setFieldsValue({ ...item });
  }, [adultInfoForm, contactInfoForm, employmentInfoForm, item, militaryInfoForm, schoolInfoForm]);

  // Values for employment
  const employmentStatus = Form.useWatch(['employmentStatus'], employmentInfoForm);
  const militaryEmployee = Form.useWatch(['militaryEmployee'], employmentInfoForm);
  const militaryInstallationId = Form.useWatch(['position', 'militaryInstallationId'], militaryInfoForm);
  const programSponsorId = Form.useWatch(['position', 'programSponsorId'], militaryInfoForm);
  const militaryComponentId = Form.useWatch(['position', 'militaryComponentId'], militaryInfoForm);
  const employmentValues = {
    employmentStatus,
    militaryInstallationId,
    militaryEmployee,
    position: { programSponsorId, militaryComponentId },
  };

  const adult = useMemo(
    () => (item?.id ? item : currentStep === 0 ? null : profileData?.adults[profileData?.adults?.length - 1]),
    [item, profileData?.adults, currentStep],
  );
  const { loading: addUpdateLoading, execute: onAddItem } = useAsyncCallback(
    async (payload, lastActionType) => {
      try {
        payload = adult?.id ? { ...payload } : [{ ...payload }];
        const updatedData = await familyService.addUpdateAdult(profileData?.id, adult?.id, payload);

        if (!isFamilyUser) {
          let tempAdults = [...profileData.adults];
          const adultIndex = tempAdults.findIndex((adult) => updatedData.id === adult.id);
          tempAdults[adultIndex] = updatedData;
          reload(tempAdults);
          message.success('Updated successfully.', 5);
          if (pathname.includes('sponsor')) {
            dispatch(actions.setApplication({ applicant: updatedData }));
          } else {
            let tempAdditionalParent = [...application.adults];
            const adultIndex = tempAdditionalParent.findIndex((parent) => updatedData.id === parent.id);
            tempAdditionalParent[adultIndex] = updatedData;
            dispatch(actions.setApplication({ adults: tempAdditionalParent }));
          }
          dispatch(actions.setHousehold({ ...profileData, adults: tempAdults }));
        }
        reload();
        setProfileInfo({ ...updatedData, ...profileInfo });
        if (isFamilyUser) {
          if ((currentStep === 4 && militaryEmployee) || (currentStep === 3 && !militaryEmployee)) {
            onCancel(lastActionType);
          } else {
            let tempSteps = [...steps];
            let tempStepIndex = tempSteps[currentStep + 1];
            tempStepIndex.disabled = false;
            tempSteps[currentStep + 1] = tempStepIndex;
            setSteps(tempSteps);
            setCurrentStep(currentStep + 1);
          }
        }
      } catch (e) {
        message.error('Something went wrong.', 5);
      }
    },
    [currentStep, profileData?.id],
  );

  const onCancel = useCallback(
    (lastActionType) => {
      setCurrentStep(0);
      setSteps(stepsData);
      if (lastActionType === 'addingNewAdult') {
        setVisible(true);
      }
      if (lastActionType === 'addingRelationship') {
        setVisibleAddRelationshipWizard(true);
      }
      if (lastActionType === 'addingNewChild') {
        setShowChildWizard(true);
      }
    },
    [setVisible, stepsData],
  );

  const adultSteps = useMemo(() => steps, [steps]);

  useEffect(() => {
    if (
      (militaryEmployee && steps.length === 4) ||
      (!isFamilyUser && steps.length === 2 && militaryEmployee && !isInfoSection)
    ) {
      const tempSteps = [...steps];
      tempSteps.splice(isFamilyUser ? 3 : 1, 0, {
        title: <span className="text-xs">Military Information</span>,
        disabled: isFamilyUser,
      });
      setSteps(tempSteps);
    } else if (
      (!militaryEmployee && adultSteps.length === 5) ||
      (!isFamilyUser && steps.length === 3 && !militaryEmployee && !isInfoSection)
    ) {
      const tempSteps = [...steps];
      tempSteps.splice(isFamilyUser ? 3 : 1, 1);
      setSteps(tempSteps);
    }
  }, [militaryEmployee, steps, adultSteps, isFamilyUser, isInfoSection]);

  const [visibleAddChildWizard, setVisibleAddChildWizard] = useState(false);

  return (
    <Spin spinning={loading}>
      {!showChildWizard && (
        <div className="flex md:flex-row flex-col [&_.ant-steps]:w-full md:[&_.ant-steps]:w-1/4 [&_.ant-btn]:!min-w-[250px]">
          <Steps
            direction={sm && !md ? 'horizontal' : 'vertical'}
            className="mt-6 md:border-r"
            current={currentStep}
            onChange={(step) => setCurrentStep(step)}
            items={adultSteps}
          />
          <div className="mt-6 w-full md:w-3/4 md:pl-10 md:pr-6">
            {
              <div
                className={classNames({
                  hidden: isFamilyUser ? currentStep !== 0 : !(currentStep === 0 && currentStepCoordinator === 0),
                })}
              >
                <Form onFinish={onAddItem} form={adultInfoForm} layout="vertical">
                  <AdultFormFields
                    form={adultInfoForm}
                    addUpdateLoading={addUpdateLoading}
                    setVisible={setVisible}
                    isFamilyUser={isFamilyUser}
                  />
                </Form>
              </div>
            }
            {
              <div
                className={classNames({
                  hidden: isFamilyUser ? currentStep !== 1 : !(currentStep === 1 && currentStepCoordinator === 0),
                })}
              >
                <Form onFinish={onAddItem} layout="vertical" form={contactInfoForm}>
                  <AdultContactInformationFormFields
                    addUpdateLoading={addUpdateLoading || loading}
                    item={item}
                    profileData={profileData}
                    setVisible={() => setCurrentStep(currentStep - 1)}
                    form={contactInfoForm}
                    isFamilyUser={isFamilyUser}
                  />
                </Form>
              </div>
            }
            {
              <div
                className={classNames({
                  hidden: isFamilyUser ? currentStep !== 2 : !(currentStep === 0 && !isInfoSection),
                })}
              >
                <Form onFinish={onAddItem} layout="vertical" form={employmentInfoForm}>
                  <AdultEmploymentInformationFormFields
                    addUpdateLoading={addUpdateLoading || loading}
                    values={employmentValues}
                    form={employmentInfoForm}
                    setVisible={() => setCurrentStep(currentStep - 1)}
                    militaryEmployee={militaryEmployee}
                    isFamilyUser={isFamilyUser}
                  />
                </Form>
              </div>
            }
            {
              <div
                className={classNames({
                  hidden: isFamilyUser
                    ? !militaryEmployee
                      ? currentStep !== 4
                      : currentStep !== 3
                    : isInfoSection || !(militaryEmployee && currentStep === 1 && !isFamilyUser),
                })}
              >
                <Form onFinish={onAddItem} layout="vertical" form={militaryInfoForm}>
                  <AdultMilitaryInformationFormFields
                    addUpdateLoading={addUpdateLoading || loading}
                    values={employmentValues}
                    form={militaryInfoForm}
                    setVisible={() => setCurrentStep(currentStep - 1)}
                    isFamilyUser={isFamilyUser}
                  />
                </Form>
              </div>
            }

            <div
              className={classNames({
                hidden:
                  (currentStep !== 4 && militaryEmployee && isFamilyUser) ||
                  (currentStep !== 3 && !militaryEmployee && isFamilyUser) ||
                  (currentStep !== 1 && !militaryEmployee && !isFamilyUser) ||
                  (currentStep !== 2 && militaryEmployee && !isFamilyUser) ||
                  isInfoSection,
              })}
            >
              {' '}
              <AdultSchoolInformation
                addUpdateLoading={addUpdateLoading || loading}
                profileData={profileData}
                setVisible={() => setCurrentStep(currentStep - 1)}
                adult={adult}
                form={schoolInfoForm}
                onAddingNewAdult={(lastActionTpe) => {
                  message.success(`Adult ${item?.id ? 'updated' : 'added'} successfully.`, 5);

                  onCancel(lastActionTpe);
                }}
                isFamilyUser={isFamilyUser}
                addingAdult
                householdId={profileData?.id}
                reload={reload}
                militaryEmployee={militaryEmployee}
              />
            </div>
            {
              <div
                className={classNames({
                  hidden: !(currentStep === 2 && isInfoSection),
                })}
              >
                <AdultFamilyRelationship householdId={profileData?.id} profileData={profileData} personId={personId} />
              </div>
            }
          </div>
        </div>
      )}
      {showChildWizard && (
        <AddFamilyChildMemberModal
          profileData={profileData}
          reload={reload}
          visibleAddChildWizard={visibleAddChildWizard}
          setVisibleAddChildWizard={setVisibleAddChildWizard}
        />
      )}
      {
        <FamilyRelationshipModal
          visible={visibleAddRelationshipWizard}
          setVisible={setVisibleAddRelationshipWizard}
          profileData={profileData}
          childId={adult?.id}
        />
      }
    </Spin>
  );
};

export default AddAdultFamilyMemberWizard;
