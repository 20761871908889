import React from 'react';
import classNames from 'classnames';

export default function Page({ children, outerTop, className }) {
  return (
    <>
      {outerTop}
      <div className={classNames('px-5 md:px-7 py-6', className)}>{children}</div>
    </>
  );
}
