export const ENV = 'staging';
export const API_BASE_URL = 'https://api.staging.greatchildcare.org';

export const AMPLIFY_CONFIG = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:5d85ce8b-8901-4ea7-8490-cf30836d8e45',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_8XK6pR7LP',
  aws_user_pools_web_client_id: '7vsl3lslacr62dhed7dg6akbtj',
  oauth: {},
  federationTarget: 'COGNITO_IDENTITY_POOLS',
  aws_user_files_s3_bucket: 'staging-gcc-uploads-bucket',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_content_delivery_bucket_region: 'us-east-1',
};
