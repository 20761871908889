import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Button, Input, Spin, InputNumber } from 'antd';
import { useAsyncCallback, useAsync } from 'react-async-hook';
import { FaPlus } from 'react-icons/fa';

import Form from 'components/Form';
import Modal from 'components/Modal';
import { AsyncSelect } from 'components/Select';
import useEntityGroup from 'hooks/useEntityGroup';
import familyService from 'services/family.service';
import useStartEndDates from 'hooks/useStartEndDates';
import { formatDate } from 'utils';
import AddressFields from 'components/AddressFields';
import SwitchField from 'components/Switch/SwitchField';

const StartEndDates = ({ form, values }) => {
  const [startDate, endDate] = useStartEndDates({
    form,
    values,
    startDateFieldProps: {
      rules: [{ required: false }],
    },
    endDateFieldProps: {
      rules: [{ required: false }],
    },
  });
  return (
    <>
      <div>{startDate}</div>
      <div>{endDate}</div>
    </>
  );
};

export default function AdultEducationContainer(props, person) {
  const [parentForm] = Form.useForm();
  const [addInstituteModal, setAddInstituteModal] = useState(false);
  const {
    loading: loadingEducation,
    execute: getEducation,
    result: education = [],
  } = useAsyncCallback(async () => {
    try {
      return await familyService.getSchoolInfo(props.adultId);
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, []);

  const { loading: addUpdateEducationLoading, execute: addUpdateEducation } = useAsyncCallback(async (values) => {
    try {
      // return await familyService.addUpdateSchool(props.adultId);
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, []);

  const { loading: addingEducation, execute: addEducation } = useAsyncCallback(async (values) => {
    try {
      await familyService.addUpdateSchool(props.adultId, [{ ...values }]);
      getEducation();
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, []);

  useEffect(() => {
    getEducation();
  }, [getEducation]);

  useEffect(() => {
    parentForm.setFieldsValue({ ...education });
  }, [parentForm, education]);

  return (
    <Spin spinning={loadingEducation || addUpdateEducationLoading}>
      <Form.Item data-testid="exempt-switch" name="enrolledInSchool" valuePropName="checked">
        <SwitchField>Enrolled in School</SwitchField>
      </Form.Item>
      {!person?.enrolledInSchool && (
        <>
          <Button className="absolute right-0 mb-6 top-2" onClick={() => setAddInstituteModal(true)} icon={<FaPlus />}>
            Add Institute
          </Button>
          <Form form={parentForm} onFinish={addUpdateEducation} className="mt-6">
            {education?.map((item, index) => (
              <>
                <Form.Item name={[index, 'name']} label="Institution Name">
                  <Input placeholder="Name" />
                </Form.Item>
                <AddressFields addressKey={[index, 'address']} id={[index, 'address']} />
                <Form.Item name={[index, 'terms']}>
                  <AdultSchoolInformationContainer education={item} {...props} />
                </Form.Item>
              </>
            ))}
            <div className="actions flex mt-5">
              <Button data-testid="cancel-school-information-btn">Back</Button>
              {/* <Button
            // onClick={() => onMovingNextWizard('child')}
            disabled={addUpdateEducationLoading}
            loading={addUpdateEducationLoading}
          >
            Save and Add New Adult
          </Button> */}
              <Button
                type="primary"
                htmlType="submit"
                data-testid="save-school-info-btn"
                disabled={addUpdateEducationLoading}
                loading={addUpdateEducationLoading}
              >
                Save and Add New Child
              </Button>
            </div>
          </Form>
          <Modal visible={addInstituteModal} setVisible={setAddInstituteModal} width={800}>
            <Spin spinning={addingEducation}>
              <span className="section-title">Add Institute</span>
              <Form layout="vertical" onFinish={addEducation}>
                <Form.Item name={['name']} label="Institution Name">
                  <Input placeholder="Name" />
                </Form.Item>
                <AddressFields addressKey="address" id="address" />
                <div className="actions flex mt-5">
                  <Button onClick={() => setAddInstituteModal(false)} data-testid="cancel-school-information-btn">
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    data-testid="save-reservation-btn"
                    disabled={addingEducation}
                    loading={addingEducation}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Spin>
          </Modal>
        </>
      )}
    </Spin>
  );
}

function AdultSchoolInformationContainer(props) {
  return <AdultSchoolInformation {...props} />;
}

export function AdultSchoolInformation({ value: items, onChange, application, adultId, education, className }) {
  const onAddItem = useCallback(
    async (data) => {
      const updatedData = { ...education, terms: [{ ...data }] };
      await familyService.addUpdateSchoolInfo(adultId, updatedData);
      return data;
    },
    [adultId, education],
  );
  const onDeleteItem = useCallback(
    async (item) => familyService.deleteSchoolInfo(application?.id, item.id),
    [application?.id],
  );
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">School Type</span>,
        headerText: 'School Type',
        dataIndex: 'grade',
        key: 'grade',
        ellipsis: true,
        width: 150,
        render: (type) => type?.title || 'N/A',
      },
      {
        title: <span className="action-header">Credit Hours</span>,
        headerText: 'Credit Hours',
        dataIndex: 'creditHours',
        key: 'creditHours',
        ellipsis: true,
        width: 150,
        render: (creditHours) => creditHours || 'N/A',
      },
      {
        title: <span className="action-header">Start Date</span>,
        headerText: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        ellipsis: true,
        width: 150,
        render: (endDate) => formatDate(endDate) || 'N/A',
      },
      {
        title: <span className="action-header">End Date</span>,
        headerText: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        ellipsis: true,
        width: 150,
        render: (endDate) => formatDate(endDate) || 'N/A',
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback((props) => {
    return <ChildSchoolInformationFormFields {...props} />;
  }, []);

  const { section } = useEntityGroup({
    items,
    onChange,
    onAddItem,
    onDeleteItem,
    entity: application,
    getAllColumns,
    getFormFields,
    entityTitle: 'School information',
    tableProps: {
      scroll: { x: 1200 },
    },
    hideDeleteButton: true,
    entityName: 'adultSchoolInformation',
    dateFields: [['startDate'], ['endDate']],
  });
  return (
    <div className={classNames(className)}>
      <div>{section}</div>
    </div>
  );
}

export const ChildSchoolInformationFormFields = ({
  setVisible,
  items,
  form,
  item,
  index,
  addUpdateLoading,
  childId,
  isFamilyUser,
}) => {
  const { loading: loadingEducation, result = [] } = useAsync(
    async () => await familyService.getSchoolInfo(childId),
    [childId],
  );

  const educationInfo = useMemo(() => ({ ...result?.[0] }), [result]);
  const terms = useMemo(() => educationInfo?.terms?.[0], [educationInfo]);
  const type = useMemo(() => terms?.type, [terms?.type]);
  const grade = useMemo(() => terms?.grade, [terms?.grade]);

  useEffect(() => {
    form.setFieldsValue({
      educationId: educationInfo.id,
      ...educationInfo,
      ...terms,
    });
  }, [form, educationInfo, terms]);

  useEffect(() => {
    form.setFieldsValue({
      type,
      grade,
    });
  }, [form, grade, type]);

  return (
    <Spin spinning={loadingEducation}>
      <div className="mb-6">
        <span className="section-title">Step 3: Enter Child's Schooling Information</span>
      </div>
      <span className="instructions">Select Next if your child is not enrolled in Prek or above.</span>
      <div className="grid grid-cols-2 gap-x-4 mt-6">
        <Form.Item name="educationId" hidden>
          <InputNumber />
        </Form.Item>
        <div className="col-span-2">
          <Form.Item name="name">
            <Input placeholder="Institution Name" />
          </Form.Item>
        </div>
        <Form.Item name="type" label="Type of School">
          <AsyncSelect
            optionsApiUrl="/education-types"
            placeholder="School Type"
            ariaLabel="School Type"
            name="type"
            apiPrefix="families"
          />
        </Form.Item>
        <div>
          <Form.Item name="grade" label="Grade">
            <AsyncSelect
              optionsApiUrl="/options/5"
              placeholder="Grade"
              ariaLabel="Grade"
              name="grade"
              apiPrefix="families"
            />
          </Form.Item>
        </div>
        <StartEndDates form={form} />
      </div>
      <AddressFields addressKey="address" id="address" required={false} />
      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-school-information-btn">
          Back
        </Button>
        <Button
          type="primary"
          onClick={() => form.submit()}
          data-testid="save-reservation-btn"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
        >
          {isFamilyUser ? 'Next' : 'Save'}
        </Button>
      </div>
    </Spin>
  );
};
