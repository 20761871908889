import React, { useCallback, useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { useLocation, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import FamilyReviewContainer from '../FamilyReviewContainer';
import ChildScheduleHeader from './ChildScheduleHeader';
import ChildScheduleContent from './ChildScheduleContent';
import ChildInstallationHeader from './ChildInstallationHeader';
import ChildInstallationContent from './ChildInstallationContent';
import ChildInfoHeader from './ChildInfoHeader';
import ChildFamilyMembers from 'containers/Family/Profile/ChildFamilyMembers';
import { familyService } from 'services';

const CHILD_STEPS_ORDER = ['info', 'installation', 'schedule'];

export default function ChildDetails({ application, loading, rootRef }) {
  const { householdId, childId, id } = useParams();
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const child = useMemo(() => {
    return (application.children || []).find((child) => child.id === Number(childId)) || {};
  }, [application.children, childId]);

  const index = useMemo(
    () => (application?.children || []).findIndex((child) => child.id === Number(childId)),
    [application?.children, childId],
  );

  const {
    result: installationInfo,
    loading: installationLoading,
    merge: setInstallationInfo,
  } = useAsync(
    async (appId, childId) => {
      if (!appId || !childId) return null;
      return await familyService.getPersonInstallation(appId, childId);
    },
    [id, child?.id],
  );

  let current = useMemo(() => CHILD_STEPS_ORDER.findIndex((key) => pathname.includes(key)) || 0, [pathname]);
  if (pathname.endsWith('/review')) {
    current = 0;
  }
  current = current === -1 ? 0 : current;
  const onChange = useCallback(
    (index) => {
      history.push(`${url}/${CHILD_STEPS_ORDER[index]}`);
    },
    [history, url],
  );

  const childIndex = useMemo(
    () => application?.children?.findIndex((item) => item.id === Number(childId)),
    [application?.children, childId],
  );
  const commonProps = useMemo(
    () => ({
      profileData: { adults: application?.adults, children: application?.children, id: householdId },
      application,
      loading: false,
      reload: () => {},
      getItem: () => ({ ...application?.children?.[childIndex], index: childIndex }),
      item: application?.children?.[childIndex],
      personId: application?.applicant?.id,
    }),
    [application, childIndex, householdId],
  );
  const headerExtraElement = useMemo(
    () => <ChildFamilyMembers {...commonProps} hideTable isWrapperWithSection={false} currentStep={current} />,
    [commonProps, current],
  );

  const props = useMemo(
    () => ({
      id,
      householdId,
      childId,
      application,
      child,
      loading,
      headerExtraElement,
    }),
    [id, householdId, childId, application, child, loading, headerExtraElement],
  );
  const { routePrefix, pathPrefix } = useMemo(() => {
    return {
      routePrefix: `${path}/${CHILD_STEPS_ORDER[current]}`,
      pathPrefix: `${url}/${CHILD_STEPS_ORDER[current]}`,
    };
  }, [current, path, url]);

  const statusPathName = useMemo(() => pathname.replace(/\/documents(.*)$/, ''), [pathname]);

  const infoPrev = useMemo(() => {
    if (index > 0) {
      return `/families/${householdId}/applications/${application.id}/review/children/${
        application.children.at(index - 1).id
      }/schedule`;
    } else if (application.additionalParents?.length > 0) {
      return `/families/${householdId}/applications/${application.id}/review/secondParent/${
        application.additionalParents.at(-1).id
      }/income`;
    }
    return `/families/${householdId}/applications/${application.id}/review/sponsor/income`;
  }, [application.additionalParents, application.children, application.id, householdId, index]);

  const stepItems = useMemo(
    () => [
      {
        title: 'Basic Info',
        status:
          statusPathName.endsWith('/review') ||
          statusPathName.endsWith(`/children/${childId}`) ||
          statusPathName.endsWith(`/children/${childId}/info`)
            ? 'process'
            : 'wait',
        header: <ChildInfoHeader personLabel="Child Name" {...props} />,
        prev: infoPrev,
        next: `${url}/installation`,
      },
      {
        title: 'Installation',
        status: statusPathName.endsWith('installation') ? 'process' : 'wait',
        header: (
          <ChildInstallationHeader
            {...props}
            installation={installationInfo?.installation}
            installationInfo={installationInfo}
            loading={loading}
          />
        ),
        content: (
          <ChildInstallationContent
            {...props}
            installation={installationInfo?.installation}
            installationInfo={installationInfo}
            setInstallationInfo={setInstallationInfo}
            loading={loading || installationLoading}
            prev={`${url}/info`}
            next={`${url}/schedule`}
          />
        ),
      },
      {
        title: 'Schedules',
        status: statusPathName.endsWith(`/children/${childId}/schedule`) ? 'process' : 'wait',
        header: <ChildScheduleHeader {...props} />,
        content: <ChildScheduleContent {...props} />,
        prev: `${url}/installation`,
        next: `/families/${householdId}/applications/${application.id}/review/eligibility`,
      },
    ],
    [
      statusPathName,
      childId,
      props,
      infoPrev,
      url,
      installationInfo,
      loading,
      installationLoading,
      setInstallationInfo,
      householdId,
      application.id,
    ],
  );

  return (
    <div>
      <div>
        <FamilyReviewContainer
          stepItems={stepItems}
          current={current}
          onChange={onChange}
          rootRef={rootRef}
          routePrefix={routePrefix}
          pathPrefix={pathPrefix}
        />
      </div>
    </div>
  );
}
