import { message } from 'antd';
import { LOAD } from 'redux-storage';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { call, put, takeEvery, all } from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import { RESET_ALL } from 'constants/index';
import providerService from 'services/providers.service';
import { createAsyncAction } from 'utils';

const initialState = {
  center: {},
  loading: false,
  error: false,
  isFormSubmitting: false,
  documents: [],
  documentTypes: [],
  snapshot: null,
  isEditable: true,
};

const selectCenterState = createSelector(
  (state) => state.center,
  (centerState) => centerState?.center || {},
);
const selectInternalStatus = createSelector(
  (state) => state?.center,
  (centerState) => centerState.center?.application?.status?.status,
);
const selectCenterSnapshot = createSelector(
  (state) => state.center,
  (centerState) => centerState?.snapshot,
);
const selectLoading = createSelector(
  (state) => state.center,
  (centerState) => centerState?.loading || false,
);
const selectStaffPerson = (personId) =>
  createSelector(
    (state) => state.center?.center,
    (centerState) => {
      const staff = centerState?.staff || [];
      return staff.find((p) => p.id === personId);
    },
  );

const selectIsFormSubmitting = createSelector(
  (state) => state.center,
  (centerState) => centerState?.isFormSubmitting || false,
);

export const getCenter = createAsyncAction('center/getCenter');

export const centerStateSlice = createSlice({
  name: 'center',
  initialState,
  reducers: {
    setCenterInfo: (state, { payload }) => {
      if (isEmpty(payload)) {
        state.center = {};
      } else {
        state.center = { ...state.center, ...payload };
      }
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setCenterSnapshot: (state, { payload }) => {
      state.snapshot = payload;
    },
    setIsFormSubmitting: (state, { payload }) => {
      state.isFormSubmitting = payload;
    },
    setIsEditable: (state, { payload }) => {
      state.isEditable = payload;
    },
  },
  extraReducers: {
    [RESET_ALL]: () => {
      return initialState;
    },
    [LOAD]: () => {
      return initialState;
    },
    [getCenter]: (state) => {
      state.loading = true;
    },
    [getCenter.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.center = payload;
    },
    [getCenter.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
const reducer = centerStateSlice.reducer;
const actions = {
  ...centerStateSlice.actions,
  getCenter,
};
const selectors = {
  selectCenterState,
  selectInternalStatus,
  selectLoading,
  selectIsFormSubmitting,
  selectCenterSnapshot,
  selectStaffPerson,
};

export function* getCenterSaga({ payload }) {
  try {
    const data = yield call(providerService.getCenterById, payload);
    yield put(getCenter.fulfilled(data));
  } catch (error) {
    newrelic.noticeError(error);
    message.error('Unable to get provider information', 5);
    yield put(getCenter.rejected(error));
  }
}

function* sagas() {
  yield all([takeEvery(getCenter, getCenterSaga)]);
}

export { reducer, actions, selectors, initialState, sagas };
export default centerStateSlice;
