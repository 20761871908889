import axios from 'axios';
import config from 'config';
import { setupCache } from './interceptors';

const instance = axios.create({
  baseURL: `${config.API_BASE_URL}/communications`,
});
const request = setupCache(instance);

const api = {
  getEmails: async (params) => {
    const { data } = await request.get(`/`, { params });
    return data;
  },
  markEmailRead: async (id, read) => {
    const { data } = await request.put(`/${id}/${read ? 'read' : 'unread'}`);
    return data;
  },
  getFamilyChecklistTemplate: async (householdId, appId) => {
    const { data } = await request.get(`/checklist/family/application/template`, { params: { appId, householdId } });
    return data;
  },
  sendFamilyChecklistEmail: async (householdId, appId, payload) => {
    const { data } = await request.post(`/checklist/family/application/send`, payload, {
      params: { appId, householdId },
    });
    return data;
  },
  scheduleFamilyChecklistEmail: async (householdId, appId, body) => {
    const { data } = await request.post(`/checklist/family/application/schedule`, body, {
      params: { appId, householdId },
    });
    return data;
  },
  updateScheduleEmail: async (id, body) => {
    const { data } = await request.put(`/email/update/${id}`, body);
    return data;
  },
  cancelScheduleEmail: async (id) => {
    const { data } = await request.delete(`/scheduled/${id}`);
    return data;
  },
  getProviderChecklistTemplate: async (careFacilityId) => {
    const { data } = await request.get(`/checklist/provider/application/template/${careFacilityId}`);
    return data;
  },
  sendProviderChecklistEmail: async (careFacilityId, payload) => {
    const { data } = await request.post(`/checklist/provider/application/send/${careFacilityId}`, payload);
    return data;
  },
  scheduleProviderChecklistEmail: async (careFacilityId, body) => {
    const { data } = await request.post(`/checklist/provider/application/schedule/${careFacilityId}`, body);
    return data;
  },
  getBgcChecklistTemplate: async (careFacilityId) => {
    const { data } = await request.get(`/checklist/provider/background/template/${careFacilityId}`);
    return data;
  },
  sendBgcChecklistEmail: async (careFacilityId, payload) => {
    const { data } = await request.post(`/checklist/provider/background/send/${careFacilityId}`, payload);
    return data;
  },
  scheduleBgcChecklistEmail: async (careFacilityId, body) => {
    const { data } = await request.post(`/checklist/provider/background/schedule/${careFacilityId}`, body);
    return data;
  },
  sendEmail: async (entityType, entityId, body) => {
    const { data } = await request.post(`/email/send?entityType=${entityType}&entityId=${entityId}`, body);
    return data;
  },
  scheduleEmail: async (entityType, entityId, body) => {
    const { data } = await request.post(`/email/schedule?entityType=${entityType}&entityId=${entityId}`, body);
    return data;
  },
  getCommunicationDetails: async (id) => {
    const { data } = await request.get(`/byId/${id}`);
    return data;
  },
  updateScheduledEmail: async (id, body) => {
    const { data } = await request.put(`/email/update/${id}`, body);
    return data;
  },
  createAlert: async (body) => {
    const { data } = await request.post(`/alerts`, body);
    return data;
  },
  updateAlert: async (id, body) => {
    const { data } = await request.put(`/alerts/${id}`, body);
    return data;
  },
  getAlertDetails: async (id) => {
    const { data } = await request.get(`/alerts/${id}`);
    return data;
  },
  getWatchers: async (alertId) => {
    const { data } = await request.put(`/alerts/${alertId}/watchers`);
    return data;
  },
  updateWatchers: async (alertId, userIds) => {
    const { data } = await request.put(`/alerts/${alertId}/watchers?userIds=${userIds}`);
    return data;
  },
  getAlertWatchers: async (id) => {
    const { data } = await request.get(`/alerts/${id}/watchers`);
    return data;
  },
  removeWatchers: async (id, userIds) => {
    const { data } = await request.delete(`/alerts/${id}/watchers?userIds=${userIds}`);
    return data;
  },
  createAlertNote: async (alertId, body) => {
    const { data } = await request.post(`/alerts/${alertId}/notes`, body);
    return data;
  },
  updateAlertNote: async (alertId, noteId, body) => {
    const { data } = await request.put(`/alerts/${alertId}/notes/${noteId}`, body);
    return data;
  },
  deleteAlertNote: async (alertId, noteId) => {
    const { data } = await request.delete(`/alerts/${alertId}/notes/${noteId}`);
    return data;
  },
  resolveAlert: async (id, resolved) => {
    const { data } = await request.put(`/alerts/${id}/${resolved ? 'unresolve' : 'resolve'}`);
    return data;
  },
  sendHelpEmail: async (payload) => {
    const { data } = await request.post('/help', payload);
    return data;
  },
};
export { request };
export default api;
