import { useEffect } from 'react';

function useOnClickOutside(ref, handler, ignore = [], events = ['mousedown', 'touchstart']) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (
          !ref.current ||
          ref.current.contains(event.target) ||
          ignore.some((r) => r.current && r.current.contains(event.target))
        ) {
          return;
        }
        handler(event);
      };

      events.forEach((eventName) => document.addEventListener(eventName, listener));

      return () => {
        events.forEach((eventName) => document.removeEventListener(eventName, listener));
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, ignore, events],
  );
}

export default useOnClickOutside;
