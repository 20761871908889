import React, { useEffect, useMemo } from 'react';
import { message, Spin, Button } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Form from 'components/Form';
import Section from 'components/Section';
import AdultFamilyMembers from './AdultFamilyMembers';
import ChildFamilyMembers from './ChildFamilyMembers';
import familyService from 'services/family.service';
import { actions, selectors } from 'features/family';
import AddFamilyRelationshipWizard from './AddFamilyRelationshipWizard';

export default function Profile() {
  const dispatch = useDispatch();
  const history = useHistory();
  const application = useSelector(selectors.selectApplication);

  const {
    loading,
    execute: loadProfile,
    result: profileData,
  } = useAsyncCallback(async () => {
    try {
      const houseHold = await familyService.getHouseHold();
      const children = await familyService.getChildren(houseHold.id);
      const adults = await familyService.getAdults(houseHold.id);
      dispatch(actions.setHousehold({ ...houseHold, children, adults }));
      return { ...houseHold, adults, children };
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to load profile data.', 5);
      return { adults: [], children: [] };
    }
  }, []);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const commonProps = useMemo(
    () => ({
      profileData,
      reload: loadProfile,
      loading,
      application,
    }),
    [loadProfile, loading, profileData, application],
  );

  return (
    <Form layout="vertical">
      <Section collapsible={false} heading="Family Profile">
        <Spin spinning={loading}>
          <div className="bg-white mb-6">
            <AdultFamilyMembers {...commonProps} />
            <ChildFamilyMembers {...commonProps} />
            <div className="px-6 py-4">
              <AddFamilyRelationshipWizard {...commonProps} />
            </div>
            <div className="actions flex py-10 mt-6 border-t-2 px-6">
              <Button onClick={() => history.push('/family')}>Return to Landing Page</Button>
              <Button type="primary" onClick={() => history.push('/family/applications')}>
                Go to My Applications
              </Button>
            </div>
          </div>
        </Spin>
      </Section>
    </Form>
  );
}
