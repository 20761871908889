import classNames from 'classnames';
import React from 'react';
import Switch from './Switch';

export default function SwitchField({
  id,
  checked,
  onChange,
  switchProps,
  children,
  collapsed,
  textClassName,
  className,
  readOnly = false,
}) {
  return (
    <div
      className={classNames(
        `switch-field-container transition-all min-h-[40px] flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-10 md:items-center`,
        className,
      )}
    >
      <Switch {...switchProps} checked={checked} onChange={onChange} id={id} readOnly={readOnly} />
      <p className={classNames('text-black text-15 mb-0', textClassName)}>{children}</p>
    </div>
  );
}
