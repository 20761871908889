import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function ColumnTitle({ title, className }) {
  return (
    <div className={classNames('col-header flex items-start white', className)} id={`${title}-header`}>
      <span>{title}</span>
    </div>
  );
}

ColumnTitle.propTypes = {
  dataIndex: PropTypes.any,
  colKey: PropTypes.string,
  sortColumn: PropTypes.shape({
    dataIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  sortOrder: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.any,
  showSorting: PropTypes.bool,
};
