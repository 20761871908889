import React, { useState, useMemo } from 'react';
import produce from 'immer';
import classNames from 'classnames';
import { Tree, Input } from 'antd';
import PdfViewer from 'components/PdfViewer';

import styles from './HelpTree.module.less';
import Html from 'Html';
import toLower from 'lodash/toLower';

export default function HelpTree({ className }) {
  const [node, setNode] = useState(defaultData[0]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const onExpand = (newExpandedKeys, { expanded, node }) => {
    setSearchValue('');
    setExpandedKeys(expanded ? newExpandedKeys : newExpandedKeys.filter((key) => !key.startsWith(node.key)));
  };

  const onChange = (e) => {
    const { value } = e.target;
    const newExpandedKeys = dataList
      .map((item) => {
        if (toLower(item.title).indexOf(toLower(value)) > -1) {
          return getParentKey(item.key, defaultData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);
    setExpandedKeys(value ? newExpandedKeys : []);
    setSearchValue(value);
    // setAutoExpandParent(true);
  };
  const treeData = useMemo(() => {
    const loop = (data) =>
      data.map((item) => {
        const strTitle = item.title;
        const index = toLower(strTitle).indexOf(toLower(searchValue));
        const beforeStr = strTitle.substring(0, index);
        const afterStr = strTitle.slice(index + searchValue.length);
        const midStr = strTitle.slice(index, index + searchValue.length);
        const title =
          index > -1 ? (
            <span
              onClick={() => {
                setNode(item);
              }}
            >
              {beforeStr}
              <span className="text-yellow-600 font-semibold">{midStr}</span>
              {afterStr}
            </span>
          ) : (
            <span
              onClick={() => {
                setNode(item);
              }}
            >
              {strTitle}
            </span>
          );
        if (item.children) {
          return {
            title,
            // title: item.title,
            key: item.key,
            children: loop(item.children),
          };
        }
        return {
          title,
          // title: item.title,
          key: item.key,
        };
      });
    return loop(defaultData);
  }, [searchValue]);
  return (
    <div className={classNames(styles.helpTree, className, 'px-4 md:px-6 pt-7 pb-4')}>
      <div>
        <Input
          data-testid="search-input"
          placeholder="input search text"
          onChange={onChange}
          value={searchValue}
          className="w-full"
        />
      </div>
      <div className="flex white-box min-h-[400px] h-[calc(100vh-100px)]">
        <div className="sidebar w-[300px] border-r border-gray-300 px-4 py-6 overflow-auto">
          <Tree
            showLine
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent
            treeData={treeData}
            // filterTreeNode={(node) => {
            //   return toLower(node.title).indexOf(toLower(searchValue)) > -1;
            // }}
          />
        </div>
        <div className="content flex-1 p-6">
          {Array.from(node?.data || []).map((item) => {
            /* istanbul ignore else */
            if (item.type === 'jsx') {
              return <div>{item.jsx}</div>;
            }
            /* istanbul ignore else */
            if (item.type === 'content') {
              return <Html html={item.content} />;
            }
            /* istanbul ignore else */
            if (item.type === 'pdf') {
              return <PdfViewer key={item.name} url={item?.url} id="help-document" className="mb-6" />;
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

const SampleContent = ({ title }) => (
  <article className="prose">
    <h3 className="text-lg font-semibold mb-4">{title}</h3>
    <p className="text-14">
      Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor
      quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean
      ultricies mi vitae est. Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra.
      Vestibulum erat wisi, condimentum sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt
      condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. Donec non enim in turpis pulvinar
      facilisis. Ut felis. Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna
      eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus
    </p>
    <ul className="list-disc mt-10 pl-6 mb-4">
      <li>
        Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed
        arcu. Cras consequat.
      </li>
      <li>
        Praesent dapibus, neque id cursus faucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam
        erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus.
      </li>
      <li>
        Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus.
        Nam nulla quam, gravida non, commodo a, sodales sit amet, nisi.
      </li>
      <li>
        Pellentesque fermentum dolor. Aliquam quam lectus, facilisis auctor, ultrices ut, elementum vulputate, nunc.
      </li>
    </ul>
  </article>
);
const helpData = [
  {
    title: 'Provider Resources',
    children: [
      {
        title: 'MCCYN Application',
        children: [
          {
            title: 'CCAoA Website Link',
            data: [
              {
                type: 'content',
                content:
                  '<div><a target="_blank" rel="noopener noreferrer" href="https://www.childcareaware.org/">CCAoA Website Link</a></div>',
              },
            ],
          },
          {
            title: 'State by State Resources - Child Care Aware® of America',
          },
          {
            title: 'Required Documents',
            children: [
              {
                title: 'State License',
                data: [{ type: 'pdf', name: 'state-license', url: '/agreements/NTC-HCC Eligibility questions.pdf' }],
              },
              {
                title: 'State Licensing Inspection Report',
                data: [
                  {
                    type: 'pdf',
                    name: 'state-license-inspection',
                    url: '/agreements/Provider Fee Assistance ULA 2017.pdf',
                  },
                ],
              },
              {
                title: 'W9 Form',
              },
              {
                title: 'EIN',
              },
              {
                title: 'Discount Verification Form',
              },
              {
                title: 'User Agreement Form',
              },
              {
                title: 'Rates',
              },
            ],
          },
          {
            title: 'Optional Documents',
            children: [
              {
                title: 'National Accreditation Certificate',
              },
              {
                title: 'QRIS Rating CO, MD, NV, TX, VA, WA, FL: Miami-Dade County',
              },
              {
                title: 'Direct Deposit Form',
              },
              {
                title: 'Background Check Staff Registry (ME, NB, NY, OK)',
              },
              {
                title: 'FBI Fingerprint Check (ME, NB, NY)',
              },
              {
                title: 'Child Abuse & Neglect Registry Check (OK)',
              },
            ],
          },
        ],
      },
      {
        title: 'Army Respite Care Application',
        key: '0-0-1',

        children: [
          {
            title: 'CCAoA Website Link',
            key: '0-0-1-0',
          },
          {
            title: 'State by State Resources - Child Care Aware® of America',
            key: '0-0-1-1',
          },
          {
            title: 'Required Documents',
            key: '0-0-1-2',
            children: [
              {
                title: 'State License',
                key: '0-0-1-2-0',
              },
              {
                title: 'State Licensing Inspection Report',
                key: '0-0-1-2-1',
              },
              {
                title: 'W9 Form',
                key: '0-0-1-2-2',
              },
              {
                title: 'EIN',
                key: '0-0-1-2-3',
              },
              {
                title: 'Discount Verification Form',
                key: '0-0-1-2-4',
              },
              {
                title: 'User Agreement Form',
                key: '0-0-1-2-5',
              },
              {
                title: 'Hourly Rates',
                key: '0-0-1-2-6',
              },
            ],
          },
        ],
      },
      {
        title: 'EFM Respite Care Application',
        key: '0-0-2',
      },
      {
        title: 'CCYH Application',
        key: '0-0-3',
      },
      {
        title: 'NTC Application',
        key: '0-0-4',
      },
    ],
  },
  {
    title: 'Family Resources',
    children: [
      {
        title: 'MCCYN Application',
      },
      {
        title: 'Army Respite Care Application',
      },
      {
        title: 'EFM Respite Care Application',
      },
      {
        title: 'WDCC Application',
      },
      {
        title: 'TPU Application',
      },
    ],
  },
  {
    title: 'Payment Process',
    children: [
      {
        title: 'MCCYN Payment Policies',
        children: [
          {
            title: 'Army',
            children: [
              {
                title: 'Payment Policy Document',
              },
              {
                title: 'Payment Calendar',
              },
            ],
          },
          {
            title: 'Air Force/Space Force',
          },
          {
            title: 'Marine Corps',
          },
          {
            title: 'NGA',
          },
        ],
      },
    ],
  },
  {
    title: 'Trainings and Education',
    children: [
      {
        title: 'Child Abuse Training Guide',
      },
      {
        title: 'Preventing Child Abuse',
      },
      {
        title: 'Identifying and Reporting Child Abuse',
      },
      {
        title: 'Opening the Door to Quality Center',
      },
      {
        title: 'Opening the Door to Quality FCC',
      },
    ],
  },
  {
    title: 'Child Care Resource and Referral (CCR&R)',
    children: [
      {
        title: 'Link to CCR&R Search - Child Care Aware® of America ',
      },
    ],
  },
  {
    title: "How To's",
    children: [
      {
        title: 'Navigate GCC Web App',
        children: [
          {
            title: 'Provider Application',
            children: [
              {
                title: 'Video Link',
              },
            ],
          },
          {
            title: 'Family Application',
          },
        ],
      },
    ],
  },
  {
    title: 'Contacts',
    children: [
      {
        title: 'List of Department Contacts within CCAoA',
      },
    ],
  },
];
const defaultData = produce(helpData, (draft) => {
  addKeys(draft, '0');
});
const dataList = [];
generateList(defaultData);

function generateList(data) {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { key } = node;
    dataList.push({
      key,
      title: node.title,
    });
    if (node.children) {
      generateList(node.children);
    }
  }
}

function getParentKey(key, tree) {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
}

function addKeys(nodes, key) {
  nodes.forEach((node, index) => {
    const currentKey = `${key}-${index}`;
    node.key = currentKey;
    if (node.data) {
      node.data = [{ type: 'jsx', jsx: <SampleContent title={node.title} /> }, ...node.data];
    } else {
      node.data = [{ type: 'jsx', jsx: <SampleContent title={node.title} /> }];
    }
    if (Array.isArray(node.children)) {
      addKeys(node.children, currentKey);
    }
  });
}
