import React from 'react';

// Antd
import { Result } from 'antd';

export default function GeneralErrorState({ error, extra }) {
  let errorMesage;
  if (error instanceof TypeError) {
    errorMesage = error.stack || error.toString();
  }
  if (!errorMesage) {
    errorMesage = error?.response?.data?.detail || error?.message || error?.code;
  }

  // const statusCode = error?.response?.status || error?.response?.data?.status;

  return (
    <>
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please try again later, or contact support."
        // extra={
        //   <div className="flex justify-center gap-2">
        //     <a key="support" href="mailto:">
        //       <Button icon={<MailOutlined />} type="primary">
        //         Contact Support
        //       </Button>
        //     </a>
        //   </div>
        // }
        className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 max-w-[90vw]"
      />

      {/* <Alert
        message={<p className="font-semibold">Technical details:</p>}
        description={
          <p>
            {errorMesage}

            {statusCode !== undefined && (
              <p className={errorMesage ? 'mt-4' : ''}>
                <span className="font-semibold">Status code:</span> {statusCode}
              </p>
            )}

            {extra && <div className="mt-6">{extra}</div>}
          </p>
        }
        type="error"
        className="w-[80%] max-w-[600px] m-auto"
      /> */}
    </>
  );
}
