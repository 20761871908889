import axios from 'axios';
import config from 'config';
import { setupCache } from './interceptors';

const instance = axios.create({ baseURL: `${config.API_BASE_URL}/files` });
const request = setupCache(instance);

const api = {
  getDocuments: async (params) => {
    const { data } = await request.get(`/documents`, { params });
    return data.map((doc) => ({ ...doc, documentType: doc.type }));
  },
  saveDocuments: async (body) => {
    const { data } = await request.post(`/documents`, body);
    return data;
  },
  deleteDocuments: async (ids) => {
    const { data } = await request.delete(`/documents`, { data: ids });
    return data;
  },
  getDocumentSubTypes: async (typeId) => {
    const { data } = await request.get(`/documents/types/${typeId}/subtypes`);
    return data;
  },
  getDocumentsSnapshot: async (params) => {
    const { data } = await request.get(`/documents/snapshot`, { params });
    return data;
  },
  getDocumentReview: async (id) => {
    const { data } = await request.get(`/documents/${id}/review`);
    return data;
  },
  addDocumentReview: async (id, payload) => {
    const { data } = await request.post(`/documents/${id}/review`, payload);
    return data;
  },
  updateDocumentReview: async (documentId, reviewId, payload) => {
    const { data } = await request.put(`/documents/${documentId}/review/${reviewId}`, payload);
    return data;
  },
  // approveReview: async (id, reviewId) => {
  //   const { data } = await request.put(`/documents/${id}/review/${reviewId}/approve`);
  //   return data;
  // },
  markForReview: async (id, params) => {
    const { data } = await request.put(`/documents/${id}/markForReview`, {}, { params });
    return data;
  },
  getDocumentById: async (id) => {
    const { data } = await request.get(`/documents/${id}`);
    return data;
  },
  getDocumentContents: async (id) => {
    const { data } = await request.get(`/documents/${id}/contents`);
    return data;
  },

  getS3File: async (file) => {
    let responseType = 'blob';
    if (file.mimeType === 'image/svg+xml') {
      responseType = 'text';
    }
    // if (file.type === 'application/pdf') {
    //   responseType = 'application/pdf';
    // }
    const response = await request.get(`/documents/${file.id}/contents`, { responseType }); //{ responseType: 'blob' });
    const blob = response.data;
    return blob;
  },
  updateDocuments: async (body) => {
    const { data } = await request.put(`/documents`, body);
    return data;
  },
  updateDocument: async (id, body) => {
    const { data } = await request.put(`/documents/${id}`, body);
    return data;
  },
  deleteDocument: async (id) => {
    const { data } = await request.delete(`/documents/${id}`);
    return data;
  },
  getFileContents: async (id) => {
    const { data } = await request.get(`/files/${id}/contents`);
    return data;
  },
};
export { request };
export default api;
