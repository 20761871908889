import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';

import Form from 'components/Form';
import Section from 'components/Section';
import { ADD_FAMILY_STEPS } from 'constants/index';
import { LayoutContext } from 'utils';

export default function Documents({ id, setStep, onCancel, next, application }) {
  const { collapsed } = useContext(LayoutContext);
  const onFinish = useCallback(() => {
    next({
      paths: {
        step: ADD_FAMILY_STEPS.CHILDREN,
        id,
      },
    });
  }, [id, next]);

  return (
    <Form onFinish={onFinish}>
      <p className={classNames('px-0 pt-2 pb-12', { 'max-w-6xl': collapsed, 'max-w-5xl': !collapsed })}>
        Complete and upload any documents that may have overlapping information for multiple children in this section,
        including the{' '}
        <a
          className="text-primary underline font-medium"
          target="_blank"
          rel="noopener noreferrer"
          href="https://public.militarychildcare.csd.disa.mil/mcc-central/system/files/2022-07/SelfCertificationStatement_CCAOA.pdf"
        >
          Self-Certification Form
        </a>{' '}
        or{' '}
        <a
          className="text-primary underline font-medium"
          target="_blank"
          rel="noopener noreferrer"
          href="https://public.militarychildcare.csd.disa.mil/mcc-central/system/files/2022-07/ProviderCostVerificationForm_CCAOA.pdf"
        >
          Provider Cost Verification Form
        </a>{' '}
        /{' '}
        <a
          className="text-primary underline font-medium"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.childcareaware.org/wp-content/uploads/2023/01/CCYH-Provider-Cost-Verification-Form_fillable-1.pdf"
        >
          CCYH Provider Cost Verification Form
        </a>
        .
      </p>

      <Section
        contentClassName="px-8"
        heading="Child & Youth Services (CYS) Verification"
        className="section-border"
        defaultOpen={false}
      >
        <p className={classNames('-mt-2 mb-6 ', { 'max-w-6xl': collapsed, 'max-w-5xl': !collapsed })}>
          Upload the Installation Child Care Request Form or Statement of Non-Availability signed from the assigned
          designee at Parent Central Services at your closest Branch sponsored garrison to confirm if there are no
          spaces available at this time for your family.
        </p>
      </Section>

      <Section
        contentClassName="px-8"
        heading="Children's Birth Verification"
        className="section-border"
        defaultOpen={false}
      >
        <p className={classNames('-mt-2 mb-6 ', { 'max-w-6xl': collapsed, 'max-w-5xl': !collapsed })}>
          In order to be eligible for fee assistance, the child(ren) must be listed as a legal dependent in the
          Sponsor’s DEERS. You will need to upload either the Birth Certificates or{' '}
          <a
            href="https://public.militarychildcare.csd.disa.mil/mcc-central/system/files/2022-07/SelfCertificationStatement_CCAOA.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Self Certification Form
          </a>{' '}
          for all applicable children. The final version of the sponsor’s DEERS enrollment (a screenshot with the
          child's name selected) is required if the Sponsor is not listed on the Birth Certificate or the child's
          surname is different from the Sponsor's and a Self-Certification Form is submitted. Please note: the DEERS
          Enrollment Application (DD 1172-2) is not an acceptable form of verification.
        </p>
      </Section>

      <Section
        contentClassName="px-8"
        heading="Children's Rate Verification"
        className="section-border"
        defaultOpen={false}
      >
        <p className={classNames('-mt-2 mb-6 ', { 'max-w-6xl': collapsed, 'max-w-5xl': !collapsed })}>
          Please have your provider complete the{' '}
          <a
            href="https://public.militarychildcare.csd.disa.mil/mcc-central/system/files/2022-07/ProviderCostVerificationForm_CCAOA.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Provider Cost Verification Form
          </a>{' '}
          /{' '}
          <a
            href="https://www.childcareaware.org/wp-content/uploads/2023/01/CCYH-Provider-Cost-Verification-Form_fillable-1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            CCYH Provider Cost Verification Form
          </a>{' '}
          to confirm how you are being charged for care, including all discounts offered to your family. Signatures are
          required for both parent and provider before submitting this form.
        </p>
      </Section>

      <Section contentClassName="px-8" heading="Other Child Documents" className="section-border" defaultOpen={false}>
        <p className={classNames('-mt-2 mb-6 ', { 'max-w-6xl': collapsed, 'max-w-5xl': !collapsed })}>
          Upload any remaining documents that apply to the children selected for this application.
        </p>
      </Section>
    </Form>
  );
}
