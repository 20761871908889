import React, { useContext, useEffect, useMemo } from 'react';
import { Input, InputNumber } from 'antd';
import PhoneNumber from 'components/PhoneNumber';
import Select, { AsyncSelect } from 'components/Select';
import { breakpointsContext, getPhoneNumberValidator } from 'utils';
import { PHONE_TYPES } from 'constants/index';
import Form from 'components/Form';
import SwitchField from 'components/Switch/SwitchField';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import Section from 'components/Section';

export default function ContactInfo({
  className,
  prefixName,
  showDualMilitary,
  isWorkEmailRequired = false,
  index = 0,
  sectionProps,
  readOnly = false,
}) {
  const { sm } = useContext(breakpointsContext);
  const form = Form.useFormInstance();
  const idFieldName = useMemo(() => [...prefixName, 'id'], [prefixName]);
  const militaryEmployeeFieldName = useMemo(() => [...prefixName, 'militaryEmployee'], [prefixName]);
  const firstNameFieldName = useMemo(() => [...prefixName, 'firstName'], [prefixName]);
  const lastNameFieldName = useMemo(() => [...prefixName, 'lastName'], [prefixName]);
  const emailFieldName = useMemo(() => [...prefixName, 'email'], [prefixName]);
  const workEmailFieldName = useMemo(() => [...prefixName, 'workEmail'], [prefixName]);
  const middleNameFieldName = useMemo(() => [...prefixName, 'middleName'], [prefixName]);
  const phoneFieldName = useMemo(() => [...prefixName, 'phone'], [prefixName]);
  const phoneTypeFieldName = useMemo(() => [...prefixName, 'phoneType'], [prefixName]);
  const secondaryPhoneFieldName = useMemo(() => [...prefixName, 'secondaryPhone'], [prefixName]);
  const secondaryPhoneTypeFieldName = useMemo(() => [...prefixName, 'secondaryPhoneType'], [prefixName]);
  const isRequired = useMemo(() => !readOnly, [readOnly]);

  const militaryEmployee = Form.useWatch(militaryEmployeeFieldName, form);
  useEffect(() => {
    const emailErrors = form.getFieldError(workEmailFieldName);
    if (emailErrors?.length > 0) {
      form.validateFields([workEmailFieldName]);
    }
  }, [form, isWorkEmailRequired, militaryEmployee, workEmailFieldName]);

  const infoSection = (
    <div className={className}>
      <Form.Item name={idFieldName} hidden>
        <InputNumber />
      </Form.Item>
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8">
        <div>
          <Form.Item
            name={firstNameFieldName}
            label="First Name"
            rules={[{ required: isRequired, message: 'First Name is required' }]}
          >
            <Input placeholder="First Name" readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={middleNameFieldName} label="Middle Name/Initials">
            <Input placeholder="Middle Name" readOnly={readOnly} />
          </Form.Item>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <Form.Item
            name={lastNameFieldName}
            label="Last Name"
            rules={[{ required: isRequired, message: 'Last Name is required' }]}
          >
            <Input placeholder="Last Name" readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={emailFieldName}
            label="Preferred Email Address"
            rules={[
              { required: isRequired, message: 'Preferred Email is required' },
              { type: 'email', message: 'Preferred Email Address in invalid' },
            ]}
          >
            <Input placeholder="Preferred Email" readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            validateFirst
            name={workEmailFieldName}
            label="Secondary/Military Email Address"
            rules={[
              {
                required: isRequired,
                message: 'Secondary/Military email address is required',
              },
              {
                type: 'email',
                message: `Secondary/Military email address is invalid`,
              },
            ]}
          >
            <Input placeholder={'Secondary/Military Email Address'} readOnly={readOnly} />
          </Form.Item>
        </div>
        {showDualMilitary && (
          <div className="pt-6">
            <Form.Item name={militaryEmployeeFieldName} valuePropName="checked">
              <SwitchField switchProps={{ showLabels: false }} readOnly={readOnly}>
                <span className="-ml-16">Dual Military</span>
              </SwitchField>
            </Form.Item>
          </div>
        )}
        <div className="lg:col-start-1">
          <Form.Item
            name={phoneFieldName}
            label="Preferred Phone Number"
            rules={[
              { required: isRequired, message: 'Preferred Phone Number is required' },
              getPhoneNumberValidator('Invalid Preferred Phone Number!'),
            ]}
          >
            <PhoneNumber readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={phoneTypeFieldName} label="Phone Type">
            <Select
              placeholder={sm ? 'Preferred Phone ' : 'Preferred Phone Type'}
              ariaLabel="Preferred Phone Type"
              options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
              isOptionSelected={(opt, [value]) => opt?.title === value}
              onChangeFormatter={(v) => v?.title}
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
        <div className="lg:col-start-1">
          <Form.Item
            name={secondaryPhoneFieldName}
            label="Secondary Phone Number"
            rules={[getPhoneNumberValidator('Invalid Secondary Phone Number!')]}
          >
            <PhoneNumber readOnly={readOnly} />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={secondaryPhoneTypeFieldName} label="Phone Type">
            <Select
              placeholder={sm ? 'Secondary Phone' : 'Secondary Phone Type'}
              ariaLabel="Secondary Phone Type"
              options={PHONE_TYPES.map((opt) => ({ title: opt, id: opt }))}
              isOptionSelected={(opt, [value]) => opt?.title === value}
              onChangeFormatter={(v) => v?.title}
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {sectionProps ? <Section {...sectionProps}>{infoSection}</Section> : infoSection}
      {showDualMilitary && militaryEmployee && (
        <DualMilitarySection prefixName={prefixName} index={index} form={form} readOnly={readOnly} />
      )}
    </>
  );
}

export function DualMilitarySection({
  prefixName,
  collapsible = true,
  heading = 'Dual Military',
  contentClassName = 'section-px -mt-2 pb-2',
  headingClassName = 'page-title',
  showMaritalStatus = true,
  headerContainerClassName,
  readOnly = false,
  isRequired,
}) {
  const form = Form.useFormInstance();
  const programSponsorFieldName = useMemo(() => [...prefixName, 'position', 'programSponsorId'], [prefixName]);
  const militaryComponentIdFieldName = useMemo(() => [...prefixName, 'position', 'militaryComponentId'], [prefixName]);
  const militaryStatusIdFieldName = useMemo(() => [...prefixName, 'position', 'militaryStatusId'], [prefixName]);
  const gradeFieldName = useMemo(() => [...prefixName, 'position', 'grade'], [prefixName]);
  const recruiterTypeFieldName = useMemo(() => [...prefixName, 'position', 'recruiterType'], [prefixName]);
  const maritalStatusFieldName = useMemo(() => [...prefixName, 'maritalStatus'], [prefixName]);

  const programSponsorId = Form.useWatch(programSponsorFieldName, form);
  const militaryComponentId = Form.useWatch(militaryComponentIdFieldName, form);

  return (
    <Section
      headingClassName={headingClassName}
      heading={heading}
      collapsible={collapsible}
      testId="employment-info-self-employment"
      contentClassName={contentClassName}
      headerContainerClassName={headerContainerClassName}
    >
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8">
        <div>
          <Form.Item
            name={programSponsorFieldName}
            label="Branch"
            rules={[{ required: isRequired, message: 'Branch is required' }]}
          >
            <AsyncSelect
              apiPrefix="/common"
              optionsApiUrl="/program-sponsors"
              placeholder="Select"
              ariaLabel="Select Branch"
              onChangeFormatter={(v) => v.id}
              isOptionSelected={(option, [value]) => option.id === value}
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={militaryComponentIdFieldName}
            label="Component"
            rules={[{ required: isRequired, message: 'Component is required' }]}
          >
            <AsyncSelect
              key={programSponsorId}
              getOptions={commonService.get}
              apiPrefix="/common"
              optionsApiUrl={
                programSponsorId
                  ? `/program-types/family/components?branchId=${programSponsorId}`
                  : `/program-types/family/components`
              }
              placeholder="Select"
              ariaLabel="Select Component"
              onChangeFormatter={(v) => v.id}
              isOptionSelected={(option, [value]) => option.id === value}
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={militaryStatusIdFieldName}
            label="Status"
            rules={[{ required: isRequired, message: 'Status is required' }]}
          >
            <AsyncSelect
              key={`${programSponsorId}-${militaryComponentId}`}
              getOptions={commonService.get}
              apiPrefix="/common"
              optionsApiUrl={
                programSponsorId && militaryComponentId
                  ? `/program-types/family/statuses/by-component-branch?programSponsorId=${programSponsorId}&componentId=${militaryComponentId}`
                  : `/program-types/family/statuses`
              }
              placeholder="Select"
              ariaLabel="Select Status"
              onChangeFormatter={(v) => v.id}
              isOptionSelected={(option, [value]) => option.id === value}
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name={gradeFieldName}
            label="Military grade"
            rules={[{ required: isRequired, message: 'Military grade is required' }]}
          >
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/options/3"
              placeholder="Grade"
              ariaLabel="Select Grade"
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item name={recruiterTypeFieldName} label="Recruiter Type (If Applicable)">
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/options/4"
              placeholder="Recruiter Type"
              ariaLabel="Recruiter Type"
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
        {showMaritalStatus && (
          <div>
            <Form.Item
              name={maritalStatusFieldName}
              label="Marital Status"
              rules={[{ required: isRequired, message: 'Marital Status is required' }]}
            >
              <AsyncSelect
                getOptions={familyService.getOptions}
                apiPrefix=""
                optionsApiUrl="/options/6"
                placeholder="Marital Status"
                ariaLabel="Marital Status"
                readOnly={readOnly}
              />
            </Form.Item>
          </div>
        )}
      </div>
    </Section>
  );
}
