import { useSelector } from 'react-redux';

import { selectors } from 'features/auth';

export default function useHasRole(role) {
  const roles = useSelector(selectors.selectRoles);
  return roles.indexOf(role) > -1;
}
export function useHasNoRole(role) {
  const roles = useSelector(selectors.selectRoles);
  return roles.indexOf(role) === -1;
}
