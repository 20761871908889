export default function getTemplate({ fullName }) {
  return `
  <p>
    Dear ${fullName},
  </p>
  <p><br></p>
  <p>Please review the attached letter in regard to your current provider. Please be advised you may continue to use them as your provider in the fee assistance program. If you have any questions about the probation please contact our Provider Services Department at 800-424-2246 option 4. </p>
  <p><br></p>
  <p>If you would like to find a new provider please contact our Parent Services Department at 800-424-2246 option 3.</p>
  <p><br></p>
`;
}
