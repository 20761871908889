import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { Button, message, Spin, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretUp, AiOutlineEdit } from 'react-icons/ai';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import Table from 'components/Table';
import ColumnTitle from 'components/ColumnTitle';
import useTablePagination from 'hooks/useTablePagination';
import { formatDate, getFullName, hasApplicationExpired, sleep } from 'utils';
import { actions } from 'features/family';
import commonService from 'services/common.service';
import config from 'config';
import { Add, Renew } from '@carbon/icons-react';
import Card from 'components/Card/Card';
import Space from 'components/Space/Space';

const PAGE_SIZE = 10;
function getCommonColumn(programs) {
  return [
    {
      title: (props) => <ColumnTitle {...props} title="ID" dataIndex="id" colKey="id" showSorting />,
      headerText: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      width: 150,
    },
    {
      title: (props) => (
        <ColumnTitle
          {...props}
          title={<span>Sponsor Name</span>}
          dataIndex={['applicant', 'firstName']}
          colKey="applicant"
          showSorting
        />
      ),
      headerText: 'Sponsor Name',
      dataIndex: ['applicant', 'firstName'],
      sorter: true,
      key: 'applicant',
      render: (value, { applicant }) => getFullName(applicant),
    },
    {
      title: (props) => (
        <ColumnTitle {...props} title="Submitted Date" dataIndex="submittedDate" colKey="submittedDate" showSorting />
      ),
      headerText: 'Submitted Date',
      dataIndex: 'submittedDate',
      key: 'submittedDate',
      sorter: true,
      render: (submittedDate) => {
        return submittedDate ? <span className="font-medium">{formatDate(submittedDate)}</span> : 'Not Submitted';
      },
      width: 250,
    },
    {
      title: (props) => <ColumnTitle {...props} title="Programs" dataIndex="program" colKey="programs" />,
      headerText: 'Programs',
      dataIndex: 'program',
      key: 'programs',
      render: (programId) => {
        const filteredProgram = programId && programs?.find?.((item) => item.id === programId);
        return (
          <span>
            {filteredProgram ? `${filteredProgram?.type?.title} (${filteredProgram?.sponsor?.title})` : 'N/A'}
          </span>
        );
      },
      width: 250,
    },
    {
      title: (props) => (
        <ColumnTitle
          {...props}
          colKey="status"
          title="Status"
          dataIndex={['status', 'status', 'publicStatus', 'title']}
          showSorting
        />
      ),
      width: 150,
      headerText: 'Status',
      dataIndex: ['status', 'status', 'publicStatus', 'title'],
      key: 'status',
      render: (status) => status || 'N/A',
      sorter: true,
    },
  ];
}

export default function FamilyApplications({ householdId, className }) {
  return householdId ? (
    <Applications householdId={householdId} className={className} />
  ) : (
    <Spin className="h-screen" spinning size="large">
      <div className="h-screen flex justify-center items-center">Loading Household Information...</div>
    </Spin>
  );
}
function Applications({ className, householdId }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);

  const { result: programs } = useAsync(async () => {
    try {
      return await commonService.getPrograms();
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Programs not found.', 5);
      return [];
    }
  }, []);

  const params = useMemo(
    () => ({
      current: 1,
      pageSize: PAGE_SIZE,
      sort: 'id,desc',
    }),
    [],
  );
  const applications = useTablePagination({
    url: `/families/households/${householdId}/applications`,
    params,
  });
  const reload = useCallback(async () => {
    return await applications.fetchMore({
      current: 1,
      pageSize: PAGE_SIZE,
    });
  }, [applications]);

  useEffect(() => {
    dispatch(actions.setApplication(null));
  }, [dispatch]);

  const commonColumns = useMemo(() => getCommonColumn(programs), [programs]);
  const allColumns = useMemo(
    () => [
      ...commonColumns,
      {
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 150,
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          const isExpired = hasApplicationExpired(record, index);
          return (
            <Space>
              {isExpired && config.ENV !== 'uat' && (
                <Tooltip placement="top" title="Edit">
                  <Button
                    onClick={() => {
                      if (expandedRows[0] === record.id) {
                        setExpandedRows([]);
                      } else {
                        setExpandedRows([record.id]);
                      }
                    }}
                    icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    className="icon-btn"
                    aria-label="Open"
                    data-testid={`collapse-button-${record.id}`}
                    title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
                  />
                </Tooltip>
              )}

              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    history.push(`/family/application/${record.id}/dashboard`);
                    dispatch(actions.setApplication({}));
                  }}
                  icon={<AiOutlineEdit />}
                  aria-label="Edit"
                />
              </Tooltip>
            </Space>
          );
        },
      },
    ],
    [commonColumns, expandedRows, history, dispatch],
  );
  const expandable = {
    expandIcon: () => null,
    rowExpandable: (record, index) => true,
    expandedRowRender: (record) => {
      return <RenewalApplications record={record} programs={programs} />;
    },
    expandedRowKeys: expandedRows,
  };
  return (
    <div className={(className, 'p-6')}>
      <Card
        title="Applications"
        noBodyPadding
        extra={
          <Space>
            <Button type="primary" icon={<Add />} onClick={() => history.push('/family/application')}>
              New Application
            </Button>

            <Button
              icon={<Renew />}
              disabled={applications.loading}
              loading={applications.loading}
              onClick={reload}
              data-testid="reload-btn"
            />
          </Space>
        }
      >
        <Table
          allColumns={allColumns}
          data={applications.data}
          loading={applications.loading}
          pagination={applications.pagination}
          onChange={applications.onChange}
          rowClassName={(record, index) => {
            const isExpired = hasApplicationExpired(record, index);
            return isExpired && config.ENV !== 'uat'
              ? '[&_td]:!bg-red-100 h-12'
              : index % 2 === 0
              ? '[&_td]:!bg-light-bg h-12'
              : '[&_td]:!bg-white h-12';
          }}
          xsCols={['id', 'actions']}
          rowKey="id"
          expandable={config.ENV === 'uat' ? undefined : expandable}
          showColSeparator={false}
        />
      </Card>
    </div>
  );
}

function RenewalApplications({ record, programs }) {
  const history = useHistory();
  const { loading, execute: createApplication } = useAsyncCallback(async () => {
    await sleep(500);
    message.success('New Application created');
    await sleep(500);
    // Move user to newly created application edit page
    history.push('/family/application/9247544');
  }, []);
  const commonColumns = useMemo(() => getCommonColumn(programs), [programs]);
  const allColumns = useMemo(
    () => [
      ...commonColumns,
      {
        title: <span className="action-header">Actions</span>,
        headerText: 'Actions',
        key: 'actions',
        width: 50,
        // eslint-disable-next-line react/display-name
        render: (_, record, index) => {
          return (
            <div className="actions-cell justify-end">
              <Tooltip placement="top" title="Edit">
                <Button
                  className="icon-btn edit-button"
                  onClick={() => {
                    history.push(`/family/application/${record.id}/dashboard`);
                  }}
                  icon={<AiOutlineEdit />}
                  aria-label="Edit"
                />
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [commonColumns, history],
  );
  return (
    <div className="section bg-white border-l-2 border-yellow-500 email-renderer !pb-0">
      <div className=" flex justify-between items-center mb-6">
        <h3 className="section-title">Renewal Applications</h3>
        <Button onClick={createApplication} disabled={loading} loading={loading}>
          Create Renewal Application
        </Button>
      </div>
      <Table
        data={data}
        allColumns={allColumns}
        pagination={{
          total: 3,
          pageSize: 10,
          current: 1,
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
}

const data = [
  {
    id: 10,
    householdId: 10,
    applicant: {
      id: 36,
      firstName: 'Test',
      middleName: '',
      lastName: 'Sponsor',
    },
    submittedDate: '2022-01-01',
    assignedDate: '2022-09-12',
    assignedTo: {
      id: 193,
      firstName: null,
      lastName: null,
      email: null,
    },
    reviewer: null,
    highPriority: false,
    status: {
      id: 44494,
      status: {
        id: 3,
        order: 3,
        description: 'Assigned',
        title: 'ASSIGNED',
        publicStatus: {
          id: 21,
          type: 3,
          sequenceNumber: 3,
          title: 'Approved',
        },
      },
    },
    startDate: '2022-01-01',
    endDate: '2022-12-31',
  },
  {
    id: 3,
    householdId: 3,
    applicant: {
      id: 5,
      firstName: 'Zohaib',
      middleName: null,
      lastName: 'Ijaz',
    },
    submittedDate: '2021-01-01',
    assignedDate: '2022-09-09',
    assignedTo: {
      id: 157,
      firstName: 'olivia',
      lastName: 'olivia',
      email: 'olivia.lambert@usa.childcareaware.org',
    },
    reviewer: null,
    highPriority: false,
    status: {
      id: 44494,
      status: {
        id: 3,
        order: 3,
        description: 'Assigned',
        title: 'ASSIGNED',
        publicStatus: {
          id: 21,
          type: 3,
          sequenceNumber: 3,
          title: 'Approved',
        },
      },
    },
    startDate: '2021-01-01',
    endDate: '2021-12-31',
  },
  {
    id: 2,
    householdId: 2,
    applicant: {
      id: 2,
      firstName: 'Riffat',
      middleName: 'Un',
      lastName: 'Nisa',
    },
    submittedDate: '2020-01-01',
    assignedDate: '2022-09-09',
    assignedTo: {
      id: 182,
      firstName: null,
      lastName: null,
      email: null,
    },
    reviewer: null,
    highPriority: false,
    status: {
      id: 44494,
      status: {
        id: 3,
        order: 3,
        description: 'Assigned',
        title: 'ASSIGNED',
        publicStatus: {
          id: 21,
          type: 3,
          sequenceNumber: 3,
          title: 'Approved',
        },
      },
    },
    startDate: '2020-01-01',
    endDate: '2020-12-31',
  },
];
