import React, { useState } from 'react';
import { Input, InputNumber, Button, message, Spin } from 'antd';
import dayjs from 'dayjs';
import { BiTrash } from 'react-icons/bi';
import classNames from 'classnames';
import { useAsyncCallback } from 'react-async-hook';

import Form from 'components/Form';
import { AsyncSelect } from 'components/Select';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import SponsorInstallationModal from 'components/Modals/SponsorInstallationModal';
import SwitchField from 'components/Switch/SwitchField';
import useStartEndDates from 'hooks/useStartEndDates';
import styles from './FeeCalculator.module.less';
import { getPastDateValidator } from 'utils';
import { DATE_FORMAT } from 'constants/index';
import AriaDatePicker from 'components/AriaDatePicker';

export default function FeeCalculator() {
  const [visibleInstallationModal, setVisibleInstallationModal] = useState(false);
  const [form] = Form.useForm();
  const programSponsorId = Form.useWatch('branchId', form);
  const {
    loading: calculating,
    result: feeResponse,
    execute: calculateFeeRequest,
  } = useAsyncCallback(async (values) => {
    try {
      values = { ...values, ...values, familyIncome: (values.sponsorIncome || 0) + (values.spouseIncome || 0) };
      const providerRate = values.facilityRate - (values.providerDiscount / 100) * values.facilityRate;
      values = {
        ...values,
        careStartDate: dayjs(values.careStartDate).format(DATE_FORMAT[2]),
        careEndDate: dayjs(values.careEndDate).format(DATE_FORMAT[2]),
        childDOB: dayjs(values.childDOB).format(DATE_FORMAT[2]),
        providerRate,
      };
      const data = await familyService.caculateFeeAssistance(values);
      return { ...data, feeAssistance: { ...data.feeAssistance, providerRate } };
    } catch (error) {
      message.error('Unable to calculate fee.', 5);
      newrelic.noticeError(error);
    }
  }, []);

  const [startDate, EndDate] = useStartEndDates({
    form,
    startDateFieldProps: {
      name: 'careStartDate',
      label: 'Start Date',
      rules: [{ required: true, message: 'Care Start Date is required.' }],
    },
    endDateFieldProps: {
      name: 'careEndDate',
      label: 'End Date',
      rules: [{ required: false }],
    },
  });

  return (
    <Form
      form={form}
      onValuesChange={(changedValues) => {
        if ('branchId' in changedValues) {
          form.resetFields(['programId']);
        } else if ('woundedWarrior' in changedValues) {
          form.setFieldsValue({
            deploymentBonus: !changedValues['woundedWarrior'],
          });
        } else if ('deploymentBonus' in changedValues) {
          form.setFieldsValue({
            woundedWarrior: !changedValues['deploymentBonus'],
          });
        }
      }}
      layout="vertical"
      onFinish={calculateFeeRequest}
    >
      {(values) => (
        <Spin spinning={calculating}>
          <div className={classNames('flex flex-col lg:flex-row w-full p-6', styles.feeCalculator)}>
            <div className="white-box w-full lg:w-3/4 mr-6">
              <div className="border-b p-6 white-box">
                {' '}
                <h3 className="page-title mb-4">Fee Assistance Calculator Input</h3>
                <span>Please fill out the following information</span>
                FeeCalculator
                <div className="flex items-center">
                  <h3 className="section-title mt-6">Sponsor Information</h3>
                </div>
                <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4 xl:gap-y-0 mt-4">
                  <div>
                    <Form.Item
                      name="branchId"
                      label="Branch"
                      rules={[{ required: true, message: 'Branch is required.' }]}
                    >
                      <AsyncSelect
                        apiPrefix="/common"
                        optionsApiUrl="/program-sponsors"
                        placeholder="Select"
                        ariaLabel="Select Branch"
                        onChangeFormatter={(v) => v.id}
                        isOptionSelected={(option, [value]) => option.id === value}
                      />
                    </Form.Item>
                  </div>

                  <div className="relative">
                    <h3 className="section-title absolute xl:-top-8 -top-6">Sponsor Income</h3>
                    <Form.Item
                      name="sponsorIncome"
                      label="Total income (Annually)"
                      rules={[
                        { required: true, message: 'Total Sponsor Incomes is required.' },
                        {
                          validator: async (rule, value) => {
                            if (value === undefined || value === '') return;
                            if (value > 999999) {
                              throw new Error('Sponsor Income must be between 0-999999');
                            }
                          },
                        },
                      ]}
                    >
                      <FeeCalculatorField />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item name="sponsorStatusId" label="Sponsor Status">
                      <AsyncSelect
                        getOptions={commonService.get}
                        apiPrefix="/common"
                        optionsApiUrl={`/program-types/family/statuses`}
                        placeholder="Select"
                        ariaLabel="Select Status"
                        onChangeFormatter={(v) => v.id}
                        isOptionSelected={(option, [value]) => option.id === value}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item name="residenceState" label="Residence State">
                      <AsyncSelect
                        name="state"
                        placeholder="Residence State"
                        ariaLabel="Residence State"
                        optionsApiUrl="/countries/states/US/"
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.code}
                        isOptionSelected={(option, [value]) => option?.code === value}
                        onChangeFormatter={(v) => v?.code}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="w-full grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-4 xl:gap-y-0 mt-4">
                  <Form.Item
                    name="installationId"
                    label="Installation"
                    rules={[{ required: true, message: 'Installation is required.' }]}
                  >
                    <SponsorInstallationModal
                      initialValues={values?.sponsorInstallation}
                      visible={visibleInstallationModal}
                      setVisible={setVisibleInstallationModal}
                      parentForm={form}
                      programSponsorId={programSponsorId}
                      showSelect
                      defaultState={values?.residenceState}
                      disableSelect={!programSponsorId}
                      updateInstallation={async () => {}}
                    />
                  </Form.Item>
                </div>
                <div className="flex relative my-2" id="second-parent-section">
                  <h3 className="section-title mt-2">Second Parent Information</h3>
                  <Form.Item name="secondParentInfo" className="ml-12">
                    <SwitchField className="absolute -top-1" switchProps={{ showLabels: false }} />
                  </Form.Item>
                </div>
                {values?.secondParentInfo && (
                  <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4 lg:gap-y-0 mt-2">
                    <div className="relative">
                      <h3 className="section-title absolute xl:-top-2 -top-6">Second Parent Income</h3>
                      <Form.Item name="spouseIncome" label="Total income (Annually)" className="mt-6">
                        <FeeCalculatorField />
                      </Form.Item>
                    </div>
                  </div>
                )}
                <div>
                  <h3 className="section-title my-2">Child Attending Child Care at a Provider</h3>
                  <div className="w-full grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-x-8 mt-6">
                    <div>
                      <Form.Item
                        name="childDOB"
                        data-testid="child-date-of-birth"
                        label="Child Date of Birth"
                        rules={[
                          { required: false, message: 'Child Date of Birth is required.' },
                          getPastDateValidator('Date of Birth should be in past.'),
                        ]}
                      >
                        <AriaDatePicker onlyPast />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        name="careTypeId"
                        label="Schedule of care"
                        rules={[{ required: true, message: 'Schedule of care is required.' }]}
                      >
                        <AsyncSelect
                          getOptions={commonService.get}
                          apiPrefix="/families"
                          optionsApiUrl="/options/7"
                          placeholder="Type of care"
                          ariaLabel="Type of care"
                          getOptionLabel={(option) => option.title}
                          onChangeFormatter={(v) => v.id}
                          isOptionSelected={(option, [value]) => option.id === value}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="w-full grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 mt-4">
                    <div>
                      <Form.Item name="isFirstChild" valuePropName="checked">
                        <SwitchField switchProps={{ showLabels: false }}>Older Child Bonus</SwitchField>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item name="woundedWarrior" valuePropName="checked">
                        <SwitchField switchProps={{ showLabels: false }}>Wounded Warrior Bonus</SwitchField>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item name="deploymentBonus" valuePropName="checked">
                        <SwitchField switchProps={{ showLabels: false }}>Deployment Bonus</SwitchField>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item name="partTimeCalculation" valuePropName="checked">
                        <SwitchField switchProps={{ showLabels: false }}>Part-Time Calculation</SwitchField>
                      </Form.Item>
                    </div>
                  </div>
                  <h3 className="section-title my-2">Provider Information</h3>
                  <div className="w-full grid  grid-cols-1 sm:grid-cols-2 lg:grid-cols-2  xl:grid-cols-3 gap-y-4 lg:gap-y-0 gap-x-8 mt-6">
                    <div>
                      <Form.Item
                        name="facilityRate"
                        label="Facility Rate (Monthly)"
                        rules={[{ required: true, message: 'Facility Rate  is required.' }]}
                      >
                        <FeeCalculatorField />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        name="providerDiscount"
                        label="Facility Applicable Discounts"
                        rules={[
                          { required: true, message: 'Facility Applicable Discounts is required.' },
                          {
                            validator: async (rule, value) => {
                              if (value === undefined || value === '') return;
                              if (value > 100) {
                                throw new Error('Facility Applicable Discounts must be between 0-100');
                              }
                            },
                          },
                        ]}
                      >
                        <FeeCalculatorField
                          formatter={(value) => `${value}%`}
                          parser={(value) => value.replace('%', '')}
                        />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item name="registrationFee" label="Registration Fee">
                        <FeeCalculatorField placeholder="$" />
                      </Form.Item>
                    </div>
                  </div>
                  <h3 className="section-title my-4">Program Information</h3>
                  <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-y-4 lg:gap-y-0 gap-x-8 mt-6">
                    <Form.Item
                      name="programId"
                      label="Program Type"
                      rules={[{ required: true, message: 'Program Type is required.' }]}
                    >
                      <AsyncSelect
                        key={`${programSponsorId}`}
                        getOptions={commonService.get}
                        apiPrefix="/common"
                        optionsApiUrl={`/programs/by-sponsor-Id?progSponsorId=${programSponsorId}`}
                        placeholder="Select"
                        ariaLabel="Select Program Type"
                        getOptionLabel={(option) => option.type.title}
                        onChangeFormatter={(v) => v.type.id}
                        isOptionSelected={(option, [value]) => option.type.id === value}
                      />
                    </Form.Item>
                  </div>
                  <div className="w-full grid grid-cols-1 lg:grid-cols-2  xl:grid-cols-3 gap-y-4 lg:gap-y-0 gap-x-8">
                    {startDate}
                    {EndDate}
                  </div>
                </div>
              </div>
              <div className="p-6 flex-col xl:flex-row flex xl:items-baseline justify-between space-y-2">
                <Button>Advance Calculator</Button>
                <div className="actions flex xl:w-3/4">
                  <Button>Cancel</Button>
                  <Button onClick={() => form.resetFields()}>Reset Form</Button>
                  <Button>View Summary</Button>
                  <Button type="primary" htmlType="submit" data-testid="submit-request">
                    Calculate
                  </Button>
                </div>
              </div>
            </div>

            <div className="p-6 white-box w-full lg:w-1/4">
              <div className="flex justify-between">
                <h3 className="page-title mb-4">
                  Fee Assistance Calculator
                  <br /> Output
                </h3>
                <Button className="icon-btn alert delete-button" icon={<BiTrash />} aria-label="Delete" />
              </div>

              <h3 className="section-title py-4">Family Category</h3>
              <FeeCalculatorField value={feeResponse?.familyIncome} label="Total Family Income" inputType="plain" />
              <FeeCalculatorField
                value={feeResponse?.feeAssistance?.incomeCategory}
                label="Income Category"
                inputType="plain"
              />
              <h3 className="section-title py-4">Discounts</h3>
              <FeeCalculatorField
                value={feeResponse?.discount?.providerDiscount}
                label="Provider Discount"
                inputType="plain"
              />
              <FeeCalculatorField
                value={feeResponse?.discount?.deployedBonus}
                label="Deployed/WW Bonus: 20%"
                inputType="plain"
              />
              <FeeCalculatorField
                value={feeResponse?.discount?.eldestChildBonus}
                label="Eldest Child Bonus: 15%"
                inputType="plain"
              />
              <h3 className="section-title py-4">CCAOA Fee Assistance Rate</h3>
              <FeeCalculatorField
                value={feeResponse?.feeAssistance?.registrationFee}
                label="Registration Fee (Once only)"
                inputType="plain"
              />
              <FeeCalculatorField
                value={feeResponse?.feeAssistance?.providerRate}
                label="Provider Rate (Monthly)"
                inputType="plain"
              />
              <FeeCalculatorField
                value={feeResponse?.feeAssistance?.providerRateCap}
                label="Provider Cap Rate"
                inputType="plain"
              />
              <FeeCalculatorField
                value={feeResponse?.feeAssistance?.sponsorRate}
                label="Sponsor Rate (Monthly)"
                inputType="plain"
              />
              <FeeCalculatorField
                value={feeResponse?.feeAssistance?.feeAssistance}
                label="Fee Assistance (Monthly)"
                inputType="plain"
              />
              <h3 className="section-title py-4">Zero Dollar Status</h3>
              <FeeCalculatorField
                value={feeResponse?.zeroDollarStatus?.zeroDollarCert}
                label="Zero Dollar Certificate"
                inputType="plain"
              />
              <FeeCalculatorField
                value={feeResponse?.zeroDollarStatus?.zeroDollarAmount}
                label="Zero Dollar Amount"
                inputType="plain"
              />
            </div>
          </div>
        </Spin>
      )}
    </Form>
  );
}

const FeeCalculatorField = ({
  value,
  label,
  inputType = 'number',
  formatter = (value) => `$ ${value}`,
  parser = (value) => value?.replace('$ ', ''),
  min = 0,
  ...reset
}) => {
  return (
    <div className={styles.readOnlyInput}>
      <Form.Item label={label}>
        {inputType === 'number' ? (
          <InputNumber value={value} parser={parser} formatter={formatter} {...reset} />
        ) : (
          <Input value={value} readOnly {...reset} />
        )}
      </Form.Item>
    </div>
  );
};
