import { Helmet } from 'react-helmet-async';

const PageMeta = ({ title }) => {
  return (
    <Helmet>
      <title>{title} | Great Child Care</title>
    </Helmet>
  );
};

export default PageMeta;
