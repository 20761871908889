import { useSelector } from 'react-redux';
import { ROLES } from 'constants/index';
import { selectActiveRole } from 'features/auth';
import { selectors } from 'features/add-center';

export default function useProviderEditDisabled() {
  const activeRole = useSelector(selectActiveRole);
  const appStatus = useSelector(selectors.selectInternalStatus);
  return activeRole === ROLES.PROVIDER && !['INCOMPLETE', 'PENDING', undefined].includes(appStatus?.title);
}
