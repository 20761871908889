import React from 'react';

import { UserFollow } from '@carbon/icons-react';
import Button from 'components/Button';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export default function HeaderMenu() {
  return (
    <Link to="/signin">
      <Button type="primary">
        <UserFollow /> Sign In
      </Button>
    </Link>
  );
}
