export default function getTemplate({ fullName }) {
  return `
  <p>
    Dear ${fullName},
  </p>
  <p><br></p>
  <p>Please review the attached letter regarding your childcare selections’ current status in the Army Fee assistance program. Please be advised this provider is still eligible for fee assistance while in probationary status, but we need for you to reply confirming that you wish to move forward with the provider selection. If you have any questions about the probation please contact our Provider Services Department at 800-424-2246 option 4.</p>
  <p><br></p>
  <p>If you would like to find a new provider please contact our Parent Services Department at 800-424-2246 option 3.</p>
  <p><br></p>
`;
}
