import React from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';

export default function InfoSection({
  items,
  className = '',
  gridClassName = '',
  itemClassName = '',
  titleClassName = 'font-semibold text-sm',
  valueClassName = '',
  colsClassName = 'grid-cols-[200px_1fr]',
  extraContent,
  sectionId,
  loading,
}) {
  return (
    <Spin spinning={Boolean(loading)}>
      <div className={className} id={`info-section-${sectionId}`}>
        <div className={gridClassName}>
          {items.map((item, index) => {
            if (React.isValidElement(item)) return item;
            return (
              <div key={index} className={classNames('grid', colsClassName, itemClassName, item.className)}>
                <div id={`item-title-${item.id}`} className={classNames(titleClassName, item.titleClassName)}>
                  {item.title}
                </div>
                <div
                  id={`item-value-${item.id}`}
                  className={classNames(valueClassName, item.valueClassName)}
                  title={typeof item.value === 'string' ? item.value : undefined}
                >
                  {item.value}
                </div>
              </div>
            );
          })}
        </div>
        {extraContent}
      </div>
    </Spin>
  );
}
