import { useMemo, useState, useEffect } from 'react';
import { Skeleton, Descriptions } from 'antd';
import dayjs from 'dayjs';
import { useAsync } from 'react-async-hook';

import familyService from 'services/family.service';
import Select from 'components/Select';
import { formatDate, getCompletedAddress, getFullName } from 'utils';
import { selectCommonStyles } from 'constants/index';
import commonService from 'services/common.service';

export default function ChildScheduleHeader({ child, status, childId, loading, application, headerExtraElement }) {
  const [selectedProvider, setSelectedProvider] = useState({});

  useEffect(() => {
    setSelectedProvider(child?.schedules?.find((item) => item.careFacility));
  }, [child?.schedules]);

  const { loading: loadingSchoolInfo, result: schoolInfo = {} } = useAsync(async () => {
    if (childId) {
      const data = await familyService.getSchoolInfo(childId);
      return { ...data?.[0], ...data?.[0].terms?.[0] } || {};
    }
  }, [childId, application?.children]);

  const { result: installation } = useAsync(
    async (programSponsorId) => {
      if (!programSponsorId) return null;
      const data = await commonService.get(`/common/military-installations?programSponsorId=${programSponsorId}`);
      return data.find((item) => item.id === child?.guardianship?.parentGuardian?.position?.militaryInstallationId);
    },
    [child?.guardianship?.parentGuardian?.position?.programSponsorId],
  );

  const providerOptions = useMemo(
    () => child?.schedules?.filter((item) => item.careFacility) || [],
    [child?.schedules],
  );

  const items = useMemo(
    () => [
      {
        id: 'date_of_birth',
        title: 'Date of Birth',
        value: formatDate(child?.dateOfBirth) || 'N/A',
      },
      {
        id: 'school_name',
        title: 'School Name',
        value: schoolInfo?.name || 'N/A',
      },
      {
        id: 'provider_name',
        title: 'Provider Name',
        value:
          (
            <Select
              name="selectedProvider"
              options={providerOptions}
              placeholder="Select"
              ariaLabel="Select Provider"
              styles={selectCommonStyles}
              getOptionLabel={(item) => item?.careFacility?.businessLegalName || 'N/A'}
              getOptionValue={(item) => item?.careFacility?.id}
              onChange={(value) => {
                if (value?.id) {
                  window.open(`/providers/public/${value.id}`, '_blank', 'width=1200,height=1000');
                }
                setSelectedProvider(value);
              }}
              value={selectedProvider}
            />
          ) || 'N/A',
      },
      {
        id: 'age',
        title: 'Age',
        value: child?.dateOfBirth ? dayjs(child?.dateOfBirth).toNow(true) : 'N/A',
      },
      {
        id: 'school_address',
        title: 'School Address',
        value: <Address value={schoolInfo?.address} />,
      },
      {
        id: 'provider_id',
        title: 'Provider ID',
        value: selectedProvider?.careFacility?.id || 'N/A',
      },
      {
        id: 'person_homeAddress',
        title: 'Home Address',
        value: <Address value={child?.homeAddress} />,
      },
      {
        id: 'grade',
        title: 'Grade',
        value: schoolInfo?.grade?.title || 'N/A',
      },
      {
        id: 'provider_Address',
        title: 'Provider Address',
        value: <Address value={selectedProvider?.careFacility?.address} />,
      },
      {
        id: 'parent_installation',
        title: 'Parent Installation',
        value: installation?.title || 'N/A',
      },
      {
        id: 'school_start_date',
        title: 'School Year Start Date',
        value: formatDate(schoolInfo?.startDate) || 'N/A',
      },
      {
        id: 'provider_installation',
        title: 'Provider Installation',
        value: status?.title || 'N/A',
      },
      {
        id: 'school_end_date',
        title: 'School Year End Date',
        value: formatDate(schoolInfo?.endDate) || 'N/A',
      },
    ],
    [
      child?.dateOfBirth,
      child?.homeAddress,
      schoolInfo?.name,
      schoolInfo?.address,
      schoolInfo?.grade?.title,
      schoolInfo?.startDate,
      schoolInfo?.endDate,
      providerOptions,
      selectedProvider,
      installation?.title,
      status?.title,
    ],
  );

  const descItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        key: item.id,
        label: item.title,
        children: item.value,
      })),
    [items],
  );

  return (
    <div className="px-6 pb-6 pt-5">
      <Skeleton avatar loading={loading || loadingSchoolInfo} paragraph={{ rows: 4 }}>
        <div className="mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Child:</span>{' '}
                <span className=" text-primary font-medium">{getFullName(child) || 'N/A'}</span>
              </p>
            }
            size="small"
            items={descItems}
            extra={headerExtraElement}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {descItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}

function Address({ value }) {
  if (!value) return 'N/A';
  const address = getCompletedAddress(value);
  return (
    <span className="truncate max-w-[250px] block" title={address}>
      {address}
    </span>
  );
}
