import React, { useCallback, useMemo, useState } from 'react';
import { Button, Tabs } from 'antd';

import Emails from 'components/Emails';
import useTablePagination from 'hooks/useTablePagination';
import { EMAIL_TYPES } from 'constants/index';
import Card from 'components/Card/Card';
import { Renew } from '@carbon/icons-react';

const PAGE_SIZE = 10;

export default function AllEmails({ className, entityLabel }) {
  const mapper = useCallback((obj) => obj, []);

  const scheduledEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      scheduled: true,
      incoming: false,
      statuses: ['PENDING'],
      sort: 'id,desc',
    }),
    [],
  );
  const scheduledEmailsData = useTablePagination({
    url: `/communications/email`,
    params: scheduledEmailParams,
    mapper,
  });

  const sentEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      scheduled: false,
      incoming: false,
      statuses: ['DELIVERED', 'QUEUED', 'ERROR'],
      sort: 'id,desc',
    }),
    [],
  );
  const sentEmailsData = useTablePagination({
    url: `/communications/email`,
    params: sentEmailParams,
    mapper,
  });

  const incomingEmailParams = useMemo(
    () => ({
      size: PAGE_SIZE,
      incoming: true,
      scheduled: false,
      statuses: ['DELIVERED', 'QUEUED', 'ERROR'],
      sort: 'id,desc',
    }),
    [],
  );
  const incomingEmailsData = useTablePagination({
    url: `/communications/email`,
    params: incomingEmailParams,
    mapper,
  });

  const items = useMemo(
    () => [
      {
        label: 'Inbox',
        key: 'inbox',
        children: (
          <Card noBodyPadding title="Inbox Emails" extra={<Actions state={incomingEmailsData} />}>
            <Emails entityLabel={entityLabel} state={incomingEmailsData} type={EMAIL_TYPES.INCOMING} />
          </Card>
        ),
      },
      {
        label: 'Sent',
        key: 'sent',
        children: (
          <Card noBodyPadding title="Sent Emails" extra={<Actions state={sentEmailsData} />}>
            <Emails entityLabel={entityLabel} state={sentEmailsData} type={EMAIL_TYPES.SENT} />
          </Card>
        ),
      },
      {
        label: 'Schedule',
        key: 'schedule',
        children: (
          <Card noBodyPadding title="Scheduled Emails" extra={<Actions state={scheduledEmailsData} />}>
            <Emails entityLabel={entityLabel} state={scheduledEmailsData} type={EMAIL_TYPES.SCHEDULED} />
          </Card>
        ),
      },
    ],
    [entityLabel, incomingEmailsData, scheduledEmailsData, sentEmailsData],
  );
  const [activeKey, onTabClick] = useState(items[0]?.key);

  return (
    <div className={className}>
      <Tabs items={items} activeKey={activeKey} onTabClick={onTabClick} />
    </div>
  );
}

const Actions = ({ state }) => (
  <>
    <Button
      icon={<Renew />}
      data-testid="reload-btn"
      onClick={state.reload}
      loading={state.loading}
      disabled={state.loading}
    />
  </>
);
