import { useMemo, useState } from 'react';
import { Descriptions, message, Skeleton } from 'antd';
import { useAsync } from 'react-async-hook';

import { getCompletedAddress, getFullName } from 'utils';
import Select from 'components/Select';
import { familyService } from 'services';

export default function EmploymentHeader({ id, parent, branch, component, status, loading, headerExtraElement }) {
  const [selectedEducation, setSelectedEducation] = useState(null);
  const items = useMemo(
    () => [
      {
        id: 'person_employmentStatus',
        title: 'Employment Status',
        value: parent?.employmentStatus || 'N/A',
      },
      {
        id: 'branch_title',
        title: 'Branch',
        value: branch?.title || 'N/A',
      },
      {
        id: 'institute_title',
        title: 'Institutation Name',
        valueClassName: '-mt-2 pr-3',
        value: (
          <EducationName
            parent={parent}
            selectedEducation={selectedEducation}
            setSelectedEducation={setSelectedEducation}
          />
        ),
      },
      {
        id: 'person_employed',
        title: 'Employment Details',
        value: parent?.militaryEmployee ? 'Military Personnel' : parent.employmentStatus || 'N/A',
      },
      {
        id: 'component_title',
        title: 'Component',
        value: component?.title || 'N/A',
      },
      {
        id: 'institutionAddress',
        title: 'Institution Address',
        value: getCompletedAddress(selectedEducation?.address) || 'N/A',
      },
      {
        id: 'person_workAddress',
        title: 'Work Address',
        value: getCompletedAddress(parent?.workAddress) || 'N/A',
      },
      {
        id: 'status_title',
        title: 'Status',
        value: status?.title || 'N/A',
      },
      {
        id: 'person_position_grade_title',
        title: 'Grade',
        value: parent?.position?.grade?.title || 'N/A',
      },
      {
        id: 'person_position_recruiterType_title',
        title: 'Recruiter Type',
        value: parent?.position?.recruiterType?.title || 'N/A',
      },
    ],
    [branch?.title, component?.title, parent, selectedEducation, status?.title],
  );
  const descItems = useMemo(
    () => items.map((item) => ({ key: item.id, label: item.title, children: item.value })),
    [items],
  );
  return (
    <div className="px-6 pb-6 pt-1">
      <Skeleton avatar loading={loading} paragraph={{ rows: 4 }}>
        <div className="mt-4 mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Sponsor:</span>{' '}
                <span className=" text-primary font-medium">{getFullName(parent)}</span>
              </p>
            }
            size="small"
            items={descItems}
            extra={headerExtraElement}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {descItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}

function EducationName({ parent, selectedEducation, setSelectedEducation }) {
  const { result: educations = [], loading } = useAsync(async () => {
    if (!parent?.id) return [];
    try {
      const educations = await familyService.getParentEducations(parent?.id);
      setSelectedEducation(educations?.[0]);
      return educations;
    } catch (error) {
      newrelic.noticeError(error);
      message.error('Unable to get institutes.');
      return [];
    }
  }, []);
  return (
    <Select
      className="w-full"
      options={educations}
      isOptionSelected={(option, [value]) => option.id === value?.id}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      loading={loading}
      onChange={(value) => setSelectedEducation(value)}
      value={selectedEducation}
      styles={{
        control: (provided) => ({ ...provided, height: 30, minHeight: 30 }),
        valueContainer: (provided) => ({ ...provided, height: 30, marginTop: -5 }),
        input: (provided) => ({ ...provided, height: 30, margin: 0 }),
        indicatorsContainer: (provided) => ({ ...provided, height: 30 }),
      }}
    />
  );
}
