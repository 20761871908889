import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

export default function CardItem({ label, value, icon, className, onClick, valueClassName, testId, disabled, title }) {
  return (
    <div
      title={title}
      className={classNames(
        'h-24 relative flex flex-col gap-1 justify-center py-1 px-3 border border-gray-200 border-opacity-50',
        className,
      )}
    >
      <div className="label flex justify-between text-13 tracking-wider leading-4">{label}</div>
      <div className={classNames('value flex justify-between items-end', valueClassName)}>
        {typeof value === 'string' ? (
          <div className="text text-base font-semibold truncate max-w-min">{value}</div>
        ) : (
          value
        )}
        {icon && onClick && (
          <div className="icon">
            <Button
              type="text"
              className="h-8 absolute bottom-1 right-2 icon-btn"
              onClick={onClick}
              icon={icon}
              data-testid={testId}
              disabled={disabled}
            />
          </div>
        )}
        {icon && !onClick && <div className="icon">{icon}</div>}
      </div>
    </div>
  );
}
