import React, { useState, useEffect, useMemo } from 'react';
import { Button, message, Spin } from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import ChecklistOptions from 'components/ChecklistOptions';
import CollapsePanel from 'components/CollapsePanel';
import useExitPrompt from 'hooks/useExitPrompt';
import ConfirmRouteChange from 'components/ConfirmRouteChange';
import useChecklist from 'hooks/useChecklist';
import StatusNotes from './StatusNotes';
import checklistService from 'services/checklist.service';

const Checklist = ({
  id,
  className,
  checklistTitle = 'Provider Checklist',
  saveChecklist,
  notesState,
  getNote,
  saveNote,
  statusState,
  updateStatus,
  statusOptionsApiUrl,
  optionsApiPrefix = '/providers',
  type,
  onGenerateChecklist,
  checklistDescription,
  shouldRenderChecklist = true,
}) => {
  // const [visibleChecklistModal, setVisibleChecklistModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  const history = useHistory();

  const { saveChecklistItems, checklistData, isSaving } = useChecklist({
    id,
    data,
    saveChecklist,
  });

  const items = useMemo(() => {
    return checklistData.reduce((acc, item) => acc.concat(item.items), []) || [];
  }, [checklistData]);
  const generateChecklistDisabled = useMemo(() => {
    const rejectedItem = items.find((item) => {
      return item.status === 'REJECTED';
    });
    return rejectedItem ? false : true;
  }, [items]);

  useEffect(() => {
    if (id && type) {
      fetchChecklist(id, type);
    }
    async function fetchChecklist(id, type) {
      try {
        setLoading(true);
        const checklist = await checklistService.getChecklist(id, type);
        setData(checklist);
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to get checklist data');
      } finally {
        setLoading(false);
      }
    }
  }, [id, type]);
  useEffect(() => {
    return () => {
      setShowExitPrompt(false);
    };
  }, [setShowExitPrompt]);

  useEffect(() => {
    setShowExitPrompt(isSaving);
  }, [isSaving, setShowExitPrompt]);

  return (
    <div id="checklist-container" className={classNames(className, 'bg-white w-full')}>
      <ConfirmRouteChange
        decider={() => !isSaving}
        title="Checklist changes in progress"
        content="Checklist changes have not been saved, are you sure you want to leave?"
        when={isSaving}
        onOk={async (location) => {
          history.push(location.pathname);
        }}
      />
      <div className="collapse-section border-b-2 border-gray-300 border-dashed">
        <Spin spinning={loading}>
          <CollapsePanel
            header={
              <div className="py-4 px-4 bg-white w-full flex flex-row justify-between items-center">
                <span className="text-md text-black font-semibold uppercase">{checklistTitle}</span>
              </div>
            }
            triggerClassName="right-2 top-4"
            testId="container-section"
            className="shadow-card bg-white collapse-section"
            // defaultOpen={false}
          >
            <p className="description mb-4 px-6">
              {shouldRenderChecklist
                ? 'Please provide the following checklist information for your application.'
                : checklistDescription}
            </p>
            {shouldRenderChecklist && (
              <>
                <ChecklistOptions
                  checklist={checklistData}
                  onChange={saveChecklistItems}
                  isSaving={isSaving}
                  disabled={generateChecklistDisabled}
                />
                <div className="px-6 mb-5">
                  <Button
                    onClick={onGenerateChecklist}
                    className="w-full"
                    disabled={generateChecklistDisabled || isSaving}
                    loading={isSaving}
                    title={generateChecklistDisabled ? 'No rejected item in checklist' : undefined}
                    data-testid="generate-checklist-btn"
                  >
                    Email Checklist
                  </Button>
                </div>
              </>
            )}
          </CollapsePanel>
        </Spin>
      </div>
      {notesState && (
        <StatusNotes
          className="pb-10"
          id={id}
          notesState={notesState}
          getNote={getNote}
          saveNote={saveNote}
          statusState={statusState}
          updateStatus={updateStatus}
          optionsApiUrl={statusOptionsApiUrl}
          optionsApiPrefix={optionsApiPrefix}
        />
      )}
    </div>
  );
};

export default Checklist;
