import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import PDFObject from 'pdfobject';
import { useAsync } from 'react-use';
import { useSelector } from 'react-redux';
import { Button, Spin, message } from 'antd';
import ViewField from 'components/ViewField';
import filesService from 'services/files.service';
import useAsyncFn from 'hooks/useAsyncFn';
import { ROLES } from 'constants/index';
import { selectActiveRole } from 'features/auth';
import { formatDate } from 'utils';
import RenderUserName from 'components/RenderUserName';
import { Checkmark, Flag, FlagFilled } from '@carbon/icons-react';
import Space, { Spacer } from 'components/Space/Space';

export default function DocumentPreview({ id, document, className, getDocument, getDocuments, review }) {
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const activeRole = useSelector(selectActiveRole);
  const isFamilyRole = (activeRole || '').includes('Family');
  const { loading: fileLoading, value: file } = useAsync(async () => {
    if (!document) {
      return await filesService.getS3File({ id });
    } else {
      return document;
    }
  }, [document, id]);
  const [{ loading: markingForReview }, markForReview] = useAsyncFn(
    async (reviewRequired) => {
      try {
        await filesService.markForReview(id, { reviewRequired });
        if (typeof getDocuments === 'function') {
          await getDocuments();
        }
        if (typeof getDocument === 'function') {
          await getDocument();
        }
      } catch (error) {
        message.error('Unable to mark for review.', 5);
        newrelic.noticeError(error);
      }
    },
    [id],
  );

  const getFile = useCallback(async (document) => {
    setLoading(true);
    try {
      const blob = await filesService.getS3File(document);
      const url = URL.createObjectURL(blob, { type: document.mimeType });

      if (document.mimeType === 'application/pdf') {
        PDFObject.embed(url, `#file-preview`, {
          title: document.name,
          omitInlineStyles: true,
          height: '700px',
          width: '100%',
        });
      } else {
        setImage(url);
      }
    } catch (error) {
      message.error('Unable to get the document.', 5);
      newrelic.noticeError(error);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    /* istanbul ignore else */
    if (document?.id) getFile(document);
  }, [getFile, document?.id, document]);
  return (
    <div className={classNames('bg-white px-3 md:px-4 lg:px-8 py-5', className)} id="file-preview-container">
      <Space size={8}>
        <ViewField
          label="Submission Date:"
          value={formatDate(document?.createdDate)}
          showInfoIcon={false}
          valueClassName="pl-2 font-medium"
          className="mb-0"
        />

        <ViewField
          label="Submitted By:"
          value={<RenderUserName id={document.createdById} />}
          showInfoIcon={false}
          valueClassName="pl-2 font-medium"
          className="mb-0"
        />

        <Spacer />

        {!isFamilyRole && !document?.reviewRequired && !review?.confirmed && (
          <Button
            icon={<Flag />}
            onClick={() => markForReview(!document?.reviewRequired)}
            disabled={markingForReview}
            loading={markingForReview}
          >
            Mark for Review
          </Button>
        )}

        {!isFamilyRole && document?.reviewRequired && !review?.id && (
          <Button
            icon={<FlagFilled />}
            onClick={() => markForReview(!document?.reviewRequired)}
            danger
            disabled={activeRole !== ROLES.PROVIDER_COORDINATOR}
            loading={markingForReview}
          >
            Marked for Review
          </Button>
        )}

        {!isFamilyRole && document?.reviewRequired && !review?.confirmed && review?.id && (
          <Button icon={<FlagFilled />} danger disabled>
            Marked for Review
          </Button>
        )}

        {!isFamilyRole && review?.confirmed && (
          <Button
            className="!bg-green-600 !border-green-600 pointer-events-none"
            icon={<Checkmark className="text-white mr-2" />}
            tabIndex={-1}
          >
            <span className="!text-white">Reviewed</span>
          </Button>
        )}
      </Space>

      <div>
        <Spin spinning={fileLoading || loading} size="large" style={{ minHeight: 400 }}>
          {file?.mimeType === 'application/pdf' ? (
            <div
              id={`file-preview`}
              className={classNames('file-container', {
                'flex items-center justify-center': PDFObject.supportsPDFs,
              })}
            />
          ) : image ? (
            <img src={image} alt="Preview" height={500} />
          ) : null}
        </Spin>

        {!image && <div className="sr-only" data-testid="file-loading" />}
      </div>
    </div>
  );
}
