import React, { useCallback, useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { useLocation, useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { familyService } from 'services';

import FamilyReviewContainer from '../FamilyReviewContainer';
import CalculateCertificate from './CalculateCertificate';
import CertificateChildHeader from './CertificateChildHeader';
import CertificateStatusContent from './CertificateStatusContent';
import CertificateSummary from './CertificateSummary';
import CertificateSummaryHeader from './CertificateSummaryHeader';
import CreateCertificate from './CreateCertificate';

export default function AddCertificate({ application, loading, rootRef }) {
  const { pathname } = useLocation();
  const { id, householdId, certificateId } = useParams();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const CERTIFICATE_STEPS_ORDER = useMemo(() => {
    if (certificateId) return ['update', 'summary', 'calculate', 'status'];
    return ['add'];
  }, [certificateId]);

  const { result: certificate = {}, merge: setCertificate } = useAsync(
    async (certificateId) => {
      if (!certificateId) return null;
      const data = await familyService.getCertificateById(certificateId);
      return data;
    },
    [certificateId],
  );

  let current = useMemo(
    () => CERTIFICATE_STEPS_ORDER.findIndex((key) => pathname.includes(key)) || 0,
    [CERTIFICATE_STEPS_ORDER, pathname],
  );
  if (pathname.endsWith('/review')) {
    current = 0;
  }
  current = current === -1 ? 0 : current;
  const onChange = useCallback(
    (index) => {
      history.push(`${url}/${CERTIFICATE_STEPS_ORDER[index]}`);
    },
    [CERTIFICATE_STEPS_ORDER, history, url],
  );

  const { routePrefix, pathPrefix } = useMemo(() => {
    if (!certificateId) return { routePrefix: path, pathPrefix: url };
    return {
      routePrefix: `${path}/${CERTIFICATE_STEPS_ORDER[current]}`,
      pathPrefix: `${url}/${CERTIFICATE_STEPS_ORDER[current]}`,
    };
  }, [CERTIFICATE_STEPS_ORDER, certificateId, current, path, url]);

  const child = useMemo(() => {
    return (application?.children || []).find((child) =>
      (certificate?.schedules || []).some(({ id }) => (child?.schedules || []).some((sch) => sch.id === id)),
    );
  }, [application?.children, certificate?.schedules]);

  const { result: installation } = useAsync(
    async (childId) => {
      if (!childId) return null;
      return (await familyService.getPersonInstallation(id, childId))?.installation;
    },
    [child?.id],
  );

  const props = useMemo(
    () => ({
      id,
      householdId,
      certificateId,
      application,
      loading,
      certificate,
      child,
      installation,
      secondParent: application.additionalParents?.[0] || {},
      setCertificate,
    }),
    [application, certificate, certificateId, child, householdId, id, installation, loading, setCertificate],
  );

  const statusPathName = useMemo(() => pathname.replace(/\/documents(.*)$/, ''), [pathname]);
  const stepItems = useMemo(() => {
    let items = [
      {
        title: 'Create Certificate',
        status: statusPathName.endsWith('/add') || statusPathName.endsWith('/update') ? 'process' : 'wait',
        content: (
          <CreateCertificate
            {...props}
            prev={`/families/${householdId}/applications/${id}/review/certificates`}
            next={`${url}/summary`}
          />
        ),
      },
    ];

    if (certificateId) {
      items = items.concat(
        {
          title: 'Family Summmary',
          status: statusPathName.endsWith('/summary') ? 'process' : 'wait',
          header: <CertificateSummaryHeader {...props} />,
          content: <CertificateSummary {...props} prev={`${url}/update`} next={`${url}/calculate`} />,
        },
        {
          title: 'Calculate Certificate',
          status: statusPathName.endsWith('/calculate') ? 'process' : 'wait',
          header: <CertificateChildHeader {...props} />,
          content: <CalculateCertificate {...props} prev={`${url}/summary`} next={`${url}/status`} />,
        },
        {
          title: 'Certificate Status',
          status: statusPathName.endsWith('/status') ? 'process' : 'wait',
          content: (
            <CertificateStatusContent
              {...props}
              prev={`${url}/calculate`}
              next={`/families/${householdId}/applications/${id}/review/sponsor/info`}
            />
          ),
        },
      );
    }
    return items;
  }, [statusPathName, props, householdId, id, url, certificateId]);
  return (
    <div className="[&_.ant-steps-item-content]:!w-40">
      <FamilyReviewContainer
        stepItems={stepItems}
        current={current}
        onChange={onChange}
        rootRef={rootRef}
        routePrefix={routePrefix}
        pathPrefix={pathPrefix}
        stepsClassName="pb-4"
      />
    </div>
  );
}
