import { useMemo } from 'react';
import { Descriptions, Skeleton } from 'antd';
import dayjs from 'dayjs';

import { formatDate, getCompletedAddress, getFullName } from 'utils';
import { useAsync } from 'react-async-hook';
import { familyService } from 'services';
import RenderProviderName from 'components/RenderProviderName';

export default function CertificateChildHeader({ id, application, certificate, loading, child, installation }) {
  const providerId = certificate?.schedules?.[0]?.careFacility?.id;
  const youngerSibling = null;

  const { result: schoolInfo = {} } = useAsync(
    async (childId) => {
      if (childId) {
        const data = await familyService.getSchoolInfo(childId);
        return data[0] || {};
      }
    },
    [child?.id],
  );
  const term = useMemo(() => schoolInfo?.terms?.[0] || {}, [schoolInfo?.terms]);

  const items = useMemo(
    () => [
      {
        id: 'date_of_birth',
        title: 'Date of Birth/Age',
        value: child?.dateOfBirth
          ? `${formatDate(child?.dateOfBirth)} (${dayjs(child?.dateOfBirth).toNow(true)})`
          : 'N/A',
      },
      {
        id: 'younger_sibling',
        title: 'Younger Sibling',
        value: getFullName(youngerSibling) || 'N/A',
      },
      {
        id: 'person_homeaddress',
        title: 'Home Address',
        value: getCompletedAddress(child?.homeAddress) || 'N/A',
      },
      {
        id: 'school',
        title: 'School Name',
        value: schoolInfo?.name || 'N/A',
      },
      {
        id: 'school_address',
        title: 'School Address',
        value: getCompletedAddress(schoolInfo?.address) || 'N/A',
      },
      {
        id: 'school_year_dates',
        title: 'School Year Dates',
        value: term?.startDate && term?.endDate ? `${formatDate(term.startDate)} - ${term.endDate}` : 'N/A',
      },
      {
        id: 'grade',
        title: 'Garde',
        value: term?.grade?.title || 'N/A',
      },
      {
        id: 'certificate_dates',
        title: 'Certificate Dates',
        value:
          certificate?.startDate && certificate?.endDate
            ? `${formatDate(certificate.startDate)} - ${certificate.endDate}`
            : 'N/A',
      },
      {
        id: 'installation',
        title: 'Installation',
        value: installation?.name || 'N/A',
      },
      {
        id: 'provider_id',
        title: 'Provider ID',
        value: providerId || 'N/A',
      },
      {
        id: 'provider_name',
        title: 'Provider Name',
        value: <RenderProviderName id={providerId} />,
      },
    ],
    [
      certificate.endDate,
      certificate.startDate,
      child?.dateOfBirth,
      child?.homeAddress,
      installation?.name,
      providerId,
      schoolInfo?.address,
      schoolInfo?.name,
      term.endDate,
      term?.grade?.title,
      term.startDate,
    ],
  );

  const descItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        key: item.id,
        label: item.title,
        children: item.value,
      })),
    [items],
  );
  return (
    <div className="px-6 pb-6 -mt-4">
      <Skeleton avatar loading={loading} paragraph={{ rows: 4 }}>
        <div className="mt-4 mx-auto max-w-[1600px]">
          <Descriptions
            bordered
            title={
              <p className="text-xl">
                <span className="font-normal">Child:</span>{' '}
                <span className=" text-primary font-medium">{getFullName(child) || 'N/A'}</span>
              </p>
            }
            size="small"
            items={descItems}
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 3, xxl: 3 }}
            className="[&_.ant-descriptions-item-content]:text-base [&_.ant-descriptions-item-content]:font-medium [&_.ant-descriptions-item-label]:!text-base [&_.ant-descriptions-item-label]:!text-black"
          >
            {descItems.map((item) => (
              <Descriptions.Item {...item} />
            ))}
          </Descriptions>
        </div>
      </Skeleton>
    </div>
  );
}
