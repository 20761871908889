import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Upload, message } from 'antd';
import PDFObject from 'pdfobject';
import { Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';
import reverse from 'lodash/reverse';

import { downloadFile, getBase64String, getValue, SnapshotContext, uploadFileFieldsFormat } from 'utils';

import styles from './DragDropUpload.module.less';
import Form from 'components/Form';
import Modal from 'components/Modal';
import { selectUser } from 'features/auth';
import { selectors } from 'features/documents';
import AttachmentItem from './AttachmentItem';
import { CloudUpload } from '@carbon/icons-react';

const { Dragger } = Upload;

export default function DragDropUpload({
  keyPrefix,
  name,
  className,
  rules,
  messages,
  allowedFileMessage,
  maxFileSize,
  maxFileSizeMessage,
  accept,
  level,
  multiple,
  maxCount,
  entityId,
  entityType,
  subEntityType,
  subEntityId,
  abbr = 'OTHER',
  documentTypeId,
  AttachmentItem,
  attachmentsHeader,
  onUpload,
  onUploadComplete,
  sortNewToOld = false,
  helpText = (
    <>
      <p className="ant-upload-text font-medium">Click or drag file to this area to upload</p>
      <p className="block ant-upload-text !text-xs !mt-[-2px]">Supported formats are png, pdf, jpg, xls, doc</p>
    </>
  ),
  ...props
}) {
  const documentTypes = useSelector(selectors.selectDocumentTypes);
  const documentType = useMemo(
    () => documentTypes.find((type) => (documentTypeId ? type.id === documentTypeId : type.title === abbr)),
    [abbr, documentTypeId, documentTypes],
  );
  const beforeUpload = (file) => {
    // You can remove this validation if you want
    const isLt10M = file.size / 1024 / 1024 < maxFileSize;
    if (!isLt10M) {
      message.error(maxFileSizeMessage);
      return false;
    }
    return true;
  };
  const handleChange = async (info) => {
    const uploaded = info.fileList?.reduce((prev, curr) => {
      return (prev && curr.status === 'done') || curr.s3Key;
    }, true);
    if (uploaded) {
      onUploadComplete?.(info.fileList, name);
      // form.validateFields([name]);
      // setKey(Date.now());
    }
  };
  const customRequest = async ({ onError, onSuccess, onProgress, file }) => {
    try {
      const image = await Storage.put(`${keyPrefix}/${file.name}`, file, {
        level,
        contentType: file.type,
        progressCallback({ loaded, total }) {
          // setProgress(((100 * loaded) / total).toFixed(2));
          onProgress(
            {
              percent: Math.round((loaded / total) * 100),
            },
            file,
          );
        },
      });
      onSuccess(image, file);
    } catch (error) {
      newrelic.noticeError(error);

      onError(error);
    }
  };

  const normFile = (e) => {
    const value = e.fileList.map((doc) => ({ ...doc, documentType }));
    return value;
  };
  const uploadProps = {
    // multiple,
    maxCount: maxCount ?? multiple === false ? 1 : undefined,
    onChange: handleChange,
    beforeUpload,
    customRequest: onUpload || customRequest,
    // previewFile: async (file) => {
    //   return file.name;
    // },
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: true,
      showRemoveIcon: true,
    },
    listType: 'picture',
    accept,
  };
  const validator = useCallback(
    async (rule, value) => {
      if (!value) return;
      const uploaded = value?.reduce((prev, curr) => {
        return prev && (curr.status === 'done' || curr.s3Key);
      }, true);
      if (!uploaded) {
        throw new Error(messages?.upload || 'Files are not uploaded yet!');
      }
    },
    [messages?.upload],
  );

  return (
    <Form.Item
      name={name}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      className={`${className} ${styles.upload}`}
      rules={[{ validator }, ...rules]}
      initialValue={[]}
    >
      <Uploader
        {...props}
        {...uploadProps}
        level={level}
        AttachmentItem={AttachmentItem}
        attachmentsHeader={attachmentsHeader}
        documentType={documentType}
        entityId={entityId}
        entityType={entityType}
        subEntityType={subEntityType}
        subEntityId={subEntityId}
        name={name}
        helpText={helpText}
        sortNewToOld={sortNewToOld}
      />
    </Form.Item>
  );
}

DragDropUpload.propTypes = {
  className: PropTypes.any,
  form: PropTypes.shape({
    validateFields: PropTypes.func,
  }),
  multiple: PropTypes.bool,
  keyPrefix: PropTypes.string,
  messages: PropTypes.shape({
    upload: PropTypes.string,
  }),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  rules: PropTypes.array,
  allowedFileMessage: PropTypes.string,
  maxFileSize: PropTypes.number,
  maxFileSizeMessage: PropTypes.string,
  accept: PropTypes.string,
  level: PropTypes.oneOf(['private', 'protected', 'public']),
  AttachmentItem: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  attachmentsHeader: PropTypes.any,
};
DragDropUpload.defaultProps = {
  rules: [],
  allowedFileMessage: 'Only Images, PDF and Word files are allowed.',
  accept:
    'image/png,image/jpg,image/jpeg,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  level: 'private',
  maxFileSize: 10,
  multiple: true,
  maxFileSizeMessage: 'File size must be less than 10MB.',
  AttachmentItem,
  attachmentsHeader: null,
};

function Uploader({
  id,
  name,
  level,
  fileList,
  documentType,
  entityId,
  entityType,
  subEntityType,
  subEntityId,
  AttachmentItem,
  attachmentsHeader,
  helpText,
  multiple,
  maxCount,
  sortNewToOld,
  ...rest
}) {
  const [visible, setVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [url, setUrl] = useState(null);
  const userInfo = useSelector(selectUser);
  const { snapshotSelector } = useContext(SnapshotContext);
  const snapshot = useSelector(snapshotSelector || (() => null));
  const value = useMemo(() => {
    let list = fileList?.map?.((i, index) => ({ ...i, type: i.mimeType, documentType, index })) || [];
    return sortNewToOld ? reverse(list) : list;
  }, [documentType, fileList, sortNewToOld]);

  const setPreview = useCallback((file, url) => {
    setUrl(url);
    setPreviewFile(file);
    setVisible(true);
  }, []);
  useEffect(() => {
    if ((previewFile?.mimeType || previewFile?.type) === 'application/pdf') {
      setTimeout(() => {
        PDFObject.embed(url, `#file-preview-${previewFile?.uid}`);
      }, 50);
    }
  }, [previewFile, previewFile?.mimeType, previewFile?.uid, url, visible]);
  const prevDocuments = getValue(snapshot, name, []);

  const itemRender = useCallback(
    (originNode, file, fileList, actions, index) => {
      return (
        <>
          {file.index === 0 && attachmentsHeader}
          <AttachmentItem
            {...file}
            type={file.type || file.mimeType}
            actions={actions}
            key={file.uid}
            file={file}
            setPreview={setPreview}
            className={classNames({
              'change-request-item': snapshot && prevDocuments.findIndex((doc) => doc?.id === file?.id) === -1,
            })}
          />
        </>
      );
    },
    [attachmentsHeader, prevDocuments, setPreview, snapshot],
  );
  return (
    <div>
      <Dragger
        {...rest}
        multiple // this multiple is to select multiple files in browse window
        onChange={async ({ file, fileList }) => {
          rest.onChange({
            file,
            fileList: fileList?.map((file) => ({
              ...uploadFileFieldsFormat(file, userInfo, documentType),
              entityId,
              entityType,
              subEntityId,
              subEntityType,
            })),
          });
        }}
        id={id}
        fileList={value}
        showUploadList={fileList?.length > 0}
        onDownload={async (e) => {
          downloadFile(e, level);
        }}
        previewFile={getBase64String}
        itemRender={itemRender}
        maxCount={maxCount}
      >
        <p className="ant-upload-drag-icon mb-3">
          <CloudUpload size={30} />
        </p>

        {helpText}
      </Dragger>

      <Modal className="h-screen" width={700} visible={visible} setVisible={setVisible}>
        {(previewFile?.mimeType || previewFile?.type) === 'application/pdf' ? (
          <div
            id={`file-preview-${previewFile.uid}`}
            style={{ height: 600 }}
            className={PDFObject.supportsPDFs ? '' : 'flex p-4 items-center justify-center'}
          />
        ) : url ? (
          <img className="w-full h-full" src={url} alt="Preview" height={500} />
        ) : null}
      </Modal>
    </div>
  );
}

Uploader.propTypes = {
  AttachmentItem: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  attachmentsHeader: PropTypes.any,
};

Uploader.defaultProps = {
  AttachmentItem,
  attachmentsHeader: null,
};
