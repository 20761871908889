import React, { useCallback } from 'react';
import { Button, Checkbox } from 'antd';
import classNames from 'classnames';
import { IoMdClose } from 'react-icons/io';
import { AiOutlineCheck } from 'react-icons/ai';
import { CgFormatSlash } from 'react-icons/cg';
import CollapsePanel from 'components/CollapsePanel';
import CheckPermissions from 'components/CheckPermissions';
import { CHECKLIST_TYPES, ROLES } from 'constants/index';

import styles from './ChecklistOptions.module.less';

export default function ChecklistOptions({ onChange, className, checklist }) {
  return (
    <div className={classNames(styles.checklist, className)}>
      {checklist.map((checklistItem, rootIndex) => {
        return (
          <div key={rootIndex} className={classNames('collapse-section', 'border-t-2 border-dashed border-gray-300')}>
            <CollapsePanel
              header={
                <div className="py-3 px-5 bg-white w-full flex flex-row justify-between items-center">
                  <div className="w-full justify-between flex items-center pr-8">
                    <span className="text-sm text-gray-500 font-medium uppercase align-bottom float-right ml-1">
                      {checklistItem.name}
                    </span>
                    <div onClick={(e) => e.stopPropagation()}>
                      <ChecklistOptionsGroup
                        rootIndex={rootIndex}
                        nestedIndex={0}
                        item={{
                          id: `section-${checklistItem?.items[0]?.item?.section?.id}`,
                          status: checklistItem?.items.some((i) => i.status === 'REJECTED')
                            ? 'REJECTED'
                            : checklistItem?.items.some((i) => i.status === 'APPROVED')
                            ? 'APPROVED'
                            : 'UNKNOWN',
                        }}
                        onChange={(rootIndex, _, i, status) => {
                          checklistItem?.items.forEach((item, nestedIndex) => {
                            onChange(rootIndex, nestedIndex, item, status);
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
              triggerClassName="right-1 top-3"
              testId={`expand-button-${rootIndex}`}
              defaultOpen={false}
            >
              {checklistItem?.items?.map((checklistNestedItem, nestedIndex) => {
                return (
                  <div key={nestedIndex} className="w-ful flex items-center mb-4 ml-3">
                    {(checklistNestedItem?.type === CHECKLIST_TYPES.PROVIDER ||
                      checklistNestedItem?.type === CHECKLIST_TYPES.FAMILY) && (
                      <CheckPermissions
                        requiredRoles={[
                          ROLES.PROVIDER_QC_COORDINATOR,
                          ROLES.PROVIDER_QC_MANAGER,
                          ROLES.FAMILY_QC_COORDINATOR,
                          ROLES.FAMILY_QC_MANAGER,
                        ]}
                      >
                        <Checkbox
                          data-testid={`checked-section-${rootIndex}-${nestedIndex}`}
                          onChange={() =>
                            onChange(
                              rootIndex,
                              nestedIndex,
                              checklistNestedItem,
                              checklistNestedItem.status,
                              !checklistNestedItem.qcApproved,
                            )
                          }
                          checked={checklistNestedItem.qcApproved}
                        />
                      </CheckPermissions>
                    )}
                    <div key={checklistNestedItem.id} className="flex items-center w-ful pl-2">
                      <ChecklistOptionsGroup
                        rootIndex={rootIndex}
                        nestedIndex={nestedIndex}
                        item={checklistNestedItem}
                        onChange={onChange}
                      />

                      <span className="ml-2 text-sm overflow-ellipsis">{checklistNestedItem?.item?.title}</span>
                    </div>
                  </div>
                );
              })}
            </CollapsePanel>
          </div>
        );
      })}
    </div>
  );
}

const ChecklistOptionsGroup = ({ rootIndex, nestedIndex, item, onChange }) => {
  const onButtonClick = useCallback(
    (status) => {
      onChange(rootIndex, nestedIndex, item, status);
    },
    [item, nestedIndex, onChange, rootIndex],
  );
  return (
    <div className="flex space-x-1 btn-group">
      <Button
        onClick={() => onButtonClick('APPROVED')}
        className={classNames('icon-btn APPROVED', { checked: item.status === 'APPROVED' })}
        icon={<AiOutlineCheck size={14} />}
        data-testid={`checklist-btn-${item.id}-approved`}
      />

      <Button
        onClick={() => onButtonClick('REJECTED')}
        className={classNames('icon-btn REJECTED', { checked: item.status === 'REJECTED' })}
        icon={<IoMdClose size={14} />}
        data-testid={`checklist-btn-${item.id}-rejected`}
      />

      <Button
        onClick={() => onButtonClick('UNKNOWN')}
        className={classNames('icon-btn UNKNOWN', { checked: item.status === 'UNKNOWN' })}
        icon={<CgFormatSlash className="transform" />}
        data-testid={`checklist-btn-${item.id}-unknown`}
      />
    </div>
  );
};
