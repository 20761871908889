import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import classNames from 'classnames';
import { Close } from '@carbon/icons-react';

export default function GccModal({
  children,
  className,
  visible: open,
  setVisible,
  getContainer,
  width,
  destroyOnClose,
  closable,
  maskClosable,
  forceRender,
  ...rest
}) {
  return (
    <Modal
      className={classNames(className)}
      open={open}
      onCancel={() => {
        setVisible(false);
      }}
      centered
      closable={closable}
      closeIcon={<Close className="w-5 h-5 mt-[1px]" />}
      footer={null}
      getContainer={getContainer}
      width={width}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      forceRender={forceRender}
      {...rest}
    >
      {children}
    </Modal>
  );
}

GccModal.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  destroyOnClose: PropTypes.bool,
  getContainer: PropTypes.any,
  setVisible: PropTypes.func,
  visible: PropTypes.bool,
  closable: PropTypes.bool,
  maskClosable: PropTypes.bool,
  forceRender: PropTypes.bool,
  width: PropTypes.any,
};

GccModal.defaultProps = {
  destroyOnClose: true,
  closable: true,
  maskClosable: true,
  forceRender: false,
};
