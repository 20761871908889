import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';
import commonService from 'services/common.service';

export default function usePaginatedApi({ url, commonParams, returnCurrentPage }, deps) {
  const [data, setData] = useState({ data: [], dataMap: {}, page: 0, size: 10 });
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    async (params) => {
      setLoading(true);
      try {
        const items = await commonService.getPaginatedData({ url, params });
        setData((v) => {
          const state = { ...v };
          if (state.page === 0 || returnCurrentPage) {
            state.dataMap = {};
            state.data = [];
          }
          items?.content?.forEach((element) => {
            if (!state.dataMap[element.id]) {
              state.dataMap[element.id] = true;
              state.data.push(element);
            }
          });
          return {
            ...items,
            data: [...state.data],
            dataMap: { ...state.dataMap },
          };
        });
        return items;
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to get data.', 5);
        return {};
      } finally {
        setLoading(false);
      }
    },
    [returnCurrentPage, url],
  );

  useEffect(() => {
    fetch({ page: 0, size: 10, ...commonParams });
  }, [commonParams, fetch]);

  const fetchMore = useCallback(
    (params) => {
      const queryParams = { page: data?.number + 1, size: 10, ...commonParams, ...params };
      fetch(queryParams);
      setData((v) => ({ ...v, ...queryParams }));
    },
    [commonParams, data?.number, fetch],
  );
  return {
    ...data,
    hasMore: !data.last,
    page: data?.number || 0,
    totalCount: data?.totalElements || 0,
    loading,
    data: data.data,
    fetch,
    fetchMore,
    url,
  };
}
