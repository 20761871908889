import React, { useEffect, useCallback, useState } from 'react';
import Checklist from './Checklist';
import useAsyncFn from 'hooks/useAsyncFn';
import { useDispatch } from 'react-redux';
import { actions } from 'features/family';
import { CHECKLIST_TYPES } from 'constants/index';
import familyService from 'services/family.service';
import checklistService from 'services/checklist.service';
import ChecklistModal from 'components/Modals/ChecklistModal';

export default function FamilyChecklist({ id, application, isFormSubmitting }) {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const [statusState, changeApplicationStatus] = useAsyncFn(
    async (newStatus) => {
      const status = await familyService.setApplicationStatus(id, newStatus.id);
      dispatch(actions.setApplication({ status }));
      return status.status;
    },
    [],
    { loading: false, value: application?.status?.status },
  );

  const [notesState, getNote] = useAsyncFn(async () => {
    return await familyService.getDraftNote(application.id);
  }, [application]);
  const saveNote = useCallback(
    async (values) => {
      return await familyService.saveNote(application?.id, values);
    },
    [application?.id],
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (application?.id) {
      getNote(application?.id);
    }
  }, [application?.id, getNote]);
  return (
    <>
      <Checklist
        id={id}
        saveChecklist={(id, items) => checklistService.updateChecklist(id, CHECKLIST_TYPES.FAMILY, items)}
        isFormSubmitting={isFormSubmitting}
        notesState={notesState}
        getNote={getNote}
        saveNote={saveNote}
        statusState={statusState}
        updateStatus={changeApplicationStatus}
        statusOptionsApiUrl={`/applications/${id}/application-status-types/internal`}
        optionsApiPrefix="/families"
        type={CHECKLIST_TYPES.FAMILY}
        checklistTitle="Family Checklist"
        onGenerateChecklist={() => setVisible(true)}
      />

      <ChecklistModal
        setVisible={setVisible}
        visible={visible}
        id={application?.id}
        title="generate checklist email"
        type={CHECKLIST_TYPES.FAMILY}
        entity={application}
      />
    </>
  );
}
