import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Geosuggest from 'react-geosuggest';
import parseGooglePlace from 'parse-google-place';
import styles from './SearchAddress.module.less';
import { Close } from '@carbon/icons-react';

const addressParser = require('parse-address');

function SearchAddress(
  { id, value, onChange, onSelect, className, placeholder = '', emptyValue, disabled, country, readOnly = false },
  ref,
) {
  const localRef = useRef(null);
  const inputRef = ref || localRef;

  return (
    <div className={classNames(styles.searchAddress, className)}>
      <div className="geosuggest-container">
        <Geosuggest
          id={id}
          data-testid="addressLine1"
          inputType="search"
          inputClassName="ant-input"
          placeholder={placeholder}
          // autoComplete="off"
          autoComplete="nope"
          disabled={disabled}
          // types={['street_address', 'postal_code']}
          onBlur={() => {
            if (
              inputRef.current?.state?.userInput &&
              inputRef.current.state.suggests
                .map((sugg) => sugg.description)
                .indexOf(inputRef.current?.state?.userInput) === -1
            ) {
              inputRef.current.clear();
            }
          }}
          initialValue={value}
          readOnly={readOnly}
          ref={inputRef}
          country={country}
          onChange={(value) => {
            if (value === '') {
              inputRef.current?.clear();
            }
            onChange(value);
          }}
          onSuggestSelect={(selected) => {
            if (selected) {
              const place = parseGooglePlace(selected?.gmaps);

              let state = place.stateShort;
              if (place.countryLong === 'American Samoa') {
                state = 'AS';
              } else if (place.countryLong === 'Guam') {
                state = 'GU';
              } else if (place.countryLong === 'Northern Mariana Islands') {
                state = 'MP';
              } else if (place.countryLong === 'Puerto Rico') {
                state = 'PR';
              } else if (place.countryLong === 'U.S. Virgin Islands') {
                state = 'VI';
              }

              const _parsedAddress = addressParser.parseLocation(selected.description);
              if (!place.streetNumber && _parsedAddress.number) {
                place.streetNumber = _parsedAddress.number;
                place.address = `${place.streetNumber} ${place.streetName}`;
              }

              let street = [place.streetNumber || '', place.streetName || ''].filter(Boolean).join(' ');
              if (!street) {
                street = selected.description.split?.(',')?.[0];
              }

              onSelect({
                country: place.countryShort,
                state,
                city: place.city,
                zipCode: place.zipCode,
                county: place.county,
                street,
                description: selected.description,
                placeId: selected.placeId,
                ...selected.location,
              });
            } else {
              onSelect(emptyValue);
            }
          }}
          renderSuggestItem={(place) => {
            const { placeId, label } = place;
            return (
              <p key={placeId} className="label">
                {label}
              </p>
            );
          }}
        />

        {inputRef?.current?.state?.userInput && (
          <Close
            className="clear-icon"
            onClick={() => {
              inputRef.current.clear();
              onChange(emptyValue);
            }}
          />
        )}
      </div>
    </div>
  );
}
const SearchField = React.forwardRef(SearchAddress);

SearchField.propTypes = {
  className: PropTypes.any,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  emptyValue: PropTypes.any,
  value: PropTypes.string,
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
};
SearchField.defaultProps = {
  emptyValue: undefined,
  disabled: false,
  country: ['us'],
  onSelect: () => {},
  onChange: () => {},
};

export default SearchField;
