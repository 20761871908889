import React, { useState, useCallback, useEffect } from 'react';
import { Button, message, Spin } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import GccModal from 'components/Modal';
import commonService from 'services/common.service';
import RowSelectionTable from 'components/RowSelectionTable';
import SponsorInstallationTable from 'containers/Family/AddApplication/Steps/Sponsor/Tabs/SponsorInstallationTable';
import Select from 'components/Select';

export default function SponsorInstallationModal({
  id,
  visible,
  setVisible,
  buttonClassName = 'w-full',
  programSponsorId,
  updateInstallation,
  showSelect = false,
  value,
  onChange,
  defaultState,
  disableSelect,
  readOnly = false,
}) {
  const [selectedInstallation, setSelectedInstallation] = useState(null);
  const [results, setResults] = useState([]);

  const { loading, result: installations = [] } = useAsync(
    async (id, installationId) => {
      try {
        if (id) {
          const data = await commonService.getInstallations(id);
          const index = data.findIndex((v) => v.id === installationId);
          if (index > -1) {
            setResults([data[index], ...data.slice(0, index), ...data.slice(index + 1)]);
          } else {
            setResults(data);
          }
          return data;
        } else {
          return [];
        }
      } catch (error) {
        message.error('Error getting search results', 5);
        newrelic.noticeError(error);
      }
    },
    [programSponsorId, value],
  );

  const setInstallation = useCallback((installations, militaryInstallationId) => {
    if (militaryInstallationId && installations?.length > 0) {
      const installation = installations.find((item) => {
        return item.id === militaryInstallationId;
      });
      setSelectedInstallation(installation);
    }
  }, []);
  useEffect(() => {
    setInstallation(installations, value);
  }, [setInstallation, installations, value]);

  const { loading: updatingSponsorInstallation, execute: onSubmit } = useAsyncCallback(
    async (values) => {
      try {
        if (typeof updateInstallation === 'function') {
          await updateInstallation(selectedInstallation);
          message.success(`Installation updated successfully.`, 5);
        }
        onChange?.(selectedInstallation?.id || null, selectedInstallation);
        setVisible(false);
        setResults(installations);
      } catch (error) {
        message.error(`Unable to update installation.`, 5);
      }
    },
    [selectedInstallation, onChange],
  );

  const onSearch = useCallback(
    ({ searchKeyWord, state, city }) => {
      if (!searchKeyWord && !state && !city) return setResults(installations);
      const filteredResult = installations.filter((item) => {
        return (
          (searchKeyWord && item?.name?.toLowerCase().includes(searchKeyWord?.toLowerCase() || '')) ||
          (state && item?.address?.state?.toLowerCase() === state?.toLowerCase()) ||
          (city && item?.address?.city?.toLowerCase().includes(city?.toLowerCase() || ''))
        );
      });
      setResults(filteredResult);
    },
    [installations],
  );

  useEffect(() => {
    if (defaultState && visible) {
      onSearch({ state: defaultState });
    }
  }, [defaultState, visible, onSearch]);

  return (
    <>
      {showSelect ? (
        <Select
          className={buttonClassName}
          options={selectedInstallation ? [selectedInstallation] : []}
          getOptionLabel={(opt) => opt.name}
          value={selectedInstallation}
          isClearable={false}
          onClick={() => setVisible(true)}
          onMenuOpen={() => setVisible(true)}
          onKeyDown={() => setVisible(true)}
          disabled={disableSelect}
          id={id}
          readOnly={readOnly}
        />
      ) : selectedInstallation ? (
        <SponsorInstallationTable installation={selectedInstallation} setVisible={setVisible} />
      ) : (
        <Button className={buttonClassName} onClick={readOnly ? null : () => setVisible(true)}>
          Installation
        </Button>
      )}

      <GccModal
        visible={visible}
        setVisible={(visible) => {
          setVisible(visible);
          if (!visible) {
            setInstallation(installations, value);
            setResults(installations);
          }
        }}
        width={950}
        closable={false}
      >
        <Spin spinning={updatingSponsorInstallation || loading}>
          <RowSelectionTable
            title="Installation"
            results={results}
            selected={selectedInstallation}
            onSearch={onSearch}
            onSubmit={onSubmit}
            totalCount={results?.length}
            setSelected={setSelectedInstallation}
            onCancel={() => {
              setVisible(false);
              setInstallation(installations, value);
              setResults(installations);
            }}
            defaultState={defaultState}
          />
        </Spin>
      </GccModal>
    </>
  );
}
