import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input } from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import Form from 'components/Form';
import Table from 'components/Table';
import Section from 'components/Section';
import { AsyncSelect } from 'components/Select';
import DocumentsTable from 'components/DocumentsTable';
import ScheduleTable from 'containers/Family/AddApplication/Steps/ChildrenInfo/ScheduleTable';
import { formatDate, formatDates, getCompletedAddress, getFormattedPhoneNumber, getFullName } from 'utils';
import useStartEndDates from 'hooks/useStartEndDates';
import { ENTITY_TYPES } from 'constants/index';
import { familyService } from 'services';
import { useAsyncCallback } from 'react-async-hook';
import dayjs from 'dayjs';

export default function CertificateSummary({
  id,
  householdId,
  certificateId,
  application,
  certificate,
  setCertificate,
  prev,
  next,
}) {
  const [form] = Form.useForm();
  const history = useHistory();
  const allSchedules = useMemo(() => {
    return (
      application?.children?.reduce?.(
        (prev, child) => prev.concat(child.schedules?.map?.((sch) => ({ ...sch, child })) || []),
        [],
      ) || []
    );
  }, [application?.children]);
  const children = useMemo(
    () =>
      (certificate?.schedules || []).map?.((sch) => allSchedules.find((s) => s.id === sch.id)?.child).filter(Boolean),
    [allSchedules, certificate?.schedules],
  );
  const applicant = useMemo(() => {
    const adult = (application.adults || []).find((adult) => adult.id === application?.applicant?.id);
    return { ...application?.applicant, ...adult };
  }, [application.adults, application?.applicant]);
  const additionalParents = useMemo(() => {
    return (application?.additionalParents || [])
      .map((parent) => application.adults.find((item) => item.id === parent.id))
      .filter(Boolean);
  }, [application?.additionalParents, application.adults]);

  const [startDate, endDate] = useStartEndDates({
    form,
    startDateFieldProps: { label: 'Certificate Start Date' },
    endDateFieldProps: {
      label: 'Certificate End Date',
    },
  });

  const { execute: onSubmit, loading: submitting } = useAsyncCallback(
    async (values) => {
      try {
        let payload = {
          ...values,
          application: { id: application.id },
          applicationId: application.id,
        };
        payload = formatDates(payload, [['startDate'], ['endDate']]);
        const cert = await familyService.updateCertificate(certificateId, payload);
        setCertificate({ result: cert });
        history.push(next);
      } catch (error) {}
    },
    [setCertificate],
  );

  useEffect(() => {
    if (certificate?.id) {
      form.setFieldsValue({
        id: certificateId,
        startDate: certificate?.startDate ? dayjs(certificate.startDate) : certificate?.startDate,
        endDate: certificate?.endDate ? dayjs(certificate.endDate) : certificate?.endDate,
      });
    }
  }, [certificate?.endDate, certificate?.id, certificate?.startDate, certificateId, form]);
  return (
    <Form form={form} className="pb-6" onFinish={onSubmit}>
      <Section
        heading="Sponsor Information"
        headerContainerClassName="!px-4 py-4"
        triggerClassName="!top-3 !right-3"
        contentClassName="px-6"
      >
        <ParentInfo id={id} data={[applicant]} subEntityType={ENTITY_TYPES.SPONSOR} />
      </Section>
      {additionalParents.length > 0 && (
        <Section
          heading="Spouse/Second Parent Information"
          headerContainerClassName="!px-4 py-4"
          triggerClassName="!top-3 !right-3"
          contentClassName="px-6"
        >
          <ParentInfo
            className="pb-6"
            id={id}
            data={additionalParents}
            subEntityType={ENTITY_TYPES.PARENT}
            relationship="Parent"
          />
        </Section>
      )}
      <Section
        heading="Child(ren) Information"
        headerContainerClassName="!px-4 py-4"
        triggerClassName="!top-3 !right-3"
        contentClassName="px-6 pb-6"
      >
        <ChildrenInfo
          className="pb-6"
          id={id}
          data={children || []}
          subEntityType={ENTITY_TYPES.CHILD}
          relationship="Child"
          certificate={certificate}
        />
      </Section>
      <Section
        heading="Family Information"
        headerContainerClassName="!px-4 py-4"
        triggerClassName="!top-3 !right-3"
        contentClassName="px-6"
      >
        <DocumentsTable
          entityId={id}
          entityType={ENTITY_TYPES.FAMILY}
          pagination={{ pageSize: 5 }}
          readOnly
          showTypeFallback
        />
      </Section>
      <Section
        heading="Certificate Information"
        headerContainerClassName="!px-4 py-4"
        triggerClassName="!top-3 !right-3"
        contentClassName="px-6"
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xxl:grid-cols-4 gap-x-5">
          {startDate}
          <Form.Item
            name="certificateType"
            label="Creation Reason"
            // rules={[{ required: true, message: 'Creation Reason is required' }]}
          >
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/certificates/types"
              placeholder="Select"
              ariaLabel="Select Certificate Type"
              getOptionLabel={(option) => option.description}
            />
          </Form.Item>
          {endDate}
          <Form.Item
            name="expirationReason"
            label="Expiration Reason"
            // rules={[{ required: true, message: 'Expiration Reason is required' }]}
          >
            <AsyncSelect
              getOptions={familyService.getOptions}
              apiPrefix=""
              optionsApiUrl="/certificates/types"
              placeholder="Select"
              ariaLabel="Select Certificate Type"
              getOptionLabel={(option) => option.description}
            />
          </Form.Item>
          <Form.Item name="serviceType" label="Service Type">
            <Input />
          </Form.Item>
        </div>
      </Section>
      <div className="px-6 pt-6 actions w-full flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0 justify-end">
        <Button onClick={() => history.push(`/families/${householdId}/applications/${id}`)} disabled={submitting}>
          Cancel
        </Button>
        <Button onClick={() => history.push(prev)} disabled={submitting}>
          Back
        </Button>
        <Button type="primary" htmlType="submit" disabled={submitting} loading={submitting}>
          Next
        </Button>
      </div>
    </Form>
  );
}

function ParentInfo({ className, id, data, relationship, subEntityType = ENTITY_TYPES.SPONSOR }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const allColumns = useMemo(
    () => [
      {
        title: 'Name',
        render: (record) => getFullName(record),
      },
      {
        title: 'Relationship',
        render: (record) => relationship,
      },
      {
        title: 'Address',
        render: (record) => (record.homeAddress ? getCompletedAddress(record.homeAddress) : 'N/A'),
      },
      {
        title: 'Phone Number',
        render: (record) => (record.phone ? getFormattedPhoneNumber(record.phone) : 'N/A'),
      },
      {
        title: 'Email',
        render: (record) => record.email || 'N/A',
      },
      {
        title: 'Actions',
        width: 90,
        render: (record) => {
          return (
            <div className="flex justify-center">
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                  } else {
                    setExpandedRows([record.id]);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                className="icon-btn"
                aria-label={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
                data-testid={`nested-collapse-button-${record.id}`}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            </div>
          );
        },
      },
    ],
    [expandedRows, relationship],
  );
  const expandable = {
    expandIcon: () => null,
    rowExpandable: () => true,
    expandedRowKeys: expandedRows,
    expandedRowRender: (record) => {
      return (
        <div className="border p-5">
          <h3 className="text-md text-primary uppercase font-medium">Documents</h3>
          <DocumentsTable
            entityId={id}
            entityType={ENTITY_TYPES.FAMILY}
            subEntityId={record?.id}
            subEntityType={subEntityType}
            readOnly
            showTypeFallback
          />
        </div>
      );
    },
  };
  return (
    <div
      className={classNames(
        '[&_.ant-table]:!min-h-[100px] [&_.ant-table-expanded-row_>_.ant-table-cell]:!px-0',
        className,
      )}
    >
      <Table
        allColumns={allColumns}
        data={data}
        showColSeparator={false}
        expandable={expandable}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
}

function ChildrenInfo({ id, application, data, certificate }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const allColumns = useMemo(
    () => [
      {
        title: 'Name',
        render: (record) => getFullName(record),
      },
      {
        title: 'Relationship',
        render: (record) => 'Child',
      },
      {
        title: 'Date of Birth',
        render: (record) => formatDate(record.dob),
      },
      {
        title: 'Address',
        render: (record) => (record.homeAddress ? getCompletedAddress(record.homeAddress) : 'N/A'),
      },
      {
        title: 'Actions',
        width: 90,
        render: (record) => {
          return (
            <div className="flex justify-center">
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                  } else {
                    setExpandedRows([record.id]);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                className="icon-btn"
                aria-label={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
                data-testid={`nested-collapse-button-${record.id}`}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            </div>
          );
        },
      },
    ],
    [expandedRows],
  );
  const expandable = {
    expandIcon: () => null,
    rowExpandable: () => true,
    expandedRowKeys: expandedRows,
    expandedRowRender: (record) => {
      return (
        <div className="border p-5">
          <h3 className="text-md text-primary uppercase font-medium mb-4">Schedule of Care & Provider</h3>
          <ScheduleTable applicationId={id} childSchedules={certificate?.schedules || []} readOnly />
          <h3 className="text-md text-primary uppercase font-medium my-4">Documents</h3>
          <DocumentsTable
            entityId={id}
            entityType={ENTITY_TYPES.FAMILY}
            subEntityId={record?.id}
            subEntityType={ENTITY_TYPES.CHILD}
            readOnly
            showTypeFallback
          />
        </div>
      );
    },
  };
  return (
    <div>
      <div className={classNames('[&_.ant-table]:!min-h-[100px] [&_.ant-table-expanded-row_>_.ant-table-cell]:!px-0')}>
        <Table
          allColumns={allColumns}
          data={data}
          showColSeparator={false}
          expandable={expandable}
          rowKey="id"
          pagination={false}
        />
      </div>
    </div>
  );
}
