import { createSelector, createSlice, createAction } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { LOAD } from 'redux-storage';
import { call, put, all, takeEvery } from 'redux-saga/effects';
import { AUTH_STATES, RESET_ALL, DASHBOARD_TYPES } from 'constants/index';
import { cancelSource, isFamilyRole, message } from 'utils';
import { actions as activityActions } from 'features/activity';
import { actions as dashboardActions } from 'features/dashboard';

export const logout = {
  pending: createAction('auth/logout/pending'),
  fulfilled: createAction('auth/logout/fulfilled'),
  rejected: createAction('auth/logout/rejected'),
};

const initialState = {
  authState: AUTH_STATES.SIGN_IN,
  rememberMe: false,
  user: {},
  organization: null,
  activeRole: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },
    resetState: (state) => {
      return { ...initialState, loaded: state.loaded };
    },
    setAuthState: (state, { payload }) => {
      state.authState = payload;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    updateUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
    setOrganization: (state, { payload }) => {
      state.organization = payload;
    },
    setActiveRole: (state, { payload }) => {
      state.activeRole = payload;
    },
  },
  extraReducers: {
    [RESET_ALL]: (state) => {
      return { ...initialState, loaded: state.loaded };
    },
    [LOAD]: (state) => {
      state.loaded = true;
    },
  },
});

export const selectAuthState = createSelector(
  (state) => state.auth,
  (authState) => authState?.authState,
);
export const selectAuthLoaded = createSelector(
  (state) => state.auth,
  (authState) => authState?.loaded,
);
export const selectUser = createSelector(
  (state) => state.auth,
  (authState) => authState.user,
);
export const selectRoles = createSelector(selectUser, (user) => user?.groups || []);
export const selectActiveRole = createSelector(
  (state) => state.auth,
  (authState) => {
    return authState?.activeRole || authState?.user?.groups?.[0];
  },
);
export const selectOrganization = createSelector(
  (state) => state.auth,
  (authState) => authState.organization,
);

const reducer = authSlice.reducer;
const actions = { ...authSlice.actions, logout };
const selectors = {
  selectAuthState,
  selectUser,
  selectRoles,
  selectOrganization,
  selectActiveRole,
  selectAuthLoaded,
};
async function logoutApi() {
  await Auth.signOut();
}
export function* logoutSaga() {
  try {
    yield call(logoutApi);
    yield put(logout.fulfilled());
    yield put({ type: RESET_ALL });
    yield put(activityActions.stop());
    localStorage.removeItem('persist:root');
    cancelSource.cancel('CANCELLED');
    // message.info('You are logged out.');
  } catch (error) {
    newrelic.noticeError(error);
    yield put(logout.rejected(error));
    message.error('Something went wrong. Try later.');
  }
}
export function* setActiveRoleSaga(action) {
  const dashboardType = isFamilyRole(action.payload) ? DASHBOARD_TYPES.FAMILIES : DASHBOARD_TYPES.PROVIDERS;
  yield put(dashboardActions.setDashboardType(dashboardType));
}

function* sagas() {
  yield all([takeEvery(logout.pending, logoutSaga), takeEvery(actions.setActiveRole, setActiveRoleSaga)]);
}

export { reducer, actions, selectors, sagas, initialState };
export default authSlice;
