import React, { memo } from 'react';
import { Select } from 'antd';

function AntdSelect({ id, className, ...props }) {
  return (
    <div className={className} id={`select-container-${id}`}>
      <Select {...props} id={id} />
    </div>
  );
}

export default memo(AntdSelect);
