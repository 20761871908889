import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Geosuggest from 'react-geosuggest';
import { SiGooglemaps } from 'react-icons/si';
import { MdClose } from 'react-icons/md';

import styles from './SearchPlaces.module.less';

function SearchPlaces({ id, className, googleMaps, onSelect, initialValue, placeholder, disabled }, ref) {
  const onSuggestSelect = useCallback(
    (place) => {
      if (place) {
        onSelect({ ...place.location, address: place.label });
      }
    },
    [onSelect],
  );

  if (!googleMaps) return null;

  return (
    <div className={classNames(styles.searchPlaces, className)}>
      <div className="geosuggest-contaner">
        <SiGooglemaps className="input-prefix" />
        <Geosuggest
          id={id}
          inputType="search"
          placeholder={placeholder}
          autoComplete="off"
          ref={ref}
          googleMaps={googleMaps}
          country="us"
          initialValue={initialValue}
          onSuggestSelect={onSuggestSelect}
          renderSuggestItem={(place) => {
            const { placeId, label } = place;
            return (
              <div key={placeId}>
                <p className="label">{label}</p>
              </div>
            );
          }}
          disabled={disabled}
        />
        <MdClose
          className="clear-icon"
          onClick={() => {
            onSelect(null);
          }}
        />
      </div>
    </div>
  );
}
const AutoCompletePlaces = React.forwardRef(SearchPlaces);

SearchPlaces.propTypes = {
  className: PropTypes.string,
  googleMaps: PropTypes.any,
  onSelect: PropTypes.func,
  fixtures: PropTypes.array,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  picker: PropTypes.shape({
    current: PropTypes.any,
  }),
};

SearchPlaces.defaultProps = {
  className: '',
  onSelect: () => {},
  initialValue: '',
  placeholder: 'Search location...',
};

export default AutoCompletePlaces;
