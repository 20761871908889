import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useAsync } from 'react-async-hook';
import { b64toBlob, getBase64String } from 'utils';
import filesService from 'services/files.service';

export default function DocumentLink({ className, documents, setVisible, setItem, record, index }) {
  const [document] = documents;
  const { result: url } = useAsync(async () => {
    let blob;
    if (document.id) {
      blob = await filesService.getS3File(document);
    } else {
      blob = await getBase64String(document.originFileObj);
      blob = await b64toBlob(blob);
    }
    return URL.createObjectURL(blob);
  }, [document.id]);
  const link = (
    <a
      href={url}
      rel="noreferrer"
      className={classNames('underline block max-w-[230px] truncate overflow-ellipsis', className)}
      download={document.name}
      title={document.name}
    >
      {document.name}
    </a>
  );
  if (documents.length > 1) {
    return (
      <div className="flex items-baseline">
        {link}{' '}
        <Button
          className="icon-btn view-documents-btn ml-2"
          onClick={() => {
            setItem({ ...record, index });
            setVisible(true);
          }}
          style={{ width: 'auto' }}
          icon={<span>+{documents.length - 1}</span>}
          aria-label="View Documents"
        />
      </div>
    );
  }
  return link;
}
