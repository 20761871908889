import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useAsync } from 'react-async-hook';
import { Button, Col, Divider, Row, Skeleton } from 'antd';

import Form from 'components/Form';
import DocumentLink from 'components/DocumentLink';
import DragDropUpload from 'components/DragDropUpload';
import { formatDate, getCompletedAddress, getFullName, getValue } from 'utils';
import { ENTITY_TYPES } from 'constants/index';
import useStartEndDates from 'hooks/useStartEndDates';
import useChangeRequestClassName from 'hooks/useChangeRequestClassName';
import useEntityGroup, { GetEntityListData } from 'hooks/useEntityGroup';
import providerService from 'services/providers.service';
import Table from 'components/Table';
import { familyService } from 'services';
import ColumnTitle from 'components/ColumnTitle';

export default function AttendanceSheetsContainer({ match: { params }, className }) {
  const { id, childId } = params;
  const getData = useCallback(
    async (params) => {
      return await providerService.getAttendanceSheets(id, childId, params);
    },
    [childId, id],
  );

  return (
    <div className="pt-6">
      <h1 className="text-black px-6">Attendance Sheets</h1>
      <Form>
        <GetEntityListData name="attendanceSheets" getData={getData} className="mb-0">
          <AttendanceSheets id={id} childId={childId} />
        </GetEntityListData>
      </Form>
    </div>
  );
}

export function AttendanceSheets({ value: items = [], onChange, id, childId, readOnly, className, fetchData }) {
  const onAddItem = useCallback(
    async (data) => providerService.addUpdateAttendanceSheet(id, childId, data),
    [childId, id],
  );
  const onDeleteItem = useCallback(
    async (record) => providerService.deleteAttendanceSheet(id, childId, record.id),
    [childId, id],
  );
  const [rowSpanMap, setRowSpanMap] = useState({});
  const [monthIndexMap, setMonthIndexMap] = useState({});
  useEffect(() => {
    const indexMap = {};
    const monthCounts = items.reduce((prev, curr, index) => {
      const month = dayjs(curr.submittedDate).format('MMM YYYY');
      if (indexMap[month] === undefined) {
        indexMap[month] = index;
      }
      prev[month] = (prev[month] || 0) + 1;
      return prev;
    }, {});
    setRowSpanMap(monthCounts);
    setMonthIndexMap(indexMap);
  }, [items]);

  const getAllColumns = useCallback(
    ({ addItem, deleteItem, setItem, setVisible, documents, index }) => {
      const allColumns = [
        {
          title: (props) => (
            <ColumnTitle {...props} title="Month" dataIndex={'submittedDate'} colKey="submittedDate" showSorting />
          ),
          headerText: 'Month',
          dataIndex: 'submittedDate',
          key: 'submittedDate',
          sorter: true, //getDateSorter('submittedDate'),
          width: 140,
          render: (date) => {
            const month = dayjs(date).format('MMM YYYY');
            return <b>{month}</b>;
          },
          onCell: (record, index) => {
            const month = dayjs(record.submittedDate).format('MMM YYYY');
            // rowSpanMap[month] = (rowSpanMap[month] || 0) + 1;
            return {
              colSpan: monthIndexMap[month] === index ? 1 : 0,
              rowSpan: monthIndexMap[month] === index ? rowSpanMap[month] : 0,
            };
          },
          fixed: 'left',
        },
        {
          title: <span className="action-header">Child Name</span>,
          headerText: 'Child Name',
          dataIndex: 'childName',
          key: 'childName',
          width: 180,
          render: (_, record) => getFullName(record.child),
        },
        {
          title: <span className="action-header">Certificate ID</span>,
          headerText: 'Certificate Id',
          dataIndex: ['certificate', 'id'],
          key: 'certificateId',
        },
        {
          title: <span className="action-header">Status</span>,
          headerText: 'Status',
          dataIndex: ['status', 'title'],
          key: 'status',
        },
        {
          title: <span className="action-header">Date Submitted</span>,
          headerText: 'Date Submitted',
          dataIndex: ['submittedDate'],
          key: 'submittedDate',
          render: (date) => formatDate(date),
        },
        {
          title: <span className="action-header">Attached Document</span>,
          headerText: 'document',
          dataIndex: 'document',
          key: 'document',
          width: 250,
          ellipsis: true,
          render: (value, record) => {
            const itemDocuments = (documents || []).filter((doc) => doc.subEntityId === record.id);
            return itemDocuments.length > 0 ? (
              <DocumentLink
                documents={itemDocuments}
                setVisible={setVisible}
                setItem={setItem}
                record={record}
                index={index}
              />
            ) : (
              'NA'
            );
          },
        },
      ];
      return allColumns;
    },
    [monthIndexMap, rowSpanMap],
  );
  const { loading: childInfoLoading, result: childInfo = {} } = useAsync(
    async (id) => {
      return await familyService.getChildInfo(id);
    },
    [childId],
  );
  const { loading: careLocationLoading, result: careLocation = {} } = useAsync(
    async (id) => {
      return await providerService.getCenterById(id);
    },
    [id],
  );
  const keys = useMemo(() => ['childName', 'certificateId', 'status', 'submittedDate', 'document'], []);
  const rowClassName = useChangeRequestClassName({ keys, snapshotKey: ['attendanceSheets'] });
  const getFormFields = useCallback(
    ({ values, setVisible, items, form, item, index, addUpdateLoading }) => {
      const getSnapshotValue = (snapshot, name) => {
        return getValue(
          snapshot.attendanceSheets.find((v) => v.id === item?.id),
          name,
        );
      };
      return (
        <>
          <h3 className="section-title">Attendance Sheet Information</h3>
          <Row gutter={[20, 0]} className="form-row">
            <StartEndDates form={form} getSnapshotValue={getSnapshotValue} />
          </Row>
          <Form.Item name="certificates">
            <CertificateSelection child={{ id: 123 }} />
          </Form.Item>
          <Form.Item label="Attach attendance sheet for this child">
            <DragDropUpload
              name={['documents', 'OTHER']}
              entityId={id}
              entityType={ENTITY_TYPES.ATTENDANCE_SHEET}
              // subEntityType="EligibilityDates"
              abbr="OTHER"
              keyPrefix="forms/attendance-sheets"
            />
          </Form.Item>
          <Divider className="mt-0" />
          <div className="actions flex mt-5">
            <Button onClick={() => setVisible(false)} data-testid="cancel-attendanceSheet-btn">
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              data-testid="save-attendanceSheet-btn"
              disabled={addUpdateLoading}
              loading={addUpdateLoading}
            >
              Save
            </Button>
          </div>
        </>
      );
    },
    [id],
  );

  const { section } = useEntityGroup({
    items,
    onChange,
    onAddItem,
    onDeleteItem,
    entity: { id },
    getAllColumns,
    getFormFields,
    readOnly,
    entityTitle: 'Attendance sheet',
    tableProps: {
      rowClassName,
      onChange: async (pagination, filters, sorter) => {
        let params = {
          ...pagination,
        };
        if (sorter) {
          const isNestedValues = Array.isArray(sorter.field);
          const fieldSorter = isNestedValues
            ? sorter.field.reduce((total, current) => `${total}.${current}`)
            : sorter.field;

          if (sorter?.sort) {
            params.sort = sorter.sort;
          } else if (sorter.field && !params.sort) {
            params.sort = `${fieldSorter},${sorter.order === 'ascend' ? 'asc' : 'desc'}`;
          }
        }
        fetchData(params);
      },
    },
    entityName: 'attendanceSheet',
    // subEntityType: 'EligibilityDates',
    entityType: ENTITY_TYPES.ATTENDANCE_SHEET,
  });
  return (
    <div className="p-6">
      <div className={classNames(className, 'p-6 white-box')}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 md:gap-y-0 md:gap-x-3 mb-4">
          <Skeleton loading={childInfoLoading} paragraph={{ rows: 1 }}>
            <div className="">
              <h3 className="text-primary font-semibold text-15 uppercase">Child Info</h3>
              <p>
                <b>App ID:</b> 123456
              </p>
              <p>
                <b>Name:</b> {getFullName(childInfo)}
              </p>
            </div>
          </Skeleton>
          <Skeleton loading={careLocationLoading} paragraph={{ rows: 1 }}>
            <div className="">
              <h3 className="text-primary font-semibold text-15 uppercase">Care Location Info</h3>
              <p>
                <b>Business Name:</b> {careLocation.businessLegalName}
              </p>
              <p>
                <b>Address:</b> {getCompletedAddress(careLocation.address)}
              </p>
            </div>
          </Skeleton>
        </div>
        <div className="mb-6">{section}</div>
      </div>
    </div>
  );
}

const StartEndDates = ({ form, getSnapshotValue }) => {
  const startDateFieldProps = useMemo(() => ({ name: ['startDate'], getSnapshotValue }), [getSnapshotValue]);
  const endDateFieldProps = useMemo(() => ({ name: ['endDate'], getSnapshotValue, rules: [] }), [getSnapshotValue]);
  const [startDate, endDate] = useStartEndDates({
    form,
    startDateFieldProps,
    endDateFieldProps,
  });
  return (
    <>
      <Col xs={24} sm={12}>
        {startDate}
      </Col>
      <Col xs={24} sm={12}>
        {endDate}
      </Col>
    </>
  );
};

function CertificateSelection({ child, value: selectedRowKeys = [], onChange }) {
  const { loading, result: certificates = [] } = useAsync(
    async (childId) => {
      return await familyService.getCertificates(childId);
    },
    [child?.id],
  );
  const allColumns = [
    {
      title: <span className="action-header">Certificate ID</span>,
      headerText: 'Certificate ID',
      dataIndex: 'id',
      key: 'id',
      width: 120,
    },
    {
      title: <span className="action-header">Child Name</span>,
      headerText: 'Child Name',
      dataIndex: 'childName',
      key: 'childName',
      ellipsis: true,
      width: 140,
      className: 'truncate max-w-xs',
      render: (childName) => <span>{getFullName(childName) || 'N/A'}</span>,
    },
    {
      title: <span className="action-header">Status</span>,
      headerText: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 80,
      className: 'truncate max-w-xs',
      render: (status) => <span>{status}</span>,
    },
    {
      title: <span className="action-header">Provider Rate</span>,
      headerText: 'Provider Rate',
      dataIndex: 'providerRate',
      key: 'providerRate',
      ellipsis: true,
      width: 90,
      className: 'truncate max-w-xs',
      render: (providerRate) => <span>{`$${providerRate.toFixed(2)}`}</span>,
    },
    {
      title: <span className="action-header">Sponsor Rate</span>,
      dataIndex: 'sponsorRate',
      key: 'sponsorRate',
      ellipsis: true,
      width: 90,
      className: 'truncate max-w-xs',
      render: (sponsorRate) => <span>{`$${sponsorRate.toFixed(2)}`}</span>,
    },
    {
      title: <span className="action-header">Fee Assistance</span>,
      headerText: 'feeAssistance',
      dataIndex: 'feeAssistance',
      key: 'feeAssistance',
      ellipsis: true,
      width: 90,
      className: 'truncate max-w-xs',
      render: (feeAssistance) => <span>{`$${feeAssistance.toFixed(2)}`}</span>,
    },
  ];
  return (
    <Table
      allColumns={allColumns}
      data={certificates}
      loading={loading}
      xsCols={['id', 'childName', 'actions']}
      smCols={['id', 'childName', 'status', 'actions']}
      mdCols={['id', 'childName', 'status', 'actions']}
      lgCols={['id', 'childName', 'status', 'actions']}
      xlCols={['id', 'childName', 'status', 'sponsorRate', 'actions']}
      xxlCols={['id', 'childName', 'status', 'sponsorRate', 'feeAssistance', 'actions']}
      showColSeparator={false}
      rowClassName={(record, index) => (index % 2 === 0 ? '[&_td]:!bg-white h-9' : '[&_td]:!bg-light-bg h-9')}
      pagination={false}
      expandable={{
        rowExpandable: () => false,
        expandIcon: () => null,
        columnWidth: 0,
      }}
      rowSelection={{
        onChange,
        selectedRowKeys,
      }}
    />
  );
}
