import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FaRegSmile } from 'react-icons/fa';
import { Button, Spin } from 'antd';

import Form from 'components/Form';
import Select from 'components/Select';
import PlaceAutoComplete from 'components/PlaceAutoComplete';
import { ageGroupOptions } from 'utils';
import styles from './SearchBar.module.less';
import { Filter } from './Filters';
import { actions } from 'features/search';
import { useDispatch } from 'react-redux';
import FilterContainer from './FilterContainer';
import useSearchParams from './useSearchParams';
import { Search } from '@carbon/icons-react';

export default function SearchBar({ className, popover, isPublicSearch, onSubmit }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { params } = useSearchParams({ isPublicSearch });

  const selectStyles = useMemo(
    () => ({
      control: (provided) => ({ ...provided, height: 25, minHeight: 25 }),
      valueContainer: (provided) => ({ ...provided, height: 25, marginTop: -5 }),
      input: (provided) => ({ ...provided, height: 25, margin: 0 }),
      indicatorsContainer: (provided) => ({ ...provided, height: 25 }),
      clearIndicator: (provided) => ({ ...provided, padding: 0, marginTop: -2 }),
    }),
    [],
  );
  useEffect(() => {
    const formValues = form.getFieldsValue();
    const initialValues = { ...params, location: isPublicSearch ? params?.placeId : params?.location };
    if (formValues?.location) {
      initialValues.location = formValues?.location;
    }
    form.setFieldsValue(initialValues);
  }, [form, isPublicSearch, params]);
  if (popover) {
    const { ageInMonths } = params || {};
    const ageLabel = ageInMonths ? ` : ${ageInMonths} months` : '';
    const location = params?.location?.description;
    return (
      <>
        <Filter
          params={params}
          btnLabel={<span className="font-medium text-clip">Location {location ? ` : ${location}` : ''}</span>}
        >
          <FilterContainer
            title="City, State, Zip Code or Installation"
            id="location"
            titleClassName="pb-4 font-medium text-gray-600"
          >
            <Form.Item name="location" className="mb-10">
              <PlaceAutoComplete icon={null} placeholder="Start finding someone you trust..." setLoading={setLoading} />
            </Form.Item>
          </FilterContainer>
        </Filter>
        {/* <Filter
          params={params}
          btnLabel={<span className="font-medium text-clip">Child Care Type {childCareTypeLabel}</span>}
        >
          <FilterContainer title="Child Care Type" id="location" titleClassName="pb-4 font-medium text-gray-600">
            <Form.Item name="childCareTypes">
              <Select
                ariaLabel="Select Childcare Type"
                placeholder="Add type"
                name="childCareTypes"
                getOptionLabel={(opt) => opt.name}
                isOptionSelected={(opt, [value]) => opt.value === value?.value}
                options={CHILD_CARE_TYPES.map((opt) => ({ ...opt, label: opt.name }))}
              />
            </Form.Item>
          </FilterContainer>
        </Filter> */}
        <Filter params={params} btnLabel={<span className="font-medium text-clip">Age {ageLabel}</span>}>
          <FilterContainer title="Age" id="ageInMonths" titleClassName="pb-4 font-medium text-gray-600">
            <Form.Item name="ageInMonths">
              <Select
                ariaLabel="Select Child Age"
                name="ageInMonths"
                placeholder="Add age"
                options={ageGroupOptions}
                isOptionSelected={(opt, [value]) => opt.id === Number(value)}
                onChangeFormatter={(v) => v?.id}
              />
            </Form.Item>
          </FilterContainer>
        </Filter>
      </>
    );
  }
  return (
    <Form
      form={form}
      layout="vertical"
      className={classNames(
        'grid grid-cols-[40px_1fr_60px] gap-x-3 border border-gray-300 rounded-full h-16 bg-white',
        styles.searchBar,
        className,
      )}
      onFinish={(values) => {
        if (onSubmit && !isPublicSearch) return onSubmit(values);
        dispatch(actions.setParams({ params: { ...params, ...values }, isPublicSearch }));
      }}
    >
      <div className="h-16 rounded-l-full flex justify-end items-center">
        <FaRegSmile size={25} className="emoji text-gray-300" />
      </div>
      <div className="h-16 flex items-center">
        <div className="flex-1 grid grid-cols-[minmax(440px,_1fr)_100px] gap-x-3">
          <div className="h-12 flex items-center border-r border-black/20">
            <Form.Item
              className="location-container w-full"
              name="location"
              label={
                <b title="City, State, Zip Code or Installation" className="text-10">
                  City, State, Zip Code
                </b>
              }
            >
              <PlaceAutoComplete
                icon={null}
                placeholder="Start finding someone you trust..."
                setLoading={setLoading}
                styles={selectStyles}
              />
            </Form.Item>
          </div>

          <div className="h-12 flex items-center">
            <Form.Item
              name="ageInMonths"
              label={<b className="text-10">Child Age</b>}
              className="age-container  w-full"
            >
              <Select
                ariaLabel="Select Child Age"
                name="ageInMonths"
                placeholder="Add age"
                options={ageGroupOptions}
                isOptionSelected={(opt, [value]) => opt.id === Number(value)}
                onChangeFormatter={(v) => v?.id}
                styles={selectStyles}
                hideSeparator
                hideDropdownIcon
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="h-16 rounded-r-full flex justify-end items-center pr-[4px]">
        <Button
          htmlType="submit"
          type="primary"
          id="search-submit-btn"
          data-testid="submit-button"
          className="!w-[56px] !h-[56px] !rounded-full -mt-0.5"
          style={{ outline: 'none' }}
          name="Search"
          aria-label="Press Enter to search providers"
          disabled={loading}
          icon={loading ? <Spin className="search-icon" /> : <Search size={22} className="mt-[1px] ml-[-1px]" />}
        />
      </div>
    </Form>
  );
}
