import React, { useMemo, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { Button } from 'antd';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import Table from 'components/Table';
import familyService from 'services/family.service';
import { formatDate, getCompletedAddress } from 'utils';

export default function AdultMilitaryInfoTable({ record, value }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const { loading: loadingEducation, result: education } = useAsync(async () => {
    try {
      return await familyService.getSchoolInfo(record?.id);
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, []);
  const columns = useMemo(
    () => [
      {
        title: <span className="action-header">Institute Name</span>,
        headerText: 'Institute Name',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 120,
        render: (name) => name || 'N/A',
      },
      {
        title: <span className="action-header">Address</span>,
        headerText: 'Address',
        dataIndex: 'address',
        key: 'address',
        ellipsis: true,
        width: 150,
        render: (address) => getCompletedAddress(address) || 'N/A',
      },
      {
        title: 'ACTIONS',
        headerText: 'ACTIONS',
        key: 'actions',
        align: 'center',
        width: 100,
        fixed: 'right',
        render: (text, record) => {
          return (
            <div className="actions-cell bordered space-x-1 justify-center">
              <Button
                onClick={() => {
                  if (expandedRows[0] === record.id) {
                    setExpandedRows([]);
                  } else {
                    setExpandedRows([record.id]);
                  }
                }}
                icon={expandedRows.indexOf(record.id) > -1 ? <AiFillCaretUp /> : <AiFillCaretDown />}
                className="icon-btn"
                aria-label={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
                data-testid={`nested-collapse-button-${record.id}`}
                title={expandedRows.indexOf(record.id) > -1 ? 'Close' : 'Open'}
              />
            </div>
          );
        },
      },
    ],
    [expandedRows],
  );

  const expandedTableColumn = useMemo(
    () => [
      {
        title: <span className="action-header">Schooling Type</span>,
        headerText: 'Schooling Type',
        dataIndex: 'type',
        key: 'type',
        ellipsis: true,
        render: (type) => type?.title || 'N/A',
        width: 150,
      },
      {
        title: <span className="action-header">Credit/Clock Hours</span>,
        headerText: 'Credit/Clock Hours',
        dataIndex: 'creditHours',
        key: 'hours',
        ellipsis: true,
        render: (creditHours) => creditHours || 'N/A',
        width: 150,
      },
      {
        title: <span className="action-header">Start Date</span>,
        headerText: 'Start Dat',
        dataIndex: 'startDate',
        key: 'startDate',
        ellipsis: true,
        render: (startDate) => formatDate(startDate) || 'N/A',
        width: 150,
      },
      {
        title: <span className="action-header">End Date</span>,
        headerText: 'End Date',
        dataIndex: 'terms',
        key: 'endDate',
        ellipsis: true,
        render: (endDate) => formatDate(endDate) || 'N/A',
        width: 150,
      },
    ],
    [],
  );
  return (
    <div className="p-6">
      <h3 className="section-title mb-4">School Information</h3>
      <Table
        rowKey="id"
        allColumns={columns}
        data={education}
        loading={loadingEducation}
        pagination={false}
        showColSeparator={false}
        scroll={{ x: 1200 }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div>
                <Table data={record.terms || []} allColumns={expandedTableColumn} showColSeparator={false}></Table>
              </div>
            );
          },
          expandedRowKeys: expandedRows,
          expandIcon: () => null,
          columnWidth: 0,
        }}
      ></Table>
    </div>
  );
}
