import React, { useEffect } from 'react';
import { Input, Button, message, Spin } from 'antd';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useDispatch } from 'react-redux';

import Form from 'components/Form';
import Modal from 'components/Modal';
import Section from 'components/Section';
import Group, { GroupContext } from 'components/Group';
import Switch from 'components/Switch';
import familyService from 'services/family.service';
import commonService from 'services/common.service';
import { actions } from 'features/family';

export default function FamilyProgramAndEligibilityModal({ visible, setVisible, application }) {
  const [form] = Form.useForm();
  const { position } = { ...application?.applicant };
  const dispatch = useDispatch();

  const { loading, result: programTypes = [] } = useAsync(
    async (branchId, componentId, statusId) => {
      if (branchId && componentId && statusId) {
        return await commonService.getProgramTypes(branchId, componentId, statusId);
      }
      return [];
    },
    [position?.programSponsorId, position?.militaryComponentId, position?.militaryStatusId],
  );

  const { result: programs = [] } = useAsync(async () => {
    return await commonService.get(`/common/programs?active=true`, { cache: { interpretHeader: false } });
  }, []);

  useEffect(() => {
    if (programTypes.length > 0 && programs.length > 0 && position?.programSponsorId && application?.program?.id) {
      const program = programs.find((prog) => prog.id === application?.program?.id);
      if (program) {
        form.setFieldsValue({
          programTypeId: program.type.id,
        });
      }
    }
  }, [position?.programSponsorId, programs, programTypes, application?.program?.id, form]);

  const onSubmit = useAsyncCallback(
    async (values) => {
      const { programTypeId, notes } = values;
      const program = programs.find(
        (prog) => prog.type.id === programTypeId && prog.sponsor.id === position?.programSponsorId,
      );
      delete values.programTypeId;
      try {
        const data = await familyService.updateEligibilityProgram(application.id, program.id);
        if (notes && application?.id) {
          await familyService.saveNote(application.id, { content: notes, draft: false });
        }
        dispatch(actions.setApplication({ ...data }));
        setVisible(false);
        form.resetFields();
      } catch (error) {
        newrelic.noticeError(error);
        message.error('Unable to update program');
      } finally {
      }
    },
    [application.id, position?.programSponsorId, programs],
  );

  return (
    <Modal visible={visible} setVisible={setVisible} width={650}>
      <Spin spinning={loading || onSubmit.loading}>
        <Section
          heading="Family Program Eligibility"
          contentClassName=""
          collapsible={false}
          headerContainerClassName="!px-0 !py-0"
        >
          <Form layout="vertical" onFinish={onSubmit.execute} form={form}>
            <p className="py-7">
              Please select the program the family eligible for, then the branches you would like to participate in per
              program.{' '}
            </p>
            <Form.Item
              name="programTypeId"
              rules={[{ required: true, message: 'Choose at least one option.' }]}
              className="py-6 border-b-2 border-t-2"
            >
              <Group comparator={(v) => v} as="div" multiple={false}>
                {programTypes.map(({ title, id }) => (
                  <div key={id} className="flex space-x-2 mb-5">
                    <GroupContext value={id}>
                      <Switch className="mr-6 mb-2 md:mb-0" />
                    </GroupContext>
                    <p className="text-base">{title}</p>
                  </div>
                ))}
              </Group>
            </Form.Item>
            <Form.Item name="notes" label="notes" className="mt-4">
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="Write Note Here" autoComplete="off" />
            </Form.Item>
            <div className="actions flex">
              <Button onClick={() => setVisible(false)}>Cancel</Button>
              <Button data-testid="submit-button" type="primary" htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
        </Section>
      </Spin>
    </Modal>
  );
}
