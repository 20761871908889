import { createSelector, createSlice } from '@reduxjs/toolkit';
import { DASHBOARD_TYPES, RESET_ALL } from 'constants/index';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { commonService } from 'services';
import { createAsyncAction } from 'utils';

const initialState = {
  dashboardType: DASHBOARD_TYPES.PROVIDERS,
  data: [],
  pagination: {
    current: 1, // client side table page number starts from 1
    page: 0, // server side table page number starts from 0
    pageSize: 10,
    total: 0,
  },
  loading: false,
  url: '',
  method: 'get',
  params: {},
  body: {},
  selectedRowKeys: [],
  showBatchAssign: false,
  quickSearch: false,
};

export const selectDashboardState = createSelector(
  (state) => {
    return state.dashboard;
  },
  (state) => state,
);
export const selectDashboardType = createSelector(
  (state) => {
    return state.dashboard;
  },
  (dashboardState) => dashboardState?.dashboardType || DASHBOARD_TYPES.PROVIDERS,
);

export const getData = createAsyncAction('dashboard/getData');

export const dashboardStateSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardType: (state, { payload }) => {
      state.dashboardType = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload || [];
    },
    setLoading: (state, { payload }) => {
      state.loading = payload || false;
    },
    setUrl: (state, { payload }) => {
      state.url = payload;
    },
    setParams: (state, { payload }) => {
      state.params = payload || {};
    },
    setBody: (state, { payload }) => {
      state.body = payload || {};
    },
    setPagination: (state, { payload }) => {
      state.pagination = payload || {};
    },
    setSelectedRowKeys: (state, { payload }) => {
      state.selectedRowKeys = payload || [];
    },
    toggleBatchAssign: (state, { payload }) => {
      state.showBatchAssign = payload ?? !state.showBatchAssign;
    },
    setQuickSearch: (state, { payload }) => {
      state.quickSearch = payload;
    },
  },
  extraReducers: {
    [getData.pending]: (state, { payload: { url, params, body, method, quickSearch } }) => {
      state.loading = true;
      if (url !== undefined) {
        if (state.url !== url) state.data = [];
        state.url = url;
      }
      if (params !== undefined) state.params = params;
      if (body !== undefined) state.body = body;
      if (method !== undefined) state.method = method;
      if (quickSearch !== undefined) state.quickSearch = quickSearch;
      state.selectedRowKeys = []; // on Each Serch Reset Selected Row Keys
    },
    [getData.fulfilled]: (state, { payload }) => {
      state.data = payload.content;
      state.pagination = {
        current: payload.number + 1,
        pageSize: payload.size,
        total: payload.totalElements,
      };
      state.loading = false;
    },
    [getData.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.data = [];
    },
    [RESET_ALL]: () => {
      return initialState;
    },
  },
});
const reducer = dashboardStateSlice.reducer;
const actions = {
  ...dashboardStateSlice.actions,
  getData,
};
const selectors = {
  selectDashboardType,
  selectDashboardState,
};

export function* getDataSaga({ payload }) {
  if (payload?.cancelled) return;
  yield delay(500);
  const { url, params, body, method } = yield select((state) => state.dashboard);
  const requestParams = { ...params };
  requestParams.page = requestParams.current - 1;
  delete requestParams.current;
  delete requestParams.total;
  try {
    const response = yield call(commonService.getPaginatedData, { url, method, params: requestParams, body });
    yield put(getData.fulfilled(response));
  } catch (error) {
    newrelic.noticeError(error);
    yield put(getData.rejected(error));
  }
}

function* sagas() {
  yield all([takeLatest(getData.pending, getDataSaga)]);
}

export { reducer, actions, selectors, initialState, sagas };
export default dashboardStateSlice;
