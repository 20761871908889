import { createSelector, createSlice, createAction } from '@reduxjs/toolkit';
import { message } from 'antd';
import { call, put, throttle, all, select } from 'redux-saga/effects';
import commonService from 'services/common.service';
import { paramsToQueryString, parseQueryParams } from 'utils';

const PAGE_SIZE = 10;

const initialState = {
  content: [],
  totalElements: 0,
  pagination: { page: 0, size: PAGE_SIZE },
  params: { preferredProvider: false, facilityTypes: [] },
  loading: false,
};

export const selectSearchState = createSelector(
  (state) => state.search,
  (searchState) => searchState || initialState,
);
export const selectParams = createSelector(
  (state) => state.search,
  (searchState) => {
    return searchState?.params || initialState.params;
  },
);
export const selectPagination = createSelector(
  (state) => state.search,
  (searchState) => {
    return searchState?.pagination || initialState.pagination;
  },
);

export const searchProviders = {
  pending: createAction('providers/searchProviders/pending'),
  fulfilled: createAction('providers/searchProviders/fulfilled'),
  rejected: createAction('providers/searchProviders/rejected'),
};

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    setParams: (state, { payload }) => {
      if (payload.params) {
        if (!payload.isPublicSearch) {
          state.params = { ...{ ...initialState.params, preferredProvider: true }, ...payload.params };
        } else {
          state.params = { ...state.params, ...payload.params };
        }
      }
      if (payload.pagination) {
        state.pagination = { ...state.pagination, ...payload.pagination };
      }
    },
    resetSearch: (state) => {
      state.params = initialState.params;
      state.pagination = initialState.pagination;
      state.content = [];
      state.loading = false;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  },
  extraReducers: {
    [searchProviders.pending]: (state, { payload: { params } }) => {
      state.loading = true;
      state.params = { ...state.params, ...(params || {}) };
    },
    [searchProviders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.content = payload.content.map((item) => ({
        ...item,
        lat: item.address?.location?.lat,
        lng: item.address?.location?.lon,
      }));
      state.totalElements = payload.totalElements;
      state.numberOfElements = payload.numberOfElements;
    },
    [searchProviders.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});
const reducer = providersSlice.reducer;
const actions = {
  searchProviders,
  selectParams,
  ...providersSlice.actions,
};
const selectors = {
  selectSearchState,
  selectParams,
  selectPagination,
};
export function* searchProvidersSaga({ payload }) {
  let { params, isPublicSearch } = payload;
  const savedParams = yield select(selectParams);
  const pagination = yield select(selectPagination);
  const newParams = { ...savedParams, ...(params || {}) };
  if (!newParams.location?.point) {
    yield put(actions.setLoading(false));
    message.error('Please select a location.');
  } else {
    try {
      const data = yield call(commonService.getGccProviders, newParams, pagination, isPublicSearch);
      yield put(searchProviders.fulfilled(data));
    } catch (error) {
      newrelic.noticeError(error);
      yield put(searchProviders.rejected(error));
    }
  }
}
export function* setParamsSaga({ payload }) {
  try {
    let { params, pagination, isPublicSearch } = payload;
    let queryParams = {};
    if (isPublicSearch) {
      queryParams = parseQueryParams(window.location.search);
    }
    const newParams = { ...queryParams, ...params };
    if (params) {
      const query = paramsToQueryString(newParams, isPublicSearch);
      window.history.pushState(null, null, `?${query}`);
    }
    yield put(searchProviders.pending({ params: newParams, pagination, isPublicSearch }));
  } catch (error) {
    newrelic.noticeError(error);
  }
}

function* sagas() {
  yield all([
    throttle(500, searchProviders.pending, searchProvidersSaga),
    throttle(500, actions.setParams, setParamsSaga),
    // throttle(500, actions.setParams, searchProvidersSaga),
  ]);
}

export { reducer, actions, selectors, initialState, sagas };
export default providersSlice;
