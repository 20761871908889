import PropTypes from 'prop-types';
import React from 'react';
import { Switch } from 'antd';
import classNames from 'classnames';

import styles from './Switch.module.less';

export default function GccSwitch({
  className,
  background,
  checked,
  onChange,
  onClick,
  defaultChecked,
  showLabels,
  yesLabel,
  noLabel,
  readOnly = false,
  ...rest
}) {
  const style = {};
  if (background) {
    styles.background = background;
  }
  return (
    <span className={classNames(styles.switch, className)} onClick={onClick}>
      {showLabels && noLabel && (
        <span className="label no-label cursor-pointer" onClick={() => onChange({ target: { checked: false } })}>
          {noLabel}
        </span>
      )}
      <Switch
        {...rest}
        style={style}
        checked={checked}
        onChange={readOnly ? () => {} : onChange}
        defaultChecked={defaultChecked}
      />
      {showLabels && yesLabel && (
        <span className="label yes-label cursor-pointer" onClick={() => onChange({ target: { checked: true } })}>
          {yesLabel}
        </span>
      )}
    </span>
  );
}

GccSwitch.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  showLabels: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

GccSwitch.defaultProps = {};
