import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { BiInfoCircle } from 'react-icons/bi';

import styles from './ViewField.module.less';

export default function ViewField({
  label,
  value,
  required,
  showInfoIcon = true,
  className,
  labelClassName,
  valueClassName,
}) {
  return (
    <div className={classNames(styles.viewField, className)}>
      <div className="flex justify-between items-center">
        <span className={classNames('label', labelClassName)}>
          <span className="required">{required ? '* ' : null}</span>
          {label}
        </span>
      </div>
      {showInfoIcon && <BiInfoCircle className="info-icon text-primary" />}
      <span className={classNames('value', valueClassName)}>{value}</span>
    </div>
  );
}

ViewField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  showInfoIcon: PropTypes.bool,
};
ViewField.defaultProps = {
  label: '',
  required: true,
  value: '',
  showInfoIcon: true,
};
