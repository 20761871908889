import React from 'react';

// react-aria
import {
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Popover,
} from 'react-aria-components';

// date utilities
import dayjs from 'dayjs';
import { CalendarDate, getLocalTimeZone, today } from '@internationalized/date'; // Ensure you import parseDate

// styles
import './AriaDatePicker.less';

// icons
import { ChevronDown, ChevronLeft, ChevronRight } from '@carbon/icons-react';

export const DATE_FORMAT_US = 'MM-DD-YYYY';
export const DATE_LOCALE = 'en-US';

// Convert dayjs date to CalendarDate
function convertDayjsToCalendarDate(date) {
  const dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date);

  return new CalendarDate(dayjsDate.year(), dayjsDate.month() + 1, dayjsDate.date());
}

export default function AriaDatePicker({ value, onChange, disabled, onlyPast, style, ...rest }) {
  const [internalValue, setInternalValue] = React.useState(null);
  const selectedDate = value !== undefined ? value : internalValue;

  const handleChange = (date) => {
    if (!date) {
      // If date is null, clear the internal state and call onChange with null
      setInternalValue(null);
      if (onChange) onChange(null);
      return;
    }

    const localDate = date.toDate(getLocalTimeZone());
    const dayjsDate = dayjs(localDate);

    if (onChange) {
      onChange(dayjsDate); // Pass the dayjs format back to onChange
    } else {
      setInternalValue(dayjsDate); // Store dayjs in internal state
    }
  };

  return (
    <div className="cc__DatePicker css-var- ant-picker-css-var" style={style} data-testid={rest['data-testid']}>
      <DatePicker
        value={selectedDate ? convertDayjsToCalendarDate(selectedDate) : null}
        onChange={handleChange}
        isDisabled={disabled}
        isDateUnavailable={
          onlyPast
            ? (date) => {
                return dayjs(date.toDate(getLocalTimeZone())).isAfter(today());
              }
            : undefined
        }
      >
        <Group>
          <Button className="cc__DatePicker__trigger">
            <div className="flex items-center">
              <DateInput>{(segment) => <DateSegment segment={segment} />}</DateInput>

              <Button>
                <ChevronDown />
              </Button>
            </div>
          </Button>
        </Group>

        <Popover className="cc__DatePicker__popover css-var- ant-picker-css-var">
          <Dialog>
            <Calendar>
              <header>
                <Button slot="previous">
                  <ChevronLeft />
                </Button>

                <Heading />

                <Button slot="next">
                  <ChevronRight />
                </Button>
              </header>

              <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>
            </Calendar>
          </Dialog>
        </Popover>

        {/* Add a hidden input for testability */}
        <input
          type="hidden"
          aria-hidden="true"
          tabIndex={-1}
          data-testid="date-input"
          value={selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : ''}
          readOnly
        />
      </DatePicker>
    </div>
  );
}
