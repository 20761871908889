import React, { useRef, useState } from 'react';
import { Alert, Button, Card, message } from 'antd';
import { ADD_FACILITY_STEPS } from 'constants/index';
import Form from 'components/Form';
import useProviderEditDisabled from 'hooks/useProviderEditDisabled';
import CareFacilityFileManager from 'components/CareFacilityFileManager/CareFacilityFileManager';
import { providerService } from 'services';
import { PROVIDER_FILE_TYPES } from 'services/providers.service';
import Space, { Spacer } from 'components/Space/Space';
import { Typography } from 'antd';

export default function AdditionalDocuments({ applicationSubmitted, center, setStep, isFormSubmitting }) {
  const [filesNotFound, setFilesNotFound] = useState([]);

  const [form] = Form.useForm();
  const isSubmitDisabled = useProviderEditDisabled();
  const [submitLoading, setSubmitLoading] = useState(false);

  // Keeps track of whether `next` is pressed or `save`
  const shouldGoToNextStep = useRef(false);

  return (
    <Form
      form={form}
      onFinish={() => {
        setSubmitLoading(true);

        providerService
          .getCareFacilityFiles(center.id)
          .then((_files) => {
            // Identify missing files
            const _filesNotFound = [
              PROVIDER_FILE_TYPES.STATE_LICENSE,
              PROVIDER_FILE_TYPES.W9,
              PROVIDER_FILE_TYPES.RATE_SHEET,
            ].filter((fileType) => !_files.some((file) => file.fileType === fileType.code));
            setFilesNotFound(_filesNotFound);

            if (_filesNotFound.length === 0) {
              if (shouldGoToNextStep.current) {
                setStep(ADD_FACILITY_STEPS.AGREEMENT);
              }
            }
          })
          .catch((error) => {
            console.log('Error fetching files', error);

            message('Something went wrong. Please try again');
          })
          .finally(() => {
            setSubmitLoading(false);
          });
      }}
    >
      <Card
        title={
          <>
            <Typography.Title level={4} className="mt-4">
              Upload your documents
            </Typography.Title>
            <Typography.Paragraph style={{ fontWeight: 'normal' }} className="mb-4">
              Please upload your documentation below.
            </Typography.Paragraph>
          </>
        }
        showIcon
      >
        <CareFacilityFileManager center={center} readonly={applicationSubmitted} />
      </Card>

      {filesNotFound.length !== 0 && (
        <Alert
          className="mt-4 mb-4"
          message={<p className="font-semibold">Missing required documents</p>}
          description={
            <ul>
              {filesNotFound.map((fileType) => (
                <li key={fileType.code} className="pl-2">
                  {
                    {
                      [PROVIDER_FILE_TYPES.STATE_LICENSE.code]: 'current state license',
                      [PROVIDER_FILE_TYPES.W9.code]: 'W-9 or EIN certificate',
                      [PROVIDER_FILE_TYPES.RATE_SHEET.code]: 'current child care rates for all ages served',
                    }[fileType.code]
                  }
                </li>
              ))}
            </ul>
          }
          showIcon
          type="error"
        />
      )}

      <Space className="mt-4">
        <Button onClick={() => setStep(ADD_FACILITY_STEPS.DISCOUNTS)} style={{ borderRadius: '0px' }}>
          Back
        </Button>

        <Spacer />

        <Button
          type="primary"
          loading={isFormSubmitting || submitLoading}
          disabled={isFormSubmitting || isSubmitDisabled || submitLoading}
          data-testid="business-info-form-submit"
          onClick={() => {
            if (applicationSubmitted) {
              setStep(ADD_FACILITY_STEPS.AGREEMENT);
              return;
            }

            shouldGoToNextStep.current = true;
          }}
          htmlType={!applicationSubmitted ? 'submit' : undefined}
          style={{ borderRadius: '0px' }}
        >
          Next
        </Button>
      </Space>
    </Form>
  );
}
