import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Button, Input, InputNumber, Spin } from 'antd';
import { useAsyncCallback } from 'react-async-hook';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import Form from 'components/Form';
import { actions } from 'features/family';
import Modal from 'components/Modal';
import { AsyncSelect } from 'components/Select';
import useEntityGroup from 'hooks/useEntityGroup';
import familyService from 'services/family.service';
import useStartEndDates from 'hooks/useStartEndDates';
import { formatDate } from 'utils';
import AddressFields from 'components/AddressFields';
import SwitchField from 'components/Switch/SwitchField';

const StartEndDates = ({ form, values }) => {
  const [startDate, endDate] = useStartEndDates({
    form,
    values,
    startDateFieldProps: {
      label: 'Start Date',
      rules: [{ required: true, message: 'Start Date is required.' }],
    },
  });
  return (
    <>
      <div>{startDate}</div>
      <div>{endDate}</div>
    </>
  );
};

export default function AdultEducationContainer(props) {
  const [parentForm] = Form.useForm();
  const dispatch = useDispatch();
  const [addInstituteModal, setAddInstituteModal] = useState(false);

  const {
    loading: loadingEducation,
    execute: getEducation,
    result: education = [],
  } = useAsyncCallback(async () => {
    try {
      return await familyService.getSchoolInfo(props.adult?.id);
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, []);

  useEffect(() => {
    parentForm.setFieldsValue({ enrolledInSchool: props?.adult?.enrolledInSchool });
  }, [parentForm, props.adult]);

  const { loading: addingEducation, execute: addEducation } = useAsyncCallback(async (values) => {
    try {
      await familyService.addUpdateSchool(props.adult?.id, [{ ...values }]);
      const updatedAdult = await familyService.getAdultById(Number(props.householdId), props.adult?.id);
      const tempAdults = [...props.profileData.adults];
      const indexToUpdate = tempAdults.findIndex((adult) => adult.id === updatedAdult.id);
      tempAdults[indexToUpdate] = updatedAdult;
      setAddInstituteModal(false);
      dispatch(actions.setApplication({ adults: tempAdults }));
      getEducation();
    } catch (error) {
      newrelic.noticeError(error);
    }
  }, []);

  useEffect(() => {
    getEducation();
  }, [getEducation]);

  useEffect(() => {
    parentForm.setFieldsValue({ ...education });
  }, [parentForm, education]);
  const isAddingRelationShip = useMemo(
    () => props?.profileData?.children?.length >= 1 && props?.profileData?.adults?.length >= 1,
    [props?.profileData?.adults?.length, props?.profileData?.children?.length],
  );

  return (
    <Spin spinning={false}>
      <h3 className="section-title mb-6">Step {props.militaryEmployee ? 5 : 4}: Enter Adult's School Information</h3>
      <Button className="absolute right-4 mb-6 top-1" onClick={() => setAddInstituteModal(true)} icon={<FaPlus />}>
        Add Institute
      </Button>
      <Form form={parentForm} className="mt-6">
        <Form.Item data-testid="enrolled-in-School" name="enrolledInSchool" valuePropName="checked">
          <SwitchField>Enrolled in School</SwitchField>
        </Form.Item>
        <Form.Item data-testid="enrolled-in-School" name="id" valuePropName="checked" hidden>
          <InputNumber />
        </Form.Item>
        <Form.Item
          rules={[
            () => ({
              async validator(_, value) {
                if (value === undefined) {
                  return;
                }
                if (education?.length === 0) {
                  throw new Error('Please add at least one Institute.');
                }
              },
            }),
          ]}
          name="education"
          dependencies={[['enrolledInSchool']]}
        >
          {education?.map((item, index) => (
            <>
              <Form.Item
                name={[index, 'name']}
                rules={[{ required: true, message: 'Institute Name is required' }]}
                label="Institution Name"
              >
                <Input placeholder="Name" readOnly />
              </Form.Item>
              <div className="pt-2 pb-4">
                <span className="section-title">Current Work Address</span>
                <span className="text-primary">
                  (Enter address if you need assistance search for childcare near this location)
                </span>
              </div>
              <AddressFields addressKey={[index, 'address']} id={[index, 'address']} required={false} readOnly />
              <div>
                <div className="pt-2 pb-4">
                  <span className="section-title">Semester Schedules</span>
                  <span className="text-primary">(Enter all relevant Schedules)</span>
                </div>
                <Form.Item name={[index, 'terms']}>
                  <AdultSchoolInformationContainer education={item} {...props} />
                </Form.Item>
              </div>
            </>
          ))}
        </Form.Item>
        {props.addingAdult && (
          <div className="actions flex mt-[15%]">
            <Button onClick={props.setVisible} data-testid="cancel-school-information-btn">
              Back
            </Button>
            <Button
              onClick={() => {
                props.onAddingNewAdult(isAddingRelationShip ? 'addingRelationship' : 'addingNewAdult');
              }}
              disabled={loadingEducation}
              loading={loadingEducation}
            >
              {isAddingRelationShip ? 'Save and Add Relationship' : 'Save and Add New Adult'}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                props.onAddingNewAdult('addingNewChild');
              }}
              data-testid="save-school-info-btn"
              disabled={loadingEducation}
              loading={loadingEducation}
            >
              Save and Add New Child
            </Button>
          </div>
        )}
      </Form>
      <Modal visible={addInstituteModal} setVisible={setAddInstituteModal} width={800}>
        <Spin spinning={addingEducation}>
          <span className="section-title mb-6">Add Institute</span>
          <Form layout="vertical" onFinish={addEducation}>
            <Form.Item name={['name']} label="Institution Name">
              <Input placeholder="Name" />
            </Form.Item>
            <AddressFields addressKey="address" id="address" />
            <div className="actions flex mt-5">
              <Button onClick={() => setAddInstituteModal(false)} data-testid="cancel-school-information-btn">
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                data-testid="save-reservation-btn"
                disabled={addingEducation}
                loading={addingEducation}
              >
                Save
              </Button>
            </div>
          </Form>
        </Spin>
      </Modal>
    </Spin>
  );
}

function AdultSchoolInformationContainer(props) {
  return <AdultSchoolInformation {...props} adultId={props?.adult?.id} />;
}

export function AdultSchoolInformation({ value: items, onChange, application, adultId, education, className }) {
  const onAddItem = useCallback(
    async (data) => {
      const updatedData = { ...education, terms: [{ ...data }] };
      await familyService.addUpdateSchoolInfo(adultId, updatedData);
      return data;
    },
    [adultId, education],
  );
  const onDeleteItem = useCallback(
    async (item) => familyService.deleteSchoolInfo(application?.id, item.id),
    [application?.id],
  );
  const getAllColumns = useCallback(({ addItem, deleteItem, setItem, setVisible }) => {
    const allColumns = [
      {
        title: <span className="action-header">School Type</span>,
        headerText: 'School Type',
        dataIndex: 'type',
        key: 'type',
        ellipsis: true,
        width: 150,
        render: (type) => type?.title || 'N/A',
      },
      {
        title: <span className="action-header">Credit Hours</span>,
        headerText: 'Credit Hours',
        dataIndex: 'creditHours',
        key: 'creditHours',
        ellipsis: true,
        width: 150,
        render: (creditHours) => creditHours || 'N/A',
      },
      {
        title: <span className="action-header">Start Date</span>,
        headerText: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        ellipsis: true,
        width: 150,
        render: (endDate) => formatDate(endDate) || 'N/A',
      },
      {
        title: <span className="action-header">End Date</span>,
        headerText: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        ellipsis: true,
        width: 150,
        render: (endDate) => formatDate(endDate) || 'N/A',
      },
    ];
    return allColumns;
  }, []);

  const getFormFields = useCallback((props) => {
    return <AdultSchoolInformationFormFields {...props} />;
  }, []);

  const { section } = useEntityGroup({
    items,
    onChange,
    onAddItem,
    onDeleteItem,
    entity: application,
    getAllColumns,
    getFormFields,
    entityTitle: 'School information',
    tableProps: {
      scroll: { x: 1200 },
    },
    hideDeleteButton: true,
    entityName: 'adultSchoolInformation',
    dateFields: [['startDate'], ['endDate']],
  });
  return (
    <div className={classNames(className)}>
      <div>{section}</div>
    </div>
  );
}

export const AdultSchoolInformationFormFields = ({ setVisible, items, form, item, index, addUpdateLoading }) => {
  return (
    <div>
      <h3 className="section-title">{item?.id ? 'Edit' : 'Add'} School Information</h3>
      <div className="grid grid-cols-2 gap-x-4 mt-6">
        <Form.Item name="type" label="Type of School">
          <AsyncSelect
            optionsApiUrl="/education-types"
            placeholder="School Type"
            ariaLabel="School Type"
            name="type"
            apiPrefix="families"
          />
        </Form.Item>
        <Form.Item name="grade" label="Grade">
          <AsyncSelect
            optionsApiUrl="/options/5"
            placeholder="Grade"
            ariaLabel="Grade"
            name="grade"
            apiPrefix="families"
          />
        </Form.Item>
        <StartEndDates form={form} />
      </div>
      <div className="actions flex mt-5">
        <Button onClick={() => setVisible(false)} data-testid="cancel-school-information-btn">
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          data-testid="save-reservation-btn"
          disabled={addUpdateLoading}
          loading={addUpdateLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
