import classNames from 'classnames';

// Styles
import './ScrollableTermsView.less';

export default function ScrollableTermsView(props) {
  return (
    <div
      className={classNames(
        'max-h-96 overflow-y-scroll border border-solid border-gray-400 p-8 always-show-scrollbar',
        props.className,
      )}
      data-testid="termsView"
    >
      {props.children}
    </div>
  );
}
