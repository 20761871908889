import React, { useCallback } from 'react';
import { Col, Row, Radio, Button, Input, InputNumber, Divider } from 'antd';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Form from 'components/Form';
import { AsyncSelect } from 'components/Select';
import { DATE_FORMAT } from 'constants/index';
import useEntityGroup from 'hooks/useEntityGroup';
import useStartEndDates from 'hooks/useStartEndDates';
import {
  formatDate,
  getAgeInWeeks,
  getMinMaxNumberValidator,
  getNumericValidator,
  getValue,
  renderAgeRange,
} from 'utils';
import AgeSelectInputNumber from 'components/AgeSelect/AgeSelectInputNumber';
import SwitchField from 'components/Switch/SwitchField';
import useChangeRequestClassName from 'hooks/useChangeRequestClassName';
import FormItem from 'components/FormItem';
import dayjs from 'dayjs';
import Card from 'components/Card/Card';
import Space, { Spacer } from 'components/Space/Space';
import { FacilityTypeMapping } from 'components/ProviderCard/ProviderCard';
import { Typography } from 'antd';

const { Title } = Typography;

const StartEndDates = ({ form, values, getSnapshotValue, startFieldLabel, endFieldLabel }) => {
  const [startDate, endDate] = useStartEndDates({
    form,
    startDateFieldProps: {
      label: startFieldLabel,
      getSnapshotValue,
      rules: [],
    },
    startDatePickerProps: {
      'data-testid': 'licenseStartDate',
    },
    endDateFieldProps: {
      label: endFieldLabel,
      rules: [
        {
          required: values.exempt ? values?.neverExpires : !values?.neverExpires,
          message: 'End Date is required',
        },
      ],
      dependencies: [['exempt'], ['neverExpires']],
      getSnapshotValue,
    },
    endDatePickerProps: {
      disabled: values.exempt ? !values?.neverExpires : values.neverExpires,
      'data-testid': 'licenseEndDate',
    },
  });
  return (
    <>
      <Col xs={24} sm={12} md={8}>
        {startDate}
      </Col>
      <Col xs={24} sm={12} md={8}>
        {endDate}
      </Col>
    </>
  );
};

const SchoolStartEndDates = ({ form, values, getSnapshotValue, startFieldLabel, endFieldLabel }) => {
  const [startDate, endDate] = useStartEndDates({
    form,
    startDateFieldProps: {
      label: startFieldLabel,
      getSnapshotValue,
      rules: [
        {
          required: values.exempt ? values?.neverExpires : !values?.neverExpires,
          message: 'Start Date is required',
        },
      ],
    },
    startDatePickerProps: {
      'data-testid': 'schoolStartDate',
    },
    endDateFieldProps: {
      label: endFieldLabel,
      rules: [
        {
          required: values.exempt ? values?.neverExpires : !values?.neverExpires,
          message: 'End Date is required',
        },
      ],
      dependencies: [['exempt'], ['neverExpires']],
      getSnapshotValue,
    },
    endDatePickerProps: {
      disabled: values.exempt ? !values?.neverExpires : values.neverExpires,
      'data-testid': 'schoolEndDate',
    },
  });

  return (
    <Row gutter={[20, 0]} className="form-row">
      <Col xs={24} sm={12} md={8}>
        {startDate}
      </Col>
      <Col xs={24} sm={12} md={8}>
        {endDate}
      </Col>
    </Row>
  );
};

export default function Licenses({ disabled, value: items, onChange, center }) {
  const keys = [
    'id',
    'duration',
    'endDate',
    'licenseNumber',
    'oldestAgeServed',
    'startDate',
    'totalCapacity',
    ['type', 'id'],
    'youngestAgeServed',
  ];
  const { providerAppsAgeGroupsEnabled } = useFlags();
  const rowClassName = useChangeRequestClassName({ keys, snapshotKey: ['licenses'] });
  const getAllColumns = useCallback(
    ({ addItem, deleteItem, setItem, setVisible }) => {
      const age_group_col = {
        title: <span className="action-header">Age Group</span>,
        headerText: 'Age Group',
        dataIndex: 'totalCapacity',
        width: 180,
        key: 'totalCapacity',
        render: (value, { youngestAgeServed, oldestAgeServed }) => {
          return (
            <div>
              {youngestAgeServed &&
                oldestAgeServed &&
                renderAgeRange({ minAge: youngestAgeServed, maxAge: oldestAgeServed })}{' '}
            </div>
          );
        },
      };

      const allColumns = [
        {
          title: <span className="action-header">License No</span>,
          headerText: 'Number',
          dataIndex: 'licenseNumber',
          key: 'licenseNumber',
          ellipsis: true,
          width: 140,
          render: (licenseNumber) => licenseNumber || 'N/A',
          // fixed: 'left',
        },
        {
          title: <span className="action-header">License Type</span>,
          headerText: 'Number',
          dataIndex: ['type', 'title'],
          key: 'type',
          ellipsis: true,
          width: 140,
          render: (type) => type || 'N/A',
        },
        {
          title: <span className="action-header">Start Date</span>,
          headerText: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (date) => formatDate(date),
          align: 'left',
          width: 120,
          ellipsis: true,
        },
        {
          title: <span className="action-header">Expiry Date</span>,
          headerText: 'Expiry Date',
          dataIndex: 'endDate',
          key: 'endDate',
          render: (date) => formatDate(date, DATE_FORMAT[1], <span className="font-medium">Non-Expiring</span>),
          align: 'left',
          width: 120,
        },
        {
          title: 'Capacity',
          headerText: 'Capacity',
          dataIndex: 'totalCapacity',
          width: 90,
          key: 'totalCapacity',
          render: (value) => {
            return value || 0;
          },
        },
      ];
      if (providerAppsAgeGroupsEnabled) {
        allColumns.splice(4, 0, age_group_col);
      }
      return allColumns;
    },
    [providerAppsAgeGroupsEnabled],
  );

  const getFormFields = useCallback(
    ({ values, setVisible, item, form }) => {
      const getSnapshotValue = (snapshot, name) => {
        return getValue(
          snapshot.licenses.find((v) => v.id === item?.id),
          name,
        );
      };
      const isLicenseFieldRequired = true;

      const centerIsSchool = center?.type?.title === FacilityTypeMapping.SCHOOL;

      return (
        <>
          {centerIsSchool && (
            <>
              <Form.Item name="isStateLicensed" label="Are you state-licensed?" valuePropName="checked" className="m-0">
                <SwitchField />
              </Form.Item>

              <Divider />
            </>
          )}

          {!centerIsSchool || form.getFieldValue('isStateLicensed') ? (
            <>
              <Row gutter={[20, 0]} className="form-row">
                <Col xs={24} md={8}>
                  <FormItem
                    name="type"
                    label="License Type"
                    rules={[{ required: isLicenseFieldRequired, message: 'License Type is required.' }]}
                    getSnapshotValue={getSnapshotValue}
                    isSame={(prev, curr) => prev?.id === curr?.id}
                  >
                    <AsyncSelect
                      optionsApiUrl="/options/15"
                      placeholder="License Type"
                      ariaLabel="License Type"
                      name="careFacilityLicenseType"
                    />
                  </FormItem>
                </Col>

                <Col xs={24} lg={16}>
                  <Form.Item name={['duration']} label={`License Terms`}>
                    <Radio.Group className="w-full">
                      <Row className="w-full" gutter={[12, 12]}>
                        <Col xs={24} lg={8}>
                          <FormItem
                            formItem={false}
                            name={['duration']}
                            namePrefix={['licenses', item.index]}
                            isSame={(prev, curr) => {
                              return (
                                (prev === 'SCHOOL_YEAR_ONLY' && curr === 'SCHOOL_YEAR_ONLY') ||
                                (prev !== 'SCHOOL_YEAR_ONLY' && curr !== 'SCHOOL_YEAR_ONLY')
                              );
                            }}
                            getSnapshotValue={getSnapshotValue}
                            className="h-10 flex justify-center"
                            changeRequestClassName="change-request-item px-1"
                          >
                            <Radio value="SCHOOL_YEAR_ONLY">School Year Only</Radio>
                          </FormItem>
                        </Col>
                        <Col xs={24} lg={8}>
                          <FormItem
                            formItem={false}
                            name={['duration']}
                            namePrefix={['licenses', item.index]}
                            isSame={(prev, curr) => {
                              return (
                                (prev === 'SUMMER_ONLY' && curr === 'SUMMER_ONLY') ||
                                (prev !== 'SUMMER_ONLY' && curr !== 'SUMMER_ONLY')
                              );
                            }}
                            getSnapshotValue={getSnapshotValue}
                            className="h-10 flex justify-center"
                            changeRequestClassName="change-request-item px-1"
                          >
                            <Radio value="SUMMER_ONLY">Summer Only</Radio>
                          </FormItem>
                        </Col>
                        <Col xs={24} lg={8}>
                          <FormItem
                            formItem={false}
                            name={['duration']}
                            namePrefix={['licenses', item.index]}
                            isSame={(prev, curr) => {
                              return (
                                (prev === 'ALL_YEAR' && curr === 'ALL_YEAR') ||
                                (prev !== 'ALL_YEAR' && curr !== 'ALL_YEAR')
                              );
                            }}
                            getSnapshotValue={getSnapshotValue}
                            className="h-10 flex justify-center"
                            changeRequestClassName="change-request-item px-1"
                          >
                            <Radio value="ALL_YEAR">All Year</Radio>
                          </FormItem>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <FormItem
                data-testid="never-expires"
                name="neverExpires"
                valuePropName="checked"
                getSnapshotValue={getSnapshotValue}
              >
                <SwitchField>I have a non-expiring license</SwitchField>
              </FormItem>

              <Row className="form-row" gutter={[20, 0]}>
                <Col xs={24} sm={12} md={8}>
                  <FormItem
                    name="licenseNumber"
                    label="License Number"
                    rules={[
                      { required: isLicenseFieldRequired, message: 'License Number is required.' },
                      {
                        validator: async (rule, value) => {
                          if (value === undefined || value === '') return;
                          if (!/^[0-9A-Za-z\-\/]+$/.test(value)) {
                            throw new Error('Invalid License Number.');
                          }
                        },
                      },
                      { max: 20, message: 'License Number is too long.' },
                    ]}
                    getSnapshotValue={getSnapshotValue}
                  >
                    <Input placeholder="License Number" />
                  </FormItem>
                </Col>

                <StartEndDates
                  form={form}
                  values={values}
                  getSnapshotValue={getSnapshotValue}
                  startFieldLabel="License Start Date"
                  endFieldLabel="License End Date"
                />
              </Row>
              {providerAppsAgeGroupsEnabled && (
                <Card title="Ages served">
                  <Row gutter={[20, 0]} className="form-row">
                    <Col xs={24} sm={12} md={10}>
                      <FormItem
                        name="youngestAgeServed"
                        label="Youngest Age Served"
                        dependencies={['oldestAgeServed']}
                        rules={[{ required: isLicenseFieldRequired, message: 'Youngest Age Served is required.' }]}
                        getSnapshotValue={getSnapshotValue}
                      >
                        <AgeSelectInputNumber />
                      </FormItem>
                    </Col>

                    <Col xs={24} sm={12} md={10}>
                      <FormItem
                        name="oldestAgeServed"
                        label="Oldest Age Served"
                        dependencies={['youngestAgeServed']}
                        rules={[
                          { required: isLicenseFieldRequired, message: 'Oldest Age Served is required.' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const minAge = getFieldValue('youngestAgeServed') || {};
                              const maxAge = value || {};
                              const minAgeWeeks = getAgeInWeeks(minAge);
                              const maxAgeWeeks = getAgeInWeeks(maxAge);
                              if (value === undefined || minAgeWeeks < maxAgeWeeks) {
                                return Promise.resolve();
                              }
                              return Promise.reject('Oldest Age Served must be greater than Youngest Age Served.');
                            },
                          }),
                        ]}
                        getSnapshotValue={getSnapshotValue}
                      >
                        <AgeSelectInputNumber />
                      </FormItem>
                    </Col>
                    <Col xs={24} sm={12} md={4}>
                      <FormItem
                        name="totalCapacity"
                        label="Capacity"
                        rules={[
                          { required: isLicenseFieldRequired, message: 'Capacity is required.' },
                          { validator: getNumericValidator('Invalid Capacity.') },
                          {
                            validator: getMinMaxNumberValidator({
                              min: 1,
                              max: 1000,
                              minMessage: 'Invalid value for Capacity.',
                              maxMessage: 'Too large value for Capacity.',
                            }),
                          },
                        ]}
                        getSnapshotValue={getSnapshotValue}
                      >
                        <InputNumber min={0} placeholder="Capacity" />
                      </FormItem>
                    </Col>
                  </Row>
                </Card>
              )}
            </>
          ) : (
            <>
              <Row gutter={[20, 0]} className="form-row">
                <Col xs={24} md={8}>
                  <FormItem
                    name="type"
                    label="Child Care Type"
                    rules={[{ required: isLicenseFieldRequired, message: 'Child Care Type is required.' }]}
                    getSnapshotValue={getSnapshotValue}
                    isSame={(prev, curr) => prev?.id === curr?.id}
                  >
                    <AsyncSelect
                      optionsApiUrl="/options/15"
                      ariaLabel="License Type"
                      name="careFacilityLicenseType"
                      filterOption={(option) => {
                        return option.label === 'School-Age';
                      }}
                    />
                  </FormItem>
                </Col>

                <Col xs={24} lg={16}>
                  <Form.Item name={['duration']} label={`Care Terms`}>
                    <Radio.Group className="w-full">
                      <Row className="w-full" gutter={[12, 12]}>
                        <Col xs={24} lg={8}>
                          <FormItem
                            formItem={false}
                            name={['duration']}
                            namePrefix={['licenses', item.index]}
                            isSame={(prev, curr) => {
                              return (
                                (prev === 'SCHOOL_YEAR_ONLY' && curr === 'SCHOOL_YEAR_ONLY') ||
                                (prev !== 'SCHOOL_YEAR_ONLY' && curr !== 'SCHOOL_YEAR_ONLY')
                              );
                            }}
                            getSnapshotValue={getSnapshotValue}
                            className="h-10 flex justify-center"
                            changeRequestClassName="change-request-item px-1"
                          >
                            <Radio value="SCHOOL_YEAR_ONLY">School Year Only</Radio>
                          </FormItem>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <SchoolStartEndDates
                form={form}
                values={values}
                getSnapshotValue={getSnapshotValue}
                startFieldLabel="School Year Start Date"
                endFieldLabel="School Year End Date"
              />
            </>
          )}

          <Divider />

          <Divider />

          <Space>
            <Spacer />

            <Button onClick={() => setVisible(false)} data-testid="cancel-license-btn" style={{ borderRadius: '2px' }}>
              Cancel
            </Button>

            <Button type="primary" htmlType="submit" data-testid="save-license-btn" style={{ borderRadius: '2px' }}>
              Save
            </Button>
          </Space>
        </>
      );
    },
    [center?.type?.title, providerAppsAgeGroupsEnabled],
  );

  const { section } = useEntityGroup({
    items: (items || []).sort((a, b) => (b.neverExpires || !b.endDate ? -1 : dayjs(a.endDate) - dayjs(b.endDate))),
    onChange,
    entity: center,
    getAllColumns,
    getFormFields,
    entityTitle: 'license',
    tableProps: {
      rowClassName,
    },
    dateFields: [['startDate'], ['endDate']],
    initialValues: { licenseTerms: 'School Year Only', isStateLicensed: true },
    getFormInitialValues: (item) => {
      return {
        ...item,
        isStateLicensed: item.isStateLicensed || !!item?.licenseNumber,
      };
    },
    entityName: 'license',
    onValuesChange:
      (form) =>
      ({ neverExpires }) => {
        if (neverExpires) {
          form.setFieldsValue({ endDate: null });
        }
      },
    readOnly: disabled,
  });

  return (
    <Card
      collapsible
      title={
        <Title level={3} className="!mb-0">
          License information
        </Title>
      }
    >
      <p className="instructions mb-5 mt-4">
        Providers must have a valid license issued by their state to participate in Fee Assistance Programs.
      </p>

      <Form.Item
        name="licenseRows"
        dependencies={[['licenses']]}
        rules={[
          (form) => ({
            validator: (rule, value) => {
              const licenses = form.getFieldValue(['licenses']);
              if (licenses?.length > 0) return Promise.resolve();

              return Promise.reject('At least one license is required.');
            },
          }),
        ]}
      >
        {section}
      </Form.Item>
    </Card>
  );
}
