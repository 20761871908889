import { useEffect, useCallback, useState } from 'react';
import { message } from 'antd';
import debounce from 'lodash/debounce';
import useAsyncFn from './useAsyncFn';
import Form from 'components/Form';

export default function useAutoSaveNotes({
  notesFieldKey = 'notes',
  notesState,
  getNote,
  saveNote,
  statusState,
  updateStatus,
}) {
  const [form] = Form.useForm();
  const [visibleDQModal, setVisibleDQModal] = useState(false);

  useEffect(() => {
    /* istanbul ignore else */
    if (notesState?.value?.content) {
      form.setFieldsValue({
        notes: notesState?.value?.content,
      });
    }
  }, [form, notesState?.value?.content]);

  const [{ loading: draftNoteSaving, value: draftNote }, saveDraftNote] = useAsyncFn(
    async (values) => {
      /* istanbul ignore else */
      if (values.notes) {
        try {
          const savedNote = await saveNote({
            id: notesState?.value?.id,
            draft: true,
            content: values.notes,
          });
          /* istanbul ignore else */
          if (!notesState?.value?.id) {
            await getNote();
          }
          notesState?.setValue(savedNote);
          return savedNote;
        } catch (error) {
          newrelic.noticeError(error);
        }
      }
    },
    [notesState?.value?.id, notesState.setValue, saveNote],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveDraft = useCallback(
    debounce((values) => {
      saveDraftNote(values);
    }, 3000),
    [saveDraftNote],
  );
  const [, submitForm] = useAsyncFn(
    async (values) => {
      /* istanbul ignore else */
      if (values?.notes) {
        try {
          await saveNote({
            id: notesState?.value?.id,
            draft: false,
            content: values.notes,
          });
          await getNote();
          notesState?.setValue(undefined);
          form.resetFields([notesFieldKey]);
        } catch (error) {
          newrelic.noticeError(error);
        }
      }
      if (values?.status?.title === 'Disqualified' && !visibleDQModal) {
        return setVisibleDQModal(true);
      }
      if (values?.status?.id !== statusState.value?.status?.id) {
        try {
          statusState?.setValue?.(values.status);
          await updateStatus(values?.status);
          message.success('Application status successfully updated.');
        } catch (error) {
          message.error('Unable to updated Application status.');
          newrelic.noticeError(error);
        }
      }
    },
    [
      notesState?.value?.id,
      notesState?.setValue,
      form,
      notesFieldKey,
      statusState.value,
      updateStatus,
      getNote,
      saveNote,
      visibleDQModal,
    ],
  );
  return {
    note: notesState?.value,
    getNoteLoading: notesState?.loading,
    getNote,
    draftNote,
    draftNoteSaving,
    changingStatus: statusState.loading,
    saveDraft,
    form,
    formSubmitting: statusState.loading,
    submitForm,
    visibleDQModal,
    setVisibleDQModal,
  };
}
