import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import PDFObject from 'pdfobject';
import { Spin } from 'antd';
import ViewField from 'components/ViewField';
import { formatDate } from 'utils';
import Space from 'components/Space/Space';
import { filesService } from 'services';

export default function DocumentPreview({ document }) {
  const [fileContents, setFileContents] = useState(null);
  const [fileType, setFileType] = useState(null);

  const [image, setImage] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  const [loading, setLoading] = useState(false);

  const getFile = useCallback(async (document) => {
    setImageLoaded(false);
    setFileType(null);
    setFileContents(null);
    setImage(null);

    setLoading(true);

    // Fetch file contents
    const data = await filesService.getFileContents(document.id);
    setFileContents(data.contents);
    setFileType(data.contentType);
  }, []);

  useEffect(() => {
    if (document) {
      getFile(document);
    }
  }, [getFile, document?.id, document]);

  useEffect(() => {
    if (_isPdf(fileType)) {
      PDFObject.embed(`data:application/pdf;base64,${fileContents}`, `#file-preview`, {
        title: document.name,
        height: '700px',
        width: '100%',
        pdfOpenParams: {
          view: 'FitH',
        },
      });
    } else {
      setImage(`data:${fileType};base64,${fileContents}`);
    }

    setLoading(false);
  }, [document, fileContents, fileType]);

  return (
    <div className={classNames('px-3 md:px-4 lg:px-8 py-5')} id="file-preview-container">
      <Space size={8}>
        <ViewField
          label="Submission date:"
          value={formatDate(document?.createdDate)}
          showInfoIcon={false}
          valueClassName="font-medium"
          className="mb-0"
        />

        <ViewField
          label="Submitted by:"
          value={document?.createdBy}
          showInfoIcon={false}
          valueClassName="font-medium"
          className="mb-0"
        />

        {/* <Spacer />

        {!isFamilyRole && !document?.reviewRequired && !review?.confirmed && (
          <Button
            icon={<Flag />}
            onClick={() => markForReview(!document?.reviewRequired)}
            disabled={markingForReview}
            loading={markingForReview}
          >
            Mark for Review
          </Button>
        )}
        {!isFamilyRole && document?.reviewRequired && !review?.id && (
          <Button
            icon={<FlagFilled />}
            onClick={() => markForReview(!document?.reviewRequired)}
            danger
            disabled={activeRole !== ROLES.PROVIDER_COORDINATOR}
            loading={markingForReview}
          >
            Marked for Review
          </Button>
        )}
        {!isFamilyRole && document?.reviewRequired && !review?.confirmed && review?.id && (
          <Button icon={<FlagFilled />} danger disabled>
            Marked for Review
          </Button>
        )}
        {!isFamilyRole && review?.confirmed && (
          <Button
            className="!bg-green-600 !border-green-600 pointer-events-none"
            icon={<Checkmark className="text-white mr-2" />}
            tabIndex={-1}
          >
            <span className="!text-white">Reviewed</span>
          </Button>
        )}
    */}
      </Space>

      <div>
        <Spin spinning={loading || (!_isPdf(fileType) && !imageLoaded)} style={{ minHeight: 400 }}>
          {_isPdf(fileType) ? (
            <div
              id={`file-preview`}
              className={classNames('mt-4', {
                'flex items-center justify-center': PDFObject.supportsPDFs,
              })}
            />
          ) : image ? (
            <img
              src={image}
              alt="Preview"
              height={500}
              className="table mt-6 mx-auto"
              onLoad={handleImageLoaded}
              style={{ display: imageLoaded ? 'block' : 'none' }}
            />
          ) : null}
        </Spin>
      </div>
    </div>
  );
}

const _isPdf = (_fileType) => ['application/pdf', 'application/octet-stream'].includes(_fileType);
